import React, { Component } from 'react';
import { Layout } from './Layout';
import MaterialTable from "material-table";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import jwt_decode from 'jwt-decode';
import BarChart from 'react-bar-chart';
import $ from 'jquery';
import Api from '../serviceCall';
import * as pbi from 'powerbi-client';
import { TimeoutCounter } from './TimeoutCounter';
import { isNull, isNullOrUndefined } from 'util';

const { models } = pbi;
const powerbi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory,
);

export class Performance extends Component {
    static displayName = Performance.name;
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            Loading: true,
            barChartPendingOrder: [],
            yearlyBarChartOrder: [],
            myOrderSelectedValue: true,
            allOrderSelectedValue: false,
            businessDaysSelectedValue: false,
            byOrderDateSelectedValue: false,
            WRecordsSelectedValue: false,
            CalendarDaysSelectedValue: false,
            ByClosedDateCheckBoxChange: false,
            WOutRecordsSelectedValue: false,
            EteDaysSelectedValue: false,
            AllSelectedValue: false,
            selectedOrderType: "MyOrders",
            selectedRecordType: "",
            selectedFilterType: "",
            toasterredCla: false,
            toaster: "",
            isOwn: false,
            powerBiEmbedUrl: "",
            config : {}
        };
        this.toasterredfun2 = this.toasterredfun2.bind(this);
    }
    componentWillMount() {        
        let currentComponent = this;
        var ctype = localStorage.getItem("PerformanceMetrics");        
        if (!isNullOrUndefined(ctype) && ctype == "false") {
            currentComponent.toasterredfun2("You have no access to view. Contact Administrator.");
            setTimeout(function () { currentComponent.props.history.push('/dashboard'); }, 3000);
        }
        
        //var token = localStorage.getItem("UserId");
        //if (token == null || token.length == 0) {
        //    this.props.history.push('/');
        //} else {
            //var user = jwt_decode(token);
            //var userId = user.UserId;
            //if (userId.length == 0 || userId == null) {
            //    this.props.history.push("/");
            //}
            //var user = jwt_decode(token);
            //var userId = user.UserId;
            //let currentComponent = this;
            //Api.Post('api/placeorder/GetUserAccessLevel', {
            //    userId: userId
            //}, currentComponent).then(function (result) {
            //    if (result.statusCode === 100) {
            //        currentComponent.setState({ isOwn: result.accessLevel == "Own" });
            //    } else {
            //        currentComponent.toasterredfun2(result.statusMessage);
            //    }
            //});
        //}
        this.setState({ Loading: false });
        let companyType = localStorage.getItem("CompanyType");
        let companyCode = localStorage.getItem("Companycode");
        
        //if (companyType == "LEGAL") {
        //    this.setState({
        //        powerBiEmbedUrl: "https://app.powerbi.com/view?r=eyJrIjoiMGY0ODE2ZWItNzQ1ZS00Y2U1LTg4N2EtZDA5YmYxN2RmOWRhIiwidCI6ImVlMDkzYjhhLWI0YzYtNGVhMC1hNjkzLTc5MmRmMjM5ZjQ1OCIsImMiOjEwfQ%3D%3D"
        //    });
        //}
        //else if (companyCode == "demo") {
        //    this.setState({
        //        powerBiEmbedUrl: "https://app.powerbi.com/view?r=eyJrIjoiMzI5ZjlmMzktYzA5MS00YTQzLThiNGMtOTYzYmVjNTNmM2U5IiwidCI6ImVlMDkzYjhhLWI0YzYtNGVhMC1hNjkzLTc5MmRmMjM5ZjQ1OCIsImMiOjEwfQ%3D%3D"
        //    });
        //} else {
        //    this.setState({
        //        powerBiEmbedUrl: "https://app.powerbi.com/view?r=eyJrIjoiNTFhODBlZjQtZDg1NS00NTU1LTljM2YtMWVkYWQ4ZDI0Y2IwIiwidCI6ImVlMDkzYjhhLWI0YzYtNGVhMC1hNjkzLTc5MmRmMjM5ZjQ1OCIsImMiOjEwfQ%3D%3D"
        //    });
        //}
    }
    toasterredfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this),
            5000
        );
    }
    componentDidMount() {
        this.getReport();
        //this.GetPerformanceMonthlyBarChart(this.state.selectedOrderType);
    }
    GetPerformanceMonthlyBarChart(orderType, recordType = "", filterType = "") {
        //var user = jwt_decode(localStorage.getItem("UserId"));
        //var userId = user.UserId;
        let currentComponent = this;
        currentComponent.setState({ Loading: true });

        Api.Post('api/placeorder/GetPerformanceMetricsMonthlyChart', {
           orderType: orderType, filterType: filterType, recordType: recordType
        }, currentComponent).then(function (result) {
            currentComponent.setState({ Loading: false });
            if (result.statusCode === 100) {
                var chart = am4core.create("chartdiv", am4charts.XYChart);
                // Add data
                let dataObject = [];
                for (let i = 0; i < result.months.length; i++) {
                    dataObject.push({
                        months: result.months[i], "0to7": result.percentages[i].orders0to7DaysPercentage,
                        "8to14": result.percentages[i].orders8to14DaysPercentage, "15to30": result.percentages[i].orders15to30DaysPercentage,
                        "31to45": result.percentages[i].orders31to45DaysPercentage, "46to60": result.percentages[i].orders46to60DaysPercentage,
                        "60+": result.percentages[i].orders60PlusDaysPercentage
                    })
                }
                chart.data = dataObject;


                // Create axes
                var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "months";
                categoryAxis.title.text = "Months";
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.minGridDistance = 20;

                var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.title.text = "Percentages";
                valueAxis.calculateTotals = true;
                valueAxis.min = 0;
                valueAxis.max = 100;
                valueAxis.strictMinMax = true;
                valueAxis.renderer.labels.template.adapter.add("text", function (text) {
                    return text;
                });

                // Create series
                var series1 = chart.series.push(new am4charts.ColumnSeries());
                series1.dataFields.valueY = "0to7";
                series1.dataFields.categoryX = "months";
                series1.name = "0-7 days";
                series1.tooltipText = "{name}: [bold]{valueY}[/] %";

                var series2 = chart.series.push(new am4charts.ColumnSeries());
                series2.dataFields.valueY = "8to14";
                series2.dataFields.categoryX = "months";
                series2.name = "8-14 days";
                series2.tooltipText = "{name}: [bold]{valueY}[/] %";

                var series3 = chart.series.push(new am4charts.ColumnSeries());
                series3.dataFields.valueY = "15to30";
                series3.dataFields.categoryX = "months";
                series3.name = "15-30 days";
                series3.tooltipText = "{name}: [bold]{valueY}[/] %";
                // Do not try to stack on top of previous series
                // series2.stacked = true;

                var series4 = chart.series.push(new am4charts.ColumnSeries());
                series4.dataFields.valueY = "31to45";
                series4.dataFields.categoryX = "months";
                series4.name = "31-45 days";
                series4.tooltipText = "{name}: [bold]{valueY}[/] %";

                var series5 = chart.series.push(new am4charts.ColumnSeries());
                series5.dataFields.valueY = "46to60";
                series5.dataFields.categoryX = "months";
                series5.name = "45-60 days";
                series5.tooltipText = "{name}: [bold]{valueY}[/] %";

                var series6 = chart.series.push(new am4charts.ColumnSeries());
                series6.dataFields.valueY = "60+";
                series6.dataFields.categoryX = "months";
                series6.name = "60+ Days";
                series6.tooltipText = "{name}: [bold]{valueY}[/] %";


                // Add cursor
                chart.cursor = new am4charts.XYCursor();
                chart.legend = new am4charts.Legend();
            } else {
                currentComponent.toasterredfun2(result.statusMessage);
            }
        });
    }
    GetPerformanceBarChart(orderType, recordType) {
        //var user = jwt_decode(localStorage.getItem("UserId"));
        //var userId = user.UserId;
        let currentComponent = this;
        currentComponent.setState({ Loading: true });

        Api.Post('api/placeorder/GetPerformanceOrderBarChart', {
           orderType: orderType, recordType: recordType
        }, currentComponent).then(function (result) {                        
            if (result.statusCode === 100) {
                var chart = am4core.create("chartdiv", am4charts.XYChart);
                // Add data
                let dataObject = [];
                for (let i = 0; i < result.months.length; i++) {
                    dataObject.push({ months: result.months[i], percentages: result.percentages[i] })
                }
                chart.data = dataObject;

                // Create axes
                var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "months";
                categoryAxis.title.text = "Months";
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.minGridDistance = 20;

                var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.title.text = "Percentages";
                valueAxis.calculateTotals = true;
                valueAxis.min = 0;
                valueAxis.max = 55 + 5;
                valueAxis.strictMinMax = true;
                valueAxis.renderer.labels.template.adapter.add("text", function (text) {
                    return text;
                });

                // Create series
                var series = chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.valueY = "percentages";
                series.dataFields.categoryX = "months";
                series.tooltipText = "{name}: [bold]{valueY}[/]";
                series.stacked = true;
                // Add cursor
                chart.cursor = new am4charts.XYCursor();
                series.columns.template.fill = am4core.color("#0275d8");
            }
            else {
                currentComponent.setState({ barChartPendingOrder: result, Loading: false });
            }
        });
    }
    GetPerformanceCycleTimeBarChart(orderType, recordType) {
        //var user = jwt_decode(localStorage.getItem("UserId"));
        //var userId = user.UserId;
        let currentComponent = this;
        currentComponent.setState({ Loading: true });

        Api.Post('api/placeorder/GetYearlyPerformanceOrderBarChart', {
            orderType: orderType, recordType: recordType
        }, currentComponent).then(function (result) {            
            if (result.statusCode === 100) {
                var chart = am4core.create("chartdiv1", am4charts.XYChart);
                // Add data
                let dataObject = [];
                for (let i = 0; i < result.result.orders.length; i++) {
                    dataObject.push({ orders: result.result.orders[i], percentages: result.result.percentages[i] })
                }
                chart.data = dataObject;

                // Create axes
                var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "orders";
                categoryAxis.title.text = "Orders";
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.minGridDistance = 20;

                var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.title.text = "Percentages";
                valueAxis.calculateTotals = true;
                valueAxis.min = 0;
                valueAxis.max = 100;
                valueAxis.strictMinMax = true;
                valueAxis.renderer.labels.template.adapter.add("text", function (text) {
                    return text;
                });

                // Create series
                var series = chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.valueY = "percentages";
                series.dataFields.categoryX = "orders";
                series.tooltipText = "{name}: [bold]{valueY}[/]";
                series.stacked = true;
                // Add cursor
                chart.cursor = new am4charts.XYCursor();
                series.columns.template.fill = am4core.color("#0275d8");
            }
            else {
                currentComponent.setState({ yearlyBarChartOrder: result.result, Loading: false });
            }
        });
    }
    //myOrderCheckBoxChange = () => {
    //    this.setState({
    //        myOrderSelectedValue: !this.state.myOrderSelectedValue,
    //    }); 

    //    this.GetPerformanceMonthlyBarChart("MyOrders","");
    //}
    myOrderCheckBoxChange = e => {
        this.setState({
            myOrderSelectedValue: e.target.value == "on" ? true : false,
            allOrderSelectedValue: e.target.value == "on" ? false : true,
            selectedOrderType: "MyOrders"
        }, function () {
            this.GetPerformanceMonthlyBarChart(this.state.selectedOrderType, this.state.selectedRecordType, this.state.selectedFilterType);
        });
    }
    allOrderCheckBoxChange = e => {
        this.setState({
            allOrderSelectedValue: e.target.value == "on" ? true : false,
            myOrderSelectedValue: e.target.value == "on" ? false : true,
            selectedOrderType: "AllOrders"
        }, function () {
            this.GetPerformanceMonthlyBarChart(this.state.selectedOrderType, this.state.selectedRecordType, this.state.selectedFilterType);
        });
    }
    //allOrderCheckBoxChange = () => {
    //    this.setState({
    //        allOrderSelectedValue: !this.state.allOrderSelectedValue,
    //    });
    //    alert(this.state.allOrderSelectedValue);
    //    this.GetPerformanceMonthlyBarChart("AllOrders","");
    //}
    businessDaysCheckBoxChange = e => {
        this.setState({
            businessDaysSelectedValue: e.target.value == "on" ? true : false,
            CalendarDaysSelectedValue: e.target.value == "on" ? false : true,
            EteDaysSelectedValue: e.target.value == "on" ? false : true,
            selectedFilterType: "BusinessDays"
        }, function () {
            this.GetPerformanceMonthlyBarChart(this.state.selectedOrderType, this.state.selectedRecordType, this.state.selectedFilterType);
        });
    }
    byOrderDateCheckBoxChange = () => {
        this.setState({
            byOrderDateSelectedValue: !this.state.byOrderDateSelectedValue,
        });
        if (this.state.myOrderSelectedValue) {
            this.GetPerformanceMonthlyBarChart("MyOrders", "ByOrderDate");
        }
        else {
            this.GetPerformanceMonthlyBarChart(this.state.allOrderSelectedValue, "ByOrderDate");
        }
    }
    CalendarDaysCheckBoxChange = e => {
        this.setState({
            businessDaysSelectedValue: e.target.value == "on" ? false : true,
            CalendarDaysSelectedValue: e.target.value == "on" ? true : false,
            EteDaysSelectedValue: e.target.value == "on" ? false : true,
            selectedFilterType: "CalendarDays"
        }, function () {
            this.GetPerformanceMonthlyBarChart(this.state.selectedOrderType, this.state.selectedRecordType, this.state.selectedFilterType);
        });
    }
    EteDaysCheckBoxChange = e => {
        this.setState({
            businessDaysSelectedValue: e.target.value == "on" ? false : true,
            CalendarDaysSelectedValue: e.target.value == "on" ? false : true,
            EteDaysSelectedValue: e.target.value == "on" ? true : false,
            selectedFilterType: "ETEDays"
        }, function () {
            this.GetPerformanceMonthlyBarChart(this.state.selectedOrderType, this.state.selectedRecordType, this.state.selectedFilterType);
        });
    }
    ByClosedDateCheckBoxChange = () => {
        this.setState({
            ByClosedDateSelectedValue: !this.state.ByClosedDateSelectedValue,
        });
        //this.GetPerformanceBarChart("BusinessDays");
        //this.GetPerformanceCycleTimeBarChart("BusinessDays");
        if (this.state.myOrderSelectedValue) {
            this.GetPerformanceMonthlyBarChart("MyOrders", "ByClosedDate");
        }
        else {
            this.GetPerformanceMonthlyBarChart("AllRecords", "ByClosedDate");
        }
    }
    WRecordsCheckBoxChange = e => {
        this.setState({
            WRecordsSelectedValue: e.target.value == "on" ? true : false,
            WOutRecordsSelectedValue: e.target.value == "on" ? false : true,
            AllSelectedValue: e.target.value == "on" ? false : true,
            selectedRecordType: "WithRecords"
        }, function () {
            this.GetPerformanceMonthlyBarChart(this.state.selectedOrderType, this.state.selectedRecordType, this.state.selectedFilterType);
        });
    }
    WOutRecordsOnlyCheckBoxChange = e => {
        this.setState({
            WRecordsSelectedValue: e.target.value == "on" ? false : true,
            WOutRecordsSelectedValue: e.target.value == "on" ? true : false,
            AllSelectedValue: e.target.value == "on" ? false : true,
            selectedRecordType: "WithoutRecords"
        }, function () {
            this.GetPerformanceMonthlyBarChart(this.state.selectedOrderType, this.state.selectedRecordType, this.state.selectedFilterType);
        });
    }
    AllCheckBoxChange = e => {
        this.setState({
            WRecordsSelectedValue: e.target.value == "on" ? false : true,
            WOutRecordsSelectedValue: e.target.value == "on" ? false : true,
            AllSelectedValue: e.target.value == "on" ? true : false,
            selectedRecordType: "All"
        }, function () {
            this.GetPerformanceMonthlyBarChart(this.state.selectedOrderType, this.state.selectedRecordType, this.state.selectedFilterType);
        });
    }
    handleResetClick = () => {
        this.setState({
            allOrderSelectedValue: false,
            myOrderSelectedValue: true,
            businessDaysSelectedValue: false,
            CalendarDaysSelectedValue: false,
            EteDaysSelectedValue: false,
            WRecordsSelectedValue: false,
            WOutRecordsSelectedValue: false,
            AllSelectedValue: false,
            selectedOrderType: "MyOrders",
            selectedFilterType: "",
            selectedRecordType: ""
        }, function () {
            this.GetPerformanceMonthlyBarChart(this.state.selectedOrderType, this.state.selectedRecordType, this.state.selectedFilterType);
        });
    }
    getReport = e => {
        let currentComponent = this;
        currentComponent.setState({ Loading: true });
        //var jtoken = localStorage.getItem("UserId");
        //if (jtoken == null || jtoken.length == 0) {
        //    this.props.history.push('/');
        //}

        //var user = jwt_decode(jtoken);
        //var userId = user.UserId;
        //if (userId.length == 0 || userId == null) {
        //    this.props.history.push('/');
        //}
        var data = {
            "CompanyCode": localStorage.getItem("Companycode")            
        };
        Api.Post('api/placeorder/GetReport', data,
            currentComponent).then(function (result) {
                currentComponent.setState({ Loading: false });
                if (result.statusCode != 415) {
                    if (result.statusCode == 100) {
                        let config = {
                            type: 'report',
                            tokenType: models.TokenType.Embed,
                            accessToken: result.embedToken,
                            embedUrl: result.embedUrl,
                            id: result.id,
                            permissions: models.Permissions.All,
                            pageView: "fitToWidth",
                            settings: {
                                panes: {
                                    filters: {
                                        visible: true
                                    },
                                    pageNavigation: {
                                        visible: true
                                    }
                                }
                            }
                        };
                        currentComponent.setState({
                            config: config
                        });
                        powerbi.reset(currentComponent.myRef.current);
                        const report = powerbi.embed(currentComponent.myRef.current, config);
                    }
                    else {
                        currentComponent.toasterredfun2(result.statusMessage);
                    }
                }
                else {
                    currentComponent.toasterredfun2(result.statusMessage);
                }
            });
    }
    render() {
        var landing = "img/ems-logo.gif";
        return (
            <Layout>
                <TimeoutCounter />
                <span className={"loading " + (this.state.Loading ? "show" : "hide")}>
                    <span className="loading-div-d"></span>{" "}
                    <img src={process.env.PUBLIC_URL + landing} />{" "}
                </span>
                <div
                    className={
                        "toasterred-div " + (this.state.toasterredCla ? "show" : "hide")
                    }
                >
                    <i className="fa fa-exclamation-circle" aria-hidden="true"></i>{" "}
                    {this.state.toaster}{" "}
                </div>
                <div className="iframeDivHeight"></div>
                <div className="iframeHeight" ref={this.myRef}></div>
                {/*
                <div className='hgtDiv bg-white mt-2 p-3'>
                    <div className="">

                        <div className="row">
                            <div className="col-xl-12 margin-result">
                                <div className="row align-items-center">
                                    <div className="col-md-2">
                                        <button type="button" class="btn btn-primary btn-block btn-metrics">Selection</button>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="form-group pull-left mr-6 radioLable-x">
                                            <input type="radio" id="myOrder" name="order" checked={this.state.myOrderSelectedValue} onChange={this.myOrderCheckBoxChange}/>
                                            <label className="small mb-0" for="last5years">My Orders</label>
                                        </div>
                                        <div className="form-group pull-left radioLable-x">
                                            <input type="radio" id="allOrder" name="order" checked={this.state.allOrderSelectedValue} onChange={this.allOrderCheckBoxChange} disabled={this.state.isOwn} />
                                            <label className="small mb-0" for="last5years">All Office Orders</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 margin-result mt-4">
                                <div className="row align-items-center">
                                    <div className="col-md-2">
                                        <button type="button" class="btn btn-primary btn-block btn-metrics">Calculation</button>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="form-group pull-left mr-23 radioLable-x">
                                            <input type="radio" id="businessdays" name="customedate2" checked={this.state.businessDaysSelectedValue} onChange={this.businessDaysCheckBoxChange} />
                                            <label className="small mb-0" for="last5years">Business Days</label>
                                        </div>
                                        <div className="form-group pull-left mr-23 radioLable-x">
                                            <input type="radio" id="calendardays" name="customedate2" checked={this.state.CalendarDaysSelectedValue} onChange={this.CalendarDaysCheckBoxChange} />
                                            <label className="small mb-0" for="last5years">Calendar Days</label>
                                        </div>
                                        <div className="form-group pull-left mr-6 radioLable-x" >
                                            <input type="radio" id="etedays" name="customedate2" checked={this.state.EteDaysSelectedValue} onChange={this.EteDaysCheckBoxChange} />
                                            <label className="small mb-0" for="last5years">End-to-End Days </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 margin-result mt-4">
                                <div className="row align-items-center">
                                    <div className="col-md-2">
                                        <button type="button" class="btn btn-primary btn-block btn-metrics">Case Disposition</button>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="form-group pull-left mr-23 radioLable-x">
                                            <input type="radio" id="wrecordsonly" name="customedate3" checked={this.state.WRecordsSelectedValue} onChange={this.WRecordsCheckBoxChange} />
                                            <label className="small mb-0" for="last5years">Closed W/Records</label>
                                        </div>
                                        <div className="form-group pull-left mr-23 radioLable-x">
                                            <input type="radio" id="woutrecordsonly" name="customedate3" checked={this.state.WOutRecordsSelectedValue} onChange={this.WOutRecordsOnlyCheckBoxChange} />
                                            <label className="small mb-0" for="last5years">Closed W/Out Records</label>
                                        </div>
                                        <div className="form-group pull-left mr-6 radioLable-x">
                                            <input type="radio" id="all" name="customedate3" checked={this.state.AllSelectedValue} onChange={this.AllCheckBoxChange} />
                                            <label className="small mb-0" for="last5years">All</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 margin-result mt-4 text-right">
                                <button type="button" class="btn btn-primary btn-metrics" onClick={this.handleResetClick}>Reset</button>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-12 dashboard-map1 mt-4">

                                <div className="card">
                                    <div className="card-header">

                                        % Cleared By Day
                                    </div>
                                    <div className="card-body">
                                        <div id="chartdiv" style={{ width: "100%", height: "250px" }}></div>
                                    </div>
                                </div>

                            </div>




                        </div>




                    </div>
                </div>*/}
            </Layout>
        );
    }
}
