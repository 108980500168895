import React, { Component } from 'react';
import { Layout } from './Layout';
export class LogoutSSO extends Component {
    constructor(props) {
        super(props);

    }
    componentWillMount() {
        localStorage.clear();
    }
    //componentDidMount() {
    //    window.location.href = "/LogoutSSO";
    //}
    render() {
        return (
            <div className="container">
                <div className="container h-100 d-flex">
                    <div className="jumbotron my-auto">
                        <h1 className="display-6">Warning!</h1>
                        <p className="lead">Your login session is no longer valid. You have been logged out, please close the browser.</p>
                    </div>
                </div>
            </div>
        );
    }
}

