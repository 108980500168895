import React, { Component } from 'react';
import { Layout } from './Layout';
import jwt_decode from 'jwt-decode';
import $ from 'jquery';
import Api from '../serviceCall';
import { isNull, isNullOrUndefined } from 'util';
import { TimeoutCounter } from './TimeoutCounter';
export class Confirmation extends Component {
    static displayName = Confirmation.name;
    constructor(props) {
        super(props);
        this.state = {
            Numbers: [], contactName:"", contactEmail: "",
            contactPhone: "",
            policyLabel: "",
            policyNumber: "",
            patientLastName: "",
            patientFirstName: "",
            Loading: true,

        };
        this.toasterredfun = this.toasterredfun.bind(this);
    }
    componentWillMount() {
        let currentComponent = this;
        /*var token = localStorage.getItem("UserId");*/
        var id = localStorage.getItem("OrderId");
        /*if (token == null || token.length == 0) {
            currentComponent.props.history.push('/');
        }*/
        if (id == null || id.length == 0) {
            currentComponent.props.history.push('/place-order');
        }
       /* else {
            var user = jwt_decode(token);
            var userId = user.UserId;
            if (userId.length == 0 || userId == null) {
                currentComponent.props.history.push('/');
            }*/
            else {
                currentComponent.setState({ Loading: true });
                Api.Post('api/placeorder/GetConfirmation', { "orderId": parseInt(id) }, currentComponent).then(function (result) {
                    if (result.statusMessage == "Success") {                        
                        currentComponent.setState({ Numbers: result.result });
                         
                        if (!isNullOrUndefined(result.policyNumberValue)) {
                            currentComponent.setState({ policyLabel: Api.decryptData(result.policyNumberLabel) });
                            currentComponent.setState({ policyNumber: Api.decryptData(result.policyNumberValue) });
                        }
                        currentComponent.setState({ patientLastName: Api.decryptData(result.patientLN) });
                        currentComponent.setState({ patientFirstName: Api.decryptData(result.patientFN.trim()) });

                        localStorage.setItem("OrderId", "");
                    }
                    else {

                    }
                    currentComponent.setState({ Loading: false });
                });

                //fetch('api/placeorder/GetConfirmation', {
                //    method: 'post',
                //    headers: {
                //        Accept: 'application/json',
                //        'Content-Type': 'application/json',
                //        "Authorization": "Bearer " + token,
                //    },
                //    body: JSON.stringify({ "orderId": parseInt(id) }),
                //}).then(function (response) {
                //    if (response.status == 401) {
                //        currentComponent.props.history.push('/');
                //    }
                //    else { return response.json(); }
                //}).then(function (result) {
                //    if (result.statusMessage == "Success") {
                //        currentComponent.setState({ Numbers: result.result });
                //        localStorage.setItem("OrderId", "");
                //    }
                //    else {

                //    }
                //}).catch(function (error) {

                //});
            }
            currentComponent.setState({ Loading: true });
            Api.Get('api/placeorder/GetConfigData', currentComponent).then(function (result) {
                if (result.statusMessage == "Success") {
                    currentComponent.setState({ contactName: result.contactName, contactEmail: result.contactEmail, contactPhone: result.contactPhone });
                }
                else {

                }
                currentComponent.setState({ Loading: false });
            });

            //fetch('api/placeorder/GetConfigData', {
            //    method: 'Get',
            //    headers: {
            //        Accept: 'application/json',
            //        'Content-Type': 'application/json',
            //        "Authorization": "Bearer " + token,
            //    },
            //}).then(function (response) {
            //    if (response.status == 401) {
            //        currentComponent.props.history.push('/');
            //    }
            //    else { return response.json(); }
            //}).then(function (result) {
            //    if (result.statusMessage == "Success") {
            //        currentComponent.setState({ contactName: result.contactName, contactEmail: result.contactEmail, contactPhone: result.contactPhone });
            //    }
            //    else {

            //    }
            //}).catch(function (error) {

            //});
        /*} */     
     }
    toasterredfun(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }
    render() {
        var landing = "img/ems-logo.gif";
        return (
          <Layout>
                <TimeoutCounter />
                <div className='hgtDiv'>
                    <div className="">
                        <h5 className="mt-4 mb-2">Success! We have received your request(s)! Please see your Order number(s) below, write down or print this page for your records.
						</h5>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="mb-4">
                                    <ul className="confirm-list">
                                        {this.state.Numbers.map((num, i) => { return (<li key={i + 1}> Order: {num} {this.state.policyNumber != "" ? "- " + this.state.policyLabel + ": " + this.state.policyNumber : ""} - Patient: {this.state.patientLastName}, {this.state.patientFirstName.substring(0, 1)} </li>) })}
                                    </ul>
                                </div>
                            </div>


                            <div className="col-xl-12">
                                <div className="mt-4 mb-2">If you have any questions or concerns, please reach out to your Customer Service Representative at the information provided below
						    </div>
                                    <div className="mb-4">
                                        <ul className="letter-div-x">
                                            <li>{this.state.contactName}</li>
                                            <li> {this.state.contactEmail} </li>
                                            <li> {this.state.contactPhone} </li>                                            
                                    </ul>


                                    </div>
                                <h5 className="mb-6"> Thank you! </h5>
                            </div>
                        </div>
                     </div>
                </div>
                <div>
                <span id="load" className={"loading " + (this.state.Loading ? "show" : "hide")}>
                    <span className="loading-div-d"></span>{" "}
                    <img src={process.env.PUBLIC_URL + landing} />{" "}
                    </span>
                  </div>
            </Layout>
        );
    }
}
