import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Link, NavLink, Redirect } from 'react-router-dom';
export class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CopyRightYear: new Date().getFullYear()
        }
    }
    render() {
        var landing = 'img/ems-logo.gif';
        return (
            <Container fluid>
                <div className="container-div">
                    <div className="con-div-pso">
                        <div className="con-div-xd lefeside-panel">
                        </div>
                    </div>
                    <div className="con-div-xm">
                        <div className="con-div-x">
                            <div className="login-page">
                                <div className="logo">
                                    <img src="img/eSubmission-logo.png" />
                                    <h2>eNoah Online Portal </h2>
                                </div>
                                <div className="form">
                                    <h5>Register</h5> 
                                    <form className="login-form">
                                        <label>User Name</label>
                                        <input type="text" placeholder="User" />

                                        <label>Password</label>
                                        <input type="password" placeholder="Password" />

                                        <label>Confirm Password</label>
                                        <input type="password" placeholder="Password" />

                                        <button className="onLogin">Register</button>
                                        <span className="forgot-div-x"><NavLink tag={Link} to="/">Login</NavLink> </span>
                                    </form>
                                </div>
                            </div>
                            <div class="footer-text text-center mb-2">Build: <span class="text-dark mr-2">v2.0</span> Environment: <span class="text-dark">UAT</span></div>
                            <div class="footer-text text-center mb-2">Copyright @ eNoah iSolutions Inc. {this.state.CopyRightYear}</div>

                        </div>
                    </div>

                </div>


            </Container>
        );
    }
}