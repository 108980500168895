import MaterialTable from 'material-table';
import React, { Component } from 'react';
import { Layout } from './Layout';
import jwt_decode from 'jwt-decode';
import Api from '../serviceCall';
import $ from 'jquery';
import { DatePicker, DatePickerInput } from 'rc-datepicker';
import { TimeoutCounter } from './TimeoutCounter';
import { isNull, isNullOrUndefined } from 'util';
import { CsvBuilder } from 'filefy';
import jsPDF from 'jspdf';
import FileSaver from 'file-saver';

export class ReportData extends Component {
    static displayName = ReportData.name;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            reportData: [],
            carrierName: "",
            userOrdersCount: 0,
            mtdCount: 0,
            jobOrdersCount: 0,
            totalOrdersCount: 0,
            startDate: "",
            endDate: "",
            dayDiff: 0,
            dosd: false,
            doed: false,
            allowExport: false,
            adminAlert:false
        };
        this.GetReportResults = this.GetReportResults.bind(this);
        this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
        this.handleSDChange = this.handleSDChange.bind(this);
        this.handleEDChange = this.handleEDChange.bind(this);
        this.formatDateToString = this.formatDateToString.bind(this);
        this.getDifferenceInDays = this.getDifferenceInDays.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleKeypress = this.handleKeypress.bind(this);
        this.exportGridValues = this.exportGridValues.bind(this);
        this.Onclickisadminpopup = this.Onclickisadminpopup.bind(this);
    }

    componentWillMount() {
        var usertype = localStorage.getItem("UserType");
        if (usertype == "Agent") {
            this.props.history.push('/dashboard');
        }
        var ctype = localStorage.getItem("ShowAdminMenu");
        if (usertype.toLowerCase() == "client home office") {
            if (!isNullOrUndefined(ctype) && ctype == "false") {
                this.props.history.push('/dashboard');
            }
        }
        var fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 6);
        var toDate = new Date();
        toDate.setDate(toDate.getDate());
        var MinDate = new Date(new Date().getFullYear() - 110, 1, 1);
        var MaxDate = new Date();
        MaxDate.setHours(0, 0, 0, 0);
        MinDate.setHours(0, 0, 0, 0);

        let currentComponent = this;        

        this.setState({ minDate: MinDate, maxDate: MaxDate, });
        currentComponent.setState({
            loading: false,
        });
        var data = {
            "FromDate": currentComponent.formatDateToString(fromDate), "ToDate": currentComponent.formatDateToString(toDate), "UserType": usertype
        };
        this.GetReportResults(data);
    }

    handleClear(e) {
        var usertype = localStorage.getItem("UserType");
        var fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 6);
        var toDate = new Date();
        toDate.setDate(toDate.getDate());

        this.setState({
            startDate: "", endDate: "", doed: false, dosd: false
        });

        var data = {
            "FromDate": this.formatDateToString(fromDate), "ToDate": this.formatDateToString(toDate), "UserType": usertype
        };
        this.GetReportResults(data);

        setTimeout(
            function () {
                document.getElementById("sdate").value = "";
                document.getElementById("edate").value = "";
                $('.clear-button').trigger("click");
            }.bind(this),
            100
        );
    }

    handleSubmitSearch(e) {
        var usertype = localStorage.getItem("UserType");
        let currentComponent = this, isvalid = true, dayDiff = 0;
        if (this.state.endDate == "Invalid date") {
            if (document.getElementById("edate").value.length != 0) {
                isvalid = false;
                this.setState({ doed: true });
            }
            else {
                this.state.endDate = "";
                this.setState({ endDate: "", doed: false });
            }
        }
        if (this.state.startDate == "Invalid date") {
            if (document.getElementById("sdate").value.length != 0) {
                isvalid = false;
                this.setState({ dosd: true });
            }
            else {
                this.state.startDate = "";
                this.setState({ startDate: "", dosd: false });
            }
        }
        if (this.state.startDate instanceof Date && !(this.state.endDate instanceof Date)) {
            isvalid = false;
            this.setState({ doed: true });
            if (document.getElementById("edate").value.length != 0) {
                this.setState({ endDate: "" });
            }
        }
        if (!(this.state.startDate instanceof Date) && this.state.endDate instanceof Date) {
            isvalid = false;
            this.setState({ dosd: true });
            if (document.getElementById("sdate").value.length != 0) {
                this.setState({ startDate: "" });
            }
        }
        if (this.state.startDate instanceof Date && this.state.endDate instanceof Date && (new Date(this.state.startDate) > new Date(this.state.endDate))) {
            this.setState({ dosd: true, doed: true });
            isvalid = false;
        }

        if (isvalid) {
            if (this.state.startDate instanceof Date && this.state.endDate instanceof Date && (new Date(this.state.startDate) <= new Date(this.state.endDate))) {
                dayDiff = this.getDifferenceInDays(this.state.startDate, this.state.endDate);
                if (dayDiff > this.state.dayDiff) {
                    this.toasterredfun("Duration should be within " + this.state.dayDiff + " Days. Please select End Date on or before " + (this.AddConfiguredDays(this.state.startDate)));
                    isvalid = false;
                }
                else {
                    isvalid = true;
                }
            }
            else {
                isvalid = false;
                this.toasterredfun("Provide any input to search");
            }
        }
        if (isvalid) {
            currentComponent.setState({ loading: true, reportData: [] });
            var data = {
                "FromDate": currentComponent.formatDateToString(currentComponent.state.startDate), "ToDate": currentComponent.formatDateToString(currentComponent.state.endDate), "UserType": usertype
            };

            currentComponent.GetReportResults(data);
        }
    }

    getDifferenceInDays(date1, date2) {
        const diffInMs = Math.abs(date2- date1);
        return ((diffInMs + 86400 ) / (1000 * 60 * 60 * 24) );
    }

    AddConfiguredDays(date1) {
        var someDate = new Date(date1);
        someDate.setDate(someDate.getDate() + this.state.dayDiff);

        //var dateFormated = (someDate.getMonth() + 1) + "/" + someDate.getDay() + "/" + someDate.getFullYear();
        var dateFormated = someDate.toISOString().substr(5, 2) + "/" + someDate.toISOString().substr(8, 2) + "/" + someDate.toISOString().substr(0, 4);
        return dateFormated;
    }

    formatDateToString(dt) {
        if (dt.toString().length != 0) {
            var date = new Date(dt);
            var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
            var MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
            var yyyy = date.getFullYear();
            return (MM + "/" + dd + "/" + yyyy);
        }
        else {
            return "";
        }
    }
    handleKeypress(event) {
        var key = event.which || event.key;
        if (key == 13) {
            //if (this.state.Username.length != 0 && this.state.Password.length != 0 && this.state.Companycode.length != 0) {
            this.handleSubmitSearch();
            // }
        }
    }
    onKeyPressValueStartDate(event) {
        var length = event.target.value.length;
        if (length == 1) {
            var n = event.target.value.includes("/");
            if (n) {
                this.setState({ startDate: "" });
            }
        }
        if (length == 2) {
            var n = event.target.value.includes("/");
            if (n) {
                event.target.value = "0" + event.target.value;
            }
        }
        if (length == 5) {
            var n = event.target.value.slice(3, 5).includes("/");
            if (n) {
                event.target.value = [event.target.value.slice(0, 3), "0", event.target.value.slice(3)].join();
            }
        }
        event.target.value = event.target.value.replace(/^(\d\d)(\d)$/g, '$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2').replace(/[^\d\/]/g, '');
        if (length > 10) {
            event.stopPropagation();
        }
        this.handleKeypress(event);
    }
    onKeyPressValueEndDate(event) {
        var length = event.target.value.length;
        if (length == 1) {
            var n = event.target.value.includes("/");
            if (n) {
                this.setState({ endDate: "" });
            }
        }
        if (length == 2) {
            var n = event.target.value.includes("/");
            if (n) {
                event.target.value = "0" + event.target.value;
            }
        }
        if (length == 5) {
            var n = event.target.value.slice(3, 5).includes("/");
            if (n) {
                event.target.value = [event.target.value.slice(0, 3), "0", event.target.value.slice(3)].join();
            }
        }
        event.target.value = event.target.value.replace(/^(\d\d)(\d)$/g, '$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2').replace(/[^\d\/]/g, '');
        if (length > 10) {
            event.stopPropagation();
        }
        this.handleKeypress(event);
    }

    GetReportResults(data) {
        let currentComponent = this;
        currentComponent.setState({ carrierName: "", userOrdersCount: 0, mtdCount: 0, jobOrdersCount: 0, totalOrdersCount: 0, reportData: [] });
        currentComponent.setState({ loading: true });

        Api.Post('api/placeorder/GetOrderReport', data, currentComponent).then(function (result) {
            currentComponent.setState({ loading: false });
            if (result.statusCode === 100) {
                if (result.reportHeader) {
                    currentComponent.setState({
                        carrierName: result.reportHeader.carrierName,
                        userOrdersCount: result.reportHeader.ordersCreatedByUsers,
                        jobOrdersCount: result.reportHeader.ordersCreatedByJob,
                        totalOrdersCount: result.reportHeader.totalCount,
                        mtdCount: result.reportHeader.mtD_Count,
                        dayDiff: result.maxDayDiff
                    });
                }
                if (result.reportData) {
                    currentComponent.setState({ reportData: result.reportData });
                }
            } else if (result.statusCode == 403) {
                currentComponent.setState({ loading: false });
                localStorage.removeItem('ShowAdminMenu');
                localStorage.removeItem('UserType');
                localStorage.removeItem('PlaceOrder');
                localStorage.removeItem('ViewResults');
                localStorage.removeItem('ViewAuth');
                localStorage.removeItem('ExportSearchResults');
                localStorage.removeItem('PerformanceMetrics');
                localStorage.setItem('ShowAdminMenu', result.isAdmin);
                localStorage.setItem('UserType', result.userType);
                localStorage.setItem('PlaceOrder', result.placeOrder);
                localStorage.setItem('ViewResults', result.downloadImages);
                localStorage.setItem('ViewAuth', result.viewAuth);
                localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                currentComponent.setState({ adminAlert: true });
            }
            else if (result.statusCode == 402) {
                currentComponent.setState({ loading: false });
                localStorage.removeItem('ShowAdminMenu');
                localStorage.removeItem('UserType');
                localStorage.removeItem('PlaceOrder');
                localStorage.removeItem('ViewResults');
                localStorage.removeItem('ViewAuth');
                localStorage.removeItem('ExportSearchResults');
                localStorage.removeItem('PerformanceMetrics');
                localStorage.setItem('ShowAdminMenu', result.isAdmin);
                localStorage.setItem('UserType', result.userType);
                localStorage.setItem('PlaceOrder', result.placeOrder);
                localStorage.setItem('ViewResults', result.downloadImages);
                localStorage.setItem('ViewAuth', result.viewAuth);
                localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                currentComponent.setState({ adminAlert: true });
            }
            
            else {
                currentComponent.toasterredfun(result.statusMessage);
                currentComponent.setState({ reportData: [] });
            }
        });
    }

    handleSDChange(e) {

        if (e && e instanceof Date) {
            e.setHours(0, 0, 0, 0);
        }
        if (e > this.state.maxDate || e < this.state.minDate) {
            this.setState({ dosd: true });
            document.getElementById("sdate").value = "";
            this.setState({ startDate: "" });
        } else {
            this.setState({ dosd: false });
            if (e instanceof Date) {
                this.setState({ startDate: e });
            }
            else {
                this.setState({ startDate: e });
            }
        }
    }
    
    handleEDChange(e) {
        if (e && e instanceof Date) {
            e.setHours(0, 0, 0, 0);
        }
        if (e > this.state.maxDate || e < this.state.minDate) {
            this.setState({ doed: true });
            document.getElementById("edate").value = "";
            this.setState({ endDate: "" });
        } else {
            this.setState({ doed: false });

            if (e instanceof Date) {
                this.setState({ endDate: e });
            }
            else {
                this.setState({ endDate: e });
            }
        }
    }
    async exportGridValues() {
        let currentComponent = this;
        await Api.Get('api/placeorder/CheckForAdmin', currentComponent).then(function (result) {
            
            currentComponent.setState({ allowExport: result });
        });
    }
    toasterredfun(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }
    Onclickisadminpopup() {
        let currentComponent = this;
        currentComponent.props.history.push("/dashboard");
        currentComponent.setState({ adminAlert: false, loading: true });
    }

    render() {
        var landing = 'img/ems-logo.gif';
        return (
            <Layout>
                <TimeoutCounter />
                <h3 className="text-right py-2 carrier-title">{this.state.carrierName}</h3>
                <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster} </div>

                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i>
                    {this.state.toaster} </div>
                <div className={"removeAlert-divbrowser " + (this.state.adminAlert ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x text-center">
                        <img src="img/error.png" alt="Error" />
                        <p className="my-3"> Your permissions has been modified/revoked to perform this action. Please contact your administrator.</p>
                        <div className="text-center-div m-0">
                            <div className="bttn popupButton m-0" onClick={this.Onclickisadminpopup}>
                                Ok
                            </div>
                        </div>
                    </div>
                </div>
                <div className='hgtDiv bg-white mt-2 p-3'>
                    <div className="">
                        <div className="row">
                            <div className='border-md col-xl-10'>
                                <div class="row">
                                    <div class="col-xl-8">
                                        <div className="form-row-xm">
                                            <div className="col-md-3 pull-left nopadding">
                                                <label className="small mdb-1 pull-right" htmlFor="inputFirstName">Date Range</label>
                                            </div>
                                            <div className="col-md-9 pull-left nopaddingleft">
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <div className="form-group">
                                                            <div className='input-group dop-d wid125'>
                                                                <label className="small mr-1 mtop7" htmlFor="last5years">Start Date</label>
                                                                <DatePickerInput selected={this.state.startDate} id="sdate" autoComplete="off" maxLength="10" onKeyPress={this.onKeyPressValueStartDate.bind(this)} onChange={this.handleSDChange} value={this.state.startDate} dateformat="MM/dd/yyyy" className={"form-control " + (this.state.dosd ? "redborder" : "blackborder")} maxDate={new Date()} minDate={new Date(1920, 1, 1)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div className="form-group">
                                                            <div className='input-group dop-d wid125'>
                                                                <label className="small mr-1 mtop7" htmlFor="last5years">End Date</label>
                                                                <DatePickerInput selected={this.state.endDate} id="edate" autoComplete="off" maxLength="10" onKeyPress={this.onKeyPressValueEndDate.bind(this)} onChange={this.handleEDChange} value={this.state.endDate} dateformat="MM/dd/yyyy" className={"form-control " + (this.state.doed ? "redborder" : "blackborder")} maxDate={new Date()} minDate={new Date(1920, 1, 1)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-10 pull-left nopadding">
                                        <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1 onconfirm" onClick={this.handleSubmitSearch}>Search </button>
                                    </div>
                                    <div className="col-xl-2 pull-left nopadding">
                                        <button className="btn btn-blue-btnnm btn-type pull-left mt-1 mb-1 " onClick={this.handleClear}>Clear</button>
                                    </div>
                                </div></div>
                            <div className="col-md-12">
                                <div className="mb-4 mt-3 normal-data">
                                    <div className="card-body posrelative">
                                        <div className="table-responsive dataTable unsubmitted posrel postion-right">
                                            <div className="export-div-xx right-0">
                                                <button className="export-class-x">Export</button>
                                            </div>
                                            <div className="col-md-9 filter-dashboard">
                                                <div className="form-group">
                                                    <fieldset>
                                                        <div className="form-row">
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label>YTD Orders by Users:</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-1">
                                                                <div className="form-group">
                                                                    <label>{this.state.userOrdersCount}</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label>YTD B2B Orders:</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-1">
                                                                <div className="form-group">
                                                                    <label>{this.state.jobOrdersCount}</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label>MTD Orders:</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-1">
                                                                <div className="form-group">
                                                                    <label>{this.state.mtdCount}</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label>YTD Orders:</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-1">
                                                                <div className="form-group">
                                                                    <label>{this.state.totalOrdersCount}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <MaterialTable title="" className="dataTable"
                                                columns={[
                                                    { title: 'Date', field: 'date' },
                                                    { title: 'Count', field: 'count' }
                                                ]}
                                                data={this.state.reportData}
                                                options={{
                                                    exportButton: true,
                                                    exportFileName: "Orders count report",
                                                    isLoading: true,
                                                    filtering: false,
                                                    exportAllData: true,
                                                    search: false,
                                                    pageSize: 10,
                                                    paginationType: "stepped",
                                                    exportCsv: async (data, columns) => {
                                                        var isExport = await this.exportGridValues();
                                                        
                                                        if (this.state.allowExport) {
                                                            if (this.state.reportData.length > 0) {
                                                                const columnTitles = data.filter(a => a.hidden != true).filter(a => a.hidden != true).map(columnDef => columnDef.title);
                                                                const csvData = this.state.reportData.map(rowData =>
                                                                    data.filter(a => a.hidden != true).map(columnDef => rowData[columnDef.field]),
                                                                );
                                                                const builder = new CsvBuilder(`Orders count report`)
                                                                    .setColumns(columnTitles)
                                                                    .addRows(csvData)
                                                                    .exportFile();
                                                                return builder;
                                                            }
                                                        }
                                                        else {
                                                            let currentComponent = this;
                                                            currentComponent.setState({ adminAlert: true });
                                                        }

                                                    },
                                                    exportPdf: async (data, columns) => {
                                                        var isExport = await this.exportGridValues();
                                                        if (this.state.allowExport) {
                                                            
                                                            if (this.state.reportData.length > 0) {
                                                                const unit = "pt";
                                                                const size = "A4"; // Use A1, A2, A3 or A4
                                                                const orientation = "landscape"; // portrait or landscape
                                                                const doc = new jsPDF(orientation, unit, size);
                                                                doc.text("Orders count report", 40, 35);
                                                                const columnTitles = data.filter(a => a.hidden != true).map(columnDef => columnDef.title);
                                                                const pdfData = this.state.reportData.map(rowData =>
                                                                    data.filter(a => a.hidden != true ).map(columnDef => rowData[columnDef.field]),
                                                                );

                                                                doc.autoTable({
                                                                    styles: {
                                                                        cellPadding: 4,
                                                                        cellWidth: 'auto'
                                                                    },
                                                                    bodyStyles: {
                                                                        cellPadding: 5,
                                                                        margin: 50,
                                                                    },
                                                                    columnStyles: {
                                                                        0: { cellWidth: 'auto'},
                                                                        1: { cellWidth: 'auto'},
                                                                        2: { cellWidth: 'auto'},                                                                       
                                                                        
                                                                        text: {
                                                                            cellWidth: 'wrap'
                                                                        }
                                                                    },
                                                                    head: [columnTitles],
                                                                    body: pdfData
                                                                });

                                                                doc.save(`Orders count report.pdf`);
                                                            }
                                                        }
                                                        else {
                                                            let currentComponent = this;
                                                            currentComponent.setState({ adminAlert: true });
                                                        }
                                                    }
                                                }}
                                            ></MaterialTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span className={"loading " + (this.state.loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} /> </span>
            </Layout>
        );
    }
}