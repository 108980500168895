import React, { Component } from 'react';
import { Layout } from './Layout';
import MaterialTable from "material-table";
import TablePagination from '@material-ui/core/TablePagination';
import jQuery from 'jquery';
import { DatePicker, DatePickerInput } from 'rc-datepicker';
import jwt_decode from 'jwt-decode';
import $ from 'jquery';
import FileSaver from 'file-saver';
import axios from 'axios';
import Api from '../serviceCall';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CsvBuilder } from 'filefy';
import { isNull, isNullOrUndefined } from 'util';
import { MultiSelect } from "react-multi-select-component";
import "./Result.css";
import { TimeoutCounter } from './TimeoutCounter';
import Moment from 'moment';
import ReactTooltip from "react-tooltip";
import { TableHead, TableRow, TableCell, TableSortLabel, Checkbox } from '@material-ui/core';

export class Result extends Component {
    static displayName = Result.name;
    constructor(props) {
        super(props);
        this.state = {
            isModalopen: false, SearchList: [], StartDate: "", EndDate: "", Files: [], isEditModalopen: false, SelectedRows: [], Loading: true, toasterredCla: false, isOwn: false,
            isDownload: false, url: "", pdftitle: "", DateTyep: "", SearchType: "", ViewType: "", toasterCla: false, toasterredCla: false, toaster: "", edate: false, sdate: false, dosd: false, doed: false, DateType: "", OrderFacilityIdTemp: "", IsOrderOfficeTemp: false,

            lblFirstName: "",
            lblLastName: "",
            lblDoctorFirstName: "",
            lblDoctorLastName: "",
            lblFacilityName: "",
            lblPolicyNum: "",

            RequiredFieldsList: [],
            maxDate: new Date(),
            minDate: "",
            FirstNameHide: false,
            LastNameHide: false,
            DoctorLastNameHide: false,
            DoctorFirstNameHide: false,
            FacilityNameHide: false,
            PolicyNumHide: false,



            UserId: "",
            offSet: 0,
            PageLimit: 5,
            totalRecord: 0,
            currentPage: 0,
            dataForExport: [],
            dataForSelectAll: [],
            isSearchClicked: false,

            srViewType: "",
            srSearchType: "",
            srStartDate: "",
            srEndDate: "",
            emptyDataSourceMessage: "Click on search button to see results",
            orderingOfficeAccessList: [],
            selectedMultiItem: [],
            hideExportButton: false,
            srDateType: "",
            IsDownloadforNewResults: false,
            DateType: "", OrderFacilityIdTemp: "",            
            sortOrder: '',
            sortColumn: '',
            clickCount: 0

        };
        this.handleSTChange = this.handleSTChange.bind(this);
        this.handleVTChange = this.handleVTChange.bind(this);
        this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleDownloadSelected = this.handleDownloadSelected.bind(this);
        this.onOpenclosePopup = this.onOpenclosePopup.bind(this);
        this.onOpenPopup = this.onOpenPopup.bind(this);
        this.onDownload = this.onDownload.bind(this);
        this.handleSDChange = this.handleSDChange.bind(this);
        this.handleEDChange = this.handleEDChange.bind(this);
        this.onClickopenEditPopup = this.onClickopenEditPopup.bind(this);
        this.onClickclosePopup = this.onClickclosePopup.bind(this);
        this.handleDTChange = this.handleDTChange.bind(this);
        this.handleKeypress = this.handleKeypress.bind(this);
        this.GetRequiredFields = this.GetRequiredFields.bind(this);
        this.getGridValues = this.getGridValues.bind(this);
        this.exportGridValues = this.exportGridValues.bind(this);
        this.GetDataForSelectAll = this.GetDataForSelectAll.bind(this);
        this.loadOrderingOfficeAccessList = this.loadOrderingOfficeAccessList.bind(this);
        this.setSelectedfun = this.setSelectedfun.bind(this);
        this.Onclickexportpopup = this.Onclickexportpopup.bind(this);
        this.CheckForExport = this.CheckForExport.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.tableRef = React.createRef();
        this.handlePageChange = this.handlePageChange.bind(this);
        this.formatDateToString = this.formatDateToString.bind(this);
    }
    handlePageChange(e) {
        let CurrentPageLSR = localStorage.getItem('ClickedPageInResult');
  
        if (e == "first_page" || e == "0") {
            CurrentPageLSR = 1;
        }
        else if (e == "chevron_left") {
            CurrentPageLSR = parseInt(localStorage.getItem('ChevronleftInResult')) + 1;
        }
        else if (e == "chevron_right") {
            CurrentPageLSR = parseInt(localStorage.getItem('ChevronrightInResult')) + 1;
        }
        else if (e == "last_page") {
            var lp = localStorage.getItem('TotalRecordsInResult') / localStorage.getItem('PageLimit');
            lp = Math.ceil(lp) - 1;
            if (lp == 0) {
                CurrentPageLSR = 1;
            }
            else {
                CurrentPageLSR = parseInt(lp) + 1;
            }
        }
        localStorage.setItem('ClickedPageInResult', CurrentPageLSR);
        //let sr = localStorage.getItem('ClickedPageInResult');

    }
    setSelectedfun(e) {
        this.setState({ selectedMultiItem: e });
    }

    loadOrderingOfficeAccessList(selectDropDownUsers) {
        let currentComponent = this;
        currentComponent.setState({ orderingOfficeAccessList: [], Loading: true });
        Api.Post('api/placeorder/GetOrderingOfficeAccessList', {
            AccessLevel: selectDropDownUsers
        }, currentComponent).then(function (result) {
            if (result.statusCode === 100) {
                if (!isNullOrUndefined(result.result)) {
                    currentComponent.setState({ orderingOfficeAccessList: result.result, Loading: false, selectedMultiItem: result.result });
                }
                else {
                    currentComponent.setState({ orderingOfficeAccessList: [], Loading: false });
                }
            }
            else {
                currentComponent.setState({ orderingOfficeAccessList: [], Loading: false });
            }
        });
    }
    onClickclosePopup() {
        this.setState({ isEditModalopen: false, url: "", pdftitle: "" });
    }
    onClickopenEditPopup(e, ext, authDocId) {
        var windowlocation = window.location.href;
        if (ext.toLowerCase() == "tif" || ext.toLowerCase() == "tiff") {
            this.toasterredfun2("Your browser doesn't support to view tif images.");
        }
        else {
            let currentComponent = this;
            currentComponent.setState({ Loading: true });
            Api.Post('api/placeorder/GetDocURL', { "CompanyCode": e, "FacilityId": this.state.OrderFacilityIdTemp, "AuthDocId": authDocId }, currentComponent).then(function (result) {
                if (result != null && result.length != 0) {
                    currentComponent.setState({ url: result });
                    currentComponent.setState({
                        isEditModalopen: true, Loading: false
                    });
                    var hiddenIFrameID = 'hiddenDownloader',
                        iframe = document.getElementById(hiddenIFrameID);
                    if (iframe === null) {
                        iframe = document.createElement('iframe');
                        iframe.id = hiddenIFrameID;
                        iframe.style.display = 'none';
                        document.body.appendChild(iframe);
                    }
                    iframe.src = result;
                }
                else {
                    currentComponent.toasterredfun2("Document not found.");
                    currentComponent.setState({ url: '', Loading: false });
                }
            });
        }
    }
    handleSDChange(e) {
        if (e && e instanceof Date) {
            e.setHours(0, 0, 0, 0);
        }
        if (e > this.state.maxDate || e < this.state.minDate) {
            this.setState({ dosd: true });
            document.getElementById("sdate").value = "";
            this.setState({ StartDate: "" });
        } else {
            this.setState({ dosd: false });
            if (e instanceof Date) {
                this.setState({ StartDate: e });
            }
            else {
                this.setState({ StartDate: e });
            }
        }
        if (e <= this.state.EndDate) {
            this.setState({ dosd: false, doed: false });
        }
        if (this.state.DateType != "PublishDate") {
            if (this.state.StartDate == "") {
                this.setState({ DateType: "OrderDate" });
            }
        }
    }
    handleEDChange(e) {
        if (e && e instanceof Date) {
            e.setHours(0, 0, 0, 0);
        }
        if (e > this.state.maxDate || e < this.state.minDate) {
            this.setState({ doed: true });
            document.getElementById("edate").value = "";
            this.setState({ EndDate: "" });
        } else {
            this.setState({ doed: false });

            if (e instanceof Date) {
                this.setState({ EndDate: e });
            }
            else {
                this.setState({ EndDate: e });
            }
        }
        if (e >= this.state.StartDate) {
            this.setState({ dosd: false, doed: false });
        }
        if (this.state.DateType != "PublishDate") {
            if (this.state.StartDate == "") {
                this.setState({ DateType: "OrderDate" });
            }
        }
    }
    toasterredfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }
    componentWillMount() {
        //debugger;
        /* var token = localStorage.getItem("UserId");
         if (token == null || token.length == 0) {
             this.props.history.push('/');
         }*/
        let currentComponent = this;
        /*var user = jwt_decode(token);
        var userId = user.UserId;
        if (userId.length == 0 || userId == null) {
            currentComponent.props.history.push('/');
        }*/


        var MinDate = new Date(new Date().getFullYear() - 110, 1, 1);
        var MaxDate = new Date();
        MaxDate.setHours(0, 0, 0, 0);
        MinDate.setHours(0, 0, 0, 0);

        this.setState({ minDate: MinDate, maxDate: MaxDate });

        let companyType = localStorage.getItem("CompanyType");
        this.GetRequiredFields();

        var IsRedirectedFromCaseDetails = localStorage.getItem('IsFromCaseDetailsToResults');
        if (IsRedirectedFromCaseDetails === "true") {           
            localStorage.removeItem('IsFromCaseDetailsToResults');
            var selectedRows = JSON.parse(localStorage.getItem('SelectedRows'));
            var orderingOfficeAccessList = JSON.parse(localStorage.getItem('OrderingOfficeOptionsList'));
            var selectedOrderingOfficeList = JSON.parse(localStorage.getItem('SelectedOrderingOfficeList'));
            var resultsSortColumn = localStorage.getItem('ResultsSortColumn');
            var resultsSortOrder = localStorage.getItem('ResultsSortOrder');
            const pageLimit = parseInt(localStorage.getItem('PageLimit'), 10);
            const offset = parseInt(localStorage.getItem('OffSet'), 10);
            var vPageLimit;
            var vOffset;

            if (!isNaN(pageLimit)) {
                vPageLimit = pageLimit;                
            } else {
                vPageLimit = 5;
            }
            if (!isNaN(offset)) {
                vOffset = offset;
            } else {
                vOffset = 0;
            }
            this.handlePageChange(localStorage.getItem('ClickedPageInResult'));
            this.setState({
                SearchType: localStorage.getItem('SearchType'),                
                ViewType: localStorage.getItem('ViewType'),
                DateType: localStorage.getItem('DateType'),
                StartDate: localStorage.getItem('StartDate'),
                EndDate: localStorage.getItem('EndDate'),
                orderingOfficeAccessList: orderingOfficeAccessList,
                selectedMultiItem: selectedOrderingOfficeList,
                SelectedRows: selectedRows,               
                pageLimit: vPageLimit,
                offset: vOffset,             
                currentPage: parseInt(localStorage.getItem('ClickedPageInResult')) - 1,
                srViewType: localStorage.getItem('ViewType'),
                srSearchType: localStorage.getItem('SearchType'),
                srDateType: localStorage.getItem('DateType'),
                srStartDate: localStorage.getItem('StartDate'),
                srEndDate: localStorage.getItem('EndDate'),
                sortColumn: resultsSortColumn,
                sortOrder: resultsSortOrder,
                UserId: this.state.userId,
                isSearchClicked: true,
                Loading: true
            }, () => { this.getGridValues(this.state.UserId, vOffset, vPageLimit, resultsSortColumn, resultsSortOrder ); });
        }
        else {
            var UserType = localStorage.getItem("UserType");
            if (UserType == "Agent") {
                currentComponent.setState({ SearchType: "MyOrders" });
                currentComponent.loadOrderingOfficeAccessList(1);
            }
            else {
                currentComponent.setState({ SearchType: "AllOfficeOrders" });
                currentComponent.loadOrderingOfficeAccessList(2);
            }
            this.setState({ UserId: this.state.userId, offSet: 0, PageLimit: 5, Loading: true });
        }
        //this.setState({ StartDate: MinDate, EndDate: MaxDate});
        fetch('labelConfigs/Result_Labels.json').then(function (response) {
            return response.json();
        }).then(function (response) {
            currentComponent.setState({
                //individualNameTableHeaderLabel: response[companyType].Table_Header.Individual_Name,
                // facilityNameTableHeaderLabel: response[companyType].Table_Header.Facility_Name,
                //providerNameTableHeaderLabel: response[companyType].Table_Header.Provider_Name,
                //fileNumberTableHeaderLabel: response[companyType].Table_Header.File_Number
            });
        });

        Api.Get('api/placeorder/GetIsOwnStatus', currentComponent).then(function (result) {
            localStorage.removeItem('ShowAdminMenu');
            localStorage.removeItem('UserType');
            localStorage.removeItem('PlaceOrder');
            localStorage.removeItem('ViewResults');
            localStorage.removeItem('ViewAuth');
            localStorage.removeItem('ExportSearchResults');
            localStorage.removeItem('PerformanceMetrics');
            localStorage.setItem('ShowAdminMenu', result.isAdmin);
            localStorage.setItem('UserType', result.userType);
            localStorage.setItem('PlaceOrder', result.placeOrder);
            localStorage.setItem('ViewResults', result.viewResults);
            localStorage.setItem('ViewAuth', result.viewAuth);
            localStorage.setItem('ExportSearchResults', result.exportSearchResults);
            localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
            var UserId = Api.decryptData(result.userId);
            currentComponent.setState({ SearchList: [], userId: UserId });
            if (result.statusCode == '100') {
                currentComponent.setState({ Loading: false, isOwn: result.isOwn, hideExportButton: result.exportSearchResults });
            }
            else {
                currentComponent.setState({ Loading: false });
            }
        }).catch(function (error) {
            currentComponent.setState({ Loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
                

        //this.getGridValues(userId, currentComponent, 0, 5);
        //Api.Post('api/placeorder/GetResultsList', { "isInitial": true, "UserId": userId }, currentComponent).then(function (result) {
        //    currentComponent.setState({ Loading: false, isDownload: result.isDownloadable, SearchList: result.result, isOwn: result.isOwn });
        //}).catch(function (error) {
        //    if (error == "TypeError: Failed to fetch") {
        //        currentComponent.toasterredfun2("Check your internet connection.");
        //    }
        //    else {
        //        currentComponent.toasterredfun2(error);
        //    }
        //});
    }


    async GetDataForSelectAll() {
        let currentComponent = this;
        let responce;
        if (currentComponent.state.isSearchClicked == true) {
            currentComponent.setState({ Loading: true });
            var officeNameList = "0";
            if (currentComponent.state.selectedMultiItem.length > 0) {
                officeNameList = currentComponent.state.selectedMultiItem.map(function (elem) { return elem.value; }).join(",");
            }
            await Api.Post('api/placeorder/GetResultsList',
                { "isInitial": true, "UserId": this.state.userId, "dataOffSet": "0", "dataLimit": "0", "ViewType": currentComponent.state.srViewType, "DateType": currentComponent.state.srDateType, "SelectionType": currentComponent.state.srSearchType, "StartDate": currentComponent.formatDateToString(currentComponent.state.srStartDate), "EndDate": currentComponent.formatDateToString(currentComponent.state.srEndDate), "officeName": officeNameList, "fieldName": currentComponent.state.sortColumn, "sortOrder": currentComponent.state.sortOrder }, currentComponent
            ).then(function (result) {
                if (!isNullOrUndefined(result.result)) {
                    result.result.map(rowData => {
                        rowData.patientFirstName = Api.decryptData(rowData.patientFirstName);
                        rowData.patientLastName = Api.decryptData(rowData.patientLastName);
                        rowData.patientPolicyNumber = Api.decryptData(rowData.patientPolicyNumber);
                    });
                    currentComponent.setState({ Loading: false, dataForSelectAll: result.result });
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                }
                else {
                    currentComponent.setState({ Loading: false, dataForSelectAll: [] });
                }
                responce = true;
            }).catch(function (error) {
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                    responce = false;
                }
                else {
                    currentComponent.toasterredfun2(error);
                    responce = false;
                }
                currentComponent.setState({ Loading: false, dataForSelectAll: [] });
            });
        }
        else {
            responce = false;
        }
        return responce;
    }

    async exportGridValues() {
        let currentComponent = this;
        let responce;
        if (currentComponent.state.isSearchClicked == true) {
            currentComponent.setState({ Loading: true });
            var officeNameList = "0";
            const isExportExcelPDF = true
            if (currentComponent.state.selectedMultiItem.length > 0) {
                officeNameList = currentComponent.state.selectedMultiItem.map(function (elem) { return elem.value; }).join(",");
            }
            await Api.Post('api/placeorder/GetResultsList',
                { "isInitial": true, "UserId": this.state.userId, "dataOffSet": "0", "dataLimit": "0", "ViewType": currentComponent.state.srViewType, "SelectionType": currentComponent.state.srSearchType, "DateType": currentComponent.state.srDateType, "StartDate": currentComponent.formatDateToString(currentComponent.state.srStartDate), "EndDate": currentComponent.formatDateToString(currentComponent.state.srEndDate), "officeName": officeNameList, "IsExportExcelPDF": isExportExcelPDF, "fieldName": currentComponent.state.sortColumn, "sortOrder": currentComponent.state.sortOrder }, currentComponent
            ).then(function (result) {
                if (!isNullOrUndefined(result.result)) {
                    if (result.exportSearchResults) {
                        result.result.map(rowData => {
                            rowData.patientFirstName = Api.decryptData(rowData.patientFirstName);
                            rowData.patientLastName = Api.decryptData(rowData.patientLastName);
                            rowData.patientPolicyNumber = Api.decryptData(rowData.patientPolicyNumber);
                        });
                        currentComponent.setState({ Loading: false, dataForExport: result.result });
                        localStorage.removeItem('ExportSearchResults');
                        localStorage.removeItem('ShowAdminMenu');
                        localStorage.removeItem('UserType');
                        localStorage.removeItem('PlaceOrder');
                        localStorage.removeItem('ViewResults');
                        localStorage.removeItem('ViewAuth');
                        localStorage.removeItem('PerformanceMetrics');
                        localStorage.setItem('ShowAdminMenu', result.isAdmin);
                        localStorage.setItem('UserType', result.userType);
                        localStorage.setItem('PlaceOrder', result.placeOrder);
                        localStorage.setItem('ViewResults', result.downloadImages);
                        localStorage.setItem('ViewAuth', result.viewAuth);
                        localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                        localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    }
                    else {
                        currentComponent.setState({ dataForExport: [], Loading: false });
                        localStorage.removeItem('ExportSearchResults');
                        localStorage.removeItem('ShowAdminMenu');
                        localStorage.removeItem('UserType');
                        localStorage.removeItem('PlaceOrder');
                        localStorage.removeItem('ViewResults');
                        localStorage.removeItem('ViewAuth');
                        localStorage.removeItem('PerformanceMetrics');
                        localStorage.setItem('ShowAdminMenu', result.isAdmin);
                        localStorage.setItem('UserType', result.userType);
                        localStorage.setItem('PlaceOrder', result.placeOrder);
                        localStorage.setItem('ViewResults', result.downloadImages);
                        localStorage.setItem('ViewAuth', result.viewAuth);
                        localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                        localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                        if (currentComponent.state.hideExportButton) {
                            currentComponent.setState({ exportAlert: true });
                        }
                        responce = false;
                        return responce;
                    }
                }
                else {
                    currentComponent.setState({ Loading: false, dataForExport: [] });
                }
                responce = true;
            }).catch(function (error) {
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                    responce = false;
                }
                else {
                    currentComponent.toasterredfun2(error);
                    responce = false;
                }
                currentComponent.setState({ Loading: false, dataForExport: [] });
            });
        }
        else {
            responce = false;
        }
        return responce;
    }

    getGridValues(userId, offSet, dataLimit, fieldName, sortOrder) {
        let currentComponent = this;
        if (currentComponent.state.isSearchClicked == true) {
            currentComponent.setState({ Loading: true, offSet: offSet, PageLimit: dataLimit });
            localStorage.setItem('PageLimit', dataLimit);
            localStorage.setItem('OffSet', offSet);
            var officeNameList = "0";
            if (currentComponent.state.selectedMultiItem.length > 0) {
                officeNameList = currentComponent.state.selectedMultiItem.map(function (elem) { return elem.value; }).join(",");
            }
            Api.Post('api/placeorder/GetResultsList',
                {
                    "isInitial": true, "dataOffSet": offSet, "dataLimit": dataLimit, "ViewType": currentComponent.state.srViewType,
                    "SelectionType": currentComponent.state.srSearchType, "DateType": currentComponent.state.srDateType, "StartDate": currentComponent.formatDateToString(currentComponent.state.srStartDate), "EndDate": currentComponent.formatDateToString(currentComponent.state.srEndDate),
                    "officeName": officeNameList, "fieldName": fieldName, "sortOrder": sortOrder
                }, currentComponent
            ).then(function (result) {
                if (!isNullOrUndefined(result.result)) {
                    if (result.totalRecords == 0) {
                        currentComponent.setState({ emptyDataSourceMessage: "No records to display" });
                    }
                    result.result.map(rowData => {
                        rowData.patientFirstName = Api.decryptData(rowData.patientFirstName);
                        rowData.patientLastName = Api.decryptData(rowData.patientLastName);
                        rowData.patientPolicyNumber = Api.decryptData(rowData.patientPolicyNumber);
                    });                
                    currentComponent.setState({ Loading: false, isDownload: result.isDownloadable, SearchList: result.result, isOwn: result.isOwn, totalRecord: result.totalRecords });

                    localStorage.setItem('TotalRecordsInResult', result.totalRecords)
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);

                    const newPageLimit = dataLimit;
                    if (currentComponent.tableRef.current) {
                        const event = {
                            target: {
                                value: newPageLimit,
                            },
                        };
                        currentComponent.tableRef.current.onChangeRowsPerPage(event);
                    }
                    localStorage.removeItem('IsFromCaseDetailsToResults');
                }
                else if (result == null || result.statusCode === 429) {
                    currentComponent.setState({ Loading: false });
                    currentComponent.toasterredfun2("The number of consecutive mouse clicks exceeded. Please refresh the page or wait 7 seconds and try again.");
                }
                else {
                    currentComponent.setState({ Loading: false, isDownload: result.isDownloadable, SearchList: [], isOwn: result.isOwn, totalRecord: 0, emptyDataSourceMessage: "No records to display" });
                }
            }).catch(function (error) {
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
                currentComponent.setState({ Loading: false, SearchList: [], totalRecord: 0, emptyDataSourceMessage: "No records to display" });
            });
        }
    }
    GetRequiredFields() {
        var CompanyId = 1;
        /* var token = localStorage.getItem("UserId");
         if (token == null || token.length == 0) {
             this.props.history.push('/');
         }
 
         var user = jwt_decode(token);
         var userId = user.UserId;
         if (userId.length == 0 || userId == null) {
             this.props.history.push('/');
         }*/

        let currentComponent = this;
        currentComponent.setState({ Loading: true });
        Api.Post('api/placeorder/GetRequiredFields', { "CompanyId": CompanyId }, currentComponent).then(function (result) {
            currentComponent.setState({ RequiredFieldsList: result.requiredFields });

            if (currentComponent.state.RequiredFieldsList.length > 0) {
                for (var i = 0; i < currentComponent.state.RequiredFieldsList.length; i++) {
                    currentComponent.setState({
                        [currentComponent.state.RequiredFieldsList[i].className + 'Hide']: currentComponent.state.RequiredFieldsList[i].isActive,
                        // ['isMandatory' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].isRequired,
                    });
                    if (currentComponent.state.RequiredFieldsList[i].customFieldName == "") {

                        //fetch('labelConfigs/Search_Labels.json').then(function (response) {
                        //    return response.json();
                        //}).then(function (response) {
                        //    currentComponent.setState({
                        //        loading: false,
                        //        lblindividualFirstNameSearch: response[companyType].Search_Filter.Individual_First_Name,
                        //        lblindividualLastNameSearch: response[companyType].Search_Filter.Individual_Last_Name,
                        //        lblagentIdSearch: response[companyType].Search_Filter.Agent_Id,
                        //        lblfileNumberSearch: response[companyType].Search_Filter.File_Number,
                        //        lblindividualNameTableHeader: response[companyType].Table_Header.Individual_Name,
                        //        lblfacilityNameTableHeader: response[companyType].Table_Header.Facility_Name,
                        //        lblproviderNameTableHeader: response[companyType].Table_Header.Provider_Name,
                        //        lblfileNumberTableHeader: response[companyType].Table_Header.File_Number
                        //    });
                        //});


                        currentComponent.setState({
                            ['lbl' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].originalFieldName, Loading: false
                        });
                    }
                    else {
                        currentComponent.setState({
                            ['lbl' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].customFieldName, Loading: false
                        });
                    }
                }
            }

        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }

    handleSTChange(e) {
        this.setState({ SearchType: e.target.value, selectedMultiItem: [] });
        var user = localStorage.getItem("UserType");
        if (user == "Agent") {
            if (e.target.value == "MyOrders") {
                this.loadOrderingOfficeAccessList(1);
            }
            else {
                this.loadOrderingOfficeAccessList(2);
            }
        }
        else {
            this.loadOrderingOfficeAccessList(2);
        }
    }
    handleVTChange(e) {
        this.setState({ ViewType: e.target.value });
    }
    handleDateChange(e) {
        this.setState({ DateType: e.target.value });
        if (this.state.StartDate == "") {
            this.setState({ dosd: true });

        }
        if (this.state.EndDate == "") {
            this.setState({ doed: true });
        }
    }
    handleDTChange(e) {
        this.setState({ DateTyep: e.target.value });
        if (e.target.value == "All") {
            this.setState({ StartDate: "", EndDate: "" });
            document.getElementById("sdate").value = "";
            document.getElementById("edate").value = "";
        }
    }
    handleSubmitSearch(e) {
        /* var jwttoken = localStorage.getItem("UserId");
         if (jwttoken == null || jwttoken.length == 0) {
             this.props.history.push('/');
         }
         var user = jwt_decode(jwttoken);
         var userId = user.UserId;*/
        let currentComponent = this, isvalid = true;
        if (this.state.DateType == "OrderDate" || this.state.DateType == "PublishDate") {
            if (this.state.StartDate == "") {
                isvalid = false;
                this.setState({ dosd: true });

            }
            if (this.state.EndDate == "") {
                isvalid = false;
                this.setState({ doed: true });
            }
        }
        if (this.state.EndDate == "Invalid date") {
            if (document.getElementById("edate").value.length != 0) {
                isvalid = false;
                this.setState({ doed: true });
            }
            else {
                this.state.EndDate = "";
                this.setState({ EndDate: "", doed: false });
            }
        }
        if (this.state.StartDate == "Invalid date") {
            if (document.getElementById("sdate").value.length != 0) {
                isvalid = false;
                this.setState({ dosd: true });
            }
            else {
                this.state.StartDate = "";
                this.setState({ StartDate: "", dosd: false });
            }
        }
        if (this.state.StartDate instanceof Date && !(this.state.EndDate instanceof Date)) {
            isvalid = false;
            this.setState({ doed: true });
            if (document.getElementById("edate").value.length != 0) {
                this.setState({ EndDate: "" });
            }
        }
        if (!(this.state.StartDate instanceof Date) && this.state.EndDate instanceof Date) {
            isvalid = false;
            this.setState({ dosd: true });
            if (document.getElementById("sdate").value.length != 0) {
                this.setState({ StartDate: "" });
            }
        }
        if (this.state.StartDate instanceof Date && this.state.EndDate instanceof Date && (new Date(this.state.StartDate) > new Date(this.state.EndDate))) {
            this.setState({ dosd: true, doed: true });
            isvalid = false;
            this.toasterredfun2("Start Date should be less than or equal to End date");
        }
        if (isvalid) {
            if ((this.state.StartDate instanceof Date && this.state.EndDate instanceof Date && (new Date(this.state.StartDate) <= new Date(this.state.EndDate))) || this.state.SearchType.length != 0 || this.state.ViewType.length != 0) {
                isvalid = true;
            }
            else {
                isvalid = false;
                this.toasterredfun2("Provide any input to search");
            }
        }

        if (isvalid) {          
            this.state.SelectedRows.length = 0;
            this.setState({
                sortColumn: '',
                sortOrder: '',
                clickCount: 0,
            });
            this.setState({
                currentPage: 0, isSearchClicked: true, srViewType: this.state.ViewType, srSearchType: this.state.SearchType, srDateType: this.state.DateType,
                srStartDate: document.getElementById("sdate").value, srEndDate: document.getElementById("edate").value
            }, () => { this.getGridValues(this.state.UserId, 0, this.state.PageLimit); });

            localStorage.setItem('ClickedPageInResult', 1);

            //fetch('api/placeorder/GetResultsList', {
            //    method: 'post',
            //    headers: {
            //        Accept: 'application/json',
            //        'Content-Type': 'application/json',
            //        "Authorization": "Bearer " + localStorage.getItem("UserId"),
            //    },
            //    body: JSON.stringify({
            //        "ViewType": currentComponent.state.ViewType, "SelectionType": currentComponent.state.SelectionType, "UserId": userId, "StartDate": currentComponent.state.StartDate, "EndDate": currentComponent.state.EndDate
            //    })
            //}).then(function (response) {
            //    if (response.status == 401) {
            //        currentComponent.props.history.push('/');
            //    }
            //    else { return response.json(); }
            //}).then(function (result) {
            //    currentComponent.setState({ SearchList: result.result });
            //}).catch(function (error) {
            //    currentComponent.setState({ Loading: false });
            //    if (error == "TypeError: Failed to fetch") {
            //        currentComponent.toasterredfun2("Check your internet connection.");
            //    }
            //    else {
            //        currentComponent.toasterredfun2(error);
            //    }
            //});
        }

    }
    toasterredfun(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
                this.props.history.goBack();
            }.bind(this), 5000);
    }
    getResultsList = () => {
        /*var jwttoken = localStorage.getItem("UserId");
        if (jwttoken == null || jwttoken.length == 0) {
            this.props.history.push('/');
        }*/
        let currentComponent = this;
        currentComponent.setState({ Loading: true, SearchList: [] });
        var officeNameList = "0";
        if (currentComponent.state.selectedMultiItem.length > 0) {
            officeNameList = currentComponent.state.selectedMultiItem.map(function (elem) { return elem.value; }).join(",");
        }
        Api.Post('api/placeorder/GetResultsList', {
            "isInitial": true, "dataOffSet": currentComponent.state.offSet, "dataLimit": currentComponent.state.PageLimit,
            "ViewType": currentComponent.state.srViewType, "SelectionType": currentComponent.state.srSearchType, "DateType": currentComponent.state.srDateType,
            "StartDate": currentComponent.formatDateToString(currentComponent.state.srStartDate), "EndDate": currentComponent.formatDateToString(currentComponent.state.srEndDate), "officeName": officeNameList,
            "fieldName": currentComponent.state.sortColumn, "sortOrder": currentComponent.state.sortOrder
        }, currentComponent).then(function (result) {
            if (!isNullOrUndefined(result.result)) {
                if (result.totalRecords == 0) {
                    currentComponent.setState({ emptyDataSourceMessage: "No records to display" });
                }
                result.result.map(rowData => {
                    rowData.patientFirstName = Api.decryptData(rowData.patientFirstName);
                    rowData.patientLastName = Api.decryptData(rowData.patientLastName);
                    rowData.patientPolicyNumber = Api.decryptData(rowData.patientPolicyNumber);
                });
                currentComponent.setState({ Loading: false, isDownload: result.isDownloadable, SearchList: result.result, isOwn: result.isOwn, totalRecord: result.totalRecords });
            }
            else {
                currentComponent.setState({ Loading: false, isDownload: result.isDownloadable, SearchList: [], isOwn: result.isOwn, totalRecord: 0, emptyDataSourceMessage: "No records to display" });
            }
        }).catch(function (error) {
            currentComponent.setState({ Loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
            currentComponent.setState({ Loading: false, SearchList: [], totalRecord: 0, emptyDataSourceMessage: "No records to display" });
        });
    }
    handleClear(e) {
        this.setState({
            StartDate: "", EndDate: "", SearchType: "", DateTyep: "", ViewType: "", doed: false, dosd: false, SearchList: [], isSearchClicked: false,
            srViewType: "", srSearchType: "", srStartDate: "", srEndDate: "", totalRecord: "0", emptyDataSourceMessage: "Click on search button to see results",
            DateType: "", srDateType: ""
        });
        this.setState({
            sortColumn: '',
            sortOrder: '',
            clickCount: 0,
        });
        document.getElementById("sdate").value = "";
        document.getElementById("edate").value = "";
        var UserType = localStorage.getItem("UserType");
        if (UserType == "Agent") {
            this.setState({ SearchType: "MyOrders" });
            this.loadOrderingOfficeAccessList(1);
        }
        else {
            this.setState({ SearchType: "AllOfficeOrders" });
            this.loadOrderingOfficeAccessList(2);
        }
    }

    async CheckForExport() {
        /*var user = jwt_decode(localStorage.getItem("UserId"));
        var userId = user.UserId;*/
        let currentComponent = this;
        let responce = false;
        /* var data = {
             "UserId": userId,
         };*/
        currentComponent.setState({ Loading: true });
        await Api.Get('api/placeorder/AllowExportValues', currentComponent).then(function (result) {
            if (result.statusCode == "100") {
                let hideExportAlert = false;
                let notAllow = false;
                if (!isNullOrUndefined(result)) {



                    if (result.allowExport) {

                        currentComponent.setState({ Loading: false });
                        currentComponent.setState({ hideExportButton: result.allowExport });
                        localStorage.removeItem('ShowAdminMenu');
                        localStorage.removeItem('UserType');
                        localStorage.removeItem('PlaceOrder');
                        localStorage.removeItem('ViewResults');
                        localStorage.removeItem('ViewAuth');
                        localStorage.removeItem('ExportSearchResults');
                        localStorage.removeItem('PerformanceMetrics');
                        localStorage.setItem('ShowAdminMenu', result.isAdmin);
                        localStorage.setItem('UserType', result.userType);
                        localStorage.setItem('PlaceOrder', result.placeOrder);
                        localStorage.setItem('ViewResults', result.downloadImages);
                        localStorage.setItem('ViewAuth', result.viewAuth);
                        localStorage.setItem('ExportSearchResults', result.allowExport);
                        localStorage.setItem('PerformanceMetrics', result.performanceMetrics);

                    }
                    else {
                        currentComponent.setState({ Loading: false });
                        localStorage.removeItem('ShowAdminMenu');
                        localStorage.removeItem('UserType');
                        localStorage.removeItem('PlaceOrder');
                        localStorage.removeItem('ViewResults');
                        localStorage.removeItem('ViewAuth');
                        localStorage.removeItem('ExportSearchResults');
                        localStorage.removeItem('PerformanceMetrics');
                        localStorage.setItem('ShowAdminMenu', result.isAdmin);
                        localStorage.setItem('UserType', result.userType);
                        localStorage.setItem('PlaceOrder', result.placeOrder);
                        localStorage.setItem('ViewResults', result.downloadImages);
                        localStorage.setItem('ViewAuth', result.viewAuth);
                        localStorage.setItem('ExportSearchResults', result.allowExport);
                        localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                        if (currentComponent.state.hideExportButton) {
                            currentComponent.setState({ exportAlert: true });
                            hideExportAlert = true;
                        }
                        responce = false;
                        notAllow = true;


                    }
                    if (result.isDownload) {
                        currentComponent.setState({ Loading: false });
                        currentComponent.setState({ isDownload: result.isDownload });
                    }
                    else {
                        currentComponent.setState({ Loading: false });
                        if (currentComponent.state.isDownload) {
                            if (!hideExportAlert) {
                                currentComponent.setState({ exportAlert: true });
                            }

                        }
                        responce = false;
                        notAllow = true;
                    }

                    if (notAllow) {
                        return responce;
                    }

                }
                else {
                    currentComponent.setState({ Loading: false });
                }
                responce = true;
            } else {
                currentComponent.setState({ Loading: false });
                currentComponent.toasterredfun(result.statusMessage);
                responce = false;
            }
        });

        return responce;
    }

    async CheckForIsDownload(OrderOfficeId, currentDownloadAccess) {
        let currentComponent = this;
        let response = false;
        currentComponent.setState({ Loading: true });
        await Api.Post('api/placeorder/GetIsDownloadValue',
            {
                "OrderOfficeId": OrderOfficeId
            }, currentComponent
        ).then(function (result) {
            if (!isNullOrUndefined(result) && result.statusCode == 100) {
                localStorage.removeItem('ShowAdminMenu');
                localStorage.removeItem('UserType');
                localStorage.removeItem('PlaceOrder');
                localStorage.removeItem('ViewResults');
                localStorage.removeItem('ViewAuth');
                localStorage.removeItem('ExportSearchResults');
                localStorage.removeItem('PerformanceMetrics');
                localStorage.setItem('ShowAdminMenu', result.isAdmin);
                localStorage.setItem('UserType', result.userType);
                localStorage.setItem('PlaceOrder', result.placeOrder);
                localStorage.setItem('ViewResults', result.downloadImages);
                localStorage.setItem('ViewAuth', result.viewAuth);
                localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                if (result.isDownloadAccess) {
                    currentComponent.setState({ Loading: false, IsOrderOfficeTemp: true });
                    response = true;
                    return response;
                }
                else {
                    if (currentDownloadAccess) {
                        currentComponent.setState({ exportAlert: true });
                        currentComponent.setState({ Loading: false, IsOrderOfficeTemp: true });
                        return;
                    }
                    else
                        currentComponent.setState({ Loading: false, IsOrderOfficeTemp: false });
                }
            }
            else {
                currentComponent.toasterredfun2(result.statusMessage);
                currentComponent.setState({ Loading: false });
            }
        }).catch(function (error) {
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
            currentComponent.setState({ Loading: false });
        });
        return response;
    }


    async handleDownloadSelected(e) {
        e.persist()
        let currentComponent = this;
        if (this.state.isSearchClicked) {
            var isExport = await this.CheckForExport();
            if (this.state.hideExportButton) {
                if (this.state.isDownload) {
                    if (isExport) {
                        if (this.state.SelectedRows.length == 0) {
                            currentComponent.toasterredfun2("Please select an order");
                            return false;
                        }
                        /*var token = localStorage.getItem("UserId");*/
                        let euid = localStorage.getItem("Euid");
                        /*if (token == null || token.length == 0) {
                            this.props.history.push('/');
                        }
                        var user = jwt_decode(token);
                        var userId = user.UserId;
                        if (userId.length == 0 || userId == null) {
                            currentComponent.props.history.push('/');
                        }*/
                        currentComponent.setState({ Loading: true });
                        await Api.Post('api/placeorder/GetDecryptedDocumentsByFacilityId', { "FacilityIdList": this.state.SelectedRows, "IsDownloadforNewResults": e.target.value == 'vdownloadnew' ? true : false }, currentComponent).then(function (result) {
                            if (result.statusCode == '100') {
                                result.customerAddedDocsList.map((doc, index) => {
                                    var formdata = new FormData();
                                    formdata.append("CompanyCode", localStorage.getItem("Companycode"));
                                    formdata.append("Name", doc.documentName);
                                    formdata.append("FilePath", doc.path);
                                    axios({
                                        method: 'post',
                                        responseType: 'arraybuffer', //Force to receive data in a Blob Format
                                        url: '/api/placeorder/GetDoc',
                                        data: formdata,
                                        headers: { "Companycode": localStorage.getItem("Companycode"), "Euid": euid }
                                    })
                                        .then(res => {
                                            let type = "";
                                            if (doc.documentType.toLowerCase() == "pdf") {
                                                type = 'application/pdf';
                                            }
                                            else if (doc.documentType.toLowerCase() == "jpg" || doc.documentType.toLowerCase() == "jpeg") {
                                                type = 'image/jpg';
                                            }
                                            else if (doc.documentType.toLowerCase() == "png") {
                                                type = 'image/png';
                                            }
                                            else if (doc.documentType.toLowerCase() == "tif" || doc.documentType.toLowerCase() == "tiff") {
                                                type = 'image/tiff';
                                            }
                                            let fileName = doc.documentName;
                                            const blob = new Blob([res.data], {
                                                type: type
                                            });

                                            var filesaver = FileSaver(blob, fileName);
                                            filesaver.onwriteend = function () { }
                                            //currentComponent.setState({ url: res.data });
                                        })
                                        .catch(error => {
                                        });
                                });
                                setTimeout(() => { currentComponent.getResultsList(); }, 5000);
                            }
                            else if (result.statusCode == '413') {
                                currentComponent.toasterredfun2(result.statusMessage);
                                currentComponent.setState({ Loading: false });
                            }
                            else if (result.statusCode == '416') {
                                currentComponent.toasterredfun2(result.statusMessage);
                                currentComponent.setState({ Loading: false });
                            }
                            else if (result == null || result.statusCode === 429) {
                                currentComponent.setState({ loading: false });
                                currentComponent.toasterredfun2("The number of consecutive mouse clicks exceeded. Please refresh the page or wait 7 seconds and try again.");
                            }

                        }).catch(function (error) {
                            currentComponent.setState({ Loading: false });
                            if (error == "TypeError: Failed to fetch") {
                                currentComponent.toasterredfun2("Check your internet connection.");
                            }
                            else {
                                currentComponent.toasterredfun2(error);
                            }
                        });
                    }
                } else {
                    this.toasterredfun2("You have no access to download. Contact Administrator.");
                }

            } else {
                this.toasterredfun2("You have no access to download. Contact Administrator.");
            }


        }
        else {
            currentComponent.toasterredfun2("Please select an order");
            return false;
        }
    }
    onOpenclosePopup(e) {
        this.setState({ url: "" });
        this.getResultsList();
        this.setState({ OrderFacilityIdTemp: "" })
        this.setState({ isModalopen: false });
    }
    onOpenclosePopup() {
        this.setState({ OrderFacilityIdTemp: "" })
        this.setState({ isModalopen: false });
        this.getResultsList();
    }
    async onOpenPopup(facilityid, isDownload, orderOfficeId) {
        var DownloadAccess = await this.CheckForIsDownload(orderOfficeId, isDownload);
        if (this.state.IsOrderOfficeTemp) {
            if (DownloadAccess) {
                //document.getElementsByClassName("document-modal")[0].innerHTML = "";
                let currentComp = this;
                //var facilityid = '13';
                currentComp.setState({ OrderFacilityIdTemp: facilityid })
                /* var jtoken = localStorage.getItem("UserId");
                 if (jtoken == null || jtoken.length == 0) {
                     this.props.history.push('/');
                 }
                 var user = jwt_decode(jtoken);
                 var userId = user.UserId;
                 if (userId.length == 0 || userId == null) {
                     this.props.history.push('/');
                 }*/
                currentComp.setState({ Loading: true });
                await Api.Post('api/placeorder/GetDocumentsByFacilityId', { "FacilityId": facilityid }, currentComp).then(function (result) {
                    //currentComp.getResultsList();
                    currentComp.setState({ Files: result.customerAddedDocsList, isModalopen: true, Loading: false });
                    currentComp.renderDocumentPopup();
                }).catch(function (error) {
                    currentComp.setState({ Loading: false });
                    if (error == "TypeError: Failed to fetch") {
                        currentComp.toasterredfun2("Check your internet connection.");
                    }
                    else {
                        currentComp.toasterredfun2(error);
                    }
                });
            }

        }
        else {
            this.toasterredfun2("You have no access to download. Contact Administrator.");
        }
    }
    async onDownload(facilityid, isDownload, orderOfficeId) {
        var DownloadAccess = await this.CheckForIsDownload(orderOfficeId, isDownload);
        if (this.state.IsOrderOfficeTemp) {
            if (DownloadAccess) {
                let currentComponent = this;
                //var jtoken = localStorage.getItem("UserId");
                let euid = localStorage.getItem("Euid");
                /*if (jtoken == null || jtoken.length == 0) {
                    this.props.history.push('/');
                }
                var token = localStorage.getItem("UserId");
                if (token == null || token.length == 0) {
                    this.props.history.push('/');
                }
                var user = jwt_decode(jtoken);
                var userId = user.UserId;
                if (userId.length == 0 || userId == null) {
                    this.props.history.push('/');
                }*/
                currentComponent.setState({ Loading: true });
                await Api.Post('api/placeorder/GetDecryptedDocumentsByFacilityId', { "FacilityId": facilityid }, currentComponent).then(function (response) {
                    if (response.statusCode == '100') {
                        currentComponent.setState({ Loading: true });
                        //$(".onconfirm").click();
                        response.customerAddedDocsList.map((doc, index) => {
                            var formdata = new FormData();
                            formdata.append("CompanyCode", localStorage.getItem("Companycode"));
                            formdata.append("Name", doc.documentName);
                            formdata.append("FilePath", doc.path);
                            axios({
                                method: 'post',
                                responseType: 'arraybuffer', //Force to receive data in a Blob Format
                                url: '/api/placeorder/GetDoc',
                                data: formdata,
                                headers: { "Companycode": localStorage.getItem("Companycode"), "Euid": euid }
                            })
                                .then(res => {
                                    let type = "";
                                    if (doc.documentType.toLowerCase() == "pdf") {
                                        type = 'application/pdf';
                                    }
                                    else if (doc.documentType.toLowerCase() == "jpg" || doc.documentType.toLowerCase() == "jpeg") {
                                        type = 'image/jpg';
                                    }
                                    else if (doc.documentType.toLowerCase() == "png") {
                                        type = 'image/png';
                                    }
                                    else if (doc.documentType.toLowerCase() == "tif" || doc.documentType.toLowerCase() == "tiff") {
                                        type = 'image/tiff';
                                    }
                                    let fileName = doc.documentName;

                                    const blob = new Blob([res.data], {
                                        type: type
                                    });
                                    FileSaver(blob, fileName);
                                })
                                .catch(error => {
                                });
                            setTimeout(() => { currentComponent.getResultsList(); }, 5000);
                        })
                    }
                    else if (response.statusCode == '413') {
                        currentComponent.toasterredfun2(response.statusMessage);
                        currentComponent.setState({ Loading: false });
                    }
                }).catch(function (error) {
                    currentComponent.setState({ Loading: false });
                    if (error == "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun2("Check your internet connection.");
                    }
                    else {
                        currentComponent.toasterredfun2(error);
                    }
                });

                //fetch('api/placeorder/GetDecryptedDocumentsByFacilityId', {
                //    method: 'post',
                //    headers: {
                //        Accept: 'application/json',
                //        'Content-Type': 'application/json',
                //        "Authorization": "Bearer " + token,
                //    },
                //    body: JSON.stringify({ "FacilityId": facilityid, "UserId": userId })
                //}).then(function (response) {
                //    if (response.status == 401) {
                //        currentComponent.props.history.push('/');
                //    }
                //    else { return response.json(); }
                //}).then(function (response) {
                //    //currentComponent.setState({ loading: false });
                //    if (response.statusCode == '100') {
                //        //$(".onconfirm").click();
                //        response.customerAddedDocsList.map((doc, index) => {
                //            var documentBs64 = response.customerAddedDocsList[index].base64;

                //            const linkSrc = `data:${response.mimeType};base64,${documentBs64}`;
                //            const dwnldLink = document.createElement("a");
                //            const fileName = response.customerAddedDocsList[index].documentName;
                //            dwnldLink.href = linkSrc;
                //            dwnldLink.download = fileName;
                //            dwnldLink.click();
                //        })

                //        $(".onconfirm").click();
                //    }
                //    if (response.statusCode == '413') {
                //        currentComponent.toasterredfun2(response.statusMessage);
                //    }
                //}).catch(function (error) {
                //    currentComponent.setState({ Loading: false });
                //    if (error == "TypeError: Failed to fetch") {
                //        currentComponent.toasterredfun2("Check your internet connection.");
                //    }
                //    else {
                //        currentComponent.toasterredfun2(error);
                //    }
                //});            
            }
        }
        else {
            this.toasterredfun2("You have no access to download. Contact Administrator.");
        }
    }
    renderDocumentPopup() {
        if (this.state.Files.length != 0) {
            return (<div className="form-row">
                {this.state.Files.map((doc, index) => {
                    var filename = "", ext = doc.documentName.split('.')[doc.documentName.split('.').length - 1];
                    var authDocId = doc.id;
                    if (ext.toLowerCase() == "pdf") {
                        filename = "Records";
                    }
                    else {
                        filename = ext;
                    }
                    return (<div className="col-md-4 col-lg-3">
                        <div className="form-group mat-div-xd mb-3" style={{ "cursor": "pointer" }} onClick={(e) => { this.onClickopenEditPopup(doc.path, ext, authDocId) }} >
                            <div className="text-center">
                                <i className='fas fa-envelope-open-text'></i>
                            </div>
                            <div key={index} className="text-center icon-tx-x text-break"> {filename}</div>
                            {!isNullOrUndefined(doc.createdOnDateTime) ? (
                                <div className="text-center icon-tx-x"> {Moment(doc.createdOnDateTime).format('MM/DD/yyyy')} {Moment(doc.createdOnDateTime).format('hh:mm A')}</div>
                            ) : (
                                <div className="text-center icon-tx-x"> </div>
                            )}
                        </div>
                    </div>)
                })} </div>)
        }
        else {
            return (<div> No Document Found</div >)
        }
    }
    handleKeypress(event) {
        var key = event.which || event.key;
        if (key == 13) {
            this.handleSubmitSearch();
        }
    }
    onKeyPressValueStartDate(event) {
        var length = event.target.value.length;
        if (length == 1) {
            var n = event.target.value.includes("/");
            if (n) {
                this.setState({ StartDate: "" });
            }
        }
        if (length == 2) {
            var n = event.target.value.includes("/");
            if (n) {
                event.target.value = "0" + event.target.value;
            }
        }
        if (length == 5) {
            var n = event.target.value.slice(3, 5).includes("/");
            if (n) {
                event.target.value = [event.target.value.slice(0, 3), "0", event.target.value.slice(3)].join();
            }
        }
        event.target.value = event.target.value
            .replace(/^(\d\d)(\d)$/g, "$1/$2")
            .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
            .replace(/[^\d\/]/g, "");
        if (length > 10) {
            event.stopPropagation();
        }
        this.handleKeypress(event);
    }
    Onclickexportpopup() {
        let currentComponent = this;
        currentComponent.setState({ exportAlert: false, Loading: false });
    }
    onKeyPressValueEndDate(event) {
        var length = event.target.value.length;
        if (length == 1) {
            var n = event.target.value.includes("/");
            if (n) {
                this.setState({ EndDate: "" });
            }
        }
        if (length == 2) {
            var n = event.target.value.includes("/");
            if (n) {
                event.target.value = "0" + event.target.value;
            }
        }
        if (length == 5) {
            var n = event.target.value.slice(3, 5).includes("/");
            if (n) {
                event.target.value = [event.target.value.slice(0, 3), "0", event.target.value.slice(3)].join();
            }
        }
        event.target.value = event.target.value
            .replace(/^(\d\d)(\d)$/g, "$1/$2")
            .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
            .replace(/[^\d\/]/g, "");
        if (length > 10) {
            event.stopPropagation();
        }
        this.handleKeypress(event);
    }
    handleColumnHeaderClick = (column) => {
        if (this.state.SearchList.length != 0)
        {
            let offSet = (this.state.currentPage) * this.state.PageLimit;            
            if (this.state.sortColumn === column.field) {
                const newSortOrder = this.state.sortOrder === 'asc' ? 'desc' : 'asc';
                this.setState({
                    sortOrder: newSortOrder,
                    sortColumn: column.field,
                });
                this.getGridValues(this.state.UserId, offSet, this.state.PageLimit, column.field, newSortOrder);
            } else {
                this.setState({
                    sortColumn: column.field,
                    sortOrder: 'asc',
                });
                this.getGridValues(this.state.UserId, offSet, this.state.PageLimit, column.field, "asc");
            }
        }       
    } 
    formatDateToString(dt) {
        if (dt.toString().length != 0) {
            var date = new Date(dt);
            var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
            var MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
            var yyyy = date.getFullYear();
            return (MM + "/" + dd + "/" + yyyy);
        }
        else {
            return "";
        }
    }
    render() {
        var landing = 'img/ems-logo.gif';
        var companyType = localStorage.getItem("CompanyType");
        let columns = [
         
            { title: 'Order Date', field: 'createdOnDateTime', type: 'date' },
            { title: 'Publish Date', field: 'uploadDate', type: 'date' },
            { title: 'eNoah Order Number', field: 'eNoahOrderId' },
            { title: 'Patient ' + this.state.lblFirstName, field: 'patientFirstName', hidden: this.state.FirstNameHide === false },
            { title: 'Patient ' + this.state.lblLastName, field: 'patientLastName', hidden: this.state.LastNameHide === false },
            { title: this.state.lblDoctorFirstName, field: 'doctorFirstName', hidden: this.state.DoctorFirstNameHide === false },
            { title: this.state.lblDoctorLastName, field: 'doctorLastName', hidden: this.state.DoctorLastNameHide === false },
            { title: this.state.lblFacilityName, field: 'facilityName', hidden: this.state.FacilityNameHide === false },
            { title: this.state.lblPolicyNum, field: 'patientPolicyNumber', hidden: this.state.PolicyNumHide === false },
            { title: 'Request Type', field: 'requestType', hidden: companyType === "LIFE" },
            { title: 'Certified', field: 'certified', hidden: companyType === "LIFE" },
            { title: 'Order Type', field: 'orderType', hidden: companyType === "LIFE" },
            { title: 'Requestor Name ', field: 'requesterName' },
            { title: 'Status ', field: 'statusBy' },
            { title: 'No. of Results', field: 'numberOfResults' },
            { title: 'Viewed By ', field: 'viewedBy' },
            { title: 'Id ', field: 'id', hidden: true },
            { title: 'OrderOfficeId ', field: 'orderOfficeId', hidden: true },
            {
                title: 'Action ', field: 'action', disableClick: true, disableSorting: true, render: rowData => <span><span className="btnm-xs" onClick={(e) => { this.onOpenPopup(rowData.id, rowData.isDownload, rowData.orderOfficeId) }} ><img src="img/view.png" /></span>
                    <span className="btnm-xs" onClick={(e) => { this.onDownload(rowData.id, rowData.isDownload, rowData.orderOfficeId) }}><img src="img/download.png" /> </span> </span>
            }
        ];
        let visibleColumns = (columns.filter((column) => !column.hidden));
        return (
            <Layout>
                <TimeoutCounter />
                <span className={"loading " + (this.state.Loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} /> </span>
                <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                <div className={"removeAlert-divbrowser " + (this.state.exportAlert ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x text-center">
                        <img src="img/error.png" alt="Error" />
                        <p className="my-3"> Your permissions has been modified/revoked to perform this action. Please contact your administrator.</p>
                        <div className="text-center-div m-0">
                            <div className="bttn popupButton m-0" onClick={this.Onclickexportpopup}>
                                Ok
                            </div>
                        </div>
                    </div>
                </div>
                <div className='hgtDiv bg-white mt-2 p-3'>
                    <div className="">
                        <div className='row'>
                            <div className='border-md col-xl-8'>
                                <div className="col-xl-12 pull-left mt-2">
                                    <div className="mb-1">
                                        <div className="">
                                            <div className="form-row">
                                                <div className="col-md-12">
                                                    <div className="form-row-xm">
                                                        <div className="col-md-2 pull-left nopadding">
                                                            <span className="result-title-xds">Selection </span>
                                                        </div>
                                                        <div className="col-md-10 pull-left nopadding">
                                                            <div className="form-group pull-left mr-6 nopadding radioLable-x">
                                                                <input type="radio" id="SelectionType" name="customedate1" value="MyOrders" onChange={this.handleSTChange} checked={this.state.SearchType == "MyOrders"} />
                                                                <label className="small" htmlFor="SelectionType">My Orders</label>
                                                            </div>
                                                            <div className="form-group pull-left mr-6 radioLable-x">
                                                                <input type="radio" id="SelectionType" name="customedate1" value="AllOfficeOrders" onChange={this.handleSTChange} checked={this.state.SearchType == "AllOfficeOrders"} />
                                                                <label className="small" htmlFor="SelectionType">All Orders</label>
                                                            </div>
                                                            <div className="form-group pull-left w-50">
                                                                <MultiSelect className="form-control" style={{ "z-index": "9999" }}
                                                                    options={this.state.orderingOfficeAccessList}
                                                                    value={this.state.selectedMultiItem}
                                                                    onChange={this.setSelectedfun}
                                                                    labelledBy={"Select Office"}
                                                                    hasSelectAll={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-row-xm">
                                                        <div className="col-md-2 pull-left nopadding">
                                                            <span className="result-title-xds">View </span>
                                                        </div>
                                                        <div className="col-md-10 pull-left nopadding">
                                                            <div className="form-group pull-left mr-35 nopadding radioLable-x">
                                                                <input type="radio" id="ViewType" name="customedate2" value="NewResults" onChange={this.handleVTChange} checked={this.state.ViewType == "NewResults"} />
                                                                <label className="small" htmlFor="ViewType">New Results</label>
                                                            </div>
                                                            <div className="form-group pull-left mr-6 radioLable-x">
                                                                <input type="radio" id="ViewType" name="customedate2" value="AllResults" onChange={this.handleVTChange} checked={this.state.ViewType == "AllResults"} />
                                                                <label className="small" htmlFor="ViewType">All Results</label>
                                                            </div>
                                                            <div className="form-group pull-left mr-6 radioLable-x">
                                                                <input type="radio" id="ViewType" name="customedate2" value="ViewByMe" onChange={this.handleVTChange} checked={this.state.ViewType == "ViewByMe"} />
                                                                <label className="small" htmlFor="ViewType">Viewed By Me </label>
                                                            </div>
                                                            <div className="form-group pull-left mr-6 radioLable-x">
                                                                <input type="radio" id="ViewType" name="customedate2" value="ViewByAnotherUser" onChange={this.handleVTChange} checked={this.state.ViewType == "ViewByAnotherUser"} />
                                                                <label className="small" htmlFor="ViewType">Viewed By Another User </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 nopadding">
                                                    <div className="form-row-xm">
                                                        <div className="col-md-2 nopadding pull-left">
                                                            <span className="result-title-xds"> Date Range </span>
                                                        </div>
                                                        <div className="col-md-10 pull-left nopadding">
                                                            <div className="form-group pull-left mr-6 nopadding radioLable-x">
                                                                <input type="radio" id="DateType" name="customedate3" value="OrderDate" onChange={this.handleDateChange} checked={this.state.DateType == "OrderDate"} />
                                                                <label className="small" htmlFor="DateType">Order Date</label>

                                                            </div>
                                                            <div className="form-group pull-left mr-6 radioLable-x">
                                                                <input type="radio" id="DateType" name="customedate3" value="PublishDate" onChange={this.handleDateChange} checked={this.state.DateType == "PublishDate"} />
                                                                <label className="small" data-tip data-for="publishdate" htmlFor="DateType">Publish Date</label>
                                                                <ReactTooltip id="publishdate" place="top" effect="solid">
                                                                    'Publish Date' is the date the records were available on the portal.
                                                                </ReactTooltip>
                                                            </div>
                                                            <div className="col-md-3 nopadding pull-left">
                                                                <div className="form-group pull-left mr-35">
                                                                    <div className='input-group dop-d result-date result-calendar-icon-startdate'>
                                                                        <label className="small mr-1 mtop7" htmlFor="last5years">Start Date</label>
                                                                        <DatePickerInput selected={this.state.StartDate} id="sdate" autoComplete="off" maxLength="10" onKeyPress={this.onKeyPressValueStartDate.bind(this)} value={this.state.StartDate} onChange={this.handleSDChange} dateformat="MM/dd/yyyy" className={"form-control " + (this.state.dosd ? "redborder" : "blackborder")} maxDate={new Date()} minDate={new Date(1920, 1, 1)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3 nopadding pull-left">
                                                                <div className="form-group pull-left mr-35">
                                                                    <div className='input-group dop-d result-date result-calendar-icon-enddate' id='datetimepicker1'>
                                                                        <label className="small mr-1 mtop7" htmlFor="last5years">End Date</label>
                                                                        <DatePickerInput selected={this.state.EndDate} id="edate" autoComplete="off" maxLength="10" onKeyPress={this.onKeyPressValueEndDate.bind(this)} onChange={this.handleEDChange} value={this.state.EndDate} dateformat="MM/dd/yyyy" className={"form-control " + (this.state.doed ? "redborder" : "blackborder")} maxDate={new Date()} minDate={new Date(1920, 1, 1)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-1 pull-left mt-2">
                                                    <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 " onClick={this.handleClear}>Clear</button>
                                                    <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1 onconfirm" onClick={this.handleSubmitSearch}>Search </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-4 normal-data">
                            <div className="card-body posrelative ">
                                <div className="table-responsive dataTable unsubmitted  hidesearch eport-ml selectOption">
                                    <div className="export-div-xxm">
                                        <button className="export-class">Export to Excel</button>
                                    </div>
                                    <div className="export-div-xxc">
                                        <button className="export-class " data-tip data-for="downloadanew" onClick={this.handleDownloadSelected} value="vdownloadnew">Download New</button>
                                        <ReactTooltip id="downloadanew" effect="solid" multiline={true} place="top">
                                            This will download only the new <br /> results, not previously viewed or <br /> downloaded results for the cases <br /> selected below
                                        </ReactTooltip>
                                    </div>
                                    <div className="export-div-xxd">
                                        <button className="export-class width170" data-tip data-for="downloadall" onClick={this.handleDownloadSelected} value="vdownloadall">Download All</button>
                                        <ReactTooltip id="downloadall" effect="solid" multiline={true} place="top">
                                            This will download all results, including <br /> new and previously viewed or <br /> downloaded results for the cases <br /> selected below.
                                        </ReactTooltip>
                                    </div>
                                    <div className="col-md-2 searchoff nopadding">
                                        <span className="show-title">Show</span>
                                    </div>
                                    <MaterialTable
                                        title=""
                                        tableRef={this.tableRef}
                                        onRowClick={(event, rowData) => {
                                            localStorage.setItem('PrevPage', 'result');
                                            let orderingOfficeOptionsList = JSON.stringify(this.state.orderingOfficeAccessList);
                                            let selectedOrderingOfficeList = JSON.stringify(this.state.selectedMultiItem);                                           
                                            let selectedRows = JSON.stringify(this.state.SelectedRows);
                                            localStorage.setItem('SelectedRows', selectedRows);
                                            localStorage.setItem('OrderingOfficeOptionsList', orderingOfficeOptionsList);
                                            localStorage.setItem('SelectedOrderingOfficeList', selectedOrderingOfficeList);
                                            localStorage.setItem('SearchType', this.state.SearchType);
                                            localStorage.setItem('ViewType', this.state.ViewType);
                                            localStorage.setItem('DateType', this.state.DateType);
                                            localStorage.setItem('StartDate', this.state.StartDate);
                                            localStorage.setItem('EndDate', this.state.EndDate);
                                            localStorage.setItem('CurrentPage', this.state.currentPage);
                                            localStorage.setItem('ResultsSortColumn', this.state.sortColumn);
                                            localStorage.setItem('ResultsSortOrder', this.state.sortOrder);
                                            this.props.history.push('/case-details?id=' + rowData.encId);
                                        }}
                                        className="dataTable"
                                        localization={{ body: { emptyDataSourceMessage: <p>{this.state.emptyDataSourceMessage}</p> } }}
                                        columns={columns}                             
                                        data={this.state.SearchList}
                                        onSelectionChange={async (rows, rowData) => {
                                           

                                            if (rowData.isDownload) {
                                                if (rows.length == this.state.PageLimit && isNullOrUndefined(rowData)) {
                                                    var isExport = await this.GetDataForSelectAll();
                                                    if (isExport) {
                                                        if (this.state.dataForSelectAll.length > 0) {
                                                            this.state.SelectedRows.length = 0;
                                                            for (let n = 0; n < this.state.dataForSelectAll.length; n++) {
                                                                this.state.SelectedRows.push(this.state.dataForSelectAll[n].id);
                                                            }
                                                        }
                                                    }
                                                }
                                                else if (rows.length == 0 && isNullOrUndefined(rowData)) {
                                                    this.state.SelectedRows.length = 0;
                                                }
                                                else {
                                                    if (rows.length > 0) {
                                                        if (this.state.SelectedRows.length > 0) {
                                                            for (let j = 0; j < rows.length; j++) {
                                                                var index = this.state.SelectedRows.indexOf(rows[j].id);
                                                                if (index == -1) {
                                                                    this.state.SelectedRows.push(rows[j].id);
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            for (let k = 0; k < rows.length; k++) {
                                                                this.state.SelectedRows.push(rows[k].id);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            else {
                                                this.toasterredfun2("You have no access to download. Contact Administrator.");
                                            }
                                        }
                                        }
                                        options={{
                                            exportButton: true,
                                            selection: true,
                                            exportAllData: true,
                                            paginationType: "stepped",
                                            labelRowsPerPage: false,
                                            selectionProps: (rowData) => {                                        
                                                if (rowData.isDownload) {
                                                    for (let i = 0; i < this.state.SelectedRows.length; i++) {
                                                        if (rowData.id == this.state.SelectedRows[i]) {
                                                            if (rowData.tableData.checked || rowData.tableData.checked == false) {
                                                                if (rowData.tableData.checked == false) {
                                                                    var index = this.state.SelectedRows.indexOf(rowData.id)
                                                                    if (index !== -1) {
                                                                        this.state.SelectedRows.splice(index, 1);
                                                                        this.setState({ SelectedRows: this.state.SelectedRows });
                                                                    }
                                                                    return { checked: false }
                                                                }
                                                                else {
                                                                    return { checked: true }
                                                                }
                                                            }
                                                            else {
                                                                rowData.tableData = { id: rowData.tableData.id, checked: true }
                                                                return { checked: true }
                                                            }
                                                        }
                                                    }
                                                }
                                                else {
                                                    rowData.tableData = { id: rowData.tableData.id, checked: false }
                                                    return { checked: false }
                                                }
                                            },
                                            exportCsv: async (data, columns) => {
                                                var isExport = await this.exportGridValues();
                                                if (this.state.hideExportButton) {
                                                    if (isExport) {
                                                        if (this.state.dataForExport.length > 0) {
                                                            const columnTitles = data.filter(a => a.hidden != true).filter(a => a.hidden != true && a.field.toLowerCase() != 'numberofresults' && a.field.toLowerCase() != 'action').map(columnDef => columnDef.title);
                                                            const csvData = this.state.dataForExport.map(rowData =>
                                                                data.filter(a => a.hidden != true && a.field.toLowerCase() != 'numberofresults').map(columnDef => rowData[columnDef.field]),
                                                            );
                                                            const builder = new CsvBuilder(`PendingOrderList.csv`)
                                                                .setColumns(columnTitles)
                                                                .addRows(csvData)
                                                                .exportFile();
                                                            return builder;
                                                        }
                                                    }
                                                }
                                                else {
                                                    this.toasterredfun2("You have no access to download. Contact Administrator.");
                                                }
                                            },
                                            exportPdf: async (data, columns) => {
                                                var isExport = await this.exportGridValues();
                                                if (this.state.hideExportButton) {
                                                    if (isExport) {                                                      
                                                        if (this.state.dataForExport.length > 0) {
                                                            const unit = "pt";
                                                            const size = "A4"; // Use A1, A2, A3 or A4
                                                            const orientation = "landscape"; // portrait or landscape
                                                            const doc = new jsPDF(orientation, unit, size);
                                                            doc.text("Completed Order List", 40, 35);
                                                            const columnTitles = data.filter(a => a.hidden != true && a.field.toLowerCase() != 'action' && a.field.toLowerCase() != 'numberofresults').map(columnDef => columnDef.title);
                                                            const pdfData = this.state.dataForExport.map(rowData =>
                                                                data.filter(a => a.hidden != true && a.field.toLowerCase() != 'numberofresults').map(columnDef => rowData[columnDef.field]),
                                                            );
                                                            if (companyType == 'LIFE') {
                                                                doc.autoTable({
                                                                    styles: {
                                                                        cellPadding: 2,
                                                                        cellWidth: 'auto'
                                                                    },
                                                                    bodyStyles: {
                                                                        cellPadding: 2,
                                                                        margin: 40,
                                                                    },
                                                                    columnStyles: {
                                                                        0: { cellWidth: 65 },
                                                                        1: { cellWidth: 65 },
                                                                        2: { cellWidth: 65 },
                                                                        3: { cellWidth: 65 },
                                                                        4: { cellWidth: 65 },
                                                                        5: { cellWidth: 65 },
                                                                        6: { cellWidth: 65 },
                                                                        7: { cellWidth: 65 },
                                                                        8: { cellWidth: 65 },
                                                                        9: { cellWidth: 65 },
                                                                        10: { cellWidth: 65 },
                                                                        11: { cellWidth: 65 },
                                                                        12: { cellWidth: 65 },
                                                                        13: { cellWidth: 65 },

                                                                        text: {
                                                                            cellWidth: 'wrap'
                                                                        }
                                                                    },
                                                                    head: [columnTitles],
                                                                    body: pdfData
                                                                });
                                                            }
                                                            else {
                                                                doc.autoTable({
                                                                    styles: {
                                                                        cellPadding: 2,
                                                                        cellWidth: 'auto'
                                                                    },
                                                                    bodyStyles: {
                                                                        cellPadding: 2,
                                                                        margin: 40,
                                                                    },
                                                                    columnStyles: {
                                                                        0: { cellWidth: 50 },
                                                                        1: { cellWidth: 50 },
                                                                        2: { cellWidth: 50 },
                                                                        3: { cellWidth: 50 },
                                                                        4: { cellWidth: 50 },
                                                                        5: { cellWidth: 50 },
                                                                        6: { cellWidth: 50 },
                                                                        7: { cellWidth: 50 },
                                                                        8: { cellWidth: 50 },
                                                                        9: { cellWidth: 50 },
                                                                        10: { cellWidth: 50 },
                                                                        11: { cellWidth: 50 },
                                                                        12: { cellWidth: 50 },
                                                                        13: { cellWidth: 50 },
                                                                        text: {
                                                                            cellWidth: 'wrap'
                                                                        }
                                                                    },
                                                                    head: [columnTitles],
                                                                    body: pdfData
                                                                });
                                                            }

                                                            doc.save(`CompletedOrderList.pdf`);
                                                        }
                                                    }
                                                }
                                                else {
                                                    this.toasterredfun2("You have no access to download. Contact Administrator.");
                                                }
                                            }
                                        }}
                                        components={{
                                            Header: (props) => (
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                onChange={(event) => {
                                                                    debugger
                                                                    let list = this.state.SearchList
                                                                    const selectedRows = list.map((row) => row.id);
                                                                    if (event.target.checked) {
                                                                        props.onAllSelected(true, selectedRows);
                                                                    } else {
                                                                        props.onAllSelected(false, selectedRows);
                                                                    }
                                                                }}

                                                            />
                                                        </TableCell>
                                                        {visibleColumns.map((column, index) => (
                                                            <TableCell
                                                                key={column.field}

                                                            >
                                                                {column.disableSorting ? (
                                                                    <span>{column.title}</span>
                                                                ) : (
                                                                    <TableSortLabel
                                                                        active={this.state.sortColumn === column.field}
                                                                        direction={this.state.sortColumn === column.field ? this.state.sortOrder : 'asc'}
                                                                        onClick={() => this.handleColumnHeaderClick(column)}
                                                                    >
                                                                        {column.title}
                                                                    </TableSortLabel>
                                                                )}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                            ),
                                            Pagination: props => (
                                                <TablePagination
                                                    {...props}
                                                    rowsPerPageOptions={[5, 10, 20]}
                                                    rowsPerPage={this.state.PageLimit}
                                                    count={this.state.totalRecord}
                                                    page={this.state.currentPage}
                                                    onChangeRowsPerPage={event => {
                                                        props.onChangeRowsPerPage(event);
                                                        this.setState({ PageLimit: event.target.value, currentPage: 0 });
                                                        localStorage.setItem('ClickedPageInResult', 1);                                                        
                                                        this.getGridValues(this.state.UserId, 0, event.target.value, this.state.sortColumn, this.state.sortOrder)
                                                    }}
                                                    onChangePage={(event, page) => {
                                                        let ClickedButton = event.nativeEvent.target.innerText;
                                                        localStorage.setItem('ClickedPageInResult', ClickedButton)
                                                        if (ClickedButton == "first_page") {
                                                            this.setState({ currentPage: 0 })
                                                            this.getGridValues(this.state.UserId, 0, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                        }
                                                        else if (ClickedButton == "chevron_left") {
                                                            this.setState({ currentPage: this.state.currentPage - 1 })
                                                            let Chevronleftvalue = this.state.currentPage - 1
                                                            localStorage.setItem('ChevronleftInResult', Chevronleftvalue)
                                                            let offSet = (this.state.currentPage - 1) * this.state.PageLimit;
                                                            this.getGridValues(this.state.UserId, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                        }
                                                        else if (ClickedButton == "chevron_right") {
                                                            this.setState({ currentPage: this.state.currentPage + 1 })                                                           
                                                            let Chevronrightvalue = this.state.currentPage + 1
                                                            localStorage.setItem('ChevronrightInResult', Chevronrightvalue)
                                                            let offSet = (this.state.currentPage + 1) * this.state.PageLimit;
                                                            this.getGridValues(this.state.UserId, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                        }
                                                        else if (ClickedButton == "last_page") {
                                                            var lp = this.state.totalRecord / this.state.PageLimit;
                                                            lp = Math.ceil(lp) - 1;
                                                            let offSet;
                                                            if (lp == 0) {
                                                                this.setState({ currentPage: 1 })
                                                                offSet = this.state.PageLimit
                                                            }
                                                            else {
                                                                this.setState({ currentPage: lp })
                                                                offSet = lp * this.state.PageLimit
                                                            }
                                                            this.getGridValues(this.state.UserId, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                        }
                                                        else {
                                                            let offSet;
                                                            if (ClickedButton > this.state.currentPage) {
                                                                this.setState({ currentPage: this.state.currentPage + 1 })
                                                                offSet = (this.state.currentPage + 1) * this.state.PageLimit;
                                                            }
                                                            else {
                                                                this.setState({ currentPage: this.state.currentPage - 1 })
                                                                offSet = (this.state.currentPage - 1) * this.state.PageLimit;
                                                            }
                                                            this.getGridValues(this.state.UserId, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                        }
                                                    }}
                                                />
                                            ),
                                        }}                                      

                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"modal " + (this.state.isModalopen ? "show" : "hide")} id="myModal3">
                    <span className="alert-bg-div-x"></span>
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Documents
                                </h4>
                                <button type="button" className="close" onClick={this.onOpenclosePopup} data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body p-3">
                                {this.renderDocumentPopup()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"modal " + (this.state.isEditModalopen ? "show" : "hide")} id="editDoc">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content h-96">
                            <div className="modal-header">
                                <h4 className="modal-title">Edit Document
                                </h4>
                                <button type="button" className="close" onClick={this.onClickclosePopup} data-dismiss="modal">&times;</button>
                            </div>
                            <h6 className="edit-document"></h6>
                            <div className="modal-body document-modal">
                                <div className="holds-the-iframe">
                                    <iframe src={this.state.url} scrolling="yes" title={this.state.pdftitle} className="iframewidth">
                                        <p>Your browser does not support iframes.</p>
                                    </iframe>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
