import React, { Component } from 'react';
import Api from '../serviceCall';
import $ from 'jquery';

export class ErrorDetails extends Component {
    static displayName = ErrorDetails.name;
    constructor(props) {
        super(props);
        this.sate = {
            selectedFile: null
        };
    }

    // On file select (from the pop up) 
    onFileChange = event => {
        // Update the state 
        this.setState({ selectedFile: event.target.files[0] });
        //this.fileData();
    };

    // On file upload (click the upload button) 
    onFileUpload = () => {

        // Create an object of formData 
        const formData = new FormData();

        // Update the formData object 
        formData.append(
            "myFile",
            this.state.selectedFile,
            this.state.selectedFile.name
        );

        // Details of the uploaded file 
        //console.log(this.state.selectedFile);

        $.ajax({
            url: '/services/api/FileUpload',
            method: 'POST',
            contentType: false,
            processData: false,
            data: formData,
            success: function () {
                window.location = '/services/api/DownloadFile';
            },
            error: function (err) {
               console.log(err);
                alert(err.responseText);
            }
        })
    };
    
    render() {
        return (
            <div className="container">
                <br />
                    <hr/>
                <h3>
                    Choose the file to convert as xlsx file:
                 </h3>
                <div>
                    <input type="file" onChange={this.onFileChange} />
                    <button className="btn btn-primary" onClick={this.onFileUpload}>
                        Upload!
                </button>
                </div>

                <hr />
            </div>
        );
    }
}
