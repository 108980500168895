import React, { Component } from 'react';
import { browserName, browserVersion, deviceDetect } from "react-device-detect";
import { isNull, isNullOrUndefined } from 'util';
import Api from '../serviceCall';
import IdleTimer from 'react-idle-timer';

export class TimeoutCounter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: {}, seconds: 0, popupShow: false, staticSeconds: 0, btnstayLogged: false,
            logoutSeconds: 0, UserId: "", CompanyCode: "", IsSSOUser: false, isFromCaseDetails: true,
            dHour: "", dMinute: "", dSeconds: "", euid: ""
        };
        this.idleTimer = null;
        this.logoutTimer = null;
        //this.stayLoggedPopup = this.stayLoggedPopup.bind(this);
        this.logoutuser = this.logoutuser.bind(this);
    }
    onIdle = () => {
        this.logoutuser();
        //this.logoutTimer = setTimeout(() => {
        //  this.handleLogout();
        //}, 1000 * this.state.logoutSeconds * 1); // customize seconds
    }
    /*    async togglePopup() {
            this.setState({ popupShow: true });
            if (window.location.pathname == '/place-order') {
                await this.props.willunMount();
                if (window.location.href.indexOf("?orderid") == -1) {
                    this.setState({ isFromCaseDetails: false });
                }
                else {
                    this.setState({ isFromCaseDetails: true });
                }
            }
        }
        stayLoggedPopup(e) {
            if (this.logoutTimer) {
                clearTimeout(this.logoutTimer);
                this.logoutTimer = null;
            }
            this.idleTimer.reset();
            this.setState({ popupShow: false });
        }
       handleLogout = () => {
            let currentComponent = this;
            if (currentComponent.logoutTimer) {
                clearTimeout(currentComponent.logoutTimer);
                currentComponent.logoutTimer = null;
            }
            currentComponent.idleTimer = null;
            currentComponent.setState({ btnstayLogged: true });
            this.UserLogoutTimeWithOutRedirection();
        }*/

    async logoutuser(e) {       
        let currentComponent = this;        
        var Euid = localStorage.getItem("Euid");     
        if (!isNullOrUndefined(Euid)) {
            localStorage.setItem('IsTimeOut', 'true');
        }
        var IsSSOUser = currentComponent.state.IsSSOUser;
        if (window.location.pathname == '/place-order') {
            //await this.props.willunMount();
            if (window.location.href.indexOf("?orderid") == -1) {
                this.setState({ isFromCaseDetails: false });
                localStorage.setItem('IsTimeOutFromPlaceOrder', 'true');
                localStorage.setItem('IsTimeOut', 'false');
            }
            else {
                localStorage.setItem('IsTimeOutFromPlaceOrder', 'false');
            }
        }
        else {
            localStorage.setItem('IsTimeOutFromPlaceOrder', 'false');
        }
        if (IsSSOUser == 'true') {
            currentComponent.SSOUserLogoutUrl();
        }
        else {
            await currentComponent.UserLogoutTimeWithOutRedirection();
            if (window.location.pathname == '/search') {
                window.location.href = "/";
            }
            else {
                currentComponent.props.history.push("/");
            }
        }
    }

  /*  UserLogoutTime() {
        let currentComponent = this;
        var IsSSOUser = currentComponent.state.IsSSOUser;
        var CompanyCode = currentComponent.state.CompanyCode;
        if (isNullOrUndefined(currentComponent.state.UserId)) {
            window.location.href = "/";
        }
        else if (!isNullOrUndefined(CompanyCode) && CompanyCode.toLowerCase() == 'primerica' && IsSSOUser == 'true') {
            currentComponent.SSOUserLogoutUrl();
        }
        else {
            let currentComponent = this;
            var CompanyCode = currentComponent.state.CompanyCode;
            let token = localStorage.getItem("UserId");
            let euid = localStorage.getItem("Euid");
            this.setState({ loading: true });
            fetch('api/login/UserLogout', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + token,
                    "Euid": euid,
                    "CompanyCode": CompanyCode,
                },
                body: JSON.stringify({
                    "CompanyCode": CompanyCode,
                    "BrowserType": deviceDetect().browserName,
                    "BrowserVersion": deviceDetect().browserFullVersion
                })
            }).then(function (response) {
                var IsSSOUser = currentComponent.state.IsSSOUser;
                if (IsSSOUser == 'true') {
                    currentComponent.SSOUserLogoutUrl();
                }
                else {
                    localStorage.clear();
                    window.location.href = "/";
                }
            })
        }
    }*/

    UserLogoutTimeWithOutRedirection() {
        let currentComponent = this;
        var IsSSOUser = currentComponent.state.IsSSOUser;
        var CompanyCode = currentComponent.state.CompanyCode;       
        let euid = localStorage.getItem("Euid");
        this.setState({ loading: true });
        fetch('api/login/UserLogout', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',                
                    "Euid": euid,
                    "CompanyCode": CompanyCode,
                },
                body: JSON.stringify({
                    "CompanyCode": CompanyCode,
                    "BrowserType": deviceDetect().browserName,
                    "BrowserVersion": deviceDetect().browserFullVersion
                })
            }).then(function (response) {
                window.location.href = "/";
                localStorage.clear();
            })
        
    }

    SSOUserLogoutUrl() {
        var CompanyCode = localStorage.getItem("Companycode");
        var euid = localStorage.getItem("Euid");   
        fetch('api/login/SSOUserLogout', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',               
                "Euid": euid,
                "CompanyCode": CompanyCode,
            },
            body: JSON.stringify({
                "CompanyCode": CompanyCode,
                "BrowserType": deviceDetect().browserName,
                "BrowserVersion": deviceDetect().browserFullVersion
            })
        }).then(response => response.json())
            .then(function (res) {
                localStorage.clear();
                if (!isNullOrUndefined(res.ssoLogOutURL)) {
                    window.location.href = res.ssoLogOutURL;
                }
                else {
                    window.location.href = "/LogoutSSO";
                }
            })
    }

    componentWillMount() {
        let currentComponent = this;
        fetch('services/api/GetVersionConfiguration', {
            method: 'Get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',

            },

        }).then(function (response) {
            return response.json();

        }).then(function (result) {            
            if (result.statusCode === 100) {
                var Logout_Time = currentComponent.SecondsToTime(result.logoutSeconds == "" ? "0" : result.logoutSeconds);
                var hours = 0;
                var minutes = 0;
                var seconds = 0;
                if (Logout_Time.length > 2) {
                    hours = Logout_Time[0];
                    minutes = Logout_Time[1];
                    seconds = Logout_Time[2];
                }
                currentComponent.setState({
                    seconds: result.logoutSeconds, staticSeconds: result.staticSeconds,
                    logoutSeconds: result.logoutSeconds, dHour: hours, dMinute: minutes,
                    dSeconds: seconds
                },
                    () => {
                        currentComponent.idleTimer.start();
                    }
                );
            }
        });
        currentComponent.setState({ CompanyCode: localStorage.getItem("Companycode"), euid :localStorage.getItem("Euid") });
        if (localStorage.getItem("IsSSOUser") !== null) {
            currentComponent.setState({ IsSSOUser: localStorage.getItem("IsSSOUser") });
        }
        else {
            currentComponent.setState({ IsSSOUser: false });
        }
    }

    SecondsToTime(Secs) {
        var hours = Math.floor(Secs / (60 * 60));
        var divisor_for_minutes = Secs % (60 * 60);
        var minutes = Math.floor(divisor_for_minutes / 60);
        var divisor_for_seconds = divisor_for_minutes % 60;
        var seconds = Math.ceil(divisor_for_seconds);
        return (hours + "_" + minutes + "_" + seconds).split("_");
    }

    render() {
        return (
            <div>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    stopOnIdle={true}
                    startManually={true}
                    onIdle={() => { this.onIdle() }}
                    timeout={1000 * this.state.staticSeconds * 1} // Customize seconds
                />
                <div className={"removeAlert-divbrowserLogout " + (this.state.popupShow ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x text-center">
                        {/*<img src="img/logout_png.png" alt="Error" />*/}
                        {this.state.isFromCaseDetails ?
                            (
                                <div>
                                    {/*<p className={"my-3 " + (this.state.btnstayLogged ? "hide" : "show")}>
                                        Your session is going to expire
                                        {this.state.dHour != 0 || this.state.dMinute != 0 || this.state.dSeconds != 0 ? " in " : ""}
                                        {this.state.dHour != 0 ? this.state.dHour + " hour(s) " : ""}
                                        {this.state.dMinute != 0 ? this.state.dMinute + " minute(s) " : ""}
                                        {this.state.dSeconds != 0 ? this.state.dSeconds + " second(s) " : ""}
                                        {this.state.dHour == 0 && this.state.dMinute == 0 && this.state.dSeconds == 0 ? " now " : ""}
                                        due to inactivity. Do you wish to stay Login or Logout?
                                </p>*/}
                                    <p className={"my-3 " + (!this.state.btnstayLogged ? "hide" : "show")}>
                                        Your session has been expired due to inactivity. Please log out and login again to continue.
                                    </p>
                                </div>
                            ) :
                            (
                                <div>
                                    {/*<p className={"my-3 " + (this.state.btnstayLogged ? "hide" : "show")}>
                                        Your session is going to expire
                                        {this.state.dHour != 0 || this.state.dMinute != 0 || this.state.dSeconds != 0 ? " in " : ""}
                                        {this.state.dHour != 0 ? this.state.dHour + " hour(s) " : ""}
                                        {this.state.dMinute != 0 ? this.state.dMinute + " minute(s) " : ""}
                                        {this.state.dSeconds != 0 ? this.state.dSeconds + " second(s) " : ""}
                                        {this.state.dHour == 0 && this.state.dMinute == 0 && this.state.dSeconds == 0 ? " now " : ""}
                                        due to inactivity. Do you wish to stay Login or Logout?
                                        <br />
                                        <b>Note</b>: Incomplete Order details will be saved and available in the Unsubmitted Orders.
                                </p>*/}
                                    <p className={"my-3 " + (!this.state.btnstayLogged ? "hide" : "show")}>
                                        Your session has been expired due to inactivity. Please log out and login again to continue. <br />
                                        <b>Note</b>: Incomplete Order details will be saved and available in the Unsubmitted Orders.
                                    </p>
                                </div>
                            )}
                        <p className="my-3">
                            {/*{this.state.time.h} : {this.state.time.m} : {this.state.time.s}*/}
                        </p>

                        {/* <div className={"bttn popupButton m-1"} style={{ display: this.state.btnstayLogged ? "none" : "inline-block" }} onClick={this.stayLoggedPopup}>
                            Stay Logged
                        </div>*/}
                        <div className="bttn popupButton m-1" /*onClick={this.logoutuser}*/>
                            Ok
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}