import { Component } from 'react';
import $ from 'jquery';
import Cookies from 'js-cookie';
import { isNull, isNullOrUndefined } from 'util';
var CryptoJS = require('crypto-js');
export default class Api extends Component {
    static isLoaderActive = false;
    static staticSeconds = 900;
    static logoutSeconds = 60;  
   
    static Get(url, currentComponent, isDisableLoader = false) {
        return Api.fetchGetRetry(url, currentComponent, isDisableLoader).then(function (response) {            
            if (!response.ok) {                        
                return response;
            }
            return response.json();
        }).then((responseData) => {            
                if (responseData.status === 403) {
                    currentComponent.toasterredfun("You don't have permission to access this operation, please try again later.");
                } else if (responseData.status === 429) {
                    responseData.statusCode = 429;
                    responseData.statusMessage = "Maximum hits exceeded, Try after 7s."
                } else {
                    return responseData;
                }
            return responseData;                      
        });
    }
    toasterredfun(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
                this.props.history.goBack();
            }.bind(this), 5000);
    }
    static fetchGetRetry(url, currentComponent, isDisableLoader) {
        return new Promise((resolve) => {
            let timeoutData = setTimeout(function () {
                this.clearSetTimeout(currentComponent);
            }.bind(this), 900000);
            if (!isDisableLoader) {
                if (this.isLoaderActive === false) {
                    //currentComponent.setState({ loading: true });
                } else
                    this.isLoaderActive = true;
            } else {
                this.isLoaderActive = false;
                //currentComponent.setState({ loading: false });
            }

            let headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            };
            let euid = localStorage.getItem("Euid");
            let userCompanycode = localStorage.getItem("Companycode");
            let antiToken = Cookies.get('XSRF-TOKEN');
            if (currentComponent && userCompanycode && userCompanycode !== null) {
                headers = { ...headers, "Companycode": userCompanycode, "Euid": euid };
            }
            headers = { ...headers, "X-XSRF-Token": antiToken };
            function fetchUrl() {
                const options = { method: 'GET', headers };
                return fetch(url, options).then((res) => {                    
                    clearTimeout(timeoutData);
                    currentComponent.setState({ loading: false });
                    if (res.status == 401) {
                        failure(res);
                    } else {
                        success(res);
                    }
                }).catch(error => {
                    clearTimeout(timeoutData);
                    //currentComponent.setState({ loading: false });
                    success(error);
                    if (error == "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun("Check your internet connection.");
                    }
                    else {
                        currentComponent.toasterredfun(error);
                    }
                });
            }

            function success(response) {
                resolve(response);
            }

            function failure(error) {                
                localStorage.clear();
                localStorage.setItem("Message", "Your current session has expired, please login again to continue");
                localStorage.setItem("Title", "Session Expired");
                if (currentComponent.props.history) {
                    currentComponent.props.history.push("/error");
                }
            }
            fetchUrl();
        });
    }

    static Post(url, data, currentComponent, isDisableLoader) {
        return Api.fetchPostRetry(url, data, currentComponent, isDisableLoader).then(function (response) {
            if (!response.ok) {               
                return response;
            }
            return response.json();
        }).then((responseData) => {           
                if (responseData.status === 403) {
                    currentComponent.toasterredfun("You don't have permission to access this operation, please try again later.");
                } else if (responseData.status === 429) {
                    responseData.statusCode = 429;
                    responseData.statusMessage = "The number of consecutive mouse clicks exceeded. Please refresh the page or wait 7 seconds and try again."
                } else {
                    return responseData;
                }
                return responseData;            
        });
    }
    
    static fetchPostRetry(url, data, currentComponent, isDisableLoader) {
        return new Promise((resolve) => {
            let timeoutData = setTimeout(function () {
                this.clearSetTimeout(currentComponent);
            }.bind(this), 900000);

            if (!isDisableLoader) {
                if (this.isLoaderActive === false) {
                    if (currentComponent != null) {
                        //currentComponent.setState({ loading: true });
                    }
                }
            } else {
                this.isLoaderActive = false;
                if (currentComponent != null) {
                    //currentComponent.setState({ loading: false });
                }
            }

            let euid = localStorage.getItem("Euid");
            let userCompanycode = localStorage.getItem("Companycode");
            let antiToken = Cookies.get('XSRF-TOKEN');
            let headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            };

            if (currentComponent && userCompanycode && userCompanycode !== null) {
                headers = { ...headers, "Companycode": userCompanycode, "Euid": euid };
            }
            headers = { ...headers, "X-XSRF-Token": antiToken };
            function fetchUrl() {
                const options = { method: 'POST', headers, body: JSON.stringify(data) };
                return fetch(url, options).then((res) => {                    
                    clearTimeout(timeoutData);
                    if (currentComponent != null) {
                        //currentComponent.setState({ loading: false });
                    }
                    if (res.status === 401) {
                        failure(res);
                    } else {
                        success(res);
                    }
                }).catch(error => {
                    clearTimeout(timeoutData);
                    if (currentComponent != null) {
                        //currentComponent.setState({ loading: false });
                    }
                    success(error);
                });
            }

            function success(response) {
                resolve(response);
            }

            function failure(error) {             
                localStorage.clear();
                localStorage.setItem("Message", "Your current session has expired, please login again to continue");
                localStorage.setItem("Title", "Session Expired");
                if (currentComponent.props.history) {
                    currentComponent.props.history.push("/error");
                }
                
            }

            fetchUrl();
        });
    }

    static clearSetTimeout(currentComponent) {
        //currentComponent.setState({ loading: false });
        currentComponent.toasterredfun("Looks like the server is taking too long to respond, please wait for sometime or try again later.");
    }
    static SetLocalStorage(request, IsSSOUser, Companycode)
    {
        localStorage.setItem('Euid', request.userId);
        localStorage.setItem('ShowAdminMenu', request.showAdminMenu);
        localStorage.setItem('UserType', request.userType);
        localStorage.setItem('PlaceOrder', request.placeOrder);
        localStorage.setItem('ViewResults', request.downloadImages);
        localStorage.setItem('Companycode', Companycode);
        localStorage.setItem('CompanyType', request.companyType);
        localStorage.setItem('IsSSOUser', IsSSOUser);
        localStorage.setItem('SyncClicked', 'false');
        localStorage.setItem('IsTimeOut', 'false');
        localStorage.setItem('IsTimeOutFromPlaceOrder', 'false');
        localStorage.setItem('ViewAuth', request.viewAuth);
        localStorage.setItem('ExportSearchResults', request.exportSearchResults);
        localStorage.setItem('PerformanceMetrics', request.performanceMetrics);
        localStorage.setItem('ClickedPageInResult', 0);
        localStorage.setItem('IsFromCaseDetailsToSearch', 'false');
        localStorage.setItem('ClickedPageInSearch',0);
        localStorage.setItem('OffsetValue', 0);
    }
    //static encryptData(e) {
    //    var key = CryptoJS.enc.Utf8.parse('6728623734782342');
    //    var iv = CryptoJS.enc.Utf8.parse('8080808080808080');
    //    var encryptedData = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(e), key,
    //        {
    //            keySize: 128 / 8,
    //            iv: iv,
    //            mode: CryptoJS.mode.CBC,
    //            padding: CryptoJS.pad.Pkcs7
    //        });
    //    return encryptedData.toString();
    //}
    //static decryptData(e) {
    //    var key = CryptoJS.enc.Utf8.parse('6728623734782342');
    //    var iv = CryptoJS.enc.Utf8.parse('8080808080808080');
    //    var bytes = CryptoJS.AES.decrypt(e, key, { iv: iv });
    //    var originalText = bytes.toString(CryptoJS.enc.Utf8);
    //    return originalText.split('+').join(' ');
    //}

    static encryptData(e) {
        var iv = CryptoJS.enc.Utf8.parse('8080808080808080');
        const mkey = CryptoJS.SHA256('6728623734782342')

        var encryptedData = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(e), mkey,
            {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
        return encryptedData.toString();
    }

    static decryptData(e) {    
        var iv = CryptoJS.enc.Utf8.parse('8080808080808080');
        const mkey = CryptoJS.SHA256('6728623734782342')

        var bytes = CryptoJS.AES.decrypt(e, mkey, { iv: iv });
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
        return originalText.split('+').join(' ');
    }

    static replaceAll(string, search, replace) {
        return string.split(search).join(replace);
    }

    static splitURLParams(e) {
        //debugger;
        var responseObj = {};
        if (!isNullOrUndefined(e) && e != "") {
            var decryptString = this.decryptData(e);
            if (!isNullOrUndefined(decryptString) && decryptString != "" ) {
                if (decryptString.includes("fromSearch")) {
                    const urlParamsArray = decryptString.split('&');
                    var idSplit = urlParamsArray[0].split('id=');
                    var uidSplit = urlParamsArray[1].split('uid=');
        
                    responseObj = {
                        uid: uidSplit[1],
                        id: idSplit[1]
                    };
                }
                else {
                    const urlParamsArray = decryptString.split('&');
                    for (var i = 0; i < urlParamsArray.length; i++) {
                        var keyValueArray = urlParamsArray[i].split('=');
                        responseObj[keyValueArray[0]] = keyValueArray[1];
                    }
                }
            }
        }
        return responseObj;
    }

    static CheckForNoiseWords(input)
    {
        var noiseWords = [
            'select *',
            'insert into',
            'alter table',
            'echo(',
            'sleep(',
            'delay(',
            'ping(',
            'typeoff(',
            '<script',
            'while(',
            'alert(',
            'hexdec(',
            'dechex(',
            '><',
            ';;',
            'confirm(',
            '>',
            '<',
            '<img',
            '<strong',
            '<br',
            '<body',
            '<meta',
            '<iframe',
            '<xml',
            '<div',
            '<style',
            '<?',
            '<!',
            '<a',
            '<link',
            '<table',
            '<input',
            '<marquee',
            '<embed',
            '<svg',
            '<audio',
            '<video',
            'document.write(',
            'eval(',
            'onload=',
            'onpageshow(',
            '%3C',
            '%3E',
            'onclick(',
            'ondblclick(',
            '&gt;',
            '&lt;',
            'onmousedown(',
            'onmousemove(',
            'onmouseover(',
            'onmouseout(',
            'onmouseup(',
            'onkeydown(',
            'onkeypressI(',
            'onkeyup(',
            'onabort(',
            'onerror(',
            'onload(',
            'autofocus(',
            'onresize(',
            'onscroll(',
            'onunload(',
            'onsubmit(',
            'onblur(',
            'onchange(',
            'onfocus(',
            'onreset(',
            'onselect(',
            'onMoveOn(',
            'delete ',
            'prompt(',
            "' or ",
            "'='",
            "'><'",
            '&lt;img',
            '&lt;strong',
            '&lt;br',
            '&lt;body',
            '&lt;meta',
            '&lt;iframe',
            '&lt;div',
            '&lt;style',
            '&lt;link',
            '&lt;table',
            '&lt;input',
            '&lt;marquee',
            '&lt;embed',
            '&lt;svg',
            '&lt;a',
            '&lt;audio',
            '&lt;video'];
        let foundWords = noiseWords.filter((word) => input.includes(word));
        if (foundWords.length > 0) {
            return true;
        }
        return false;
    }
}

Api.displayName = "Api";