import React, { Component } from 'react';
import { Layout } from './Layout';
import MaterialTable from "material-table";
import TablePagination from '@material-ui/core/TablePagination';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import BarChart from 'react-bar-chart';
import $ from 'jquery';
import Api from '../serviceCall';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CsvBuilder } from 'filefy';
import { isNull, isNullOrUndefined } from 'util';
import { MultiSelect } from "react-multi-select-component";
import "./dashboard.css";
import { TimeoutCounter } from './TimeoutCounter';
import { Icon } from '@material-ui/core';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';

export class Dashboard extends Component {
    static displayName = Dashboard.name;
    
    constructor(props) {
        super(props);
        //this.loadActionRequiredOrderBarChart();
        
        this.state = {
            loading: true,
            LandingPendingOrderList: [],
            status: '-1',
            barChartPendingOrder: [],
            barChartActionRequiredOrder: [], toasterCla: false,
            barChartDeliveredResultsOrder: [],
            value: { text: 'Select', value: 0 },
            selectDropDownUsers: '0', toasterredCla: false, isOwn: false,

            lblRequestType: "",
            lblFirstName: "",
            lblDoctorFirstName: "",
            lblFacilityName: "",
            lblPolicyNum: "",
            RequiredFieldsList: [],
            FirstNameHide: false,
            LastNameHide: false,
            DoctorLastNameHide: false,
            DoctorFirstNameHide: false,
            FacilityNameHide: false,
            PolicyNumHide: false,

            // lblindividualLastNameSearch: "",
            lblLastName: "",
            selectDropDownCycleTime: '0', RowCount: "", isPending: false, isActionRequired: false, isAll: false, UserId: "",
            offSet: 0,
            PageLimit: 5,
            totalRecord: 0,
            currentPage: 0,
            dataForExport: [],
            isSearchClicked: false,
            emptyDataSourceMessage: "Choose a quick filter to see results",
            orderingOfficeAccessList: [],
            selectedMultiItem: [],
            hideExportButton: false,
            sortOrder: '',
            sortColumn: '',
            clickCount: 0    
        };
        this.handleSyncDashboard = this.handleSyncDashboard.bind(this);
        this.GetShowAdminMenu = this.GetShowAdminMenu.bind(this);
        this.GetRequiredFields = this.GetRequiredFields.bind(this);
        this.LandingPageOrders = this.LandingPageOrders.bind(this);
        this.exportGridValues = this.exportGridValues.bind(this);
        this.loadOrderingOfficeAccessList = this.loadOrderingOfficeAccessList.bind(this);
        this.setSelectedfun = this.setSelectedfun.bind(this);
        this.Onclickexportpopup = this.Onclickexportpopup.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.tableRef = React.createRef();
    }
    setSelectedfun(e) {
        this.setState({ selectedMultiItem: e, sortColumn: '', sortOrder: '', clickCount: 0, currentPage:0 }, () => {
            //this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, 0, 5);         

            if (this.state.selectDropDownUsers === '0' || this.state.selectDropDownUsers === '1') {
                if (this.state.status != "-1") {
                    this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, 0, this.state.PageLimit);
                }
                this.loadPendingOrderBarChart(this.state.selectDropDownUsers, this.state.selectDropDownCycleTime);
                this.loadActionRequiredOrderBarChart(this.state.selectDropDownUsers, this.state.selectDropDownCycleTime);
            }
            else if (this.state.selectDropDownUsers === '2') {
                if (this.state.status != "-1") {
                    this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, 0, this.state.PageLimit);
                }
                this.loadPendingOrderBarChart(this.state.selectDropDownUsers, this.state.selectDropDownCycleTime);
                this.loadActionRequiredOrderBarChart(this.state.selectDropDownUsers, this.state.selectDropDownCycleTime);
            }
            

            //this.loadPendingOrderBarChart(this.state.selectDropDownUsers, this.state.selectDropDownCycleTime);
            //this.loadActionRequiredOrderBarChart(this.state.selectDropDownUsers, this.state.selectDropDownCycleTime);
        });
    }
    handleSyncDashboard(event) {
        localStorage.setItem('SyncClicked', 'true');
        let currentComponent = this;
        let url = "api/placeorder/UpdateDaysCalculation";
        currentComponent.setState({ loading: true });
        Api.Get(url, currentComponent)
            .then(function (resul) {
                if (resul.statusCode == "100") {
                    currentComponent.setState({ loading: false });
                    currentComponent.toasterfunc(resul.statusMessage);
                    window.location.reload(false);                    
                } else {
                    currentComponent.setState({ loading: false });
                    currentComponent.toasterredfun(resul.statusMessage);
                }
                //localStorage.setItem('SyncClicked', 'false');
            })
            .catch(function (error) {
                currentComponent.setState({ loading: false });
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun("Check your internet connection.");
                } else {
                    currentComponent.toasterredfun(error);
                }
                //localStorage.setItem('SyncClicked', 'false');
            });
    }
    handleStatusChange(event) {        
        this.setState({ status: event.target.value, currentPage: 0 });
        this.setState({
            sortColumn: '',
            sortOrder: '',
            clickCount: 0,
        });
        var selectDropDownUsers = this.state.selectDropDownUsers;
        var selectDropDownCycleTime = this.state.selectDropDownCycleTime;
        var targetValue = event.target.value;
        if (event.target.value == "3") {
            this.setState({
                isPending: true,
                isActionRequired: false,
                isAll: false,
                isSearchClicked: true
            }, () => { this.LandingPageOrders(targetValue, selectDropDownUsers, selectDropDownCycleTime, 0, this.state.PageLimit); });
        }
        else if (event.target.value == "4") {
            this.setState({
                isPending: false,
                isActionRequired: true,
                isAll: false,
                isSearchClicked: true
            }, () => { this.LandingPageOrders(targetValue, selectDropDownUsers, selectDropDownCycleTime, 0, this.state.PageLimit); });
        }
        else {
            this.setState({
                isPending: false,
                isActionRequired: false,
                isAll: true,
                isSearchClicked: true
            }, () => { this.LandingPageOrders(targetValue, selectDropDownUsers, selectDropDownCycleTime, 0, this.state.PageLimit); });
        }
    }
    handleBasedUserListChange = (event) => {
        this.setState({
            selectDropDownUsers: event.target.value, LandingPendingOrderList: [], orderingOfficeAccessList: [], selectedMultiItem: [], RowCount: "", totalRecord: 0, emptyDataSourceMessage: "No records to display", sortColumn: '', sortOrder: '', clickCount: 0, currentPage: 0
        }, () => {
            /*var selectDropDownUsers = '0';*/
            var selectDropDownCycleTime = '0';
            
            this.loadOrderingOfficeAccessList(this.state.selectDropDownUsers, true);
        });
    }
    handleBasedUserCycleTimeListChange = (event) => {
        this.setState({
            selectDropDownCycleTime: event.target.value,
            currentPage: 0
        });
        this.setState({
            sortColumn: '',
            sortOrder: '',
            clickCount: 0,
            currentPage:0
        });
        if (event.target.value === '0' || event.target.value === '1') {
            if (this.state.status != "-1") {
                this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, event.target.value, 0, this.state.PageLimit);
            }
            this.loadPendingOrderBarChart(this.state.selectDropDownUsers, event.target.value);
            this.loadActionRequiredOrderBarChart(this.state.selectDropDownUsers, event.target.value);
        }
        else if (event.target.value === '2') {
            if (this.state.status != "-1") {
                this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, event.target.value, 0, this.state.PageLimit);
            }
            this.loadPendingOrderBarChart(this.state.selectDropDownUsers, event.target.value);
            this.loadActionRequiredOrderBarChart(this.state.selectDropDownUsers, event.target.value);
        }
        else if (event.target.value === '3') {
            if (this.state.status != "-1") {
                this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, event.target.value, 0, this.state.PageLimit);
            }
            this.loadPendingOrderBarChart(this.state.selectDropDownUsers, event.target.value);
            this.loadActionRequiredOrderBarChart(this.state.selectDropDownUsers, event.target.value);
        }
    }

    async exportGridValues() {
        let currentComponent = this;
        let responce;
        if (currentComponent.state.isSearchClicked == true) {
            currentComponent.setState({ loading: true })
            var officeNameList = "0";
            if (currentComponent.state.selectedMultiItem.length > 0) {
                officeNameList = currentComponent.state.selectedMultiItem.map(function (elem) { return elem.value; }).join(",");
            }
            await Api.Post('api/placeorder/GetLandingPageOrders', {
                status: currentComponent.state.status, selectDropDownUsers: currentComponent.state.selectDropDownUsers,
                selectDropDownCycleTime: currentComponent.state.selectDropDownCycleTime, dataOffSet: "0", dataLimit: "0", officeName: officeNameList
            }, currentComponent).then(function (result) {
                if (result.statusCode === 100) {
                    if (!isNullOrUndefined(result.result)) {
                        if (result.allowExport) {
                            result.result.map(rowData => {
                                rowData.patientFirstName = Api.decryptData(rowData.patientFirstName);
                                rowData.patientLastName = Api.decryptData(rowData.patientLastName);
                                rowData.policyNumber = Api.decryptData(rowData.policyNumber);
                            });
                            currentComponent.setState({ dataForExport: result.result, loading: false });
                            currentComponent.setState({ hideExportButton: result.allowExport });
                            localStorage.removeItem('ExportSearchResults');
                            localStorage.setItem('ExportSearchResults', result.allowExport);
                        }
                        else
                        {
                            currentComponent.setState({ dataForExport: [], loading: false });
                            localStorage.removeItem('ExportSearchResults');
                            localStorage.setItem('ExportSearchResults', result.allowExport);
                            if (currentComponent.state.hideExportButton) {
                                currentComponent.setState({ exportAlert: true });
                            }
                            responce = false;
                            return responce;
                        }
                    }
                    else {
                        currentComponent.setState({ dataForExport: [], loading: false });
                    }
                    responce = true;
                }
                else {
                    currentComponent.setState({ dataForExport: [], loading: false });
                    responce = false;
                }
            });
        }
        else {
            responce = false;
        }
        return responce;
    }

    LandingPageOrders(status, selectDropDownUsers, selectDropDownCycleTime, offset, Limit,fieldName,sortOrder) {       
        let currentComponent = this;
        this.setState({ offSet: offset });
        if (currentComponent.state.isSearchClicked == true) {
            //currentComponent.setState({ LandingPendingOrderList: [] });
            currentComponent.setState({ loading: true });

            var officeNameList = "0";
            if (currentComponent.state.selectedMultiItem.length > 0) {
                officeNameList = currentComponent.state.selectedMultiItem.map(function (elem) { return elem.value; }).join(",");
            }         
            Api.Post('api/placeorder/GetLandingPageOrders', {
                status: status, selectDropDownUsers: selectDropDownUsers,
                selectDropDownCycleTime: selectDropDownCycleTime, dataOffSet: offset, dataLimit: Limit,
                officeName: officeNameList, fieldName: fieldName, sortOrder: sortOrder
            }, currentComponent).then(function (result) {
                if (result.statusCode === 100) {
                    currentComponent.setState({ LandingPendingOrderList: [], RowCount: "" });
                    if (!isNullOrUndefined(result.result)) {
                        if (result.rowCount == 0) {
                            currentComponent.setState({ emptyDataSourceMessage: "No records to display" });
                        }
                        result.result.map(rowData => {
                            rowData.patientFirstName = Api.decryptData(rowData.patientFirstName);
                            rowData.patientLastName = Api.decryptData(rowData.patientLastName);
                            rowData.policyNumber = Api.decryptData(rowData.policyNumber);
                        });
                        localStorage.setItem('TotalRecordsInResult', result.rowCount)
                        currentComponent.setState({ LandingPendingOrderList: result.result, loading: false, isOwn: result.isOwn, RowCount: result.rowCount, totalRecord: result.rowCount });
                        const newPageLimit = Limit;
                        if (currentComponent.tableRef.current) {
                            const event = {
                                target: {
                                    value: newPageLimit,
                                },
                            };
                            currentComponent.tableRef.current.onChangeRowsPerPage(event);
                        }
                        localStorage.removeItem('IsFromCaseDetailsToDashboard');
                    }
                    else {
                        currentComponent.setState({ LandingPendingOrderList: [], loading: false, isOwn: result.isOwn, RowCount: "", totalRecord: 0, emptyDataSourceMessage: "No records to display" });
                    }
                }
                else if (result.statusCode == 429) {
                    currentComponent.toasterredfun2(result.statusMessage);
                    currentComponent.setState({ loading: false });
                }
                else {
                    currentComponent.setState({ LandingPendingOrderList: [], loading: false, RowCount: "", totalRecord: 0, emptyDataSourceMessage: "No records to display" });
                }                
            });

            //fetch('api/PlaceOrder/GetLandingPageOrders/', {
            //    method: 'post',
            //    headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem("UserId") },
            //    body: JSON.stringify({ userId: userId, status: status, selectDropDownUsers: selectDropDownUsers }),
            //    token: localStorage.getItem("jwtToken")
            //}).then(function (response) {
            //    return response.json();
            //}).then(function (result) {
            //    if (result.statusCode === 100) {
            //        currentComponent.setState({ LandingPendingOrderList: result.result, loading: false, isOwn: result.isOwn });
            //    } else {
            //        currentComponent.setState({ LandingPendingOrderList: result.result, loading: false, isOwn: result.isOwn });
            //    }

            //}).catch(function (error) {
            //    if (error == "TypeError: Failed to fetch") {
            //        currentComponent.toasterredfun("Check your internet connection.");
            //    }
            //    else {
            //        currentComponent.toasterredfun(error);
            //    }
            //    currentComponent.setState({ loading: false });
            //});
        }
        
    }
    loadOrderingOfficeAccessList(selectDropDownUsers, isDefault = false) {        
        let currentComponent = this;
        currentComponent.setState({ loading: true, orderingOfficeAccessList: [], selectedMultiItem: [] });
        Api.Post('api/placeorder/GetOrderingOfficeAccessList', {
            AccessLevel: selectDropDownUsers
        }, currentComponent).then(function (result) {            
            if (!isNullOrUndefined(result.result)) {
                if (isDefault) {
                    var IsRedirectedFromDashboard = localStorage.getItem('IsFromCaseDetailsToDashboard');
                    if (IsRedirectedFromDashboard === "true") {
                        localStorage.removeItem('IsFromCaseDetailsToDashboard');
                        var OrderingOfficeAccessList = JSON.parse(localStorage.getItem('OrderingOfficeAccessList'));
                        var SelectedMultiItem = JSON.parse(localStorage.getItem('SelectedMultiItem'));

                        currentComponent.setState({ orderingOfficeAccessList: OrderingOfficeAccessList, selectedMultiItem: SelectedMultiItem });
                    }
                    else {
                        currentComponent.setState({ orderingOfficeAccessList: result.result, selectedMultiItem: result.result });
                    }
                }
                else { currentComponent.setState({ orderingOfficeAccessList: result.result }); }
                if (currentComponent.state.status != "-1") {
                    currentComponent.LandingPageOrders(currentComponent.state.status, currentComponent.state.selectDropDownUsers, currentComponent.state.selectDropDownCycleTime, currentComponent.state.offSet, currentComponent.state.PageLimit, currentComponent.state.sortColumn, currentComponent.state.sortOrder);
                }
                currentComponent.loadPendingOrderBarChart(currentComponent.state.selectDropDownUsers, currentComponent.state.selectDropDownCycleTime);
                currentComponent.loadActionRequiredOrderBarChart(currentComponent.state.selectDropDownUsers, currentComponent.state.selectDropDownCycleTime);
            }
            else {
                currentComponent.loadPendingOrderBarChart(currentComponent.state.selectDropDownUsers, currentComponent.state.selectDropDownCycleTime);
                currentComponent.loadActionRequiredOrderBarChart(currentComponent.state.selectDropDownUsers, currentComponent.state.selectDropDownCycleTime);
            }
            currentComponent.setState({ loading: false });
        });
    }

    loadPendingOrderBarChart(selectDropDownUsers, selectDropDownCycleTime) {        
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        var officeNameList = "0";
        if (currentComponent.state.selectedMultiItem.length > 0) {
            officeNameList = currentComponent.state.selectedMultiItem.map(function (elem) { return elem.value; }).join(",");
        }
        Api.Post('api/placeorder/GetPendingOrderBarChart', { selectDropDownUsers: selectDropDownUsers, selectDropDownCycleTime: selectDropDownCycleTime, officeName: officeNameList }, currentComponent).then(function (result) {
            if (result.statusCode === 100) {               
                //currentComponent.setState({ barChartPendingOrder: result.result, loading: false });
                var chart = am4core.create("chartdiv", am4charts.XYChart);
                // Add data
                let dataObject = [];
                for (let i = 0; i < result.result[0].month.length; i++) {
                    dataObject.push({ month: result.result[0].month[i], orders: result.result[0].orders[i] })
                }
                chart.data = dataObject;

                // Create axes
                var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "month";
                // categoryAxis.title.text = "Month";
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.minGridDistance = 20;

                var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.title.text = "Orders";
                valueAxis.calculateTotals = true;
                valueAxis.min = 0;
                valueAxis.max = result.max + 5;
                valueAxis.strictMinMax = true;
                valueAxis.renderer.labels.template.adapter.add("text", function (text) {
                    return text;
                });

                // Create series
                var series = chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.valueY = "orders";
                series.dataFields.categoryX = "month";
                series.tooltipText = "{name}: [bold]{valueY}[/]";
                series.stacked = true;
                // Add cursor
                chart.cursor = new am4charts.XYCursor();
                series.columns.template.fill = am4core.color("#0275d8");
                currentComponent.setState({ loading: false });
            }
            else {
                currentComponent.setState({ barChartPendingOrder: result, loading: false });
            }
        });

        //fetch('api/PlaceOrder/GetPendingOrderBarChart/', {
        //    method: 'post',
        //    headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem("UserId") },
        //    body: JSON.stringify({ userId: userId, selectDropDownUsers: selectDropDownUsers }),
        //}).then(function (response) {
        //    return response.json();
        //}).then(function (result) {
        //    if (result.statusCode === 100) {        
        //        //currentComponent.setState({ barChartPendingOrder: result.result, loading: false });
        //        var chart = am4core.create("chartdiv", am4charts.XYChart);
        //        // Add data
        //        let dataObject = [];
        //        for (let i = 0; i < result.result[0].month.length; i++) {
        //            dataObject.push({ month: result.result[0].month[i], orders: result.result[0].orders[i] })
        //        }       
        //        chart.data = dataObject;

        //        // Create axes
        //        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        //        categoryAxis.dataFields.category = "month";
        //        // categoryAxis.title.text = "Month";
        //        categoryAxis.renderer.grid.template.location = 0;
        //        categoryAxis.renderer.minGridDistance = 20;

        //        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        //        valueAxis.title.text = "Orders";
        //        valueAxis.calculateTotals = true;
        //        valueAxis.min = 0;
        //        valueAxis.max = result.max + 5;
        //        valueAxis.strictMinMax = true;
        //        valueAxis.renderer.labels.template.adapter.add("text", function (text) {
        //            return text;
        //        });

        //        // Create series
        //        var series = chart.series.push(new am4charts.ColumnSeries());
        //        series.dataFields.valueY = "orders";
        //        series.dataFields.categoryX = "month";
        //        series.tooltipText = "{name}: [bold]{valueY}[/]";
        //        series.stacked = true;
        //        // Add cursor
        //        chart.cursor = new am4charts.XYCursor();
        //        series.columns.template.fill = am4core.color("#0275d8");
        //    }
        //    else {
        //        currentComponent.setState({ barChartPendingOrder: result, loading: false });
        //    }

        //}).catch(function (error) {
        //    if (error == "TypeError: Failed to fetch") {
        //        currentComponent.toasterredfun("Check your internet connection.");
        //    }
        //    else {
        //        currentComponent.toasterredfun(error);
        //    }
        //    currentComponent.setState({ loading: false });
        //});
    }
    loadActionRequiredOrderBarChart(selectDropDownUsers, selectDropDownCycleTime) {       
        let currentComponent = this;
        currentComponent.setState({ loading: true });
        var officeNameList = "0";
        if (currentComponent.state.selectedMultiItem.length > 0) {
            officeNameList = currentComponent.state.selectedMultiItem.map(function (elem) { return elem.value; }).join(",");
        }
        Api.Post('api/placeorder/GetActionRequiredOrderBarChart', { selectDropDownUsers: selectDropDownUsers, selectDropDownCycleTime: selectDropDownCycleTime, officeName: officeNameList }, currentComponent).then(function (result) {
            if (result.statusCode === 100) {                
                //currentComponent.setState({ barChartActionRequiredOrder: result.result, loading: false });
                currentComponent.setState({ barChartActionRequiredOrder: result.result });
                var chart = am4core.create("chartdiv1", am4charts.XYChart);
                // Add data
                let dataObject = [];
                for (let i = 0; i < result.result[0].month.length; i++) {
                    dataObject.push({ month: result.result[0].month[i], orders: result.result[0].orders[i] })
                }
                chart.data = dataObject;
                // Create axes
                var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "month";
                // categoryAxis.title.text = "Month";
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.minGridDistance = 20;


                var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.title.text = "Orders";
                valueAxis.calculateTotals = true;
                valueAxis.min = 0;
                valueAxis.max = result.max + 5;
                valueAxis.strictMinMax = true;
                valueAxis.renderer.labels.template.adapter.add("text", function (text) {
                    return text;
                });

                // Create series
                var series = chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.valueY = "orders";
                series.dataFields.categoryX = "month";
                series.tooltipText = "{name}: [bold]{valueY}[/]";
                series.stacked = true;
                // Add cursor
                chart.cursor = new am4charts.XYCursor();
                series.columns.template.fill = am4core.color("#0275d8");
                currentComponent.setState({ loading: false });
            } else {
                currentComponent.setState({ barChartActionRequiredOrder: result.result, loading: false });
                //currentComponent.toasterfun2(result.statusMessage);
            }
        });

        //fetch('api/PlaceOrder/GetActionRequiredOrderBarChart/', {
        //    method: 'post',
        //    headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem("UserId") },
        //    body: JSON.stringify({ userId: userId, selectDropDownUsers: selectDropDownUsers })
        //}).then(function (response) {
        //    return response.json();
        //}).then(function (result) {
        //    if (result.statusCode === 100) {
        //        currentComponent.setState({ barChartActionRequiredOrder: result.result, loading: false });
        //        var chart = am4core.create("chartdiv1", am4charts.XYChart);
        //        // Add data
        //        let dataObject = [];
        //        for (let i = 0; i < result.result[0].month.length; i++) {
        //            dataObject.push({ month: result.result[0].month[i], orders: result.result[0].orders[i] })
        //        }
        //        chart.data = dataObject;
        //        // Create axes
        //        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        //        categoryAxis.dataFields.category = "month";
        //        // categoryAxis.title.text = "Month";
        //        categoryAxis.renderer.grid.template.location = 0;
        //        categoryAxis.renderer.minGridDistance = 20;


        //        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        //        valueAxis.title.text = "Orders";
        //        valueAxis.calculateTotals = true;
        //        valueAxis.min = 0;
        //        valueAxis.max = result.max + 5;
        //        valueAxis.strictMinMax = true;
        //        valueAxis.renderer.labels.template.adapter.add("text", function (text) {
        //            return text;
        //        });

        //        // Create series
        //        var series = chart.series.push(new am4charts.ColumnSeries());
        //        series.dataFields.valueY = "orders";
        //        series.dataFields.categoryX = "month";
        //        series.tooltipText = "{name}: [bold]{valueY}[/]";
        //        series.stacked = true;
        //        // Add cursor
        //        chart.cursor = new am4charts.XYCursor();
        //        series.columns.template.fill = am4core.color("#0275d8");
        //    } else {
        //        currentComponent.setState({ barChartActionRequiredOrder: result.result, loading: false });
        //        //currentComponent.toasterfun2(result.statusMessage);
        //    }

        //}).catch(function (error) {
        //    currentComponent.setState({ Loading: false });
        //    if (error == "TypeError: Failed to fetch") {
        //        currentComponent.toasterredfun("Check your internet connection.");
        //    }
        //    else {
        //        currentComponent.toasterredfun(error);
        //    }
        //    currentComponent.setState({ loading: false });
        //});
    }
    loadDeliveredResultsOrderBarChart(selectDropDownUsers) {
        let currentComponent = this;
        currentComponent.setState({ loading: true });

        Api.Post('api/placeorder/GetDeliveredResultsOrderBarChart', { selectDropDownUsers: selectDropDownUsers }, currentComponent).then(function (result) {
            if (result.statusCode === 100) {
                currentComponent.setState({ barChartDeliveredResultsOrder: result.result, loading: false });
                var chart = am4core.create("chartdiv2", am4charts.XYChart);
                // Add data
                let dataObject = [];
                for (let i = 0; i < result.result[0].month.length; i++) {
                    dataObject.push({ month: result.result[0].month[i], orders: result.result[0].orders[i] })
                }
                chart.data = dataObject;
                // Create axes
                var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "month";
                // categoryAxis.title.text = "Month";
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.minGridDistance = 20;


                var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.title.text = "Orders";
                valueAxis.calculateTotals = true;
                valueAxis.min = 0;
                valueAxis.max = result.max + 5;
                valueAxis.strictMinMax = true;
                valueAxis.renderer.labels.template.adapter.add("text", function (text) {
                    return text;
                });

                // Create series
                var series = chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.valueY = "orders";
                series.dataFields.categoryX = "month";
                series.tooltipText = "{name}: [bold]{valueY}[/]";
                series.stacked = true;
                // Add cursor
                chart.cursor = new am4charts.XYCursor();
                series.columns.template.fill = am4core.color("#0275d8");
            } else {
                currentComponent.setState({ barChartDeliveredResultsOrder: result.result, loading: false });
                //currentComponent.toasterfun2(result.statusMessage);
            }
        });
    }
    toasterredfun(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }
    componentWillMount() {        
        localStorage.setItem('SyncClicked', 'false');       
        let companyType = localStorage.getItem("CompanyType");       
        let currentComponent = this;
        this.GetRequiredFields();
        /*var selectDropDownUsers = '0';*/
        var selectDropDownCycleTime = '0';
       
        //localStorage.setItem('Status', this.state.status);
        //localStorage.setItem('RowCount', this.state.RowCount);
        //localStorage.setItem('IsPending', this.state.isPending);
        //localStorage.setItem('IsActionRequired', this.state.isActionRequired);
        //localStorage.setItem('IsAll', this.state.isAll);
        //localStorage.setItem('SelectDropDownCycleTime', this.state.selectDropDownCycleTime);
        //localStorage.setItem('SelectDropDownUsers', this.state.selectDropDownUsers);
        var userType = localStorage.getItem("UserType");
        var IsRedirectedFromDashboard = localStorage.getItem('IsFromCaseDetailsToDashboard');
        if (IsRedirectedFromDashboard === "true") {
            //localStorage.removeItem('IsFromCaseDetailsToDashboard');

            var OrderingOfficeAccessList = JSON.parse(localStorage.getItem('OrderingOfficeAccessList'));
            var SelectedMultiItem = JSON.parse(localStorage.getItem('SelectedMultiItem'));

            const pageLimit = parseInt(localStorage.getItem('PageLimit'), 10);
            const offset = parseInt(localStorage.getItem('OffSet'), 10);
            var vPageLimit;
            var vOffset;

            if (!isNaN(pageLimit)) {
                vPageLimit = pageLimit;
            } else {
                vPageLimit = 5;
            }
            if (!isNaN(offset)) {
                vOffset = offset;
            } else {
                vOffset = 0;
            }
            this.handlePageChange(localStorage.getItem('ClickedPageInResult'));           
            
            this.setState({
                status: localStorage.getItem('Status'),
                RowCount: localStorage.getItem('RowCount'),
                isPending: localStorage.getItem('IsPending') === "true"?true:false,
                isActionRequired: localStorage.getItem('IsActionRequired') === "true" ? true : false,
                isAll: localStorage.getItem('IsAll') === "true" ? true : false,
                selectDropDownCycleTime: localStorage.getItem('SelectDropDownCycleTime'),
                selectDropDownUsers: localStorage.getItem('SelectDropDownUsers'),
                orderingOfficeAccessList: OrderingOfficeAccessList,
                selectedMultiItem: SelectedMultiItem,
                PageLimit: vPageLimit,
                offSet: vOffset,
                currentPage: parseInt(localStorage.getItem('ClickedPageInResult')) - 1,
                sortColumn: localStorage.getItem('DashboardSortColumn'),
                sortOrder: localStorage.getItem('DashboardSortOrder'),
                UserId: this.state.userId,
                isSearchClicked: true,
                Loading: true
            }, () => {
                if (userType == "Agent") {
                    currentComponent.loadOrderingOfficeAccessList(1, true);
                } else {
                    currentComponent.loadOrderingOfficeAccessList(2, true);
                } });
            
            //currentComponent.loadPendingOrderBarChart(localStorage.getItem('SelectDropDownUsers'), localStorage.getItem('SelectDropDownCycleTime'));
            //currentComponent.loadActionRequiredOrderBarChart(localStorage.getItem('SelectDropDownUsers'), localStorage.getItem('SelectDropDownCycleTime'));
        }
        else {           
            if (userType == "Agent") {

                currentComponent.setState({ selectDropDownUsers: '1', selectDropDownCycleTime: '0' }, () => {
                    currentComponent.loadOrderingOfficeAccessList(1, true);
                });
                //this.loadPendingOrderBarChart('1', selectDropDownCycleTime);
                //this.loadActionRequiredOrderBarChart('1', selectDropDownCycleTime);
            }
            else {

                currentComponent.setState({ selectDropDownUsers: '2', selectDropDownCycleTime: '0' }, () => {
                    currentComponent.loadOrderingOfficeAccessList(2, true);
                });
                //this.loadPendingOrderBarChart('2', selectDropDownCycleTime);
                //this.loadActionRequiredOrderBarChart('2', selectDropDownCycleTime);
            }
        }       
               
        //currentComponent.setState({ loading: false });
        //this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime);
        //if (companyType == "LEGAL") {
        //    this.loadDeliveredResultsOrderBarChart(selectDropDownUsers);
        //}

        currentComponent.setState({ loading: true });

        Api.Get('api/placeorder/GetIsOwnStatus', currentComponent).then(function (result) {
            if (result.statusCode == '100') {
                currentComponent.setState({ loading: false, isOwn: result.isOwn, hideExportButton: result.exportSearchResults });
            }
            else {
                currentComponent.setState({ loading: false });
            }
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });

    }
    GetRequiredFields() {
        var CompanyId = 1;        

        let currentComponent = this;
        Api.Post('api/placeorder/GetRequiredFields', { "CompanyId": CompanyId }, currentComponent).then(function (result) {
            currentComponent.setState({ RequiredFieldsList: result.requiredFields });

            if (currentComponent.state.RequiredFieldsList.length > 0) {
                for (var i = 0; i < currentComponent.state.RequiredFieldsList.length; i++) {
                    currentComponent.setState({
                        [currentComponent.state.RequiredFieldsList[i].className + 'Hide']: currentComponent.state.RequiredFieldsList[i].isActive,
                        // ['isMandatory' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].isRequired,
                    });
                    if (currentComponent.state.RequiredFieldsList[i].customFieldName == "") {

                        //fetch('labelConfigs/Search_Labels.json').then(function (response) {
                        //    return response.json();
                        //}).then(function (response) {
                        //    currentComponent.setState({
                        //        loading: false,
                        //        lblindividualFirstNameSearch: response[companyType].Search_Filter.Individual_First_Name,
                        //        lblindividualLastNameSearch: response[companyType].Search_Filter.Individual_Last_Name,
                        //        lblagentIdSearch: response[companyType].Search_Filter.Agent_Id,
                        //        lblfileNumberSearch: response[companyType].Search_Filter.File_Number,
                        //        lblindividualNameTableHeader: response[companyType].Table_Header.Individual_Name,
                        //        lblfacilityNameTableHeader: response[companyType].Table_Header.Facility_Name,
                        //        lblproviderNameTableHeader: response[companyType].Table_Header.Provider_Name,
                        //        lblfileNumberTableHeader: response[companyType].Table_Header.File_Number
                        //    });
                        //});


                        currentComponent.setState({
                            ['lbl' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].originalFieldName,
                        });
                    }
                    else {
                        currentComponent.setState({
                            ['lbl' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].customFieldName,
                        });
                    }
                }
            }

        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    toasterredfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }
    GetShowAdminMenu() {
        debugger;
        var CompanyId = 1;        

        let currentComponent = this;
        currentComponent.setState({ loading: true });
        Api.Get('api/login/GetShowAdminMenu', currentComponent).then(function (result) {
            debugger;
            localStorage.setItem("ShowAdminMenu", result);
        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }

    toasterfunc(e) {

        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 5000);
    }
    Onclickexportpopup() {
        let currentComponent = this;
        currentComponent.setState({ exportAlert: false, loading: false });
    }  
    handleColumnHeaderClick = (column) => {
        if (this.state.LandingPendingOrderList.length != 0)
        {
            let offSet = (this.state.currentPage) * this.state.PageLimit;           
            if (this.state.sortColumn === column.field) {
                const newSortOrder = this.state.sortOrder === 'asc' ? 'desc' : 'asc';               
                    this.setState({
                        sortOrder: newSortOrder,
                        sortColumn: column.field,
                    });
                this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, offSet, this.state.PageLimit, column.field, newSortOrder);                                
            } else {
                this.setState({
                    sortColumn: column.field,
                    sortOrder: 'asc',                   
                });
                this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, offSet, this.state.PageLimit, column.field, "asc");
            }
        }
        
    }

    handlePageChange(e) {
        
        let CurrentPageLSR = localStorage.getItem('ClickedPageInResult');

        if (e == "first_page" || e == "0") {
            CurrentPageLSR = 1;
        }
        else if (e == "chevron_left") {
            CurrentPageLSR = parseInt(localStorage.getItem('ChevronleftInResult')) + 1;
        }
        else if (e == "chevron_right") {
            CurrentPageLSR = parseInt(localStorage.getItem('ChevronrightInResult')) + 1;
        }
        else if (e == "last_page") {
            var lp = localStorage.getItem('TotalRecordsInResult') / localStorage.getItem('PageLimit');
            lp = Math.ceil(lp) - 1;
            if (lp == 0) {
                CurrentPageLSR = 1;
            }
            else {
                CurrentPageLSR = parseInt(lp) + 1;
            }
        }
        localStorage.setItem('ClickedPageInResult', CurrentPageLSR);
        //let sr = localStorage.getItem('ClickedPageInResult');

    }
    render() {

        var landing = 'img/ems-logo.gif';
        var companyType = localStorage.getItem("CompanyType");
        let columns = [
            { title: 'Order Date', field: 'createdOnDateTime', type: 'date' },
            { title: 'eNoah Order Number', field: 'eNoahOrderId' },
            { title: 'Patient ' + this.state.lblFirstName, field: 'patientFirstName', hidden: this.state.FirstNameHide === false },
            { title: 'Patient ' + this.state.lblLastName, field: 'patientLastName', hidden: this.state.LastNameHide === false },
            { title: this.state.lblDoctorFirstName, field: 'doctorFirstName', hidden: this.state.DoctorFirstNameHide === false },
            { title: this.state.lblDoctorLastName, field: 'doctorLastName', hidden: this.state.DoctorLastNameHide === false },
            { title: this.state.lblFacilityName, field: 'facilityName', hidden: this.state.FacilityNameHide === false },
            { title: this.state.lblPolicyNum, field: 'policyNumber', hidden: this.state.PolicyNumHide === false },
            { title: 'Request Type', field: 'requestType', hidden: companyType === "LIFE" },
            { title: 'Certified', field: 'certified', hidden: companyType === "LIFE" },
            { title: 'Order Type', field: 'orderType', hidden: companyType === "LIFE" },
            { title: 'Requestor Name ', field: 'firstLastName' },
            { title: 'Status ', field: 'status', render: rowData => <span>{rowData.status}</span> },
            { title: 'Status Event', field: 'statusEvent' },
            { title: 'EncId ', field: 'encId', hidden: true },           

        ];
        let visibleColumns = columns.filter((column) => !column.hidden);
        return (
            <Layout>
                <TimeoutCounter />
                <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster} </div>

                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i>
                    {this.state.toaster} </div>
                <div className={"removeAlert-divbrowser " + (this.state.exportAlert ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x text-center">
                        <img src="img/error.png" alt="Error" />
                        <p className="my-3"> Your permissions has been modified/revoked to perform this action. Please contact your administrator.</p>
                        <div className="text-center-div m-0">
                            <div className="bttn popupButton m-0" onClick={this.Onclickexportpopup}>
                                Ok
                            </div>
                        </div>
                    </div>
                </div>
                <div className='hgtDiv'>

                    <div className="">

                        <div className="row">
                            <div className="col-xl-12 ">
                                <div className="col-md-12 margin-result mt-2 pull-left mb-3 nopadding">
                                    <div className="col-md-2 pull-left nopadding">
                                        <h3 className="mt-1 mb-2 withset">Quick Dashboard</h3>
                                    </div>

                                    <div className="col-md-1 pull-left">
                                        <label className="small dashLabel" htmlFor="inputLastName">Cycle Time Calculation</label>
                                    </div>

                                    <div className="col-md-2 pull-left">
                                        <div className="form-group">
                                            <select className="form-control" onChange={this.handleBasedUserCycleTimeListChange} value={this.state.selectDropDownCycleTime}>
                                                <option value='0'>Select </option>
                                                <option value='1'>Business Days </option>
                                                <option value='3'>Calendar Days</option>
                                                <option value='2'>End to End Days </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className={"col-md-1 pull-left "}>
                                        <label className="small dashLabel" htmlFor="inputLastName">Scope</label>
                                    </div>

                                    <div className={"col-md-2 pull-left "}>
                                        <div className="form-group">
                                            <select className="form-control" onChange={this.handleBasedUserListChange} value={this.state.selectDropDownUsers}>
                                               {/* <option value='0'>Select </option>*/}
                                                <option value='1'>My Orders</option>
                                                <option value='2'>All Orders</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={"col-md-1 pull-left "}>
                                        <label className="small dashLabel" htmlFor="inputLastName">Ordering Office</label>
                                    </div>
                                    <div className={"col-md-2 pull-left "}>
                                        <MultiSelect className="form-control p-0"
                                            options={this.state.orderingOfficeAccessList}
                                            value={this.state.selectedMultiItem}
                                            onChange={this.setSelectedfun}
                                            labelledBy={"Select Office"}
                                            hasSelectAll={true}
                                        />
                                    </div>

                                    <div className="col-md-1 pull-right">
                                        <button className="btn btn-blue-btnnm btn-type mt-1 mb-1 btn-size-1" onClick={this.handleSyncDashboard}>
                                            <Icon className="material-icons">cached</Icon></button>
                                    </div>
                                </div>
                            </div>
                            <div className={"dashboard-map " + (companyType == "LIFE" ? "col-xl-6" : "col-xl-6")}>
                                <div className="card">
                                    <div className="card-header">

                                        Pending Orders
                                    </div>
                                    <div className="card-body">
                                        <div id="chartdiv" style={{ width: "100%", height: "250px" }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className={"dashboard-map " + (companyType == "LIFE" ? "col-xl-6" : "col-xl-6")}>
                                <div className="card">
                                    <div className="card-header">

                                        Action Required
                                    </div>
                                    <div className="card-body">
                                        <div id="chartdiv1" style={{ width: "100%", height: "250px" }}></div>

                                    </div>
                                </div>
                            </div>
                            {/*<div className={"col-xl-4 dashboard-map " + (companyType == "LIFE" ? "hide" : "show")}>
                                <div className="card">
                                    <div className="card-header">

                                        Delivered Results
                                    </div>
                                    <div className="card-body">
                                        <div id="chartdiv2" style={{ width: "100%", height: "250px" }}></div>

                                    </div>
                                </div>
                            </div>*/}
                        </div>
                        <div className="col-md-12 nopadding">
                            <div className="pending-list-title">Pending List <span>*click on column header to sort</span></div>
                        </div>
                        <div className="card mb-4 dashboard-div-x">

                            <div className="card-body posrel">
                                <div className="export-div-x">
                                    <button className="export-class">Export</button>
                                </div>
                                <div className="col-md-2 searchoff nopadding">
                                    <span className="show-title">Show</span>
                                </div>
                                <div className="col-md-2 entriesoff nopadding">
                                    <span className="show-entries">entries</span>
                                </div>
                                <div className="col-md-6 filter-dashboard">
                                    <div className="form-group">
                                        <div className="col-md-2 pull-left">
                                            <label className="small fotbold sizelabel mr-1" >Quick Filter	</label>
                                        </div>

                                        <fieldset onChange={this.handleStatusChange.bind(this)}>
                                            <div className="form-row">
                                                <div className="col-md-2">
                                                    <div className="form-group radioLable-x">
                                                        <input type="radio" id="Pending" value="3" checked={this.state.status === "3"} readOnly={ true} />
                                                        <label className="small" >Pending</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-1">
                                                    <div className="form-group">
                                                        <label className={(this.state.isPending ? "show" : "hide")}>({this.state.RowCount})</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group radioLable-x">
                                                        <input type="radio" id="ActionRequired" value="4" checked={this.state.status === "4"} readOnly={true} />
                                                        <label className="small">Action Required</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-1">
                                                    <div className="form-group">
                                                        <label className={(this.state.isActionRequired ? "show" : "hide")}>({this.state.RowCount})</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group radioLable-x">
                                                        <input type="radio" id="All" value="0" checked={this.state.status === "0"} readOnly={true} />
                                                        <label className="small">All </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label className={(this.state.isAll ? "show" : "hide")}>({this.state.RowCount})</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="table-responsive dataTable dashboard-table masterSearch">
                                    <MaterialTable
                                        title=""
                                        tableRef={this.tableRef}
                                        onRowClick={(event, rowData) => {
                                            localStorage.setItem('PrevPage', 'dashboard');

                                            let orderingOfficeAccessList = JSON.stringify(this.state.orderingOfficeAccessList);
                                            let selectedMultiItem = JSON.stringify(this.state.selectedMultiItem);

                                            localStorage.setItem('OrderingOfficeAccessList', orderingOfficeAccessList);
                                            localStorage.setItem('SelectedMultiItem', selectedMultiItem);
                                            localStorage.setItem('Status', this.state.status);
                                            localStorage.setItem('RowCount', this.state.RowCount);
                                            localStorage.setItem('IsPending', this.state.isPending);
                                            localStorage.setItem('IsActionRequired', this.state.isActionRequired);
                                            localStorage.setItem('IsAll', this.state.isAll);
                                            localStorage.setItem('SelectDropDownCycleTime', this.state.selectDropDownCycleTime);
                                            localStorage.setItem('SelectDropDownUsers', this.state.selectDropDownUsers);
                                            localStorage.setItem('PageLimit', this.state.PageLimit);
                                            localStorage.setItem('OffSet', this.state.offSet);
                                            localStorage.setItem('DashboardSortColumn', this.state.sortColumn);
                                            localStorage.setItem('DashboardSortOrder', this.state.sortOrder);
                                            
                                            this.props.history.push('/case-details?id=' + rowData.encId);
                                        }}
                                        className="dataTable"
                                        localization={{ body: { emptyDataSourceMessage: <p>{this.state.emptyDataSourceMessage}</p> } }}
                                        columns={columns}
                                        data={this.state.LandingPendingOrderList}                                        
                                        options={{
                                            exportButton: true,
                                            exportAllData: true,
                                            paginationType: "stepped",
                                            exportFileName: "PendingOrderList",
                                            filtering: true,
                                            search: false,
                                            export: true,
                                            rowStyle: rowData => ({
                                                color: (rowData.status === 'DE Problem') ? '#ff0000' : '#000'
                                            }),
                                            isLoading: true,
                                            exportCsv: async (data, columns) => {
                                                if (this.state.hideExportButton) {
                                                var isExport = await this.exportGridValues();
                                                    if (isExport) {
                                                        if (this.state.dataForExport.length > 0) {
                                                            const columnTitles = data.filter(a => a.hidden != true).filter(a => a.hidden != true).map(columnDef => columnDef.title);
                                                            const csvData = this.state.dataForExport.map(rowData =>
                                                                data.filter(a => a.hidden != true).map(columnDef => rowData[columnDef.field]),
                                                            );
                                                            const builder = new CsvBuilder(`PendingOrderList.csv`)
                                                                .setColumns(columnTitles)
                                                                .addRows(csvData)
                                                                .exportFile();
                                                            return builder;
                                                        }
                                                    }
                                                }
                                                else {
                                                    this.toasterredfun2("You have no access to download. Contact Administrator.");
                                                }
                                            },
                                            exportPdf: async (data, columns) => {
                                                if (this.state.hideExportButton) {
                                                var isExport = await this.exportGridValues();
                                                     if (isExport) {
                                                        if (this.state.dataForExport.length > 0) {
                                                            const unit = "pt";
                                                            const size = "A4"; // Use A1, A2, A3 or A4
                                                            const orientation = "landscape"; // portrait or landscape
                                                            const doc = new jsPDF(orientation, unit, size);
                                                            doc.text("Pending Order List", 40, 35);
                                                            const columnTitles = data.filter(a => a.hidden != true).map(columnDef => columnDef.title);
                                                            const pdfData = this.state.dataForExport.map(rowData =>
                                                                data.filter(a => a.hidden != true).map(columnDef => rowData[columnDef.field]),
                                                            );
                                                            if (companyType == 'LIFE') {
                                                                doc.autoTable({
                                                                    styles: {
                                                                        cellPadding: 2,
                                                                        cellWidth: 'auto'
                                                                    },
                                                                    bodyStyles: {
                                                                        cellPadding: 2,
                                                                        margin: 40,
                                                                    },
                                                                    columnStyles: {
                                                                        0: { cellWidth: 70 },
                                                                        1: { cellWidth: 70 },
                                                                        2: { cellWidth: 70 },
                                                                        3: { cellWidth: 70 },
                                                                        4: { cellWidth: 70 },
                                                                        5: { cellWidth: 70 },
                                                                        6: { cellWidth: 70 },
                                                                        7: { cellWidth: 70 },
                                                                        8: { cellWidth: 70 },
                                                                        9: { cellWidth: 70 },

                                                                        text: {
                                                                            cellWidth: 'wrap'
                                                                        }
                                                                    },
                                                                    head: [columnTitles],
                                                                    body: pdfData
                                                                });
                                                            }
                                                            else {
                                                                doc.autoTable({
                                                                    styles: {
                                                                        cellPadding: 2,
                                                                        cellWidth: 'auto'
                                                                    },
                                                                    bodyStyles: {
                                                                        cellPadding: 2,
                                                                        margin: 40,
                                                                    },
                                                                    columnStyles: {
                                                                        0: { cellWidth: 55 },
                                                                        1: { cellWidth: 55 },
                                                                        2: { cellWidth: 55 },
                                                                        3: { cellWidth: 55 },
                                                                        4: { cellWidth: 55 },
                                                                        5: { cellWidth: 55 },
                                                                        6: { cellWidth: 55 },
                                                                        7: { cellWidth: 55 },
                                                                        8: { cellWidth: 55 },
                                                                        9: { cellWidth: 55 },
                                                                        10: { cellWidth: 55 },
                                                                        11: { cellWidth: 55 },
                                                                        12: { cellWidth: 55 },
                                                                        text: {
                                                                            cellWidth: 'wrap'
                                                                        }
                                                                    },
                                                                    head: [columnTitles],
                                                                    body: pdfData
                                                                });
                                                            }


                                                            doc.save(`PendingOrderList.pdf`);
                                                        }
                                                    }
                                                }
                                                else {
                                                    this.toasterredfun2("You have no access to download. Contact Administrator.");
                                                }
                                            }
                                        }}
                                        components={{
                                            Header: (props) => (
                                                <TableHead>
                                                    <TableRow>
                                                        {visibleColumns.map((column, index) => (
                                                            <TableCell
                                                                key={column.field}
                                                               
                                                            >
                                                                <TableSortLabel
                                                                 
                                                                    active={this.state.sortColumn === column.field}
                                                                    direction={this.state.sortColumn === column.field ? this.state.sortOrder : 'asc'}
                                                                    onClick={() => this.handleColumnHeaderClick(column)}
                                                                   
                                                                >
                                                                    {column.title}                                                                    
                                                                </TableSortLabel>
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                            ),
                                            Pagination: props => (
                                                <TablePagination
                                                    {...props}
                                                    rowsPerPageOptions={[5, 10, 20]}
                                                    rowsPerPage={this.state.PageLimit}
                                                    count={this.state.totalRecord}
                                                    page={this.state.currentPage}
                                                    onChangeRowsPerPage={event => {                                                       
                                                        props.onChangeRowsPerPage(event);
                                                        this.setState({ PageLimit: event.target.value, currentPage: 0 });
                                                        localStorage.setItem('ClickedPageInResult', 1);
                                                        this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, 0, event.target.value, this.state.sortColumn,this.state.sortOrder);
                                                    }}
                                                    onChangePage={(event, page) => {
                                                        let ClickedButton = event.nativeEvent.target.innerText;
                                                        localStorage.setItem('ClickedPageInResult', ClickedButton)
                                                        if (ClickedButton == "first_page") {
                                                            this.setState({ currentPage: 0 })
                                                            this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, 0, this.state.PageLimit, this.state.sortColumn,this.state.sortOrder);
                                                        }
                                                        else if (ClickedButton == "chevron_left") {
                                                            this.setState({ currentPage: this.state.currentPage - 1 })
                                                            let Chevronleftvalue = this.state.currentPage - 1
                                                            localStorage.setItem('ChevronleftInResult', Chevronleftvalue)
                                                            let offSet = (this.state.currentPage - 1) * this.state.PageLimit;
                                                            this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder);
                                                        }
                                                        else if (ClickedButton == "chevron_right") {
                                                            this.setState({ currentPage: this.state.currentPage + 1 })
                                                            let Chevronrightvalue = this.state.currentPage + 1
                                                            localStorage.setItem('ChevronrightInResult', Chevronrightvalue)
                                                            let offSet = (this.state.currentPage + 1) * this.state.PageLimit;
                                                            this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder);
                                                        }
                                                        else if (ClickedButton == "last_page") {
                                                            var lp = this.state.totalRecord / this.state.PageLimit;
                                                            lp = Math.ceil(lp) - 1;
                                                            let offSet;
                                                            if (lp == 0) {
                                                                this.setState({ currentPage: 1 })
                                                                offSet = this.state.PageLimit
                                                            }
                                                            else {
                                                                this.setState({ currentPage: lp })
                                                                offSet = lp * this.state.PageLimit
                                                            }
                                                            this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder);
                                                        }
                                                        else {
                                                            let offSet;
                                                            if (ClickedButton > this.state.currentPage) {
                                                                this.setState({ currentPage: this.state.currentPage + 1 })
                                                                offSet = (this.state.currentPage + 1) * this.state.PageLimit;
                                                            }
                                                            else {
                                                                this.setState({ currentPage: this.state.currentPage - 1 })
                                                                offSet = (this.state.currentPage - 1) * this.state.PageLimit;
                                                            }
                                                            this.LandingPageOrders(this.state.status, this.state.selectDropDownUsers, this.state.selectDropDownCycleTime, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder);
                                                        }
                                                    }}
                                                />
                                            ),
                                        }}

                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span className={"loading " + (this.state.loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} /> </span>
            </Layout>
        );
    }
}
