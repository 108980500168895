import React, { Component } from 'react';
import { Layout } from "./Layout";
import 'rc-datepicker/lib/style.css';
import $ from 'jquery';
import Api from '../serviceCall';
import _ from "lodash";


export class B2BConfiguration extends Component {
    static displayName = B2BConfiguration.name;
    constructor(props) {
        super(props);
        this.state = {
            loading: false, toasterredCla: false, toasterCla: false, toaster: "", selectDropDownSource: "--Select--", selectDropDownFormat: "--Select--", selectDropDownDataFormat: "--Select--",
            Loading: true, ShowSFTP: true, ShowWebApi: false, ShowEmail: false, ShowOutbound: false,
            CompanyCode: "", Direction: "",
            SftpInboundHostUrl: "", SftpAPIUrl: "", SftpInboundPortNumber: 0, SftpInboundUserName: "", SftpInboundPassword: "", SftpInboundFolderPath: "",
            SftpInboundArchiveFolderPath: "", SftpInboundFileFormat: "", rSftpInboundHostUrl: false,
            rCompanyCode: false, rAPIUrl: false, rSftpInboundPortNumber: false, rSftpInboundUserName: false, rSftpInboundPassword: false,
            rSftpInboundFolderPath: false, rSftpInboundArchiveFolderPath: false, rSftpInboundFileFormat: false,
            rOutboundOrderDetails: false, rSource: false, rFormat: false, rDataFormat: false, IsFiles: false, IsImages: false, DirectionType: "Inbound", SFTPOperation: "CToE", ShowWebAPIMenuRO: true, ShowSFTPMenuRO: true, ResponseRequired: "Yes"
        };
        this.handleSourceTypeChange = this.handleSourceTypeChange.bind(this);
        this.handleSubmitB2CConfig = this.handleSubmitB2CConfig.bind(this);
        this.handleCompanyCodeChange = this.handleCompanyCodeChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleAPIUrlChange = this.handleAPIUrlChange.bind(this);
        this.handleSftpInboundHostUrl = this.handleSftpInboundHostUrl.bind(this);
        this.handleSftpInboundPortNumberChange = this.handleSftpInboundPortNumberChange.bind(this);
        this.handleSftpInboundUserNameChange = this.handleSftpInboundUserNameChange.bind(this);
        this.handleSftpInboundPasswordChange = this.handleSftpInboundPasswordChange.bind(this);
        this.handleSftpInboundFolderPathChange = this.handleSftpInboundFolderPathChange.bind(this);
        this.handleSftpInboundArchiveFolderPathChange = this.handleSftpInboundArchiveFolderPathChange.bind(this);
        this.handleFormatChange = this.handleFormatChange.bind(this);
        this.handleDataFormatChange = this.handleDataFormatChange.bind(this);
        this.handleOutboundOrderDetailsChange = this.handleOutboundOrderDetailsChange.bind(this);
        this.handleDirectionChange = this.handleDirectionChange.bind(this);
        this.handleSFTPOperationChange = this.handleSFTPOperationChange.bind(this);
        this.handleResponseRequiredChange = this.handleResponseRequiredChange.bind(this);
        this.handleFiles = this.handleFiles.bind(this);
        this.handleImages = this.handleImages.bind(this);
        this.toasterredfun2 = this.toasterredfun2.bind(this);
    }

    componentWillMount() {
        let currentComponent = this;
        currentComponent.setState({ Loading: false });
    }
    componentDidMount() {
        this.setState({ loading: false });
        /*var token = localStorage.getItem("UserId");
        if (token == null || token.length == 0) {
            this.props.history.push('/');
        }*/
    }
    toasterredfun2(event) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: event });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }
    toasterredfun(event) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: event });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
                this.props.history.goBack();
            }.bind(this), 5000);
    }

    handleSourceTypeChange(e) {
        if (e.target.value == "SFTP") {
            this.setState({ ShowSFTP: true, ShowWebApi: false, ShowEmail: false });
        } else if (e.target.value == "Web API") {
            this.setState({ ShowSFTP: false, ShowWebApi: true, ShowEmail: false });
        } else if (e.target.value == "Email") {
            this.setState({ ShowSFTP: false, ShowWebApi: false, ShowEmail: true });
        }
    }
    handleDirectionChange = (event) => {
        this.setState({ DirectionType: event.target.value });
        if (event.target.value === 'Inbound') {
            this.setState({ ShowOutbound: false, IsFiles: false, IsImages: false, ShowWebApi: true });
        }
        else if (event.target.value === 'Outbound') {
            this.setState({ ShowOutbound: true, ShowWebApi: true });
        }
        else {
            this.setState({ ShowOutbound: false, ShowWebApi: true });
        }
    }
    handleSFTPOperationChange(e) {
        this.setState({ SFTPOperation: e.target.value });
    }
    handleResponseRequiredChange = (event) => {
        this.setState({ ResponseRequired: event.target.value });
        if (event.target.value === 'Yes') {
        }
        else if (event.target.value === 'No') {
        }
        else {
        }
    }
    handleFiles = (e) => {
        if (this.state.ShowOutbound) {
            this.setState({

                IsFiles: e.target.checked
            });
        }
    };
    handleImages = (e) => {
        if (this.state.ShowOutbound) {
            this.setState({
                IsImages: e.target.checked
            });
        }
    };
    handleSourceChange = (event) => {
        this.setState({
            selectDropDownSource: event.target.value,
        });
        if (event.target.value === 'SFTP') {
            this.setState({ SftpAPIUrl: "", ShowSFTPMenuRO: false, ShowWebAPIMenuRO: true, rSource: false });
        }
        else if (event.target.value === 'Web API') {
            this.setState({ SftpInboundHostUrl: "", SftpInboundPortNumber: 0, SftpInboundUserName: "", SftpInboundPassword: "", SftpInboundFolderPath: "", SftpInboundArchiveFolderPath: "", ShowSFTPMenuRO: true, ShowWebAPIMenuRO: false, rSource: false });
        }
        else {
            this.setState({ ShowSFTPMenuRO: true, ShowWebAPIMenuRO: true, rSource: true });
        }
    }
    handleFormatChange = (event) => {
        this.setState({
            selectDropDownFormat: event.target.value,
        });
        if (event.target.value === 'XML') {
            this.setState({ rFormat: false });
        }
        else if (event.target.value === 'Json') {
            this.setState({ rFormat: false });
        }
        else {
            this.setState({ rFormat: true });
        }

    }
    handleDataFormatChange = (event) => {
        this.setState({
            selectDropDownDataFormat: event.target.value,
        });
        if (event.target.value === 'Acord') {
            this.setState({ rDataFormat: false });
        }
        else if (event.target.value === 'Custom') {
            this.setState({ rDataFormat: false });
        }
        else {
            this.setState({ rDataFormat: true });
        }
    }
    handleOutboundOrderDetailsChange = (event) => {
        this.setState({
            selectDropDownOutboundOrderDetails: event.target.value,
        });
        if (event.target.value === 'FilesOnly') {
            this.setState({ rOutboundOrderDetails: false });
        }
        else if (event.target.value === 'FilesPlusImages') {
            this.setState({ rOutboundOrderDetails: false });
        }
        else {
            this.setState({ rOutboundOrderDetails: true });
        }

    }
    handleSubmitB2CConfig(e) {
        let currentComponent = this;
        currentComponent.setState({ addvalid: true });
        currentComponent.state.addvalid = true;
        if (document.getElementById("Source").value != "--Select--") {

        } else {
            currentComponent.state.addvalid = false;
            currentComponent.state.rSource = false;
            currentComponent.setState({ rSource: true, addvalid: false });
        }
        if (document.getElementById("Format").value != "--Select--") {

        } else {
            currentComponent.state.addvalid = false;
            currentComponent.state.rFormat = false;
            currentComponent.setState({ rFormat: true, addvalid: false });
        }
        if (document.getElementById("DataFormat").value != "--Select--") {

        } else {
            currentComponent.state.addvalid = false;
            currentComponent.state.rDataFormat = false;
            currentComponent.setState({ rDataFormat: true, addvalid: false });
        }
        if (currentComponent.state.CompanyCode.length > 0) {
            currentComponent.state.rCompanyCode = false;
        } else {
            currentComponent.state.addvalid = false;
            currentComponent.state.rCompanyCode = false;
            currentComponent.setState({ rCompanyCode: true, addvalid: false });
        }
        if (!currentComponent.state.ShowSFTPMenuRO) {
            if (currentComponent.state.SftpInboundHostUrl.length > 0) {

            } else {
                currentComponent.state.addvalid = false;
                currentComponent.state.rSftpInboundHostUrl = false;
                currentComponent.setState({ rSftpInboundHostUrl: true, addvalid: false });
            }
            if (currentComponent.state.SftpInboundPortNumber.length > 0) {

            } else {
                currentComponent.state.addvalid = false;
                currentComponent.state.rSftpInboundPortNumber = false;
                currentComponent.setState({ rSftpInboundPortNumber: true, addvalid: false });
            }
            if (currentComponent.state.SftpInboundUserName.length > 0) {

            } else {
                currentComponent.state.addvalid = false;
                currentComponent.state.rSftpInboundUserName = false;
                currentComponent.setState({ rSftpInboundUserName: true, addvalid: false });
            }
            if (currentComponent.state.SftpInboundPassword.length > 0) {

            } else {
                currentComponent.state.addvalid = false;
                currentComponent.state.rSftpInboundPassword = false;
                currentComponent.setState({ rSftpInboundPassword: true, addvalid: false });
            }
            if (currentComponent.state.SftpInboundFolderPath.length > 0) {

            } else {
                currentComponent.state.addvalid = false;
                currentComponent.state.rSftpInboundFolderPath = false;
                currentComponent.setState({ rSftpInboundFolderPath: true, addvalid: false });
            }
            if (currentComponent.state.SftpInboundArchiveFolderPath.length > 0) {

            } else {
                currentComponent.state.addvalid = false;
                currentComponent.state.rSftpInboundArchiveFolderPath = false;
                currentComponent.setState({ rSftpInboundArchiveFolderPath: true, addvalid: false });
            }

        }
        if (!currentComponent.state.ShowWebAPIMenuRO) {
            if (currentComponent.state.SftpAPIUrl.length > 0) {

            } else {
                currentComponent.state.addvalid = false;
                currentComponent.state.rAPIUrl = false;
                currentComponent.setState({ rAPIUrl: true, addvalid: false });
            }
        }
        if (this.state.addvalid) {
            let currentComponent = this;
            currentComponent.setState({ Loading: true });
            var outboundOrderDetails = "";
            var isInbound = false;
            var isHostedByeNoah = false;
            if (currentComponent.state.IsFiles) {
                outboundOrderDetails = "Files";
            }
            if (currentComponent.state.IsImages) {
                outboundOrderDetails = outboundOrderDetails + "," + "Images";
            }
            if (currentComponent.state.DirectionType == "Inbound") {
                isInbound = true;
            }
            if (currentComponent.state.SFTPOperation == "CToE") {
                isHostedByeNoah = true;
            }
            let data = {
                CompanyCode: currentComponent.state.CompanyCode,
                IsInbound: isInbound,
                Source: currentComponent.state.selectDropDownSource,
                IsHostedByeNoah: isHostedByeNoah,
                SftpHostUrl: currentComponent.state.SftpInboundHostUrl,
                PortNumber: currentComponent.state.SftpInboundPortNumber,
                UserName: currentComponent.state.SftpInboundUserName,
                Password: currentComponent.state.SftpInboundPassword,
                FolderPath: currentComponent.state.SftpInboundFolderPath,
                ArchiveFolderPath: currentComponent.state.SftpInboundArchiveFolderPath,
                FileFormat: currentComponent.state.selectDropDownFormat,
                ApiUrl: currentComponent.state.SftpAPIUrl,
                IsResponseRequired: currentComponent.state.ResponseRequired == "Yes",
                DataFormat: currentComponent.state.selectDropDownDataFormat,
                OutboundOrderDetails: outboundOrderDetails,
            };

            Api.Post('api/placeorder/CreateB2BConfiguration', data, currentComponent).then(function (result) {
                if (result.statusMessage == "Success") {
                    currentComponent.setState({
                        Loading: false
                    });
                    currentComponent.toasterredfun2("B2B Configuartion Record Added.");
                    $("#clear").trigger("click");
                }
                else {
                    currentComponent.toasterredfun2(result.statusMessage);
                    currentComponent.setState({ Loading: false });
                }
            }).catch(function (error) {
                currentComponent.setState({ url: '', Loading: false });
                if (error != null) {
                    currentComponent.toasterredfun2(error);
                }
            });
        }

    }
    handleClear(e) {
        this.setState({ removeAlertx: false });
        this.setState({ Loading: true });
        this.setState({
            selectDropDownSource: "--Select--", rCompanyCode: false, rAPIUrl: false, rSftpInboundHostUrl: false, rSftpInboundPortNumber: false, rSftpInboundUserName: false, rSftpInboundPassword: false, rSftpInboundFolderPath: false,
            rSftpInboundArchiveFolderPath: false, rDirection: false, rSource: false, rOperation: false, rFormat: false, rDataFormat: false,
            rOutboundOrderDetails: false,
            selectDropDownFormat: "--Select--", selectDropDownDataFormat: "--Select--", selectDropDownOutboundOrderDetails: "--Select--",
            ShowOutbound: false, ShowSFTPMenuRO: true, ShowWebAPIMenuRO: true, IsFiles: false, IsImages: false, DirectionType: "Inbound", SFTPOperation: "CToE", ResponseRequired: "Yes"
        });

        document.getElementById("Source").value = "--Select--";
        document.getElementById("DataFormat").value = "--Select--";
        document.getElementById("CompanyCode").value = "";
        document.getElementById("SftpAPIUrl").value = "";
        document.getElementById("SftpInboundHostUrl").value = "";
        document.getElementById("SftpInboundPortNumber").value = 0;
        document.getElementById("SftpInboundUserName").value = 0;
        document.getElementById("SftpInboundPassword").value = "";
        document.getElementById("SftpInboundFolderPath").value = "";
        document.getElementById("SftpInboundArchiveFolderPath").value = "";

        this.setState({ CompanyCode: "", SftpAPIUrl: "", SftpInboundHostUrl: "", SftpInboundPortNumber: 0, SftpInboundUserName: "", SftpInboundPassword: "", SftpInboundFolderPath: "", SftpInboundArchiveFolderPath: "" });
        this.setState({ Loading: false });

        setTimeout(
            function () {
                $(".clear-button").trigger("click");
            }.bind(this),
            100
        );
    }
    handleCompanyCodeChange(e) {
        this.setState({ CompanyCode: e.target.value });
    }
    handleAPIUrlChange(e) {
        this.setState({ SftpAPIUrl: e.target.value });
    }
    handleSftpInboundHostUrl(e) {
        this.setState({ SftpInboundHostUrl: e.target.value });
    }
    handleSftpInboundPortNumberChange(e) {
        this.setState({ SftpInboundPortNumber: e.target.value });
    }
    handleSftpInboundUserNameChange(e) {
        this.setState({ SftpInboundUserName: e.target.value });
    }
    handleSftpInboundPasswordChange(e) {
        this.setState({ SftpInboundPassword: e.target.value });
    }
    handleSftpInboundFolderPathChange(e) {
        this.setState({ SftpInboundFolderPath: e.target.value });
    }
    handleSftpInboundArchiveFolderPathChange(e) {
        this.setState({ SftpInboundArchiveFolderPath: e.target.value });
    }
    render() {
        var landing = "img/ems-logo.gif";
        return (
            <Layout>
                <span id="load" className={"loading " + (this.state.Loading ? "show" : "hide")}>
                    <span className="loading-div-d"></span>{" "}
                    <img src={process.env.PUBLIC_URL + landing} />{" "}
                </span>
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}>
                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                    {this.state.toaster}{" "}
                </div>
                <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}>
                    <i className="fa fa-exclamation-circle" aria-hidden="true"></i>{" "}
                    {this.state.toaster}{" "}
                </div>
                <div className='hgtDiv bg-white mt-2 p-3'>
                    <div className="">
                        <div className="row">
                            <div className="col-xl-6 mt-4">
                                <div className="card mb-4 placeholder-div">
                                    <div className={this.state.ShowSFTP ? "card mb-1 placeholder-div show" : "hide"}>
                                        <div className="card-header">
                                            B2B Configurations
                                        </div>
                                        <div className="card-body">
                                            <div className="card mb-1 placeholder-div show">
                                                <div className="card-body">
                                                    <div className="col-md-12 nopadding mt-2 pull-left">
                                                        <div className="col-md-4 pull-left nopadding">
                                                            <label className="small mb-1" htmlFor="CompanyCode">Company Code:</label>
                                                            <span className="red">*</span>
                                                        </div>
                                                        <div className="col-md-8 pull-left nopadding">
                                                            <input
                                                                className={"form-control " + (this.state.rCompanyCode ? "redborder" : "blackborder")}
                                                                id="CompanyCode"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.CompanyCode}
                                                                maxLength={15}
                                                                onChange={this.handleCompanyCodeChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 nopadding mt-2 pull-left">
                                                        <div className="col-md-4 pull-left nopadding">
                                                            <label className="small mb-1" htmlFor="ViewType"> Direction: </label>
                                                        </div>
                                                        <div className="col-md-8 pull-left nopadding">
                                                            <div className="form-group pull-left mr-6 radioLable-x">
                                                                <input type="radio" id="ViewType" name="customedate2" value="Inbound" onChange={this.handleDirectionChange} checked={this.state.DirectionType == "Inbound"} />
                                                                <label className="small" htmlFor="ViewType">Inbound</label>
                                                            </div>
                                                            <div className="form-group pull-left mr-6 radioLable-x">
                                                                <input type="radio" id="ViewType" name="customedate2" value="Outbound" onChange={this.handleDirectionChange} checked={this.state.DirectionType == "Outbound"} />
                                                                <label className="small" htmlFor="ViewType">Outbound</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 nopadding mt-2 pull-left">
                                                        <div className="col-md-4 pull-left nopadding">
                                                            <label className="small mb-1" htmlFor="Source"> Source:</label>
                                                            <span className="red">*</span>
                                                        </div>
                                                        <div className="col-md-8 pull-left nopadding">
                                                            <select id="Source" name="Source" className={"form-control " + (this.state.rSource ? "redborder" : "blackborder")} value={this.state.selectDropDownSource} onChange={this.handleSourceChange}>
                                                                <option value="--Select--">--Select--</option>
                                                                <option value="SFTP">SFTP</option>
                                                                <option value="Web API">Web API</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 nopadding mt-2 pull-left">
                                                        <div className="col-md-4 pull-left nopadding">
                                                            <label className="small mb-1" htmlFor="SFTPOperation"> SFTP Operation:</label>
                                                            <span className="red">*</span>
                                                        </div>
                                                        <div className="col-md-8 pull-left nopadding">
                                                            <div className="form-group pull-left mr-35 nopadding radioLable-x">
                                                                <input type="radio" id="SFTPOperation" name="customedate3" value="CToE" onChange={this.handleSFTPOperationChange} checked={this.state.SFTPOperation == "CToE"} />
                                                                <label className="small" htmlFor="SFTPOperation">Hosted By Enoah</label>
                                                            </div>
                                                            <div className="form-group pull-left mr-6 radioLable-x">
                                                                <input type="radio" id="SFTPOperation" name="customedate3" value="EToC" onChange={this.handleSFTPOperationChange} checked={this.state.SFTPOperation == "EToC"} />
                                                                <label className="small" htmlFor="SFTPOperation">Hosted By Customer</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 nopadding mt-2 pull-left">
                                                        <div className="col-md-4 pull-left nopadding">
                                                            <label className="small mb-1" htmlFor="SftpInboundHostUrl"> SFTP Host Url:</label>
                                                            <span className="red">*</span>
                                                        </div>
                                                        <div className="col-md-8 pull-left nopadding">
                                                            <input className={"form-control " + (this.state.rSftpInboundHostUrl ? "redborder" : "blackborder")} id="SftpInboundHostUrl" value={this.state.SftpInboundHostUrl} onChange={this.handleSftpInboundHostUrl} type="text" autoComplete="off" readOnly={this.state.ShowSFTPMenuRO ? "readOnly" : ""} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 nopadding mt-2 pull-left">
                                                        <div className="col-md-4 pull-left nopadding">
                                                            <label className="small mb-1" htmlFor="SftpInboundPortNumber"> Port Number:</label>
                                                            <span className="red">*</span>
                                                        </div>
                                                        <div className="col-md-8 pull-left nopadding">
                                                            <input className={"form-control " + (this.state.rSftpInboundPortNumber ? "redborder" : "blackborder")} id="SftpInboundPortNumber" value={this.state.SftpInboundPortNumber} onChange={this.handleSftpInboundPortNumberChange} type="text" autoComplete="off" readOnly={this.state.ShowSFTPMenuRO ? "readOnly" : ""} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 nopadding mt-2 pull-left">
                                                        <div className="col-md-4 pull-left nopadding">
                                                            <label className="small mb-1" htmlFor="SftpInboundUserName"> SFTP UserName:</label>
                                                            <span className="red">*</span>
                                                        </div>
                                                        <div className="col-md-8 pull-left nopadding">
                                                            <input className={"form-control " + (this.state.rSftpInboundUserName ? "redborder" : "blackborder")} id="SftpInboundUserName" value={this.state.SftpInboundUserName} onChange={this.handleSftpInboundUserNameChange} type="text" autoComplete="off" readOnly={this.state.ShowSFTPMenuRO ? "readOnly" : ""} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 nopadding mt-2 pull-left">
                                                        <div className="col-md-4 pull-left nopadding">
                                                            <label className="small mb-1" htmlFor="SftpInboundPassword"> SFTP Password:</label>
                                                            <span className="red">*</span>
                                                        </div>
                                                        <div className="col-md-8 pull-left nopadding">
                                                            <input className={"form-control " + (this.state.rSftpInboundPassword ? "redborder" : "blackborder")} id="SftpInboundPassword" maxLength={15} value={this.state.SftpInboundPassword} onChange={this.handleSftpInboundPasswordChange} type="text" autoComplete="off" readOnly={this.state.ShowSFTPMenuRO ? "readOnly" : ""} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 nopadding mt-2 pull-left">
                                                        <div className="col-md-4 pull-left nopadding">
                                                            <label className="small mb-1" htmlFor="SftpInboundFolderPath"> File Folder Path:</label>
                                                            <span className="red">*</span>
                                                        </div>
                                                        <div className="col-md-8 pull-left nopadding">
                                                            <input className={"form-control " + (this.state.rSftpInboundFolderPath ? "redborder" : "blackborder")} id="SftpInboundFolderPath" ref="ocp" value={this.state.SftpInboundFolderPath} onChange={this.handleSftpInboundFolderPathChange} type="text" autoComplete="off" readOnly={this.state.ShowSFTPMenuRO ? "readOnly" : ""} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 nopadding mt-2 pull-left">
                                                        <div className="col-md-4 pull-left nopadding">
                                                            <label className="small mb-1" htmlFor="SftpInboundArchiveFolderPath"> Archive Folder Path:</label>
                                                            <span className="red">*</span>
                                                        </div>
                                                        <div className="col-md-8 pull-left nopadding">
                                                            <input className={"form-control " + (this.state.rSftpInboundArchiveFolderPath ? "redborder" : "blackborder")} id="SftpInboundArchiveFolderPath" ref="ocp" value={this.state.SftpInboundArchiveFolderPath} onChange={this.handleSftpInboundArchiveFolderPathChange} type="text" autoComplete="off" readOnly={this.state.ShowSFTPMenuRO ? "readOnly" : ""} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 nopadding mt-2 pull-left">
                                                        <div className="col-md-4 pull-left nopadding">
                                                            <label className="small mb-1" htmlFor="SftpAPIUrl"> API Url:</label>
                                                            <span className="red">*</span>
                                                        </div>
                                                        <div className="col-md-8 pull-left nopadding">
                                                            <input className={"form-control " + (this.state.rAPIUrl ? "redborder" : "blackborder")} id="SftpAPIUrl" value={this.state.SftpAPIUrl} onChange={this.handleAPIUrlChange} type="text" maxLength={100} autoComplete="off" readOnly={this.state.ShowWebAPIMenuRO ? "readOnly" : ""} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 nopadding mt-2 pull-left">
                                                        <div className="col-md-4 pull-left nopadding">
                                                            <label className="small mb-1" htmlFor="Format">File Format:</label>
                                                            <span className="red">*</span>
                                                        </div>
                                                        <div className="col-md-8 pull-left nopadding">
                                                            <select id="Format" name="Format" value={this.state.selectDropDownFormat} className={"form-control " + (this.state.rFormat ? "redborder" : "blackborder")} onChange={this.handleFormatChange}>
                                                                <option value="--Select--">--Select--</option>
                                                                <option value="XML">XML</option>
                                                                <option value="Json">Json</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 nopadding mt-2 pull-left">
                                                        <div className="col-md-4 pull-left nopadding">
                                                            <label className="small mb-1" htmlFor="ViewType2">Response Required:</label>
                                                        </div>
                                                        <div className="col-md-8 pull-left nopadding">
                                                            <div className="form-group pull-left mr-6 radioLable-x">
                                                                <input type="radio" id="ViewType2" name="customedate4" value="Yes" onChange={this.handleResponseRequiredChange} checked={this.state.ResponseRequired == "Yes"} />
                                                                <label className="small" htmlFor="ViewType2">Yes</label>
                                                            </div>
                                                            <div className="form-group pull-left mr-6 radioLable-x">
                                                                <input type="radio" id="ViewType2" name="customedate4" value="No" onChange={this.handleResponseRequiredChange} checked={this.state.ResponseRequired == "No"} />
                                                                <label className="small" htmlFor="ViewType2">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 nopadding mt-2 pull-left">
                                                        <div className="col-md-4 pull-left nopadding">
                                                            <label className="small mb-1" htmlFor="DataFormat">Data Format:</label>
                                                            <span className="red">*</span>
                                                        </div>
                                                        <div className="col-md-8 pull-left nopadding">
                                                            <select id="DataFormat" name="DataFormat" value={this.state.selectDropDownDataFormat} className={"form-control " + (this.state.rDataFormat ? "redborder" : "blackborder")} onChange={this.handleDataFormatChange}>
                                                                <option value="--Select--">--Select--</option>
                                                                <option value="Acord">Acord</option>
                                                                <option value="Custom">Custom</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 nopadding mt-2 pull-left">
                                                        <div className="row">
                                                            <div className="col-md-4 pull-left nopadding">
                                                                <label className="small mb-1" htmlFor="Files">Outbound Order Details:</label>
                                                                <span className="red">*</span>
                                                            </div>
                                                            <div className="col-md-8 pull-left nopadding align-self-end">
                                                                <div className="form-group pull-left mr-6 radioLable-x">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="Files"
                                                                        className="pull-left"
                                                                        name="Files"
                                                                        value="Files"
                                                                        onChange={this.handleFiles}
                                                                        checked={this.state.IsFiles}
                                                                        disabled={this.state.IsNotFiles}
                                                                        readOnly={this.state.ShowWebAPIMenuRO ? "readOnly" : ""}/>
                                                                    <label className="small leftside" htmlFor="Files">
                                                                        Files
                                                                    </label>
                                                                </div>
                                                                <div className="form-group pull-left mr-6 radioLable-x">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="Images"
                                                                        className="pull-left"
                                                                        name="Images"
                                                                        value="Images"
                                                                        onChange={this.handleImages}
                                                                        checked={this.state.IsImages}
                                                                        disabled={this.state.IsNotImages}
                                                                        readOnly={this.state.ShowWebAPIMenuRO ? "readOnly" : ""}/>
                                                                    <label className="small leftside" htmlFor="Images">
                                                                        Images
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clear"> </div>
                                <div className="col-md-12 pull-right">
                                    <ul className="btn-alignment-x float-none">
                                        <li>
                                            <button id="clear" className="btn btn-blue-btnn btn-type" onClick={this.handleClear}>Clear </button>
                                        </li>
                                        <li>
                                            <button className="btn btn-blue-btnn btn-type btn-auto-width" onClick={this.handleSubmitB2CConfig}>Save </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

