import React, { Component } from 'react';
import { Layout } from './Layout';
import MaterialTable from "material-table";
import TablePagination from '@material-ui/core/TablePagination';
import jQuery from 'jquery';
import { DatePicker, DatePickerInput } from 'rc-datepicker';
import $ from 'jquery';
import jwt_decode from 'jwt-decode';
import Api from '../serviceCall';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CsvBuilder } from 'filefy';
import { isNull, isNullOrUndefined } from 'util';
import { TimeoutCounter } from './TimeoutCounter';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';
export class Search extends Component {
    static displayName = Search.name;
    constructor(props) {
        super(props);
        this.state = {
            loading: true, SearchList: [], IndividualFirstName: '', IndividualLastName: '', AttorneyFirstName: '', AttorneyLastName: '', AgentFirstName: '', AgentLastName: '', DOB: "", SSN: "", AgentId: "", RequesterName: "", DL: "", OrderNum: "", PolicyNum: "", ConfirmationNum: "",
            StartDate: "", EndDate: "", DateTyep: "", SearchType: "", toasterCla: false, toasterredCla: false, toaster: "", vdop: false, edate: false, sdate: false, pdob: false, dosd: false, doed: false,
            DateType: "",
            pssn: false,
            lblFirstName: "",
            lblLastName: "",
            lblAgentFirstName: "",
            lblAgentLastName: "",
            lblAdjusterFN: "",
            lblAdjusterLN: "",
            lblSSN: "",
            lblDOB: "",

            lblDoctorFirstName: "",
            lblDoctorLastName: "",
            lblFacilityName: "",
            lblPolicyNum: "",


            FirstNameHide: false,
            LastNameHide: false,
            DoctorLastNameHide: false,
            DoctorFirstNameHide: false,
            FacilityNameHide: false,
            PolicyNumHide: false,

            lblfacilityNameTableHeader: "",
            lblproviderNameTableHeader: "",
            lblfileNumberTableHeader: "",
            RequiredFieldsList: [],
            maxDate: new Date(),
            minDate: "",
            // FirstNameHide: false,

            LastNameHide: false,
            DOBHide: false,
            SSNHide: false,
            AgentIdHide: false,
            PolicyNumHide: false,
            OrderNumberHide: true,
            SICustomDateRangeHide: true,
            ClosedRequestHide: true,
            OnlyOpenOrdersHide: true,
            OnlyAllOrdersHide: true,
            AgentFirstNameHide: false,
            AgentLastNameHide: false,
            AdjusterFNHide: false,
            AdjusterLNHide: false,
            isMandatoryOnlyAllOrders: true,

            UserId: "",
            offSet: 0,
            PageLimit: 5,
            totalRecord: 0,
            currentPage: 0,
            dataForExport: [],
            isSearchClicked: false,

            srIndividualFirstName: "",
            srIndividualLastName: "",
            srAttorneyFirstName: "",
            srAttorneyLastName: "",
            srAgentFirstName: "",
            srAgentLastName: "",
            srDOB: "",
            srRequesterName: "",
            srSSN: "",
            srAgentId: "",
            srDL: "",
            srOrderNum: "",
            srPolicyNum: "",
            srStartDate: "",
            srEndDate: "",
            srSearchType: "",
            emptyDataSourceMessage: "Click on search button to see results",
            hideExportButton: false,
            DefaultStartDate: "",
            DefaultEndDate: "",
            SearchMode: "ExactMatches",
            srSearchMode: false,
            DefaultSearchMode: false,
            SearchMatches: false,
            sortOrder: '',
            sortColumn: '',
            clickCount: 0
        };
        this.handleIFNChange = this.handleIFNChange.bind(this);
        this.handleKeypress = this.handleKeypress.bind(this);
        this.handleILNChange = this.handleILNChange.bind(this);
        this.handleAtFNChange = this.handleAtFNChange.bind(this);
        this.handleAtLNChange = this.handleAtLNChange.bind(this);
        this.handleAgFNChange = this.handleAgFNChange.bind(this);
        this.handleAgLNChange = this.handleAgLNChange.bind(this);
        this.handleDOBChange = this.handleDOBChange.bind(this);
        this.handleSSNChange = this.handleSSNChange.bind(this);
        this.handleAIChange = this.handleAIChange.bind(this);
        this.handleRNChange = this.handleRNChange.bind(this);
        this.handleDLChange = this.handleDLChange.bind(this);
        this.handleONChange = this.handleONChange.bind(this);
        this.handlePNChange = this.handlePNChange.bind(this);
        this.handleCNChange = this.handleCNChange.bind(this);
        this.handleSDChange = this.handleSDChange.bind(this);
        this.handleEDChange = this.handleEDChange.bind(this);
        this.handleDTChange = this.handleDTChange.bind(this);
        this.handleSTChange = this.handleSTChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.GetRequiredFields = this.GetRequiredFields.bind(this);
        this.testName = this.testName.bind(this);
        this.testAlphawithSpace = this.testAlphawithSpace.bind(this);
        this.testON = this.testON.bind(this);
        this.testNum = this.testNum.bind(this);
        this.testDate = this.testDate.bind(this);
        this.testAlphaNumeric = this.testAlphaNumeric.bind(this);
        this.testAlphaNumericWithDash = this.testAlphaNumericWithDash.bind(this);
        this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
        this.formatDateToString = this.formatDateToString.bind(this);
        this.handleDOBclear = this.handleDOBclear.bind(this);
        this.getGridValues = this.getGridValues.bind(this);
        this.exportGridValues = this.exportGridValues.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSearchModeChange = this.handleSearchModeChange.bind(this);
        this.Onclickexportpopup = this.Onclickexportpopup.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.tableRef = React.createRef();

    }
    handlePageChange(e) {
        let CurrentPageLS = localStorage.getItem('ClickedPageInSearch');
        if (e == "first_page" || e == "0") {
            CurrentPageLS = 1;
        }
        else if (e == "chevron_left") {
            CurrentPageLS = parseInt(localStorage.getItem('Chevron-leftClick')) + 1 ;
        }
        else if (e == "chevron_right") {
            CurrentPageLS = parseInt(localStorage.getItem('Chevron-rightClick')) + 1;
        }
        else if (e == "last_page") {
            var lp = localStorage.getItem('TotalRecords') / localStorage.getItem('PageLimitInSearch');
            lp = Math.ceil(lp) - 1;
            if (lp == 0) {
                CurrentPageLS = 1;
            }
            else {
                CurrentPageLS = parseInt(lp) + 1;
            }
        }
        localStorage.setItem('ClickedPageInSearch', CurrentPageLS);
    }
    handleDOBclear() {
        this.setState({ DOB: null });
    }
    formatDateToString(dt) {
        if (dt.toString().length != 0) {
            var date = new Date(dt);
            var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
            var MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
            var yyyy = date.getFullYear();
            return (MM + "/" + dd + "/" + yyyy);
        }
        else {
            return "";
        }
    }
    componentWillMount() {
       /* var jwttoken = localStorage.getItem("UserId");
        if (jwttoken == null || jwttoken.length == 0) {
            this.props.history.push('/');
        }
*/
        let companyType = localStorage.getItem("CompanyType");
        let currentComponent = this;

        var defaultstartdate = new Date(new Date().setDate(new Date().getDate() - 120));
        var defaultenddate = new Date();
        var MinDate = new Date(new Date().getFullYear() - 110, 1, 1);
        var MaxDate = new Date();
        MaxDate.setHours(0, 0, 0, 0);
        MinDate.setHours(0, 0, 0, 0);

        this.setState({ minDate: MinDate, maxDate: MaxDate });

        this.GetRequiredFields();

        var IsRedirectedFromCaseDetails = localStorage.getItem('IsFromCaseDetailsToSearch');
        if (IsRedirectedFromCaseDetails === "true") {
            var searchSortColumn = localStorage.getItem('searchSortColumn');
            var searchSortOrder = localStorage.getItem('searchSortOrder');
            const pageLimit = parseInt(localStorage.getItem('PageLimitInSearch'), 10);          
            var vPageLimit;         
            if (!isNaN(pageLimit)) {
                vPageLimit = pageLimit;
            } else {
                vPageLimit = 5;
            }
            if (localStorage.getItem('srStartDate') != "") {
                var StartDateFromLS = new Date(localStorage.getItem('srStartDate'));
            }
            else {
                var StartDateFromLS = localStorage.getItem('srStartDate');
            }
            if (localStorage.getItem('srEndDate') != "") {
                var EndDateFromLS = new Date(localStorage.getItem('srEndDate'));
            }
            else {
                var EndDateFromLS = localStorage.getItem('srEndDate');
            }
            let decryptedDOB = Api.decryptData(localStorage.getItem('srDOB'));
            if (decryptedDOB != "") {
                var DOBFromLS = new Date(Api.decryptData(localStorage.getItem('srDOB')));
            }
            else {
                var DOBFromLS = Api.decryptData(localStorage.getItem('srDOB'));
            }
            let OffsetValueFromSearch = localStorage.getItem('OffsetValue');
            this.handlePageChange(localStorage.getItem('ClickedPageInSearch'));
            localStorage.removeItem('IsFromCaseDetailsToSearch');
           this.setState({
               currentPage: parseInt(localStorage.getItem('ClickedPageInSearch')) - 1, isSearchClicked: true, srIndividualFirstName: Api.decryptData(localStorage.getItem('srIndividualFirstName')), srIndividualLastName: Api.decryptData(localStorage.getItem('srIndividualLastName')), srAttorneyFirstName: localStorage.getItem('srAttorneyFirstName'), srAttorneyLastName: localStorage.getItem('srAttorneyLastName'),
               srAgentFirstName: localStorage.getItem('srAgentFirstName'), srAgentLastName: localStorage.getItem('srAgentLastName'), srDOB: Api.decryptData(localStorage.getItem('srDOB')), srRequesterName: localStorage.getItem('srRequesterName'), srSSN: Api.decryptData(localStorage.getItem('srSSN')), srAgentId: localStorage.getItem('srAgentId'), srDL: localStorage.getItem('srDL'),
               srOrderNum: localStorage.getItem('srOrderNum'), srPolicyNum: Api.decryptData(localStorage.getItem('srPolicyNum')), srStartDate: localStorage.getItem('srStartDate'), srEndDate: localStorage.getItem('srEndDate'), srSearchType: localStorage.getItem('srSearchType'), srDateType: localStorage.getItem('srDateType'), DefaultSearchMode: localStorage.getItem('LSearchMode'),
               SearchMatches: localStorage.getItem('LSearchMode'),
               IndividualFirstName:Api.decryptData(localStorage.getItem('srIndividualFirstName')),
               IndividualLastName: Api.decryptData(localStorage.getItem('srIndividualLastName')),
               AttorneyFirstName: localStorage.getItem('srAttorneyFirstName'),
               AttorneyLastName: localStorage.getItem('srAttorneyLastName'),
               AgentFirstName: localStorage.getItem('srAgentFirstName'),
               AgentLastName: localStorage.getItem('srAgentLastName'),
               DOB: DOBFromLS,
               RequesterName: localStorage.getItem('srRequesterName'),
               SSN: Api.decryptData(localStorage.getItem('srSSN')),
               AgentId: localStorage.getItem('srAgentId'),
               DL: localStorage.getItem('srDL'),
               OrderNum: localStorage.getItem('srOrderNum'),
               PolicyNum: Api.decryptData(localStorage.getItem('srPolicyNum')),
               StartDate: StartDateFromLS,
               EndDate: EndDateFromLS,
               SearchType: localStorage.getItem('srSearchType'),
               DateType: localStorage.getItem('srDateType'),
               srSearchMode: localStorage.getItem('LSearchMode'),
               sortColumn: searchSortColumn,
               sortOrder: searchSortOrder
           }, () => { this.getGridValues(this.state.UserId, parseInt(OffsetValueFromSearch), vPageLimit, searchSortColumn, searchSortOrder); });
        }
        else {
            this.setState({ DateType: "OrderDate", DefaultStartDate: defaultstartdate, DefaultEndDate: defaultenddate, StartDate: defaultstartdate, EndDate: defaultenddate });
             }
       
        //fetch('api/placeorder/GetSearchResult', {
        //    method: 'post',
        //    headers: {
        //        Accept: 'application/json',
        //        'Content-Type': 'application/json',
        //        "Authorization": "Bearer " + jwttoken,
        //    },
        //    body: JSON.stringify({ "isInitial": true, "UserId": userId })
        //}).then(function (response) {
        //    if (response.status == 401) {
        //        currentComponent.props.history.push('/');
        //    }
        //    else { return response.json(); }
        //}).then(function (result) {
        //    //currentComponent.setState({ SearchList: result.result, loading: false });
        //}).catch(function (error) {
        //    currentComponent.setState({ Loading: false });
        //    if (error == "TypeError: Failed to fetch") {
        //        currentComponent.toasterredfun("Check your internet connection.");
        //    }
        //    else {
        //        currentComponent.toasterredfun(error);
        //    }currentComponent.setState({ loading: false });
        //});
        Api.Get('api/placeorder/GetIsOwnStatus', currentComponent).then(function (result) {
            localStorage.removeItem('ShowAdminMenu');
            localStorage.removeItem('UserType');
            localStorage.removeItem('PlaceOrder');
            localStorage.removeItem('ViewResults');
            localStorage.removeItem('ViewAuth');
            localStorage.removeItem('ExportSearchResults');
            localStorage.removeItem('PerformanceMetrics');
            localStorage.setItem('ShowAdminMenu', result.isAdmin);
            localStorage.setItem('UserType', result.userType);
            localStorage.setItem('PlaceOrder', result.placeOrder);
            localStorage.setItem('ViewResults', result.viewResults);
            localStorage.setItem('ViewAuth', result.viewAuth);
            localStorage.setItem('ExportSearchResults', result.exportSearchResults);
            localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
            var userId = Api.decryptData(result.userId);
            currentComponent.setState({ UserId: userId } );
            if (result.statusCode == '100') {              
                currentComponent.setState({ hideExportButton: result.exportSearchResults });
                currentComponent.setState({ SearchMode: result.searchMode == false ? "ExactMatches" : "SimilarMatches", srSearchMode: result.searchMode, DefaultSearchMode: result.searchMode });
            }

        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun(error);
            }
        });
    }

    GetRequiredFields() {
        var CompanyId = 1;
        /*var token = localStorage.getItem("UserId");
        if (token == null || token.length == 0) {
            this.props.history.push('/');
        }

        var user = jwt_decode(token);
        var userId = user.UserId;
        if (userId.length == 0 || userId == null) {
            this.props.history.push('/');
        }*/

        this.setState({ UserId: this.state.UserId, offSet: 0, PageLimit: 5 });

        let currentComponent = this;
        currentComponent.setState({ loading: true });
        Api.Post('api/placeorder/GetRequiredFields', { "CompanyId": CompanyId }, currentComponent).then(function (result) {
            currentComponent.setState({ RequiredFieldsList: result.requiredFields, loading: false });

            if (currentComponent.state.RequiredFieldsList.length > 0) {
                for (var i = 0; i < currentComponent.state.RequiredFieldsList.length; i++) {
                    currentComponent.setState({
                        [currentComponent.state.RequiredFieldsList[i].className + 'Hide']: currentComponent.state.RequiredFieldsList[i].isActive,
                        // ['isMandatory' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].isRequired,
                    });
                    if (currentComponent.state.RequiredFieldsList[i].customFieldName == "") {

                        //fetch('labelConfigs/Search_Labels.json').then(function (response) {
                        //    return response.json();
                        //}).then(function (response) {
                        //    currentComponent.setState({
                        //        loading: false,
                        //        lblindividualFirstNameSearch: response[companyType].Search_Filter.Individual_First_Name,
                        //        lblindividualLastNameSearch: response[companyType].Search_Filter.Individual_Last_Name,
                        //        lblagentIdSearch: response[companyType].Search_Filter.Agent_Id,
                        //        lblfileNumberSearch: response[companyType].Search_Filter.File_Number,
                        //        lblindividualNameTableHeader: response[companyType].Table_Header.Individual_Name,
                        //        lblfacilityNameTableHeader: response[companyType].Table_Header.Facility_Name,
                        //        lblproviderNameTableHeader: response[companyType].Table_Header.Provider_Name,
                        //        lblfileNumberTableHeader: response[companyType].Table_Header.File_Number
                        //    });
                        //});


                        currentComponent.setState({
                            ['lbl' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].originalFieldName,
                        });
                    }
                    else {
                        currentComponent.setState({
                            ['lbl' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].customFieldName,
                        });
                    }
                }
            }

        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    toasterredfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }
    toasterfun2(e) {
        jQuery("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 5000);
    }
    testName(e) {
        if (/^[._a-zA-Z0-9&'-\s]+$/g.test(e)) {
            if (/\s{2,}/g.test(e)) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    }
    testON(e) {
        if (/^[a-zA-Z0-9-]+$/g.test(e)) {
            if (/-{2,}/g.test(e)) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    }
    testDate(e) {
        if (/^[0-9\/]+$/g.test(e)) {
            return true;
        }
        else {
            return false;
        }
    }
    testNum(e) {
        if (/^[0-9]+$/g.test(e)) {
            if (/-{2,}/g.test(e)) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    }
    testAlphawithSpace(e) {
        if (/^[a-zA-Z\s]+$/g.test(e)) {
            if (/\s{2,}/g.test(e)) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    }
    testAlphaNumeric(e) {
        if (/^[a-zA-Z0-9]+$/g.test(e)) {
            return true;
        }
        else {
            return false;
        }
    }
    testAlphaNumericWithDash(e) {
        if (/^[a-zA-Z0-9-]+$/g.test(e)) {
            return true;
        }
        else {
            return false;
        }
    }
    testAlphaNumericWithSpace(e) {
        if (/^[a-zA-Z0-9\s]+$/g.test(e)) {
            if (/\s{2,}/g.test(e)) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    }
    handleKeypress(event) {
        var key = event.which || event.key;
        if (key == 13) {
            //if (this.state.Username.length != 0 && this.state.Password.length != 0 && this.state.Companycode.length != 0) {
            this.handleSubmitSearch();
            // }
        }
    }

    handleIFNChange(e) {
        if (e.target.value.length != 0) {
            if (this.testName(e.target.value)) {
                this.setState({ IndividualFirstName: e.target.value });
            }
        }
        else {
            this.setState({ IndividualFirstName: e.target.value });
        }
    }
    handleILNChange(e) {
        if (e.target.value.length != 0) {
            if (this.testName(e.target.value)) {
                this.setState({ IndividualLastName: e.target.value });
            }
        }
        else {
            this.setState({ IndividualLastName: e.target.value });
        }
    }
    handleAtFNChange(e) {
        if (e.target.value.length != 0) {
            if (this.testName(e.target.value)) {
                this.setState({ AttorneyFirstName: e.target.value });
            }
        }
        else {
            this.setState({ AttorneyFirstName: e.target.value });
        }
    }
    handleAtLNChange(e) {
        if (e.target.value.length != 0) {
            if (this.testName(e.target.value)) {
                this.setState({ AttorneyLastName: e.target.value });
            }
        }
        else {
            this.setState({ AttorneyLastName: e.target.value });
        }
    }
    handleAgFNChange(e) {
        if (e.target.value.length != 0) {
            if (this.testName(e.target.value)) {
                this.setState({ AgentFirstName: e.target.value });
            }
        }
        else {
            this.setState({ AgentFirstName: e.target.value });
        }
    }
    handleAgLNChange(e) {
        if (e.target.value.length != 0) {
            if (this.testName(e.target.value)) {
                this.setState({ AgentLastName: e.target.value });
            }
        }
        else {
            this.setState({ AgentLastName: e.target.value });
        }
    }
    handleAIChange(e) {
        if (e.target.value.length != 0) {
            if (this.testAlphaNumeric(e.target.value)) {
                this.setState({ AgentId: e.target.value });
            }
        }
        else {
            this.setState({ AgentId: e.target.value });
        }
    }
    handleCNChange(e) {
        if (e.target.value.length != 0) {
            if (this.testNum(e.target.value)) {
                this.setState({ ConfirmationNum: e.target.value });
            }
        }
        else {
            this.setState({ ConfirmationNum: e.target.value });
        }
    }
    handleDLChange(e) {
        this.setState({ DL: e.target.value });
    }
    handleDTChange(e) {
        this.setState({ DateTyep: e.target.value });
        if (e.target.value == "All") {
            this.setState({ StartDate: "", EndDate: "" });
            document.getElementById("sdate").value = "";
            document.getElementById("edate").value = "";
        }
    }
    handleONChange(e) {
        if (e.target.value.length != 0) {
            if (this.testON(e.target.value)) {
                this.setState({ OrderNum: e.target.value });
            }
        }
        else {
            this.setState({ OrderNum: e.target.value });
        }
    }
    handlePNChange(e) {
        if (e.target.value.length != 0) {
            if (this.testAlphaNumericWithDash(e.target.value)) {
                this.setState({ PolicyNum: e.target.value });
            }
        }
        else {
            this.setState({ PolicyNum: e.target.value });
        }
    }
    handleRNChange(e) {
        if (e.target.value.length != 0) {
            //if (this.testAlphawithSpace(e.target.value)) {
            if (this.testAlphaNumericWithSpace(e.target.value)) {
                this.setState({ RequesterName: e.target.value });
            }
        }
        else {
            this.setState({ RequesterName: e.target.value });
        }
    }
    handleSTChange(e) {
        this.setState({ SearchType: e.target.value });
        var _checkopenorder = $("#openorderschecked");
        var _checkorderdate = $("#orderdate");
        if (_checkopenorder.is(':checked')) {
            if (_checkorderdate.is(':checked')) {
                $("#closeorders").prop('disabled', true);
            }
            else {
                $("#closeorders").prop('disabled', true);
                this.setState({ DateType: "", StartDate: "", EndDate: "" });
                this.setState({ dosd: false, doed: false });
            }
        }
        else {
            $("#closeorders").prop('disabled', false);
            this.setState({ DateType: this.state.DateType });
        }
    }
    handleDateChange(e) {
        this.setState({ DateType: e.target.value });
        if (this.state.StartDate == "") {
            this.setState({ dosd: true });

        }
        if (this.state.EndDate == "") {
            this.setState({ doed: true });
        }
    }
    handleSDChange(e) {
        if (e && e instanceof Date) {
            e.setHours(0, 0, 0, 0);
        }
        if (e > this.state.maxDate || e < this.state.minDate) {
            this.setState({ dosd: true });
            document.getElementById("sdate").value = "";
            this.setState({ StartDate: "" });
        } else {
            this.setState({ dosd: false });
            if (e instanceof Date) {
                this.setState({ StartDate: e });
            }
            else {
                this.setState({ StartDate: e });
            }
        }
        if (e <= this.state.EndDate) {
            this.setState({ dosd: false, doed: false });
        }
        if (this.state.DateType != "ClosedDate") {
            if (this.state.StartDate == this.state.DefaultStartDate || this.state.StartDate == "") {
                this.setState({ DateType: "OrderDate" });
            }
        }
    }
    handleEDChange(e) {
        if (e && e instanceof Date) {
            e.setHours(0, 0, 0, 0);
        }
        if (e > this.state.maxDate || e < this.state.minDate) {
            this.setState({ doed: true });
            document.getElementById("edate").value = "";
            this.setState({ EndDate: "" });
        } else {
            this.setState({ doed: false });

            if (e instanceof Date) {
                this.setState({ EndDate: e });
            }
            else {
                this.setState({ EndDate: e });
            }
        }
        if (e >= this.state.StartDate) {
            this.setState({ dosd: false, doed: false });
        }
        if (this.state.DateType != "ClosedDate") {
            if (this.state.EndDate == this.state.DefaultEndDate || this.state.EndDate == "") {
                this.setState({ DateType: "OrderDate" });
            }
        }
    }
    handleSearchModeChange(e) {
       this.setState({ SearchMode: e.target.value, srSearchMode: e.target.value == "ExactMatches" ? false : true });
        }
    handleDOBChange(e) {
        if (e >= this.state.maxDate || e <= this.state.minDate) {
            this.setState({ pdob: true });
            document.getElementById("dobDate").value = "";
            this.setState({ DOB: null });
        } else {
            this.setState({ pdob: false });
            if (e instanceof Date) {
                this.setState({ DOB: e });
                this.setState({ DOB: e });

            }
            else {
                this.setState({ DOB: e });

            }
        }
    }
    Onclickexportpopup() {
        let currentComponent = this;
        currentComponent.setState({ exportAlert: false, loading: false });      
    }
    handleSubmitSearch(e) {
        /*var jwttoken = localStorage.getItem("UserId");
        if (jwttoken == null || jwttoken.length == 0) {
            this.props.history.push('/');
        }
        var user = jwt_decode(jwttoken);
        var userId = user.UserId;*/
        let currentComponent = this, isvalid = true;
        if (this.state.DateType == "OrderDate" || this.state.DateType == "ClosedDate") {
            if (this.state.StartDate == "") {
                isvalid = false;
                this.setState({ dosd: true });

            }
            if (this.state.EndDate == "") {
                isvalid = false;
                this.setState({ doed: true });
            }
        }
        if (this.state.SSN.length != 0 && this.state.SSN.length < 11) {
            isvalid = false;
            this.setState({ pssn: true });
        }
        if (this.state.DOB == "Invalid date" || this.state.DOB == null) {
            if (document.getElementById("dobDate").value.length != 0) {
                isvalid = false;
                this.setState({ pdob: true });
            }
            else {
                this.state.DOB = "";
                this.setState({ DOB: "", pdob: false });
            }
        }
        if (this.state.EndDate == "Invalid date" || this.state.EndDate == null) {
            if (document.getElementById("edate").value.length != 0) {
                isvalid = false;
                this.setState({ doed: true });
            }
            else {
                this.state.EndDate = "";
                this.setState({ EndDate: "", doed: false });
            }
        }
        if (this.state.StartDate == "Invalid date" || this.state.StartDate == null) {
            if (document.getElementById("sdate").value.length != 0) {
                isvalid = false;
                this.setState({ dosd: true });
            }
            else {
                this.state.StartDate = "";
                this.setState({ StartDate: "", dosd: false });
            }
        }
        if (this.state.StartDate instanceof Date && !(this.state.EndDate instanceof Date)) {
            isvalid = false;
            this.setState({ doed: true });
            if (document.getElementById("edate").value.length != 0) {
                this.setState({ EndDate: "" });
            }
        }
        if (!(this.state.StartDate instanceof Date) && this.state.EndDate instanceof Date) {
            isvalid = false;
            this.setState({ dosd: true });
            if (document.getElementById("sdate").value.length != 0) {
                this.setState({ StartDate: "" });
            }
        }
        if (this.state.StartDate instanceof Date && this.state.EndDate instanceof Date && (new Date(this.state.StartDate) > new Date(this.state.EndDate))) {
            this.setState({ dosd: true, doed: true });
            isvalid = false;
        }
        if (isvalid) {
            if (this.state.AgentId.length != 0 || this.state.IndividualFirstName.length != 0 || this.state.IndividualLastName.length != 0 || this.state.AttorneyFirstName.length != 0 || this.state.AttorneyLastName.length != 0 || this.state.AgentFirstName.length != 0 || this.state.AgentLastName.length != 0 || this.state.OrderNum.length != 0 || this.state.RequesterName.length != 0 || this.state.PolicyNum.length != 0 || this.state.DOB instanceof Date || this.state.SSN.length != 0 || ((this.state.StartDate instanceof Date && this.state.EndDate instanceof Date && (new Date(this.state.StartDate) <= new Date(this.state.EndDate)))) || this.state.SearchType.length != 0) {
                isvalid = true;
            }
            else {
                isvalid = false;
                this.toasterredfun("Provide any input to search");
            }
        }
        if (isvalid) {          
            this.setState({
                currentPage: 0, isSearchClicked: true, srIndividualFirstName: this.state.IndividualFirstName, srIndividualLastName: this.state.IndividualLastName, srAttorneyFirstName: this.state.AttorneyFirstName, srAttorneyLastName: this.state.AttorneyLastName,
                srAgentFirstName: this.state.AgentFirstName, srAgentLastName: this.state.AgentLastName, srDOB: this.state.DOB, srRequesterName: this.state.RequesterName, srSSN: this.state.SSN, srAgentId: this.state.AgentId, srDL: this.state.DL,
                srOrderNum: this.state.OrderNum, srPolicyNum: this.state.PolicyNum, srStartDate: this.state.StartDate, srEndDate: this.state.EndDate, srSearchType: this.state.SearchType, srDateType: this.state.DateType, DefaultSearchMode: this.state.srSearchMode,
                SearchMatches: this.state.srSearchMode, sortColumn: '', sortOrder: '', clickCount: 0              
            }, () => { this.getGridValues(this.state.UserId, 0, 5); });
            localStorage.setItem('ClickedPageInSearch', 1);
            //currentComponent.setState({ loading: true, SearchList: [], offSet: offSet, PageLimit: dataLimit });
            //var data = {
            //    "IndividualFirstName": currentComponent.state.IndividualFirstName, "IndividualLastName": currentComponent.state.IndividualLastName, "AttorneyFirstName": currentComponent.state.AttorneyFirstName, "AttorneyLastName": currentComponent.state.AttorneyLastName, "AgentFirstName": currentComponent.state.AgentFirstName, "AgentLastName": currentComponent.state.AgentLastName, "DOB": currentComponent.formatDateToString(currentComponent.state.DOB), "RequesterName": currentComponent.state.RequesterName,
            //    "SSN": currentComponent.state.SSN, "AgentId": currentComponent.state.AgentId, "DLNum": currentComponent.state.DL, "OrderNum": currentComponent.state.OrderNum, "PolicyNum": currentComponent.state.PolicyNum, "UserId": userId,
            //    "StartDate": currentComponent.formatDateToString(currentComponent.state.StartDate), "EndDate": currentComponent.formatDateToString(currentComponent.state.EndDate), "RequestType": currentComponent.state.SearchType, "isInitial": false,
            //    "dataOffSet": offSet, "dataLimit": dataLimit
            //};

            //Api.Post('api/placeorder/GetSearchResult', data, currentComponent).then(function (result) {
            //    currentComponent.setState({ loading: false });
            //    if (result.statusCode == "100") {
            //        currentComponent.setState({ SearchList: result.result, totalRecord: result.RowCount });
            //    } else {
            //        currentComponent.toasterredfun(result.statusMessage);
            //    }
            //});
            //fetch('api/placeorder/GetSearchResult', {
            //    method: 'post',
            //    headers: {
            //        Accept: 'application/json',
            //        'Content-Type': 'application/json',
            //        "Authorization": "Bearer " + localStorage.getItem("UserId"),
            //    },
            //    body: data
            //}).then(function (response) {
            //    if (response.status == 401) {
            //        currentComponent.props.history.push('/');
            //    }
            //    else { return response.json(); }
            //}).then(function (result) {
            //    currentComponent.setState({ SearchList: result.result, loading: false });
            //}).catch(function (error) {
            //    currentComponent.setState({ Loading: false });
            //    if (error == "TypeError: Failed to fetch") {
            //        currentComponent.toasterredfun("Check your internet connection.");
            //    }
            //    else {
            //        currentComponent.toasterredfun(error);
            //    }
            //});
        }
    }

    getGridValues(userId, offSet, dataLimit, fieldName, sortOrder) {
        let currentComponent = this;
        if (currentComponent.state.isSearchClicked == true) {
            currentComponent.setState({ loading: true, offSet: offSet, PageLimit: dataLimit });
            localStorage.setItem('PageLimitInSearch', dataLimit);
            localStorage.setItem('OffsetValue', offSet);
            var data = {
                "IndividualFirstName": Api.encryptData(currentComponent.state.srIndividualFirstName), "IndividualLastName": Api.encryptData(currentComponent.state.srIndividualLastName), "AttorneyFirstName": currentComponent.state.srAttorneyFirstName, "AttorneyLastName": currentComponent.state.srAttorneyLastName,
                "AgentFirstName": currentComponent.state.srAgentFirstName, "AgentLastName": currentComponent.state.srAgentLastName, "DOB": Api.encryptData(currentComponent.formatDateToString(currentComponent.state.srDOB)), "RequesterName": currentComponent.state.srRequesterName,
                "SSN": Api.encryptData(currentComponent.state.srSSN), "AgentId": currentComponent.state.srAgentId, "DLNum": currentComponent.state.srDL, "OrderNum": currentComponent.state.srOrderNum, "PolicyNum": Api.encryptData(currentComponent.state.srPolicyNum),
                "StartDate": currentComponent.formatDateToString(currentComponent.state.srStartDate), "EndDate": currentComponent.formatDateToString(currentComponent.state.srEndDate), "RequestType": currentComponent.state.srSearchType, "isInitial": false,
                "dataOffSet": offSet, "dataLimit": dataLimit, "DateType": currentComponent.state.srDateType, "SearchMode": currentComponent.state.srSearchMode, "fieldName": fieldName, "sortOrder": sortOrder
            };
            localStorage.setItem('LSearchMode', currentComponent.state.srSearchMode);
            //localStorage.setItem('ClickedPageInSearch', 1);
            localStorage.setItem('CurrentPage', '0');
            localStorage.setItem('IsSearchClicked', 'true');
            localStorage.setItem('srIndividualFirstName', Api.encryptData(currentComponent.state.srIndividualFirstName));
            localStorage.setItem('srIndividualLastName', Api.encryptData(currentComponent.state.srIndividualLastName));
            localStorage.setItem('srAttorneyFirstName', currentComponent.state.srAttorneyFirstName);
            localStorage.setItem('srAttorneyLastName', currentComponent.state.srAttorneyLastName);
            localStorage.setItem('srAgentFirstName', currentComponent.state.srAgentFirstName);
            localStorage.setItem('srAgentLastName', currentComponent.state.srAgentLastName);
            localStorage.setItem('srDOB', Api.encryptData(currentComponent.formatDateToString(currentComponent.state.srDOB)));
            localStorage.setItem('srRequesterName', currentComponent.state.srRequesterName);
            localStorage.setItem('srSSN', Api.encryptData(currentComponent.state.srSSN));
            localStorage.setItem('srAgentId', currentComponent.state.srAgentId);
            localStorage.setItem('srDL', currentComponent.state.srDL);
            localStorage.setItem('srOrderNum', currentComponent.state.srOrderNum);
            localStorage.setItem('srPolicyNum', Api.encryptData(currentComponent.state.srPolicyNum));
            localStorage.setItem('srStartDate', currentComponent.formatDateToString(currentComponent.state.srStartDate));
            localStorage.setItem('srEndDate', currentComponent.formatDateToString(currentComponent.state.srEndDate));
            localStorage.setItem('srSearchType', currentComponent.state.srSearchType);
            localStorage.setItem('srDateType', currentComponent.state.srDateType);
            localStorage.setItem('DefaultSearchMode', currentComponent.state.srSearchMode);
            localStorage.setItem('SearchMatches', currentComponent.state.srSearchMode);
            Api.Post('api/placeorder/GetSearchResult', data, currentComponent).then(function (result) {
                currentComponent.setState({ loading: false });
                if (result.statusCode == "100") {
                    currentComponent.setState({ SearchList: [] });
                    if (!isNullOrUndefined(result.result)) {
                        if (result.rowCount == 0) {
                            currentComponent.setState({ emptyDataSourceMessage: "No records to display" });
                        }
                        result.result.map(rowData => {
                            rowData.patientFirstName = Api.decryptData(rowData.patientFirstName);
                            rowData.patientLastName = Api.decryptData(rowData.patientLastName);
                            rowData.patientPolicyNumber = Api.decryptData(rowData.patientPolicyNumber);
                        });                       
                        currentComponent.setState({ SearchList: result.result, totalRecord: result.rowCount });
                        localStorage.setItem('TotalRecords', result.rowCount);
                        currentComponent.setState({ hideExportButton: result.allowExport });
                            localStorage.removeItem('ExportSearchResults');                         
                            localStorage.removeItem('ShowAdminMenu');
                            localStorage.removeItem('UserType');
                            localStorage.removeItem('PlaceOrder');
                            localStorage.removeItem('ViewResults');
                            localStorage.removeItem('ViewAuth');                  
                            localStorage.removeItem('PerformanceMetrics');
                            localStorage.setItem('ShowAdminMenu', result.isAdmin);
                            localStorage.setItem('UserType', result.userType);
                            localStorage.setItem('PlaceOrder', result.placeOrder);
                            localStorage.setItem('ViewResults', result.downloadImages);
                            localStorage.setItem('ViewAuth', result.viewAuth);
                            localStorage.setItem('ExportSearchResults', result.allowExport);
                        localStorage.setItem('PerformanceMetrics', result.performanceMetrics);                      
                        const newPageLimit = dataLimit;
                        if (currentComponent.tableRef.current) {
                            const event = {
                                target: {
                                    value: newPageLimit,
                                },
                            };
                            currentComponent.tableRef.current.onChangeRowsPerPage(event);
                        }
                    }
                    else {
                        currentComponent.setState({ SearchList: [], totalRecord: 0, emptyDataSourceMessage: "No records to display" });
                    }
                } else {
                    //currentComponent.setState({ SearchList: [], emptyDataSourceMessage: "No records to display" });
                    currentComponent.toasterredfun(result.statusMessage);
                }
            });
        }
    }

    async exportGridValues() {
       /* var user = jwt_decode(localStorage.getItem("UserId"));
        var userId = user.UserId;*/
        let currentComponent = this;
        let responce = false;
        if (currentComponent.state.isSearchClicked == true) {
            currentComponent.setState({ loading: true, dataForExport: [] });           
            var data = {
                "IndividualFirstName": Api.encryptData(currentComponent.state.srIndividualFirstName), "IndividualLastName": Api.encryptData(currentComponent.state.srIndividualLastName), "AttorneyFirstName": currentComponent.state.srAttorneyFirstName, "AttorneyLastName": currentComponent.state.srAttorneyLastName,
                "AgentFirstName": currentComponent.state.srAgentFirstName, "AgentLastName": currentComponent.state.srAgentLastName, "DOB": Api.encryptData(currentComponent.formatDateToString(currentComponent.state.srDOB)), "RequesterName": currentComponent.state.srRequesterName,
                "SSN": Api.encryptData(currentComponent.state.srSSN), "AgentId": currentComponent.state.srAgentId, "DLNum": currentComponent.state.srDL, "OrderNum": currentComponent.state.srOrderNum, "PolicyNum": Api.encryptData(currentComponent.state.srPolicyNum), "UserId": this.state.UserId,
                "StartDate": currentComponent.formatDateToString(currentComponent.state.srStartDate), "EndDate": currentComponent.formatDateToString(currentComponent.state.srEndDate), "RequestType": currentComponent.state.srSearchType, "isInitial": false,
                "dataOffSet": "0", "dataLimit": "0", "DateType": currentComponent.state.srDateType, "SearchMode": this.state.SearchMatches
            };

            await Api.Post('api/placeorder/GetSearchResult', data, currentComponent).then(function (result) {             
                if (result.statusCode == "100") {
                    if (!isNullOrUndefined(result.result)) {
                        if (result.allowExport)
                        {
                            result.result.map(rowData => {
                                rowData.patientFirstName = Api.decryptData(rowData.patientFirstName);
                                rowData.patientLastName = Api.decryptData(rowData.patientLastName);
                                rowData.patientPolicyNumber = Api.decryptData(rowData.patientPolicyNumber);
                            });
                            currentComponent.setState({ dataForExport: result.result, loading: false });
                            currentComponent.setState({ hideExportButton: result.allowExport });
                            localStorage.removeItem('ExportSearchResults');                         
                            localStorage.removeItem('ShowAdminMenu');
                            localStorage.removeItem('UserType');
                            localStorage.removeItem('PlaceOrder');
                            localStorage.removeItem('ViewResults');
                            localStorage.removeItem('ViewAuth');                  
                            localStorage.removeItem('PerformanceMetrics');
                            localStorage.setItem('ShowAdminMenu', result.isAdmin);
                            localStorage.setItem('UserType', result.userType);
                            localStorage.setItem('PlaceOrder', result.placeOrder);
                            localStorage.setItem('ViewResults', result.downloadImages);
                            localStorage.setItem('ViewAuth', result.viewAuth);
                            localStorage.setItem('ExportSearchResults', result.allowExport);
                            localStorage.setItem('PerformanceMetrics', result.performanceMetrics);

                        }
                        else
                        {
                            currentComponent.setState({ dataForExport: [], loading: false });                           
                            localStorage.removeItem('ExportSearchResults');                         
                            localStorage.removeItem('ShowAdminMenu');
                            localStorage.removeItem('UserType');
                            localStorage.removeItem('PlaceOrder');
                            localStorage.removeItem('ViewResults');
                            localStorage.removeItem('ViewAuth');                  
                            localStorage.removeItem('PerformanceMetrics');
                            localStorage.setItem('ShowAdminMenu', result.isAdmin);
                            localStorage.setItem('UserType', result.userType);
                            localStorage.setItem('PlaceOrder', result.placeOrder);
                            localStorage.setItem('ViewResults', result.downloadImages);
                            localStorage.setItem('ViewAuth', result.viewAuth);
                            localStorage.setItem('ExportSearchResults', result.allowExport);
                            localStorage.setItem('PerformanceMetrics', result.performanceMetrics)
                            if (currentComponent.state.hideExportButton)

                            {
                                currentComponent.setState({ exportAlert: true });
                            }
                                responce = false;
                                return responce;                           
                        }                       
                    }
                    else {
                        currentComponent.setState({ dataForExport: [], loading: false });
                    }
                    responce = true;
                } else {
                    currentComponent.setState({ dataForExport: [], loading: false });
                    currentComponent.toasterredfun(result.statusMessage);
                    responce = false;
                }
            });
        }
        else {
            responce = false;
        }
        return responce;
    }

    componentDidCatch(error, info) {

    }
    handleClear(e) {
        this.setState({
            sortColumn: '',
            sortOrder: '',
            clickCount: 0,
        });
        this.setState({
            IndividualFirstName: '', IndividualLastName: '', AttorneyFirstName: '', AttorneyLastName: '', AgentFirstName: '',
            AgentLastName: '', DOB: "", SSN: "", AgentId: "", RequesterName: "", DL: "", OrderNum: "", PolicyNum: "", ConfirmationNum: "",
            StartDate: "", EndDate: "", DateTyep: "", SearchType: "", pdob: false, doed: false, dosd: false, SearchList: [],
            isSearchClicked: false, totalRecord: "0", emptyDataSourceMessage: "Click on search button to see results", DateType: "",
            DefaultStartDate: "", DefaultEndDate: "", SearchMode: this.state.DefaultSearchMode == false ? "ExactMatches" : "SimilarMatches"
        });
        $("#closeorders").prop('disabled', false);

        setTimeout(
            function () {
                document.getElementById("dobDate").value = "";
                document.getElementById("sdate").value = "";
                document.getElementById("edate").value = "";
                $('.clear-button').trigger("click");
            }.bind(this),
            100
        );
    }
    handleSSNChange(e) {
        this.setState({ pssn: false });
        var ssnnum = e.target.value.replace(/[^0-9]+/g, "");
        var curStart = e.target.selectionStart;
        if (ssnnum.length == 0) {
            this.setState({ SSN: "" });
        }
        if (/[0-9]$/.test(ssnnum)) {
            let ssnArr = [...ssnnum];
            if (ssnnum.length == 1) {
                this.setState({ SSN: ssnArr[0] }, () => this.refs.ssn.selectionStart = this.refs.ssn.selectionEnd = curStart);
            }
            if (ssnnum.length == 2) {
                this.setState({ SSN: ssnArr[0] + ssnArr[1] }, () => this.refs.ssn.selectionStart = this.refs.ssn.selectionEnd = curStart);
            }
            else if (ssnnum.length == 3) {
                this.setState({ SSN: ssnnum }, () => this.refs.ssn.selectionStart = this.refs.ssn.selectionEnd = curStart);
            }
            else if (ssnnum.length == 4) {
                this.setState({ SSN: ssnArr[0] + ssnArr[1] + ssnArr[2] + "-" + ssnArr[3] }, () => this.refs.ssn.selectionStart = this.refs.ssn.selectionEnd = curStart + 1);
            }
            else if (ssnnum.length == 5) {
                this.setState({ SSN: ssnArr[0] + ssnArr[1] + ssnArr[2] + "-" + ssnArr[3] + ssnArr[4] }, () => this.refs.ssn.selectionStart = this.refs.ssn.selectionEnd = curStart);
            }
            else if (ssnnum.length == 6) {
                this.setState({ SSN: ssnArr[0] + ssnArr[1] + ssnArr[2] + "-" + ssnArr[3] + ssnArr[4] + "-" + ssnArr[5] }, () => this.refs.ssn.selectionStart = this.refs.ssn.selectionEnd = curStart + 1);
            }
            else if (ssnnum.length == 7) {
                this.setState({ SSN: ssnArr[0] + ssnArr[1] + ssnArr[2] + "-" + ssnArr[3] + ssnArr[4] + "-" + ssnArr[5] + ssnArr[6] }, () => this.refs.ssn.selectionStart = this.refs.ssn.selectionEnd = curStart);
            }
            else if (ssnnum.length == 8) {
                this.setState({ SSN: ssnArr[0] + ssnArr[1] + ssnArr[2] + "-" + ssnArr[3] + ssnArr[4] + "-" + ssnArr[5] + ssnArr[6] + ssnArr[7] }, () => this.refs.ssn.selectionStart = this.refs.ssn.selectionEnd = curStart);
            }
            else if (ssnnum.length == 9) {
                this.setState({ SSN: ssnArr[0] + ssnArr[1] + ssnArr[2] + "-" + ssnArr[3] + ssnArr[4] + "-" + ssnArr[5] + ssnArr[6] + ssnArr[7] + ssnArr[8] }, () => this.refs.ssn.selectionStart = this.refs.ssn.selectionEnd = curStart);
            }
        }
    }
    toasterredfun(e) {
        jQuery("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }
    onKeyPressValue(event) {
        var length = event.target.value.length;
        if (length == 1) {
            var n = event.target.value.includes("/");
            if (n) {
                document.getElementById("dobDate").value = "";
            }
        }
        if (length == 2) {
            var n = event.target.value.includes("/");
            if (n) {
                event.target.value = "0" + event.target.value;
            }
        }
        if (length == 5) {
            var n = event.target.value.slice(3, 5).includes("/");
            if (n) {
                event.target.value = [event.target.value.slice(0, 3), "0", event.target.value.slice(3)].join();
            }
        }
        event.target.value = event.target.value.replace(/^(\d\d)(\d)$/g, '$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2').replace(/[^\d\/]/g, '');
        if (length > 10) {
            event.stopPropagation();
        }
        var key = event.which || event.key;
        if (key == 13) {
            //if (this.state.Username.length != 0 && this.state.Password.length != 0 && this.state.Companycode.length != 0) {
            this.handleSubmitSearch();
            // }
        }
    }
    onKeyPressValueStartDate(event) {
        var length = event.target.value.length;
        if (length == 1) {
            var n = event.target.value.includes("/");
            if (n) {
                this.setState({ StartDate: "" });
            }
        }
        if (length == 2) {
            var n = event.target.value.includes("/");
            if (n) {
                event.target.value = "0" + event.target.value;
            }
        }
        if (length == 5) {
            var n = event.target.value.slice(3, 5).includes("/");
            if (n) {
                event.target.value = [event.target.value.slice(0, 3), "0", event.target.value.slice(3)].join();
            }
        }
        event.target.value = event.target.value.replace(/^(\d\d)(\d)$/g, '$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2').replace(/[^\d\/]/g, '');
        if (length > 10) {
            event.stopPropagation();
        }
        this.handleKeypress(event);
    }
    onKeyPressValueEndDate(event) {
        var length = event.target.value.length;
        if (length == 1) {
            var n = event.target.value.includes("/");
            if (n) {
                this.setState({ EndDate: "" });
            }
        }
        if (length == 2) {
            var n = event.target.value.includes("/");
            if (n) {
                event.target.value = "0" + event.target.value;
            }
        }
        if (length == 5) {
            var n = event.target.value.slice(3, 5).includes("/");
            if (n) {
                event.target.value = [event.target.value.slice(0, 3), "0", event.target.value.slice(3)].join();
            }
        }
        event.target.value = event.target.value.replace(/^(\d\d)(\d)$/g, '$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2').replace(/[^\d\/]/g, '');
        if (length > 10) {
            event.stopPropagation();
        }
        this.handleKeypress(event);
    }
    handleColumnHeaderClick = (column) => {
        if (this.state.SearchList.length != 0) {
            let offSet = (this.state.currentPage) * this.state.PageLimit;            
            if (this.state.sortColumn === column.field) {
                const newSortOrder = this.state.sortOrder === 'asc' ? 'desc' : 'asc';
                this.setState({
                    sortOrder: newSortOrder,
                    sortColumn: column.field,
                });
                this.getGridValues(this.state.UserId, offSet, this.state.PageLimit, column.field, newSortOrder);
            } else {
                this.setState({
                    sortColumn: column.field,
                    sortOrder: 'asc',
                });
                this.getGridValues(this.state.UserId, offSet, this.state.PageLimit, column.field, "asc");
            }
        }
    }
    render() {
        var companyType = localStorage.getItem("CompanyType");
        let columns =
            [
                { title: 'Order Date', field: 'createdOnDateTime', type: 'date' },
                { title: 'Close Date', field: 'orderClosedDate', type: 'date' },
                { title: 'eNoah Order Number', field: 'eNoahOrderId' },
                { title: 'Patient ' + this.state.lblFirstName, field: 'patientFirstName', hidden: this.state.FirstNameHide === false },
                { title: 'Patient ' + this.state.lblLastName, field: 'patientLastName', hidden: this.state.LastNameHide === false },
                { title: this.state.lblDoctorFirstName, field: 'doctorFirstName', hidden: this.state.DoctorFirstNameHide === false },
                { title: this.state.lblDoctorLastName, field: 'doctorLastName', hidden: this.state.DoctorLastNameHide === false },
                { title: this.state.lblFacilityName, field: 'facilityName', hidden: this.state.FacilityNameHide === false },
                { title: this.state.lblPolicyNum, field: 'patientPolicyNumber', hidden: this.state.PolicyNumHide === false },
                { title: 'Request Type', field: 'requestType', hidden: companyType === "LIFE" },
                { title: 'Certified', field: 'certified', hidden: companyType === "LIFE" },
                { title: 'Order Type', field: 'orderType', hidden: companyType === "LIFE" },
                { title: 'Requestor Name ', field: 'firstLastName' },
                { title: 'Status ', field: 'status' },
                {title: 'Status Event', field: 'statusEvent'},
                { title: 'EncId ', field: 'encId', hidden: true },
            ];
        let visibleColumns = columns.filter((column) => !column.hidden);      
        var landing = 'img/ems-logo.gif';    
        return (
            <Layout>
                <TimeoutCounter />
                <span className={"loading " + (this.state.loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} /> </span>
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i>
                    {this.state.toaster} </div>
                <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                <div className={"removeAlert-divbrowser " + (this.state.exportAlert ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x text-center">
                        <img src="img/error.png" alt="Error" />
                        <p className="my-3"> Your permissions has been modified/revoked to perform this action. Please contact your administrator.</p>
                        <div className="text-center-div m-0">
                            <div className="bttn popupButton m-0" onClick={this.Onclickexportpopup}>
                                Ok
                            </div>
                        </div>
                    </div>
                </div>
                <div className='hgtDiv bg-white mt-2 p-3'>
                    <div className="">
                        <div className="row ">
                            <div className='border-md col-xl-11'>
                                <div className="row">
                                    <div className="col-xl-5">
                                        <div className={"form-row-xm " + (this.state.FirstNameHide ? "show" : "hide")}>
                                            <div className="col-md-4 pull-left nopadding">
                                                <label className="small mdb-1 pull-right" htmlFor="inputIndividualFirstName">{this.state.lblFirstName}</label>
                                            </div>
                                            <div className="col-md-8 pull-left nopaddingleft">
                                                <input className="form-control" id="inputIndividualFirstName" type="text" onKeyPress={this.handleKeypress} autoComplete="off" value={this.state.IndividualFirstName} onChange={this.handleIFNChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-5">
                                        <div className={"form-row-xm " + (this.state.LastNameHide ? "show" : "hide")}>
                                            <div className="col-md-4 pull-left nopadding">
                                                <label className="small mdb-1 pull-right" htmlFor="inputIndividualLastName">{this.state.lblLastName}</label>
                                            </div>
                                            <div className="col-md-8 pull-left nopaddingleft">
                                                <input className="form-control" id="inputIndividualLastName" type="text" onKeyPress={this.handleKeypress} autoComplete="off" value={this.state.IndividualLastName} onChange={this.handleILNChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-xl-5 " + (companyType == "LEGAL" ? "show" : "hide")}>
                                        <div className={"form-row-xm " + (this.state.AgentFirstNameHide ? "show" : "hide")}>
                                            <div className={"col-md-4 pull-left nopadding  " + (this.state.AgentFirstNameHide ? "show" : "hide")}>
                                                <label className="small mdb-1 pull-right" htmlFor="inputAttorneyFirstName"> Attorney {this.state.lblAgentFirstName}</label>
                                            </div>
                                            <div className={"col-md-8 pull-left nopaddingleft  " + (this.state.AgentFirstNameHide ? "show" : "hide")}>
                                                <input className="form-control" id="inputAttorneyFirstName" type="text" onKeyPress={this.handleKeypress} autoComplete="off" value={this.state.AttorneyFirstName} onChange={this.handleAtFNChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-xl-5 " + (companyType == "LEGAL" ? "show" : "hide")}>
                                        <div className={"form-row-xm " + (this.state.AgentLastNameHide ? "show" : "hide")}>
                                            <div className={"col-md-4 pull-left nopadding  " + (this.state.AgentLastNameHide ? "show" : "hide")}>
                                                <label className="small mdb-1 pull-right" htmlFor="inputAttorneyLastName"> Attorney {this.state.lblAgentLastName}</label>
                                            </div>
                                            <div className={"col-md-8 pull-left nopaddingleft  " + (this.state.AgentLastNameHide ? "show" : "hide")}>
                                                <input className="form-control" id="inputAttorneyLastName" type="text" autoComplete="off" onKeyPress={this.handleKeypress} value={this.state.AttorneyLastName} onChange={this.handleAtLNChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-xl-5 " + (companyType == "LEGAL" && this.state.AdjusterFNHide ? "show" : "hide")}>
                                        <div className="form-row-xm">
                                            <div className="col-md-4 pull-left nopadding">
                                                <label className="small mdb-1 pull-right" htmlFor="inputAgentFirstName">Adjuster {this.state.lblAdjusterFN}</label>
                                            </div>
                                            <div className="col-md-8 pull-left nopaddingleft">
                                                <input className="form-control" id="inputAgentFirstName" type="text" autoComplete="off" onKeyPress={this.handleKeypress} value={this.state.AgentFirstName} onChange={this.handleAgFNChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-xl-5 " + (companyType == "LEGAL" && this.state.AdjusterLNHide ? "show" : "hide")}>
                                        <div className="form-row-xm">
                                            <div className={"col-md-4 pull-left nopadding  " + (this.state.AdjusterLNHide ? "show" : "hide")}>
                                                <label className="small mdb-1 pull-right" htmlFor="inputAgentLastName">Adjuster {this.state.lblAdjusterLN}</label>
                                            </div>
                                            <div className={"col-md-8 pull-left nopaddingleft  " + (this.state.AdjusterLNHide ? "show" : "hide")}>
                                                <input className="form-control" id="inputAgentLastName" type="text" autoComplete="off" onKeyPress={this.handleKeypress} value={this.state.AgentLastName} onChange={this.handleAgLNChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-5">
                                        <div className={"form-row-xm " + (this.state.DOBHide ? "show" : "hide")}>
                                            <div className="col-md-4 pull-left nopadding">
                                                <label className="small mdb-1 pull-right" htmlFor="inputDOB">{this.state.lblDOB} </label>
                                            </div>
                                            <div className="col-md-8 pull-left nopaddingleft">
                                                <div className='input-group dop-d'>
                                                    <DatePickerInput selected={this.state.DOB} value={this.state.DOB} onClear={this.handleDOBclear} id="dobDate" onChange={this.handleDOBChange} maxLength="10" onKeyPress={this.onKeyPressValue.bind(this)} autoComplete="off" className={"docs-date form-control " + (this.state.pdob ? "redborder" : "blackborder")} maxDate={new Date()} minDate={new Date(1910, 1, 1)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-5">
                                        <div className={"form-row-xm " + (this.state.AgentIdHide ? "show" : "hide")}>
                                            <div className="col-md-4 pull-left nopadding">
                                                <label className="small mdb-1 pull-right" htmlFor="inputAgentID">{this.state.lblAgentId}</label>
                                            </div>
                                            <div className="col-md-8 pull-left nopaddingleft">
                                                <input className="form-control" id="inputAgentID" type="text" autoComplete="off" onKeyPress={this.handleKeypress} value={this.state.AgentId} onChange={this.handleAIChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-xl-5 " + (this.state.SSNHide ? "show" : "hide")}>
                                        <div className={"form-row-xm " + (this.state.SSNHide ? "show" : "hide")}>
                                            <div className="col-md-4 pull-left nopadding">
                                                <label className="small mdb-1 pull-right" htmlFor="inputSSN">{this.state.lblSSN}</label>
                                            </div>
                                            <div className="col-md-8 pull-left nopaddingleft">
                                                <input className={"form-control " + (this.state.pssn ? "redborder" : "blackborder")} id="inputSSN" onKeyPress={this.handleKeypress} ref="ssn" type="text" autoComplete="off" value={this.state.SSN} onChange={this.handleSSNChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-5">
                                        <div className="form-row-xm">
                                            <div className="col-md-4 pull-left nopadding">
                                                <label className="small mdb-1 pull-right" htmlFor="inputRequestorName">Requestor Name </label>
                                            </div>
                                            <div className="col-md-8 pull-left nopaddingleft">
                                                <input className="form-control" id="inputRequestorName" type="text" onKeyPress={this.handleKeypress} autoComplete="off" value={this.state.RequesterName} onChange={this.handleRNChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-xl-5 " + (this.state.PolicyNumHide ? "show" : "hide")}>
                                        <div className="form-row-xm">
                                            <div className={"col-md-4 pull-left nopadding  " + (this.state.PolicyNumHide ? "show" : "hide")}>
                                                <label className="small mdb-1 pull-right" htmlFor="inputPolicyNumber">{this.state.lblPolicyNum}</label>
                                            </div>
                                            <div className={"col-md-8 pull-left nopaddingleft  " + (this.state.PolicyNumHide ? "show" : "hide")}>
                                                <input className="form-control" id="inputPolicyNumber" onKeyPress={this.handleKeypress} type="text" autoComplete="off" value={this.state.PolicyNum} onChange={this.handlePNChange} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class={"col-xl-5 " + (companyType == "LIFE" ? "show" : "hide")}>
                                        <div className="form-row-xm">
                                            <div className={"col-md-4 pull-left nopadding  " + (this.state.PolicyNumHide ? "show" : "hide")}>
                                                <label className="small mdb-1 pull-right" htmlFor="inputPolicyNumber">{this.state.lblPolicyNum}</label>
                                            </div>
                                            <div className={"col-md-8 pull-left nopaddingleft  " + (this.state.PolicyNumHide ? "show" : "hide")}>
                                                <input className="form-control" id="inputPolicyNumber" onKeyPress={this.handleKeypress} type="text" autoComplete="off" value={this.state.PolicyNum} onChange={this.handlePNChange} />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className={"col-xl-5 " + (this.state.OrderNumberHide ? "show" : "hide")}>
                                        <div className="form-row-xm">
                                            <div className={"col-md-4 pull-left nopadding  " + (this.state.OrderNumberHide ? "show" : "hide")}>
                                                <label className="small mdb-1 pull-right" htmlFor="inputOrderNumber">Order Number </label>
                                            </div>
                                            <div className={"col-md-8 pull-left nopaddingleft  " + (this.state.OrderNumberHide ? "show" : "hide")}>
                                                <input className="form-control" id="inputOrderNumber" onKeyPress={this.handleKeypress} type="text" autoComplete="off" value={this.state.OrderNum} onChange={this.handleONChange} />
                                            </div>
                                        </div>
                                    </div>
                                      <div className={"col-xl-6 " + (this.state.SICustomDateRangeHide ? "show" : "hide")}>
                                        <div className="form-row-xm">
                                            <div className={"col-md-3 pull-left nopadding  " + (this.state.SICustomDateRangeHide ? "show" : "hide")}>
                                                <label className="small mdb-1 pull-right" htmlFor="inputFirstName">Custom Date Range</label>
                                            </div>
                                            <div className="col-md-9 pull-left nopaddingleft">
                                                <div className="row">
                                                    <div className="col-sm-3 pl-1">
                                                        <div className={"form-group " + (this.state.SICustomDateRangeHide ? "show" : "hide")}>
                                                            <div className='input-group dop-d wid125'>
                                                                <input type="radio" id="orderdate" name="datetype" value="OrderDate" onChange={this.handleDateChange} checked={this.state.DateType == "OrderDate"} />
                                                                <label className="small mr-2" htmlFor="last5years">Order Date</label>

                                                            </div>
                                                        </div>
                                                        <div className={"form-group " + (this.state.SICustomDateRangeHide ? "show" : "hide")}>
                                                            <div className='input-group dop-d wid125'>
                                                                <input type="radio" id="closeorders" name="datetype" value="ClosedDate" onChange={this.handleDateChange} checked={this.state.DateType == "ClosedDate"} />
                                                                <label className="small mr-2" htmlFor="last5years">Close Date</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 pl-0">
                                                        <div className={"form-group " + (this.state.SICustomDateRangeHide ? "show" : "hide")}>
                                                            <div className='input-group dop-d wid70 calendar-icon'>
                                                                <label className="small mr-1 mtop7" htmlFor="last5years">Start Date</label>
                                                                <DatePickerInput selected={this.state.StartDate} id="sdate" autoComplete="off" maxLength="10" onKeyPress={this.onKeyPressValueStartDate.bind(this)} value={this.state.StartDate} onChange={this.handleSDChange} dateformat="MM/dd/yyyy" className={"form-control " + (this.state.dosd ? "redborder" : "blackborder")} defaultValue={this.state.DefaultStartDate} maxDate={new Date()} minDate={new Date(1920, 1, 1)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 pl-6">
                                                        <div className={"form-group " + (this.state.SICustomDateRangeHide ? "show" : "hide")}>
                                                            <div className='input-group dop-d wid70 calendar-icon'>
                                                                <label className="small mr-1 mtop7" htmlFor="last5years">End Date</label>
                                                                <DatePickerInput selected={this.state.EndDate} id="edate" autoComplete="off" maxLength="10" onKeyPress={this.onKeyPressValueEndDate.bind(this)} onChange={this.handleEDChange} value={this.state.EndDate} dateformat="MM/dd/yyyy" className={"form-control " + (this.state.doed ? "redborder" : "blackborder")} defaultValue={this.state.DefaultEndDate} maxDate={new Date()} minDate={new Date(1920, 1, 1)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-5">
                                        <div className={"form-group pull-right radioLable-x  mt-3 " + (this.state.ClosedRequestHide ? "show" : "hide")}>
                                            <input type="radio" id="lastyears" name="customdate" value="Closed" onChange={this.handleSTChange} checked={this.state.SearchType == "Closed"} />
                                            <label className="small" htmlFor="last5years">Closed Request Only</label>
                                        </div>
                                        <div className={"form-group pull-right mr-20 radioLable-x  mt-3 " + (this.state.OnlyOpenOrdersHide ? "show" : "hide")}>
                                            <input type="radio" id="openorderschecked" name="customdate" value="Open" onChange={this.handleSTChange} checked={this.state.SearchType == "Open"} />
                                            <label className="small" htmlFor="last5years">Open Orders Only</label>
                                        </div>
                                        <div className={"form-group pull-right mr-20 radioLable-x  mt-3 " + (this.state.OnlyAllOrdersHide ? "show" : "hide")}>
                                            <input type="radio" id="lastyears" name="customdate" value="All" onChange={this.handleSTChange} checked={this.state.SearchType == "All"} />
                                            <label className="small" htmlFor="last5years">All Orders</label>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="form-row-xm searchpagenotesleft">
                                            <div className={"col-md-3 pull-left nopadding "}>
                                                <label className="small mdb-1 pull-right" htmlFor="searchpagenotes">Note: </label>
                                            </div>
                                            <div className={"col-md-9 pull-left nopaddingleft "}>
                                                <label className="small mdb-1 pull-left" htmlFor="searchpagenotes">
                                                    Choosing a date range that is too large may  cause your search results <br />
                                                     &nbsp;to take a long time to process or may cause the search to fail.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-5">
                                        <div className={"form-group pull-right mr-21 radioLable-x ml-1"}>
                                            <input type="radio" id="similarmatches" name="searchmode" value="SimilarMatches" onChange={this.handleSearchModeChange} checked={this.state.SearchMode == "SimilarMatches"} />
                                            <label className="small" htmlFor="last5years">Search for similar matches</label>
                                        </div>
                                        <div className={"form-group pull-right mr-21 radioLable-x ml-1"}>
                                            <input type="radio" id="exactmatches" name="searchmode" value="ExactMatches" onChange={this.handleSearchModeChange} checked={this.state.SearchMode == "ExactMatches"} />
                                            <label className="small" htmlFor="last5years">Search for exact matches</label>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="form-row-xm searchpagenotesright" >
                                            <div className={"col-md-3 pull-right nopadding rightnote"}>
                                                <label className="small mdb-1 pull-right" htmlFor="searchpagenotes">Note: </label>
                                            </div>
                                            <div className={"col-md-9 pull-right nopaddingleft rightbody"}>
                                                <label className="small mdb-1 pull-right" htmlFor="searchpagenotes">
                                                    Choosing an exact match search will decrease the time it takes to return <br />
                                                    &nbsp;results. Choosing a similar match will increase the time to return results.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 pull-left">
                                </div>
                                <div className='clear'></div>
                                <div className="col-xl-10 pull-left mt-2">
                                </div>
                                <div className="col-xl-2 pull-left">
                                </div>
                                <div className='clear'></div>
                                <div className="col-xl-10 pull-left nopadding">
                                    <button className="btn btn-blue-btnnm btn-type pull-right mt-4 mb-1 mr-1 onconfirm" onClick={this.handleSubmitSearch}>Search </button>
                                </div>
                                <div className="col-xl-2 pull-left nopadding">
                                    <button className="btn btn-blue-btnnm btn-type pull-left mt-4 mb-1 " onClick={this.handleClear}>Clear</button>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-4 mt-3 normal-data">
                                    <div className="card-body posrelative">

                                        <div className="table-responsive dataTable unsubmitted posrel postion-right">
                                            <div className="export-div-xx right-0">
                                                <button className="export-class-x">Export</button>
                                            </div>
                                            <div className="col-md-2 searchoff nopadding">
                                                <span className="show-title">Show</span>
                                            </div>
                                            <div className="col-md-2 entriesoff nopadding">
                                                <span className="show-entries">entries</span>
                                            </div>
                                            <MaterialTable
                                                title=""
                                                tableRef={this.tableRef}
                                                localization={{ body: { emptyDataSourceMessage: <p>{this.state.emptyDataSourceMessage }</p> } }}
                                                onRowClick={(event, rowData) => {
                                                    localStorage.setItem('PrevPage', 'search');
                                                    this.props.history.push('/case-details?id=' + rowData.encId);
                                                    localStorage.setItem('searchSortColumn', this.state.sortColumn);
                                                    localStorage.setItem('searchSortOrder', this.state.sortOrder);
                                                }}
                                                className="dataTable"
                                                columns={columns}
                                                data={this.state.SearchList}
                                                class="mat-paginator-sticky"
                                                title=""

                                                options={{
                                                    exportButton: true,
                                                    isLoading: true,
                                                    paginationType: "stepped",
                                                    search: false,
                                                    exportAllData: true,
                                                    labelRowsPerPage: false,
                                                    exportCsv: async (data, columns) => {
                                                        var isExport = await this.exportGridValues();
                                                        if (this.state.hideExportButton) {
                                                            if (isExport) {
                                                                if (this.state.dataForExport.length > 0) {
                                                                    const columnTitles = data.filter(a => a.hidden != true).filter(a => a.hidden != true).map(columnDef => columnDef.title);
                                                                    const csvData = this.state.dataForExport.map(rowData =>
                                                                        data.filter(a => a.hidden != true).map(columnDef => rowData[columnDef.field]),
                                                                    );
                                                                    const builder = new CsvBuilder(`Orders.csv`)
                                                                        .setColumns(columnTitles)
                                                                        .addRows(csvData)
                                                                        .exportFile();
                                                                    return builder;
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            this.toasterredfun("You have no access to download. Contact Administrator.");
                                                        }
                                                    },
                                                    exportPdf: async (data, columns) => {
                                                        var isExport = await this.exportGridValues();
                                                        if (this.state.hideExportButton) {
                                                            if (isExport) {
                                                                if (this.state.dataForExport.length > 0) {
                                                                    const unit = "pt";
                                                                    const size = "A4"; // Use A1, A2, A3 or A4
                                                                    const orientation = "landscape"; // portrait or landscape
                                                                    const doc = new jsPDF(orientation, unit, size);
                                                                    doc.text("Completed Order List", 40, 35);
                                                                    const columnTitles = data.filter(a => a.hidden != true).map(columnDef => columnDef.title);
                                                                    const pdfData = this.state.dataForExport.map(rowData =>
                                                                        data.filter(a => a.hidden != true).map(columnDef => rowData[columnDef.field]),
                                                                    );
                                                                    if (companyType == 'LIFE') {
                                                                        doc.autoTable({
                                                                            styles: {
                                                                                cellPadding: 2,
                                                                                cellWidth: 'auto'
                                                                            },
                                                                            bodyStyles: {
                                                                                cellPadding: 2,
                                                                                margin: 40,
                                                                            },
                                                                            columnStyles: {
                                                                                0: { cellWidth: 65 },
                                                                                1: { cellWidth: 65 },
                                                                                2: { cellWidth: 65 },
                                                                                3: { cellWidth: 65 },
                                                                                4: { cellWidth: 65 },
                                                                                5: { cellWidth: 65 },
                                                                                6: { cellWidth: 65 },
                                                                                7: { cellWidth: 65 },
                                                                                8: { cellWidth: 65 },
                                                                                9: { cellWidth: 65 },
                                                                                10: { cellWidth: 65 },


                                                                                text: {
                                                                                    cellWidth: 'wrap'
                                                                                }
                                                                            },
                                                                            head: [columnTitles],
                                                                            body: pdfData
                                                                        });
                                                                    }
                                                                    else {
                                                                        doc.autoTable({
                                                                            styles: {
                                                                                cellPadding: 2,
                                                                                cellWidth: 'auto'
                                                                            },
                                                                            bodyStyles: {
                                                                                cellPadding: 2,
                                                                                margin: 40,
                                                                            },
                                                                            columnStyles: {
                                                                                0: { cellWidth: 50 },
                                                                                1: { cellWidth: 50 },
                                                                                2: { cellWidth: 50 },
                                                                                3: { cellWidth: 50 },
                                                                                4: { cellWidth: 50 },
                                                                                5: { cellWidth: 50 },
                                                                                6: { cellWidth: 50 },
                                                                                7: { cellWidth: 50 },
                                                                                8: { cellWidth: 50 },
                                                                                9: { cellWidth: 50 },
                                                                                10: { cellWidth: 50 },
                                                                                11: { cellWidth: 50 },
                                                                                12: { cellWidth: 50 },
                                                                                text: {
                                                                                    cellWidth: 'wrap'
                                                                                }
                                                                            },
                                                                            head: [columnTitles],
                                                                            body: pdfData
                                                                        });
                                                                    }

                                                                    doc.save(`Orders.pdf`);
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            this.toasterredfun("You have no access to download. Contact Administrator.");
                                                        }
                                                    }
                                                }}
                                                components={{
                                                    Header: (props) => (
                                                        <TableHead>
                                                            <TableRow>
                                                                {visibleColumns.map((column, index) => (
                                                                    <TableCell
                                                                        key={column.field}

                                                                    >
                                                                        <TableSortLabel

                                                                            active={this.state.sortColumn === column.field}
                                                                            direction={this.state.sortColumn === column.field ? this.state.sortOrder : 'asc'}
                                                                            onClick={() => this.handleColumnHeaderClick(column)}

                                                                        >
                                                                            {column.title}
                                                                        </TableSortLabel>
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                    ),
                                                    Pagination: props => (
                                                        <TablePagination
                                                            {...props}
                                                            rowsPerPageOptions={[5, 10, 20]}
                                                            rowsPerPage={this.state.PageLimit}
                                                            count={this.state.totalRecord}
                                                            page={this.state.currentPage}
                                                            onChangeRowsPerPage={event => {
                                                                props.onChangeRowsPerPage(event);
                                                                this.setState({ PageLimit: event.target.value, currentPage: 0});
                                                                localStorage.setItem('ClickedPageInSearch', 1);
                                                                this.getGridValues(this.state.UserId, 0, event.target.value, this.state.sortColumn, this.state.sortOrder)
                                                            }}
                                                            onChangePage={(event, page) => {
                                                                let ClickedButton = event.nativeEvent.target.innerText;
                                                                localStorage.setItem('ClickedPageInSearch', ClickedButton)
                                                                if (ClickedButton == "first_page") {
                                                                    this.setState({ currentPage: 0 })
                                                                    this.getGridValues(this.state.UserId, 0, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                                }
                                                                else if (ClickedButton == "chevron_left") {
                                                                    this.setState({ currentPage: this.state.currentPage - 1 })
                                                                    let chevroleftValue = this.state.currentPage - 1;
                                                                    localStorage.setItem('Chevron-leftClick', chevroleftValue);
                                                                    let offSet = (this.state.currentPage - 1) * this.state.PageLimit;
                                                                    this.getGridValues(this.state.UserId, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                                }
                                                                else if (ClickedButton == "chevron_right") {
                                                                    this.setState({ currentPage: this.state.currentPage + 1 })
                                                                    let chevronrightValue = this.state.currentPage + 1;
                                                                    localStorage.setItem('Chevron-rightClick', chevronrightValue);
                                                                    let offSet = (this.state.currentPage + 1) * this.state.PageLimit;
                                                                    this.getGridValues(this.state.UserId, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                                }
                                                                else if (ClickedButton == "last_page") {
                                                                    var lp = this.state.totalRecord / this.state.PageLimit;
                                                                    lp = Math.ceil(lp) - 1;
                                                                    let offSet;
                                                                    if (lp == 0) {
                                                                        this.setState({ currentPage: 1 })
                                                                        offSet = this.state.PageLimit
                                                                    }
                                                                    else {
                                                                        this.setState({ currentPage: lp })
                                                                        offSet = lp * this.state.PageLimit
                                                                    }
                                                                    this.getGridValues(this.state.UserId, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                                }
                                                                else {
                                                                    let offSet;
                                                                    if (ClickedButton > this.state.currentPage) {
                                                                        this.setState({ currentPage: this.state.currentPage + 1 })
                                                                        offSet = (this.state.currentPage + 1) * this.state.PageLimit;
                                                                    }
                                                                    else {
                                                                        this.setState({ currentPage: this.state.currentPage - 1 })
                                                                        offSet = (this.state.currentPage - 1) * this.state.PageLimit;
                                                                    }
                                                                    this.getGridValues(this.state.UserId, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                                }
                                                            }}
                                                        />
                                                    ),
                                                }}

                                                onOrderChange={(orderBy, orderDirection) => {
                                                }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
