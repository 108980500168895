import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import jwt_decode from 'jwt-decode';
import { browserName, browserVersion, deviceDetect } from "react-device-detect";
import { isNull, isNullOrUndefined } from 'util';
import Api from '../serviceCall';
import $ from 'jquery';
import { Link, NavLink, Redirect } from 'react-router-dom';


export class LayoutContext extends Component {
    static displayName = LayoutContext.name;
    constructor(props) {
        super(props);
        this.state = { UserId:"" }
        this.UserLogoutTime = this.UserLogoutTime.bind(this);
    }
    componentWillMount() {
        let currentComponent = this;
        
    }

    componentDidMount() {     
        let currentComponent = this;
        localStorage.removeItem('PressedKey');
        localStorage.removeItem('PressedKeyCode');
        localStorage.removeItem('IsShiftPressed');
        localStorage.removeItem('IsCtrlPressed');

        document.addEventListener('keydown', function (event) {            
            localStorage.setItem('PressedKey', event.key);
            localStorage.setItem('PressedKeyCode', event.keyCode);
            localStorage.setItem('IsCtrlPressed', event.ctrlKey);
            localStorage.setItem('IsShiftPressed', event.shiftKey);
        });
        window.addEventListener('beforeunload', function (e) {
            var IsSSOUser = localStorage.getItem("IsSSOUser");
            var SyncClicked = localStorage.getItem("SyncClicked");
            var IsTimeOut = localStorage.getItem("IsTimeOut");
            var IsTimeOutFromPlaceOrder = localStorage.getItem("IsTimeOutFromPlaceOrder");
            if (IsSSOUser == 'true' && SyncClicked == 'false' && IsTimeOut == 'false') {
                e.preventDefault();
                e.returnValue = '';
            }
            else if (SyncClicked == 'false' && IsTimeOut == 'false' && IsTimeOutFromPlaceOrder == 'false') {
                e.preventDefault();
                e.returnValue = '';
            }
        });
        window.addEventListener('unload', function (event) {            
            var IsSSOUser = localStorage.getItem("IsSSOUser");
            var SyncClicked = localStorage.getItem("SyncClicked");
            var IsTimeOut = localStorage.getItem("IsTimeOut");
            var IsTimeOutFromPlaceOrder = localStorage.getItem("IsTimeOutFromPlaceOrder");
            var Euid = localStorage.getItem("Euid");      
            var PressedKey = localStorage.getItem("PressedKey");
            var PressedKeyCode = localStorage.getItem("PressedKeyCode");
            var IsShiftPressed = localStorage.getItem("IsShiftPressed");
            var IsCtrlPressed = localStorage.getItem("IsCtrlPressed");
            if (IsSSOUser == 'true' && SyncClicked == 'false' && IsTimeOut == 'false') {
                if ((IsShiftPressed && IsCtrlPressed && (PressedKey == 'r' || PressedKey == 'R')) || PressedKey == 'F5' || (IsCtrlPressed && (PressedKey == 'r' || PressedKey == 'R'))) {
                    //Normal refresh will happen without Logging out.
                } else {
                    currentComponent.UserLogoutTime();
                }
            }
            
            else if (!isNullOrUndefined(Euid) && IsTimeOutFromPlaceOrder == 'false' && IsTimeOut == 'false' && SyncClicked == 'false') {
                if ((IsShiftPressed && IsCtrlPressed && (PressedKey == 'r' || PressedKey == 'R')) || PressedKey == 'F5' || (IsCtrlPressed && (PressedKey == 'r' || PressedKey == 'R'))) {
                    //Normal refresh will happen without Logging out.
                } else {
                    currentComponent.UserLogoutTime();
                }
            }
            setTimeout(() => {
                console.log('A Delay of 10 seconds');
            }, 10000);
        });       
    }
   
    async UserLogoutTime() {
        let currentComponet = this;
        var CompanyCode = localStorage.getItem("Companycode");        
        let euid = localStorage.getItem("Euid");
        const headers = {
            'Content-Type': 'application/json',           
            'Euid': euid,
            'CompanyCode': CompanyCode,
            'BrowserType': deviceDetect().browserName,
            'BrowserVersion': deviceDetect().browserFullVersion
        };
        const formData = new FormData();
        Object.keys(headers).forEach((key) => {
            formData.append(key, headers[key]);
        });
        const result = navigator.sendBeacon('services/api/UserLogOutEntry', formData);
        localStorage.clear();
        if (result) {

            console.log('Beacon sent successfully');
        } else {
            console.log('Beacon failed to send');
        }
        setTimeout(
            function () {
                console.log("-------");
            }, 9000);
    }
    render() {
        return (
            <Container fluid>
                {this.props.children}                
            </Container>
            );
    }
   
}
