import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { Layout } from './Layout';
/*import jwt_decode from 'jwt-decode';*/
import MaterialTable from "material-table";
import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import Select from "react-dropdown-select";
import { Link, NavLink } from 'react-router-dom';
import $ from 'jquery';
import Api from '../serviceCall';
import { TimeoutCounter } from './TimeoutCounter';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CsvBuilder } from 'filefy';
import { isNull, isNullOrUndefined } from 'util';
import { TablePagination, TableHead, TableRow, TableCell, TableSortLabel} from '@material-ui/core';
export class Unsubmitted extends Component {
    static displayName = Unsubmitted.name;
    constructor(props) {
        super(props);
        this.state = {
            Loading: true,
            unsubmittedOrderList: [],
            removeAlert: false, removehidden: '', removeDoc: '', toasterCla: false, toasterredCla: false, toaster: '',
            individualNameTableHeaderLabel: "",
            facilityNameTableHeaderLabel: "",
            providerNameTableHeaderLabel: "",
            fileNumberTableHeaderLabel: "",
            lblRequestType: "",
            lblFirstName: "",
            lblLastName: "",

            lblDoctorFirstName: "",
            lblFacilityName: "",
            lblPolicyNum: "",
            FirstNameHide: false,
            LastNameHide: false,
            DoctorLastNameHide: false,
            DoctorFirstNameHide: false,
            FacilityNameHide: false,
            PolicyNumHide: false,

            RequiredFieldsList: [],
            hideExportButton: false,
            userId: "",
            offSet: 0,
            PageLimit: 5,
            totalRecord: 0,
            currentPage: 0,
            isSearchClicked: false,
            SeachValue: "",
            sortColumn:"",
            clickCount: 0,
            sortOrder: "",
            DataForExport:[]

        };
        this.deleteUnSubmittedOrders = this.deleteUnSubmittedOrders.bind(this);
        this.handleremoveCancelbttn = this.handleremoveCancelbttn.bind(this);
        this.onhandeditevent = this.onhandeditevent.bind(this);
        this.GetRequiredFields = this.GetRequiredFields.bind(this);
        this.GetUnsubmittedOrders = this.GetUnsubmittedOrders.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.exportGridValues = this.exportGridValues.bind(this);
        this.Onclickexportpopup = this.Onclickexportpopup.bind(this);
        this.getGridValues = this.getGridValues.bind(this);
        this.handleUnsubmittedOrderSearch = this.handleUnsubmittedOrderSearch.bind(this);
        this.handleKeypress = this.handleKeypress.bind(this);
        this.testName = this.testName.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.exportUnsubmittedOrders = this.exportUnsubmittedOrders.bind(this);
        
    }
    
    async exportUnsubmittedOrders() {
        debugger;
        let currentComponent = this;
        currentComponent.setState({ Loading: true });
        await Api.Post('api/PlaceOrder/GetHoldOrder', {
            "DataOffSet": 0, "DataLimit": 0, "SearchOrder": "", "SortField": "", "SortOrder": ""
        }, currentComponent).then(function (result) {
            if (result.statusCode === 100 && result.result != null) {
                result.result.map(rowData => {
                    rowData.patientFirstName = Api.decryptData(rowData.patientFirstName);
                    rowData.patientLastName = Api.decryptData(rowData.patientLastName);
                    rowData.policyNumber = Api.decryptData(rowData.policyNumber);
                });
                currentComponent.setState({ DataForExport: result.result, loading: false, removeAlert: false, totalRecord: result.totalRowsCount });
            } else if (result == null || result.status === 429) {
                currentComponent.toasterredfun2("The number of consecutive mouse clicks exceeded. Please refresh the page or wait 7 seconds and try again.");
            } else {
                currentComponent.setState({ Loading: false, removeAlert: false, DataForExport: [], totalRecord: 0 });
            }
            currentComponent.setState({ Loading: false });
        }).catch(function (error) {
            currentComponent.setState({ Loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    handleUnsubmittedOrderSearch() {        
        let currentComponent = this;        
        currentComponent.setState({ offSet: 0, currentPage: 0, sortColumn: "", sortOrder: "" }, () => { currentComponent.GetUnsubmittedOrders() });
    }
    handleClearSearch() {
        let currentComponent = this;
        this.setState({ SeachValue: "", sortColumn: "", sortOrder: "", offSet: 0, currentPage: 0 }, () => { currentComponent.GetUnsubmittedOrders(); });
    }
    handleKeypress(event) {
        var key = event.which || event.key;
        if (key == 13) {
            this.handleUnsubmittedOrderSearch();
        }
    }
    testName(e) {
        if (/^[._a-zA-Z0-9&'-\s]+$/g.test(e)) {
            if (/\s{2,}/g.test(e)) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    }    
    handleSearchChange(e) {
        if (e.target.value.length != 0) {
            if (this.testName(e.target.value)) {
                this.setState({ SeachValue: e.target.value });
            }
        }
        else {
            this.setState({ SeachValue: e.target.value });
        }
    }

    getGridValues(offSet, dataLimit,sortcolumn,sortingorder) {
        let currentComponent = this;
        currentComponent.setState({ Loading: true, offSet: offSet, PageLimit: dataLimit });
        Api.Post('api/PlaceOrder/GetHoldOrder', {
            "DataOffSet": offSet, "DataLimit": dataLimit, "SearchOrder": Api.encryptData(currentComponent.state.SeachValue), "SortField": sortcolumn, "SortOrder": sortingorder
        }, currentComponent).then(function (result) {
            if (result.statusCode === 100 && result.result != null) {
                result.result.map(rowData => {
                    rowData.patientFirstName = Api.decryptData(rowData.patientFirstName);
                    rowData.patientLastName = Api.decryptData(rowData.patientLastName);
                    rowData.policyNumber = Api.decryptData(rowData.policyNumber);
                });
                currentComponent.setState({ unsubmittedOrderList: result.result, loading: false, removeAlert: false, totalRecord: result.totalRowsCount });
            } else if (result == null || result.status === 429) {
                currentComponent.toasterredfun2("The number of consecutive mouse clicks exceeded. Please refresh the page or wait 7 seconds and try again.");
            } else {
                currentComponent.setState({ Loading: false, removeAlert: false, unsubmittedOrderList: [], totalRecord:0});
            }
            currentComponent.setState({ Loading: false });
        }).catch(function (error) {
            currentComponent.setState({ Loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    loadUnsubmittedOrders() {
        //var token = localStorage.getItem("UserId");
        //var user = jwt_decode(token);
        //var userId = user.UserId;
        //let currentComponent = this;

        //Api.Post('api/PlaceOrder/GetUnSubmittedOrder/', { userId: userId }, currentComponent).then(function (result) {
        //    if (result.statusCode === 100) {
        //        currentComponent.setState({ unsubmittedOrderList: result.result, loading: false, removeAlert: false });
        //    } else {
        //        currentComponent.setState({ unsubmittedOrderList: result.result, loading: false, removeAlert: false });
        //        currentComponent.toasterredfun2(result.statusMessage);
        //    }

        //}).catch(function (error) {
        //    currentComponent.setState({ Loading: false });
        //    if (error == "TypeError: Failed to fetch") {
        //        currentComponent.toasterredfun2("Check your internet connection.");
        //    }
        //    else {
        //        currentComponent.toasterredfun2(error);
        //    }
        //});

        //fetch('api/PlaceOrder/GetUnSubmittedOrder/', {
        //    method: 'post',
        //    headers: {
        //        'Content-Type': 'application/json',
        //        "Authorization": "Bearer " + localStorage.getItem("UserId") },
        //    body: JSON.stringify({ userId: userId})
        //}).then(function (response) {
        //    if (response.status == 401) {
        //        currentComponent.props.history.push('/');
        //    }
        //    else { return response.json(); }
        //}).then(function (result) {            
        //    if (result.statusCode === 100) {
        //        currentComponent.setState({ unsubmittedOrderList: result.result, loading: false, removeAlert: false  });
        //    } else {
        //        currentComponent.setState({ unsubmittedOrderList: result.result, loading: false, removeAlert: false });
        //        currentComponent.toasterredfun2(result.statusMessage);
        //    }

        //}).catch(function (error) {
        //    currentComponent.setState({ Loading: false });
        //    if (error == "TypeError: Failed to fetch") {
        //        currentComponent.toasterredfun2("Check your internet connection.");
        //    }
        //    else {
        //        currentComponent.toasterredfun2(error);
        //    }
        //});
    }
    handleremoveCancelbttn() {
        this.setState({ removeAlert: false });
    }    
    componentWillMount() {       
        let currentComponent = this;
        
        var ctype = localStorage.getItem("PlaceOrder");
        var user = localStorage.getItem("UserType");
        if (!isNullOrUndefined(user) && (user.toLowerCase() == "agent" || user.toLowerCase() == "client home office")) {
            if (!isNullOrUndefined(ctype) && ctype == "false") {
                currentComponent.toasterredfun2("You have no access to view. Contact Administrator.");
                setTimeout(function () { currentComponent.props.history.push('/dashboard'); }, 3000);
            }
        }
        let companyType = localStorage.getItem("CompanyType");

        Api.Get('api/placeorder/GetIsOwnStatus', currentComponent).then(function (result) {
            localStorage.removeItem('ShowAdminMenu');
            localStorage.removeItem('UserType');
            localStorage.removeItem('PlaceOrder');
            localStorage.removeItem('ViewResults');
            localStorage.removeItem('ViewAuth');
            localStorage.removeItem('ExportSearchResults');
            localStorage.removeItem('PerformanceMetrics');
            localStorage.setItem('ShowAdminMenu', result.isAdmin);
            localStorage.setItem('UserType', result.userType);
            localStorage.setItem('PlaceOrder', result.placeOrder);
            localStorage.setItem('ViewResults', result.viewResults);
            localStorage.setItem('ViewAuth', result.viewAuth);
            localStorage.setItem('ExportSearchResults', result.exportSearchResults);
            localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
            var UserId = Api.decryptData(result.userId);            
            if (result.statusCode == '100') {           
                currentComponent.setState({ hideExportButton: result.exportSearchResults });
            }
            currentComponent.setState({ userId: UserId }, () => { currentComponent.GetUnsubmittedOrders(); currentComponent.GetRequiredFields(); });

        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun(error);
            }
        });
        //this.GetRequiredFields();
        //setTimeout(function () {
        //    this.GetUnsubmittedOrders();
        //}.bind(this), 7000);
    }

    GetUnsubmittedOrders() {        
        //var token = localStorage.getItem("UserId");
        //var user = jwt_decode(token);
        //var userId = user.UserId;
        let currentComponent = this;
        currentComponent.setState({ Loading: true });
        var userId = currentComponent.state.userId;        
        let companyType = localStorage.getItem("CompanyType");        
        Api.Post('api/PlaceOrder/GetHoldOrder', {
            "DataOffSet": currentComponent.state.offSet, "DataLimit": currentComponent.state.PageLimit, "SearchOrder": Api.encryptData(currentComponent.state.SeachValue), "SortField": currentComponent.state.sortColumn, "SortOrder": currentComponent.state.sortOrder
        }, currentComponent).then(function (result) {
            if (result.statusCode === 100 && result.result != null) {
                result.result.map(rowData => {
                    rowData.patientFirstName = Api.decryptData(rowData.patientFirstName);
                    rowData.patientLastName = Api.decryptData(rowData.patientLastName);
                    rowData.policyNumber = Api.decryptData(rowData.policyNumber);
                });
                currentComponent.setState({ unsubmittedOrderList: result.result, loading: false, removeAlert: false, totalRecord: result.totalRowsCount });
            }
            else if (result == null || result.status === 429) {
                currentComponent.toasterredfun2("The number of consecutive mouse clicks exceeded. Please refresh the page or wait 7 seconds and try again.");
            }else {
                currentComponent.setState({ loading: false, removeAlert: false, unsubmittedOrderList: [], totalRecord: 0});
            }
            currentComponent.setState({ Loading: false });
        }).catch(function (error) {            
            currentComponent.setState({ Loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    GetRequiredFields() {
        var CompanyId = 1;
        //var token = localStorage.getItem("UserId");
        //if (token == null || token.length == 0) {
        //    this.props.history.push('/');
        //}

        //var user = jwt_decode(token);
        //var userId = user.UserId;
        var userId = this.state.userId;
        if (userId.length == 0 || userId == null) {
            this.props.history.push('/');
        }

        let currentComponent = this;
        currentComponent.setState({ loading: true });
        Api.Post('api/placeorder/GetRequiredFields', { "CompanyId": CompanyId }, currentComponent).then(function (result) {
            currentComponent.setState({ RequiredFieldsList: result.requiredFields, loading: false });

            if (currentComponent.state.RequiredFieldsList.length > 0) {
                for (var i = 0; i < currentComponent.state.RequiredFieldsList.length; i++) {
                    currentComponent.setState({
                        [currentComponent.state.RequiredFieldsList[i].className + 'Hide']: currentComponent.state.RequiredFieldsList[i].isActive,
                        // ['isMandatory' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].isRequired,
                    });
                    if (currentComponent.state.RequiredFieldsList[i].customFieldName == "") {

                        //  fetch('labelConfigs/Unsubmitted_Labels.json').then(function (response) {
                        //    return response.json();
                        //}).then(function (response) {
                        //  currentComponent.setState({
                        //    individualNameTableHeaderLabel: response[companyType].Table_Header.Individual_Name,
                        //  facilityNameTableHeaderLabel: response[companyType].Table_Header.Facility_Name,
                        //providerNameTableHeaderLabel: response[companyType].Table_Header.Provider_Name,
                        //fileNumberTableHeaderLabel: response[companyType].Table_Header.File_Number
                        // });
                        //});


                        currentComponent.setState({
                            ['lbl' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].originalFieldName,
                        });
                    }
                    else {
                        currentComponent.setState({
                            ['lbl' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].customFieldName,
                        });
                    }
                }
            }

        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }
    toasterredfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }

    componentDidMount() {
        this.setState({ loading: false });
        //var token = localStorage.getItem("UserId");
        //if (token == null || token.length == 0) {
        //    this.props.history.push('/');

        //}
    }

    onEditClick(HoldOrderId) {
        localStorage.removeItem("HoldOrderId");
       /* window.location.href = "/place-order?id=" + HoldOrderId;*/
        this.props.history.push("/place-order?id=" + HoldOrderId);    
    }

    onhandeditevent(e) {

        this.setState({ removeAlert: true });
        this.setState({ removehidden: e });
    }

    toasterredfun(event) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: event });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
                this.props.history.goBack();
            }.bind(this), 5000);
    }

    deleteUnSubmittedOrders(orderId) {
        //var token = localStorage.getItem("UserId");
        //var user = jwt_decode(token);
        //var userId = user.UserId;
        var userId = this.state.userId;
        let currentComponent = this;
        currentComponent.setState({ Loading: true });

        Api.Post('api/PlaceOrder/DeleteHoldOrders/', { holdOrderId: orderId, DataOffSet: currentComponent.state.offSet, DataLimit: currentComponent.state.PageLimit, SearchOrder: Api.encryptData(currentComponent.state.SeachValue), SortField: currentComponent.state.sortColumn, SortOrder: currentComponent.state.sortOrder }, currentComponent).then(function (result) {
            if (result.statusCode === 100) {
                if (result.result) {
                    result.result.map(rowData => {
                        debugger
                        rowData.patientFirstName = Api.decryptData(rowData.patientFirstName);
                        rowData.patientLastName = Api.decryptData(rowData.patientLastName);
                        rowData.policyNumber = Api.decryptData(rowData.policyNumber);
                    });
                    currentComponent.setState({ unsubmittedOrderList: result.result, removeAlert: false });
                }
                else {
                    currentComponent.setState({ unsubmittedOrderList: [], removeAlert: false });
                }
            } else {
                currentComponent.setState({ removeAlert: false });
                currentComponent.toasterredfun2(result.statusMessage);
            }
            currentComponent.setState({ Loading: false });
        }).catch(function (error) {
            currentComponent.setState({ Loading: false });
            if (error == "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });

        //fetch('api/PlaceOrder/DeleteUnSubmittedOrders/', {
        //    method: 'post',
        //    headers: {
        //        'Content-Type': 'application/json',
        //        "Authorization": "Bearer " + localStorage.getItem("UserId") },
        //    body: JSON.stringify({ orderId:orderId,userId: userId })
        //}).then(function (response) {
        //    if (response.status == 401) {
        //        currentComponent.props.history.push('/');
        //    }
        //    else { return response.json(); }
        //}).then(function (result) {            
        //    if (result.statusCode === 100) {
        //        currentComponent.setState({ unsubmittedOrderList: result.result, loading: false, removeAlert: false});
        //    } else {
        //        currentComponent.setState({ unsubmittedOrderList: result.result, removeAlert: false });
        //        currentComponent.toasterfun2(result.statusMessage);
        //    }

        //}).catch(function (error) {
        //    currentComponent.setState({ loading: false });
        //    if (error == "TypeError: Failed to fetch") {
        //        currentComponent.toasterredfun2("Check your internet connection.");
        //    }
        //    else {
        //        currentComponent.toasterredfun2(error);
        //    }
        //});
        currentComponent.setState({ Loading: false });
    }
    Onclickexportpopup()
    {
        let currentComponent = this;
        currentComponent.setState({ exportAlert: false, Loading: false });

    }

    async exportGridValues() {
        //var user = jwt_decode(localStorage.getItem("UserId"));
        //var userId = user.UserId;
        var userId = this.state.userId;
        let currentComponent = this;
        let responce = false;           
        currentComponent.setState({ Loading: true });
        await Api.Get('api/placeorder/AllowExportValues', currentComponent).then(function (result) {
                if (result.statusCode == "100") {
                    if (!isNullOrUndefined(result)) {
                        if (result.allowExport) {
                         
                            currentComponent.setState({ Loading: false });
                            currentComponent.setState({ hideExportButton: result.allowExport });
                            localStorage.removeItem('ExportSearchResults');
                            localStorage.setItem('ExportSearchResults', result.allowExport);
                        }
                        else {
                            currentComponent.setState({ Loading: false });
                            localStorage.removeItem('ExportSearchResults');
                            localStorage.setItem('ExportSearchResults', result.allowExport);
                            if (currentComponent.state.hideExportButton) {
                                currentComponent.setState({ exportAlert: true });
                            }
                            responce = false;
                            return responce;

                        }

                    }
                    else {
                        currentComponent.setState({ Loading: false });
                    }
                    responce = true;
                } else {
                    currentComponent.setState({  Loading: false });
                    currentComponent.toasterredfun(result.statusMessage);
                    responce = false;
                }
            });
       
        return responce;
    }    
    handleColumnHeaderClick = (column) => {        
        if (this.state.unsubmittedOrderList.length != 0) {
            let offSet = (this.state.currentPage) * this.state.PageLimit;            
            if (this.state.sortColumn === column.field) {
                const newSortOrder = this.state.sortOrder === 'asc' ? 'desc' : 'asc';
                this.setState({
                    sortOrder: newSortOrder,
                    sortColumn: column.field,
                });
                this.getGridValues(offSet, this.state.PageLimit, column.field, newSortOrder);
            } else {
                this.setState({
                    sortColumn: column.field,
                    sortOrder: 'asc',
                });
                this.getGridValues(offSet, this.state.PageLimit, column.field, "asc");
            }            
        }
    }
    render() {
        var landing = 'img/ems-logo.gif';
        var companyType = localStorage.getItem("CompanyType");
        let columns =
            [
                { title: 'Order Date', field: 'orderDate', type: 'date' },
                { title: 'Patient ' + this.state.lblFirstName, field: 'patientFirstName', hidden: this.state.FirstNameHide === false },
                { title: 'Patient ' + this.state.lblLastName, field: 'patientLastName', hidden: this.state.LastNameHide === false },
                { title: this.state.lblDoctorFirstName, field: 'doctorName', hidden: this.state.DoctorFirstNameHide === false },
                { title: this.state.lblDoctorLastName, field: 'doctorLastName', hidden: this.state.DoctorLastNameHide === false },
                { title: this.state.lblFacilityName, field: 'facilityName', hidden: this.state.FacilityNameHide === false },
                { title: this.state.lblPolicyNum, field: 'policyNumber', hidden: this.state.PolicyNumHide === false },
                { title: 'Request Type', field: 'requestType', hidden: companyType === "LIFE" },
                { title: 'Certified', field: 'certified', hidden: companyType === "LIFE" },
                { title: 'Order Type', field: 'orderType', hidden: companyType === "LIFE" },
                { title: 'Requestor Name ', field: 'requesterName' },
                { title: 'Status ', field: 'status' },
                {
                    title: 'Action ', field: 'action', disableClick: true, disableSorting: true,
                    /*render: rowData => <span style={{ "width": "150px", "display": "block" }}><NavLink tag={Link} activeClassName="main-nav-active" className="main-nav" to={"/place-order?id=" + rowData.mapId + "_" + rowData.isIdFrom}><span className="edit-btnm editActionE"><img src="img/edit-icon.png" /> Edit</span></NavLink>*/
                    render: rowData => <span style={{ "width": "150px", "display": "block" }}><span className="edit-btnm editActionE" onClick={() => this.onEditClick(rowData.mapId + "_" + rowData.isIdFrom)}><img src="img/edit-icon.png" /> Edit</span>
                        <span className="edit-btnm" onClick={() => this.onhandeditevent(rowData.mapId + "_" + rowData.isIdFrom)}><img src="img/delete.png" /> Delete</span> </span>
                }

            ]
        let visibleColumns = columns.filter((column) => !column.hidden);
        return (
            <Layout>
                <TimeoutCounter />
                <span className={"loading " + (this.state.Loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} /> </span>
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i>
                    {this.state.toaster} </div>
                <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                <div className={"removeAlert-divbrowser " + (this.state.exportAlert ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x text-center">
                        <img src="img/error.png" alt="Error" />
                        <p className="my-3"> Your permissions has been modified/revoked to perform this action. Please contact your administrator.</p>
                        <div className="text-center-div m-0">
                            <div className="bttn popupButton m-0" onClick={this.Onclickexportpopup}>
                                Ok
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"removeAlert-div " + (this.state.removeAlert ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x"> <i className="fa fa-bell" aria-hidden="true"></i> Are you sure you wish to delete this Order?
                        <div className="text-center-div">
                            <div className="bttn popupButton" onClick={(e) => { this.deleteUnSubmittedOrders(this.state.removehidden) }}>
                                Yes
                            <input type="hidden" value={this.state.removehidden} readOnly="readonly" />

                            </div>
                            <div className="bttn popupButton" onClick={(e) => { this.handleremoveCancelbttn() }}>
                                No
                        </div>
                        </div>
                    </div>
                </div>
                <div className='hgtDiv bg-white mt-2 p-3'>
                    <div className="container-fluid nopadding">
                        <h1 className="mt-1 mb-3 withset">Unsubmitted Orders</h1>
                        <div className=" mb-4 normal-data ">
                            <div className="card-body posrelative">

                                <div className="table-responsive dataTable unsubmitted posrel">
                                    <div className="export-div-xx">
                                        <button className="export-class-x">Export</button>
                                    </div>
                                    <div className="col-md-2 searchoff nopadding">
                                        <span className="show-title">Show</span>
                                    </div>
                                    <div className="col-md-2 entriesoff nopadding">
                                        <span className="show-entries">entries</span>
                                    </div>
                                    <div className="searchoff-x">
                                        <span className="show-title-x">Search:</span>
                                    </div>
                                    <div className="searchinput-x">
                                        <input className="form-control" id="SearchOrders" type="text" onKeyPress={this.handleKeypress} autoComplete="off" value={this.state.SeachValue} onChange={this.handleSearchChange} maxLength={50} placeholder="Search" />
                                       
                                    </div>
                                    <div className="searchicon-x">
                                        <span className="search-icon" onClick={this.handleUnsubmittedOrderSearch}>
                                            <i className="fa fa-search"></i> 
                                        </span>
                                    </div>
                                   
                                    <div className="clearsearch-x">
                                    <span className="clear-icon" onClick={this.handleClearSearch}>
                                        <i className="fa fa-times"></i> 
                                        </span>
                                    </div>
                                    <MaterialTable
                                        title=""
                                        className="dataTable"
                                        columns={columns}
                                        data={this.state.unsubmittedOrderList}
                                        class="mat-paginator-sticky"
                                        title=""                                        
                                        options={{
                                            search: false,                                            
                                            filtering: true,
                                            exportButton: true,
                                            exportButton: true,
                                            isLoading: true,
                                            paginationType: "stepped",
                                            exportAllData: true,
                                            exportCsv: async (data, columns) => {
                                                var isExport = await this.exportGridValues();
                                                if (this.state.hideExportButton) {
                                                    var exportGridValues = await this.exportUnsubmittedOrders();                                                    
                                                    if (isExport)
                                                    {
                                                        if (this.state.DataForExport.length > 0) {                                                           
                                                            const columnTitles = data.filter(a => a.hidden != true).filter(a => a.hidden != true).map(columnDef => columnDef.title);
                                                            const csvData = this.state.DataForExport.map(rowData =>
                                                                data.filter(a => a.hidden != true).map(columnDef => rowData[columnDef.field]),
                                                            );
                                                            const builder = new CsvBuilder(`Unsubmitted Orders.csv`)
                                                                .setColumns(columnTitles)
                                                                .addRows(csvData)
                                                                .exportFile();
                                                            return builder;
                                                        }
                                                    }
                                                }
                                                else {
                                                    this.toasterredfun2("You have no access to download. Contact Administrator.");
                                                }
                                            },
                                            exportPdf: async (data, columns) => {
                                                if (this.state.hideExportButton) {
                                                    var exportGridValues = await this.exportUnsubmittedOrders();                                                    
                                                    if (this.state.DataForExport.length > 0) {
                                                        const unit = "pt";
                                                        const size = "A4"; // Use A1, A2, A3 or A4
                                                        const orientation = "landscape"; // portrait or landscape
                                                        const doc = new jsPDF(orientation, unit, size);
                                                        doc.text("Unsubmitted Orders", 40, 35);
                                                        const columnTitles = data.filter(a => a.hidden != true).map(columnDef => columnDef.title);
                                                        const pdfData = this.state.DataForExport.map(rowData =>
                                                            data.filter(a => a.hidden != true).map(columnDef => rowData[columnDef.field]),
                                                        );

                                                        doc.autoTable({
                                                            styles: {
                                                                cellPadding: 2,
                                                                cellWidth: 'auto'
                                                            },
                                                            bodyStyles: {
                                                                cellPadding: 2,
                                                                margin: 40,
                                                            },
                                                            head: [columnTitles],
                                                            body: pdfData
                                                        });

                                                        doc.save(`Unsubmitted Orders.pdf`);
                                                    }
                                                }
                                                else {
                                                    this.toasterredfun2("You have no access to download. Contact Administrator.");
                                                }
                                            }
                                        }}
                                        components={{
                                            Header: (props) => (
                                                <TableHead>
                                                    <TableRow>
                                                        {visibleColumns.map((column, index) => (
                                                            <TableCell
                                                                key={column.field}
                                                            >
                                                                {column.disableSorting ? (<span>{column.title}</span>) : (
                                                                    <TableSortLabel
                                                                        active={this.state.sortColumn === column.field}
                                                                        direction={this.state.sortColumn === column.field ? this.state.sortOrder : 'asc'}
                                                                        onClick={() => this.handleColumnHeaderClick(column)}
                                                                    >
                                                                        {column.title}
                                                                    </TableSortLabel>)}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                            ),
                                            Pagination: props => (
                                                <TablePagination
                                                    {...props}
                                                    rowsPerPageOptions={[5, 10, 20]}
                                                    rowsPerPage={this.state.PageLimit}
                                                    count={this.state.totalRecord}
                                                    page={this.state.currentPage}
                                                    onChangeRowsPerPage={event => {
                                                        props.onChangeRowsPerPage(event);
                                                        this.setState({ PageLimit: event.target.value, currentPage: 0 });
                                                        this.getGridValues(0, event.target.value, this.state.sortColumn,this.state.sortOrder)
                                                    }}
                                                    onChangePage={event => {                                                        
                                                        let ClickedButton = event.nativeEvent.target.innerText;
                                                        if (ClickedButton == "first_page") {
                                                            this.setState({ currentPage: 0 })
                                                            this.getGridValues(0, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                        }
                                                        else if (ClickedButton == "chevron_left") {
                                                            this.setState({ currentPage: this.state.currentPage - 1 })
                                                            let offSet = (this.state.currentPage - 1) * this.state.PageLimit;
                                                            this.getGridValues(offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                        }
                                                        else if (ClickedButton == "chevron_right") {
                                                            this.setState({ currentPage: this.state.currentPage + 1 })
                                                            let offSet = (this.state.currentPage + 1) * this.state.PageLimit;
                                                            this.getGridValues(offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                        }
                                                        else if (ClickedButton == "last_page") {
                                                            var lp = this.state.totalRecord / this.state.PageLimit;
                                                            lp = Math.ceil(lp) - 1;
                                                            let offSet;
                                                            if (lp == 0) {
                                                                this.setState({ currentPage: 1 })
                                                                offSet = this.state.PageLimit
                                                            }
                                                            else {
                                                                this.setState({ currentPage: lp })
                                                                offSet = lp * this.state.PageLimit
                                                            }
                                                            this.getGridValues(offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                        }
                                                        else {
                                                            let offSet;
                                                            if (ClickedButton > this.state.currentPage) {
                                                                this.setState({ currentPage: this.state.currentPage + 1 })
                                                                offSet = (this.state.currentPage + 1) * this.state.PageLimit;
                                                            }
                                                            else {
                                                                this.setState({ currentPage: this.state.currentPage - 1 })
                                                                offSet = (this.state.currentPage - 1) * this.state.PageLimit;
                                                            }
                                                            this.getGridValues(offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                        }
                                                    }}
                                                    onOrderChange={(orderBy, orderDirection) => {
                                                    }
                                                    }                                                    
                                                />
                                                ),
                                        }}
                                    />                                    
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </Layout>
        );
    }
}
