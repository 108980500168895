import React, { Component } from 'react';
import $ from 'jquery';
import { isNull, isNullOrUndefined } from 'util';
import { browserName, browserVersion, deviceDetect } from "react-device-detect";
import Api from '../serviceCall';
import Cookies from 'js-cookie';
export class ValidateSSOUser extends Component {
    static displayName = ValidateSSOUser.name;
    constructor(props) {
        super(props);
        this.state = {
            Loading: true,
            Success: false,
            Error: false,
            Message: "",
            toasterCla: false,
            toasterredCla: false,
            toaster: "",
            UserId: "",
            CompanyCode: "",
            SSOUser: false
        }
    }
    componentWillMount() {
        //debugger;
        localStorage.clear();
        let currentComponent = this;
        var encryptedRequest = window.location.search.slice(1);
        var urlParams = Api.splitURLParams(encryptedRequest);
        if (Object.keys(urlParams).length < 2) {
            currentComponent.setState({ Message: "Something went wrong!", Error: true, Success: false, Loading: false });
        }
        else {
            let userId = urlParams['UID'];
            let companyCode = urlParams['CC'];
            if (companyCode.toLowerCase() == "primerica") {
                let companyType = urlParams["CT"];
                let orderid = urlParams['OID'];
                let uid = urlParams['Euid'];
                let antiToken = Cookies.get('XSRF-TOKEN');
                fetch('api/login/GetSSODetails', {
                    method: 'post',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Companycode': companyCode,
                        'X-XSRF-Token': antiToken
                    },
                    body: JSON.stringify({                        
                        "Token": Api.encryptData(uid)
                    })
                }).then(function (response) {
                    return response.json();
                }).then(function (result) {
                     if (result.statusMessage == "Success") {
                        localStorage.setItem('Companycode', companyCode);
                        localStorage.setItem('SyncClicked', 'false');
                        localStorage.setItem('IsTimeOut', 'false');
                        localStorage.setItem('CompanyType', 'LIFE');
                        localStorage.setItem('UserType', 'Agent');
                        localStorage.setItem('IsSSOUser', 'true');
                        localStorage.setItem('PrevPage', 'validatessouser');
                        localStorage.setItem('Euid', result.userId);
                        localStorage.setItem('Agentid', Api.encryptData(urlParams['UID']));

                        setTimeout(
                            function () {
                                var caseDetailsEncryptedParams = Api.encryptData('id=' + orderid + '&uid=' + uid);
                                currentComponent.props.history.push('/case-detailsSSO?' + caseDetailsEncryptedParams);
                            }.bind(this), 2000);
                    }
                    else {
                        currentComponent.setState({ Message: "SSO Verification Failure", Error: true, Success: false, Loading: false });
                    }
                }).catch(function (error) {
                    currentComponent.setState({ Message: "SSO Verification Failure", Error: true, Success: false, Loading: false});
                });
            }
            else if (!isNullOrUndefined(userId) && !isNullOrUndefined(companyCode)) {
                this.setState({ UserId: userId, CompanyCode: companyCode, SSOUser: true });

                fetch('api/login/CheckSSOUserlogin', {
                    method: 'post',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        "Companycode": companyCode
                    },
                    body: JSON.stringify({
                        "UserName": userId,
                        "CompanyCode": companyCode
                    })
                }).then(function (response) {
                    if (!response.ok) {
                        return response;
                    }
                    return response.json();
                }).then(function (result) {
                    //debugger;
                    currentComponent.setState({ Loading: false });
                    if (result) {
                        if (result.status === 401) {
                            currentComponent.toasterredfun2("Company code is invalid.");
                        } else {
                            if (result.statusMessage != "Success") {
                                currentComponent.setState({ Message: result.statusMessage, Error: true, Success: false });
                            }
                            else {
                                currentComponent.setState({ UserType: result.userType, PlaceOrder: result.placeOrder, ViewStatus: result.viewStatus, Success: true, Error: false });
                                Api.SetLocalStorage(result, true, companyCode);
                                setTimeout(
                                    function () {
                                        currentComponent.props.history.push('/dashboard');
                                    }.bind(this), 2000);
                            }
                        }
                    } else {
                        currentComponent.setState({ Message: "Something Went Wrong" });
                    }
                }).catch(function (error) {
                    currentComponent.setState({ Loading: false, sererror: true });
                    if (error == "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun2("Check your internet connection.");
                    }
                    else {
                        currentComponent.toasterredfun2(error);
                    }
                });
            } else {
                currentComponent.setState({ Message: "SSO Verification Failure", Error: true, Success: false, Loading: false });
            }
        }
    }
    toasterfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this), 5000);
    }
    toasterredfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }
    render() {
        var landing = 'img/ems-logo.gif';
        return (
            <div>
                <span className={"loading " + (this.state.Loading ? "show" : "hide")} ><span style={{ background: "none" }} className="loading-div-d"><h3 style={{ top: "40%", position: "fixed", left: 0, right: 0, margin: "0 auto", display: "block", textAlign: "center" }}>SSO Verification Inprogress...Please wait..</h3></span> <img style={{ width: "55px", position: "fixed", zIndex: "99999", left: 0, right: 0, margin: "0 auto", top: "30%" }} src={process.env.PUBLIC_URL + landing} /></span>
                <span className={"loading " + (this.state.Success ? "show" : "hide")} ><span style={{ background: "none" }} className="loading-div-d"><h3 style={{ top: "40%", position: "fixed", left: 0, right: 0, margin: "0 auto", display: "block", textAlign: "center" }}>SSO Verification Completed...You will be re-directed to our application automatically..</h3></span><i style={{ width: "55px", position: "fixed", zIndex: "99999", left: 0, right: 0, margin: "0 auto", top: "30%", fontSize: "370%", color: "green" }} className="fa fa-check-circle" aria-hidden="true"></i></span>
                <span className={"loading " + (this.state.Error ? "show" : "hide")} ><span style={{ background: "none" }} className="loading-div-d"><h3 style={{ top: "40%", position: "fixed", left: 0, right: 0, margin: "0 auto", display: "block", textAlign: "center" }}>{this.state.Message}</h3></span><i style={{ width: "55px", position: "fixed", zIndex: "99999", left: 0, right: 0, margin: "0 auto", top: "30%", fontSize: "370%", color: "red" }} className="fa fa-exclamation-circle" aria-hidden="true"></i></span>
                <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i>{this.state.toaster} </div>
                <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster} </div>

                <div className="con-div-xm">
                    <div style={{float: "text", height: "100%", position: "absolute", right: "36%"}}>
                        <div style={{ width: "400px", padding: "5% 0 0", margin: "auto", textAlign: "center"}}>
                            <div className="logo">
                                <img src="img/eSubmission-logo.png" />
                                <h2>Online Portal </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}