import React, { Component } from "react";
import ReactDOM from "react-dom";
import { NavMenu } from "./NavMenu";
import { Layout } from "./Layout";
import jwt_decode from "jwt-decode";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import Select from "react-dropdown-select";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import $ from "jquery";
import TextField from "@material-ui/core/TextField";
import Autocomp from "@material-ui/lab/Autocomplete";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import Api from "../serviceCall";
import Switch from "react-bootstrap-switch";
import axios from "axios";
import { isDate } from "@amcharts/amcharts4/.internal/core/utils/Type";
import { isNull, isNullOrUndefined } from 'util';
import { TimeoutCounter } from './TimeoutCounter';
import { browserName, browserVersion, deviceDetect } from "react-device-detect";
import { NavLink } from "react-router-dom";
import { v4 as uuid } from 'uuid';
export class PlaceOrder extends Component {
    static displayName = PlaceOrder.name;
    constructor(props) {
        super(props);        
        this.state = {
            CompanyType: "",
            lblOtherField1: "",
            lblOtherField2: "",
            lblOtherField3: "",
            lblOtherField4: "",
            lblPolicyAmount: "",
            lblPolicyNum: "",
            lblTitle: "",
            lblDOB: "",
            lblDateOfIncident: "",
            lblOtherNames: "",
            lblSSN: "",
            lblGender: "",
            lblFirstName: "",
            lblLastName: "",
            lblEmail: "",
            lblPCAddress: "",
            lblAgentFN: "First Name",
            lblAgentLN: "Last Name",
            lblAgentId: "Agent ID",
            lblAttorneyId: "",
            lblAttFirstName: "",
            lblAttLastName: "",
            lblAttEmailAddress: "",
            lblAttWorkPhone: "",
            lblAttCellPhone: "",
            lblAttExt: "",
            lblAttFax: "",
            lblAgentPhone: "Work Phone",
            lblAgentExt: "EXT",
            lblAgentMobile: "Cell Phone",
            lblAgentFax: "Fax",
            lblAgentEmail: "",
            lblPCCity: "",
            lblPCZipCode: "",
            lblStateId: "",
            lblFax: "",
            lblPhone1: "",
            lblPhone1Type: "",
            lblExt1: "",
            lblPhone2: "",
            lblExt2: "",
            lblPhone2Type: "",
            lblPreferredContact: "",
            lblExt3: "",
            lblPhone3: "",
            lblPhone3Type: "",
            lblSpecialInstructionType: "",
            lblStartDate: "",
            lblEndDate: "",
            lblSpecialInstruction: "",
            lblWorkPhone: "",
            lblExt: "",
            lblCellPhone: "",
            lblFax: "",
            lblDoctorFirstName: "",
            lblDoctorLastName: "",
            lblDrEmailAddress: "",
            lblDrAddress: "",
            lblDoctorCity: "",
            lblDrZipCode: "",
            lblDrState: "",
            lblPhone: "",
            lblDrFax: "",
            lblFacilityName: "",
            lblCMFN: "",
            lblCMLN: "",
            lblCMEmailAddress: "",
            lblCMWorkPhone: "",
            lblCMCellPhone: "",
            lblCMExt: "",
            lblCMFax: "",
            lblLifeRushRequest: "",
            lblLifeSpecialInstruction: "",
            lblUploadAuthorization: "",
            lblCarrierManager: "",
            lblAdjusterFN: "",
            lblAdjusterLN: "",
            lblAdjEmailAddress: "",
            lblAdjusterPhone: "",
            lblAdjusterMobile: "",
            lblAdjusterExt: "",
            lblAdjusterFax: "",
            lblAdjusterId: "",

            value: "",
            isHold: false,
            isReopen: false,
            Loading: true,
            Title: "",
            FirstName: "",
            LastName: "",
            SSN: "",
            DOB: "",
            Gender: "Select",
            OtherNames: "",
            PolicyNum: "",
            addvalid: true,
            PolicyAmount: "",
            Email: "",
            PCAddress: "",
            PCCity: "",
            StateId: "Select",
            State: "",
            PCZipCode: "",
            Phone1: "",
            Phone2: "",
            Phone3: "",
            Phone1Type: "Select",
            Phone2Type: "Select",
            Phone3Type: "Select",
            Ext1: "",
            Ext2: "",
            Ext3: "",
            Fax: "",
            PreferredContact: "",
            AgentFN: "",
            AgentLN: "",
            AgentEmail: "",
            AgentId: "",
            AgentPhone: "",
            AgentExt: "",
            AgentMobile: "",
            AgentFax: "",
            AdjusterFN: "",
            AdjusterLN: "",
            AdjusterEmail: "",
            AdjusterId: "",
            AdjusterPhone: "",
            AdjusterExt: "",
            AdjusterMobile: "",
            AdjusterFax: "",
            FacilityName: "",
            DoctorFirstName: "",
            DoctorLastName: "",
            DrEmailAddress: "",
            DrAddress: "",
            DoctorCity: "",
            DrId: "",
            isNew: true,
            DrState: "",
            DrStateId: "Select",
            DrZipCode: "",
            Phone: "",
            DrFax: "",
            RushReq: false,
            SpecialType: "",
            SpecialInstruction: "",
            StartDate: "",
            EndDate: "",
            DatesOfService: "",
            CustomStartDate: "",
            CustomEndDate: "",
            csd: false,
            ced: false,
            MedicalRequestType: "",
            BillingRequestType: "",
            FilmsRequestType: "",
            OtherRequestType: "",
            MedicalRushRequest: false,
            MedicalCertifiedRequest: false,
            MedicalAffidavitRequest: false,
            BillingRushRequest: false,
            BillingCertifiedRequest: false,
            BillingAffidavitRequest: false,
            FilmsRushRequest: false,
            FilmsCertifiedRequest: false,
            FilmsAffidavitRequest: false,
            OtherRushRequest: false,
            OtherCertifiedRequest: false,
            OtherAffidavitRequest: false,
            MedicalSplInstruct: "",
            BillingSplInstruct: "",
            FilmsSplInstruct: "",
            OtherSplInstruct: "",
            MedicalCustodianInstruction: "",
            BillingCustodianInstruction: "",
            FilmsCustodianInstruction: "",
            OtherCustodianInstruction: "",
            RequestTypes: [],
            FileName: "",
            FileType: "",
            FileString: "",
            Files: [],
            ExistingFiles: [],
            //IsInvalidAuth: false,
            DoctorList: "",
            States: "",
            GenderList: "",
            PhoneTypes: "",
            ShowAddProvider: true,
            DocList: [],
            Base64: "",
            toasterCla: false,
            toasterredCla: false,
            toaster: "",
            toaster2: "",
            Facilities: [],
            CarrierManagers: [],
            pFirstName: false,
            pLastName: false,
            pDOB: false,
            pGender: false,
            pPolicyNum: false,
            pPolicyAmount: false,
            policyAmountRO: false,
            pEmail: false,
            pPCAddress: false,
            pPCCity: false,
            pPCZipCode: false,
            pPhone1: false,
            pPhone1Type: false,
            pPhone2Type: false,
            pPhone3Type: false,
            pAgentFN: false,
            pAgentLN: false,
            pAgentEmail: false,
            pCMEmailAddress: false,
            pAgentPhone: false,
            pAgentExt: false,
            pAgentMobile: false,
            pAgentFax: false,
            pAgentId: false,
            pAdjusterFN: false,
            pAdjusterLN: false,
            pAdjusterEmail: false,
            pAdjusterPhone: false,
            pAdjusterExt: false,
            pAdjusterMobile: false,
            pAdjusterFax: false,
            pAdjusterId: false,
            pFacilityName: false,
            pDoctorFirstName: false,
            pDoctorLastName: false,
            pDoctorCity: false,
            pDrZipCode: false,
            pSSN: false,
            pOtherNames: false,
            pPhone2: false,
            pPhone3: false,
            pFax: false,
            pPreferredContact: false,
            pTitle: false,
            pStateId: false,
            pExt1: false,
            pExt2: false,
            pExt3: false,
            pDrEmailAddress: false,
            pDrAddress: false,
            pDrStateId: false,
            pPhone: false,
            pDrFax: false,
            choseRadiox: false,
            choseRadioxtext: false,
            dosd: false,
            doed: false,
            dopopfacilityname: false,
            PopFacilityName: "",
            Options: [],
            PopFacilityId: "",
            dFacNameRO: false,
            dFNRO: false,
            dLNRO: false,
            dEmailRO: false,
            dAddrRO: false,
            dCityRO: false,
            dStateRO: false,
            dZipRO: false,
            dPhnRO: false,
            dFaxRO: false,
            FacilityId: "",
            PatientId: "",
            AgId: "",
            AgentUserId: "",
            AgentUserRole: "",
            isUnsubmitted: false,
            UserId: "",
            isModalopen: false,
            isEditModalopen: false,
            removeAlert: false,
            removeAlertx: false,
            deleteAlert: false,
            removehidden: "",
            typehidden: "",
            maxDate: new Date(),
            minDate: "",
            clearable: false,
            searchable: true,
            docIndex: "",
            DocFile: "",
            DocFileName: "",
            titleRO: false,
            firstNameRO: false,
            lastNameRO: false,
            genderRO: false,
            dobRO: false,
            policyRO: false,
            ssnRo: false,
            otherNamesRO: false,
            emailRO: false,
            addrRO: false,
            cityRO: false,
            phn1RO: false,
            phn2RO: false,
            phn3RO: false,
            zipRO: false,
            stateRO: false,
            faxRO: false,
            pcRO: false,
            ext1RO: false,
            ext2RO: false,
            phn1typeRO: false,
            phn2typeRO: false,
            afnRO: false,
            alnRO: false,
            aemailRO: false,
            aphnRO: false,
            aextRO: false,
            aIdRO: false,
            amobileRO: false,
            afaxRO: false,
            adfnRO: false,
            adlnRO: false,
            ademailRO: false,
            adphnRO: false,
            adextRO: false,
            adIdRO: false,
            admobileRO: false,
            adfaxRO: false,
            DocEdit: false,
            DocDelete: false,
            NewDocList: false,
            HideHoldButton: false,
            SubmitButtonChange: false,
            ssnChecked: false,
            isModalAgentopen: false,
            isModalAdjusteropen: false,
            Agents: [],
            CarrierManagers: [],
            Adjusters: [],
            AgentOptions: [],
            CarrierManagerOptions: [],
            AdjusterOptions: [],
            PopAgentId: "",
            PopCarrierManagerId: "",
            PopAgentName: "",
            PopCarrierManagerName: "",
            dopopAgentname: false,
            dopopCarrierManagername: false,
            PopAdjusterId: "",
            PopAdjusterName: "",
            dopopAdjustername: false,
            IsAgentId: "",
            CMId: "",
            IsAdjusterId: "",
            removeAgentAlert: false,
            removeAdjusterAlert: false,
            removeCMAlert: false,
            isOrderAdditional: false,
            RequiredFieldsList: [],
            InitialDocList: [],
            InitialAgentList: [],
            InitialCarrierManagerList: [],
            InitialCarrierManagerValues: [],
            InitialAdjusterList: [],
            InitialDocValues: [],
            SelectedDoc: "",
            InitialAgentValues: [],
            SelectedAgent: "",
            SelectedAdjuster: "",
            SelectedCarrierManager: "",

            DatesOfServiceHide: true,
            RequestTypeHide: true,

            LifeRushRequestHide: true,
            isMandatoryLifeRushRequest: false,

            CarrierManagerHide: true,
            isMandatoryCarrierManager: false,

            LifeSpecialInstructionHide: true,
            isMandatoryLifeSpecialInstructionHide: false,

            UploadAuthorizationHide: true,
            isMandatoryUploadAuthorization: false,

            TitleHide: false,
            isMandatoryTitle: false,

            FirstNameHide: false,
            isMandatoryFirstName: true,

            LastNameHide: false,
            isMandatoryLastName: true,

            DOBHide: false,
            isMandatoryDOB: true,

            SSNHide: false,
            isMandatorySSN: false,

            GenderHide: false,
            isMandatoryGender: true,
            OtherNameHide: false,
            isMandatoryOtherName: false,


            LegalPFNHide: false,
            isMandatoryLegalPFN: true,
            LegalPLNHide: false,
            isMandatoryLegalPLN: false,
            LegalPDOBHide: false,
            isMandatoryLegalPDOB: false,
            LegalPSSNHide: false,
            isMandatoryLegalPSSN: false,
            LegalPGenderHide: true,
            isMandatoryLegalPGender: false,
            LegalPOtherNameHide: true,
            isMandatoryLegalPOtherName: false,
            PolicyNumHide: false,
            isMandatoryPolicyNum: false,
            DateOfIncidentHide: false,
            isMandatoryDateOfIncident: false,

            LegalPOtherField1Hide: false,
            isMandatoryLegalPOtherField1: false,
            LegalPOtherField2Hide: false,
            isMandatoryLegalPOtherField2: false,
            LegalPOtherField3Hide: false,
            isMandatoryLegalPOtherField3: false,
            LegalPOtherField4Hide: false,
            isMandatoryLegalPOtherField4: false,


            LegalPDOSALLRECORDSHide: true,
            isMandatoryLegalPDOSALLRECORDS: false,
            LegalPDOSCustomHide: true,
            isMandatoryLegalPDOSCustom: false,


            LegalRTMedicalHide: true,
            isMandatoryLegalRTMedical: false,

            LegalRTBillingHide: true,
            isMandatoryLegalRTBilling: false,

            LegalRTFilmsHide: true,
            isMandatoryLegalRTFilims: false,

            LegalRTOtherHide: true,
            isMandatoryLegalRTOther: false,

            PolicyAmountHide: false,
            isMandatoryPolicyAmount: false,
            OtherField1Hide: false,
            isMandatoryOtherField1: false,
            OtherField2Hide: false,
            isMandatoryOtherField2: false,
            OtherField3Hide: false,
            isMandatoryOtherField3: false,
            OtherField4Hide: false,
            isMandatoryOtherField4: false,

            FacilityNameHide: false,
            isMandatoryFacilityName: true,

            DoctorFirstNameHide: false,
            isMandatoryDoctorFirstName: true,

            DoctorLastNameHide: false,
            isMandatoryDoctorLastName: true,

            DrEmailAddressHide: false,
            isMandatoryDrEmailAddress: true,

            DrAddressHide: false,
            isMandatoryDrAddress: true,

            DoctorCityHide: false,
            isMandatoryDoctorCity: true,

            DrStateIdHide: false,
            isMandatoryDrStateId: true,

            DrZipCodeHide: false,
            isMandatoryDrZipCode: true,

            PhoneHide: false,
            isMandatoryPhone: true,

            DrFaxHide: false,
            isMandatoryDrFax: false,

            EmailHide: false,
            isMandatoryEmail: false,
            PCAddressHide: false,
            isMandatoryPCAddress: true,
            PCCityHide: false,
            isMandatoryPCCity: true,
            StateIdHide: false,
            isMandatoryStateId: true,
            PCZipHide: false,
            isMandatoryPCZip: true,

            Phone1Hide: false,
            isMandatoryPhone1: false,

            Ext1Hide: false,
            isMandatoryExt1: false,

            Phone1TypeHide: false,
            isMandatoryPhone1Type: false,

            Phone2Hide: false,
            isMandatoryPhone2: false,

            Ext2Hide: false,
            isMandatoryExt2: false,

            Phone2TypeHide: false,
            isMandatoryPhone2Type: false,

            Phone3Hide: false,
            isMandatoryPhone3: false,

            Ext3Hide: false,
            isMandatoryExt3: false,

            Phone3TypeHide: false,
            isMandatoryPhone3Type: false,

            FaxHide: false,
            isMandatoryFax: false,

            PreferredContactHide: false,
            isMandatoryPreferredContact: false,

            AgentFNHide: false,
            isMandatoryAgentFN: false,

            AgentLNHide: false,
            isMandatoryAgentLN: false,

            AgentEmailHide: false,
            isMandatoryAgentEmail: false,

            AgentPhoneHide: false,
            isMandatoryAgentPhone: false,

            AgentExtHide: false,
            isMandatoryAgentExt: false,

            AgentMobileHide: false,
            isMandatoryAgentMobile: false,

            AgentFaxHide: false,
            isMandatoryAgentFax: false,

            AgentIdHide: false,
            isMandatoryAgentId: false,

            AdjusterFNHide: false,
            isMandatoryAdjusterFN: false,

            AdjusterLNHide: false,
            isMandatoryAdjusterLN: false,

            AdjusterEmailHide: false,
            isMandatoryAdjusterEmail: false,

            AdjusterPhoneHide: false,
            isMandatoryAdjusterPhone: false,

            AdjusterExtHide: false,
            isMandatoryAdjusterExt: false,

            AdjusterMobileHide: false,
            isMandatoryAdjusterMobile: false,

            AdjusterFaxHide: false,
            isMandatoryAdjusterFax: false,

            AdjusterIdHide: false,
            isMandatoryAdjusterId: false,

            //AdjEmailHide: true,
            //isMandatoryAdjEmail: true,
            //AdjPhoneHide: true,
            //isMandatoryAdjPhone: true,
            //AdjusterExtHide: true,
            //isMandatoryAdjusterExt: true,
            //AdjCellHide: true,
            //isMandatoryAdjCell: true,
            //AdjusterFaxHide: true,
            //isMandatoryAdjusterFax: true,
            //AdjusterIdHide: true,
            //isMandatoryAdjusterId: true,

            CMFNHide: false,
            isMandatoryCMFN: false,

            CMLNHide: false,
            isMandatoryCMLN: false,

            CMEmailAddressHide: false,
            isMandatoryCMEmailAddress: false,

            CMWorkPhoneHide: false,
            isMandatoryCMWorkPhone: false,

            CMExtHide: false,
            isMandatoryCMExt: false,

            CMCellPhoneHide: false,
            isMandatoryCMCellPhone: false,

            CMFaxHide: false,
            isMandatoryCMFax: false,

            SI5yearHide: true,
            isMandatorySI5year: false,
            SI3yearHide: true,
            isMandatorySI3year: false,
            SICustomDateRangeHide: true,
            isMandatorySICustomDateRange: false,
            SICustomRequirementsHide: true,
            isMandatorySICustomRequirements: false,
            HideLifeDiv: false,
            HideLegalDiv: false,
            DateOfIncident: "",
            OtherField1: "",
            OtherField2: "",
            OtherField3: "",
            OtherField4: "",
            pDateOfIncident: false,
            pOtherField1: false,
            pOtherField2: false,
            pOtherField3: false,
            pOtherField4: false,
            pDateOfIncidentRO: false,
            pOtherField1RO: false,
            pOtherField2RO: false,
            pOtherField3RO: false,
            pOtherField4RO: false,
            individualHeaderLabel: "",
            individualPolicyNoLabel: "",
            facilityHeaderLabel: "",
            facilityAlertLabel: "",
            facilityNameLabel: "",
            facilityFirstNameLabel: "",
            facilityLastNameLabel: "",
            facilityStateLabel: "",
            facilitySearchModalHeader: "",
            facilityClearButtonLabel: "Clear",
            facilitySearchButtonLabel: "Search",
            individualContactInfoHeaderLabel: "",
            agentInfoHeaderLabel: "",
            agentInfoAgentIdLabel: "",
            agentInfoClearButtonLabel: "Clear",
            agentInfoSearchButtonLabel: "Search",
            additionalHeaderLabel: "",
            additionalButtonLabal: "",
            ShowAttorneyInfo: true,
            ShowAdjusterInfo: false,
            isMedicalModalopen: false,
            isBillingModalopen: false,
            isFilmsModalopen: false,
            isOtherModalopen: false,
            AdId: "",
            url: "",
            pdftitle: "",
            OrderingOfficeList: [],
            IsDefaultOrderingOffice: false,
            isdefaultorderingofficeRO: false,
            orderingofficereq: false,
            orderingofficeRO: false,
            OrderingOfficeId: 0,
            OfficeAccountNumber: "",
            IsFromInboundOrder: false,
            //added code
            CMId: "",
            CMFN: "",
            CMLN: "",
            CMEmailAddress: "",
            CMWorkPhone: "",
            CMExt: "",
            CMCellPhone: "",
            CMFax: "",
            pCMFN: false,
            pCMLN: false,
            pCMEmailAddress: false,
            pCMWorkPhone: false,
            pCMExt: false,
            pCMCellPhone: false,
            pCMFax: false,
            CMFNRo: "",
            CMLNRo: "",
            CMEmailAddressRo: "",
            CMWorkPhoneRo: "",
            CMExtRo: "",
            CMCellPhoneRo: "",
            CMFaxRo: "",
            AgentList: [],
            IsMissingFacilityDetails: false,
            CustomerProvidedFacility: "",
            ExistingOrderingOfficeId: 0,
            AllowDefaultOfficeCA: true,
            ReOpenButtonHide: true,
            DisRushReq: false,
            DisY5SpecialType: false,
            DisY3SpecialType: false,
            DisCustomSpecialType: false,
            DisStartDate: false,
            DisEndDate: false,
            DisCustomRequest: false,
            DisSpecialInstruction: false,
            DisDOSAllRec: false,
            DisDOSCus: false,
            DisRqTypeMedical: false,
            DisRqTypeBilling: false,
            DisRqTypeFlims: false,
            DisRqTypeOther: false,
            DisAddAnotherFac: false,
            DisOrderingOffice: false,
            DisO_RushReq: false,
            DisO_CertifiedReq: false,
            DisO_AffidavitReq: false,
            DisO_Standard: false,
            DisO_Custom: false,
            DisO_Cancel: false,
            DisFacilitySearch: false,
            DisAgentSearch: false,
            DisCarrierSearch: false,
            HoldOrderId: 0,
            willUnmount: true,
            AllowDefaultOfficeCA: true,
            CompanyType: "",
            IsAdditionalOrder: false,
            IsAuthView: true,
            SupportedFileTypes: [],
            AllFileTypes: [],
            SupportedFileSize: "",
            visibleSSN: false,
            maskSSN: "",
            ShowMask: true,
            MaskDOB: true,
            isFromCaseDetails: false,
            MaskValue: "",
            ShowSSNValue: false,
            SSNEyeHide: false,
            DOBEyeHide: false,
            ShowSSNBFocus: false,
            ShowSSNValueFromCaseDetails:false,
            OrderGuid: "",
            IsOrderSent: true,
            ProductCode: "",
            APSReason:[]
        };        
        this.checkAndValidateOrderDetails = this.checkAndValidateOrderDetails.bind(this);
        this.handleFNChange = this.handleFNChange.bind(this);
        this.handleFNBlur = this.handleFNBlur.bind(this);
        this.handleLNChange = this.handleLNChange.bind(this);
        this.handleSSNChange = this.handleSSNChange.bind(this);
        this.handlepSSNChange = this.handlepSSNChange.bind(this);
        this.handleDOBChange = this.handleDOBChange.bind(this);
        this.handleDOBBlur = this.handleDOBBlur.bind(this);
        this.handleGenderChange = this.handleGenderChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleONChange = this.handleONChange.bind(this);
        this.handlePCChange = this.handlePCChange.bind(this);
        this.handlePNChange = this.handlePNChange.bind(this);
        this.handleStChange = this.handleStChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleAddrChange = this.handleAddrChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleZipChange = this.handleZipChange.bind(this);
        this.handlePhn1Change = this.handlePhn1Change.bind(this);
        this.handleExt1Change = this.handleExt1Change.bind(this);
        this.handlePhn1TypeChange = this.handlePhn1TypeChange.bind(this);
        this.handlePhn2Change = this.handlePhn2Change.bind(this);
        this.handlePhn3Change = this.handlePhn3Change.bind(this);
        this.handleExt2Change = this.handleExt2Change.bind(this);
        this.handleExt3Change = this.handleExt3Change.bind(this);
        this.handlePhn2TypeChange = this.handlePhn2TypeChange.bind(this);
        this.handlePhn3TypeChange = this.handlePhn3TypeChange.bind(this);
        this.handleFaxChange = this.handleFaxChange.bind(this);
        this.getOptionValue = this.getOptionValue.bind(this);
        this.getOptionLabel = this.getOptionLabel.bind(this);
        this.handleAFNChange = this.handleAFNChange.bind(this);
        this.handleALNChange = this.handleALNChange.bind(this);
        this.handleAEmailChange = this.handleAEmailChange.bind(this);
        this.handleAIdChange = this.handleAIdChange.bind(this);
        this.handleAPhnChange = this.handleAPhnChange.bind(this);
        this.handleAExtChange = this.handleAExtChange.bind(this);
        this.handleAMobileChange = this.handleAMobileChange.bind(this);
        this.handleAFaxChange = this.handleAFaxChange.bind(this);
        this.handleAdFNChange = this.handleAdFNChange.bind(this);
        this.handleAdLNChange = this.handleAdLNChange.bind(this);
        this.handleAdEmailChange = this.handleAdEmailChange.bind(this);
        this.handleAdIdChange = this.handleAdIdChange.bind(this);
        this.handleAdPhnChange = this.handleAdPhnChange.bind(this);
        this.handleAdExtChange = this.handleAdExtChange.bind(this);
        this.handleAdMobileChange = this.handleAdMobileChange.bind(this);
        this.handleAdFaxChange = this.handleAdFaxChange.bind(this);
        this.handleDFNChange = this.handleDFNChange.bind(this);
        this.handleDLNChange = this.handleDLNChange.bind(this);
        this.handleDFacilityChange = this.handleDFacilityChange.bind(this);
        this.handleDPhnChange = this.handleDPhnChange.bind(this);
        this.handleDCityChange = this.handleDCityChange.bind(this);
        this.handleDStateChange = this.handleDStateChange.bind(this);
        this.handleDZipChange = this.handleDZipChange.bind(this);
        this.handleDAddressChange = this.handleDAddressChange.bind(this);
        this.handleDEmailAddressChange = this.handleDEmailAddressChange.bind(this);
        this.handleDFaxChange = this.handleDFaxChange.bind(this);
        this.ClearProvider = this.ClearProvider.bind(this);
        this.ClearAgentProvider = this.ClearAgentProvider.bind(this);
        this.ClearAdjusterProvider = this.ClearAdjusterProvider.bind(this);
        this.ClearCarrierManager = this.ClearCarrierManager.bind(this);
        this.handleRushChange = this.handleRushChange.bind(this);
        this.handleSpChange = this.handleSpChange.bind(this);
        this.handleSPTypeChange = this.handleSPTypeChange.bind(this);
        this.handleEDChange = this.handleEDChange.bind(this);
        this.handleSDChange = this.handleSDChange.bind(this);
        this.handleSDBlur = this.handleSDBlur.bind(this);
        this.handleDatesOfServiceChange = this.handleDatesOfServiceChange.bind(this);
        this.handleCSDChange = this.handleCSDChange.bind(this);
        this.handleCEDChange = this.handleCEDChange.bind(this);
        this.handleCSDBlur = this.handleCSDBlur.bind(this);
        this.handleCEDBlur = this.handleCEDBlur.bind(this);
        this.testDate = this.testDate.bind(this);
        this.testPhone = this.testPhone.bind(this);
        this.DocChangeHandler = this.DocChangeHandler.bind(this);
        this.deleteDoc = this.deleteDoc.bind(this);
        this.deleteDoctor = this.deleteDoctor.bind(this);
        this.addDoctor = this.addDoctor.bind(this);
        this.checkSSN = this.checkSSN.bind(this);
        this.getBase64 = this.getBase64.bind(this);
        this.testAlphaNumericwithSpace = this.testAlphaNumericwithSpace.bind(this);
        this.testAlphawithSpace = this.testAlphawithSpace.bind(this);
        this.testAlphawithSpaceforCity = this.testAlphawithSpaceforCity.bind(this);
        this.testAlphawithSpaceForName = this.testAlphawithSpaceForName.bind(this);
        this.testAlphawithSpaceForNameAgent = this.testAlphawithSpaceForNameAgent.bind(this);
        this.testAlphaForTitle = this.testAlphaForTitle.bind(this);
        this.testNumericForDate = this.testNumericForDate.bind(this);
        this.testAlphaNumericwithSpaceForEmail = this.testAlphaNumericwithSpaceForEmail.bind(
            this
        );
        this.testAlphaNumericwithSpaceForFacility = this.testAlphaNumericwithSpaceForFacility.bind(
            this
        );
        this.testAlphaNumeric = this.testAlphaNumeric.bind(this);
        this.testAlphaNumericWithDash = this.testAlphaNumericWithDash.bind(this);
        this.testNumericForPolicyAmount = this.testNumericForPolicyAmount.bind(
            this
        );
        this.testZip = this.testZip.bind(this);
        this.testZip5 = this.testZip5.bind(this);
        this.renderDocTable = this.renderDocTable.bind(this);
        //this.renderFailedDocTable = this.renderFailedDocTable.bind(this);
        this.renderDoctorTable = this.renderDoctorTable.bind(this);
        this.handleSubmitOrder = this.handleSubmitOrder.bind(this);
        this.editDoctor = this.editDoctor.bind(this);
        this.handleFaNameChange = this.handleFaNameChange.bind(this);
        this.handlePopFaNameChange = this.handlePopFaNameChange.bind(this);
        this.handlePopAgentNameChange = this.handlePopAgentNameChange.bind(this);
        this.handlePopCarrierManagerNameChange = this.handlePopCarrierManagerNameChange.bind(this);
        this.handlePopAdjusterNameChange = this.handlePopAdjusterNameChange.bind(this);
        this.handleFaclitityAdd = this.handleFaclitityAdd.bind(this);
        this.handleAgentAdd = this.handleAgentAdd.bind(this);
        this.handleCarrierManagerAdd = this.handleCarrierManagerAdd.bind(this);
        this.handleAdjusterAdd = this.handleAdjusterAdd.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.clearFacility = this.clearFacility.bind(this);
        this.handleHoldOrder = this.handleHoldOrder.bind(this);
        this.onClickclosePopup = this.onClickclosePopup.bind(this);
        this.onClickopenPopup = this.onClickopenPopup.bind(this);
        this.onClickopenAgentPopup = this.onClickopenAgentPopup.bind(this);
        this.onClickopenCMPopup = this.onClickopenCMPopup.bind(this);
        this.onClickopenAdjusterPopup = this.onClickopenAdjusterPopup.bind(this);
        this.onClickopenEditPopup = this.onClickopenEditPopup.bind(this);
        this.handleremoveCancelbttn = this.handleremoveCancelbttn.bind(this);
        this.handleremoveCarrierManagerCancelbttn = this.handleremoveCarrierManagerCancelbttn.bind(this);
        this.onOpenclearpopup = this.onOpenclearpopup.bind(this);
        this.onOpenclearAgentpopup = this.onOpenclearAgentpopup.bind(this);
        this.onOpenclearCMpopup = this.onOpenclearCMpopup.bind(this);
        this.onOpenclearAdjusterpopup = this.onOpenclearAdjusterpopup.bind(this);
        this.handleremoveCancelbttnx = this.handleremoveCancelbttnx.bind(this);
        this.onClickpopupclorder = this.onClickpopupclorder.bind(this);
        this.handledeletebttn = this.handledeletebttn.bind(this);
        this.onhandeditevent = this.onhandeditevent.bind(this);
        this.Onclicktabledelpopup = this.Onclicktabledelpopup.bind(this);
        this.testEXT = this.testEXT.bind(this);
        this.renderSearch = this.renderSearch.bind(this);
        this.onTagsChange = this.onTagsChange.bind(this);
        this.toasterfun2 = this.toasterfun2.bind(this);
        this.toasterredfun2 = this.toasterredfun2.bind(this);
        this.toasterredfun = this.toasterredfun.bind(this);
        this.toasterredfunback = this.toasterredfunback.bind(this);
        this.handleSubmitOrderWithOrderId = this.handleSubmitOrderWithOrderId.bind(this);
        this.handleDOBclear = this.handleDOBclear.bind(this);
        this.getOptions = _.debounce(this.getOptions.bind(this), 500);
        this.getAgentOptions = _.debounce(this.getAgentOptions.bind(this), 500);
        this.handlePAChange = this.handlePAChange.bind(this);
        this.handleOtherField1 = this.handleOtherField1.bind(this);
        this.handleOtherField2 = this.handleOtherField2.bind(this);
        this.handleOtherField3 = this.handleOtherField3.bind(this);
        this.handleOtherField4 = this.handleOtherField4.bind(this);
        this.handleDOIclear = this.handleDOIclear.bind(this);
        this.handleDOIChange = this.handleDOIChange.bind(this);
        this.checkdates = this.checkdates.bind(this);
        this.handleDefaultOrderingOffice = this.handleDefaultOrderingOffice.bind(this);
        this.handleOrderingOfficeChange = this.handleOrderingOfficeChange.bind(this);
        this.testAlphaNumericwithSpaceForEmailNew = this.testAlphaNumericwithSpaceForEmailNew.bind(this);
        this.testEmailNew = this.testEmailNew.bind(this);
        this.handleCMFNChange = this.handleCMFNChange.bind(this);
        this.handleCMLNChange = this.handleCMLNChange.bind(this);
        this.handleCMEmailAddressChange = this.handleCMEmailAddressChange.bind(this);
        this.handleCMWorkPhoneChange = this.handleCMWorkPhoneChange.bind(this);
        this.handleCMExtChange = this.handleCMExtChange.bind(this);
        this.handleCMCellPhoneChange = this.handleCMCellPhoneChange.bind(this);
        this.handleCMFaxChange = this.handleCMFaxChange.bind(this);
        this.getCarrierManagerOptions = _.debounce(this.getCarrierManagerOptions.bind(this), 500);
        this.handleblurEmailChange = this.handleblurEmailChange.bind(this);
        this.handleblurDEmailAddressChange = this.handleblurDEmailAddressChange.bind(this);
        this.handleblurAEmailChange = this.handleblurAEmailChange.bind(this);
        this.handleblurCMEmailAddressChange = this.handleblurCMEmailAddressChange.bind(this);
        this.handleblurAdEmailChange = this.handleblurAdEmailChange.bind(this);
        this.handleSubmitOrderReopen = this.handleSubmitOrderReopen.bind(this);
        //this.checkEMail = this.checkEMail.bind(this);

        // this.GetRenderedList = this.GetRenderedList.bind(this);
        // this.getlblName = this.getlblName.bind(this);
        this.HoldOrder = this.HoldOrder.bind(this);
        this.HoldOrderWRD = this.HoldOrderWRD.bind(this);
        this.GetInitial = this.GetInitial.bind(this);
        this.GetOrderEditDetails = this.GetOrderEditDetails.bind(this);
        this.GetFileTypes = this.GetFileTypes.bind(this);
        this.Onclickisadminpopup = this.Onclickisadminpopup.bind(this);
        this.handleSSNfocusOut = this.handleSSNfocusOut.bind(this);
        this.handleSSNfocusIn = this.handleSSNfocusIn.bind(this);
        this.handleDOBfocusOut = this.handleDOBfocusOut.bind(this);
        this.handleDOBfocusIn = this.handleDOBfocusIn.bind(this);
        this.setMaskDOB = this.setMaskDOB.bind(this);


    }

    async GetFileTypes() {
        let currentComponent = this;
        await Api.Get('api/placeorder/GetFileTypes', currentComponent).then(function (result) {
            if (result.statusMessage == "Success") {
                currentComponent.setState({ SupportedFileTypes: result.supportedFileFormats, AllFileTypes: result.allFileFormats, SupportedFileSize: result.fileSize });
            }
        });
    }

    handleEvent = (event) => {
        if (event.button == 0) {
            if (event.type === "mousedown") {
                this.setState({ ShowSSNValue: true, ShowSSNBFocus:false });
                $('#eye-ssn').toggleClass('fa fa-eye');
            } else {
                this.setState({ ShowSSNValue: false });
                $('#eye-ssn').toggleClass('fa fa-eye-slash');
            }
        }

    }
    handleEventFromCaseDetails = (event) => {
        if (event.button == 0) {
            if (event.type === "mousedown") {
                this.setState({ ShowSSNValueFromCaseDetails: true});
                $('#eye-ssn').toggleClass('fa fa-eye');
            } else {
                this.setState({ ShowSSNValueFromCaseDetails: false });
                $('#eye-ssn').toggleClass('fa fa-eye-slash');
            }
        }

    }
    handleEventLegalFromCaseDetails = (event) => {
        if (event.button == 0) {
            if (event.type === "mousedown") {
                this.setState({ ShowSSNValueFromCaseDetails: true });
                $('#eye-ssn').toggleClass('fa fa-eye');
            } else {
                this.setState({ ShowSSNValueFromCaseDetails: false });
                $('#eye-ssn').toggleClass('fa fa-eye-slash');
            }
        }

    }
    handleEventLegal = (event) => {
        if (event.button == 0) {
            if (event.type === "mousedown") {
                this.setState({ ShowSSNValue: true, ShowSSNBFocus: false });
                $('#eye-ssn-legal').toggleClass('fa fa-eye');
            } else {
                this.setState({ ShowSSNValue: false });
                $('#eye-ssn-legal').toggleClass('fa fa-eye-slash');
            }
        }

    }    
    handleDOBEvent = (event) => {
        if (event.button == 0) {
            if (event.type === "mousedown") {
                this.setState({ MaskDOB: false });
                $('#eye-dob').toggleClass('fa fa-eye');
            } else {
                this.setState({ MaskDOB: true });
                $('#eye-dob').toggleClass('fa fa-eye-slash');
            }
        }
    }
    handleDOBEventLegal = (event) => {
        if (event.button == 0) {
            if (event.type === "mousedown") {
                this.setState({ MaskDOB: false });
                $('#eye-dob-legal').toggleClass('fa fa-eye');
            } else {
                this.setState({ MaskDOB: true });
                $('#eye-dob-legal').toggleClass('fa fa-eye-slash');
            }
        }
    }
    ShowAttorneyInfo = () => {
        this.setState({
            AgentList: [],
            ShowAttorneyInfo: true,
            ShowAdjusterInfo: false,
        });
    };
    ShowAdjusterInfo = () => {
        this.setState({
            AgentList: [],
            ShowAttorneyInfo: false,
            ShowAdjusterInfo: true,
        });
    };
    handleDOBclear() {
        this.setState({ DOB: null });
    }
    handleDOIclear() {
        this.setState({ DateOfIncident: null });
    }
    onhandeditevent(e, type) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ deleteAlert: true });

        this.setState({ removehidden: e });
        this.setState({ typehidden: type });
    }
    handledeletebttn() {
        this.setState({ deleteAlert: false });
    }
    onClickpopupclorder() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ removeAlertx: true });
    }
    handleremoveCancelbttnx() {
        this.setState({ removeAlertx: false });
    }
    handleremoveCarrierManagerCancelbttn() {
        this.setState({ removeCMAlert: false });
    }
    onOpenclearpopup() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ removeAlert: true });
    }
    onOpenclearAgentpopup() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ removeAgentAlert: true });
    }
    onOpenclearCMpopup() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ removeCMAlert: true });
    }
    onOpenclearAdjusterpopup() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ removeAdjusterAlert: true });
    }
    onTagsChange = (event, values) => {
        this.setState(
            {
                tags: values,
            },
            () => {
                // This will output an array of objects
                // given by Autocompelte options property.
            }
        );
    };
    getOptionValue = (option) => {
        return option.value || option.id;
    };

    getOptionLabel = (option) => {
        return option.label;
    };
    renderSearch() {
        return (
            <AsyncSelect
                cacheOptions
                //className="form-control"
                value={this.state.SelectedDoc}
                //selected={this.state.PopFacilityId}
                defaultOptions={this.state.Options}
                loadOptions={this.getOptions}
                placeholder="Select Facility (Type min 3 chars)"
                onChange={this.handlePopFaNameChange}
                noOptionsMessage={() => "No Facilities found"}
            />
        );
    }
    getOptions = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        if (inputValue.length > 2) {
            let currentComponent = this;

            Api.Post(
                "api/login/SearchDoc",
                { apstool: inputValue },
                currentComponent
            ).then(function (result) {
                if (result.statusCode == 403) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('PlaceOrder', result.allowPlaceOrder);
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }
                else
                {
                    currentComponent.setState({
                        Facilities: result.doctorList,
                        Options: [],
                    });
                    currentComponent.setState({ Options: result.docList });
                    callback(result.docList);
                }
            });

            //fetch("api/login/SearchDoc", {
            //    method: 'post',
            //    headers: {
            //        Accept: 'application/json',
            //        'Content-Type': 'application/json',
            //    },
            //    body: JSON.stringify({ "apstool": inputValue })
            //}).then(response => {
            //    response.json().then(data => {
            //        currentComponent.setState({ Facilities: data.doctorList, Options: [] });
            //        currentComponent.setState({ Options: data.docList });
            //        callback(data.docList);
            //    });
            //});
        }
    };
    getAgentOptions = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        if (inputValue.length > 2) {
            let currentComponent = this;
            let companyType = localStorage.getItem("CompanyType");

            Api.Post(
                "api/login/SearchAgent",
                { apstool: inputValue, agentType: (companyType == "LIFE" ? "Agent" : "Attorney") },
                currentComponent
            ).then(function (result) {
                if (result.statusCode == 403) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('PlaceOrder', result.allowPlaceOrder);
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }
                else
                {
                    currentComponent.setState({
                        Agents: result.agentList,
                        AgentOptions: [],
                    });
                    currentComponent.setState({ AgentOptions: result.docList });
                    callback(result.docList);
                }
            });

            //fetch("api/login/SearchAgent", {
            //    method: 'post',
            //    headers: {
            //        Accept: 'application/json',
            //        'Content-Type': 'application/json',
            //    },
            //    body: JSON.stringify({ "apstool": inputValue })
            //}).then(response => {
            //    response.json().then(data => {
            //        currentComponent.setState({ Agents: data.agentList, AgentOptions: [] });
            //        currentComponent.setState({ AgentOptions: data.docList });
            //        callback(data.docList);
            //    });
            //});
        }
    };

    getCarrierManagerOptions = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        if (inputValue.length > 2) {
            let currentComponent = this;

            Api.Post(
                "api/login/SearchCarrierManager",
                { apstool: inputValue },
                currentComponent
            ).then(function (result) {
                if (result.statusCode == 403) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('PlaceOrder', result.allowPlaceOrder);
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });
                }
                else
                {
                    currentComponent.setState({
                        CarrierManagers: result.carrierManagerList,
                        CarrierManagerOptions: [],
                    });
                    currentComponent.setState({ CarrierManagerOptions: result.docList });
                    callback(result.docList);

                }

            });
        }
    };

    getAdjusterOptions = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        if (inputValue.length > 2) {
            let currentComponent = this;

            Api.Post(
                "api/login/SearchAgent",
                { apstool: inputValue, agentType: "Adjuster" },
                currentComponent
            ).then(function (result) {

                if (result.statusCode == 403) {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('PlaceOrder', result.allowPlaceOrder);
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    currentComponent.setState({ adminAlert: true });

                }
                else
                {
                    currentComponent.setState({
                        Adjusters: result.adjusterList,
                        AdjusterOptions: [],
                    });
                    currentComponent.setState({ AdjusterOptions: result.docList });
                    callback(result.docList);

                }

            });
        }
    };
    onSelectAgent = (agent) => {
        this.setState({ IsAgentId: agent.id });
        this.setState({
            AgentEmail: agent.agentEmail,
            AgentFN: agent.agentFN,
            AgentLN: agent.agentLN,
            AgentMobile: agent.agentCell,
            AgentPhone: agent.agentPhone,
            AgentExt: agent.agentExt,
            AgentFax: agent.agentFax ? agent.agentFax : "",
            AgentId: agent.agentId,
            AgId: agent.id,
        });
    }
    renderAgentList() {
        if (this.state.AgentList && this.state.AgentList.length > 0) {
            return (
                <div className="dropdown-menu">
                    {this.state.AgentList.map(listitem => (
                        <a className="dropdown-item" onClick={() => this.onSelectAgent(listitem)} key={listitem.id}>
                            {listitem.agentFN} {listitem.agentLN}
                        </a>
                    ))}
                </div>
            );
        } else {
            return (
                <div className="dropdown-menu">
                    <a className="dropdown-item">No Results</a>
                </div>
            );
        }
    }
    onSelectAdjuster = (adjuster) => {
        this.setState({ IsAdjusterId: adjuster.id });
        this.setState({
            AdjusterEmail: adjuster.agentEmail,
            AdjusterFN: adjuster.agentFN,
            AdjusterLN: adjuster.agentLN,
            AdjusterMobile: adjuster.agentCell,
            AdjusterPhone: adjuster.agentPhone,
            AdjusterExt: adjuster.agentExt,
            AdjusterFax: adjuster.agentFax ? adjuster.agentFax : "",
            AdjusterId: adjuster.agentId,
            AdId: adjuster.id,
        });
    }
    renderAdjusterList() {
        if (this.state.AgentList && this.state.AgentList.length > 0) {
            return (
                <div className="dropdown-menu">
                    {this.state.AgentList.map(listitem => (
                        <a className="dropdown-item" onClick={() => this.onSelectAdjuster(listitem)} key={listitem.id}>
                            {listitem.agentFN} {listitem.agentLN}
                        </a>
                    ))}
                </div>
            );
        } else {
            return (
                <div className="dropdown-menu">
                    <a className="dropdown-item">No Results</a>
                </div>
            );
        }
    }
    renderAgentSearch() {
        return (
            <AsyncSelect
                cacheOptions
                //className="form-control"
                value={this.state.SelectedAgent}
                //selected={this.state.PopFacilityId}
                defaultOptions={this.state.AgentOptions}
                loadOptions={this.getAgentOptions}
                placeholder="Select Agent (Type min 3 chars)"
                onChange={this.handlePopAgentNameChange}
                noOptionsMessage={() => "No Agents found"}
            />
            //<Autocomp
            //    id="combo-box-demo"
            //    options={this.state.AgentOptions}
            //    getOptionLabel={(option) => option.label}
            //    defaultValue={this.state.PopAgentName}
            //    onChange={this.handlePopAgentNameChange}
            //    renderInput={(params) => <TextField {...params} label="Search Agent" margin="normal" variant="outlined" />}
            ///>
        );
    }

    renderCarrierManagerSearch() {
        return (
            <AsyncSelect
                cacheOptions
                value={this.state.SelectedCarrierManager}
                defaultOptions={this.state.CarrierManagerOptions}
                loadOptions={this.getCarrierManagerOptions}
                placeholder="Select Client Manager(Type min 3 chars)"
                onChange={this.handlePopCarrierManagerNameChange}
                noOptionsMessage={() => "No Client Manager found"}
            />
        );
    }
    renderAdjusterSearch() {
        return (
            <AsyncSelect
                cacheOptions
                //className="form-control"
                value={this.state.SelectedAdjuster}
                //selected={this.state.PopFacilityId}
                defaultOptions={this.state.AdjusterOptions}
                loadOptions={this.getAdjusterOptions}
                placeholder="Select Adjuster (Type min 3 chars)"
                onChange={this.handlePopAdjusterNameChange}
                noOptionsMessage={() => "No Adjusters found"}
            />
        );
    }
    handleremoveCancelbttn() {
        this.setState({ removeAlert: false, removeAgentAlert: false, removeAdjusterAlert: false, removeCMAlert: false, });
    }
    onClickclosePopup() {
        this.setState({
            isModalAgentopen: false,
            isModalCMtopen: false,
            isModalAdjusteropen: false,
            Facilities: this.state.InitialDocList,
            SelectedDoc: "",
            SelectedAgent: "",
            SelectedAdjuster: "",
            SelectedCarrierManager: "",
            Agents: this.state.InitialAgentList,
            Adjusters: this.state.InitialAdjusterList,
            AgentOptions: this.state.InitialAgentValues,
            CarrierManagers: this.state.InitialCarrierManagerList,
            CarrierManagerOptions: this.state.InitialCarrierManagerValues,
            AdjusterOptions: this.state.InitialAdjusterValues,
        });
        this.setState({ isModalopen: false });
        this.setState({ isEditModalopen: false });
    }
    onClickopenPopup() {
        this.setState({ isModalopen: true });
        $("#combo-box-demo").val("");

        setTimeout(
            function () {
                $("#combo-box-demo").val("");
                $(".MuiAutocomplete-clearIndicator").trigger("click");
            }.bind(this),
            200
        );
    }
    onClickopenAgentPopup() {
        this.setState({ isModalAgentopen: true });
        $("#combo-box-demo").val("");

        setTimeout(
            function () {
                $("#combo-box-demo").val("");
                $(".MuiAutocomplete-clearIndicator").trigger("click");
            }.bind(this),
            200
        );
    }
    onClickopenCMPopup() {
        this.setState({ isModalCMtopen: true });

        $("#combo-box-demo").val("");

        setTimeout(
            function () {
                $("#combo-box-demo").val("");
                $(".MuiAutocomplete-clearIndicator").trigger("click");
            }.bind(this),
            200
        );
    }
    onClickopenAdjusterPopup() {
        this.setState({ isModalAdjusteropen: true });
        $("#combo-box-demo").val("");

        setTimeout(
            function () {
                $("#combo-box-demo").val("");
                $(".MuiAutocomplete-clearIndicator").trigger("click");
            }.bind(this),
            200
        );
    }
    checkdates() {
        if (this.state.DOB instanceof Date) {
            if (new Date(this.state.DateOfIncident) instanceof Date) {
                if (this.state.DOB > new Date(this.state.DateOfIncident)) {
                    this.toasterredfun2("hi");
                }
            }
        }
    }
    onClickopenEditPopup(index) {
        let currentComponent = this;
        if (currentComponent.state.IsAuthView) {
            currentComponent.setState({ Loading: true });
            Api.Post("api/placeorder/GetDocURL", { "CompanyCode": currentComponent.state.Files[index].DownloadString,"isPlaceOrderPage":true }, currentComponent).then(function (result) {
                if (result.includes("access-denied"))
                {
                    currentComponent.setState({ loading: false });
                    localStorage.removeItem('PlaceOrder');
                    localStorage.setItem('PlaceOrder', 'false');
                    currentComponent.setState({ adminAlert: true });
                }
                else
                {
                    currentComponent.setState({ isEditModalopen: true, docIndex: index });
                    currentComponent.setState({ Loading: false, url: result, pdftitle: currentComponent.state.Files[index].FileName });
                }
            }).catch(function (error) {
                currentComponent.setState({ Loading: false });
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
            });
        }
        else {
            currentComponent.toasterredfun2("You have no access to view. Contact Administrator.");
        }
    }
    toasterfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterCla: false });
            }.bind(this),
            5000
        );
    }
    toasterredfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this),
            5000
        );
    }
    componentWillUnmount() {
        if (this.state.willUnmount == true && this.state.HideHoldButton == false) {
            this.HoldOrderWRD();
        }
    }
    componentWillMount() {        
        let currentComponent = this;
        var unique_id = uuid();        
        currentComponent.setState({ OrderGuid: unique_id });
        var ctype = localStorage.getItem("PlaceOrder");
        var user = localStorage.getItem("UserType");
        if (!isNullOrUndefined(user) && (user.toLowerCase() == "agent" || user.toLowerCase() == "client home office")) {
            if (!isNullOrUndefined(ctype)&&ctype == "false") {
                currentComponent.toasterredfun2("You have no access to view. Contact Administrator.");
                setTimeout(function () { currentComponent.props.history.push('/dashboard'); }, 3000);
            }
        }

        this.GetFileTypes();
        let companyType = localStorage.getItem("CompanyType");
        let holdOrderId = localStorage.getItem("HoldOrderId");

        if (companyType == "LEGAL") {
            this.setState({ HideLegalDiv: true, HideLifeDiv: false, CompanyType: companyType });
        } else {
            this.setState({ HideLifeDiv: true, HideLegalDiv: false, CompanyType: companyType });
        }

        var windowlocation = window.location.href;
        if (windowlocation.includes("place-order?id=")) {
            var ordersplit = windowlocation.split("id=");
            var getorderid = ordersplit[1];
            if (!isNullOrUndefined(holdOrderId)) {
                if (holdOrderId != getorderid) {
                    window.location.href = "/place-order?id=" + holdOrderId;
                }
            }
        }

        currentComponent.state.CompanyType = companyType;
        fetch("labelConfigs/PlaceOrder_Labels.json")
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                currentComponent.setState({
                    individualHeaderLabel: response[companyType].Individual_Info.Header,

                    //  individualPolicyNoLabel: response[companyType].Individual_Info.Policy_No,
                    facilityHeaderLabel: response[companyType].Facility_Info.Header,
                    facilityAlertLabel: response[companyType].Facility_Info.Alert,
                    facilityNameLabel: response[companyType].Facility_Info.Facility_Name,
                    facilityFirstNameLabel:
                        response[companyType].Facility_Info.First_Name,
                    facilityLastNameLabel: response[companyType].Facility_Info.Last_Name,
                    facilityStateLabel: response[companyType].Facility_Info.State,
                    facilitySearchModalHeader:
                        response[companyType].Facility_Info.Facility_Search_Modal_Header,
                    // facilityClearButtonLabel: response[companyType].Facility_Info.Clear,
                    // facilitySearchButtonLabel: response[companyType].Facility_Info.Search,
                    individualContactInfoHeaderLabel:
                        response[companyType].Individual_Contact_Info.Header,
                    agentInfoHeaderLabel: response[companyType].Agent_Info.Header,
                    agentInfoAgentIdLabel: response[companyType].Agent_Info.Agent_Id,
                    // agentInfoClearButtonLabel: response[companyType].Agent_Info.Clear,
                    // agentInfoSearchButtonLabel: response[companyType].Agent_Info.Search,
                    additionalHeaderLabel: response[companyType].Additional.Header,
                    additionalButtonLabal: response[companyType].Additional.Add,
                });
            });

        var MinDate = new Date(new Date().getFullYear() - 110, 1, 1);
        var MaxDate = new Date();
        this.setState({ minDate: MinDate, maxDate: MaxDate });

        /*var token = localStorage.getItem("UserId");
        if (token == null || token.length == 0) {
            currentComponent.setState({ willUnmount: false, });
            currentComponent.props.history.push("/");
        } else {
            var user = jwt_decode(token);
            var userId = user.UserId;
            if (userId.length == 0 || userId == null) {
                currentComponent.setState({ willUnmount: false, });
                currentComponent.props.history.push("/");
            } else {
                currentComponent.setState({ UserId: userId });
*/
                let data = {};

                var windowlocation = window.location.href;
                if (windowlocation.includes("orderid=")) {
                    var ordersplit = windowlocation.split("orderid=");
                    var getorderid = ordersplit[1];
                    if (
                        getorderid != null &&
                        getorderid.length != 0 &&
                        ordersplit[1].length > 1
                    ) {
                        data = { IsOrderAdditional: true, IsAdditionalOrder: true }
                    }
                }

                this.GetInitial(windowlocation);
            
    }

    async GetInitial(windowlocation) {
        let currentComponent = this;
        var companyType = currentComponent.state.CompanyType;
        await Api.Get(
            "api/placeorder/GetInitial",
            currentComponent
        )
            .then(function (result) {
                localStorage.removeItem('ShowAdminMenu');
                localStorage.removeItem('UserType');
                localStorage.removeItem('PlaceOrder');
                localStorage.removeItem('ViewResults');
                localStorage.removeItem('ViewAuth');
                localStorage.removeItem('ExportSearchResults');
                localStorage.removeItem('PerformanceMetrics');
                localStorage.setItem('ShowAdminMenu', result.isAdmin);
                localStorage.setItem('UserType', result.userType);
                localStorage.setItem('PlaceOrder', result.placeOrder);
                localStorage.setItem('ViewResults', result.downloadImages);
                localStorage.setItem('ViewAuth', result.viewAuth);
                localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                if (result.isAccessible) {

                    result.agentList.forEach((doc, index) => {
                        result.agentList[index].agentEmail = Api.decryptData(result.agentList[index].agentEmail);
                    });

                    result.doctorList.forEach((doc, index) => {
                        result.doctorList[index].drEmailAddress = Api.decryptData(result.doctorList[index].drEmailAddress);
                    });

                    result.carrierManagerList.forEach((doc, index) => {
                        result.carrierManagerList[index].emailAddress = Api.decryptData(result.carrierManagerList[index].emailAddress);
                    });

                    currentComponent.setState({ States: result.states, AllowDefaultOfficeCA: result.allowDefaultOfficeCA });
                    currentComponent.setState({ GenderList: result.gender });
                    currentComponent.setState({ PhoneTypes: result.phoneTypes });
                    currentComponent.setState({ Facilities: result.doctorList });
                    currentComponent.setState({ RequiredFieldsList: result.requiredFields });
                    currentComponent.setState({ InitialDocList: result.doctorList });
                    currentComponent.setState({ Agents: result.agentList });
                    currentComponent.setState({ InitialAgentList: result.agentList });
                    currentComponent.setState({ CarrierManagers: result.carrierManagerList });
                    currentComponent.setState({ InitialCarrierManagerList: result.carrierManagerList });
                    currentComponent.setState({ Adjusters: result.adjusterList ? result.adjusterList : [] });
                    currentComponent.setState({ InitialAdjusterList: result.adjusterList ? result.adjusterList : [] });
                    var userId = Api.decryptData(result.userId);
                    currentComponent.setState({ UserId: userId });

                    var isNotInboundOrder = windowlocation.indexOf("isFromInboundOrder") == -1 ? true : false;
                    if (isNotInboundOrder) {
                        if (result.orderOfficeInformationList != null && result.orderOfficeInformationList.length > 0) {
                            var orderOfficeInformation = result.orderOfficeInformationList.find(a => a.isDefault == true);
                            currentComponent.setState({ OrderingOfficeList: result.orderOfficeInformationList, OrderingOfficeId: orderOfficeInformation ? orderOfficeInformation.id : 0 });
                            if (result.orderOfficeInformationList.length == 1) {
                                currentComponent.setState({ OrderingOfficeId: result.orderOfficeInformationList[0].id });
                            }
                        } else {
                            currentComponent.toasterredfun2(
                                "Ordering office is not mapped/active, contact Administrator"
                            );
                        }
                    }
                    currentComponent.state.Facilities.map((doc, index) => {
                        currentComponent.setState({
                            Options: [
                                ...currentComponent.state.Options,
                                { label: doc.combinedName, value: "" + doc.id },
                            ],
                        });
                    });

                    if (currentComponent.state.CarrierManagers) {
                        currentComponent.state.CarrierManagers.map((doc, index) => {
                            currentComponent.setState({
                                CarrierManagerOptions: [
                                    ...currentComponent.state.CarrierManagerOptions,
                                    { label: doc.firstName + " " + doc.lastName, value: "" + doc.id },
                                ],
                            });
                        });
                    }

                    currentComponent.state.Agents.map((doc, index) => {
                        currentComponent.setState({
                            AgentOptions: [
                                ...currentComponent.state.AgentOptions,
                                { label: doc.combinedName, value: "" + doc.id },
                            ],
                        });
                    });

                    currentComponent.setState({
                        InitialDocValues: currentComponent.state.Options,
                        InitialAgentValues: currentComponent.state.AgentOptions,
                        InitialCarrierManagerValues: currentComponent.state.CarrierManagerOptions
                    });
                    if (companyType == "LEGAL") {
                        currentComponent.state.Adjusters.map((doc, index) => {
                            currentComponent.setState({
                                AdjusterOptions: [
                                    ...currentComponent.state.AdjusterOptions,
                                    { label: doc.combinedName, value: "" + doc.id },
                                ],
                            });

                        });
                    }
                    currentComponent.setState({
                        //InitialDocValues: currentComponent.state.Options,
                        InitialAdjusterValues: currentComponent.state.AdjusterOptions,
                    });
                    if (currentComponent.state.RequiredFieldsList.length > 0) {
                        for (var i = 0; i < currentComponent.state.RequiredFieldsList.length; i++) {
                            currentComponent.setState({
                                [currentComponent.state.RequiredFieldsList[i].className + 'Hide']: currentComponent.state.RequiredFieldsList[i].isActive,
                                ['isMandatory' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].isRequired,
                            });
                            if (currentComponent.state.RequiredFieldsList[i].customFieldName == "") {
                                currentComponent.setState({
                                    ['lbl' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].originalFieldName,
                                });
                            }
                            else {
                                currentComponent.setState({
                                    ['lbl' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].customFieldName,
                                });
                            }
                        }
                    }
                    if (companyType == "LEGAL") {
                        currentComponent.setState({ isMandatoryPolicyAmount: false });
                    }
                    currentComponent.renderSearch();
                    if (!window.location.href.includes("=")) {
                        currentComponent.setState({ Loading: false });
                    }
                    currentComponent.setState({ Loading: false });
                    currentComponent.GetOrderEditDetails(windowlocation, userId, companyType);
                } else {
                    currentComponent.setState({ Loading: false });
                    currentComponent.toasterredfun(
                        "You are not eligible to Place or Edit Order"
                    );

                }
            })
            .catch(function (error) {
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2(
                        "Check your internet connection."
                    );
                } else {
                    currentComponent.toasterredfun2(error);
                }
                if (!window.location.href.includes("=")) {
                    currentComponent.setState({ Loading: false });
                }
            });
    }

    async GetOrderEditDetails(windowlocation, userId, companyType) {
        let currentComponent = this;
        currentComponent.setState({ Loading: true });
        if (windowlocation.includes("orderid=")) {
            var ordersplit = windowlocation.split("orderid=");
            var getorderid = ordersplit[1];
            if (
                getorderid != null &&
                getorderid.length != 0 &&
                ordersplit[1].length > 1
            ) {
                currentComponent.setState({
                    HideHoldButton: true,
                    SubmitButtonChange: true,
                    DisAgentSearch: true,
                    DisCarrierSearch: true
                });
                await Api.Post(
                    "api/placeorder/GetOrderAdditionalEditDetails",
                    { Id: getorderid, UserId: userId },
                    currentComponent
                )
                    .then(function (result) {
                        if (result.statusCode == 401) {
                            currentComponent.toasterredfunback(
                                "You are not authorized to access the page"
                            );
                        } else {
                            if (result.statusMessage != "Success") {

                                currentComponent.toasterredfun2(result.statusMessage);
                            } else {
                                currentComponent.setState({
                                    OrderingOfficeId: result.orderingOfficeId,
                                    ExistingOrderingOfficeId: result.orderingOfficeId,
                                    isUnsubmitted: true,
                                    PatientId: result.patient.id,
                                    Title: Api.decryptData(result.patient.title),
                                    ssnChecked: true,
                                    FirstName: Api.decryptData(result.patient.patientFN),
                                    LastName: Api.decryptData(result.patient.patientLN),
                                    PCAddress: Api.decryptData(result.patient.address),
                                    DOB: new Date(
                                        Api.decryptData(result.patient.dob).replace("-", "/").replace("-", "/")
                                    ),
                                    Email: Api.decryptData(result.patient.email),
                                    SSN: Api.decryptData(result.patient.ssn ? result.patient.ssn : ""),
                                    Gender: "" + result.patient.Gender,
                                    OtherNames: Api.decryptData(result.patient.otherName),
                                    PolicyNum: result.patient.policy
                                        ? Api.decryptData(result.patient.policy)
                                        : "",
                                    PCCity: Api.decryptData(result.patient.city),
                                    StateId: "" + result.patient.stateId,
                                    PCZipCode: Api.decryptData(result.patient.zip),
                                    Phone2Type: result.patient.phone2Type
                                        ? result.patient.phone2Type
                                        : "",
                                    Phone3Type: result.patient.phone3Type
                                        ? result.patient.phone3Type
                                        : "",
                                    Phone1: Api.decryptData(result.patient.phone1),
                                    Phone2: Api.decryptData(result.patient.phone2),
                                    Phone3: Api.decryptData(result.patient.phone3),
                                    Phone1Type: result.patient.phone1Type,
                                    Ext1: Api.decryptData(result.patient.ext1),
                                    Ext2: Api.decryptData(result.patient.ext2),
                                    Ext3: Api.decryptData(result.patient.ext3),
                                    Fax: Api.decryptData(result.patient.fax),
                                    PreferredContact: Api.decryptData(result.patient.preferredContact),
                                    titleRO: true,
                                    firstNameRO: true,
                                    lastNameRO: true,
                                    dobRO: true,
                                    ssnRO: true,
                                    genderRO: true,
                                    policyRO: true,
                                    otherNamesRO: true,
                                    emailRO: true,
                                    addrRO: true,
                                    cityRO: true,
                                    phn1RO: true,
                                    phn2RO: true,
                                    phn3RO: true,
                                    zipRO: true,
                                    stateRO: true,
                                    ext1RO: true,
                                    ext2RO: true,
                                    ext3RO: true,
                                    phn1typeRO: true,
                                    phn2typeRO: true,
                                    phn3typeRO: true,
                                    faxRO: true,
                                    pcRO: true,
                                    policyAmountRO: true,
                                    orderingofficeRO: true,
                                    isdefaultorderingofficeRO: true,
                                    OtherField1: Api.decryptData(result.patient.otherField1),
                                    OtherField2: Api.decryptData(result.patient.otherField2),
                                    OtherField3: Api.decryptData(result.patient.otherField3),
                                    OtherField4: Api.decryptData(result.patient.otherField4),
                                    pOtherField1RO: true,
                                    pOtherField2RO: true,
                                    pOtherField3RO: true,
                                    pOtherField4RO: true,
                                    isFromCaseDetails:true,
                                    PolicyAmount: currentComponent.state.isMandatoryPolicyAmount && (Api.decryptData(result.patient.policyAmount) == "" || Api.decryptData(result.patient.policyAmount) == "0") ? "" : Api.decryptData(result.patient.policyAmount) ? Api.decryptData(result.patient.policyAmount) : "0",
                                    IsAuthView: result.isViewAuth
                                });
                                let orderingOfficeList = currentComponent.state.OrderingOfficeList;
                                if (orderingOfficeList && orderingOfficeList.length > 0) {
                                    if (result.orderingOfficeId != "0") {
                                        let orderingOfficeObj = orderingOfficeList.find(o => o.id == result.orderingOfficeId);

                                        if (orderingOfficeObj) {
                                            currentComponent.setState({
                                                OrderingOfficeId: result.orderingOfficeId, IsAdditionalOrder: false, orderingofficereq: false
                                            });
                                        } else {
                                            currentComponent.toasterredfun2(
                                                "Previously selected Ordering office does not have Place Order Permission/Inactive/not mapped. Please change the ordering Office."
                                            );
                                            currentComponent.setState({
                                                OrderingOfficeId: "0", IsAdditionalOrder: true, orderingofficereq: true
                                            });
                                        }
                                    }
                                }

                                currentComponent.setState({
                                    titleRO: currentComponent.state.isMandatoryTitle && currentComponent.state.Title == "" ? false : true,
                                    firstNameRO: currentComponent.state.isMandatoryFirstName && currentComponent.state.FirstName == "" ? false : true,
                                    lastNameRO: currentComponent.state.isMandatoryLastName && currentComponent.state.LastName == "" ? false : true,
                                    dobRO: currentComponent.state.isMandatoryDOB && currentComponent.state.DOB == "" ? false : true,
                                    ssnRO: currentComponent.state.isMandatorySSN && currentComponent.state.SSN == "" ? false : true,
                                    genderRO: currentComponent.state.isMandatoryGender && currentComponent.state.Gender == "" ? false : true,
                                    policyRO: currentComponent.state.isMandatoryPolicyNum && currentComponent.state.PolicyNum == "" ? false : true,
                                    otherNamesRO: currentComponent.state.isMandatoryOtherNames && currentComponent.state.OtherNames == "" ? false : true,
                                    emailRO: currentComponent.state.isMandatoryEmail && currentComponent.state.Email == "" ? false : true,
                                    addrRO: currentComponent.state.isMandatoryPCAddress && currentComponent.state.PCAddress == "" ? false : true,
                                    cityRO: currentComponent.state.isMandatoryPCCity && currentComponent.state.PCCity == "" ? false : true,
                                    phn1RO: currentComponent.state.isMandatoryPhone1 && currentComponent.state.Phone1 == "" ? false : true,
                                    phn2RO: currentComponent.state.isMandatoryPhone2 && currentComponent.state.Phone2 == "" ? false : true,
                                    phn3RO: currentComponent.state.isMandatoryPhone3 && currentComponent.state.Phone3 == "" ? false : true,
                                    zipRO: currentComponent.state.isMandatoryPCZip && currentComponent.state.PCZipCode == "" ? false : true,
                                    stateRO: currentComponent.state.isMandatoryStateId && (currentComponent.state.StateId == "Select" || currentComponent.state.StateId == "") ? false : true,
                                    ext1RO: currentComponent.state.isMandatoryExt1 && currentComponent.state.Ext1 == "" ? false : true,
                                    ext2RO: currentComponent.state.isMandatoryExt2 && currentComponent.state.Ext2 == "" ? false : true,
                                    ext3RO: currentComponent.state.isMandatoryExt3 && currentComponent.state.Ext3 == "" ? false : true,
                                    phn1typeRO: currentComponent.state.isMandatoryPhone1Type && (currentComponent.state.Phone1Type == "Select" || currentComponent.state.Phone1Type == "") ? false : true,
                                    phn2typeRO: currentComponent.state.isMandatoryPhone2Type && (currentComponent.state.Phone2Type == "Select" || currentComponent.state.Phone2Type == "") ? false : true,
                                    phn3typeRO: currentComponent.state.isMandatoryPhone3Type && (currentComponent.state.Phone3Type == "Select" || currentComponent.state.Phone3Type == "") ? false : true,
                                    faxRO: currentComponent.state.isMandatoryFax && currentComponent.state.Fax == "" ? false : true,
                                    pcRO: currentComponent.state.isMandatoryPreferredContact && currentComponent.state.PreferredContact == "" ? false : true,
                                    policyAmountRO: currentComponent.state.isMandatoryPolicyAmount && (currentComponent.state.PolicyAmount == "" || currentComponent.state.PolicyAmount == "0") ? false : true,
                                    orderingofficeRO: true,
                                    isdefaultorderingofficeRO: true,
                                    pOtherField1RO: currentComponent.state.isMandatoryOtherField1 && currentComponent.state.OtherField1 == "" ? false : true,
                                    pOtherField2RO: currentComponent.state.isMandatoryOtherField2 && currentComponent.state.OtherField2 == "" ? false : true,
                                    pOtherField3RO: currentComponent.state.isMandatoryOtherField3 && currentComponent.state.OtherField3 == "" ? false : true,
                                    pOtherField4RO: currentComponent.state.isMandatoryOtherField4 && currentComponent.state.OtherField4 == "" ? false : true,
                                });

                                if (result.carrierManager) {
                                    currentComponent.setState({
                                        CMId: result.carrierManager.id,
                                        CMFN: result.carrierManager.firstName,
                                        CMLN: result.carrierManager.lastName,
                                        CMEmailAddress: result.carrierManager.emailAddress,
                                        CMWorkPhone: result.carrierManager.workPhone,
                                        CMExt: result.carrierManager.ext,
                                        CMCellPhone: result.carrierManager.cellPhone,
                                        CMFax: result.carrierManager.fax,
                                    });
                                }
                                currentComponent.setState({
                                    CMFNRo: currentComponent.state.isMandatoryCMFN && currentComponent.state.CMFN == "" ? false : true,
                                    CMLNRo: currentComponent.state.isMandatoryCMLN && currentComponent.state.CMLN == "" ? false : true,
                                    CMWorkPhoneRo: currentComponent.state.isMandatoryCMWorkPhone && currentComponent.state.CMWorkPhone == "" ? false : true,
                                    CMExtRo: currentComponent.state.isMandatoryCMExt && currentComponent.state.CMExt == "" ? false : true,
                                    CMCellPhoneRo: currentComponent.state.isMandatoryCMCellPhone && currentComponent.state.CMCellPhone == "" ? false : true,
                                    CMFaxRo: currentComponent.state.isMandatoryCMFax && currentComponent.state.CMFax == "" ? false : true,
                                });

                                if (((!isNullOrUndefined(currentComponent.state.CMFN) && currentComponent.state.CMFN.trim().length != 0) ||
                                    (!isNullOrUndefined(currentComponent.state.CMLN) && currentComponent.state.CMLN.trim().length != 0) ||
                                    (!isNullOrUndefined(currentComponent.state.CMWorkPhone) && currentComponent.state.CMWorkPhone.trim().length != 0) ||
                                    (!isNullOrUndefined(currentComponent.state.CMExt) && currentComponent.state.CMExt.trim().length != 0) ||
                                    (!isNullOrUndefined(currentComponent.state.CMCellPhone) && currentComponent.state.CMCellPhone.trim().length != 0) ||
                                    (!isNullOrUndefined(currentComponent.state.CMFax) && currentComponent.state.CMFax.trim().length != 0)) &&
                                    currentComponent.state.CMEmailAddress.trim().length == 0) {
                                    currentComponent.setState({
                                        CMEmailAddressRo: currentComponent.state.CMEmailAddress == "" ? false : true
                                    });
                                }
                                else if (currentComponent.state.isMandatoryCMFN || currentComponent.state.isMandatoryCMLN || currentComponent.state.isMandatoryCMCellPhone || currentComponent.state.isMandatoryCMWorkPhone || currentComponent.state.isMandatoryCMExt || currentComponent.state.isMandatoryCMFax) {
                                    currentComponent.setState({ CMEmailAddressRo: currentComponent.state.CMEmailAddress == "" ? false : true });
                                }
                                else {
                                    currentComponent.setState({
                                        CMEmailAddressRo: true
                                    });
                                }
                                if (companyType == "LEGAL") {
                                    currentComponent.setState({
                                        OtherField1: Api.decryptData(result.patient.otherField1),
                                        OtherField2: Api.decryptData(result.patient.otherField2),
                                        OtherField3: Api.decryptData(result.patient.otherField3),
                                        OtherField4: Api.decryptData(result.patient.otherField4),
                                        DateOfIncident: Api.decryptData(result.patient.dateOfIncident),
                                        pDateOfIncidentRO: true,
                                        pOtherField1RO: true,
                                        pOtherField2RO: true,
                                        pOtherField3RO: true,
                                        pOtherField4RO: true,
                                        afnRO: true,
                                        alnRO: true,
                                        aemailRO: true,
                                        aphnRO: true,
                                        aextRO: true,
                                        aIdRO: true,
                                        amobileRO: true,
                                        afaxRO: true,
                                        adfnRO: true,
                                        adlnRO: true,
                                        ademailRO: true,
                                        adphnRO: true,
                                        adextRO: true,
                                        adIdRO: true,
                                        admobileRO: true,
                                        adfaxRO: true
                                    });
                                    if (result.attroney) {
                                        currentComponent.setState({
                                            AgId: result.attroney.id,
                                            AgentFN: result.attroney.agentFN,
                                            AgentLN: result.attroney.agentLN,
                                            AgentPhone: result.attroney.agentPhone,
                                            AgentExt: result.attroney.agentExt,
                                            AgentFax: result.attroney.agentFax,
                                            AgentEmail: result.attroney.agentEmail,
                                            AgentMobile: result.attroney.agentCell,
                                            AgentId: result.attroney.agentNumber,
                                            AgentUserId: result.attroney.userId,
                                            AgentUserRole: result.attroney.userRole,
                                            IsAgentId: result.attroney.id
                                        });
                                    }
                                    if (result.adjuster) {
                                        currentComponent.setState({
                                            AdId: result.adjuster.id,
                                            AdjusterFN: result.adjuster.agentFN,
                                            AdjusterLN: result.adjuster.agentLN,
                                            AdjusterEmail: result.adjuster.agentEmail,
                                            AdjusterPhone: result.adjuster.agentPhone,
                                            AdjusterExt: result.adjuster.agentExt,
                                            AdjusterMobile: result.adjuster.agentCell,
                                            AdjusterFax: result.adjuster.agentFax,
                                            AdjusterId: result.adjuster.agentNumber,
                                            IsAdjusterId: result.adjuster.id
                                        });
                                    }
                                    currentComponent.setState({
                                        pDateOfIncidentRO: currentComponent.state.isMandatoryDateOfIncident && currentComponent.state.DateOfIncident == "" ? false : true,
                                        pOtherField1RO: currentComponent.state.isMandatoryOtherField1 && currentComponent.state.OtherField1 == "" ? false : true,
                                        pOtherField2RO: currentComponent.state.isMandatoryOtherField2 && currentComponent.state.OtherField2 == "" ? false : true,
                                        pOtherField3RO: currentComponent.state.isMandatoryOtherField3 && currentComponent.state.OtherField3 == "" ? false : true,
                                        pOtherField4RO: currentComponent.state.isMandatoryOtherField4 && currentComponent.state.OtherField4 == "" ? false : true,

                                        afnRO: currentComponent.state.isMandatoryAgentFN && currentComponent.state.AgentFN == "" ? false : true,
                                        alnRO: currentComponent.state.isMandatoryAgentLN && currentComponent.state.AgentLN == "" ? false : true,
                                        aemailRO: currentComponent.state.isMandatoryAgentEmail && currentComponent.state.AgentEmail == "" ? false : true,
                                        aphnRO: currentComponent.state.isMandatoryAgentPhone && currentComponent.state.AgentPhone == "" ? false : true,
                                        aextRO: currentComponent.state.isMandatoryAgentExt && currentComponent.state.AgentExt == "" ? false : true,
                                        aIdRO: currentComponent.state.isMandatoryAgentId && currentComponent.state.AgentId == "" ? false : true,
                                        amobileRO: currentComponent.state.isMandatoryAgentMobile && currentComponent.state.AgentMobile == "" ? false : true,
                                        afaxRO: currentComponent.state.isMandatoryAgentFax && currentComponent.state.AgentFax == "" ? false : true,


                                        adfnRO: currentComponent.state.isMandatoryAdjusterFN && currentComponent.state.AdjusterFN == "" ? false : true,
                                        adlnRO: currentComponent.state.isMandatoryAdjusterLN && currentComponent.state.AdjusterLN == "" ? false : true,
                                        ademailRO: currentComponent.state.isMandatoryAdjusterEmail && currentComponent.state.AdjusterEmail == "" ? false : true,
                                        adphnRO: currentComponent.state.isMandatoryAdjusterPhone && currentComponent.state.AdjusterPhone == "" ? false : true,
                                        adextRO: currentComponent.state.isMandatoryAdjusterExt && currentComponent.state.AdjusterExt == "" ? false : true,
                                        adIdRO: currentComponent.state.isMandatoryAdjusterId && currentComponent.state.AdjusterId == "" ? false : true,
                                        admobileRO: currentComponent.state.isMandatoryAdjusterMobile && currentComponent.state.AdjusterMobile == "" ? false : true,
                                        adfaxRO: currentComponent.state.isMandatoryAdjusterFax && currentComponent.state.AdjusterFax == "" ? false : true
                                    });
                                } else {
                                    if (result.agent) {
                                        currentComponent.setState({
                                            AgId: result.agent.id,
                                            AgentFN: result.agent.agentFN,
                                            AgentLN: result.agent.agentLN,
                                            AgentPhone: result.agent.agentPhone,
                                            AgentExt: result.agent.agentExt,
                                            AgentFax: result.agent.agentFax,
                                            AgentEmail: result.agent.agentEmail,
                                            AgentMobile: result.agent.agentCell,
                                            AgentId: result.agent.agentNumber,
                                            AgentUserId: result.agent.userId,
                                            AgentUserRole: result.agent.userRole,
                                            IsAgentId: result.agent.id
                                        });
                                    }
                                    currentComponent.setState({
                                        afnRO: currentComponent.state.isMandatoryAgentFN && currentComponent.state.AgentFN == "" ? false : true,
                                        alnRO: currentComponent.state.isMandatoryAgentLN && currentComponent.state.AgentLN == "" ? false : true,
                                        aemailRO: currentComponent.state.isMandatoryAgentEmail && currentComponent.state.AgentEmail == "" ? false : true,
                                        aphnRO: currentComponent.state.isMandatoryAgentPhone && currentComponent.state.AgentPhone == "" ? false : true,
                                        aextRO: currentComponent.state.isMandatoryAgentExt && currentComponent.state.AgentExt == "" ? false : true,
                                        aIdRO: currentComponent.state.isMandatoryAgentId && currentComponent.state.AgentId == "" ? false : true,
                                        amobileRO: currentComponent.state.isMandatoryAgentMobile && currentComponent.state.AgentMobile == "" ? false : true,
                                        afaxRO: currentComponent.state.isMandatoryAgentFax && currentComponent.state.AgentFax == "" ? false : true
                                    });
                                }
                                var getDate = Api.decryptData(result.patient.dob)
                                    .replace("-", "/")
                                    .replace("-", "/");
                                setTimeout(
                                    function () {                                        
                                        document.getElementById("dobDate").value = getDate;
                                    }.bind(this),
                                    100
                                );
                                currentComponent.setState({
                                    DOB: getDate,
                                    isOrderAdditional: true,
                                });
                                if (companyType == "LEGAL") {
                                    result.facility.map((doc, index) => {
                                        var docs = [];
                                        doc.authDocs.map((document, i) => {
                                            docs = [
                                                ...docs,
                                                {
                                                    DownloadString: document.path
                                                        ? document.path
                                                        : "",
                                                    FileName: document.fileName,
                                                    MimeType: document.mimeType,
                                                    id: document.id,
                                                },
                                            ];
                                        });
                                        currentComponent.setState({
                                            DocList: [
                                                ...currentComponent.state.DocList,
                                                {
                                                    DrFacilityName: doc.drFacilityName
                                                        ? doc.drFacilityName
                                                        : "",
                                                    DrFirstName: doc.drFirstName,
                                                    DrLastName: doc.drLastName,
                                                    DrEmailAddress: doc.drEmailAddress,
                                                    DrAddress: doc.drAddress,
                                                    DrCity: doc.DrCity,
                                                    DrStateId: parseInt(doc.drStateId),
                                                    Id: parseInt(doc.id),
                                                    isNew: doc.isNew,
                                                    StateId: doc.drStateId,
                                                    DrState: doc.drState,
                                                    DrZipCode: doc.drZipCode,
                                                    DrPhone: doc.drPhone,
                                                    DrFax: doc.drFax,
                                                    RushRequest: doc.rushRequest,
                                                    AuthDocs: docs,
                                                    SpecialInstruction: doc.specialInstruction
                                                        ? doc.specialInstruction
                                                        : "",
                                                    SpecialType: doc.specialType ? doc.specialType : "",
                                                    StartDate: doc.startDate ? doc.startDate : "",
                                                    EndDate: doc.endDate ? doc.endDate : "",
                                                    DocEdit: false,
                                                    DocDelete: false,
                                                    OrderFacilityId: doc.mapId,
                                                    DateOfServiceType: doc.dateOfServiceType,
                                                    StartDate: doc.startDate ? new Date(doc.startDate) : "",
                                                    EndDate: doc.endDate ? new Date(doc.endDate) : "",
                                                    FacilityRequests: doc.facilityRequests
                                                },
                                            ],
                                        });
                                    });
                                }
                                else {
                                    result.facility.map((doc, index) => {
                                        var docs = [];
                                        doc.authDocs.map((document, i) => {
                                            docs = [
                                                ...docs,
                                                {
                                                    DownloadString: document.path
                                                        ? document.path
                                                        : "",
                                                    FileName: document.fileName,
                                                    MimeType: document.mimeType,
                                                    id: document.id,
                                                },
                                            ];
                                        });
                                        currentComponent.setState({
                                            DocList: [
                                                ...currentComponent.state.DocList,
                                                {
                                                    DrFacilityName: doc.drFacilityName
                                                        ? doc.drFacilityName
                                                        : "",
                                                    DrFirstName: doc.drFirstName,
                                                    DrLastName: doc.drLastName,
                                                    DrEmailAddress: doc.drEmailAddress,
                                                    DrAddress: doc.drAddress,
                                                    DrCity: doc.drCity,
                                                    DrStateId: parseInt(doc.drStateId),
                                                    Id: parseInt(doc.id),
                                                    isNew: doc.isNew,
                                                    StateId: doc.drStateId,
                                                    DrState: doc.drState,
                                                    DrZipCode: doc.drZipCode,
                                                    DrPhone: doc.drPhone,
                                                    DrFax: doc.drFax,
                                                    RushRequest: doc.rushRequest,
                                                    AuthDocs: docs,
                                                    SpecialInstruction: doc.specialInstruction
                                                        ? doc.specialInstruction
                                                        : "",
                                                    SpecialType: doc.specialType ? doc.specialType : "",
                                                    StartDate: doc.startDate ? doc.startDate : "",
                                                    EndDate: doc.endDate ? doc.endDate : "",
                                                    DocEdit: false,
                                                    DocDelete: false,
                                                    OrderFacilityId: doc.mapId,
                                                },
                                            ],
                                        });
                                    });
                                }

                                if (!isNullOrUndefined(result.facility) && result.facility.length > 0) {
                                    result.facility[0].authDocs.filter(x => isNullOrUndefined(x.title)).map((attDocument, i) => {
                                        currentComponent.setState({
                                            Files: [...currentComponent.state.Files, { DownloadString: attDocument.path, FileName: attDocument.fileName, MimeType: attDocument.mimeType, id: attDocument.id }],
                                            ExistingFiles: [...currentComponent.state.ExistingFiles, { DownloadString: attDocument.path, FileName: attDocument.fileName, MimeType: attDocument.mimeType, id: attDocument.id }]
                                        });
                                    });
                                }

                                currentComponent.setState({
                                    DoctorList: currentComponent.state.DocList,
                                    isUnsubmitted: true,
                                });
                                currentComponent.setState({
                                    Gender: "" + result.patient.gender,
                                });
                            }
                        }
                        currentComponent.setState({ Loading: false });
                    })
                    .catch(function (error) {
                        if (error == "TypeError: Failed to fetch") {
                            currentComponent.toasterredfun2(
                                "Check your internet connection."
                            );
                        } else {
                            currentComponent.toasterredfun2(error);
                        }
                        currentComponent.setState({ Loading: false });
                    });
            }
        }
        else if (windowlocation.includes("orderreopenid=")) {
            currentComponent.setState({ DisRushReq: true });
            var ordersplit = windowlocation.split("orderreopenid=");
            var getorderid = ordersplit[1];
            if (
                getorderid != null &&
                getorderid.length != 0 &&
                ordersplit[1].length > 1
            ) {
                currentComponent.setState({
                    HideHoldButton: true,
                    SubmitButtonChange: true,
                    ReOpenButtonHide: false,
                    DisOrderingOffice: true,
                });
                Api.Post(
                    "api/placeorder/GetOrderReopenEditDetails",
                    { Id: getorderid, UserId: userId },
                    currentComponent
                )
                    .then(function (result) {

                        if (result.statusCode == 401) {
                            currentComponent.toasterredfunback(
                                "You are not authorized to access the page"
                            );
                        } else {
                            if (result.statusMessage != "Success") {

                                currentComponent.toasterredfun2(result.statusMessage);
                            } else {

                                currentComponent.setState({
                                    OrderingOfficeId: result.orderingOfficeId,
                                    ExistingOrderingOfficeId: result.orderingOfficeId,
                                    PatientId: result.patient.id,
                                    Title: Api.decryptData(result.patient.title),
                                    ssnChecked: true,
                                    FirstName: Api.decryptData(result.patient.patientFN),
                                    LastName: Api.decryptData(result.patient.patientLN),
                                    PCAddress: Api.decryptData(result.patient.address),
                                    DOB: new Date(
                                        Api.decryptData(result.patient.dob).replace("-", "/").replace("-", "/")
                                    ),
                                    Email: Api.decryptData(result.patient.email),
                                    SSN: Api.decryptData(result.patient.ssn ? result.patient.ssn : ""),
                                    Gender: "" + result.patient.Gender,
                                    OtherNames: Api.decryptData(result.patient.otherName),
                                    PolicyNum: result.patient.policy
                                        ? Api.decryptData(result.patient.policy)
                                        : "",
                                    PCCity: Api.decryptData(result.patient.city),
                                    StateId: "" + result.patient.stateId,
                                    PCZipCode: Api.decryptData(result.patient.zip),
                                    Phone2Type: result.patient.phone2Type
                                        ? result.patient.phone2Type
                                        : "",
                                    Phone3Type: result.patient.phone3Type
                                        ? result.patient.phone3Type
                                        : "",
                                    Phone1: Api.decryptData(result.patient.phone1),
                                    Phone2: Api.decryptData(result.patient.phone2),
                                    Phone3: Api.decryptData(result.patient.phone3),
                                    Phone1Type: result.patient.phone1Type,
                                    Ext1: Api.decryptData(result.patient.ext1),
                                    Ext2: Api.decryptData(result.patient.ext2),
                                    Ext3: Api.decryptData(result.patient.ext3),
                                    Fax: Api.decryptData(result.patient.fax),
                                    PreferredContact: Api.decryptData(result.patient.preferredContact),
                                    titleRO: true,
                                    firstNameRO: true,
                                    lastNameRO: true,
                                    dobRO: true,
                                    ssnRO: true,
                                    genderRO: true,
                                    policyRO: true,
                                    otherNamesRO: true,
                                    emailRO: true,
                                    addrRO: true,
                                    cityRO: true,
                                    phn1RO: true,
                                    phn2RO: true,
                                    phn3RO: true,
                                    zipRO: true,
                                    stateRO: true,
                                    ext1RO: true,
                                    ext2RO: true,
                                    ext3RO: true,
                                    phn1typeRO: true,
                                    phn2typeRO: true,
                                    phn3typeRO: true,
                                    faxRO: true,
                                    pcRO: true,
                                    policyAmountRO: true,
                                    orderingofficeRO: true,
                                    isdefaultorderingofficeRO: true,
                                    OtherField1: Api.decryptData(result.patient.otherField1),
                                    OtherField2: Api.decryptData(result.patient.otherField2),
                                    OtherField3: Api.decryptData(result.patient.otherField3),
                                    OtherField4: Api.decryptData(result.patient.otherField4),
                                    pOtherField1RO: true,
                                    pOtherField2RO: true,
                                    pOtherField3RO: true,
                                    pOtherField4RO: true,
                                    isFromCaseDetails: true,
                                    PolicyAmount: Api.decryptData(result.patient.policyAmount)
                                        ? Api.decryptData(result.patient.policyAmount)
                                        : "0",
                                    IsAuthView: result.isViewAuth,
                                });
                                let orderingOfficeList = currentComponent.state.OrderingOfficeList;
                                if (orderingOfficeList && orderingOfficeList.length > 0) {
                                    if (result.orderingOfficeId != "0") {
                                        let orderingOfficeObj = orderingOfficeList.find(o => o.id == result.orderingOfficeId);

                                        if (orderingOfficeObj) {
                                            currentComponent.setState({
                                                OrderingOfficeId: result.orderingOfficeId, IsAdditionalOrder: false, orderingofficereq: false
                                            });
                                        } else {
                                            currentComponent.toasterredfun2(
                                                "Previously selected Ordering office does not have Place Order Permission/Inactive/not mapped. Please change the ordering Office."
                                            );
                                            currentComponent.setState({
                                                OrderingOfficeId: "0", IsAdditionalOrder: true, orderingofficereq: true
                                            });
                                        }
                                    }
                                }
                                currentComponent.setState({
                                    CMFNRo: true,
                                    CMLNRo: true,
                                    CMEmailAddressRo: true,
                                    CMWorkPhoneRo: true,
                                    CMExtRo: true,
                                    CMCellPhoneRo: true,
                                    CMFaxRo: true,
                                });

                                if (result.carrierManager) {
                                    currentComponent.setState({
                                        CMId: result.carrierManager.id,
                                        CMFN: result.carrierManager.firstName,
                                        CMLN: result.carrierManager.lastName,
                                        CMEmailAddress: result.carrierManager.emailAddress,
                                        CMWorkPhone: result.carrierManager.workPhone,
                                        CMExt: result.carrierManager.ext,
                                        CMCellPhone: result.carrierManager.cellPhone,
                                        CMFax: result.carrierManager.fax,
                                    });
                                }
                                if (localStorage.getItem("CompanyType") == "LEGAL") {
                                    currentComponent.setState({
                                        OtherField1: Api.decryptData(result.patient.otherField1),
                                        OtherField2: Api.decryptData(result.patient.otherField2),
                                        OtherField3: Api.decryptData(result.patient.otherField3),
                                        OtherField4: Api.decryptData(result.patient.otherField4),
                                        DateOfIncident: Api.decryptData(result.patient.dateOfIncident),
                                        pDateOfIncidentRO: true,
                                        pOtherField1RO: true,
                                        pOtherField2RO: true,
                                        pOtherField3RO: true,
                                        pOtherField4RO: true,
                                        afnRO: true,
                                        alnRO: true,
                                        aemailRO: true,
                                        aphnRO: true,
                                        aextRO: true,
                                        aIdRO: true,
                                        amobileRO: true,
                                        afaxRO: true,
                                        adfnRO: true,
                                        adlnRO: true,
                                        ademailRO: true,
                                        adphnRO: true,
                                        adextRO: true,
                                        adIdRO: true,
                                        admobileRO: true,
                                        adfaxRO: true
                                    });
                                    if (result.attroney) {
                                        currentComponent.setState({
                                            AgId: result.attroney.id,
                                            AgentFN: result.attroney.agentFN,
                                            AgentLN: result.attroney.agentLN,
                                            AgentPhone: result.attroney.agentPhone,
                                            AgentExt: result.attroney.agentExt,
                                            AgentFax: result.attroney.agentFax,
                                            AgentEmail: result.attroney.agentEmail,
                                            AgentMobile: result.attroney.agentCell,
                                            AgentId: result.attroney.agentId,
                                            AgentUserId: result.attroney.userId,
                                            AgentUserRole: result.attroney.userRole,
                                            IsAgentId: result.attroney.id
                                        });
                                    }
                                    if (result.adjuster) {
                                        currentComponent.setState({
                                            AdId: result.adjuster.id,
                                            AdjusterFN: result.adjuster.agentFN,
                                            AdjusterLN: result.adjuster.agentLN,
                                            AdjusterEmail: result.adjuster.agentEmail,
                                            AdjusterPhone: result.adjuster.agentPhone,
                                            AdjusterExt: result.adjuster.agentExt,
                                            AdjusterMobile: result.adjuster.agentCell,
                                            AdjusterFax: result.adjuster.agentFax,
                                            AdjusterId: result.adjuster.agentId,
                                            IsAdjusterId: result.adjuster.id
                                        });
                                    }
                                }
                                else {
                                    currentComponent.setState({
                                        afnRO: true,
                                        alnRO: true,
                                        aemailRO: true,
                                        aphnRO: true,
                                        aextRO: true,
                                        aIdRO: true,
                                        amobileRO: true,
                                        afaxRO: true,
                                    });
                                    if (result.agent) {
                                        currentComponent.setState({
                                            AgId: result.agent.id,
                                            AgentFN: result.agent.agentFN,
                                            AgentLN: result.agent.agentLN,
                                            AgentPhone: result.agent.agentPhone,
                                            AgentExt: result.agent.agentExt,
                                            AgentFax: result.agent.agentFax,
                                            AgentEmail: result.agent.agentEmail,
                                            AgentMobile: result.agent.agentCell,
                                            AgentId: result.agent.agentId,
                                            AgentUserId: result.agent.userId,
                                            AgentUserRole: result.agent.userRole,
                                            IsAgentId: result.agent.id
                                        });
                                    }
                                }
                                var getDate = Api.decryptData(result.patient.dob)
                                    .replace("-", "/")
                                    .replace("-", "/");
                                setTimeout(
                                    function () {
                                        document.getElementById("dobDate").value = getDate;
                                    }.bind(this),
                                    100
                                );
                                currentComponent.setState({
                                    DOB: getDate,
                                    isOrderAdditional: true,
                                });
                                if (companyType == "LEGAL") {
                                    result.facility.map((doc, index) => {

                                        if (doc.encMapId == getorderid) {

                                            var docs = [];
                                            doc.authDocs.map((document, i) => {
                                                docs = [
                                                    ...docs,
                                                    {
                                                        DownloadString: document.path
                                                            ? document.path
                                                            : "",
                                                        FileName: document.fileName,
                                                        MimeType: document.mimeType,
                                                        id: document.id,
                                                    },
                                                ];
                                            });
                                            //debugger
                                            currentComponent.setState({
                                                DrAddress: doc.drAddress,
                                                DrEmailAddress: doc.drEmailAddress,
                                                DoctorCity: doc.drCity,
                                                DrFax: doc.drFax,
                                                DoctorFirstName: doc.drFirstName,
                                                DoctorLastName: doc.drLastName,
                                                Phone: doc.drPhone,
                                                DrStateId: doc.drStateId,
                                                DrState: doc.drState,
                                                DrZipCode: doc.drZipCode,
                                                FacilityName: doc.drFacilityName,
                                                SpecialType: doc.specialType ? doc.specialType : "",
                                                SpecialInstruction: localStorage.getItem("ReopenComments"),
                                                StartDate: doc.startDate ? new Date(doc.startDate) : "",
                                                EndDate: doc.endDate ? new Date(doc.endDate) : "",
                                                RushReq: doc.rushRequest,
                                                FacilityId: doc.id,
                                                isNew: doc.isNew,
                                                Id: parseInt(doc.id),
                                                AuthDocs: docs,
                                                dFacNameRO: true,
                                                dFNRO: true,
                                                dLNRO: true,
                                                dEmailRO: true,
                                                dAddrRO: true,
                                                dCityRO: true,
                                                dStateRO: true,
                                                dZipRO: true,
                                                dPhnRO: true,
                                                dFaxRO: true,
                                                DisRushReq: true,
                                                DisY5SpecialType: true,
                                                DisY3SpecialType: true,
                                                DisCustomSpecialType: true,
                                                DisStartDate: true,
                                                DisEndDate: true,
                                                DisCustomRequest: true,
                                                DisSpecialInstruction: true,
                                                DisDOSAllRec: true,
                                                DisDOSCus: true,
                                                DisAddAnotherFac: true,
                                                DisO_RushReq: true,
                                                DisO_CertifiedReq: true,
                                                DisO_AffidavitReq: true,
                                                DisO_Standard: true,
                                                DisO_Custom: true,
                                                DisO_Cancel: true,
                                                DisFacilitySearch: true,
                                                DisAgentSearch: true,
                                                DisCarrierSearch: true,
                                                DatesOfService: doc.dateOfServiceType,
                                                CustomStartDate: doc.startDate ? new Date(doc.startDate) : "",
                                                CustomEndDate: doc.endDate ? new Date(doc.endDate) : "",
                                                OrderFacilityId: doc.mapId,
                                                CustomerProvidedFacility: doc.CustomerProvidedFacility,
                                            });
                                            //debugger
                                            if (doc.facilityRequests.length > 0) {
                                                currentComponent.setState({
                                                    RequestTypes: [{
                                                        ...doc.facilityRequests[0],
                                                        AffidavitRequest: doc.facilityRequests[0].affidavitRequest,
                                                        CertifiedRequest: doc.facilityRequests[0].certifiedRequest,
                                                        RequestType: doc.facilityRequests[0].requestType,
                                                        RushRequest: doc.facilityRequests[0].rushRequest,
                                                        SpecialInstruction: localStorage.getItem("ReopenComments"),
                                                        SpecialInstructionType: doc.facilityRequests[0].specialInstructionType
                                                    }]
                                                });
                                            }
                                            if (localStorage.getItem("CompanyType") == "LEGAL") {
                                                doc.facilityRequests.map((doc, index) => {
                                                    doc.specialInstruction = localStorage.getItem("ReopenComments");
                                                    if (doc.requestType == "Billing") {
                                                        currentComponent.setState({
                                                            BillingRequestType: doc.requestType, BillingRushRequest: doc.rushRequest
                                                            , BillingAffidavitRequest: doc.affidavitRequest, BillingCertifiedRequest: doc.certifiedRequest,
                                                            BillingSplInstruct: doc.specialInstructionType, BillingCustodianInstruction: doc.specialInstruction,
                                                            DisRqTypeMedical: true, DisRqTypeBilling: false, DisRqTypeFlims: true, DisRqTypeOther: true,
                                                        });
                                                    }
                                                    else if (doc.requestType == "Medical") {
                                                        currentComponent.setState({
                                                            MedicalRequestType: doc.requestType, MedicalRushRequest: doc.rushRequest
                                                            , MedicalAffidavitRequest: doc.affidavitRequest, MedicalCertifiedRequest: doc.certifiedRequest,
                                                            MedicalSplInstruct: doc.specialInstructionType, MedicalCustodianInstruction: doc.specialInstruction
                                                            , DisRqTypeMedical: false, DisRqTypeBilling: true, DisRqTypeFlims: true, DisRqTypeOther: true,
                                                        });
                                                    }
                                                    else if (doc.requestType == "Films") {
                                                        currentComponent.setState({
                                                            FilmsRequestType: doc.requestType, FilmsRushRequest: doc.rushRequest,
                                                            FilmsAffidavitRequest: doc.affidavitRequest, FilmsCertifiedRequest: doc.certifiedRequest,
                                                            FilmsSplInstruct: doc.specialInstructionType, FilmsCustodianInstruction: doc.specialInstruction
                                                            , DisRqTypeMedical: true, DisRqTypeBilling: true, DisRqTypeFlims: false, DisRqTypeOther: true,
                                                        });
                                                    }
                                                    else {
                                                        currentComponent.setState({
                                                            OtherRequestType: doc.requestType, OtherRushRequest: doc.rushRequest,
                                                            OtherAffidavitRequest: doc.affidavitRequest, OtherCertifiedRequest: doc.certifiedRequest,
                                                            OtherSplInstruct: doc.specialInstructionType, OtherCustodianInstruction: doc.specialInstruction
                                                            , DisRqTypeMedical: true, DisRqTypeBilling: true, DisRqTypeFlims: true, DisRqTypeOther: false,
                                                        });
                                                    }
                                                });
                                            }
                                            $("#sdate").val(
                                                doc.startDate.replace("-", "/").replace("-", "/")
                                            );
                                            $("#edate").val(
                                                doc.endDate.replace("-", "/").replace("-", "/")
                                            );
                                        }
                                    });
                                }
                                else {
                                    result.facility.map((doc, index) => {

                                        if (doc.encMapId == getorderid) {

                                            var docs = [];
                                            doc.authDocs.map((document, i) => {
                                                docs = [
                                                    ...docs,
                                                    {
                                                        DownloadString: document.path
                                                            ? document.path
                                                            : "",
                                                        FileName: document.fileName,
                                                        MimeType: document.mimeType,
                                                        id: document.id,
                                                    },
                                                ];
                                            });
                                            currentComponent.setState({
                                                DrAddress: doc.drAddress,
                                                DrEmailAddress: doc.drEmailAddress,
                                                DoctorCity: doc.drCity,
                                                DrFax: doc.drFax,
                                                DoctorFirstName: doc.drFirstName,
                                                DoctorLastName: doc.drLastName,
                                                Phone: doc.drPhone,
                                                DrStateId: doc.drStateId,
                                                DrState: doc.drState,
                                                DrZipCode: doc.drZipCode,
                                                FacilityName: doc.drFacilityName,
                                                SpecialType: doc.specialType ? doc.specialType : "",
                                                SpecialInstruction: localStorage.getItem("ReopenComments"),
                                                StartDate: doc.startDate ? new Date(doc.startDate) : "",
                                                EndDate: doc.endDate ? new Date(doc.endDate) : "",
                                                RushReq: doc.rushRequest,
                                                FacilityId: doc.id,
                                                isNew: doc.isNew,
                                                Id: parseInt(doc.id),
                                                AuthDocs: docs,
                                                dFacNameRO: true,
                                                dFNRO: true,
                                                dLNRO: true,
                                                dEmailRO: true,
                                                dAddrRO: true,
                                                dCityRO: true,
                                                dStateRO: true,
                                                dZipRO: true,
                                                dPhnRO: true,
                                                dFaxRO: true,
                                                DisRushReq: true,
                                                DisY5SpecialType: true,
                                                DisY3SpecialType: true,
                                                DisCustomSpecialType: true,
                                                DisStartDate: true,
                                                DisEndDate: true,
                                                DisCustomRequest: true,
                                                DisSpecialInstruction: true,
                                                DisDOSAllRec: true,
                                                DisDOSCus: true,
                                                DisAddAnotherFac: true,
                                                DisO_RushReq: true,
                                                DisO_CertifiedReq: true,
                                                DisO_AffidavitReq: true,
                                                DisO_Standard: true,
                                                DisO_Custom: true,
                                                DisO_Cancel: true,
                                                DisFacilitySearch: true,
                                                DisAgentSearch: true,
                                                DisCarrierSearch: true,
                                                DatesOfService: doc.dateOfServiceType,
                                                CustomStartDate: doc.startDate ? new Date(doc.startDate) : "",
                                                CustomEndDate: doc.endDate ? new Date(doc.endDate) : "",
                                                OrderFacilityId: doc.mapId,
                                                CustomerProvidedFacility: doc.CustomerProvidedFacility
                                            });
                                            if (doc.facilityRequests.length > 0) {
                                                currentComponent.setState({
                                                    RequestTypes: [{
                                                        ...doc.facilityRequests[0],
                                                        AffidavitRequest: doc.facilityRequests[0].affidavitRequest,
                                                        CertifiedRequest: doc.facilityRequests[0].certifiedRequest,
                                                        RequestType: doc.facilityRequests[0].requestType,
                                                        RushRequest: doc.facilityRequests[0].rushRequest,
                                                        SpecialInstruction: localStorage.getItem("ReopenComments"),
                                                        SpecialInstructionType: doc.facilityRequests[0].specialInstructionType
                                                    }]
                                                });
                                            }
                                            $("#sdate").val(
                                                doc.startDate.replace("-", "/").replace("-", "/")
                                            );
                                            $("#edate").val(
                                                doc.endDate.replace("-", "/").replace("-", "/")
                                            );
                                        }
                                    });
                                }
                                if (!isNullOrUndefined(result.facility) && result.facility.length > 0) {
                                    result.facility[0].authDocs.filter(x => x.title != 3 && x.title != 13 && x.title != 17).map((attDocument, i) => {
                                        currentComponent.setState({
                                            Files: [...currentComponent.state.Files, { DownloadString: attDocument.path, FileName: attDocument.fileName, MimeType: attDocument.mimeType, id: attDocument.id }],
                                            ExistingFiles: [...currentComponent.state.ExistingFiles, { DownloadString: attDocument.path, FileName: attDocument.fileName, MimeType: attDocument.mimeType, id: attDocument.id }]
                                        });
                                    });
                                }

                                currentComponent.setState({
                                    DoctorList: currentComponent.state.DocList,
                                });
                                currentComponent.setState({
                                    Gender: "" + result.patient.gender,
                                });
                            }
                        }
                        currentComponent.setState({ Loading: false });
                    })
                    .catch(function (error) {
                        if (error == "TypeError: Failed to fetch") {
                            currentComponent.toasterredfun2(
                                "Check your internet connection."
                            );
                        } else {
                            currentComponent.toasterredfun2(error);
                        }
                        currentComponent.setState({ Loading: false });
                    });
            }
        }
        else {
            var split = windowlocation.split("=");
            //var getid = windowlocation.substring(windowlocation.lastIndexOf("=") + 1);
            var getid = windowlocation.indexOf("isFromInboundOrder") != -1 ? windowlocation.substring(windowlocation.indexOf("=") + 1, windowlocation.indexOf("&")) : windowlocation.substring(windowlocation.lastIndexOf("=") + 1);
            var isInboundOrder = windowlocation.indexOf("isFromInboundOrder") != -1 ? true : false;
            if ((getid != null || getid.length != 0) && split.length > 1) {
                var requestUrl = isInboundOrder ? "api/placeorder/GetInboundOrderDetails" : "api/placeorder/GetHoldEditDetails";
                await Api.Post(
                    requestUrl,
                    { Id: getid, UserId: userId },
                    currentComponent
                )
                    .then(function (result) {

                        if (result.statusCode == 401) {
                            currentComponent.toasterredfunback(
                                "You are not authorized to access the page"
                            );
                        }
                        else if (result.statusCode == 400) {
                            currentComponent.setState({ willUnmount: false });
                            currentComponent.toasterredfunback("Bad request");
                            /* currentComponent.props.history.push("/unsubmitted-order");*/
                        } else {
                            if (result.statusMessage != "Success") {
                                currentComponent.toasterredfun2(result.statusMessage);
                                currentComponent.setState({ Loading: false });
                            } else {

                                
                                if (isInboundOrder) {
                                    $('#addAnotherCustodian').hide();
                                    $('#holdOrder').hide();
                                    $('#custodianInfo').hide();
                                    $('#attorneyInfo').hide();
                                    result.facility.map((doc, index) => {
                                        var docs = [];
                                        doc.authDocs.map((document, i) => {
                                            docs = [
                                                ...docs,
                                                {
                                                    DownloadString: document.downloadString
                                                        ? document.downloadString
                                                        : "",
                                                    FileName: document.fileName,
                                                    MimeType: document.mimeType,
                                                    id: document.id,
                                                },
                                            ];
                                        });

                                        currentComponent.setState({
                                            DrAddress: doc.drAddress ? doc.drAddress : "",
                                            DrEmailAddress: doc.drEmailAddress ? doc.drEmailAddress : "",
                                            DoctorCity: doc.drCity ? doc.drCity : "",
                                            DrFax: doc.drFax ? doc.drFax : "",
                                            DoctorFirstName: doc.drFirstName ? doc.drFirstName : "",
                                            DoctorLastName: doc.drLastName ? doc.drLastName : "",
                                            Phone: doc.drPhone ? doc.drPhone : "",
                                            DrState: doc.drState ? doc.drState : "",
                                            DrStateId: doc.drStateId ? doc.drStateId.toString() : "",
                                            DrZipCode: doc.drZipCode ? doc.drZipCode : "",
                                            FacilityName: doc.drFacilityName ? doc.drFacilityName : "",
                                            isNew: true,
                                            FacilityId: doc.id ? doc.id : 0,
                                            SpecialInstruction: doc.specialInstruction
                                                ? doc.specialInstruction
                                                : "",
                                            SpecialType: doc.specialType
                                                ? doc.specialType
                                                : "",

                                            Files: docs,
                                            IsMissingFacilityDetails: doc.isMissingFacilityDetails,
                                            CustomerProvidedFacility: doc.customerProvidedFacility
                                        });

                                        if (doc.id != 0) {
                                            currentComponent.setState({
                                                dFacNameRO: false,
                                                dFNRO: false,
                                                dLNRO: false,
                                                dEmailRO: false,
                                                dAddrRO: false,
                                                dCityRO: false,
                                                dStateRO: false,
                                                dZipRO: false,
                                                dPhnRO: false,
                                                dFaxRO: false,
                                                isNew: false
                                            });
                                        }
                                    });

                                    result.patient.patientFN = Api.decryptData(result.patient.patientFN);
                                    result.patient.patientLN = Api.decryptData(result.patient.patientLN);
                                    result.patient.address = Api.decryptData(result.patient.address);
                                    result.patient.dob = Api.decryptData(result.patient.dob);
                                    result.patient.email = Api.decryptData(result.patient.email);
                                    result.patient.ssn = Api.decryptData(result.patient.ssn ? result.patient.ssn : "");
                                    result.patient.policyAmount = Api.decryptData(result.patient.policyAmount);
                                    result.patient.policy = Api.decryptData(result.patient.policy ? result.patient.policy : "");
                                    result.patient.city = Api.decryptData(result.patient.city);
                                    result.patient.zip = Api.decryptData(result.patient.zip);
                                    result.patient.phone2 = Api.decryptData(result.patient.phone2);
                                    result.patient.phone2Type = Api.decryptData(result.patient.phone2Type ? result.patient.phone2Type : "");
                                    result.patient.phone3Type = Api.decryptData(result.patient.phone3Type ? result.patient.phone3Type : "");
                                    result.patient.phone1 = Api.decryptData(result.patient.phone1);
                                    result.patient.phone3 = Api.decryptData(result.patient.phone3);
                                    result.patient.phone1Type = Api.decryptData(result.patient.phone1Type);

                                    currentComponent.setState({
                                        OrderingOfficeList: result.orderOfficeInformationList,
                                        OrderingOfficeId: result.orderingOfficeId,
                                        OfficeAccountNumber: result.officeAccountNumber,
                                        SubmitButtonChange: false,
                                        Title: result.patient.title,
                                        ssnChecked: true,
                                        FirstName: result.patient.patientFN,
                                        LastName: result.patient.patientLN,
                                        PCAddress: result.patient.address,
                                        DOB: result.patient.dob ? new Date(result.patient.dob.replace("-", "/").replace("-", "/")) : "",
                                        Email: result.patient.email,
                                        SSN: result.patient.ssn ? result.patient.ssn : "",
                                        Gender: "" + result.patient.gender,
                                        PolicyAmount: result.patient.policyAmount,
                                        OtherNames: result.patient.otherName,
                                        PolicyNum: result.patient.policy ? result.patient.policy : "",
                                        PCCity: result.patient.city,
                                        StateId: "" + (result.patient.stateId == 0 ? "Select" : result.patient.stateId),
                                        PCZipCode: result.patient.zip,
                                        Phone2: result.patient.phone2,
                                        Phone2Type: result.patient.phone2Type ? result.patient.phone2Type : "",
                                        Phone3Type: result.patient.phone3Type ? result.patient.phone3Type : "",
                                        Phone1: result.patient.phone1,
                                        Phone3: result.patient.phone3,
                                        Phone1Type: result.patient.phone1Type,
                                        Ext1: result.patient.ext1,
                                        Ext2: result.patient.ext2,
                                        Ext3: result.patient.ext3,
                                        Fax: result.patient.fax,
                                        PreferredContact: result.patient.preferredContact,
                                        OtherField1: result.patient.otherField1,
                                        OtherField2: result.patient.otherField2,
                                        OtherField3: result.patient.otherField3,
                                        OtherField4: result.patient.otherField4,
                                        DateOfIncident: result.patient.dateOfIncident ? result.patient.dateOfIncident.replace("-", "/").replace("-", "/") : "",
                                        orderingofficeRO: true,
                                        ProductCode: result.productCode,
                                        APSReason: result.apsReason
                                    });
                                    currentComponent.setState({ MaskValue: currentComponent.state.SSN.length > 0 ? 'xxx-xxx-' + currentComponent.state.SSN.substr(currentComponent.state.SSN.length - 4) : currentComponent.state.SSN });

                                    if (result.agent) {
                                        currentComponent.setState({
                                            AgentFN: result.agent.agentFN,
                                            AgentLN: result.agent.agentLN,
                                            AgentPhone: result.agent.agentPhone,
                                            AgentExt: result.agent.agentExt,
                                            AgentFax: result.agent.agentFax,
                                            AgentEmail: result.agent.agentEmail,
                                            AgentMobile: result.agent.agentCell,
                                            AgentId: result.agent.agentId,
                                            AgId: result.agent.id,
                                            IsAgentId: result.agent.id > 0 ? result.agent.id : ""
                                        });
                                    }

                                    //if (result.agent && result.agent.id > 0) {
                                    //    currentComponent.setState({
                                    //        aemailRO: true,
                                    //        afnRO: true,
                                    //        alnRO: true,
                                    //        aphnRO: true,
                                    //        amobileRO: true,
                                    //        aextRO: true,
                                    //        afaxRO: true,
                                    //        aIdRO: true,
                                    //    });
                                    //}

                                    if (result.carrierManager) {
                                        currentComponent.setState({
                                            CMId: result.carrierManager.id && result.carrierManager.id > 0 ? result.carrierManager.id : "",
                                            CMFN: result.carrierManager.firstName ? result.carrierManager.firstName : "",
                                            CMLN: result.carrierManager.lastName ? result.carrierManager.lastName : "",
                                            CMEmailAddress: result.carrierManager.emailAddress ? result.carrierManager.emailAddress : "",
                                            CMWorkPhone: result.carrierManager.workPhone ? result.carrierManager.workPhone : "",
                                            CMExt: result.carrierManager.ext ? result.carrierManager.ext : "",
                                            CMCellPhone: result.carrierManager.cellPhone ? result.carrierManager.cellPhone : "",
                                            CMFax: result.carrierManager.fax ? result.carrierManager.fax : "",
                                        });
                                    }
                                    currentComponent.checkAndValidateOrderDetails(result);
                                }
                                else {
                                    let orderingOfficeList = currentComponent.state.OrderingOfficeList;

                                    if (orderingOfficeList && orderingOfficeList.length > 0) {
                                        if (result.orderingOfficeId != "0") {
                                            let orderingOfficeObj = orderingOfficeList.find(o => o.id == result.orderingOfficeId);

                                            if (orderingOfficeObj) {
                                                currentComponent.setState({
                                                    OrderingOfficeId: result.orderingOfficeId
                                                });
                                            } else {
                                                currentComponent.toasterredfun2(
                                                    "Previously selected ordering office is not active. Choose another ordering office"
                                                );
                                            }
                                        }
                                    }
                                    currentComponent.setState({ HoldOrderId: result.holdOrderId == "0" ? "" : result.holdOrderId, });
                                    currentComponent.setState({ isUnsubmitted: isInboundOrder ? true : false, });
                                    currentComponent.setState({ PatientId: result.patient.id == "0" ? "" : result.patient.id });
                                    currentComponent.setState({ Title: Api.decryptData(result.patient.title), });
                                    currentComponent.setState({ ssnChecked: true, });
                                    currentComponent.setState({ FirstName: Api.decryptData(result.patient.patientFN), });
                                    currentComponent.setState({ LastName: Api.decryptData(result.patient.patientLN), });
                                    currentComponent.setState({ PCAddress: Api.decryptData(result.patient.address), });
                                    if (Api.decryptData(result.patient.dob) != "") {
                                        currentComponent.setState({ DOB: new Date(Api.decryptData(result.patient.dob)) });
                                    }
                                    currentComponent.setState({ Email: Api.decryptData(result.patient.email), });
                                    currentComponent.setState({ SSN: Api.decryptData(result.patient.ssn ? result.patient.ssn : ""), });
                                    currentComponent.setState({ Gender: isNullOrUndefined(result.patient.gender) || result.patient.gender == -1 ? "Select" : result.patient.gender.toString() });
                                    currentComponent.setState({ PolicyAmount: Api.decryptData(result.patient.policyAmount) == "0" ? "" : Api.decryptData(result.patient.policyAmount) });
                                    currentComponent.setState({ OtherNames: Api.decryptData(result.patient.otherName), });
                                    currentComponent.setState({ PolicyNum: Api.decryptData(result.patient.policy ? result.patient.policy : ""), });
                                    currentComponent.setState({ PCCity: Api.decryptData(result.patient.city), });
                                    currentComponent.setState({ StateId: "" + result.patient.stateId, });
                                    currentComponent.setState({ PCZipCode: Api.decryptData(result.patient.zip), });
                                    currentComponent.setState({ Phone2: Api.decryptData(result.patient.phone2), });
                                    currentComponent.setState({ Phone2Type: result.patient.phone2Type ? result.patient.phone2Type : "", });
                                    currentComponent.setState({ Phone3Type: result.patient.phone3Type ? result.patient.phone3Type : "", });
                                    currentComponent.setState({ Phone1: Api.decryptData(result.patient.phone1), });
                                    currentComponent.setState({ Phone3: Api.decryptData(result.patient.phone3), });
                                    currentComponent.setState({ Phone1Type: result.patient.phone1Type, });
                                    currentComponent.setState({ Ext1: Api.decryptData(result.patient.ext1), });
                                    currentComponent.setState({ Ext2: Api.decryptData(result.patient.ext2), });
                                    currentComponent.setState({ Ext3: Api.decryptData(result.patient.ext3), });
                                    currentComponent.setState({ Fax: Api.decryptData(result.patient.fax), });
                                    currentComponent.setState({ PreferredContact: Api.decryptData(result.patient.preferredContact), });
                                    currentComponent.setState({ OtherField1: Api.decryptData(result.patient.otherField1), });
                                    currentComponent.setState({ OtherField2: Api.decryptData(result.patient.otherField2), });
                                    currentComponent.setState({ OtherField3: Api.decryptData(result.patient.otherField3), });
                                    currentComponent.setState({ OtherField4: Api.decryptData(result.patient.otherField4), });
                                    if (Api.decryptData(result.patient.dateOfIncident) != "") {
                                        currentComponent.setState({ DateOfIncident: Api.decryptData(result.patient.dateOfIncident).replace("-", "/").replace("-", "/"), });
                                    }
                                    currentComponent.setState({ MaskValue: currentComponent.state.SSN.length > 0 ? 'xxx-xxx-' + currentComponent.state.SSN.substr(currentComponent.state.SSN.length - 4) : currentComponent.state.SSN });

                                    if (companyType == "LEGAL") {
                                        if (result.attroney) {
                                            currentComponent.setState({
                                                AgId: result.attroney.id == "0" ? "" : result.attroney.id,
                                                AgentFN: result.attroney.agentFN,
                                                AgentLN: result.attroney.agentLN,
                                                AgentPhone: result.attroney.agentPhone,
                                                AgentExt: result.attroney.agentExt,
                                                AgentFax: result.attroney.agentFax,
                                                AgentEmail: result.attroney.agentEmail,
                                                AgentMobile: result.attroney.agentCell,
                                                AgentId: result.attroney.agentId == "0" ? "" : result.attroney.agentId,
                                                IsAgentId: result.attroney.id > 0 ? result.attroney.id.toString() : "",
                                                AgentUserId: result.attroney.userId,
                                                AgentUserRole: result.attroney.userRole
                                                //RequestTypes: result.facilityRequest,                                                
                                            });
                                        }
                                        if (result.adjuster) {
                                            currentComponent.setState({
                                                AdId: result.adjuster.id == "0" ? "" : result.adjuster.id,
                                                AdjusterFN: result.adjuster.agentFN,
                                                AdjusterLN: result.adjuster.agentLN,
                                                AdjusterEmail: result.adjuster.agentEmail,
                                                AdjusterPhone: result.adjuster.agentPhone,
                                                AdjusterExt: result.adjuster.agentExt,
                                                AdjusterMobile: result.adjuster.agentCell,
                                                AdjusterFax: result.adjuster.agentFax,
                                                AdjusterId: result.adjuster.agentId == "0" ? "" : result.adjuster.agentId,
                                                IsAdjusterId: result.adjuster.id > 0 ? result.adjuster.id.toString() : ""
                                                //RequestTypes: result.facilityRequest,                                                
                                            });
                                        }
                                    }
                                    else {
                                        if (result.agent) {
                                            currentComponent.setState({
                                                AgId: result.agent.id == "0" ? "" : result.agent.id,
                                                AgentFN: result.agent.agentFN,
                                                AgentLN: result.agent.agentLN,
                                                AgentPhone: result.agent.agentPhone,
                                                AgentExt: result.agent.agentExt,
                                                AgentFax: result.agent.agentFax,
                                                AgentEmail: result.agent.agentEmail,
                                                AgentMobile: result.agent.agentCell,
                                                AgentId: result.agent.agentId == "0" ? "" : result.agent.agentId,
                                                IsAgentId: result.agent.id > 0 ? result.agent.id.toString() : "",
                                                AgentUserId: result.agent.userId,
                                                AgentUserRole: result.agent.userRole
                                            });
                                        }
                                    }

                                    if (companyType == "LEGAL") {
                                        if (result.facility) {
                                            result.facility.map((doc, index) => {
                                                var docs = [];
                                                doc.authDocs.map((document, i) => {
                                                    docs = [
                                                        ...docs,
                                                        {
                                                            DownloadString: document.path
                                                                ? document.path
                                                                : "",
                                                            FileName: document.fileName,
                                                            MimeType: document.mimeType,
                                                            id: document.id,
                                                        },
                                                    ];
                                                });

                                                var customerProvidedFacility = "";
                                                if (doc.drFirstName) {
                                                    customerProvidedFacility += "Dr " + doc.drFirstName;
                                                }
                                                if (doc.drLastName) {
                                                    customerProvidedFacility += doc.drFirstName ? " " + doc.drLastName : "Dr " + doc.drLastName;
                                                }
                                                customerProvidedFacility += ", " + doc.drFacilityName + ", " + doc.drAddress + ", " + doc.drCity + ", " + doc.drState + ", " + doc.drZipCode + ", " + (doc.drPhone ? "Ph: " + doc.drPhone : "");

                                                doc.CustomerProvidedFacility = customerProvidedFacility;
                                                var idFrom = getid.split("_");
                                                var mapId = idFrom.length > 1 ? parseInt(idFrom[0]) : parseInt(getid);
                                                if (parseInt(doc.mapId) != mapId) {
                                                    if (doc.facilityRequests.length > 0) {
                                                        currentComponent.setState({
                                                            DocList: [
                                                                ...currentComponent.state.DocList,
                                                                {
                                                                    DrFacilityName: doc.drFacilityName
                                                                        ? doc.drFacilityName
                                                                        : "",
                                                                    DrFirstName: doc.drFirstName,
                                                                    DrLastName: doc.drLastName,
                                                                    DrEmailAddress: doc.drEmailAddress,
                                                                    DrAddress: doc.drAddress,
                                                                    DrCity: doc.drCity,
                                                                    DrStateId: parseInt(doc.drStateId),
                                                                    Id: parseInt(doc.id),
                                                                    isNew: doc.isNew,
                                                                    StateId: doc.drStateId,
                                                                    DrState: doc.drState,
                                                                    DrZipCode: doc.drZipCode,
                                                                    DrPhone: doc.drPhone,
                                                                    DrFax: doc.drFax,
                                                                    RushRequest: doc.rushRequest,
                                                                    AuthDocs: docs,
                                                                    SpecialInstruction: doc.specialInstruction
                                                                        ? doc.specialInstruction
                                                                        : "",
                                                                    SpecialType: doc.specialType
                                                                        ? doc.specialType
                                                                        : "",
                                                                    DocEdit: true,
                                                                    DocDelete: true,
                                                                    OrderFacilityId: doc.mapId,
                                                                    DateOfServiceType: doc.dateOfServiceType,
                                                                    StartDate: doc.startDate.indexOf('1900') != -1 || doc.startDate.indexOf('1899') != -1 ? "" : new Date(doc.startDate),
                                                                    EndDate: doc.endDate.indexOf('1900') != -1 || doc.endDate.indexOf('1899') != -1 ? "" : new Date(doc.endDate),
                                                                    CustomerProvidedFacility: doc.CustomerProvidedFacility,
                                                                    FacilityRequests: [{
                                                                        ...doc.facilityRequests[0],
                                                                        AffidavitRequest: doc.facilityRequests[0].affidavitRequest,
                                                                        CertifiedRequest: doc.facilityRequests[0].certifiedRequest,
                                                                        RequestType: doc.facilityRequests[0].requestType,
                                                                        RushRequest: doc.facilityRequests[0].rushRequest,
                                                                        SpecialInstruction: doc.facilityRequests[0].specialInstruction,
                                                                        SpecialInstructionType: doc.facilityRequests[0].specialInstructionType
                                                                    }]
                                                                },
                                                            ],
                                                        });
                                                    }
                                                    else {
                                                        currentComponent.setState({
                                                            DocList: [
                                                                ...currentComponent.state.DocList,
                                                                {
                                                                    DrFacilityName: doc.drFacilityName
                                                                        ? doc.drFacilityName
                                                                        : "",
                                                                    DrFirstName: doc.drFirstName,
                                                                    DrLastName: doc.drLastName,
                                                                    DrEmailAddress: doc.drEmailAddress,
                                                                    DrAddress: doc.drAddress,
                                                                    DrCity: doc.drCity,
                                                                    DrStateId: parseInt(doc.drStateId),
                                                                    Id: parseInt(doc.id),
                                                                    isNew: doc.isNew,
                                                                    StateId: doc.drStateId,
                                                                    DrState: doc.drState,
                                                                    DrZipCode: doc.drZipCode,
                                                                    DrPhone: doc.drPhone,
                                                                    DrFax: doc.drFax,
                                                                    RushRequest: doc.rushRequest,
                                                                    AuthDocs: docs,
                                                                    SpecialInstruction: doc.specialInstruction
                                                                        ? doc.specialInstruction
                                                                        : "",
                                                                    SpecialType: doc.specialType
                                                                        ? doc.specialType
                                                                        : "",
                                                                    DocEdit: true,
                                                                    DocDelete: true,
                                                                    OrderFacilityId: doc.mapId,
                                                                    DateOfServiceType: doc.dateOfServiceType,
                                                                    StartDate: doc.startDate.indexOf('1900') != -1 || doc.startDate.indexOf('1899') != -1 ? "" : new Date(doc.startDate),
                                                                    EndDate: doc.endDate.indexOf('1900') != -1 || doc.endDate.indexOf('1899') != -1 ? "" : new Date(doc.endDate),
                                                                    CustomerProvidedFacility: doc.CustomerProvidedFacility,
                                                                    FacilityRequests: []
                                                                },
                                                            ],
                                                        });
                                                    }
                                                }
                                                else {
                                                    if (doc.facilityRequests.length > 0) {
                                                        var DocSpecialInstructionType = isInboundOrder ? doc.specialType : doc.facilityRequests[0].specialInstructionType;
                                                        var DocSpecialInstruction = isInboundOrder ? doc.specialInstruction : doc.facilityRequests[0].specialInstruction;
                                                        currentComponent.setState({
                                                            Files: docs,
                                                            DrAddress: doc.drAddress,
                                                            DrEmailAddress: doc.drEmailAddress,
                                                            DoctorCity: doc.drCity,
                                                            DrFax: doc.drFax,
                                                            DoctorFirstName: doc.drFirstName,
                                                            DoctorLastName: doc.drLastName,
                                                            Phone: doc.drPhone,
                                                            DrStateId: doc.drStateId,
                                                            DrState: doc.drState,
                                                            DrZipCode: doc.drZipCode,
                                                            FacilityName: doc.drFacilityName,
                                                            SpecialType: DocSpecialInstructionType ? DocSpecialInstructionType : "",
                                                            SpecialInstruction: DocSpecialInstruction ? DocSpecialInstruction : "",
                                                            StartDate: doc.startDate.indexOf('1900') != -1 || doc.startDate.indexOf('1899') != -1 ? "" : new Date(doc.startDate),
                                                            EndDate: doc.endDate.indexOf('1900') != -1 || doc.endDate.indexOf('1899') != -1 ? "" : new Date(doc.endDate),
                                                            RushReq: doc.rushRequest,
                                                            FacilityId: doc.id,
                                                            isNew: doc.isNew,
                                                            Id: parseInt(doc.id),
                                                            dFacNameRO: false,
                                                            dFNRO: false,
                                                            dLNRO: false,
                                                            dEmailRO: false,
                                                            dAddrRO: false,
                                                            dCityRO: false,
                                                            dStateRO: false,
                                                            dZipRO: false,
                                                            dPhnRO: false,
                                                            dFaxRO: false,
                                                            DatesOfService: doc.dateOfServiceType,
                                                            CustomStartDate: doc.startDate.indexOf('1900') != -1 || doc.startDate.indexOf('1899') != -1 ? "" : new Date(doc.startDate),
                                                            CustomEndDate: doc.endDate.indexOf('1900') != -1 || doc.endDate.indexOf('1899') != -1 ? "" : new Date(doc.endDate),
                                                            OrderFacilityId: doc.mapId,
                                                            CustomerProvidedFacility: doc.CustomerProvidedFacility,
                                                            RequestTypes: [{
                                                                ...doc.facilityRequests[0],
                                                                AffidavitRequest: doc.facilityRequests[0].affidavitRequest,
                                                                CertifiedRequest: doc.facilityRequests[0].certifiedRequest,
                                                                RequestType: doc.facilityRequests[0].requestType,
                                                                RushRequest: doc.facilityRequests[0].rushRequest,
                                                                DatesOfService: doc.dateOfServiceType,
                                                                SpecialInstruction: doc.facilityRequests[0].specialInstruction,
                                                                SpecialInstructionType: doc.facilityRequests[0].specialInstructionType
                                                            }]
                                                        });

                                                    }
                                                    else {
                                                        currentComponent.setState({
                                                            Files: docs,
                                                            DrAddress: doc.drAddress,
                                                            DrEmailAddress: doc.drEmailAddress,
                                                            DoctorCity: doc.drCity,
                                                            DrFax: doc.drFax,
                                                            DoctorFirstName: doc.drFirstName,
                                                            DoctorLastName: doc.drLastName,
                                                            Phone: doc.drPhone,
                                                            DrStateId: doc.drStateId,
                                                            DrState: doc.drState,
                                                            DrZipCode: doc.drZipCode,
                                                            FacilityName: doc.drFacilityName,
                                                            StartDate: doc.startDate.indexOf('1900') != -1 || doc.startDate.indexOf('1899') != -1 ? "" : new Date(doc.startDate),
                                                            EndDate: doc.endDate.indexOf('1900') != -1 || doc.endDate.indexOf('1899') != -1 ? "" : new Date(doc.endDate),
                                                            RushReq: doc.rushRequest,
                                                            FacilityId: doc.id,
                                                            isNew: doc.isNew,
                                                            Id: parseInt(doc.id),
                                                            dFacNameRO: false,
                                                            dFNRO: false,
                                                            dLNRO: false,
                                                            dEmailRO: false,
                                                            dAddrRO: false,
                                                            dCityRO: false,
                                                            dStateRO: false,
                                                            dZipRO: false,
                                                            dPhnRO: false,
                                                            dFaxRO: false,
                                                            DatesOfService: doc.dateOfServiceType,
                                                            CustomStartDate: doc.startDate.indexOf('1900') != -1 || doc.startDate.indexOf('1899') != -1 ? "" : new Date(doc.startDate),
                                                            CustomEndDate: doc.endDate.indexOf('1900') != -1 || doc.endDate.indexOf('1899') != -1 ? "" : new Date(doc.endDate),
                                                            OrderFacilityId: doc.mapId,
                                                            CustomerProvidedFacility: doc.CustomerProvidedFacility,
                                                            RequestTypes: []
                                                        });
                                                    }
                                                    if (companyType == "LEGAL") {
                                                        doc.facilityRequests.map((doc, index) => {
                                                            if (doc.requestType == "Billing") {
                                                                currentComponent.setState({
                                                                    BillingRequestType: doc.requestType, BillingRushRequest: doc.rushRequest
                                                                    , BillingAffidavitRequest: doc.affidavitRequest, BillingCertifiedRequest: doc.certifiedRequest,
                                                                    BillingSplInstruct: doc.specialInstructionType, BillingCustodianInstruction: doc.specialInstruction
                                                                });
                                                            }
                                                            else if (doc.requestType == "Medical") {
                                                                currentComponent.setState({
                                                                    MedicalRequestType: doc.requestType, MedicalRushRequest: doc.rushRequest
                                                                    , MedicalAffidavitRequest: doc.affidavitRequest, MedicalCertifiedRequest: doc.certifiedRequest,
                                                                    MedicalSplInstruct: doc.specialInstructionType, MedicalCustodianInstruction: doc.specialInstruction
                                                                });
                                                            }
                                                            else if (doc.requestType == "Films") {
                                                                currentComponent.setState({
                                                                    FilmsRequestType: doc.requestType, FilmsRushRequest: doc.rushRequest,
                                                                    FilmsAffidavitRequest: doc.affidavitRequest, FilmsCertifiedRequest: doc.certifiedRequest,
                                                                    FilmsSplInstruct: doc.specialInstructionType, FilmsCustodianInstruction: doc.specialInstruction
                                                                });
                                                            }
                                                            else {
                                                                currentComponent.setState({
                                                                    OtherRequestType: doc.requestType, OtherRushRequest: doc.rushRequest,
                                                                    OtherAffidavitRequest: doc.affidavitRequest, OtherCertifiedRequest: doc.certifiedRequest,
                                                                    OtherSplInstruct: doc.specialInstructionType, OtherCustodianInstruction: doc.specialInstruction
                                                                });
                                                            }
                                                        });
                                                    }
                                                    $("#sdate").val(
                                                        doc.startDate.replace("-", "/").replace("-", "/")
                                                    );
                                                    $("#edate").val(
                                                        doc.endDate.replace("-", "/").replace("-", "/")
                                                    );
                                                }
                                            });
                                        }
                                    }
                                    else {
                                        if (result.facility) {
                                            result.facility.map((doc, index) => {
                                                var docs = [];
                                                doc.authDocs.map((document, i) => {
                                                    docs = [
                                                        ...docs,
                                                        {
                                                            DownloadString: document.path
                                                                ? document.path
                                                                : "",
                                                            FileName: document.fileName,
                                                            MimeType: document.mimeType,
                                                            id: document.id,
                                                        },
                                                    ];
                                                });

                                                var customerProvidedFacility = "";
                                                if (doc.drFirstName) {
                                                    customerProvidedFacility += "Dr " + doc.drFirstName;
                                                }
                                                if (doc.drLastName) {
                                                    customerProvidedFacility += doc.drFirstName ? " " + doc.drLastName : "Dr " + doc.drLastName;
                                                }
                                                customerProvidedFacility += ", " + doc.drFacilityName + ", " + doc.drAddress + ", " + doc.drCity + ", " + doc.drState + ", " + doc.drZipCode + ", " + (doc.drPhone ? "Ph: " + doc.drPhone : "");

                                                doc.CustomerProvidedFacility = customerProvidedFacility;

                                                if (doc.mapId != parseInt(getid)) {
                                                    currentComponent.setState({
                                                        DocList: [
                                                            ...currentComponent.state.DocList,
                                                            {
                                                                DrFacilityName: doc.drFacilityName
                                                                    ? doc.drFacilityName
                                                                    : "",
                                                                DrFirstName: doc.drFirstName,
                                                                DrLastName: doc.drLastName,
                                                                DrAddress: doc.drAddress,
                                                                DrEmailAddress: doc.drEmailAddress,
                                                                DrCity: doc.drCity,
                                                                DrStateId: parseInt(doc.drStateId),
                                                                Id: parseInt(doc.id),
                                                                isNew: doc.isNew,
                                                                // StateId: doc.drStateId,
                                                                DrState: doc.drState,
                                                                DrZipCode: doc.drZipCode,
                                                                DrPhone: doc.drPhone,
                                                                DrFax: doc.drFax,
                                                                RushRequest: doc.rushRequest,
                                                                AuthDocs: docs,
                                                                SpecialInstruction: doc.specialInstruction
                                                                    ? doc.specialInstruction
                                                                    : "",
                                                                SpecialType: doc.specialType
                                                                    ? doc.specialType
                                                                    : "",
                                                                StartDate: doc.startDate.indexOf('1900') != -1 || doc.startDate.indexOf('1899') != -1 ? "" : new Date(doc.startDate),
                                                                EndDate: doc.endDate.indexOf('1900') != -1 || doc.endDate.indexOf('1899') != -1 ? "" : new Date(doc.endDate),
                                                                DocEdit: true,
                                                                DocDelete: true,
                                                                OrderFacilityId: doc.mapId,
                                                                CustomerProvidedFacility: doc.CustomerProvidedFacility,
                                                            },
                                                        ],
                                                    });
                                                }
                                                else {
                                                    if (doc.facilityRequests.length > 0) {
                                                        var DocSpecialInstructionType = isInboundOrder ? doc.specialType : doc.facilityRequests[0].specialInstructionType;
                                                        var DocSpecialInstruction = isInboundOrder ? doc.specialInstruction : doc.facilityRequests[0].specialInstruction;
                                                        currentComponent.setState({
                                                            Files: docs,
                                                            DrAddress: doc.drAddress,
                                                            DrEmailAddress: doc.drEmailAddress,
                                                            DoctorCity: doc.drCity,
                                                            DrFax: doc.drFax,
                                                            DoctorFirstName: doc.drFirstName,
                                                            DoctorLastName: doc.drLastName,
                                                            Phone: doc.drPhone,
                                                            DrStateId: doc.drStateId,
                                                            DrState: doc.drState,
                                                            DrZipCode: doc.drZipCode,
                                                            FacilityName: doc.drFacilityName,
                                                            SpecialType: DocSpecialInstructionType ? DocSpecialInstructionType : "",
                                                            SpecialInstruction: DocSpecialInstruction ? DocSpecialInstruction : "",
                                                            StartDate: doc.startDate.indexOf('1900') != -1 || doc.startDate.indexOf('1899') != -1 ? "" : new Date(doc.startDate),
                                                            EndDate: doc.endDate.indexOf('1900') != -1 || doc.endDate.indexOf('1899') != -1 ? "" : new Date(doc.endDate),
                                                            RushReq: doc.rushRequest,
                                                            FacilityId: doc.id,
                                                            isNew: doc.isNew,
                                                            Id: parseInt(doc.id),
                                                            dFacNameRO: false,
                                                            dFNRO: false,
                                                            dLNRO: false,
                                                            dEmailRO: false,
                                                            dAddrRO: false,
                                                            dCityRO: false,
                                                            dStateRO: false,
                                                            dZipRO: false,
                                                            dPhnRO: false,
                                                            dFaxRO: false,
                                                            DatesOfService: doc.dateOfServiceType,
                                                            CustomStartDate: doc.startDate.indexOf('1900') != -1 || doc.startDate.indexOf('1899') != -1 ? "" : new Date(doc.startDate),
                                                            CustomEndDate: doc.endDate.indexOf('1900') != -1 || doc.endDate.indexOf('1899') != -1 ? "" : new Date(doc.endDate),
                                                            OrderFacilityId: doc.mapId,
                                                            CustomerProvidedFacility: doc.CustomerProvidedFacility,
                                                        });
                                                    }
                                                    else {
                                                        currentComponent.setState({
                                                            Files: docs,
                                                            DrAddress: doc.drAddress,
                                                            DrEmailAddress: doc.drEmailAddress,
                                                            DoctorCity: doc.drCity,
                                                            DrFax: doc.drFax,
                                                            DoctorFirstName: doc.drFirstName,
                                                            DoctorLastName: doc.drLastName,
                                                            Phone: doc.drPhone,
                                                            DrStateId: doc.drStateId,
                                                            DrState: doc.drState,
                                                            DrZipCode: doc.drZipCode,
                                                            FacilityName: doc.drFacilityName,
                                                            SpecialType: doc.specialType,
                                                            SpecialInstruction: doc.specialInstruction,
                                                            StartDate: doc.startDate.indexOf('1900') != -1 || doc.startDate.indexOf('1899') != -1 ? "" : new Date(doc.startDate),
                                                            EndDate: doc.endDate.indexOf('1900') != -1 || doc.endDate.indexOf('1899') != -1 ? "" : new Date(doc.endDate),
                                                            RushReq: doc.rushRequest,
                                                            FacilityId: doc.id,
                                                            isNew: doc.isNew,
                                                            Id: parseInt(doc.id),
                                                            dFacNameRO: false,
                                                            dFNRO: false,
                                                            dLNRO: false,
                                                            dEmailRO: false,
                                                            dAddrRO: false,
                                                            dCityRO: false,
                                                            dStateRO: false,
                                                            dZipRO: false,
                                                            dPhnRO: false,
                                                            dFaxRO: false,
                                                            DatesOfService: doc.dateOfServiceType,
                                                            CustomStartDate: doc.startDate.indexOf('1900') != -1 || doc.startDate.indexOf('1899') != -1 ? "" : new Date(doc.startDate),
                                                            CustomEndDate: doc.endDate.indexOf('1900') != -1 || doc.endDate.indexOf('1899') != -1 ? "" : new Date(doc.endDate),
                                                            OrderFacilityId: doc.mapId,
                                                            CustomerProvidedFacility: doc.CustomerProvidedFacility,
                                                        });
                                                    }

                                                    if (companyType == "LEGAL") {
                                                        doc.facilityRequests.map((doc, index) => {
                                                            if (doc.requestType == "Billing") {
                                                                currentComponent.setState({
                                                                    BillingRequestType: doc.requestType, BillingRushRequest: doc.rushRequest
                                                                    , BillingAffidavitRequest: doc.affidavitRequest, BillingCertifiedRequest: doc.certifiedRequest,
                                                                    BillingSplInstruct: doc.specialInstructionType, BillingCustodianInstruction: doc.specialInstruction
                                                                });
                                                            }
                                                            else if (doc.requestType == "Medical") {
                                                                currentComponent.setState({
                                                                    MedicalRequestType: doc.requestType, MedicalRushRequest: doc.rushRequest
                                                                    , MedicalAffidavitRequest: doc.affidavitRequest, MedicalCertifiedRequest: doc.certifiedRequest,
                                                                    MedicalSplInstruct: doc.specialInstructionType, MedicalCustodianInstruction: doc.specialInstruction
                                                                });
                                                            }
                                                            else if (doc.requestType == "Films") {
                                                                currentComponent.setState({
                                                                    FilmsRequestType: doc.requestType, FilmsRushRequest: doc.rushRequest,
                                                                    FilmsAffidavitRequest: doc.affidavitRequest, FilmsCertifiedRequest: doc.certifiedRequest,
                                                                    FilmsSplInstruct: doc.specialInstructionType, FilmsCustodianInstruction: doc.specialInstruction
                                                                });
                                                            }
                                                            else {
                                                                currentComponent.setState({
                                                                    OtherRequestType: doc.requestType, OtherRushRequest: doc.rushRequest,
                                                                    OtherAffidavitRequest: doc.affidavitRequest, OtherCertifiedRequest: doc.certifiedRequest,
                                                                    OtherSplInstruct: doc.specialInstructionType, OtherCustodianInstruction: doc.specialInstruction
                                                                });
                                                            }
                                                        });
                                                    }
                                                    $("#sdate").val(
                                                        doc.startDate.replace("-", "/").replace("-", "/")
                                                    );
                                                    $("#edate").val(
                                                        doc.endDate.replace("-", "/").replace("-", "/")
                                                    );
                                                }
                                            });
                                        }
                                    }
                                    currentComponent.setState({
                                        DoctorList: currentComponent.state.DocList,
                                        isUnsubmitted: isInboundOrder ? true : false,
                                    });
                                    document.getElementById("dobDate").value = currentComponent.state.DOB;

                                    //Added code for Configuration Manager
                                    if (result.carrierManager) {
                                        currentComponent.setState({
                                            CMId: result.carrierManager.id == "0" ? "" : result.carrierManager.id,
                                            CMFN: result.carrierManager.firstName,
                                            CMLN: result.carrierManager.lastName,
                                            CMEmailAddress: result.carrierManager.emailAddress,
                                            CMWorkPhone: result.carrierManager.workPhone,
                                            CMExt: result.carrierManager.ext,
                                            CMCellPhone: result.carrierManager.cellPhone,
                                            CMFax: result.carrierManager.fax,
                                        });
                                    }
                                }
                                currentComponent.setState({ Loading: false });
                            }
                        }
                    })
                    .catch(function (error) {
                        if (error == "TypeError: Failed to fetch") {
                            currentComponent.toasterredfun2(
                                "Check your internet connection."
                            );
                        } else {
                            currentComponent.toasterredfun2(error);
                        }
                        currentComponent.setState({ Loading: false });
                    });
            }
            else {
                currentComponent.setState({ Loading: false });
            }
        }
    }
    toasterredfun(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
                this.props.history.goBack();
            }.bind(this),
            5000
        );
    }
    toasterredfunback(event) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: event });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
                this.props.history.goBack();
            }.bind(this),
            5000
        );
    }
    testAlphaNumericwithSpace(e) {
        if (/^[a-zA-Z0-9\s]+$/g.test(e)) {
            if (/\s{2,}/g.test(e)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    testAlphawithSpace(e) {
        if (/^[a-zA-Z.'-\s]+$/g.test(e)) {
            if (/\s{2,}/g.test(e)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    testAlphawithSpaceforCity(e) {
        if (/^[a-zA-Z.\s'-]+$/g.test(e)) {
            if (/\s{2,}/g.test(e)) {
                return false;
            } else if (/-{2,}/g.test(e)) {
                return false;
            } else if (/'{2,}/g.test(e)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    testAlphawithSpaceForName(e) {
        if (/^[._a-zA-Z0-9&'-\s]+$/g.test(e)) {
            if (/\s{2,}/g.test(e)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    testHyperLinks(e) {
        if (/(www\.[^ ]*)/.test(e.toLowerCase()) || /(https?:\/\/[^ ]*)/.test(e.toLowerCase())) {
            return false;
        }
        else {
            return true;
        }
    }
    testAlphawithSpaceForNameAgent(e) {
        if (/^[._a-zA-Z0-9&'-/\s]+$/g.test(e)) {
            if (/\s{2,}/g.test(e)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    testAlphaForTitle(e) {
        if (/^[.a-zA-Z\s]+$/g.test(e)) {
            if (/\s{2,}/g.test(e)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    testNumericForDate(e) {
        if (/^[/0-9\s]+$/g.test(e)) {
            if (/\s{2,}/g.test(e)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    testAlphaNumericwithSpaceForEmail(e) {
        if (/^[-_.a-zA-Z0-9@.]+$/g.test(e)) {
            if (/[@]{2,}/g.test(e)) {
                return false;
            } else if (/[.]{2,}/g.test(e)) {
                return false;
            } else if (/[_]{2,}/g.test(e)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    testAlphaNumericwithSpaceForFacility(e) {
        if (/^[._&a-zA-Z0-9'\-,\s]+$/g.test(e)) {
            if (/\s{2,}/g.test(e)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    testAlphaNumeric(e) {
        if (/^[a-zA-Z0-9]+$/g.test(e)) {
            return true;
        } else {
            return false;
        }
    }
    testAlphaNumericWithDash(e) {
        if (/^[a-zA-Z0-9-]+$/g.test(e)) {
            return true;
        } else {
            return false;
        }
    }
    testNumericForPolicyAmount(e) {
        if (/^[0-9]+$/g.test(e)) {
            return true;
        } else {
            return false;
        }
    }
    testZip(e) {
        if (/^[0-9]\d{4}$/g.test(e)) {
            return true;
        } else {
            return false;
        }
    }
    testZip5(e) {
        if (/^[0-9]\d{4}-[0-9]\d{3}$/g.test(e)) {
            return true;
        } else {
            return false;
        }
    }
    testEmail(e) {
        if (/^([a-zA-Z0-9_\.'&-]+)@([a-zA-Z0-9]+)\.([a-zA-Z]{2,5})+$/.test(e)) {
            return true;
        } else {
            return false;
        }
    }
    testPhone(e) {
        if (/^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/.test(e)) {
            return true;
        } else {
            return false;
        }
    }
    testSSN(e) {
        if (/^[0-9]{3}-[0-9]{2}-[0-9]{4}$/.test(e)) {
            return true;
        } else {
            return false;
        }
    }
    testEXT(e) {
        if (/^[0-9]+$/g.test(e)) {
            if (/\s{2,}/g.test(e)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    handleDefaultOrderingOffice = (e) => {
        this.setState({
            IsDefaultOrderingOffice: e.target.checked
        });
    };
    handleOrderingOfficeChange = (e) => {
        let orderingOfficeId = 0;
        let orderingofficereq = false;
        if (!isNaN(e.target.value)) {
            orderingOfficeId = parseInt(e.target.value);
            orderingofficereq = false;
        } else {
            orderingofficereq = true;
        }
        this.setState({ OrderingOfficeId: orderingOfficeId, orderingofficereq });
        //PE-511
        var agentId = this.state.AgId;
        var agentUserId = this.state.AgentUserId;
        var agentUserRole = this.state.AgentUserRole;
        let currentComponent = this;
        if (agentUserId != null && agentUserId != "" && orderingOfficeId != 0) {
            if (agentUserRole.toLowerCase() == "agent") {
                Api.Post('api/placeorder/GetUserDetialsbyAgentId', { "AgentUserId": agentId }, currentComponent).then(function (result) {
                    if (result.statusCode === 100) {
                        var FilterAgentKeyId = result.result.orderingCarriersDd.find(s => s.id == orderingOfficeId);
                        if (!FilterAgentKeyId) {
                            if (localStorage.getItem("CompanyType") == "LEGAL") {
                                currentComponent.toasterredfun2("This Attorney User is Not mapped with the selected ordering office");
                            }
                            else {
                                currentComponent.toasterredfun2("This Agent User is Not mapped with the selected ordering office");
                            }
                        }
                    }
                    if (result.statusCode == 403) {
                        currentComponent.setState({ loading: false });
                        localStorage.removeItem('ShowAdminMenu');
                        localStorage.removeItem('UserType');
                        localStorage.removeItem('PlaceOrder');
                        localStorage.removeItem('ViewResults');
                        localStorage.removeItem('ViewAuth');
                        localStorage.removeItem('ExportSearchResults');
                        localStorage.removeItem('PerformanceMetrics');
                        localStorage.setItem('PlaceOrder', result.allowPlaceOrder);
                        localStorage.setItem('ShowAdminMenu', result.isAdmin);
                        localStorage.setItem('UserType', result.userType);
                        localStorage.setItem('ViewResults', result.downloadImages);
                        localStorage.setItem('ViewAuth', result.viewAuth);
                        localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                        localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                        currentComponent.setState({ adminAlert: true });
                    }
                });
            }
        }
    };
    handleFNChange(e) {
        this.setState({ pFirstName: false });
        if (e.target.value.length != 0) {
            if (this.testAlphawithSpaceForName(e.target.value)) {
                if (this.testHyperLinks(e.target.value)) {
                    this.setState({ FirstName: e.target.value });
                }
                else {
                    this.setState({ pFirstName: true });
                }
            }
        } else {
            this.setState({ FirstName: e.target.value });
        }
    }

    //checkEMail(result) {
    //    let currentComponent = this;
    //    var data = { Email: result.patient.email };
    //    Api.Post(
    //        "services/api/IsValidEmailMailAddress/",
    //        data,
    //        currentComponent
    //    )
    //        .then(function (result) {
    //            currentComponent.setState({ pEmail: !result });
    //        })
    //        .catch(function (error) {
    //            currentComponent.setState({ pEmail: false });
    //            if (error == "TypeError: Failed to fetch") {
    //                currentComponent.toasterredfun2("Check your internet connection.");
    //            } else {
    //                currentComponent.toasterredfun2(error);
    //            }
    //        });
    //}

    async checkAndValidateOrderDetails(result) {
        if (result.patient.title && result.patient.title.length != 0) {
            if (!this.testAlphaForTitle(result.patient.title)) {
                this.setState({ pTitle: true });
            }
        }

        if (result.patient.patientFN && result.patient.patientFN.length != 0) {
            if (!this.testAlphawithSpaceForName(result.patient.patientFN)) {
                this.setState({ pFirstName: true });
            }
        } else {
            this.setState({ pFirstName: true });
        }

        if (result.patient.patientLN != "" && result.patient.patientLN.length != 0) {
            if (!this.testAlphawithSpaceForName(result.patient.patientLN)) {
                this.setState({ pLastName: true });
            }
        } else {
            this.setState({ pLastName: true });
        }

        if (result.patient.dob) {
            this.handleDOBChange(new Date(result.patient.dob.replace("-", "/").replace("-", "/")));
        } else {
            this.setState({ pDOB: true });
        }

        if (result.patient.policy) {
            if (!this.testAlphaNumericWithDash(result.patient.policy)) {
                this.setState({ pPolicyNum: true });
            }
        }

        if (result.patient.policyAmount) {
            if (!this.testNumericForPolicyAmount(result.patient.policyAmount)) {
                this.setState({ pPolicyAmount: true });
            }
        }
        if (result.patient.email) {
            //if (!this.testAlphaNumericwithSpaceForEmail(result.patient.email)) {
            //    this.setState({ pEmail: true });
            //}
            let currentComponent = this;
            //debugger
            var placeOrder = await this.CheckForPlaceOrder();
            //debugger
            if (placeOrder)
            {
                //debugger
                var data = { Email: result.patient.email.replace(/ /g, '') };
                Api.Post(
                    "services/api/IsValidEmailMailAddress/",
                    data,
                    currentComponent
                )
                    .then(function (res) {
                        currentComponent.setState({ pEmail: !res });
                    })
                    .catch(function (error) {
                        currentComponent.setState({ pEmail: false });
                        if (error == "TypeError: Failed to fetch") {
                            currentComponent.toasterredfun2("Check your internet connection.");
                        } else {
                            currentComponent.toasterredfun2(error);
                        }
                    });
            }

        }

        result.facility.map(async(doc, index) => {
            if (doc.drEmailAddress) {
                let currentComponent = this;
                var placeOrder = await this.CheckForPlaceOrder();
                if (placeOrder)
                {
                    var data = { Email: doc.drEmailAddress.replace(/ /g, '') };
                    Api.Post(
                        "services/api/IsValidEmailMailAddress/",
                        data,
                        currentComponent
                    )
                        .then(function (res) {
                            currentComponent.setState({ pDrEmailAddress: !res });
                        })
                        .catch(function (error) {
                            currentComponent.setState({ pDrEmailAddress: false });
                            if (error == "TypeError: Failed to fetch") {
                                currentComponent.toasterredfun2("Check your internet connection.");
                            } else {
                                currentComponent.toasterredfun2(error);
                            }
                        });
                }
            }
        });

        if (result.agent.agentEmail) {
            let currentComponent = this;
            var placeOrder = await this.CheckForPlaceOrder();
            if (placeOrder)
            {
                var data = { Email: result.agent.agentEmail.replace(/ /g, '') };
                Api.Post(
                    "services/api/IsValidEmailMailAddress/",
                    data,
                    currentComponent
                )
                    .then(function (res) {
                        currentComponent.setState({ pAgentEmail: !res });
                    })
                    .catch(function (error) {
                        currentComponent.setState({ pAgentEmail: false });
                        if (error == "TypeError: Failed to fetch") {
                            currentComponent.toasterredfun2("Check your internet connection.");
                        } else {
                            currentComponent.toasterredfun2(error);
                        }
                    });
            }

        }
        if (result.carrierManager.emailAddress) {
            let currentComponent = this;
            var placeOrder = await this.CheckForPlaceOrder();
            if (placeOrder)
            {
                var data = { Email: result.carrierManager.emailAddress.replace(/ /g, '') };
                Api.Post(
                    "services/api/IsValidEmailMailAddress/",
                    data,
                    currentComponent
                )
                    .then(function (res) {
                        currentComponent.setState({ pCMEmailAddress: !res });
                    })
                    .catch(function (error) {
                        currentComponent.setState({ pCMEmailAddress: false });
                        if (error == "TypeError: Failed to fetch") {
                            currentComponent.toasterredfun2("Check your internet connection.");
                        } else {
                            currentComponent.toasterredfun2(error);
                        }
                    });
            }

        }


        if (result.patient.city) {
            if (!this.testAlphawithSpaceforCity(result.patient.city)) {
                this.setState({ pPCCity: true });
            }
        }
        else {
            this.setState({ pPCCity: true });
        }

        result.facility.map((doc, index) => {
            if (doc.drFacilityName) {
                if (!this.testAlphaNumericwithSpaceForFacility(doc.drFacilityName)) {
                    this.setState({ pFacilityName: true });
                }
            }

            if (doc.drFirstName) {
                if (!this.testAlphawithSpaceForName(doc.drFirstName)) {
                    this.setState({ pDoctorFirstName: true });
                }
            }

            if (doc.drLastName) {
                if (!this.testAlphawithSpaceForName(doc.drLastName)) {
                    this.setState({ pDoctorLastName: true });
                }
            }
        });

        if (result.agent && result.agent.agentFN) {
            if (!this.testAlphawithSpaceForNameAgent(result.agent.agentFN)) {
                this.setState({ pAgentFN: true });
            }
        }

        if (result.agent && result.agent.agentLN) {
            if (!this.testAlphawithSpaceForNameAgent(result.agent.agentLN)) {
                this.setState({ pAgentLN: true });
            }
        }
    }

    checkSSN() {
        let currentComp = this;
        if (
            currentComp.state.FirstName.length != 0 &&
            currentComp.state.LastName.length != 0 &&
            currentComp.state.SSN.length != 0 &&
            currentComp.state.DOB instanceof Date &&
            !currentComp.state.isOrderAdditional
        ) {          
            let currentComponent = this;
            currentComponent.setState({ Loading: true });

            Api.Post(
                "api/placeorder/checkUserNameWithSsn/",
                {
                    lastName: currentComp.state.LastName,
                    firstName: currentComp.state.FirstName,
                    ssn: currentComp.state.SSN,
                    dob: currentComp.state.DOB,
                },
                currentComponent
            )
                .then(function (result) {
                    if (result.statusCode === 100) {
                        currentComponent.setState({ ssnChecked: true });
                    } else {
                        currentComponent.setState({ pSSN: true, ssnChecked: false });
                        currentComponent.toasterredfun2(result.statusMessage);
                    }
                })
                .catch(function (error) {
                    currentComponent.setState({ pSSN: true, ssnChecked: false });
                    currentComponent.setState({ Loading: false });
                    if (error == "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun2("Check your internet connection.");
                    } else {
                        currentComponent.toasterredfun2(error);
                    }
                });

            //fetch('api/PlaceOrder/checkUserNameWithSsn/', {
            //    method: 'post',
            //    headers: {
            //        Accept: 'application/json',
            //        'Content-Type': 'application/json',
            //        "Authorization": "Bearer " + localStorage.getItem("UserId")
            //    },
            //    body: JSON.stringify({ lastName: currentComp.state.LastName, firstName: currentComp.state.FirstName, ssn: currentComp.state.SSN, dob: currentComp.state.DOB })
            //}).then(function (response) {
            //    if (response.status == 401) {
            //        currentComponent.props.history.push('/');
            //    }
            //    else { return response.json(); }
            //}).then(function (result) {
            //    if (result.statusCode === 100) {
            //        currentComponent.setState({ ssnChecked: true });
            //    } else {
            //        currentComponent.setState({ pSSN: true, ssnChecked: false });
            //        currentComponent.toasterredfun2(result.statusMessage);
            //    }

            //}).catch(function (error) {
            //    currentComponent.setState({ pSSN: true, ssnChecked: false });
            //    currentComponent.setState({ Loading: false });
            //    if (error == "TypeError: Failed to fetch") {
            //        currentComponent.toasterredfun2("Check your internet connection.");
            //    }
            //    else {
            //        currentComponent.toasterredfun2(error);
            //    }
            //});
        }
    }
    handleFNBlur(e) {
        this.setState({ ssnChecked: true });
    }
    handleSSNfocusOut(e)
    {
        this.setState({ ssnChecked: true, ShowSSNValue: false, SSNEyeHide: false});
    }
    handleSSNMouseLeave = (event) =>
    {
        this.setState({ ShowSSNValue: false });
        $('#eye-ssn').toggleClass('fa fa-eye-slash');
    }
    handleSSNMouseLeaveLegal = (event) => {
        this.setState({ ShowSSNValue: false });
        $('#eye-ssn-legal').toggleClass('fa fa-eye-slash');
    }
    handleDOBMouseLeave = (event) => {
        this.setState({ MaskDOB: true });
        $('#eye-dob').toggleClass('fa fa-eye-slash');
    }
    handleDOBMouseLeaveLegal = (event) => {
        this.setState({ MaskDOB: true });
        $('#eye-dob-legal').toggleClass('fa fa-eye-slash');
    }
    handleSSNfocusIn(e) {
        this.setState({ ssnChecked: true, ShowSSNValue: true, ShowSSNBFocus:true, SSNEyeHide:true });

    }
    handleDOBfocusOut(e) {
        if (e.target.value == "") {
            this.setState({ pDOB: false});
        }
        if (!e.currentTarget.contains(e.relatedTarget)) {
            this.setState({ MaskDOB: true, DOBEyeHide:false });
            if (this.state.pDOB||this.state.DOB.toString().length<=0) {
                this.setState({ DOBEyeHide: true });
            } 
        }
    }

    setMaskDOB() {
        this.setState({ MaskDOB: false, DOBEyeHide: true });
    }
    async handleDOBfocusIn(e) {
        await this.setMaskDOB();
        //currentComponent.setState({ MaskDOB: false, DOBEyeHide: true }, () => { currentComponent.textInput.current.focus(); });
        document.getElementById('dobDate').focus();
    }

    handleFaNameChange(e) {
        this.setState({ pFacilityName: false });
        this.setState({ FacilityName: e[0].label });
    }
    handlePopFaNameChange(e) {
        if (e != null) {
            this.setState(
                {
                    SelectedDoc: e,
                    PopFacilityName: e.label,
                    PopFacilityId: e.value,
                },
                () => {
                    // This will output an array of objects
                    // given by Autocompelte options property.
                }
            );
        }
        //this.setState({ dopopfacilityname: false });
        //this.setState({ PopFacilityName: e[0].label });
        //this.setState({ PopFacilityId: e[0].value });
    }
    handlePopAgentNameChange(e) {
        if (e != null) {
            this.setState(
                {
                    SelectedAgent: e,
                    PopAgentName: e.label,
                    PopAgentId: e.value,
                },
                () => {
                    // This will output an array of objects
                    // given by Autocompelte options property.
                }
            );
        }
        //this.setState({ dopopfacilityname: false });
        //this.setState({ PopFacilityName: e[0].label });
        //this.setState({ PopFacilityId: e[0].value });
    }
    handlePopCarrierManagerNameChange(e) {
        if (e != null) {
            this.setState(
                {
                    SelectedCarrierManager: e,
                    PopCarrierManagerName: e.label,
                    PopCarrierManagerId: e.value,
                },
                () => {
                    // This will output an array of objects
                    // given by Autocompelte options property.
                }
            );
        }

    }
    handlePopAdjusterNameChange(e) {
        if (e != null) {
            this.setState(
                {
                    SelectedAdjuster: e,
                    PopAdjusterName: e.label,
                    PopAdjusterId: e.value,
                },
                () => {
                    // This will output an array of objects
                    // given by Autocompelte options property.
                }
            );
        }
    }
    handleFaclitityAdd(e) {

        this.setState({ addvalid: true });
        if (
            this.state.PopFacilityName == null ||
            this.state.PopFacilityName.length != 0
        ) {
            this.state.Facilities.map((doc, index) => {
                if (doc.id == parseInt(this.state.PopFacilityId)) {
                    this.setState({
                        dFacNameRO: false,
                        dFNRO: false,
                        dLNRO: false,
                        dEmailRO: false,
                        dAddrRO: false,
                        dCityRO: false,
                        dStateRO: false,
                        dZipRO: false,
                        dPhnRO: false,
                        dFaxRO: false,
                    });
                    this.setState({
                        DrEmailAddress: doc.drEmailAddress ? doc.drEmailAddress : "",
                        DrAddress: doc.drAddress ? doc.drAddress : "",
                        DoctorCity: doc.drCity ? doc.drCity : "",
                        DrFax: doc.drFax ? doc.drFax : "",
                        DoctorFirstName: doc.drFirstName ? doc.drFirstName : "",
                        DoctorLastName: doc.drLastName ? doc.drLastName : "",
                        Phone: doc.drPhone ? doc.drPhone : "",
                        DrState: doc.drState ? doc.drState : "",
                        //                        DrStateId: doc.drStateId ? doc.drStateId.toString() : "",
                        DrStateId: doc.drStateId ? doc.drStateId.toString() : "",
                        DrZipCode: doc.drZipCode ? doc.drZipCode : "",
                        FacilityName: doc.drFacilityName ? doc.drFacilityName : "",
                        isNew: false,
                        FacilityId: doc.id ? doc.id : 0,
                    });
                    this.setState({
                        Facilities: this.state.InitialDocList,
                        Options: this.state.InitialDocValues,
                        SelectedDoc: "",
                        PopFacilityName: "",
                        PopFacilityId: "",
                        pDrAddress: false,
                        pDrEmailAddress: false,
                        pDoctorCity: false,
                        pDrStateId: false,
                        pDrZipCode: false,
                        pPhone: false,
                        pDrFax: false,
                        pFacilityName: false,
                        pDoctorFirstName: false,
                        pDoctorLastName: false,
                    });
                    //document.getElementsByClassName("popfacility")[0].setAttribute('tabindex', 0);
                    this.dPhnChange(doc.drPhone ? doc.drPhone : "", 0);
                    this.handleDFaxChange(doc.drFax ? doc.drFax : "", 0);
                    this.handleDZipChange(doc.drZipCode ? doc.drZipCode : "", 0)
                }
            });
        }
        this.setState({ isModalopen: false });
    }
    handleAgentAdd(e) {
        //debugger
        if (
            this.state.PopAgentName == null ||
            this.state.PopAgentName.length != 0
        ) {
            this.state.Agents.map((doc, index) => {
                if (doc.id == parseInt(this.state.PopAgentId)) {
                    this.setState({ IsAgentId: doc.id });
                    //this.setState({   
                    //    aemailRO: true,
                    //     afnRO: true,
                    //     alnRO: true,
                    //     aphnRO: true,
                    //     amobileRO: true,
                    //     aextRO: true,
                    //     afaxRO: true,
                    //     aIdRO: true,
                    // });
                    //this.setState({
                    //    AgentEmail: doc.agentEmail,
                    //    AgentFN: doc.agentFN,
                    //    AgentLN: doc.agentLN,
                    //    AgentMobile: doc.agentCell,
                    //    AgentPhone: doc.agentPhone,
                    //    AgentExt: doc.agentExt,
                    //    AgentFax: doc.agentFax,
                    //    AgentId: doc.agentId,
                    //    AgId: doc.id,
                    //    AgentUserId: doc.userId,
                    //});
                    this.state.IsAgentId = doc.id;
                    this.state.AgentEmail = doc.agentEmail;
                    this.state.AgentFN = doc.agentFN;
                    this.state.AgentLN = doc.agentLN;
                    this.state.AgentMobile = doc.agentCell;
                    this.state.AgentPhone = doc.agentPhone;
                    this.state.AgentExt = doc.agentExt;
                    this.state.AgentFax = doc.agentFax;
                    this.state.AgentId = doc.agentId;
                    this.state.AgId = doc.id;
                    this.state.AgentUserId = doc.userId;
                    this.state.AgentUserRole = doc.userRole;
                    //this.setState({
                    //    PopAgentName: "",
                    //    PopAgentId: "",
                    //    Agents: this.state.InitialAgentList,
                    //    AgentOptions: this.state.InitialAgentValues,
                    //    SelectedAgent: "",
                    //});
                    this.state.PopAgentName = "";
                    this.state.PopAgentId = "";
                    this.state.Agents = this.state.InitialAgentList;
                    this.state.AgentOptions = this.state.InitialAgentValues;
                    this.state.SelectedAgent = "";
                    document
                        .getElementsByClassName("popAgent")[0]
                        .setAttribute("tabIndex", 0);
                    this.setState({
                        pAgentFN: this.state.isMandatoryAgentFN && doc.agentFN == "" ? true : false,
                        pAgentLN: this.state.isMandatoryAgentLN && doc.agentLN == "" ? true : false,
                        pAgentEmail: this.state.isMandatoryAgentEmail && doc.agentEmail == "" ? true : false,
                        pAgentPhone: this.state.isMandatoryAgentPhone && doc.agentCell == "" ? true : false,
                        pAgentExt: this.state.isMandatoryAgentExt && doc.agentExt == "" ? true : false,
                        pAgId: this.state.isMandatoryAgentId && doc.id == "" ? true : false,
                        pAgentMobile: this.state.isMandatoryAgentMobile && doc.agentCell == "" ? true : false,
                        pAgentFax: this.state.isMandatoryAgentFax && doc.agentFax == "" ? true : false
                    });
                }
            });
        }

        var agentId = this.state.AgId;
        var agentUserId = this.state.AgentUserId;
        var agentUserRole = this.state.AgentUserRole;
        let orderingOfficeId = this.state.OrderingOfficeId;
        let currentComponent = this;
        if (agentUserId != null && agentUserId != "" && orderingOfficeId != 0) {
            if (agentUserRole.toLowerCase() == "agent") {
                Api.Post('api/placeorder/GetUserDetialsbyAgentId', { "AgentUserId": agentId }, currentComponent).then(function (result) {
                    if (result.statusCode === 100) {
                        var FilterAgentKeyId = result.result.orderingCarriersDd.find(s => s.id == orderingOfficeId);
                        if (!FilterAgentKeyId) {
                            if (localStorage.getItem("CompanyType") == "LEGAL") {
                                currentComponent.toasterredfun2("This Attorney User is Not mapped with the selected ordering office");
                            }
                            else {
                                currentComponent.toasterredfun2("This Agent User is Not mapped with the selected ordering office");
                            }
                        }
                    }
                    if (result.statusCode == 403) {
                        currentComponent.setState({ loading: false });
                        localStorage.removeItem('ShowAdminMenu');
                        localStorage.removeItem('UserType');
                        localStorage.removeItem('PlaceOrder');
                        localStorage.removeItem('ViewResults');
                        localStorage.removeItem('ViewAuth');
                        localStorage.removeItem('ExportSearchResults');
                        localStorage.removeItem('PerformanceMetrics');
                        localStorage.setItem('PlaceOrder', result.allowPlaceOrder);
                        localStorage.setItem('ShowAdminMenu', result.isAdmin);
                        localStorage.setItem('UserType', result.userType);
                        localStorage.setItem('ViewResults', result.downloadImages);
                        localStorage.setItem('ViewAuth', result.viewAuth);
                        localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                        localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                        currentComponent.setState({ adminAlert: true });
                    }
                });
            }
        }
        this.setState({ isModalAgentopen: false });
    }

    handleCarrierManagerAdd(e) {
        if (
            this.state.PopCarrierManagerName == null ||
            this.state.PopCarrierManagerName.length != 0
        ) {
            this.state.CarrierManagers.map((doc, index) => {
                if (doc.id == parseInt(this.state.PopCarrierManagerId)) {
                    this.setState({ CMId: doc.id });
                    this.setState({
                        CMFNRo: true,
                        CMLNRo: true,
                        CMEmailAddressRo: true,
                        CMWorkPhoneRo: true,
                        CMExtRo: true,
                        CMCellPhoneRo: true,
                        CMFaxRo: true,
                    });
                    this.setState({

                        CMFN: doc.firstName,
                        CMLN: doc.lastName,
                        CMEmailAddress: doc.emailAddress,
                        CMWorkPhone: doc.workPhone,
                        CMExt: doc.ext,
                        CMCellPhone: doc.cellPhone,
                        CMFax: doc.fax,
                    });
                    this.setState({
                        pCMFN: this.state.isMandatoryCMFN && doc.firstName == "" ? true : false,
                        pCMLN: this.state.isMandatoryCMLN && doc.lastName == "" ? true : false,
                        pCMEmailAddress: this.state.isMandatoryCMEmailAddress && doc.emailAddress == "" ? true : false,
                        pCMWorkPhone: this.state.isMandatoryCMWorkPhone && doc.workPhone == "" ? true : false,
                        pCMExt: this.state.isMandatoryCMExt && doc.ext == "" ? true : false,
                        pCMCellPhone: this.state.isMandatoryCMCellPhone && doc.cellPhone == "" ? true : false,
                        pCMFax: this.state.isMandatoryCMFax && doc.fax == "" ? true : false
                    });
                    this.setState({
                        PopCarrierManagerName: "",
                        PopCarrierManagerId: "",
                        CarrierManagers: this.state.InitialCarrierManagerList,
                        CarrierManagerOptions: this.state.InitialCarrierManagerValues,
                        SelectedCarrierManager: "",
                    });
                    document
                        .getElementsByClassName("popCarrierManager")[0]
                        .setAttribute("tabIndex", 0);
                }
            });
        }
        this.setState({ isModalCMtopen: false });
    }
    handleAdjusterAdd(e) {
        if (
            this.state.PopAdjusterName == null ||
            this.state.PopAdjusterName.length != 0
        ) {
            this.state.Adjusters.map((doc, index) => {
                if (doc.id == parseInt(this.state.PopAdjusterId)) {
                    this.setState({ IsAdjusterId: doc.id });
                    //this.setState({
                    //    ademailRO: true,
                    //    adfnRO: true,
                    //    adlnRO: true,
                    //    adphnRO: true,
                    //    admobileRO: true,
                    //    adextRO: true,
                    //    adfaxRO: true,
                    //    adIdRO: true,
                    //});
                    this.setState({
                        AdjusterEmail: doc.agentEmail,
                        AdjusterFN: doc.agentFN,
                        AdjusterLN: doc.agentLN,
                        AdjusterMobile: doc.agfentCell,
                        AdjusterPhone: doc.agentPhone,
                        AdjusterExt: doc.agentExt,
                        AdjusterFax: doc.agentFax,
                        AdjusterId: doc.agentId,
                        AdId: doc.id,
                    });
                    this.setState({
                        pAdjusterFN: this.state.isMandatoryAdjusterFN && doc.agentFN == "" ? true : false,
                        pAdjusterLN: this.state.isMandatoryAdjusterLN && doc.agentLN == "" ? true : false,
                        pAdjusterEmail: this.state.isMandatoryAdjusterEmail && doc.agentEmail == "" ? true : false,
                        pAdjusterPhone: this.state.isMandatoryAdjusterPhone && doc.agentPhone == "" ? true : false,
                        pAdjusterExt: this.state.isMandatoryAdjusterExt && doc.agentExt == "" ? true : false,
                        pIsAdjusterId: this.state.isMandatoryAdjusterId && doc.agentId == "" ? true : false,
                        pAdjusterMobile: this.state.isMandatoryAdjusterMobile && doc.agfentCell == "" ? true : false,
                        pAdjusterFax: this.state.isMandatoryAdjusterFax && doc.agentFax == "" ? true : false
                    });
                    this.setState({
                        PopAdjusterName: "",
                        PopAdjusterId: "",
                        Adjusters: this.state.InitialAdjusterList,
                        AdjusterOptions: this.state.InitialAdjusterValues,
                        SelectedAdjuster: "",
                    });
                    document
                        .getElementsByClassName("popAdjuster")[0]
                        .setAttribute("tabIndex", 0);
                }
            });
        }
        this.setState({ isModalAdjusteropen: false });
    }
    handleLNChange(e) {
        this.setState({ pLastName: false });
        if (e.target.value.length != 0) {
            if (this.testAlphawithSpaceForName(e.target.value)) {
                if (this.testHyperLinks(e.target.value)) {
                    this.setState({ LastName: e.target.value });
                }
                else {
                    this.setState({ pLastName: true });
                }
            }
        } else {
            this.setState({ LastName: e.target.value });
        }
    }

    async handleSSNChange(e) {
        await this.ssnChange(e.target.value, e.target.selectionStart);
        this.setState({ MaskValue: this.state.SSN.length > 0 ? 'xxx-xxx-' + this.state.SSN.substr(this.state.SSN.length - 4) : this.state.SSN });
    }
    ssnChange = (ssnValue, curStart) => {
        this.setState({ pSSN: false, ssnChecked: false });
        var ssn = ssnValue.replace(/[^0-9]+/g, "");
        if (ssn.length == 0) {
            this.setState({ SSN: "" });
        }
        if (/[0-9]$/.test(ssn)) {
            let ssnArray = [...ssn];
            if (ssn.length == 1) {
                this.setState(
                    { SSN: ssnArray[0] },
                    () =>
                        (this.refs.ssn.selectionStart = this.refs.ssn.selectionEnd = curStart)
                );
            }
            if (ssn.length == 2) {
                this.setState(
                    { SSN: ssnArray[0] + ssnArray[1] },
                    () =>
                        (this.refs.ssn.selectionStart = this.refs.ssn.selectionEnd = curStart)
                );
            } else if (ssn.length == 3) {
                this.setState(
                    { SSN: ssnArray[0] + ssnArray[1] + ssnArray[2] },
                    () =>
                        (this.refs.ssn.selectionStart = this.refs.ssn.selectionEnd = curStart)
                );
            } else if (ssn.length == 4) {
                this.setState(
                    { SSN: ssnArray[0] + ssnArray[1] + ssnArray[2] + "-" + ssnArray[3] },
                    () =>
                    (this.refs.ssn.selectionStart = this.refs.ssn.selectionEnd =
                        curStart + 1)
                );
            } else if (ssn.length == 5) {
                this.setState(
                    {
                        SSN:
                            ssnArray[0] +
                            ssnArray[1] +
                            ssnArray[2] +
                            "-" +
                            ssnArray[3] +
                            ssnArray[4],
                    },
                    () =>
                        (this.refs.ssn.selectionStart = this.refs.ssn.selectionEnd = curStart)
                );
            } else if (ssn.length == 6) {
                this.setState(
                    {
                        SSN:
                            ssnArray[0] +
                            ssnArray[1] +
                            ssnArray[2] +
                            "-" +
                            ssnArray[3] +
                            ssnArray[4] +
                            "-" +
                            ssnArray[5],
                    },
                    () =>
                    (this.refs.ssn.selectionStart = this.refs.ssn.selectionEnd =
                        curStart + 1)
                );
            } else if (ssn.length == 7) {
                this.setState(
                    {
                        SSN:
                            ssnArray[0] +
                            ssnArray[1] +
                            ssnArray[2] +
                            "-" +
                            ssnArray[3] +
                            ssnArray[4] +
                            "-" +
                            ssnArray[5] +
                            ssnArray[6],
                    },
                    () =>
                        (this.refs.ssn.selectionStart = this.refs.ssn.selectionEnd = curStart)
                );
            } else if (ssn.length == 8) {
                this.setState(
                    {
                        SSN:
                            ssnArray[0] +
                            ssnArray[1] +
                            ssnArray[2] +
                            "-" +
                            ssnArray[3] +
                            ssnArray[4] +
                            "-" +
                            ssnArray[5] +
                            ssnArray[6] +
                            ssnArray[7],
                    },
                    () =>
                        (this.refs.ssn.selectionStart = this.refs.ssn.selectionEnd = curStart)
                );
            } else if (ssn.length == 9) {
                this.setState(
                    {
                        SSN:
                            ssnArray[0] +
                            ssnArray[1] +
                            ssnArray[2] +
                            "-" +
                            ssnArray[3] +
                            ssnArray[4] +
                            "-" +
                            ssnArray[5] +
                            ssnArray[6] +
                            ssnArray[7] +
                            ssnArray[8],
                    },
                    () =>
                        (this.refs.ssn.selectionStart = this.refs.ssn.selectionEnd = curStart)
                );
            }
        }
    }

    handleDOBChange(e) {
        this.setState({ pDOB: false });
        if (e instanceof Date) {

            var tdate = e;
            var date = new Date(tdate);
            var minDateYear = this.state.minDate.getFullYear();
            var maxDateYear = this.state.maxDate.getFullYear();
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();
            today = mm + "/" + dd + "/" + yyyy;
            var Selecteddd = String(date.getDate()).padStart(2, "0");
            var Selectedmm = String(date.getMonth() + 1).padStart(2, "0");
            var Selectedyyyy = date.getFullYear();
            var SelectedDate = Selectedmm + "/" + Selecteddd + "/" + Selectedyyyy;
            if (tdate > this.state.maxDate || tdate < this.state.minDate) {                
                this.setState({ pDOB: true });
                document.getElementById("dobDate").value = "";
                this.state.DOB = "";
            } else {
                this.setState({ DOB: e });
                this.setState({ MaskDOB: true, DOBEyeHide: false });
                if (this.state.DateOfIncident.length != 0 && new Date(this.state.DateOfIncident) instanceof Date && this.state.CustomStartDate.length != 0 && new Date(this.state.CustomStartDate) instanceof Date) {
                    if (new Date(this.state.DateOfIncident) < e && new Date(this.state.CustomStartDate) < e) {
                        this.toasterredfun2("Date of Incident and Date of Service is less than DOB.");
                        this.setState({ pDateOfIncident: true, csd: true });
                    } else if (new Date(this.state.DateOfIncident) < e) {
                        this.toasterredfun2("Date of Incident is less than DOB.");
                        this.setState({ pDateOfIncident: true });
                    }
                    else if (new Date(this.state.CustomStartDate) < e) {
                        this.toasterredfun2("Date of Service Start Date is less than DOB.");
                        this.setState({ csd: true });

                    }
                    else {
                        this.setState({
                            pDateOfIncident: false, csd: false
                        });
                    }
                } else if (this.state.DateOfIncident.length != 0 && new Date(this.state.DateOfIncident) instanceof Date) {
                    if (new Date(this.state.DateOfIncident) < e) {
                        this.toasterredfun2("Date of Incident is less than DOB.");
                        this.setState({ pDateOfIncident: true });
                    }
                    else {
                        this.setState({
                            pDateOfIncident: false
                        });
                    }
                }
                else if (this.state.CustomStartDate.length != 0 && new Date(this.state.CustomStartDate) instanceof Date) {
                    if (new Date(this.state.CustomStartDate) < e) {
                        this.toasterredfun2("Date of Service Start Date is less than DOB.");
                        this.setState({ csd: true });

                    }
                    else {
                        this.setState({
                            csd: false
                        });
                    }
                }

                if (this.state.StartDate.length != 0 && new Date(this.state.StartDate) instanceof Date && new Date(this.state.StartDate) < e) {
                    this.toasterredfun2("Special Instruction Custom Start Date is less than DOB.");
                    this.setState({ dosd: true });
                }
            }
        } else {
            this.setState({ pDOB: true, DOBEyeHide: true });
            this.setState({ DOB: e });
        }
    }
    handleDOBBlur(e) {
        var dob = document.getElementById("dobDate").value;
        if (this.state.DOB == "Invalid date") {
            this.setState({ pDOB: true });
            document.getElementById("dobDate").value = "";
            this.setState({ DOB: "" });
        }
    }
    onKeyPressValue(event) {
        var length = event.target.value.length;
        if (length == 1) {
            var n = event.target.value.includes("/");
            if (n) {
                this.setState({ DOB: "" });
            }
        }
        if (length == 2) {
            var n = event.target.value.includes("/");
            if (n) {
                event.target.value = "0" + event.target.value;
            }
        }
        if (length == 5) {

            var n = event.target.value.slice(3, 5).includes("/");
            if (n) {
                event.target.value = [event.target.value.slice(0, 3), "0", event.target.value.slice(3)].join();
            }
        }
        event.target.value = event.target.value
            .replace(/^(\d\d)(\d)$/g, "$1/$2")
            .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
            .replace(/[^\d\/]/g, "");
        if (length > 10) {
            event.stopPropagation();
        }
    }
    onKeyPressValueEndDate(event) {
        var length = event.target.value.length;
        if (length == 1) {
            var n = event.target.value.includes("/");
            if (n) {
                this.setState({ CustomEndDate: "" });
            }
        }
        if (length == 2) {
            var n = event.target.value.includes("/");
            if (n) {
                event.target.value = "0" + event.target.value;
            }
        }
        if (length == 5) {

            var n = event.target.value.slice(3, 5).includes("/");
            if (n) {
                event.target.value = [event.target.value.slice(0, 3), "0", event.target.value.slice(3)].join();
            }
        }
        event.target.value = event.target.value
            .replace(/^(\d\d)(\d)$/g, "$1/$2")
            .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
            .replace(/[^\d\/]/g, "");
        if (length > 10) {
            event.stopPropagation();
        }
    }
    onKeyPressValueStartDate(event) {
        var length = event.target.value.length;
        if (length == 1) {
            var n = event.target.value.includes("/");
            if (n) {
                this.setState({ CustomStartDate: "" });
            }
        }
        if (length == 2) {
            var n = event.target.value.includes("/");
            if (n) {
                event.target.value = "0" + event.target.value;
            }
        }
        if (length == 5) {

            var n = event.target.value.slice(3, 5).includes("/");
            if (n) {
                event.target.value = [event.target.value.slice(0, 3), "0", event.target.value.slice(3)].join();
            }
        }
        event.target.value = event.target.value
            .replace(/^(\d\d)(\d)$/g, "$1/$2")
            .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
            .replace(/[^\d\/]/g, "");
        if (length > 10) {
            event.stopPropagation();
        }
    }

    handlepSSNChange(e) {
        this.PatientssnChange(e.target.value, e.target.selectionStart);
    }

    PatientssnChange = (pssnValue, curStart) => {
        this.setState({ pSSN: false, ssnChecked: false });
        var pssn = pssnValue.replace(/[^0-9]+/g, "");
        if (pssn.length == 0) {
            this.setState({ SSN: "" });
        }
        if (/[0-9]$/.test(pssn)) {
            let pssnArray = [...pssn];
            if (pssn.length == 1) {
                this.setState(
                    { SSN: pssnArray[0] },
                    () =>
                        (this.refs.pssn.selectionStart = this.refs.pssn.selectionEnd = curStart)
                );
            }
            if (pssn.length == 2) {
                this.setState(
                    { SSN: pssnArray[0] + pssnArray[1] },
                    () =>
                        (this.refs.pssn.selectionStart = this.refs.pssn.selectionEnd = curStart)
                );
            } else if (pssn.length == 3) {
                this.setState(
                    { SSN: pssnArray[0] + pssnArray[1] + pssnArray[2] },
                    () =>
                        (this.refs.pssn.selectionStart = this.refs.pssn.selectionEnd = curStart)
                );
            } else if (pssn.length == 4) {
                this.setState(
                    { SSN: pssnArray[0] + pssnArray[1] + pssnArray[2] + "-" + pssnArray[3] },
                    () =>
                    (this.refs.pssn.selectionStart = this.refs.pssn.selectionEnd =
                        curStart + 1)
                );
            } else if (pssn.length == 5) {
                this.setState(
                    {
                        SSN:
                            pssnArray[0] +
                            pssnArray[1] +
                            pssnArray[2] +
                            "-" +
                            pssnArray[3] +
                            pssnArray[4],
                    },
                    () =>
                        (this.refs.pssn.selectionStart = this.refs.pssn.selectionEnd = curStart)
                );
            } else if (pssn.length == 6) {
                this.setState(
                    {
                        SSN:
                            pssnArray[0] +
                            pssnArray[1] +
                            pssnArray[2] +
                            "-" +
                            pssnArray[3] +
                            pssnArray[4] +
                            "-" +
                            pssnArray[5],
                    },
                    () =>
                    (this.refs.pssn.selectionStart = this.refs.pssn.selectionEnd =
                        curStart + 1)
                );
            } else if (pssn.length == 7) {
                this.setState(
                    {
                        SSN:
                            pssnArray[0] +
                            pssnArray[1] +
                            pssnArray[2] +
                            "-" +
                            pssnArray[3] +
                            pssnArray[4] +
                            "-" +
                            pssnArray[5] +
                            pssnArray[6],
                    },
                    () =>
                        (this.refs.pssn.selectionStart = this.refs.pssn.selectionEnd = curStart)
                );
            } else if (pssn.length == 8) {
                this.setState(
                    {
                        SSN:
                            pssnArray[0] +
                            pssnArray[1] +
                            pssnArray[2] +
                            "-" +
                            pssnArray[3] +
                            pssnArray[4] +
                            "-" +
                            pssnArray[5] +
                            pssnArray[6] +
                            pssnArray[7],
                    },
                    () =>
                        (this.refs.pssn.selectionStart = this.refs.pssn.selectionEnd = curStart)
                );
            } else if (pssn.length == 9) {
                this.setState(
                    {
                        SSN:
                            pssnArray[0] +
                            pssnArray[1] +
                            pssnArray[2] +
                            "-" +
                            pssnArray[3] +
                            pssnArray[4] +
                            "-" +
                            pssnArray[5] +
                            pssnArray[6] +
                            pssnArray[7] +
                            pssnArray[8],
                    },
                    () =>
                        (this.refs.pssn.selectionStart = this.refs.pssn.selectionEnd = curStart)
                );
            }
        }
    }

    onKeyPressValueDateOfIncident(event) {
        var length = event.target.value.length;
        if (length == 1) {
            var n = event.target.value.includes("/");
            if (n) {
                this.setState({ DateOfIncident: "" });
            }
        }
        if (length == 2) {
            var n = event.target.value.includes("/");
            if (n) {
                event.target.value = "0" + event.target.value;
            }
        }
        if (length == 5) {

            var n = event.target.value.slice(3, 5).includes("/");
            if (n) {
                event.target.value = [event.target.value.slice(0, 3), "0", event.target.value.slice(3)].join();
            }
        }
        event.target.value = event.target.value
            .replace(/^(\d\d)(\d)$/g, "$1/$2")
            .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
            .replace(/[^\d\/]/g, "");
        if (length > 10) {
            event.stopPropagation();
        }
    }
    handleGenderChange(e) {
        this.setState({ pGender: false });
        this.setState({ Gender: e.target.value });
    }
    handleTitleChange(e) {
        this.setState({ pTitle: false });
        if (e.target.value.length != 0) {
            if (this.testAlphaForTitle(e.target.value)) {
                if (this.testHyperLinks(e.target.value)) {
                    this.setState({ Title: e.target.value });
                }
                else {
                    this.setState({ pTitle: true });
                }
            }
        } else {
            this.setState({ Title: e.target.value });
        }
    }
    handleONChange(e) {
        this.setState({ pOtherNames: false });
        if (e.target.value.length != 0) {
            if (this.testAlphawithSpaceForName(e.target.value)) {
                if (this.testHyperLinks(e.target.value)) {
                    this.setState({ OtherNames: e.target.value });
                }
                else {
                    this.setState({ pOtherNames: true });
                }
            }
        } else {
            this.setState({ OtherNames: e.target.value });
        }
    }
    handlePCChange(e) {
        this.setState({ pPreferredContact: false });
        if (this.testHyperLinks(e.target.value)) {
            this.setState({ PreferredContact: e.target.value });
        }
        else {
            this.setState({ pPreferredContact: true });
        }
    }
    handlePNChange(e) {
        this.setState({ pPolicyNum: false });
        if (e.target.value.length != 0) {
            if (this.testAlphaNumericWithDash(e.target.value)) {
                if (this.testHyperLinks(e.target.value)) {
                    this.setState({ PolicyNum: e.target.value });
                }
                else {
                    this.setState({ pPolicyNum: true });
                }
            }
        } else {
            this.setState({ PolicyNum: e.target.value });
        }
    }
    handlePAChange(e) {
        this.setState({ pPolicyAmount: false });
        if (e.target.value.length != 0) {
            if (this.testNumericForPolicyAmount(e.target.value)) {
                this.setState({ PolicyAmount: e.target.value });
            }
        } else {
            this.setState({ PolicyAmount: e.target.value });
        }
    }
    handlePAChange1(pamount) {
        if (pamount.length != 0) {
            if (!this.testNumericForPolicyAmount(pamount)) {
                this.setState({ pPolicyAmount: true });
            }
        }
    }
    handleOtherField1(e) {
        this.setState({ pOtherField1: false });
        if (this.testHyperLinks(e.target.value)) {
            this.setState({ OtherField1: e.target.value });
        }
        else {
            this.setState({ pOtherField1: true });
        }
    }
    handleOtherField2(e) {
        this.setState({ pOtherField2: false });
        if (this.testHyperLinks(e.target.value)) {
            this.setState({ OtherField2: e.target.value });
        }
        else {
            this.setState({ pOtherField2: true });
        }
    }
    handleOtherField3(e) {
        this.setState({ pOtherField3: false });
        if (this.testHyperLinks(e.target.value)) {
            this.setState({ OtherField3: e.target.value });
        }
        else {
            this.setState({ pOtherField3: true });
        }
    }
    handleOtherField4(e) {
        this.setState({ pOtherField4: false });
        if (this.testHyperLinks(e.target.value)) {
            this.setState({ OtherField4: e.target.value });
        }
        else {
            this.setState({ pOtherField4: true });
        }
    }
    handleDOIChange(e) {
        this.setState({ pDateOfIncident: false });
        if (e instanceof Date) {
            var tdate = e;
            var date = new Date(tdate);
            var minDateYear = this.state.minDate.getFullYear();
            var maxDateYear = this.state.maxDate.getFullYear();
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();
            today = mm + "/" + dd + "/" + yyyy;
            var Selecteddd = String(date.getDate()).padStart(2, "0");
            var Selectedmm = String(date.getMonth() + 1).padStart(2, "0");
            var Selectedyyyy = date.getFullYear();
            var SelectedDate = Selectedmm + "/" + Selecteddd + "/" + Selectedyyyy;
            if (tdate > this.state.maxDate || tdate < this.state.minDate) {
                this.setState({ pDateOfIncident: true });
                document.getElementById("doiDate").value = "";
                this.setState({ DateOfIncident: "" });

            } else {
                this.setState({ DateOfIncident: SelectedDate });
                this.setState({ pDateOfIncident: false });
                this.setState({ pDateOfIncident: false });
                if (this.state.DOB instanceof Date) {
                    if (this.state.DOB > e) {
                        this.toasterredfun2("Date of Incident is less than DOB.");
                        this.setState({ pDateOfIncident: true });

                    }
                }
            }
        } else {
            this.setState({ pDateOfIncident: true });
            this.setState({ DateOfIncident: e });
        }
    }
    handleDOIBlur(e) {
        var dob = document.getElementById("doiDate").value;
        if (this.state.DateOfIncident == "Invalid date") {
            this.setState({ pDateOfIncident: true });
            document.getElementById("doiDate").value = "";
            this.setState({ DateOfIncident: "" });
        }
    }
    handleAddrChange(e) {
        this.setState({ pPCAddress: false });
        if (this.testHyperLinks(e.target.value)) {
            this.setState({ PCAddress: e.target.value });
        }
        else {
            this.setState({ pPCAddress: true });
        }

    }
    handleStChange(e) {
        this.setState({ pStateId: false });
        this.setState({ StateId: e.target.value });
        var skillsSelect = document.getElementById("patientSt");
        var selectedText = skillsSelect.options[skillsSelect.selectedIndex].text;
        this.setState({ State: selectedText });
    }
    handleEmailChange(e) {
        this.setState({ pEmail: false });
        this.setState({ Email: e.target.value.replace(/ /g, '') });
    }

    async handleblurEmailChange(e) {
        let currentComponent = this;
        e.persist()
        var placeOrder = await this.CheckForPlaceOrder();
        if (placeOrder) {
            if (e.target.value.length != 0) {
                currentComponent.setState({ Loading: true });
                var data = { Email: e.target.value.replace(/ /g, '') };
                Api.Post(
                    "services/api/IsValidEmailMailAddress/",
                    data,
                    currentComponent
                )
                    .then(function (result) {

                        currentComponent.setState({ pEmail: !result });
                        currentComponent.setState({ Loading: false });

                    })
                    .catch(function (error) {
                        currentComponent.setState({ pEmail: false });
                        currentComponent.setState({ Loading: false });
                        if (error == "TypeError: Failed to fetch") {
                            currentComponent.toasterredfun2("Check your internet connection.");
                        } else {
                            currentComponent.toasterredfun2(error);
                        }
                    });
            }
        }
    }
    handleCityChange(e) {
        this.setState({ pPCCity: false });
        if (e.target.value.length != 0) {
            if (this.testAlphawithSpaceforCity(e.target.value)) {
                if (this.testHyperLinks(e.target.value)) {
                    this.setState({ PCCity: e.target.value });
                }
                else {
                    this.setState({ pPCCity: true });
                }
            }
        } else {
            this.setState({ PCCity: e.target.value });
        }
    }
    handleZipChange(e) {
        this.setState({ pPCZipCode: false });
        var zip = e.target.value.replace(/[^0-9]+/g, "");
        var curStart = e.target.selectionStart;
        if (zip.length == 0) {
            this.setState({ PCZipCode: "" });
        } else {
            if (/[0-9]$/.test(zip)) {
                if (zip.length < 6) {
                    this.setState(
                        { PCZipCode: zip },
                        () =>
                            (this.refs.zipc.selectionStart = this.refs.zipc.selectionEnd = curStart)
                    );
                } else if (zip.length == 6) {
                    this.setState(
                        { PCZipCode: zip.substring(0, 5) + "-" + zip.substring(5, 6) },
                        () =>
                        (this.refs.zipc.selectionStart = this.refs.zipc.selectionEnd =
                            curStart + 1)
                    );
                } else {
                    this.setState(
                        { PCZipCode: zip.substring(0, 5) + "-" + zip.substring(5, 9) },
                        () =>
                            (this.refs.zipc.selectionStart = this.refs.zipc.selectionEnd = curStart)
                    );
                }
            }
        }
    }
    handlePhn1Change(e) {
        this.phn1Change(e.target.value, e.target.selectionStart);
    }
    phn1Change = (ph1Value, curStart) => {
        this.setState({ pPhone1: false });
        var phn = ph1Value.replace(/[^0-9]+/g, "");
        if (phn.length == 0) {
            this.setState({ Phone1: "" });
        }
        if (/[0-9]$/.test(phn)) {
            let phnArray = [...phn];
            if (phn.length == 1) {
                this.setState(
                    { Phone1: "(" + phnArray[0] },
                    () =>
                    (this.refs.input.selectionStart = this.refs.input.selectionEnd =
                        curStart + 1)
                );
            }
            if (phn.length == 2) {
                this.setState(
                    { Phone1: "(" + phnArray[0] + phnArray[1] },
                    () =>
                        (this.refs.input.selectionStart = this.refs.input.selectionEnd = curStart)
                );
            } else if (phn.length == 3) {
                this.setState(
                    { Phone1: "(" + phn },
                    () =>
                        (this.refs.input.selectionStart = this.refs.input.selectionEnd = curStart)
                );
            } else if (phn.length == 4) {
                this.setState(
                    {
                        Phone1:
                            "(" +
                            phnArray[0] +
                            phnArray[1] +
                            phnArray[2] +
                            ") " +
                            phnArray[3],
                    },
                    () =>
                    (this.refs.input.selectionStart = this.refs.input.selectionEnd =
                        curStart + 2)
                );
            } else if (phn.length == 5) {
                this.setState(
                    {
                        Phone1:
                            "(" +
                            phnArray[0] +
                            phnArray[1] +
                            phnArray[2] +
                            ") " +
                            phnArray[3] +
                            phnArray[4],
                    },
                    () =>
                        (this.refs.input.selectionStart = this.refs.input.selectionEnd = curStart)
                );
            } else if (phn.length == 6) {
                this.setState(
                    {
                        Phone1:
                            "(" +
                            phnArray[0] +
                            phnArray[1] +
                            phnArray[2] +
                            ") " +
                            phnArray[3] +
                            phnArray[4] +
                            phnArray[5],
                    },
                    () =>
                        (this.refs.input.selectionStart = this.refs.input.selectionEnd = curStart)
                );
            } else if (phn.length == 7) {
                this.setState(
                    {
                        Phone1:
                            "(" +
                            phnArray[0] +
                            phnArray[1] +
                            phnArray[2] +
                            ") " +
                            phnArray[3] +
                            phnArray[4] +
                            phnArray[5] +
                            "-" +
                            phnArray[6],
                    },
                    () =>
                    (this.refs.input.selectionStart = this.refs.input.selectionEnd =
                        curStart + 1)
                );
            } else if (phn.length == 8) {
                this.setState(
                    {
                        Phone1:
                            "(" +
                            phnArray[0] +
                            phnArray[1] +
                            phnArray[2] +
                            ") " +
                            phnArray[3] +
                            phnArray[4] +
                            phnArray[5] +
                            "-" +
                            phnArray[6] +
                            phnArray[7],
                    },
                    () =>
                        (this.refs.input.selectionStart = this.refs.input.selectionEnd = curStart)
                );
            } else if (phn.length == 9) {
                this.setState(
                    {
                        Phone1:
                            "(" +
                            phnArray[0] +
                            phnArray[1] +
                            phnArray[2] +
                            ") " +
                            phnArray[3] +
                            phnArray[4] +
                            phnArray[5] +
                            "-" +
                            phnArray[6] +
                            phnArray[7] +
                            phnArray[8],
                    },
                    () =>
                        (this.refs.input.selectionStart = this.refs.input.selectionEnd = curStart)
                );
            } else if (phn.length == 10) {
                this.setState(
                    {
                        Phone1:
                            "(" +
                            phnArray[0] +
                            phnArray[1] +
                            phnArray[2] +
                            ") " +
                            phnArray[3] +
                            phnArray[4] +
                            phnArray[5] +
                            "-" +
                            phnArray[6] +
                            phnArray[7] +
                            phnArray[8] +
                            phnArray[9],
                    },
                    () =>
                        (this.refs.input.selectionStart = this.refs.input.selectionEnd = curStart)
                );
            }
        }
    }
    handleExt1Change(e) {
        this.setState({ pExt1: false });
        if (e.target.value.length != 0) {
            if (this.testEXT(e.target.value)) {
                this.setState({ Ext1: e.target.value });
            }
        } else {
            this.setState({ Ext1: e.target.value });
        }
    }
    handlePhn1TypeChange(e) {
        this.setState({ pPhone1Type: false });
        this.setState({ Phone1Type: e.target.value });
    }
    handlePhn2Change(e) {
        this.phn2Change(e.target.value, e.target.selectionStart);
    }
    phn2Change = (ph1Value, curStart) => {
        this.setState({ pPhone2: false });
        var phn2 = ph1Value.replace(/[^0-9]+/g, "");
        if (phn2.length == 0) {
            this.setState({ Phone2: "" });
        }
        if (/[0-9]$/.test(phn2)) {
            let phn2Array = [...phn2];
            if (phn2.length == 1) {
                this.setState(
                    { Phone2: "(" + phn2Array[0] },
                    () =>
                    (this.refs.phone2.selectionStart = this.refs.phone2.selectionEnd =
                        curStart + 1)
                );
            }
            if (phn2.length == 2) {
                this.setState(
                    { Phone2: "(" + phn2Array[0] + phn2Array[1] },
                    () =>
                        (this.refs.phone2.selectionStart = this.refs.phone2.selectionEnd = curStart)
                );
            } else if (phn2.length == 3) {
                this.setState(
                    { Phone2: "(" + phn2 },
                    () =>
                        (this.refs.phone2.selectionStart = this.refs.phone2.selectionEnd = curStart)
                );
            } else if (phn2.length == 4) {
                this.setState(
                    {
                        Phone2:
                            "(" +
                            phn2Array[0] +
                            phn2Array[1] +
                            phn2Array[2] +
                            ") " +
                            phn2Array[3],
                    },
                    () =>
                    (this.refs.phone2.selectionStart = this.refs.phone2.selectionEnd =
                        curStart + 2)
                );
            } else if (phn2.length == 5) {
                this.setState(
                    {
                        Phone2:
                            "(" +
                            phn2Array[0] +
                            phn2Array[1] +
                            phn2Array[2] +
                            ") " +
                            phn2Array[3] +
                            phn2Array[4],
                    },
                    () =>
                        (this.refs.phone2.selectionStart = this.refs.phone2.selectionEnd = curStart)
                );
            } else if (phn2.length == 6) {
                this.setState(
                    {
                        Phone2:
                            "(" +
                            phn2Array[0] +
                            phn2Array[1] +
                            phn2Array[2] +
                            ") " +
                            phn2Array[3] +
                            phn2Array[4] +
                            phn2Array[5],
                    },
                    () =>
                        (this.refs.phone2.selectionStart = this.refs.phone2.selectionEnd = curStart)
                );
            } else if (phn2.length == 7) {
                this.setState(
                    {
                        Phone2:
                            "(" +
                            phn2Array[0] +
                            phn2Array[1] +
                            phn2Array[2] +
                            ") " +
                            phn2Array[3] +
                            phn2Array[4] +
                            phn2Array[5] +
                            "-" +
                            phn2Array[6],
                    },
                    () =>
                    (this.refs.phone2.selectionStart = this.refs.phone2.selectionEnd =
                        curStart + 1)
                );
            } else if (phn2.length == 8) {
                this.setState(
                    {
                        Phone2:
                            "(" +
                            phn2Array[0] +
                            phn2Array[1] +
                            phn2Array[2] +
                            ") " +
                            phn2Array[3] +
                            phn2Array[4] +
                            phn2Array[5] +
                            "-" +
                            phn2Array[6] +
                            phn2Array[7],
                    },
                    () =>
                        (this.refs.phone2.selectionStart = this.refs.phone2.selectionEnd = curStart)
                );
            } else if (phn2.length == 9) {
                this.setState(
                    {
                        Phone2:
                            "(" +
                            phn2Array[0] +
                            phn2Array[1] +
                            phn2Array[2] +
                            ") " +
                            phn2Array[3] +
                            phn2Array[4] +
                            phn2Array[5] +
                            "-" +
                            phn2Array[6] +
                            phn2Array[7] +
                            phn2Array[8],
                    },
                    () =>
                        (this.refs.phone2.selectionStart = this.refs.phone2.selectionEnd = curStart)
                );
            } else if (phn2.length == 10) {
                this.setState(
                    {
                        Phone2:
                            "(" +
                            phn2Array[0] +
                            phn2Array[1] +
                            phn2Array[2] +
                            ") " +
                            phn2Array[3] +
                            phn2Array[4] +
                            phn2Array[5] +
                            "-" +
                            phn2Array[6] +
                            phn2Array[7] +
                            phn2Array[8] +
                            phn2Array[9],
                    },
                    () =>
                        (this.refs.phone2.selectionStart = this.refs.phone2.selectionEnd = curStart)
                );
            }
        }
    }
    handlePhn3Change(e) {
        this.phn3Change(e.target.value, e.target.selectionStart);
    }
    phn3Change = (ph3Value, curStart) => {
        this.setState({ pPhone3: false });
        var phn3 = ph3Value.replace(/[^0-9]+/g, "");
        if (phn3.length == 0) {
            this.setState({ Phone3: "" });
        }
        if (/[0-9]$/.test(phn3)) {
            let phn3Array = [...phn3];
            if (phn3.length == 1) {
                this.setState(
                    { Phone3: "(" + phn3Array[0] },
                    () =>
                    (this.refs.phone3.selectionStart = this.refs.phone3.selectionEnd =
                        curStart + 1)
                );
            }
            if (phn3.length == 2) {
                this.setState(
                    { Phone3: "(" + phn3Array[0] + phn3Array[1] },
                    () =>
                        (this.refs.phone3.selectionStart = this.refs.phone3.selectionEnd = curStart)
                );
            } else if (phn3.length == 3) {
                this.setState(
                    { Phone3: "(" + phn3 },
                    () =>
                        (this.refs.phone3.selectionStart = this.refs.phone3.selectionEnd = curStart)
                );
            } else if (phn3.length == 4) {
                this.setState(
                    {
                        Phone3:
                            "(" +
                            phn3Array[0] +
                            phn3Array[1] +
                            phn3Array[2] +
                            ") " +
                            phn3Array[3],
                    },
                    () =>
                    (this.refs.phone3.selectionStart = this.refs.phone3.selectionEnd =
                        curStart + 2)
                );
            } else if (phn3.length == 5) {
                this.setState(
                    {
                        Phone3:
                            "(" +
                            phn3Array[0] +
                            phn3Array[1] +
                            phn3Array[2] +
                            ") " +
                            phn3Array[3] +
                            phn3Array[4],
                    },
                    () =>
                        (this.refs.phone3.selectionStart = this.refs.phone3.selectionEnd = curStart)
                );
            } else if (phn3.length == 6) {
                this.setState(
                    {
                        Phone3:
                            "(" +
                            phn3Array[0] +
                            phn3Array[1] +
                            phn3Array[2] +
                            ") " +
                            phn3Array[3] +
                            phn3Array[4] +
                            phn3Array[5],
                    },
                    () =>
                        (this.refs.phone3.selectionStart = this.refs.phone3.selectionEnd = curStart)
                );
            } else if (phn3.length == 7) {
                this.setState(
                    {
                        Phone3:
                            "(" +
                            phn3Array[0] +
                            phn3Array[1] +
                            phn3Array[2] +
                            ") " +
                            phn3Array[3] +
                            phn3Array[4] +
                            phn3Array[5] +
                            "-" +
                            phn3Array[6],
                    },
                    () =>
                    (this.refs.phone3.selectionStart = this.refs.phone3.selectionEnd =
                        curStart + 1)
                );
            } else if (phn3.length == 8) {
                this.setState(
                    {
                        Phone3:
                            "(" +
                            phn3Array[0] +
                            phn3Array[1] +
                            phn3Array[2] +
                            ") " +
                            phn3Array[3] +
                            phn3Array[4] +
                            phn3Array[5] +
                            "-" +
                            phn3Array[6] +
                            phn3Array[7],
                    },
                    () =>
                        (this.refs.phone3.selectionStart = this.refs.phone3.selectionEnd = curStart)
                );
            } else if (phn3.length == 9) {
                this.setState(
                    {
                        Phone3:
                            "(" +
                            phn3Array[0] +
                            phn3Array[1] +
                            phn3Array[2] +
                            ") " +
                            phn3Array[3] +
                            phn3Array[4] +
                            phn3Array[5] +
                            "-" +
                            phn3Array[6] +
                            phn3Array[7] +
                            phn3Array[8],
                    },
                    () =>
                        (this.refs.phone3.selectionStart = this.refs.phone3.selectionEnd = curStart)
                );
            } else if (phn3.length == 10) {
                this.setState(
                    {
                        Phone3:
                            "(" +
                            phn3Array[0] +
                            phn3Array[1] +
                            phn3Array[2] +
                            ") " +
                            phn3Array[3] +
                            phn3Array[4] +
                            phn3Array[5] +
                            "-" +
                            phn3Array[6] +
                            phn3Array[7] +
                            phn3Array[8] +
                            phn3Array[9],
                    },
                    () =>
                        (this.refs.phone3.selectionStart = this.refs.phone3.selectionEnd = curStart)
                );
            }
        }
    }
    handleExt2Change(e) {
        this.setState({ pExt2: false });
        if (e.target.value.length != 0) {
            if (this.testEXT(e.target.value)) {
                this.setState({ Ext2: e.target.value });
            }
        } else {
            this.setState({ Ext2: e.target.value });
        }
    }
    handleExt3Change(e) {
        this.setState({ pExt3: false });
        if (e.target.value.length != 0) {
            if (this.testEXT(e.target.value)) {
                this.setState({ Ext3: e.target.value });
            }
        } else {
            this.setState({ Ext3: e.target.value });
        }
    }
    handlePhn2TypeChange(e) {
        this.setState({ pPhone2Type: false });
        this.setState({ Phone2Type: e.target.value });
    }
    handlePhn3TypeChange(e) {
        this.setState({ pPhone3Type: false });
        this.setState({ Phone3Type: e.target.value });
    }
    handleFaxChange(e) {
        this.setState({ pFax: false });
        var fax = e.target.value.replace(/[^0-9]+/g, "");
        var curStart = e.target.selectionStart;
        if (fax.length == 0) {
            this.setState({ Fax: "" });
        }
        if (/[0-9]$/.test(fax)) {
            let faxArray = [...fax];
            if (fax.length == 1) {
                this.setState(
                    { Fax: "(" + faxArray[0] },
                    () =>
                    (this.refs.Fax.selectionStart = this.refs.Fax.selectionEnd =
                        curStart + 1)
                );
            }
            if (fax.length == 2) {
                this.setState(
                    { Fax: "(" + faxArray[0] + faxArray[1] },
                    () =>
                        (this.refs.Fax.selectionStart = this.refs.Fax.selectionEnd = curStart)
                );
            } else if (fax.length == 3) {
                this.setState(
                    { Fax: "(" + fax },
                    () =>
                        (this.refs.Fax.selectionStart = this.refs.Fax.selectionEnd = curStart)
                );
            } else if (fax.length == 4) {
                this.setState(
                    {
                        Fax:
                            "(" +
                            faxArray[0] +
                            faxArray[1] +
                            faxArray[2] +
                            ") " +
                            faxArray[3],
                    },
                    () =>
                    (this.refs.Fax.selectionStart = this.refs.Fax.selectionEnd =
                        curStart + 2)
                );
            } else if (fax.length == 5) {
                this.setState(
                    {
                        Fax:
                            "(" +
                            faxArray[0] +
                            faxArray[1] +
                            faxArray[2] +
                            ") " +
                            faxArray[3] +
                            faxArray[4],
                    },
                    () =>
                        (this.refs.Fax.selectionStart = this.refs.Fax.selectionEnd = curStart)
                );
            } else if (fax.length == 6) {
                this.setState(
                    {
                        Fax:
                            "(" +
                            faxArray[0] +
                            faxArray[1] +
                            faxArray[2] +
                            ") " +
                            faxArray[3] +
                            faxArray[4] +
                            faxArray[5],
                    },
                    () =>
                        (this.refs.Fax.selectionStart = this.refs.Fax.selectionEnd = curStart)
                );
            } else if (fax.length == 7) {
                this.setState(
                    {
                        Fax:
                            "(" +
                            faxArray[0] +
                            faxArray[1] +
                            faxArray[2] +
                            ") " +
                            faxArray[3] +
                            faxArray[4] +
                            faxArray[5] +
                            "-" +
                            faxArray[6],
                    },
                    () =>
                    (this.refs.Fax.selectionStart = this.refs.Fax.selectionEnd =
                        curStart + 1)
                );
            } else if (fax.length == 8) {
                this.setState(
                    {
                        Fax:
                            "(" +
                            faxArray[0] +
                            faxArray[1] +
                            faxArray[2] +
                            ") " +
                            faxArray[3] +
                            faxArray[4] +
                            faxArray[5] +
                            "-" +
                            faxArray[6] +
                            faxArray[7],
                    },
                    () =>
                        (this.refs.Fax.selectionStart = this.refs.Fax.selectionEnd = curStart)
                );
            } else if (fax.length == 9) {
                this.setState(
                    {
                        Fax:
                            "(" +
                            faxArray[0] +
                            faxArray[1] +
                            faxArray[2] +
                            ") " +
                            faxArray[3] +
                            faxArray[4] +
                            faxArray[5] +
                            "-" +
                            faxArray[6] +
                            faxArray[7] +
                            faxArray[8],
                    },
                    () =>
                        (this.refs.Fax.selectionStart = this.refs.Fax.selectionEnd = curStart)
                );
            } else if (fax.length == 10) {
                this.setState(
                    {
                        Fax:
                            "(" +
                            faxArray[0] +
                            faxArray[1] +
                            faxArray[2] +
                            ") " +
                            faxArray[3] +
                            faxArray[4] +
                            faxArray[5] +
                            "-" +
                            faxArray[6] +
                            faxArray[7] +
                            faxArray[8] +
                            faxArray[9],
                    },
                    () =>
                        (this.refs.Fax.selectionStart = this.refs.Fax.selectionEnd = curStart)
                );
            }
        }
    }
    handleAFNChange(e) {
        this.setState({ pAgentFN: false });
        if (e.target.value.length != 0) {
            if (this.testAlphawithSpaceForNameAgent(e.target.value)) {
                if (this.testHyperLinks(e.target.value)) {
                    if (e.target.value.length > 2) {
                        let currentComponent = this;
                        let companyType = localStorage.getItem("CompanyType");

                        Api.Post(
                            "api/login/SearchAgent",
                            { apstool: e.target.value, agentType: (companyType == "LIFE" ? "Agent" : "Attorney") },
                            currentComponent
                        ).then(function (result) {
                            if (result.statusCode == 403) {
                                currentComponent.setState({ loading: false });
                                localStorage.removeItem('ShowAdminMenu');
                                localStorage.removeItem('UserType');
                                localStorage.removeItem('PlaceOrder');
                                localStorage.removeItem('ViewResults');
                                localStorage.removeItem('ViewAuth');
                                localStorage.removeItem('ExportSearchResults');
                                localStorage.removeItem('PerformanceMetrics');
                                localStorage.setItem('PlaceOrder', result.allowPlaceOrder);
                                localStorage.setItem('ShowAdminMenu', result.isAdmin);
                                localStorage.setItem('UserType', result.userType);
                                localStorage.setItem('ViewResults', result.downloadImages);
                                localStorage.setItem('ViewAuth', result.viewAuth);
                                localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                                localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                                currentComponent.setState({ adminAlert: true });
                            }
                            else {
                                currentComponent.setState({
                                    AgentList: result.agentList
                                });
                                currentComponent.setState({ AgentOptions: result.docList });
                            }

                        });
                    }
                    if (e.target.value.length <= 0) {
                        this.setState({ AgentList: [] });
                    }
                    this.setState({ AgentFN: e.target.value });
                }
                else {
                    this.setState({ pAgentFN: true });
                }
            }
        } else {
            this.setState({ AgentFN: e.target.value, AgentList: [] });
        }
    }
    handleALNChange(e) {
        this.setState({ pAgentLN: false });
        if (e.target.value.length != 0) {
            if (this.testAlphawithSpaceForNameAgent(e.target.value)) {
                if (this.testHyperLinks(e.target.value)) {
                    if (e.target.value.length > 2) {
                        let currentComponent = this;
                        let companyType = localStorage.getItem("CompanyType");

                        Api.Post(
                            "api/login/SearchAgent",
                            { apstool: e.target.value, agentType: (companyType == "LIFE" ? "Agent" : "Attorney") },
                            currentComponent
                        ).then(function (result) {
                            if (result.statusCode == 403) {
                                currentComponent.setState({ loading: false });
                                localStorage.removeItem('ShowAdminMenu');
                                localStorage.removeItem('UserType');
                                localStorage.removeItem('PlaceOrder');
                                localStorage.removeItem('ViewResults');
                                localStorage.removeItem('ViewAuth');
                                localStorage.removeItem('ExportSearchResults');
                                localStorage.removeItem('PerformanceMetrics');
                                localStorage.setItem('PlaceOrder', result.allowPlaceOrder);
                                localStorage.setItem('ShowAdminMenu', result.isAdmin);
                                localStorage.setItem('UserType', result.userType);
                                localStorage.setItem('ViewResults', result.downloadImages);
                                localStorage.setItem('ViewAuth', result.viewAuth);
                                localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                                localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                                currentComponent.setState({ adminAlert: true });

                            }
                            else {
                                currentComponent.setState({
                                    AgentList: result.agentList
                                });
                                currentComponent.setState({ AgentOptions: result.docList });
                            }

                        });
                    }
                    if (e.target.value.length <= 0) {
                        this.setState({ AgentList: [] });
                    }
                    this.setState({ AgentLN: e.target.value });
                }
                else {
                    this.setState({ pAgentLN: true });
                }
            }
        } else {
            this.setState({ AgentLN: e.target.value, AgentList: [] });
        }
    }
    handleAEmailChange(e) {
        this.setState({ pAgentEmail: false });
        this.setState({ AgentEmail: e.target.value.replace(/ /g, '') });
        /* this.setState({ pAgentEmail: false });
         if (e.target.value.length != 0) {
             if (this.testAlphaNumericwithSpaceForEmailNew(e.target.value)) {
                 this.setState({ AgentEmail: e.target.value });
             }
         } else {
             this.setState({ AgentEmail: e.target.value });
         }*/
    }
    handleAIdChange(e) {
        this.setState({ pAgentId: false });
        if (e.target.value.length != 0) {
            if (this.testAlphaNumeric(e.target.value)) {
                if (e.target.value.length > 2) {
                    let currentComponent = this;
                    let companyType = localStorage.getItem("CompanyType");

                    Api.Post(
                        "api/login/SearchAgent",
                        { apstool: e.target.value, agentType: (companyType == "LIFE" ? "Agent" : "Attorney") },
                        currentComponent
                    ).then(function (result) {
                        if (result.statusCode == 403) {
                            currentComponent.setState({ loading: false });
                            localStorage.removeItem('ShowAdminMenu');
                            localStorage.removeItem('UserType');
                            localStorage.removeItem('PlaceOrder');
                            localStorage.removeItem('ViewResults');
                            localStorage.removeItem('ViewAuth');
                            localStorage.removeItem('ExportSearchResults');
                            localStorage.removeItem('PerformanceMetrics');
                            localStorage.setItem('PlaceOrder', result.allowPlaceOrder);
                            localStorage.setItem('ShowAdminMenu', result.isAdmin);
                            localStorage.setItem('UserType', result.userType);
                            localStorage.setItem('ViewResults', result.downloadImages);
                            localStorage.setItem('ViewAuth', result.viewAuth);
                            localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                            localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                            currentComponent.setState({ adminAlert: true });

                        }
                        else
                        {
                            currentComponent.setState({
                                AgentList: result.agentList
                            });
                            currentComponent.setState({ AgentOptions: result.docList });
                        }

                    });
                }
                if (e.target.value.length <= 0) {
                    this.setState({ AgentList: [] });
                }
                this.setState({ AgentId: e.target.value });
            }
        } else {
            this.setState({ AgentId: e.target.value, AgentList: [] });
        }
    }
    handleAPhnChange(e) {
        this.agPhnChange(e.target.value, e.target.selectionStart);
    }
    agPhnChange = (agPhnValue, curStart) => {
        this.setState({ pAgentPhone: false });
        var APhn = agPhnValue.replace(/[^0-9]+/g, "");
        if (APhn.length == 0) {
            this.setState({ AgentPhone: "" });
        }
        if (/[0-9]$/.test(APhn)) {
            let AphnArray = [...APhn];
            if (APhn.length == 1) {
                this.setState(
                    { AgentPhone: "(" + AphnArray[0] },
                    () =>
                    (this.refs.APhnselectionStart = this.refs.APhn.selectionEnd =
                        curStart + 1)
                );
            }
            if (APhn.length == 2) {
                this.setState(
                    { AgentPhone: "(" + AphnArray[0] + AphnArray[1] },
                    () =>
                        (this.refs.APhnselectionStart = this.refs.APhn.selectionEnd = curStart)
                );
            } else if (APhn.length == 3) {
                this.setState(
                    { AgentPhone: "(" + APhn },
                    () =>
                        (this.refs.APhnselectionStart = this.refs.APhn.selectionEnd = curStart)
                );
            } else if (APhn.length == 4) {
                this.setState(
                    {
                        AgentPhone:
                            "(" +
                            AphnArray[0] +
                            AphnArray[1] +
                            AphnArray[2] +
                            ") " +
                            AphnArray[3],
                    },
                    () =>
                    (this.refs.APhnselectionStart = this.refs.APhn.selectionEnd =
                        curStart + 2)
                );
            } else if (APhn.length == 5) {
                this.setState(
                    {
                        AgentPhone:
                            "(" +
                            AphnArray[0] +
                            AphnArray[1] +
                            AphnArray[2] +
                            ") " +
                            AphnArray[3] +
                            AphnArray[4],
                    },
                    () =>
                        (this.refs.APhnselectionStart = this.refs.APhn.selectionEnd = curStart)
                );
            } else if (APhn.length == 6) {
                this.setState(
                    {
                        AgentPhone:
                            "(" +
                            AphnArray[0] +
                            AphnArray[1] +
                            AphnArray[2] +
                            ") " +
                            AphnArray[3] +
                            AphnArray[4] +
                            AphnArray[5],
                    },
                    () =>
                        (this.refs.APhnselectionStart = this.refs.APhn.selectionEnd = curStart)
                );
            } else if (APhn.length == 7) {
                this.setState(
                    {
                        AgentPhone:
                            "(" +
                            AphnArray[0] +
                            AphnArray[1] +
                            AphnArray[2] +
                            ") " +
                            AphnArray[3] +
                            AphnArray[4] +
                            AphnArray[5] +
                            "-" +
                            AphnArray[6],
                    },
                    () =>
                    (this.refs.APhnselectionStart = this.refs.APhn.selectionEnd =
                        curStart + 1)
                );
            } else if (APhn.length == 8) {
                this.setState(
                    {
                        AgentPhone:
                            "(" +
                            AphnArray[0] +
                            AphnArray[1] +
                            AphnArray[2] +
                            ") " +
                            AphnArray[3] +
                            AphnArray[4] +
                            AphnArray[5] +
                            "-" +
                            AphnArray[6] +
                            AphnArray[7],
                    },
                    () =>
                        (this.refs.APhnselectionStart = this.refs.APhn.selectionEnd = curStart)
                );
            } else if (APhn.length == 9) {
                this.setState(
                    {
                        AgentPhone:
                            "(" +
                            AphnArray[0] +
                            AphnArray[1] +
                            AphnArray[2] +
                            ") " +
                            AphnArray[3] +
                            AphnArray[4] +
                            AphnArray[5] +
                            "-" +
                            AphnArray[6] +
                            AphnArray[7] +
                            AphnArray[8],
                    },
                    () =>
                        (this.refs.APhnselectionStart = this.refs.APhn.selectionEnd = curStart)
                );
            } else if (APhn.length == 10) {
                this.setState(
                    {
                        AgentPhone:
                            "(" +
                            AphnArray[0] +
                            AphnArray[1] +
                            AphnArray[2] +
                            ") " +
                            AphnArray[3] +
                            AphnArray[4] +
                            AphnArray[5] +
                            "-" +
                            AphnArray[6] +
                            AphnArray[7] +
                            AphnArray[8] +
                            AphnArray[9],
                    },
                    () =>
                        (this.refs.APhnselectionStart = this.refs.APhn.selectionEnd = curStart)
                );
            }
        }
    }
    handleAExtChange(e) {
        this.setState({ pAgentExt: false });
        if (e.target.value.length != 0) {
            if (this.testEXT(e.target.value)) {
                this.setState({ AgentExt: e.target.value });
            }
        } else {
            this.setState({ AgentExt: e.target.value });
        }
    }
    handleAMobileChange(e) {
        this.setState({ pAgentMobile: false });
        var Amob = e.target.value.replace(/[^0-9]+/g, "");
        var curStart = e.target.selectionStart;
        if (Amob.length == 0) {
            this.setState({ AgentMobile: "" });
        }
        if (/[0-9]$/.test(Amob)) {
            let AmobArray = [...Amob];
            if (Amob.length == 1) {
                this.setState(
                    { AgentMobile: "(" + AmobArray[0] },
                    () =>
                    (this.refs.Amob.selectionStart = this.refs.Amob.selectionEnd =
                        curStart + 1)
                );
            }
            if (Amob.length == 2) {
                this.setState(
                    { AgentMobile: "(" + AmobArray[0] + AmobArray[1] },
                    () =>
                        (this.refs.Amob.selectionStart = this.refs.Amob.selectionEnd = curStart)
                );
            } else if (Amob.length == 3) {
                this.setState(
                    { AgentMobile: "(" + Amob },
                    () =>
                        (this.refs.Amob.selectionStart = this.refs.Amob.selectionEnd = curStart)
                );
            } else if (Amob.length == 4) {
                this.setState(
                    {
                        AgentMobile:
                            "(" +
                            AmobArray[0] +
                            AmobArray[1] +
                            AmobArray[2] +
                            ") " +
                            AmobArray[3],
                    },
                    () =>
                    (this.refs.Amob.selectionStart = this.refs.Amob.selectionEnd =
                        curStart + 2)
                );
            } else if (Amob.length == 5) {
                this.setState(
                    {
                        AgentMobile:
                            "(" +
                            AmobArray[0] +
                            AmobArray[1] +
                            AmobArray[2] +
                            ") " +
                            AmobArray[3] +
                            AmobArray[4],
                    },
                    () =>
                        (this.refs.Amob.selectionStart = this.refs.Amob.selectionEnd = curStart)
                );
            } else if (Amob.length == 6) {
                this.setState(
                    {
                        AgentMobile:
                            "(" +
                            AmobArray[0] +
                            AmobArray[1] +
                            AmobArray[2] +
                            ") " +
                            AmobArray[3] +
                            AmobArray[4] +
                            AmobArray[5],
                    },
                    () =>
                        (this.refs.Amob.selectionStart = this.refs.Amob.selectionEnd = curStart)
                );
            } else if (Amob.length == 7) {
                this.setState(
                    {
                        AgentMobile:
                            "(" +
                            AmobArray[0] +
                            AmobArray[1] +
                            AmobArray[2] +
                            ") " +
                            AmobArray[3] +
                            AmobArray[4] +
                            AmobArray[5] +
                            "-" +
                            AmobArray[6],
                    },
                    () =>
                    (this.refs.Amob.selectionStart = this.refs.Amob.selectionEnd =
                        curStart + 1)
                );
            } else if (Amob.length == 8) {
                this.setState(
                    {
                        AgentMobile:
                            "(" +
                            AmobArray[0] +
                            AmobArray[1] +
                            AmobArray[2] +
                            ") " +
                            AmobArray[3] +
                            AmobArray[4] +
                            AmobArray[5] +
                            "-" +
                            AmobArray[6] +
                            AmobArray[7],
                    },
                    () =>
                        (this.refs.Amob.selectionStart = this.refs.Amob.selectionEnd = curStart)
                );
            } else if (Amob.length == 9) {
                this.setState(
                    {
                        AgentMobile:
                            "(" +
                            AmobArray[0] +
                            AmobArray[1] +
                            AmobArray[2] +
                            ") " +
                            AmobArray[3] +
                            AmobArray[4] +
                            AmobArray[5] +
                            "-" +
                            AmobArray[6] +
                            AmobArray[7] +
                            AmobArray[8],
                    },
                    () =>
                        (this.refs.Amob.selectionStart = this.refs.Amob.selectionEnd = curStart)
                );
            } else if (Amob.length == 10) {
                this.setState(
                    {
                        AgentMobile:
                            "(" +
                            AmobArray[0] +
                            AmobArray[1] +
                            AmobArray[2] +
                            ") " +
                            AmobArray[3] +
                            AmobArray[4] +
                            AmobArray[5] +
                            "-" +
                            AmobArray[6] +
                            AmobArray[7] +
                            AmobArray[8] +
                            AmobArray[9],
                    },
                    () =>
                        (this.refs.Amob.selectionStart = this.refs.Amob.selectionEnd = curStart)
                );
            }
        }
    }
    handleAFaxChange(e) {
        this.setState({ pAgentFax: false });
        var Afax = e.target.value.replace(/[^0-9]+/g, "");
        var curStart = e.target.selectionStart;
        if (Afax.length == 0) {
            this.setState({ AgentFax: "" });
        }
        if (/[0-9]$/.test(Afax)) {
            let AfaxArray = [...Afax];
            if (Afax.length == 1) {
                this.setState(
                    { AgentFax: "(" + AfaxArray[0] },
                    () =>
                    (this.refs.Afax.selectionStart = this.refs.Afax.selectionEnd =
                        curStart + 1)
                );
            }
            if (Afax.length == 2) {
                this.setState(
                    { AgentFax: "(" + AfaxArray[0] + AfaxArray[1] },
                    () =>
                        (this.refs.Afax.selectionStart = this.refs.Afax.selectionEnd = curStart)
                );
            } else if (Afax.length == 3) {
                this.setState(
                    { AgentFax: "(" + Afax },
                    () =>
                        (this.refs.Afax.selectionStart = this.refs.Afax.selectionEnd = curStart)
                );
            } else if (Afax.length == 4) {
                this.setState(
                    {
                        AgentFax:
                            "(" +
                            AfaxArray[0] +
                            AfaxArray[1] +
                            AfaxArray[2] +
                            ") " +
                            AfaxArray[3],
                    },
                    () =>
                    (this.refs.Afax.selectionStart = this.refs.Afax.selectionEnd =
                        curStart + 2)
                );
            } else if (Afax.length == 5) {
                this.setState(
                    {
                        AgentFax:
                            "(" +
                            AfaxArray[0] +
                            AfaxArray[1] +
                            AfaxArray[2] +
                            ") " +
                            AfaxArray[3] +
                            AfaxArray[4],
                    },
                    () =>
                        (this.refs.Afax.selectionStart = this.refs.Afax.selectionEnd = curStart)
                );
            } else if (Afax.length == 6) {
                this.setState(
                    {
                        AgentFax:
                            "(" +
                            AfaxArray[0] +
                            AfaxArray[1] +
                            AfaxArray[2] +
                            ") " +
                            AfaxArray[3] +
                            AfaxArray[4] +
                            AfaxArray[5],
                    },
                    () =>
                        (this.refs.Afax.selectionStart = this.refs.Afax.selectionEnd = curStart)
                );
            } else if (Afax.length == 7) {
                this.setState(
                    {
                        AgentFax:
                            "(" +
                            AfaxArray[0] +
                            AfaxArray[1] +
                            AfaxArray[2] +
                            ") " +
                            AfaxArray[3] +
                            AfaxArray[4] +
                            AfaxArray[5] +
                            "-" +
                            AfaxArray[6],
                    },
                    () =>
                    (this.refs.Afax.selectionStart = this.refs.Afax.selectionEnd =
                        curStart + 1)
                );
            } else if (Afax.length == 8) {
                this.setState(
                    {
                        AgentFax:
                            "(" +
                            AfaxArray[0] +
                            AfaxArray[1] +
                            AfaxArray[2] +
                            ") " +
                            AfaxArray[3] +
                            AfaxArray[4] +
                            AfaxArray[5] +
                            "-" +
                            AfaxArray[6] +
                            AfaxArray[7],
                    },
                    () =>
                        (this.refs.Afax.selectionStart = this.refs.Afax.selectionEnd = curStart)
                );
            } else if (Afax.length == 9) {
                this.setState(
                    {
                        AgentFax:
                            "(" +
                            AfaxArray[0] +
                            AfaxArray[1] +
                            AfaxArray[2] +
                            ") " +
                            AfaxArray[3] +
                            AfaxArray[4] +
                            AfaxArray[5] +
                            "-" +
                            AfaxArray[6] +
                            AfaxArray[7] +
                            AfaxArray[8],
                    },
                    () =>
                        (this.refs.Afax.selectionStart = this.refs.Afax.selectionEnd = curStart)
                );
            } else if (Afax.length == 10) {
                this.setState(
                    {
                        AgentFax:
                            "(" +
                            AfaxArray[0] +
                            AfaxArray[1] +
                            AfaxArray[2] +
                            ") " +
                            AfaxArray[3] +
                            AfaxArray[4] +
                            AfaxArray[5] +
                            "-" +
                            AfaxArray[6] +
                            AfaxArray[7] +
                            AfaxArray[8] +
                            AfaxArray[9],
                    },
                    () =>
                        (this.refs.Afax.selectionStart = this.refs.Afax.selectionEnd = curStart)
                );
            }
        }
    }
    handleAdFNChange(e) {
        this.setState({ pAdjusterFN: false });
        if (e.target.value.length != 0) {
            if (this.testAlphawithSpaceForNameAgent(e.target.value)) {
                if (this.testHyperLinks(e.target.value)) {
                    if (e.target.value.length > 2) {
                        let currentComponent = this;
                        let companyType = localStorage.getItem("CompanyType");

                        Api.Post(
                            "api/login/SearchAgent",
                            { apstool: e.target.value, agentType: "Adjuster" },
                            currentComponent
                        ).then(function (result) {
                            if (result.statusCode == 403) {
                                currentComponent.setState({ loading: false });
                                localStorage.removeItem('ShowAdminMenu');
                                localStorage.removeItem('UserType');
                                localStorage.removeItem('PlaceOrder');
                                localStorage.removeItem('ViewResults');
                                localStorage.removeItem('ViewAuth');
                                localStorage.removeItem('ExportSearchResults');
                                localStorage.removeItem('PerformanceMetrics');
                                localStorage.setItem('PlaceOrder', result.allowPlaceOrder);
                                localStorage.setItem('ShowAdminMenu', result.isAdmin);
                                localStorage.setItem('UserType', result.userType);
                                localStorage.setItem('ViewResults', result.downloadImages);
                                localStorage.setItem('ViewAuth', result.viewAuth);
                                localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                                localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                                currentComponent.setState({ adminAlert: true });

                            }
                            else {

                            }
                            currentComponent.setState({
                                AgentList: result.adjusterList
                            });
                            currentComponent.setState({ AdjusterOptions: result.docList });
                        });
                    }
                    if (e.target.value.length <= 0) {
                        this.setState({ AgentList: [] });
                    }
                    this.setState({ AdjusterFN: e.target.value });
                }
                else {
                    this.setState({ pAdjusterFN: true });
                }
            }
        } else {
            this.setState({ AdjusterFN: e.target.value, AgentList: [] });
        }
    }
    handleAdLNChange(e) {
        this.setState({ pAdjusterLN: false });
        if (e.target.value.length != 0) {
            if (this.testAlphawithSpaceForNameAgent(e.target.value)) {
                if (this.testHyperLinks(e.target.value)) {
                    if (e.target.value.length > 2) {
                        let currentComponent = this;
                        let companyType = localStorage.getItem("CompanyType");

                        Api.Post(
                            "api/login/SearchAgent",
                            { apstool: e.target.value, agentType: "Adjuster" },
                            currentComponent
                        ).then(function (result) {
                            if (result.statusCode == 403) {
                                currentComponent.setState({ loading: false });
                                localStorage.removeItem('ShowAdminMenu');
                                localStorage.removeItem('UserType');
                                localStorage.removeItem('PlaceOrder');
                                localStorage.removeItem('ViewResults');
                                localStorage.removeItem('ViewAuth');
                                localStorage.removeItem('ExportSearchResults');
                                localStorage.removeItem('PerformanceMetrics');
                                localStorage.setItem('PlaceOrder', result.allowPlaceOrder);
                                localStorage.setItem('ShowAdminMenu', result.isAdmin);
                                localStorage.setItem('UserType', result.userType);
                                localStorage.setItem('ViewResults', result.downloadImages);
                                localStorage.setItem('ViewAuth', result.viewAuth);
                                localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                                localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                                currentComponent.setState({ adminAlert: true });

                            }
                            else {
                                currentComponent.setState({
                                    AgentList: result.adjusterList
                                });
                                currentComponent.setState({ AdjusterOptions: result.docList });

                            }

                        });
                    }
                    if (e.target.value.length <= 0) {
                        this.setState({ AgentList: [] });
                    }
                    this.setState({ AdjusterLN: e.target.value });
                }
                else {
                    this.setState({ pAdjusterLN: true });
                }
            }
        } else {
            this.setState({ AdjusterLN: e.target.value, AgentList: [] });
        }
    }
    handleAdEmailChange(e) {
        this.setState({ pAdjusterEmail: false });
        this.setState({ AdjusterEmail: e.target.value.replace(/ /g, '') });

        /*this.setState({ pAdjusterEmail: false });
        if (e.target.value.length != 0) {
            if (this.testAlphaNumericwithSpaceForEmail(e.target.value)) {
                this.setState({ AdjusterEmail: e.target.value });
            }
        } else {
            this.setState({ AdjusterEmail: e.target.value });
            this.setState({ pAdjusterEmail: true });

        }*/
    }
    handleAdIdChange(e) {
        this.setState({ pAdjusterId: false });
        if (e.target.value.length != 0) {
            if (this.testAlphaNumeric(e.target.value)) {
                if (this.testHyperLinks(e.target.value)) {
                    if (e.target.value.length > 2) {
                        let currentComponent = this;
                        let companyType = localStorage.getItem("CompanyType");

                        Api.Post(
                            "api/login/SearchAgent",
                            { apstool: e.target.value, agentType: "Adjuster" },
                            currentComponent
                        ).then(function (result) {
                            if (result.statusCode == 403) {
                                currentComponent.setState({ loading: false });
                                localStorage.removeItem('ShowAdminMenu');
                                localStorage.removeItem('UserType');
                                localStorage.removeItem('PlaceOrder');
                                localStorage.removeItem('ViewResults');
                                localStorage.removeItem('ViewAuth');
                                localStorage.removeItem('ExportSearchResults');
                                localStorage.removeItem('PerformanceMetrics');
                                localStorage.setItem('PlaceOrder', result.allowPlaceOrder);
                                localStorage.setItem('ShowAdminMenu', result.isAdmin);
                                localStorage.setItem('UserType', result.userType);
                                localStorage.setItem('ViewResults', result.downloadImages);
                                localStorage.setItem('ViewAuth', result.viewAuth);
                                localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                                localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                                currentComponent.setState({ adminAlert: true });

                            }
                            else {
                                currentComponent.setState({
                                    AgentList: result.adjusterList
                                });
                                currentComponent.setState({ AdjusterOptions: result.docList });
                            }

                        });
                    }
                    if (e.target.value.length <= 0) {
                        this.setState({ AgentList: [] });
                    }
                    this.setState({ AdjusterId: e.target.value });
                }
                else {
                    this.setState({ pAdjusterId: true });
                }
            }
        } else {
            this.setState({ AdjusterId: e.target.value, AgentList: [] });
        }
    }
    handleAdPhnChange(e) {
        this.setState({ pAdjusterPhone: false });
        var AdPhn = e.target.value.replace(/[^0-9]+/g, "");
        var curStart = e.target.selectionStart;
        if (AdPhn.length == 0) {
            this.setState({ AdjusterPhone: "" });
        }
        if (/[0-9]$/.test(AdPhn)) {
            let AdphnArray = [...AdPhn];
            if (AdPhn.length == 1) {
                this.setState(
                    { AdjusterPhone: "(" + AdphnArray[0] },
                    () =>
                    (this.refs.AdPhn.selectionStart = this.refs.AdPhn.selectionEnd =
                        curStart + 1)
                );
            }
            if (AdPhn.length == 2) {
                this.setState(
                    { AdjusterPhone: "(" + AdphnArray[0] + AdphnArray[1] },
                    () =>
                        (this.refs.AdPhn.selectionStart = this.refs.AdPhn.selectionEnd = curStart)
                );
            } else if (AdPhn.length == 3) {
                this.setState(
                    { AdjusterPhone: "(" + AdPhn },
                    () =>
                        (this.refs.AdPhn.selectionStart = this.refs.AdPhn.selectionEnd = curStart)
                );
            } else if (AdPhn.length == 4) {
                this.setState(
                    {
                        AdjusterPhone:
                            "(" +
                            AdphnArray[0] +
                            AdphnArray[1] +
                            AdphnArray[2] +
                            ") " +
                            AdphnArray[3],
                    },
                    () =>
                    (this.refs.AdPhn.selectionStart = this.refs.AdPhn.selectionEnd =
                        curStart + 2)
                );
            } else if (AdPhn.length == 5) {
                this.setState(
                    {
                        AdjusterPhone:
                            "(" +
                            AdphnArray[0] +
                            AdphnArray[1] +
                            AdphnArray[2] +
                            ") " +
                            AdphnArray[3] +
                            AdphnArray[4],
                    },
                    () =>
                        (this.refs.AdPhn.selectionStart = this.refs.AdPhn.selectionEnd = curStart)
                );
            } else if (AdPhn.length == 6) {
                this.setState(
                    {
                        AdjusterPhone:
                            "(" +
                            AdphnArray[0] +
                            AdphnArray[1] +
                            AdphnArray[2] +
                            ") " +
                            AdphnArray[3] +
                            AdphnArray[4] +
                            AdphnArray[5],
                    },
                    () =>
                        (this.refs.AdPhn.selectionStart = this.refs.AdPhn.selectionEnd = curStart)
                );
            } else if (AdPhn.length == 7) {
                this.setState(
                    {
                        AdjusterPhone:
                            "(" +
                            AdphnArray[0] +
                            AdphnArray[1] +
                            AdphnArray[2] +
                            ") " +
                            AdphnArray[3] +
                            AdphnArray[4] +
                            AdphnArray[5] +
                            "-" +
                            AdphnArray[6],
                    },
                    () =>
                    (this.refs.AdPhn.selectionStart = this.refs.AdPhn.selectionEnd =
                        curStart + 1)
                );
            } else if (AdPhn.length == 8) {
                this.setState(
                    {
                        AdjusterPhone:
                            "(" +
                            AdphnArray[0] +
                            AdphnArray[1] +
                            AdphnArray[2] +
                            ") " +
                            AdphnArray[3] +
                            AdphnArray[4] +
                            AdphnArray[5] +
                            "-" +
                            AdphnArray[6] +
                            AdphnArray[7],
                    },
                    () =>
                        (this.refs.AdPhn.selectionStart = this.refs.AdPhn.selectionEnd = curStart)
                );
            } else if (AdPhn.length == 9) {
                this.setState(
                    {
                        AdjusterPhone:
                            "(" +
                            AdphnArray[0] +
                            AdphnArray[1] +
                            AdphnArray[2] +
                            ") " +
                            AdphnArray[3] +
                            AdphnArray[4] +
                            AdphnArray[5] +
                            "-" +
                            AdphnArray[6] +
                            AdphnArray[7] +
                            AdphnArray[8],
                    },
                    () =>
                        (this.refs.AdPhn.selectionStart = this.refs.AdPhn.selectionEnd = curStart)
                );
            } else if (AdPhn.length == 10) {
                this.setState(
                    {
                        AdjusterPhone:
                            "(" +
                            AdphnArray[0] +
                            AdphnArray[1] +
                            AdphnArray[2] +
                            ") " +
                            AdphnArray[3] +
                            AdphnArray[4] +
                            AdphnArray[5] +
                            "-" +
                            AdphnArray[6] +
                            AdphnArray[7] +
                            AdphnArray[8] +
                            AdphnArray[9],
                    },
                    () =>
                        (this.refs.AdPhn.selectionStart = this.refs.AdPhn.selectionEnd = curStart)
                );
            }
        }
    }
    handleAdExtChange(e) {
        this.setState({ pAdjusterExt: false });
        if (e.target.value.length != 0) {
            if (this.testEXT(e.target.value)) {
                this.setState({ AdjusterExt: e.target.value });
            }
        } else {
            this.setState({ AdjusterExt: e.target.value });
        }
    }
    handleAdMobileChange(e) {
        this.setState({ pAdjusterMobile: false });
        var Admob = e.target.value.replace(/[^0-9]+/g, "");
        var curStart = e.target.selectionStart;
        if (Admob.length == 0) {
            this.setState({ AdjusterMobile: "" });
        }
        if (/[0-9]$/.test(Admob)) {
            let AdmobArray = [...Admob];
            if (Admob.length == 1) {
                this.setState(
                    { AdjusterMobile: "(" + AdmobArray[0] },
                    () =>
                    (this.refs.Admob.selectionStart = this.refs.Admob.selectionEnd =
                        curStart + 1)
                );
            }
            if (Admob.length == 2) {
                this.setState(
                    { AdjusterMobile: "(" + AdmobArray[0] + AdmobArray[1] },
                    () =>
                        (this.refs.Admob.selectionStart = this.refs.Admob.selectionEnd = curStart)
                );
            } else if (Admob.length == 3) {
                this.setState(
                    { AdjusterMobile: "(" + Admob },
                    () =>
                        (this.refs.Admob.selectionStart = this.refs.Admob.selectionEnd = curStart)
                );
            } else if (Admob.length == 4) {
                this.setState(
                    {
                        AdjusterMobile:
                            "(" +
                            AdmobArray[0] +
                            AdmobArray[1] +
                            AdmobArray[2] +
                            ") " +
                            AdmobArray[3],
                    },
                    () =>
                    (this.refs.Admob.selectionStart = this.refs.Admob.selectionEnd =
                        curStart + 2)
                );
            } else if (Admob.length == 5) {
                this.setState(
                    {
                        AdjusterMobile:
                            "(" +
                            AdmobArray[0] +
                            AdmobArray[1] +
                            AdmobArray[2] +
                            ") " +
                            AdmobArray[3] +
                            AdmobArray[4],
                    },
                    () =>
                        (this.refs.Admob.selectionStart = this.refs.Admob.selectionEnd = curStart)
                );
            } else if (Admob.length == 6) {
                this.setState(
                    {
                        AdjusterMobile:
                            "(" +
                            AdmobArray[0] +
                            AdmobArray[1] +
                            AdmobArray[2] +
                            ") " +
                            AdmobArray[3] +
                            AdmobArray[4] +
                            AdmobArray[5],
                    },
                    () =>
                        (this.refs.Admob.selectionStart = this.refs.Admob.selectionEnd = curStart)
                );
            } else if (Admob.length == 7) {
                this.setState(
                    {
                        AdjusterMobile:
                            "(" +
                            AdmobArray[0] +
                            AdmobArray[1] +
                            AdmobArray[2] +
                            ") " +
                            AdmobArray[3] +
                            AdmobArray[4] +
                            AdmobArray[5] +
                            "-" +
                            AdmobArray[6],
                    },
                    () =>
                    (this.refs.Admob.selectionStart = this.refs.Admob.selectionEnd =
                        curStart + 1)
                );
            } else if (Admob.length == 8) {
                this.setState(
                    {
                        AdjusterMobile:
                            "(" +
                            AdmobArray[0] +
                            AdmobArray[1] +
                            AdmobArray[2] +
                            ") " +
                            AdmobArray[3] +
                            AdmobArray[4] +
                            AdmobArray[5] +
                            "-" +
                            AdmobArray[6] +
                            AdmobArray[7],
                    },
                    () =>
                        (this.refs.Admob.selectionStart = this.refs.Admob.selectionEnd = curStart)
                );
            } else if (Admob.length == 9) {
                this.setState(
                    {
                        AdjusterMobile:
                            "(" +
                            AdmobArray[0] +
                            AdmobArray[1] +
                            AdmobArray[2] +
                            ") " +
                            AdmobArray[3] +
                            AdmobArray[4] +
                            AdmobArray[5] +
                            "-" +
                            AdmobArray[6] +
                            AdmobArray[7] +
                            AdmobArray[8],
                    },
                    () =>
                        (this.refs.Admob.selectionStart = this.refs.Admob.selectionEnd = curStart)
                );
            } else if (Admob.length == 10) {
                this.setState(
                    {
                        AdjusterMobile:
                            "(" +
                            AdmobArray[0] +
                            AdmobArray[1] +
                            AdmobArray[2] +
                            ") " +
                            AdmobArray[3] +
                            AdmobArray[4] +
                            AdmobArray[5] +
                            "-" +
                            AdmobArray[6] +
                            AdmobArray[7] +
                            AdmobArray[8] +
                            AdmobArray[9],
                    },
                    () =>
                        (this.refs.Admob.selectionStart = this.refs.Admob.selectionEnd = curStart)
                );
            }
        }
    }
    handleAdFaxChange(e) {
        this.setState({ pAdjusterFax: false });
        var Adfax = e.target.value.replace(/[^0-9]+/g, "");
        var curStart = e.target.selectionStart;
        if (Adfax.length == 0) {
            this.setState({ AdjusterFax: "" });
        }
        if (/[0-9]$/.test(Adfax)) {
            let AdfaxArray = [...Adfax];
            if (Adfax.length == 1) {
                this.setState(
                    { AdjusterFax: "(" + AdfaxArray[0] },
                    () =>
                    (this.refs.Adfax.selectionStart = this.refs.Adfax.selectionEnd =
                        curStart + 1)
                );
            }
            if (Adfax.length == 2) {
                this.setState(
                    { AdjusterFax: "(" + AdfaxArray[0] + AdfaxArray[1] },
                    () =>
                        (this.refs.Adfax.selectionStart = this.refs.Adfax.selectionEnd = curStart)
                );
            } else if (Adfax.length == 3) {
                this.setState(
                    { AdjusterFax: "(" + Adfax },
                    () =>
                        (this.refs.Adfax.selectionStart = this.refs.Adfax.selectionEnd = curStart)
                );
            } else if (Adfax.length == 4) {
                this.setState(
                    {
                        AdjusterFax:
                            "(" +
                            AdfaxArray[0] +
                            AdfaxArray[1] +
                            AdfaxArray[2] +
                            ") " +
                            AdfaxArray[3],
                    },
                    () =>
                    (this.refs.Adfax.selectionStart = this.refs.Adfax.selectionEnd =
                        curStart + 2)
                );
            } else if (Adfax.length == 5) {
                this.setState(
                    {
                        AdjusterFax:
                            "(" +
                            AdfaxArray[0] +
                            AdfaxArray[1] +
                            AdfaxArray[2] +
                            ") " +
                            AdfaxArray[3] +
                            AdfaxArray[4],
                    },
                    () =>
                        (this.refs.Adfax.selectionStart = this.refs.Adfax.selectionEnd = curStart)
                );
            } else if (Adfax.length == 6) {
                this.setState(
                    {
                        AdjusterFax:
                            "(" +
                            AdfaxArray[0] +
                            AdfaxArray[1] +
                            AdfaxArray[2] +
                            ") " +
                            AdfaxArray[3] +
                            AdfaxArray[4] +
                            AdfaxArray[5],
                    },
                    () =>
                        (this.refs.Adfax.selectionStart = this.refs.Adfax.selectionEnd = curStart)
                );
            } else if (Adfax.length == 7) {
                this.setState(
                    {
                        AdjusterFax:
                            "(" +
                            AdfaxArray[0] +
                            AdfaxArray[1] +
                            AdfaxArray[2] +
                            ") " +
                            AdfaxArray[3] +
                            AdfaxArray[4] +
                            AdfaxArray[5] +
                            "-" +
                            AdfaxArray[6],
                    },
                    () =>
                    (this.refs.Adfax.selectionStart = this.refs.Adfax.selectionEnd =
                        curStart + 1)
                );
            } else if (Adfax.length == 8) {
                this.setState(
                    {
                        AdjusterFax:
                            "(" +
                            AdfaxArray[0] +
                            AdfaxArray[1] +
                            AdfaxArray[2] +
                            ") " +
                            AdfaxArray[3] +
                            AdfaxArray[4] +
                            AdfaxArray[5] +
                            "-" +
                            AdfaxArray[6] +
                            AdfaxArray[7],
                    },
                    () =>
                        (this.refs.Adfax.selectionStart = this.refs.Adfax.selectionEnd = curStart)
                );
            } else if (Adfax.length == 9) {
                this.setState(
                    {
                        AdjusterFax:
                            "(" +
                            AdfaxArray[0] +
                            AdfaxArray[1] +
                            AdfaxArray[2] +
                            ") " +
                            AdfaxArray[3] +
                            AdfaxArray[4] +
                            AdfaxArray[5] +
                            "-" +
                            AdfaxArray[6] +
                            AdfaxArray[7] +
                            AdfaxArray[8],
                    },
                    () =>
                        (this.refs.Adfax.selectionStart = this.refs.Adfax.selectionEnd = curStart)
                );
            } else if (Adfax.length == 10) {
                this.setState(
                    {
                        AdjusterFax:
                            "(" +
                            AdfaxArray[0] +
                            AdfaxArray[1] +
                            AdfaxArray[2] +
                            ") " +
                            AdfaxArray[3] +
                            AdfaxArray[4] +
                            AdfaxArray[5] +
                            "-" +
                            AdfaxArray[6] +
                            AdfaxArray[7] +
                            AdfaxArray[8] +
                            AdfaxArray[9],
                    },
                    () =>
                        (this.refs.Adfax.selectionStart = this.refs.Adfax.selectionEnd = curStart)
                );
            }
        }
    }
    handleDFNChange(e) {
        this.setState({ pDoctorFirstName: false });
        if (e.target.value.length != 0) {
            if (this.testAlphawithSpaceForName(e.target.value)) {
                if (this.testHyperLinks(e.target.value)) {
                    this.setState({ DoctorFirstName: e.target.value });
                }
                else {
                    this.setState({ pDoctorFirstName: true });
                }
            }
        } else {
            this.setState({ DoctorFirstName: e.target.value });
        }
    }
    handleDLNChange(e) {
        this.setState({ pDoctorLastName: false });
        if (e.target.value.length != 0) {
            if (this.testAlphawithSpaceForName(e.target.value)) {
                if (this.testHyperLinks(e.target.value)) {
                    this.setState({ DoctorLastName: e.target.value });
                }
                else {
                    this.setState({ pDoctorLastName: true });
                }
            }
        } else {
            this.setState({ DoctorLastName: e.target.value });
        }
    }
    handleDFacilityChange(e) {
        if (e.target.value.length != 0) {
            this.setState({ pDoctorFirstName: false, pDoctorLastName: false, pFacilityName: false });
            if (this.testAlphaNumericwithSpaceForFacility(e.target.value)) {
                if (this.testHyperLinks(e.target.value)) {
                    this.setState({ FacilityName: e.target.value });
                }
                else {
                    this.setState({ pFacilityName: true });
                }
            }
        } else {
            this.setState({ FacilityName: e.target.value });
        }
    }
    handleDAddressChange(e) {
        this.setState({ pDrAddress: false });
        if (this.state.isMandatoryDrAddress) {
            if (e.target.value.length == 0) {
                this.setState({ pDrAddress: true });
            }
            else {
                this.setState({ DrAddress: e.target.value });
            }
        }
        if (this.testHyperLinks(e.target.value)) {
            this.setState({ DrAddress: e.target.value });
        }
        else {
            this.setState({ pDrAddress: true });
        }
    }
    handleDEmailAddressChange(e) {
        this.setState({ pDrEmailAddress: false });
        this.setState({ DrEmailAddress: e.target.value.replace(/ /g, '') });

        /* this.setState({ pDrEmailAddress: false });
         if (e.target.value.length != 0) {
             if (this.testAlphaNumericwithSpaceForEmail(e.target.value)) {
                 this.setState({ DrEmailAddress: e.target.value });
             }
         } else {
             this.setState({ DrEmailAddress: e.target.value });
         }*/
    }
    handleDCityChange(e) {
        this.setState({ pDoctorCity: false });
        if (e.target.value.length != 0) {
            if (this.testAlphawithSpaceforCity(e.target.value)) {
                if (this.testHyperLinks(e.target.value)) {
                    this.setState({ DoctorCity: e.target.value });
                }
                else {
                    this.setState({ pDoctorCity: true });
                }
            }
        } else {
            this.setState({ DoctorCity: e.target.value });
        }
    }
    handleDStateChange(e) {
        this.setState({ pDrStateId: false });
        this.setState({ DrStateId: e.target.value });
        var skillsSelect = document.getElementById("doctorSt");
        var selectedText = skillsSelect.options[skillsSelect.selectedIndex].text;
        this.setState({ DrState: selectedText });
    }
    handleDZipChange(value, curStart) {
        this.setState({ pDrZipCode: false });
        var zip = value.replace(/[^0-9]+/g, "");
        var curStart = curStart;
        if (zip.length == 0) {
            this.setState({ DrZipCode: "" });
        } else {
            if (/[0-9]$/.test(zip)) {
                if (zip.length < 6) {
                    this.setState(
                        { DrZipCode: zip },
                        () =>
                            (this.refs.zipd.selectionStart = this.refs.zipd.selectionEnd = curStart)
                    );
                } else if (zip.length == 6) {
                    this.setState(
                        { DrZipCode: zip.substring(0, 5) + "-" + zip.substring(5, 6) },
                        () =>
                        (this.refs.zipd.selectionStart = this.refs.zipd.selectionEnd =
                            curStart + 1)
                    );
                } else {
                    this.setState(
                        { DrZipCode: zip.substring(0, 5) + "-" + zip.substring(5, 9) },
                        () =>
                            (this.refs.zipd.selectionStart = this.refs.zipd.selectionEnd = curStart)
                    );
                }
            }
        }
    }
    handleDPhnChange(e) {
        this.dPhnChange(e.target.value, e.target.selectionStart);
    }
    dPhnChange = (dPhnValue, curStart) => {
        this.setState({ pPhone: false });
        var Dphn = dPhnValue.replace(/[^0-9]+/g, "");
        if (Dphn.length == 0) {
            this.setState({ Phone: "" });
        }
        if (/[0-9]$/.test(Dphn)) {
            let DphnArray = [...Dphn];
            if (Dphn.length == 1) {
                this.setState(
                    { Phone: "(" + DphnArray[0] },
                    () =>
                    (this.refs.Dphn.selectionStart = this.refs.Dphn.selectionEnd =
                        curStart + 1)
                );
            }
            if (Dphn.length == 2) {
                this.setState(
                    { Phone: "(" + DphnArray[0] + DphnArray[1] },
                    () =>
                        (this.refs.Dphn.selectionStart = this.refs.Dphn.selectionEnd = curStart)
                );
            } else if (Dphn.length == 3) {
                this.setState(
                    { Phone: "(" + Dphn },
                    () =>
                        (this.refs.Dphn.selectionStart = this.refs.Dphn.selectionEnd = curStart)
                );
            } else if (Dphn.length == 4) {
                this.setState(
                    {
                        Phone:
                            "(" +
                            DphnArray[0] +
                            DphnArray[1] +
                            DphnArray[2] +
                            ") " +
                            DphnArray[3],
                    },
                    () =>
                    (this.refs.Dphn.selectionStart = this.refs.Dphn.selectionEnd =
                        curStart + 2)
                );
            } else if (Dphn.length == 5) {
                this.setState(
                    {
                        Phone:
                            "(" +
                            DphnArray[0] +
                            DphnArray[1] +
                            DphnArray[2] +
                            ") " +
                            DphnArray[3] +
                            DphnArray[4],
                    },
                    () =>
                        (this.refs.Dphn.selectionStart = this.refs.Dphn.selectionEnd = curStart)
                );
            } else if (Dphn.length == 6) {
                this.setState(
                    {
                        Phone:
                            "(" +
                            DphnArray[0] +
                            DphnArray[1] +
                            DphnArray[2] +
                            ") " +
                            DphnArray[3] +
                            DphnArray[4] +
                            DphnArray[5],
                    },
                    () =>
                        (this.refs.Dphn.selectionStart = this.refs.Dphn.selectionEnd = curStart)
                );
            } else if (Dphn.length == 7) {
                this.setState(
                    {
                        Phone:
                            "(" +
                            DphnArray[0] +
                            DphnArray[1] +
                            DphnArray[2] +
                            ") " +
                            DphnArray[3] +
                            DphnArray[4] +
                            DphnArray[5] +
                            "-" +
                            DphnArray[6],
                    },
                    () =>
                    (this.refs.Dphn.selectionStart = this.refs.Dphn.selectionEnd =
                        curStart + 1)
                );
            } else if (Dphn.length == 8) {
                this.setState(
                    {
                        Phone:
                            "(" +
                            DphnArray[0] +
                            DphnArray[1] +
                            DphnArray[2] +
                            ") " +
                            DphnArray[3] +
                            DphnArray[4] +
                            DphnArray[5] +
                            "-" +
                            DphnArray[6] +
                            DphnArray[7],
                    },
                    () =>
                        (this.refs.Dphn.selectionStart = this.refs.Dphn.selectionEnd = curStart)
                );
            } else if (Dphn.length == 9) {
                this.setState(
                    {
                        Phone:
                            "(" +
                            DphnArray[0] +
                            DphnArray[1] +
                            DphnArray[2] +
                            ") " +
                            DphnArray[3] +
                            DphnArray[4] +
                            DphnArray[5] +
                            "-" +
                            DphnArray[6] +
                            DphnArray[7] +
                            DphnArray[8],
                    },
                    () =>
                        (this.refs.Dphn.selectionStart = this.refs.Dphn.selectionEnd = curStart)
                );
            } else if (Dphn.length == 10) {
                this.setState(
                    {
                        Phone:
                            "(" +
                            DphnArray[0] +
                            DphnArray[1] +
                            DphnArray[2] +
                            ") " +
                            DphnArray[3] +
                            DphnArray[4] +
                            DphnArray[5] +
                            "-" +
                            DphnArray[6] +
                            DphnArray[7] +
                            DphnArray[8] +
                            DphnArray[9],
                    },
                    () =>
                        (this.refs.Dphn.selectionStart = this.refs.Dphn.selectionEnd = curStart)
                );
            }


            else if (Dphn.length >= 14) {
                this.setState(
                    {
                        Phone:
                            "(" +
                            DphnArray[0] +
                            DphnArray[1] +
                            DphnArray[2] +
                            ") " +
                            DphnArray[3] +
                            DphnArray[4] +
                            DphnArray[5] +
                            "-" +
                            DphnArray[6] +
                            DphnArray[7] +
                            DphnArray[8] +
                            DphnArray[9],
                    },
                    () =>
                        (this.refs.Dphn.selectionStart = this.refs.Dphn.selectionEnd = curStart)
                );
            }

        }
    }
    handleDFaxChange(value, curStart) {
        this.setState({ pDrFax: false });
        var Dfax = value.replace(/[^0-9]+/g, "");
        if (Dfax.length == 0) {
            this.setState({ DrFax: "" });
        }
        if (/[0-9]$/.test(Dfax)) {
            let DfaxArray = [...Dfax];

            if (Dfax.length == 1) {
                this.setState(
                    { DrFax: "(" + DfaxArray[0] },
                    () =>
                    (this.refs.Dfax.selectionStart = this.refs.Dfax.selectionEnd =
                        curStart + 1)
                );
            }
            if (Dfax.length == 2) {
                this.setState(
                    { DrFax: "(" + DfaxArray[0] + DfaxArray[1] },
                    () =>
                        (this.refs.Dfax.selectionStart = this.refs.Dfax.selectionEnd = curStart)
                );
            } else if (Dfax.length == 3) {
                this.setState(
                    { DrFax: "(" + Dfax },
                    () =>
                        (this.refs.Dfax.selectionStart = this.refs.Dfax.selectionEnd = curStart)
                );
            } else if (Dfax.length == 4) {
                this.setState(
                    {
                        DrFax:
                            "(" +
                            DfaxArray[0] +
                            DfaxArray[1] +
                            DfaxArray[2] +
                            ") " +
                            DfaxArray[3],
                    },
                    () =>
                    (this.refs.Dfax.selectionStart = this.refs.Dfax.selectionEnd =
                        curStart + 2)
                );
            } else if (Dfax.length == 5) {
                this.setState(
                    {
                        DrFax:
                            "(" +
                            DfaxArray[0] +
                            DfaxArray[1] +
                            DfaxArray[2] +
                            ") " +
                            DfaxArray[3] +
                            DfaxArray[4],
                    },
                    () =>
                        (this.refs.Dfax.selectionStart = this.refs.Dfax.selectionEnd = curStart)
                );
            } else if (Dfax.length == 6) {
                this.setState(
                    {
                        DrFax:
                            "(" +
                            DfaxArray[0] +
                            DfaxArray[1] +
                            DfaxArray[2] +
                            ") " +
                            DfaxArray[3] +
                            DfaxArray[4] +
                            DfaxArray[5],
                    },
                    () =>
                        (this.refs.Dfax.selectionStart = this.refs.Dfax.selectionEnd = curStart)
                );
            } else if (Dfax.length == 7) {
                this.setState(
                    {
                        DrFax:
                            "(" +
                            DfaxArray[0] +
                            DfaxArray[1] +
                            DfaxArray[2] +
                            ") " +
                            DfaxArray[3] +
                            DfaxArray[4] +
                            DfaxArray[5] +
                            "-" +
                            DfaxArray[6],
                    },
                    () =>
                    (this.refs.Dfax.selectionStart = this.refs.Dfax.selectionEnd =
                        curStart + 1)
                );
            } else if (Dfax.length == 8) {
                this.setState(
                    {
                        DrFax:
                            "(" +
                            DfaxArray[0] +
                            DfaxArray[1] +
                            DfaxArray[2] +
                            ") " +
                            DfaxArray[3] +
                            DfaxArray[4] +
                            DfaxArray[5] +
                            "-" +
                            DfaxArray[6] +
                            DfaxArray[7],
                    },
                    () =>
                        (this.refs.Dfax.selectionStart = this.refs.Dfax.selectionEnd = curStart)
                );
            } else if (Dfax.length == 9) {
                this.setState(
                    {
                        DrFax:
                            "(" +
                            DfaxArray[0] +
                            DfaxArray[1] +
                            DfaxArray[2] +
                            ") " +
                            DfaxArray[3] +
                            DfaxArray[4] +
                            DfaxArray[5] +
                            "-" +
                            DfaxArray[6] +
                            DfaxArray[7] +
                            DfaxArray[8],
                    },
                    () =>
                        (this.refs.Dfax.selectionStart = this.refs.Dfax.selectionEnd = curStart)
                );
            } else if (Dfax.length == 10) {
                this.setState(
                    {
                        DrFax:
                            "(" +
                            DfaxArray[0] +
                            DfaxArray[1] +
                            DfaxArray[2] +
                            ") " +
                            DfaxArray[3] +
                            DfaxArray[4] +
                            DfaxArray[5] +
                            "-" +
                            DfaxArray[6] +
                            DfaxArray[7] +
                            DfaxArray[8] +
                            DfaxArray[9],
                    },
                    () =>
                        (this.refs.Dfax.selectionStart = this.refs.Dfax.selectionEnd = curStart)
                );
            }
        }
    }
    handleSpChange(e) {
        if (this.state.SpecialType == "") {
            this.setState({ SpecialType: "CustomRequest" });
        }
        if (this.testHyperLinks(e.target.value)) {
            this.setState({ SpecialInstruction: e.target.value, choseRadioxtext: false });
        }
        else {
            this.setState({ choseRadioxtext: true });
        }

    }
    handleRushChange(e) {
        this.setState({
            RushReq: !this.state.RushReq,
        });
    }
    handleSPTypeChange(e) {
        this.setState({ choseRadiox: false });
        this.setState({ choseRadioxtext: false });
        this.setState({ SpecialType: e.target.value });
        this.setState({ disableED: true });
        this.setState({ disableSD: true });
        this.setState({ doed: false, dosd: false });
        this.setState({ EndDate: "" });
        this.setState({ StartDate: "" });
        if (e.target.value == "Years_5") {
            this.setState({
                SpecialInstruction:
                    "Please provide the last 5 years of medical records to include, but not limited to, office notes, test results, labs, ekg's, prescriptions, etc.",
            });
        } else if (e.target.value == "Years_3") {
            this.setState({
                SpecialInstruction:
                    "Please provide the last 3 years of medical records to include, but not limited to, office notes, test results, labs, ekg's, prescriptions, etc.",
            });
        } else if (e.target.value == "CustomDate") {
            this.setState({ SpecialInstruction: "" });
            this.setState({ disableED: false });
            this.setState({ disableSD: false });
        } else {
            this.setState({ SpecialInstruction: "" });
        }
    }
    handleEDChange = (date) => {
        this.setState({ doed: false });
        this.setState({ SpecialType: "CustomDate" });
        this.setState({ SpecialInstruction: "" });
        var MinDate = new Date(new Date().getFullYear() - 110, 1, 1);
        var MaxDate = new Date(new Date().setHours(0, 0, 0, 0));
        this.setState({ minDate: MinDate, maxDate: MaxDate });
        var dt = new Date(new Date().setHours(0, 0, 0, 0));
        if (dt instanceof Date) {
            if (dt > MaxDate || dt < MinDate) {
                this.setState({ doed: true });
                document.getElementById("edate").value = "";
                this.setState({ EndDate: "" });
                this.setState({ choseRadioxtext: true });
            } else {
                this.setState({ EndDate: date });
                this.setState({ choseRadioxtext: false });
                if (this.state.StartDate instanceof Date) {
                    if (date >= new Date(this.state.StartDate)) {
                        this.setState({
                            SpecialInstruction:
                                "Please provide the medical records between " +
                                this.formatDateToString(this.state.StartDate) +
                                " to " +
                                this.formatDateToString(date) +
                                " to include, but not limited to, office notes, test results, labs, ekg's, prescriptions, etc.",
                        });
                    } else {
                        this.setState({ SpecialInstruction: "" });
                    }
                }
            }
        } else {
            this.setState({ EndDate: date });
            this.setState({ doed: true });
        }
    };
    handleSDChange = (date) => {        
        this.setState({ dosd: false });
        this.setState({ SpecialType: "CustomDate" });
        this.setState({ SpecialInstruction: "" });
        if (date instanceof Date) {
            if (date > this.state.maxDate || date < this.state.minDate) {
                this.setState({ dosd: true });
                document.getElementById("sdate").value = "";
                this.setState({ StartDate: "" });
                this.setState({ choseRadioxtext: true });
            } else {
                this.setState({ StartDate: date });
                var st = this.state.DOB
                var dt = new Date(this.state.DOB);
                this.setState({ choseRadioxtext: false });
                if (dt instanceof Date) {
                    if (dt > date) {
                        this.toasterredfun2("Special Instruction Custom Start Date is less than DOB.");
                        this.setState({ dosd: true });
                    }
                }
                else {
                    this.setState({ dosd: false });
                }
                if (this.state.EndDate instanceof Date) {
                    if (date <= new Date(this.state.EndDate)) {
                        this.setState({
                            SpecialInstruction:
                                "Please provide the medical records between " +
                                this.formatDateToString(date) +
                                " to " +
                                this.formatDateToString(this.state.EndDate) +
                                " to include, but not limited to, office notes, test results, labs, ekg's, prescriptions, etc.",
                        });
                    } else {
                        this.setState({ SpecialInstruction: "" });
                    }
                }
            }
        } else {
            this.setState({ StartDate: date });
            this.setState({ dosd: true });
        }
    };
    handleSDBlur(e) {
        if (this.state.StartDate instanceof Date) {
            if (this.state.EndDate instanceof Date) {
                if (this.state.StartDate <= new Date(this.state.EndDate)) {
                    var sdate =
                        parseInt(this.state.StartDate.getMonth() + 1) +
                        "/" +
                        this.state.StartDate.getDate() +
                        "/" +
                        this.state.StartDate.getFullYear(),
                        edate =
                            parseInt(this.state.EndDate.getMonth() + 1) +
                            "/" +
                            this.state.EndDate.getDate() +
                            "/" +
                            this.state.EndDate.getFullYear();
                    this.setState({
                        SpecialInstruction:
                            "Please provide the medical records between " +
                            sdate +
                            " to " +
                            edate +
                            " to include, but not limited to, office notes, test results, labs, ekg's, prescriptions, etc.",
                    });
                } else {
                    this.setState({ SpecialInstruction: "" });
                }
            }
        } else {
            this.setState({ dosd: true });
        }
    }
    handleEDBlur(e) {
        if (this.state.StartDate instanceof Date) {
            if (this.state.EndDate instanceof Date) {
                if (this.state.StartDate <= new Date(this.state.EndDate)) {
                    this.setState({
                        SpecialInstruction:
                            "Please provide the medical records between " +
                            this.formatDateToString(this.state.StartDate) +
                            " to " +
                            this.formatDateToString(this.state.EndDate) +
                            " to include, but not limited to, office notes, test results, labs, ekg's, prescriptions, etc.",
                    });
                } else {
                    this.setState({ SpecialInstruction: "" });
                }
            }
        } else {
            this.setState({ doed: true });
        }
    }
    async  handleblurDEmailAddressChange(e) {
        let currentComponent = this;
        e.persist()
        var placeOrder = await this.CheckForPlaceOrder();
        if (placeOrder)
        {
            if (e.target.value.length != 0) {
                currentComponent.setState({ Loading: true });
                var data = { Email: e.target.value.replace(/ /g, '') };
                Api.Post(
                    "services/api/IsValidEmailMailAddress/",
                    data,
                    currentComponent
                )
                    .then(function (result) {

                        currentComponent.setState({ pDrEmailAddress: !result });
                        currentComponent.setState({ Loading: false });

                    })
                    .catch(function (error) {
                        currentComponent.setState({ pDrEmailAddress: false });
                        currentComponent.setState({ Loading: false });
                        if (error == "TypeError: Failed to fetch") {
                            currentComponent.toasterredfun2("Check your internet connection.");
                        } else {
                            currentComponent.toasterredfun2(error);
                        }
                    });
            }
        }

    }
    async handleblurAEmailChange(e) {
        let currentComponent = this;
        e.persist()
        var placeOrder = await this.CheckForPlaceOrder();
        if (placeOrder)
        {
            if (e.target.value.length != 0) {
                currentComponent.setState({ Loading: true });
                var data = { Email: e.target.value.replace(/ /g, '') };
                Api.Post(
                    "services/api/IsValidEmailMailAddress/",
                    data,
                    currentComponent
                )
                    .then(function (result) {

                        currentComponent.setState({ pAgentEmail: !result });
                        currentComponent.setState({ Loading: false });

                    })
                    .catch(function (error) {
                        currentComponent.setState({ pAgentEmail: false });
                        currentComponent.setState({ Loading: false });
                        if (error == "TypeError: Failed to fetch") {
                            currentComponent.toasterredfun2("Check your internet connection.");
                        } else {
                            currentComponent.toasterredfun2(error);
                        }
                    });
            }
        }

    }
    async  handleblurCMEmailAddressChange(e) {
        let currentComponent = this;
        e.persist()
        var placeOrder = await this.CheckForPlaceOrder();
        if (placeOrder)
        {
            if (e.target.value.length != 0) {
                currentComponent.setState({ Loading: true });
                var data = { Email: e.target.value.replace(/ /g, '') };
                Api.Post(
                    "services/api/IsValidEmailMailAddress/",
                    data,
                    currentComponent
                )
                    .then(function (result) {

                        currentComponent.setState({ pCMEmailAddress: !result });
                        currentComponent.setState({ Loading: false });

                    })
                    .catch(function (error) {
                        currentComponent.setState({ pCMEmailAddress: false });
                        currentComponent.setState({ Loading: false });
                        if (error == "TypeError: Failed to fetch") {
                            currentComponent.toasterredfun2("Check your internet connection.");
                        } else {
                            currentComponent.toasterredfun2(error);
                        }
                    });
            }
        }

    }
    async handleblurAdEmailChange(e) {
        let currentComponent = this;
        e.persist()
        var placeOrder = await this.CheckForPlaceOrder();
        if (placeOrder)
        {
            if (e.target.value.length != 0) {
                currentComponent.setState({ Loading: true });
                var data = { Email: e.target.value.replace(/ /g, '') };
                Api.Post(
                    "services/api/IsValidEmailMailAddress/",
                    data,
                    currentComponent
                )
                    .then(function (result) {

                        currentComponent.setState({ pAdjusterEmail: !result });
                        currentComponent.setState({ Loading: false });

                    })
                    .catch(function (error) {
                        currentComponent.setState({ pAdjusterEmail: false });
                        currentComponent.setState({ Loading: false });
                        if (error == "TypeError: Failed to fetch") {
                            currentComponent.toasterredfun2("Check your internet connection.");
                        } else {
                            currentComponent.toasterredfun2(error);
                        }
                    });
            }
        }

    }
    testDate(e) {
        if (/^(1[0-2]|0[1-9])\/(3[01]|[12][0-9]|0[1-9])\/[0-9]{4}$/.test(e)) {
            return true;
        } else {
            return false;
        }
    }
    handleDatesOfServiceChange(e) {
        this.setState({ DatesOfService: e.target.value });
        this.setState({ csd: false, ced: false });
        this.setState({ CustomStartDate: "" });
        this.setState({ CustomEndDate: "" });

        if (e.target.value == "AllRecords") {
            if (this.state.MedicalSplInstruct == "Standard") {
                this.setState({ MedicalCustodianInstruction: "In electronic format, please provide any and all Medical records" });
            } if (this.state.BillingSplInstruct == "Standard") {
                this.setState({ BillingCustodianInstruction: "In electronic format, please provide any and all Billing records" });
            } if (this.state.FilmsSplInstruct == "Standard") {
                this.setState({ FilmsCustodianInstruction: "In electronic format, please provide any and all Films records" });
            } if (this.state.OtherSplInstruct == "Standard") {
                this.setState({ OtherCustodianInstruction: "In electronic format, please provide any and all Other records" });
            }
        }
        else if (e.target.value == "Custom") {
            if (this.state.MedicalSplInstruct == "Standard") {
                this.setState({ MedicalCustodianInstruction: "In electronic format, please provide any and all Medical records" });
            } if (this.state.BillingSplInstruct == "Standard") {
                this.setState({ BillingCustodianInstruction: "In electronic format, please provide any and all Billing records" });
            } if (this.state.FilmsSplInstruct == "Standard") {
                this.setState({ FilmsCustodianInstruction: "In electronic format, please provide any and all Films records" });
            } if (this.state.OtherSplInstruct == "Standard") {
                this.setState({ OtherCustodianInstruction: "In electronic format, please provide any and all Other records" });
            }
        }
    }
    handleCSDChange = (date) => {
        this.setState({ DatesOfService: "Custom" });
        if (date instanceof Date) {
            if (date.Date > this.state.maxDate.Date || date.Date < this.state.minDate.Date) {
                this.setState({ csd: true });
                document.getElementById("fdate").value = "";
                this.setState({ CustomStartDate: "" });
            } else {
                this.setState({ csd: false });
                this.setState({ CustomStartDate: date });
                if (this.state.DOB instanceof Date) {
                    if (this.state.DOB > date) {
                        this.toasterredfun2("Date of Service Start Date is less than DOB.");
                        this.setState({ csd: true });

                    }
                }
                else {
                    this.setState({ csd: false });
                }
                if (this.state.CustomEndDate instanceof Date) {
                    if (date <= new Date(this.state.CustomEndDate)) {
                        if (this.state.BillingSplInstruct == "Standard") {
                            this.setState({
                                BillingCustodianInstruction: "In electronic format, please provide any and all Billing records from " + this.formatDateToString(date) +
                                    " to " +
                                    this.formatDateToString(this.state.CustomEndDate)
                            });
                        } if (this.state.MedicalSplInstruct == "Standard") {
                            this.setState({
                                MedicalCustodianInstruction: "In electronic format, please provide any and all Medical records from " + this.formatDateToString(date) +
                                    " to " +
                                    this.formatDateToString(this.state.CustomEndDate)
                            });
                        } if (this.state.FilmsSplInstruct == "Standard") {
                            this.setState({
                                FilmsCustodianInstruction: "In electronic format, please provide any and all Films records from " + this.formatDateToString(date) +
                                    " to " +
                                    this.formatDateToString(this.state.CustomEndDate)
                            });
                        } if (this.state.OtherSplInstruct == "Standard") {
                            this.setState({
                                OtherCustodianInstruction: "In electronic format, please provide any and all Other records from " + this.formatDateToString(date) +
                                    " to " +
                                    this.formatDateToString(this.state.CustomEndDate)
                            });
                        }
                    } else {
                        this.setState({ SpecialInstruction: "" });
                    }
                }
            }
        } else {
            this.setState({ csd: true });
            this.setState({ CustomStartDate: date });
        }
    };
    handleCSDBlur(e) {
        if (!this.state.CustomStartDate instanceof Date) {
            this.setState({ csd: true });
        }
    }
    handleCEDChange = (date) => {
        this.setState({ DatesOfService: "Custom" });
        if (date instanceof Date) {
            if (date.Date > this.state.maxDate.Date || date.Date < this.state.minDate.Date) {
                this.setState({ ced: true });
                document.getElementById("tdate").value = "";
                this.setState({ CustomEndDate: "" });
            } else {
                this.setState({ ced: false });
                this.setState({ CustomEndDate: date });
                if (this.state.CustomStartDate instanceof Date) {
                    if (date >= new Date(this.state.CustomStartDate)) {
                        if (this.state.BillingSplInstruct == "Standard") {
                            this.setState({
                                BillingCustodianInstruction: "In electronic format, please provide any and all Billing records from " + this.formatDateToString(this.state.CustomStartDate) +
                                    " to " +
                                    this.formatDateToString(date)
                            });
                        } if (this.state.MedicalSplInstruct == "Standard") {
                            this.setState({
                                MedicalCustodianInstruction: "In electronic format, please provide any and all Medical records from " + this.formatDateToString(this.state.CustomStartDate) +
                                    " to " +
                                    this.formatDateToString(date)
                            });
                        } if (this.state.FilmsSplInstruct == "Standard") {
                            this.setState({
                                FilmsCustodianInstruction: "In electronic format, please provide any and all Films records from " + this.formatDateToString(this.state.CustomStartDate) +
                                    " to " +
                                    this.formatDateToString(date)
                            });
                        } if (this.state.OtherSplInstruct == "Standard") {
                            this.setState({
                                OtherCustodianInstruction: "In electronic format, please provide any and all Other records from " + this.formatDateToString(this.state.CustomStartDate) +
                                    " to " +
                                    this.formatDateToString(date)
                            });
                        }
                    } else {
                        this.setState({ SpecialInstruction: "" });
                    }
                }
            }
        } else {
            this.setState({ ced: true });
            this.setState({ CustomEndDate: date });
        }
    };
    handleCEDBlur(e) {
        if (!this.state.CustomEndDate instanceof Date) {
            this.setState({ ced: true });
        }
    }

    handleCMFNChange(e) {

        this.setState({ pCMFN: false });

        if (e.target.value.length != 0) {
            if (this.testAlphawithSpaceForName(e.target.value)) {
                if (this.testHyperLinks(e.target.value)) {
                    this.setState({ CMFN: e.target.value });
                }
                else {
                    this.setState({ pCMFN: true });
                }
            }
        } else {
            this.setState({ CMFN: e.target.value });
        }
    }
    handleCMLNChange(e) {

        this.setState({ pCMLN: false });

        if (e.target.value.length != 0) {
            if (this.testAlphawithSpaceForName(e.target.value)) {
                if (this.testHyperLinks(e.target.value)) {
                    this.setState({ CMLN: e.target.value });
                }
                else {
                    this.setState({ pCMLN: true });
                }

            }
        } else {
            this.setState({ CMLN: e.target.value });
        }
    }
    //handleCMEmailAddressChange(e) {

    //    this.setState({ pCMEmailAddress: false });

    //    if (e.target.value.length != 0) {
    //        if (this.testAlphaNumericwithSpaceForEmail(e.target.value)) {
    //            this.setState({ CMEmailAddress: e.target.value });
    //        }
    //    } else {
    //        this.setState({ CMEmailAddress: e.target.value });
    //    }
    //}

    testAlphaNumericwithSpaceForEmailNew(e) {

        if (/^[a-zA-Z0-9\,\#\!\%\$\'\&\+\*\-\/\=\?\^\_\`\.\{\|\}\~\@]+$/g.test(e)) {
            if (/\s{2,}/g.test(e) || /[}]{2,}/g.test(e) || /[|]{2,}/g.test(e) || /[=]{2,}/g.test(e) || /[,]{2,}/g.test(e) || /[\\]{2,}/g.test(e) || /[!]{2,}/g.test(e) || /[-]{2,}/g.test(e) || /[{]{2,}/g.test(e) || /[#]{2,}/g.test(e) || /[@]{2,}/g.test(e) || /[.]{2,}/g.test(e) || /[_]{2,}/g.test(e) || /[&]{2,}/g.test(e) || /[$]{2,}/g.test(e) || /[%]{2,}/g.test(e) || /[*]{2,}/g.test(e) || /[\^]{2,}/g.test(e)) {
                return false;
            }
            else {
                var str = e;
                var strlength = e.length;
                var a = str.indexOf(".");
                if (a == 0) {
                    return false;
                }
                var b = str.indexOf("#");
                if (b == 0) {
                    return false;
                }
                var c = str.indexOf("!");
                if (c == 0) {
                    return false;
                }
                var d = str.indexOf("%");
                if (d == 0) {
                    return false;
                }
                var e = str.indexOf("$");
                if (e == 0) {
                    return false;
                }
                var f = str.indexOf("`");
                if (f == 0) {
                    return false;
                }

                var h = str.indexOf("&");
                if (h == 0) {
                    return false;
                }

                var j = str.indexOf("+");
                if (j == 0) {
                    return false;
                }
                var k = str.indexOf("*");
                if (k == 0) {
                    return false;
                }
                var l = str.indexOf("/");
                if (l == 0) {
                    return false;
                }
                var m = str.indexOf("=");
                if (m == 0) {
                    return false;
                }
                var n = str.indexOf("?");
                if (n == 0) {
                    return false;
                }
                var o = str.indexOf("^");
                if (o == 0) {
                    return false;
                }
                var p = str.indexOf("{");
                if (p == 0) {
                    return false;
                }
                var q = str.indexOf("}");
                if (q == 0) {
                    return false;
                }
                var r = str.indexOf("|");
                if (r == 0) {
                    return false;
                }
                var s = str.indexOf("~");
                if (s == 0) {
                    return false;
                }

                var t = str.indexOf("$");
                if (t == 0) {
                    return false;
                }
                var u = str.indexOf(",");
                if (u == 0) {
                    return false;
                }
                var w = str.indexOf("_");
                if (w == 0) {
                    return false;
                }
                var x = str.indexOf("-");
                if (x == 0) {
                    return false;
                }
                var g = str.indexOf("@");
                if (g == 0) {
                    return false;
                }
                else if (g != 0) {
                    var i = str.indexOf("@") - 1;
                    var v = str.indexOf("@") + 1;
                    if (str.charAt(i) == "." || str.charAt(i) == "~" || str.charAt(i) == "," || str.charAt(i) == "#" || str.charAt(i) == "!" || str.charAt(i) == "%" || str.charAt(i) == "$" || str.charAt(i) == "'" || str.charAt(i) == "&" || str.charAt(i) == "+" || str.charAt(i) == "*" || str.charAt(i) == "-" || str.charAt(i) == "/" || str.charAt(i) == "?" || str.charAt(i) == "^" || str.charAt(i) == "_" || str.charAt(i) == "`" || str.charAt(i) == "{" || str.charAt(i) == "|" || str.charAt(i) == "}" || str.charAt(i) == "~" || str.charAt(v) == "." || str.charAt(v) == "," || str.charAt(v) == "#" || str.charAt(v) == "!" || str.charAt(v) == "%" || str.charAt(v) == "$" || str.charAt(v) == "'" || str.charAt(v) == "&" || str.charAt(v) == "+" || str.charAt(v) == "*" || str.charAt(v) == "-" || str.charAt(v) == "/" || str.charAt(v) == "?" || str.charAt(v) == "^" || str.charAt(v) == "_" || str.charAt(v) == "`" || str.charAt(v) == "{" || str.charAt(v) == "|" || str.charAt(v) == "}" || str.charAt(v) == "~") {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else {
                    return true;
                }
            }
        }
        else {
            return false;
        }
    }

    testEmailNew(e) {
        var strlength = e.length;
        var a = e.lastIndexOf(".");
        var b = e.lastIndexOf("#");
        var c = e.lastIndexOf("!");
        var d = e.lastIndexOf("%");
        var y = e.lastIndexOf("$");
        var f = e.lastIndexOf("`");
        var g = e.lastIndexOf("@");
        var h = e.lastIndexOf("&");
        var j = e.lastIndexOf("+");
        var k = e.lastIndexOf("*");
        var l = e.lastIndexOf("/");
        var m = e.lastIndexOf("=");
        var n = e.lastIndexOf("?");
        var o = e.lastIndexOf("^");
        var p = e.lastIndexOf("{");
        var q = e.lastIndexOf("}");
        var r = e.lastIndexOf("|");
        var s = e.lastIndexOf("~");
        var t = e.lastIndexOf("$");
        var u = e.lastIndexOf(",");
        var w = e.lastIndexOf("_");
        var x = e.lastIndexOf("-");
        var str = e;
        var eSubstring = e.substring(g + 1, e.length);
        var beforeatValue = e.substring(0, g - 1);
        if (eSubstring.length > 0) {
            var firstIndexofDot = eSubstring.indexOf(".");
            var lastIndexofDot = eSubstring.lastIndexOf(".");
            var aa = lastIndexofDot + 1;
            var bb = lastIndexofDot - 1;
            var cc = firstIndexofDot + 1;
            var dd = firstIndexofDot - 1;
            if (eSubstring.charAt(aa) == "." || eSubstring.charAt(aa) == "~" || eSubstring.charAt(aa) == "," || eSubstring.charAt(aa) == "#" || eSubstring.charAt(aa) == "!" || eSubstring.charAt(aa) == "%" || eSubstring.charAt(aa) == "$" || eSubstring.charAt(aa) == "'" || eSubstring.charAt(aa) == "&" || eSubstring.charAt(aa) == "+" || eSubstring.charAt(aa) == "*" || eSubstring.charAt(aa) == "-" || eSubstring.charAt(aa) == "/" || eSubstring.charAt(aa) == "?" || eSubstring.charAt(aa) == "^" || eSubstring.charAt(aa) == "_" || eSubstring.charAt(aa) == "`" || eSubstring.charAt(aa) == "{" || eSubstring.charAt(aa) == "|" || eSubstring.charAt(aa) == "}" || eSubstring.charAt(aa) == "~" || eSubstring.charAt(bb) == "." || eSubstring.charAt(bb) == "," || eSubstring.charAt(bb) == "#" || eSubstring.charAt(bb) == "!" || eSubstring.charAt(bb) == "%" || eSubstring.charAt(bb) == "$" || eSubstring.charAt(bb) == "'" || eSubstring.charAt(bb) == "&" || eSubstring.charAt(bb) == "+" || eSubstring.charAt(bb) == "*" || eSubstring.charAt(bb) == "-" || eSubstring.charAt(bb) == "/" || eSubstring.charAt(bb) == "?" || eSubstring.charAt(bb) == "^" || eSubstring.charAt(bb) == "_" || eSubstring.charAt(bb) == "`" || eSubstring.charAt(bb) == "{" || eSubstring.charAt(bb) == "|" || eSubstring.charAt(bb) == "}" || eSubstring.charAt(bb) == "~") {
                return false;
            }
            if (eSubstring.charAt(cc) == "." || eSubstring.charAt(cc) == "~" || eSubstring.charAt(cc) == "," || eSubstring.charAt(cc) == "#" || eSubstring.charAt(cc) == "!" || eSubstring.charAt(cc) == "%" || eSubstring.charAt(cc) == "$" || eSubstring.charAt(cc) == "'" || eSubstring.charAt(cc) == "&" || eSubstring.charAt(cc) == "+" || eSubstring.charAt(cc) == "*" || eSubstring.charAt(cc) == "-" || eSubstring.charAt(cc) == "/" || eSubstring.charAt(cc) == "?" || eSubstring.charAt(cc) == "^" || eSubstring.charAt(cc) == "_" || eSubstring.charAt(cc) == "`" || eSubstring.charAt(cc) == "{" || eSubstring.charAt(cc) == "|" || eSubstring.charAt(cc) == "}" || eSubstring.charAt(cc) == "~" || eSubstring.charAt(dd) == "." || eSubstring.charAt(dd) == "," || eSubstring.charAt(dd) == "#" || eSubstring.charAt(dd) == "!" || eSubstring.charAt(dd) == "%" || eSubstring.charAt(dd) == "$" || eSubstring.charAt(dd) == "'" || eSubstring.charAt(dd) == "&" || eSubstring.charAt(dd) == "+" || eSubstring.charAt(dd) == "*" || eSubstring.charAt(dd) == "-" || eSubstring.charAt(dd) == "/" || eSubstring.charAt(dd) == "?" || eSubstring.charAt(dd) == "^" || eSubstring.charAt(dd) == "_" || eSubstring.charAt(dd) == "`" || eSubstring.charAt(dd) == "{" || eSubstring.charAt(dd) == "|" || eSubstring.charAt(dd) == "}" || eSubstring.charAt(dd) == "~") {
                return false;
            }
        }
        var eSubstringonlyDomain = eSubstring.substring(0, firstIndexofDot);
        if (a == strlength - 1 || b == strlength - 1 || c == strlength - 1 || d == strlength - 1 || e == strlength - 1 || f == strlength - 1 || g == strlength - 1 || h == strlength - 1 || j == strlength - 1 || k == strlength - 1 || l == strlength - 1 || m == strlength - 1 || n == strlength - 1 || o == strlength - 1 || p == strlength - 1 || q == strlength - 1 || r == strlength - 1 || s == strlength - 1 || t == strlength - 1 || u == strlength - 1 || w == strlength - 1 || x == strlength - 1 || y == strlength - 1) {
            return false;
        }
        else if (eSubstringonlyDomain.length > 253 || beforeatValue.length > 60 || str.length > 320) {
            return false;
        }

        else {

            var eSubstring = e.substring(g, a);
            if (this.validationForSpecialchar(eSubstring)) {
                return true;    // Contains at least one special character or space
            }
            else {
                return false;
            }
        }
    }

    validationForSpecialchar(e) {
        // var regex = new RegExp("^[a-zA-Z0-9-_.]+$");
        var regex = new RegExp("^[@]{1,1}((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,253}))$");
        //var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        var str = e;
        if (regex.test(str)) {
            return true;
        }
        else {
            return false;
        }
    }

    handleCMEmailAddressChange(e) {
        this.setState({ pCMEmailAddress: false });
        this.setState({ CMEmailAddress: e.target.value.replace(/ /g, '') });
        /*this.setState({ pCMEmailAddress: false });
        if (e.target.value.length != 0) {
            if (this.testAlphaNumericwithSpaceForEmail(e.target.value)) {
                this.setState({ CMEmailAddress: e.target.value });
            }
        }
        else {
            this.setState({ CMEmailAddress: e.target.value });
        }*/
    }

    handleCMWorkPhoneChange(e) {

        this.setState({ pCMWorkPhone: false });
        this.agPhnChange2(e.target.value, e.target.selectionStart);
    }

    agPhnChange2 = (agPhnValue, curStart) => {
        var APhn2 = agPhnValue.replace(/[^0-9]+/g, "");
        if (APhn2.length == 0) {
            this.setState({ CMWorkPhone: "" });
        }
        if (/[0-9]$/.test(APhn2)) {
            let AphnArray2 = [...APhn2];
            if (APhn2.length == 1) {
                this.setState(
                    { CMWorkPhone: "(" + AphnArray2[0] },
                    () =>
                    (this.refs.APhn2.selectionStart = this.refs.APhn2.selectionEnd =
                        curStart + 1)
                );
            }
            if (APhn2.length == 2) {
                this.setState(
                    { CMWorkPhone: "(" + AphnArray2[0] + AphnArray2[1] },
                    () =>
                        (this.refs.APhn2.selectionStart = this.refs.APhn2.selectionEnd = curStart)
                );
            } else if (APhn2.length == 3) {
                this.setState(
                    { CMWorkPhone: "(" + APhn2 },
                    () =>
                        (this.refs.APhn2.selectionStart = this.refs.APhn2.selectionEnd = curStart)
                );
            } else if (APhn2.length == 4) {
                this.setState(
                    {
                        CMWorkPhone:
                            "(" +
                            AphnArray2[0] +
                            AphnArray2[1] +
                            AphnArray2[2] +
                            ") " +
                            AphnArray2[3],
                    },
                    () =>
                    (this.refs.APhn2.selectionStart = this.refs.APhn2.selectionEnd =
                        curStart + 2)
                );
            } else if (APhn2.length == 5) {
                this.setState(
                    {
                        CMWorkPhone:
                            "(" +
                            AphnArray2[0] +
                            AphnArray2[1] +
                            AphnArray2[2] +
                            ") " +
                            AphnArray2[3] +
                            AphnArray2[4],
                    },
                    () =>
                        (this.refs.APhn2.selectionStart = this.refs.APhn2.selectionEnd = curStart)
                );
            } else if (APhn2.length == 6) {
                this.setState(
                    {
                        CMWorkPhone:
                            "(" +
                            AphnArray2[0] +
                            AphnArray2[1] +
                            AphnArray2[2] +
                            ") " +
                            AphnArray2[3] +
                            AphnArray2[4] +
                            AphnArray2[5],
                    },
                    () =>
                        (this.refs.APhn2.selectionStart = this.refs.APhn2.selectionEnd = curStart)
                );
            } else if (APhn2.length == 7) {
                this.setState(
                    {
                        CMWorkPhone:
                            "(" +
                            AphnArray2[0] +
                            AphnArray2[1] +
                            AphnArray2[2] +
                            ") " +
                            AphnArray2[3] +
                            AphnArray2[4] +
                            AphnArray2[5] +
                            "-" +
                            AphnArray2[6],
                    },
                    () =>
                    (this.refs.APhn2.selectionStart = this.refs.APhn2.selectionEnd =
                        curStart + 1)
                );
            } else if (APhn2.length == 8) {
                this.setState(
                    {
                        CMWorkPhone:
                            "(" +
                            AphnArray2[0] +
                            AphnArray2[1] +
                            AphnArray2[2] +
                            ") " +
                            AphnArray2[3] +
                            AphnArray2[4] +
                            AphnArray2[5] +
                            "-" +
                            AphnArray2[6] +
                            AphnArray2[7],
                    },
                    () =>
                        (this.refs.APhn2.selectionStart = this.refs.APhn2.selectionEnd = curStart)
                );
            } else if (APhn2.length == 9) {
                this.setState(
                    {
                        CMWorkPhone:
                            "(" +
                            AphnArray2[0] +
                            AphnArray2[1] +
                            AphnArray2[2] +
                            ") " +
                            AphnArray2[3] +
                            AphnArray2[4] +
                            AphnArray2[5] +
                            "-" +
                            AphnArray2[6] +
                            AphnArray2[7] +
                            AphnArray2[8],
                    },
                    () =>
                        (this.refs.APhn2.selectionStart = this.refs.APhn2.selectionEnd = curStart)
                );
            } else if (APhn2.length == 10) {
                this.setState(
                    {
                        CMWorkPhone:
                            "(" +
                            AphnArray2[0] +
                            AphnArray2[1] +
                            AphnArray2[2] +
                            ") " +
                            AphnArray2[3] +
                            AphnArray2[4] +
                            AphnArray2[5] +
                            "-" +
                            AphnArray2[6] +
                            AphnArray2[7] +
                            AphnArray2[8] +
                            AphnArray2[9],
                    },
                    () =>
                        (this.refs.APhn2.selectionStart = this.refs.APhn2.selectionEnd = curStart)
                );
            }
        }
    }

    handleCMExtChange(e) {

        this.setState({ pCMExt: false });

        if (e.target.value.length != 0) {
            if (this.testEXT(e.target.value)) {
                this.setState({ CMExt: e.target.value });
            }
        } else {
            this.setState({ CMExt: e.target.value });
        }
    }
    handleCMCellPhoneChange(e) {

        this.setState({ pCMCellPhone: false });

        var Cmob = e.target.value.replace(/[^0-9]+/g, "");
        var curStart = e.target.selectionStart;
        if (Cmob.length == 0) {
            this.setState({ CMCellPhone: "" });
        }
        if (/[0-9]$/.test(Cmob)) {
            let CmobArray = [...Cmob];
            if (Cmob.length == 1) {
                this.setState(
                    { CMCellPhone: "(" + CmobArray[0] },
                    () =>
                    (this.refs.Cmob.selectionStart = this.refs.Cmob.selectionEnd =
                        curStart + 1)
                );
            }
            if (Cmob.length == 2) {
                this.setState(
                    { CMCellPhone: "(" + CmobArray[0] + CmobArray[1] },
                    () =>
                        (this.refs.Cmob.selectionStart = this.refs.Cmob.selectionEnd = curStart)
                );
            } else if (Cmob.length == 3) {
                this.setState(
                    { CMCellPhone: "(" + Cmob },
                    () =>
                        (this.refs.Cmob.selectionStart = this.refs.Cmob.selectionEnd = curStart)
                );
            } else if (Cmob.length == 4) {
                this.setState(
                    {
                        CMCellPhone:
                            "(" +
                            CmobArray[0] +
                            CmobArray[1] +
                            CmobArray[2] +
                            ") " +
                            CmobArray[3],
                    },
                    () =>
                    (this.refs.Cmob.selectionStart = this.refs.Cmob.selectionEnd =
                        curStart + 2)
                );
            } else if (Cmob.length == 5) {
                this.setState(
                    {
                        CMCellPhone:
                            "(" +
                            CmobArray[0] +
                            CmobArray[1] +
                            CmobArray[2] +
                            ") " +
                            CmobArray[3] +
                            CmobArray[4],
                    },
                    () =>
                        (this.refs.Cmob.selectionStart = this.refs.Cmob.selectionEnd = curStart)
                );
            } else if (Cmob.length == 6) {
                this.setState(
                    {
                        CMCellPhone:
                            "(" +
                            CmobArray[0] +
                            CmobArray[1] +
                            CmobArray[2] +
                            ") " +
                            CmobArray[3] +
                            CmobArray[4] +
                            CmobArray[5],
                    },
                    () =>
                        (this.refs.Cmob.selectionStart = this.refs.Cmob.selectionEnd = curStart)
                );
            } else if (Cmob.length == 7) {
                this.setState(
                    {
                        CMCellPhone:
                            "(" +
                            CmobArray[0] +
                            CmobArray[1] +
                            CmobArray[2] +
                            ") " +
                            CmobArray[3] +
                            CmobArray[4] +
                            CmobArray[5] +
                            "-" +
                            CmobArray[6],
                    },
                    () =>
                    (this.refs.Cmob.selectionStart = this.refs.Cmob.selectionEnd =
                        curStart + 1)
                );
            } else if (Cmob.length == 8) {
                this.setState(
                    {
                        CMCellPhone:
                            "(" +
                            CmobArray[0] +
                            CmobArray[1] +
                            CmobArray[2] +
                            ") " +
                            CmobArray[3] +
                            CmobArray[4] +
                            CmobArray[5] +
                            "-" +
                            CmobArray[6] +
                            CmobArray[7],
                    },
                    () =>
                        (this.refs.Cmob.selectionStart = this.refs.Cmob.selectionEnd = curStart)
                );
            } else if (Cmob.length == 9) {
                this.setState(
                    {
                        CMCellPhone:
                            "(" +
                            CmobArray[0] +
                            CmobArray[1] +
                            CmobArray[2] +
                            ") " +
                            CmobArray[3] +
                            CmobArray[4] +
                            CmobArray[5] +
                            "-" +
                            CmobArray[6] +
                            CmobArray[7] +
                            CmobArray[8],
                    },
                    () =>
                        (this.refs.Cmob.selectionStart = this.refs.Cmob.selectionEnd = curStart)
                );
            } else if (Cmob.length == 10) {
                this.setState(
                    {
                        CMCellPhone:
                            "(" +
                            CmobArray[0] +
                            CmobArray[1] +
                            CmobArray[2] +
                            ") " +
                            CmobArray[3] +
                            CmobArray[4] +
                            CmobArray[5] +
                            "-" +
                            CmobArray[6] +
                            CmobArray[7] +
                            CmobArray[8] +
                            CmobArray[9],
                    },
                    () =>
                        (this.refs.Cmob.selectionStart = this.refs.Cmob.selectionEnd = curStart)
                );
            }
        }
    }
    handleCMFaxChange(e) {

        this.setState({ pCMFax: false });

        var CMfax = e.target.value.replace(/[^0-9]+/g, "");
        var curStart = e.target.selectionStart;
        if (CMfax.length == 0) {
            this.setState({ CMFax: "" });
        }
        if (/[0-9]$/.test(CMfax)) {
            let CMfaxArray = [...CMfax];
            if (CMfax.length == 1) {
                this.setState(
                    { CMFax: "(" + CMfaxArray[0] },
                    () =>
                    (this.refs.CMfax.selectionStart = this.refs.CMfax.selectionEnd =
                        curStart + 1)
                );
            }
            if (CMfax.length == 2) {
                this.setState(
                    { CMFax: "(" + CMfaxArray[0] + CMfaxArray[1] },
                    () =>
                        (this.refs.CMfax.selectionStart = this.refs.CMfax.selectionEnd = curStart)
                );
            } else if (CMfax.length == 3) {
                this.setState(
                    { CMFax: "(" + CMfax },
                    () =>
                        (this.refs.CMfax.selectionStart = this.refs.CMfax.selectionEnd = curStart)
                );
            } else if (CMfax.length == 4) {
                this.setState(
                    {
                        CMFax:
                            "(" +
                            CMfaxArray[0] +
                            CMfaxArray[1] +
                            CMfaxArray[2] +
                            ") " +
                            CMfaxArray[3],
                    },
                    () =>
                    (this.refs.CMfax.selectionStart = this.refs.CMfax.selectionEnd =
                        curStart + 2)
                );
            } else if (CMfax.length == 5) {
                this.setState(
                    {
                        CMFax:
                            "(" +
                            CMfaxArray[0] +
                            CMfaxArray[1] +
                            CMfaxArray[2] +
                            ") " +
                            CMfaxArray[3] +
                            CMfaxArray[4],
                    },
                    () =>
                        (this.refs.CMfax.selectionStart = this.refs.CMfax.selectionEnd = curStart)
                );
            } else if (CMfax.length == 6) {
                this.setState(
                    {
                        CMFax:
                            "(" +
                            CMfaxArray[0] +
                            CMfaxArray[1] +
                            CMfaxArray[2] +
                            ") " +
                            CMfaxArray[3] +
                            CMfaxArray[4] +
                            CMfaxArray[5],
                    },
                    () =>
                        (this.refs.CMfax.selectionStart = this.refs.CMfax.selectionEnd = curStart)
                );
            } else if (CMfax.length == 7) {
                this.setState(
                    {
                        CMFax:
                            "(" +
                            CMfaxArray[0] +
                            CMfaxArray[1] +
                            CMfaxArray[2] +
                            ") " +
                            CMfaxArray[3] +
                            CMfaxArray[4] +
                            CMfaxArray[5] +
                            "-" +
                            CMfaxArray[6],
                    },
                    () =>
                    (this.refs.CMfax.selectionStart = this.refs.CMfax.selectionEnd =
                        curStart + 1)
                );
            } else if (CMfax.length == 8) {
                this.setState(
                    {
                        CMFax:
                            "(" +
                            CMfaxArray[0] +
                            CMfaxArray[1] +
                            CMfaxArray[2] +
                            ") " +
                            CMfaxArray[3] +
                            CMfaxArray[4] +
                            CMfaxArray[5] +
                            "-" +
                            CMfaxArray[6] +
                            CMfaxArray[7],
                    },
                    () =>
                        (this.refs.CMfax.selectionStart = this.refs.CMfax.selectionEnd = curStart)
                );
            } else if (CMfax.length == 9) {
                this.setState(
                    {
                        CMFax:
                            "(" +
                            CMfaxArray[0] +
                            CMfaxArray[1] +
                            CMfaxArray[2] +
                            ") " +
                            CMfaxArray[3] +
                            CMfaxArray[4] +
                            CMfaxArray[5] +
                            "-" +
                            CMfaxArray[6] +
                            CMfaxArray[7] +
                            CMfaxArray[8],
                    },
                    () =>
                        (this.refs.CMfax.selectionStart = this.refs.CMfax.selectionEnd = curStart)
                );
            } else if (CMfax.length == 10) {
                this.setState(
                    {
                        CMFax:
                            "(" +
                            CMfaxArray[0] +
                            CMfaxArray[1] +
                            CMfaxArray[2] +
                            ") " +
                            CMfaxArray[3] +
                            CMfaxArray[4] +
                            CMfaxArray[5] +
                            "-" +
                            CMfaxArray[6] +
                            CMfaxArray[7] +
                            CMfaxArray[8] +
                            CMfaxArray[9],
                    },
                    () =>
                        (this.refs.CMfax.selectionStart = this.refs.CMfax.selectionEnd = curStart)
                );
            }
        }
    }

    handleCheck(val) {        
        return this.state.SupportedFileTypes.some(item => val.toLowerCase() === item);
    }

    DocChangeHandler(event) {       
        let currentComp = this, isDocAcc = false;
        currentComp.state.CurrentDocItem = null;
        let extName = event.target.files[0].name.split('.').pop();
        let doubleext = event.target.files[0].name.split('.').slice(1, -1);
        let isdoubleext = false;
        
        doubleext.map((ext) => {
            
            if (this.state.AllFileTypes.some(item => ext.toLowerCase() === item)) {
                isdoubleext = true;
            }
        });

        if (isdoubleext) {
            this.toasterredfun2("Multiple extension files are not supported");
            return;
        }
        if (!this.handleCheck(extName)) {
            this.toasterredfun2("eNoah supports PDF, TIFF, TIF, JPG and JPEG formats for authorizations");
            return;
        }

        if (event.target.files[0].size > this.state.SupportedFileSize) {
            this.toasterredfun2("Maximum file size to upload is 1GB.");
            this.setState({ Loading: false });
        }
        else {
            this.setState({ Loading: true });            
            var f = new FormData();
            f.append("CompanyCode", localStorage.getItem("Companycode"));
            f.append("File", event.target.files[0]);
            f.append("IsFromPlaceOrderPage", true);

            currentComp.state.CurrentDocItem = null;
            let docName = event.target.files[0].name;
            let split = docName.split('.');
            //let extension = split[split.length - 1];
            let extension = "pdf";
            let docNameWithoutExtesntion = split[0].replace(/[&\/\\#,+()$~%'":;*?<>{} !@^`\[\]=+]/g, "") + "." + extension;
            docName = docName.replace(/[&\/\\#,+()$~%'":;*?<>{} !@^`\[\]=+]/g, "");

            var ispresent = false;
            currentComp.state.Files.map((doc, index) => {
                if (doc.FileName == docNameWithoutExtesntion && (doc.MimeType.toLowerCase() == "pdf" || doc.MimeType.toLowerCase() == "tif" || doc.MimeType.toLowerCase() == "tiff" || doc.MimeType.toLowerCase() == "jpg" || doc.MimeType.toLowerCase() == "jpeg")) {
                    ispresent = true;
                }
            });
            if (ispresent) {
                currentComp.toasterredfun2("File is already added with same name");
                currentComp.setState({ Loading: false });
            }
            else {
                currentComp.setState({ Loading: true });
                let euid = localStorage.getItem("Euid");
                axios.post("api/placeorder/UploadDoc", f, {
                    headers: { 'Content-Type': 'multipart/form-data', "Companycode": localStorage.getItem("Companycode"), "Euid": euid }
                }).then((result) => {
                    currentComp.setState({ Loading: true });
                    if (result.data.status == 401) {
                        currentComp.props.history.push('/');
                    }
                    else if (result.data.statusMessage == "Success") {
                        currentComp.setState({ Files: [...currentComp.state.Files, { DownloadString: result.data.filePath, FileName: docNameWithoutExtesntion, MimeType: extension, id: 2 }] });
                    }
                    else {
                        currentComp.toasterredfun2(result.data.statusMessage);
                    }
                    currentComp.setState({ Loading: false });
                    document.getElementById("customFile1").value = "";
                    currentComp.setState({ DocName: '' });
                    currentComp.setState({ CurrentDocName: '' });
                }).catch((ex) => {
                    currentComp.setState({ Loading: false });
                    if (ex && ex.response && ex.response.status === 403) {
                        currentComp.toasterredfun2("File upload was terminated due to a potentially harmful file");
                    } else {
                        currentComp.toasterredfun2("Failed uploading, please check your internet connectivity and try again");
                    }
                    document.getElementById("customFile1").value = "";
                    currentComp.setState({ DocName: '' });
                    currentComp.setState({ CurrentDocName: '' });
                });
            }
        }
    }
    renderDocTable() {

        if (this.state.Files.length != 0) {
            return (
                <div className="table-responsive tableHeight">
                    <table
                        className="table dataTable table-bordered"
                        id="dataTableOne"
                        width="100%"
                        cellSpacing="0"
                    >
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Doc. Type</th>
                                <th>Doc. Name</th>
                                <th width="180px">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.Files.map((doc, index) => {
                                var filename = "",
                                    ext = doc.FileName.split(".")[
                                        doc.FileName.split(".").length - 1
                                    ];
                                if (doc.FileName.length < 15) {
                                    filename = doc.FileName;
                                } else {
                                    filename = doc.FileName.substring(0, 10) + "..." + ext;
                                }
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>Auth. Form</td>
                                        <td>{filename}</td>
                                        <td>
                                            <span
                                                className="edit-btnm editActionE"
                                                onClick={() => this.onClickopenEditPopup(index)}
                                            >
                                                <img
                                                    src="img/edit-icon.png"
                                                    disabled={doc.DocEdit ? true : false}
                                                />{" "}
                                                View
                                            </span>
                                            <span
                                                className="edit-btnm"
                                                onClick={() => this.onhandeditevent(index, "upload")}
                                            >
                                                <img
                                                    src="img/delete.png"
                                                    disabled={doc.DocDelete ? true : false}
                                                />{" "}
                                                Delete
                                            </span>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            );
        }
    }
    renderDoctorTable() {
        if (this.state.DocList.length != 0) {
            return (
                <div className={"card mb-1 col-md-12 "}>
                    <div>
                        <div className="table-responsive tableHeight-Add">
                            <table
                                className="table dataTable table-bordered"
                                id="dataTableTwo"
                                width="100%"
                                cellSpacing="0"
                            >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>
                                            {this.state.HideLegalDiv
                                                ? "Custodian/Facility Name"
                                                : "Facility Name"}
                                        </th>
                                        <th>
                                            {this.state.HideLegalDiv
                                                ? "Provider Name"
                                                : "Doctor Name"}
                                        </th>
                                        <th>Email Address</th>
                                        <th>Address</th>
                                        <th>City</th>
                                        <th>State</th>
                                        <th>Zip</th>
                                        <th className={this.state.HideLegalDiv ? "show" : "hide"}>
                                            Request Type
                                        </th>
                                        <th width="180px">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.DocList.map((doc, index) => {
                                        let providerName = "";
                                        if (
                                            doc.DrFacilityName.length != 0 &&
                                            doc.DrFirstName.length == 0 &&
                                            doc.DrLastName.length == 0
                                        ) {
                                            providerName = doc.FacilityName;
                                        } else if (
                                            doc.DrFirstName.length != 0 &&
                                            doc.DrLastName.length != 0
                                        ) {
                                            providerName = "Dr." + doc.DrFirstName;
                                        }
                                        if (doc.DocEdit) {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{doc.DrFacilityName}</td>
                                                    <td>{providerName}</td>
                                                    <td>{doc.DrEmailAddress}</td>
                                                    <td>{doc.DrAddress}</td>
                                                    <td>{doc.DrCity}</td>
                                                    <td>{doc.DrState}</td>
                                                    <td>{doc.DrZipCode}</td>
                                                    <td
                                                        className={
                                                            this.state.HideLegalDiv ? "show" : "hide"
                                                        }
                                                    >
                                                        {doc.FacilityRequests && doc.FacilityRequests.length > 0 ? doc.FacilityRequests[0].RequestType : ""}
                                                    </td>
                                                    <td>
                                                        <span
                                                            className="edit-btnm editActionE"
                                                            onClick={(e) => {
                                                                this.editDoctor(index);
                                                            }}
                                                        >
                                                            <img src="img/edit-icon.png" /> Edit
                                                        </span>
                                                        <span
                                                            className="edit-btnm"
                                                            onClick={() =>
                                                                this.onhandeditevent(index, "additional")
                                                            }
                                                        >
                                                            <img src="img/delete.png" /> Delete
                                                        </span>
                                                    </td>
                                                </tr>
                                            );
                                        } else {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{doc.DrFacilityName}</td>
                                                    <td>{providerName}</td>
                                                    <td>{doc.DrEmailAddress}</td>
                                                    <td>{doc.DrAddress}</td>
                                                    <td>{doc.DrCity}</td>
                                                    <td>{doc.DrState}</td>
                                                    <td>{doc.DrZipCode}</td>
                                                    <td
                                                        className={
                                                            this.state.HideLegalDiv ? "show" : "hide"
                                                        }
                                                    >
                                                        {doc.FacilityRequests && doc.FacilityRequests.length > 0 ? doc.FacilityRequests[0].requestType : ""}
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            );
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
        }
    }
    editDoctor(e) {
        let current = this;
        let CompanyType = localStorage.getItem("CompanyType");
        current.setState({ addvalid: true });
        current.state.addvalid = true;
        if (
            this.state.FacilityName.length != 0 ||
            this.state.DoctorFirstName.length != 0 ||
            this.state.DoctorLastName.length != 0 ||
            this.state.DoctorCity.length != 0 ||
            this.state.SpecialInstruction != "" ||
            this.state.DrZipCode.length != 0 ||
            this.state.DrFax.length != 0 ||
            this.state.SpecialType != "" ||
            this.state.Phone.length != 0 ||
            this.state.DocList.length == 0 ||
            this.state.Files.length != 0 ||
            this.state.RushReq == true ||
            this.state.DrAddress.length != 0 ||
            this.state.DrEmailAddress.length != 0 ||
            this.state.DrState.length != 0
        ) {
            if (current.state.Phone.length != 0) {
                if (!current.testPhone(current.state.Phone)) {
                    current.state.addvalid = false;
                    current.state.pPhone = true;
                    current.setState({ pPhone: true, addvalid: false });
                }
            } else {
                current.state.addvalid = false;
                current.state.pPhone = true;
                current.setState({ pPhone: true, addvalid: false });
            }
            if (current.state.DrEmailAddress != null) {
                if (current.state.DrEmailAddress.trim().length != 0) {
                    if (current.state.pDrEmailAddress) {
                        current.setState({ pDrEmailAddress: true });
                        current.state.addvalid = false;
                    }
                }
            }
            if (this.state.isMandatoryDrAddress) {
                if (current.state.DrAddress.trim().length == 0) {
                    current.setState({ pDrAddress: true });
                    current.state.addvalid = false;
                    current.state.pDrAddress = true;
                }
            }
            if (current.state.DrFax.length != 0) {
                if (!current.testPhone(current.state.DrFax)) {
                    current.state.addvalid = false;
                    current.state.pDrFax = true;
                    current.setState({ pDrFax: true, addvalid: false });
                }
            }

            if (current.state.SpecialType == "CustomDate") {
                if (
                    current.state.StartDate == "" ||
                    current.state.StartDate == "Invalid date"
                ) {
                    current.setState({ dosd: true, addvalid: false });
                    current.state.addvalid = false;
                    current.state.dosd = false;
                }
                if (
                    current.state.EndDate == "" ||
                    current.state.EndDate == "Invalid date"
                ) {
                    current.setState({ doed: true, addvalid: false });
                    current.state.addvalid = false;
                    current.state.doed = false;
                }
                if (
                    current.state.StartDate instanceof Date &&
                    current.state.EndDate instanceof Date &&
                    new Date(current.state.StartDate) <=
                    new Date(current.state.EndDate)
                ) {
                } else {
                    current.setState({ dosd: true, addvalid: false });
                    current.state.addvalid = false;
                    current.state.dosd = false;
                }

                if (new Date(current.state.StartDate) instanceof Date) {
                    if (current.state.DOB.length != 0 && current.state.DOB instanceof Date) {
                        if (new Date(current.state.StartDate) < current.state.DOB) {
                            current.state.addvalid = false;
                            current.state.dosd = true;
                        }
                    }
                }
            }
            if (current.state.SpecialType != "") {
                if (current.state.SpecialInstruction == "") {
                    current.setState({ choseRadioxtext: true, addvalid: false });
                    current.state.addvalid = false;
                    current.state.choseRadioxtext = false;
                }
            }

            if (current.state.DoctorCity.length == 0) {
                current.setState({ pDoctorCity: true });
                current.setState({ addvalid: false });
                current.state.addvalid = false;
                current.state.pDoctorCity = false;
            }

            if (
                current.state.DrZipCode.length == 0 ||
                current.state.DrZipCode.length < 5
            ) {
                current.setState({ pDrZipCode: true });
                current.setState({ addvalid: false });
                current.state.addvalid = false;
                current.state.pDrZipCode = false;
            } else if (current.state.DrZipCode.length > 5) {
                if (current.state.DrZipCode.length != 10) {
                    current.setState({ pDrZipCode: true });
                    current.setState({ addvalid: false });
                    current.state.addvalid = false;
                    current.state.pDrZipCode = false;
                }
            }
            if (
                current.state.DrState.length == 0 ||
                current.state.DrState == "Select" ||
                current.state.DrState == "0"
            ) {
                current.setState({ pDrStateId: true });
                current.setState({ addvalid: false });
                current.state.addvalid = false;
            }
            if (
                current.state.FacilityName.length == 0 &&
                current.state.DoctorFirstName.length == 0 &&
                current.state.DoctorLastName.length == 0
            ) {
                current.setState({ pDoctorFirstName: true });
                current.setState({ pFacilityName: true });
                current.setState({ pDoctorLastName: true });
                current.setState({ addvalid: false });
                current.state.addvalid = false;
                current.state.pDoctorLastName = false;
                current.state.pDoctorFirstName = false;
                current.state.pFacilityName = false;
            }
            if (
                current.state.DoctorFirstName.length != 0 &&
                current.state.DoctorLastName.length == 0
            ) {
                current.setState({ pDoctorLastName: true });
                current.setState({ addvalid: false });
                current.state.addvalid = false;
                current.state.pDoctorLastName = false;
            }
            if (
                current.state.DoctorFirstName.length == 0 &&
                current.state.DoctorLastName.length != 0
            ) {
                current.setState({ pDoctorFirstName: true });
                current.setState({ addvalid: false });
                current.state.addvalid = false;
                current.state.pDoctorFirstName = false;
            }
            if (current.state.Files.length == 0) {
                current.setState({ Files: "" });
            }
            if (CompanyType == "LEGAL") {
                if (current.state.DatesOfService == "" || current.state.DatesOfService.length == 0) {
                    current.state.addvalid = false;
                    this.toasterredfun2("Dates of service is required");
                } else if (current.state.DatesOfService == "Custom") {
                    if (
                        current.state.CustomStartDate == "" ||
                        current.state.CustomStartDate == "Invalid date"
                    ) {
                        current.setState({ csd: true, addvalid: false });
                        current.state.addvalid = false;
                        current.state.csd = false;
                    }
                    if (
                        current.state.CustomEndDate == "" ||
                        current.state.CustomEndDate == "Invalid date"
                    ) {
                        current.setState({ ced: true, addvalid: false });
                        current.state.addvalid = false;
                        current.state.ced = false;
                    }
                    if (
                        current.state.CustomStartDate instanceof Date &&
                        current.state.CustomEndDate instanceof Date &&
                        new Date(current.state.CustomStartDate) <=
                        new Date(current.state.CustomEndDate)
                    ) {
                    } else {
                        current.setState({ csd: true, addvalid: false });
                        current.state.addvalid = false;
                        current.state.csd = false;
                    }
                    if (new Date(current.state.CustomStartDate) instanceof Date) {
                        if (current.state.DOB.length != 0 && current.state.DOB instanceof Date) {
                            if (new Date(current.state.CustomStartDate) < current.state.DOB) {
                                current.state.addvalid = false;
                                current.state.csd = true;
                            }
                        }
                    }
                }
                if (current.state.addvalid && current.state.RequestTypes.length == 0) {
                    current.state.addvalid = false;
                    this.toasterredfun2("Atleast one request type is required");
                }
            }

            if (current.state.addvalid) {
                if (current.state.Files.length == 0) {
                    current.setState({ Files: "" });
                }
                var customerProvidedFacilityEdit = "";
                if (current.state.DoctorFirstName) {
                    customerProvidedFacilityEdit += "Dr " + current.state.DoctorFirstName;
                }
                if (current.state.DoctorLastName) {
                    customerProvidedFacilityEdit += current.state.DoctorFirstName ? " " + current.state.DoctorLastName : "Dr " + current.state.DoctorLastName;
                }
                customerProvidedFacilityEdit += ", " + current.state.FacilityName + ", " + current.state.DrAddress + ", " + current.state.DoctorCity + ", " + current.state.DrState + ", " + current.state.DrZipCode + ", " + (current.state.Phone ? "Ph: " + current.state.Phone : "");
                current.setState({
                    DocList: [
                        ...current.state.DocList,
                        {
                            DrFacilityName: current.state.FacilityName,
                            DrFirstName: current.state.DoctorFirstName,
                            DrLastName: current.state.DoctorLastName,
                            DrEmailAddress: current.state.DrEmailAddress,
                            DrAddress: current.state.DrAddress,
                            DrCity: current.state.DoctorCity,
                            DrState: current.state.DrState,
                            Id: parseInt(
                                current.state.FacilityId ? current.state.FacilityId : 0
                            ),
                            isNew: current.state.isNew,
                            DrStateId: parseInt(current.state.DrStateId),
                            DrZipCode: current.state.DrZipCode,
                            DrPhone: current.state.Phone,
                            DrFax: current.state.DrFax,
                            RushRequest: current.state.RushReq,
                            AuthDocs: current.state.Files,
                            SpecialInstruction: current.state.SpecialInstruction,
                            SpecialType: current.state.SpecialType,
                            StartDate: current.formatDateToString(current.state.StartDate),
                            EndDate: current.formatDateToString(current.state.EndDate),
                            DateOfServiceType: current.state.DatesOfService,
                            DocEdit: true,
                            DocDelete: true,
                            OrderFacilityId: current.state.OrderFacilityId,
                            FacilityRequests: current.state.RequestTypes,
                            IsMissingFacilityDetails: current.state.IsMissingFacilityDetails,
                            CustomerProvidedFacility: customerProvidedFacilityEdit
                        },
                    ],
                });
            }
        }
            if (this.state.addvalid) {
                this.state.pDoctorLastName = false;
                this.state.pDoctorFirstName = false;
                this.state.pFacilityName = false;
                this.state.pDrStateId = false;
                this.state.pDoctorCity = false;
                this.state.pDrZipCode = false;
                this.state.pDrFax = false;
                this.state.pPhone = false;
                this.state.pDrAddress = false;
                this.state.csd = false;
                this.state.ced = false;
                setTimeout(() => {
                    let doctor = this.state.DocList.map((item, i) => {
                        if (i == e) {
                            return item;
                        }
                    }).filter(function (e) {
                        return e;
                    })[0];

                    const list = this.state.DocList;
                    list.splice(e, 1);

                    this.setState({ DocList: list });
                    this.setState({ DrEmailAddress: doctor.DrEmailAddress });
                    this.setState({ DrAddress: doctor.DrAddress });
                    this.setState({ DoctorCity: doctor.DrCity });
                    this.setState({ DrFax: doctor.DrFax });
                    this.setState({ DoctorFirstName: doctor.DrFirstName });
                    this.setState({ DoctorLastName: doctor.DrLastName });
                    this.setState({ Phone: doctor.DrPhone });
                    this.setState({ DrStateId: doctor.DrStateId });
                    this.setState({ DrState: doctor.DrState });
                    this.setState({ DrZipCode: doctor.DrZipCode });
                    this.setState({ FacilityName: doctor.DrFacilityName });
                    this.setState({ SpecialType: doctor.SpecialType });
                    this.setState({ SpecialInstruction: doctor.SpecialInstruction });
                    this.setState({ StartDate: new Date(doctor.StartDate) });
                    this.setState({ EndDate: new Date(doctor.EndDate) });
                    this.setState({ Files: doctor.AuthDocs });
                    this.setState({ RushReq: doctor.RushRequest });
                    this.setState({ OrderFacilityId: doctor.OrderFacilityId });
                    this.setState({ DatesOfService: doctor.DateOfServiceType });
                    this.setState({ CustomStartDate: new Date(doctor.StartDate) });
                    this.setState({ CustomEndDate: new Date(doctor.EndDate) });
                    this.setState({
                        FacilityId: doctor.Id ? doctor.Id : 0,
                    });
                    this.setState({ RequestTypes: doctor.FacilityRequests ? doctor.FacilityRequests : [] });

                    this.setState({ isNew: doctor.isNew });
                    if (!doctor.isNew) {
                        this.setState({
                            dFacNameRO: false,
                            dFNRO: false,
                            dLNRO: false,
                            dEmailRO: false,
                            dAddrRO: false,
                            dCityRO: false,
                            dStateRO: false,
                            dZipRO: false,
                            dPhnRO: false,
                            dFaxRO: false,
                        });
                    }
                    if (doctor.FacilityRequests && doctor.FacilityRequests.length > 0) {
                        doctor.FacilityRequests.map((doc, index) => {
                            //if (doc.mapId == doctor.OrderFacilityId) {
                            if (doc.RequestType == "Billing") {
                                current.setState({
                                    BillingRequestType: doc.RequestType, BillingRushRequest: doc.RushRequest
                                    , BillingAffidavitRequest: doc.AffidavitRequest, BillingCertifiedRequest: doc.CertifiedRequest,
                                    BillingSplInstruct: doc.SpecialInstructionType, BillingCustodianInstruction: doc.SpecialInstruction,

                                    MedicalRequestType: "", MedicalRushRequest: false, MedicalAffidavitRequest: false, MedicalCertifiedRequest: false,
                                    MedicalSplInstruct: "", MedicalCustodianInstruction: "",

                                    FilmsRequestType: "", FilmsRushRequest: false, FilmsAffidavitRequest: false, FilmsCertifiedRequest: false,
                                    FilmsSplInstruct: "", FilmsCustodianInstruction: "",

                                    OtherRequestType: "", OtherRushRequest: false, OtherAffidavitRequest: false, OtherCertifiedRequest: false,
                                    OtherSplInstruct: "", OtherCustodianInstruction: ""
                                });
                            }
                            else if (doc.RequestType == "Medical") {
                                current.setState({
                                    MedicalRequestType: doc.RequestType, MedicalRushRequest: doc.RushRequest
                                    , MedicalAffidavitRequest: doc.AffidavitRequest, MedicalCertifiedRequest: doc.CertifiedRequest,
                                    MedicalSplInstruct: doc.SpecialInstructionType, MedicalCustodianInstruction: doc.SpecialInstruction,

                                    BillingRequestType: "", BillingRushRequest: false, BillingAffidavitRequest: false, BillingCertifiedRequest: false,
                                    BillingSplInstruct: "", BillingCustodianInstruction: "",

                                    FilmsRequestType: "", FilmsRushRequest: false, FilmsAffidavitRequest: false, FilmsCertifiedRequest: false,
                                    FilmsSplInstruct: "", FilmsCustodianInstruction: "",

                                    OtherRequestType: "", OtherRushRequest: false, OtherAffidavitRequest: false, OtherCertifiedRequest: false,
                                    OtherSplInstruct: "", OtherCustodianInstruction: ""
                                });
                            }
                            else if (doc.RequestType == "Films") {
                                current.setState({
                                    FilmsRequestType: doc.RequestType, FilmsRushRequest: doc.RushRequest,
                                    FilmsAffidavitRequest: doc.AffidavitRequest, FilmsCertifiedRequest: doc.CertifiedRequest,
                                    FilmsSplInstruct: doc.SpecialInstructionType, FilmsCustodianInstruction: doc.SpecialInstruction,

                                    MedicalRequestType: "", MedicalRushRequest: false, MedicalAffidavitRequest: false, MedicalCertifiedRequest: false,
                                    MedicalSplInstruct: "", MedicalCustodianInstruction: "",

                                    BillingRequestType: "", BillingRushRequest: false, BillingAffidavitRequest: false, BillingCertifiedRequest: false,
                                    BillingSplInstruct: "", BillingCustodianInstruction: "",

                                    OtherRequestType: "", OtherRushRequest: false, OtherAffidavitRequest: false, OtherCertifiedRequest: false,
                                    OtherSplInstruct: "", OtherCustodianInstruction: ""
                                });
                            }
                            else {
                                current.setState({
                                    OtherRequestType: doc.RequestType, OtherRushRequest: doc.RushRequest,
                                    OtherAffidavitRequest: doc.AffidavitRequest, OtherCertifiedRequest: doc.CertifiedRequest,
                                    OtherSplInstruct: doc.SpecialInstructionType, OtherCustodianInstruction: doc.SpecialInstruction,

                                    MedicalRequestType: "", MedicalRushRequest: false, MedicalAffidavitRequest: false, MedicalCertifiedRequest: false,
                                    MedicalSplInstruct: "", MedicalCustodianInstruction: "",

                                    BillingRequestType: "", BillingRushRequest: false, BillingAffidavitRequest: false, BillingCertifiedRequest: false,
                                    BillingSplInstruct: "", BillingCustodianInstruction: "",

                                    FilmsRequestType: "", FilmsRushRequest: false, FilmsAffidavitRequest: false, FilmsCertifiedRequest: false,
                                    FilmsSplInstruct: "", FilmsCustodianInstruction: ""
                                });
                            }
                            //}
                        });
                    }
                    else {
                        current.setState({
                            MedicalRequestType: "", MedicalRushRequest: false, MedicalAffidavitRequest: false, MedicalCertifiedRequest: false,
                            MedicalSplInstruct: "", MedicalCustodianInstruction: "",

                            BillingRequestType: "", BillingRushRequest: false, BillingAffidavitRequest: false, BillingCertifiedRequest: false,
                            BillingSplInstruct: "", BillingCustodianInstruction: "",

                            FilmsRequestType: "", FilmsRushRequest: false, FilmsAffidavitRequest: false, FilmsCertifiedRequest: false,
                            FilmsSplInstruct: "", FilmsCustodianInstruction: "",

                            OtherRequestType: "", OtherRushRequest: false, OtherAffidavitRequest: false, OtherCertifiedRequest: false,
                            OtherSplInstruct: "", OtherCustodianInstruction: ""
                        });
                    }
                }, 500);
            }
        }
    Onclicktabledelpopup(e, type) {
        this.setState({ deleteAlert: false });
        if (type === "upload") {
            this.deleteDoc(e);
        } else {
            this.deleteDoctor(e);
        }
    }
    deleteDoc(e) {
        //var docURL = this.state.Files[e].DownloadString;
        let currentComponent = this;
        //Api.Post("api/placeorder/DeleteDoc", { "FileName": docURL }, currentComponent).then(function (result) {
        //    if (result.statusMessage == "Success") {
        const list = currentComponent.state.Files;
        list.splice(e, 1);
        currentComponent.setState({ Files: list });
        //    }
        //});
    }
    deleteDoctor(e) {
        const list = this.state.DocList;
        list.splice(e, 1);
        this.setState({ DocList: list });
    }
    getBase64(file, cb) {
        let currentComp = this;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            currentComp.setState({ Base64: reader.result });
            cb(reader.result);
        };
        reader.onerror = function (error) { };
    }
    ClearProvider(e) {
        this.setState({ removeAlert: false });
        this.setState({
            StartDate: "",
            EndDate: "",
            dFacNameRO: false,
            dFNRO: false,
            dLNRO: false,
            dEmailRO: false,
            dAddrRO: false,
            dCityRO: false,
            dStateRO: false,
            dZipRO: false,
            dPhnRO: false,
            dFaxRO: false,
            pDrEmailAddress: false,
            pDrAddress: false,
            pDoctorCity: false,
            pDrStateId: false,
            pDrZipCode: false,
            pPhone: false,
            pDrFax: false,
            pFacilityName: false,
            pDoctorFirstName: false,
            pDoctorLastName: false,
            DrAddress: "",
            DrEmailAddress: "",
            DoctorCity: "",
            DrFax: "",
            DoctorFirstName: "",
            DoctorLastName: "",
            Phone: "",
            DrState: "",
            DrStateId: "Select",
            DrZipCode: "",
            FacilityName: "",
            FacilityId: "",
            isNew: true,
        });
    }
    ClearAgentProvider(e) {
        this.setState({ removeAgentAlert: false });
        this.setState({
            AgId: "",
            AgentFN: "",
            AgentLN: "",
            AgentEmail: "",
            AgentPhone: "",
            AgentMobile: "",
            AgentId: "",
            AgentFax: "",
            AgentEmail: "",
            IsAgentId: "",
            AgentExt: "",
            aemailRO: false,
            afnRO: false,
            alnRO: false,
            afaxRO: false,
            aIdRO: false,
            aphnRO: false,
            amobileRO: false,
            aextRO: false,
            AgentUserId: "",
            AgentUserRole: ""
        });
    }
    ClearAdjusterProvider(e) {
        this.setState({ removeAdjusterAlert: false });
        this.setState({
            AdId: "",
            AdjusterFN: "",
            AdjusterLN: "",
            AdjusterEmail: "",
            AdjusterPhone: "",
            AdjusterMobile: "",
            AdjusterId: "",
            AdjusterFax: "",
            AdjusterEmail: "",
            IsAdjusterId: "",
            AdjusterExt: "",
            ademailRO: false,
            adfnRO: false,
            adlnRO: false,
            adfaxRO: false,
            adIdRO: false,
            adphnRO: false,
            admobileRO: false,
            adextRO: false,
        });
    }

    ClearCarrierManager(e) {
        this.setState({ removeCMAlert: false });
        this.setState({
            CMId: "",
            CMFN: "",
            CMLN: "",
            CMEmailAddress: "",
            CMWorkPhone: "",
            CMExt: "",
            CMCellPhone: "",
            CMFax: "",
            CMFNRo: "",
            CMLNRo: "",
            CMEmailAddressRo: "",
            CMWorkPhoneRo: "",
            CMExtRo: "",
            CMCellPhoneRo: "",
            CMFaxRo: "",
            pCMFN: "",
            pCMLN: "",
            pCMEmailAddress: "",
            pCMWorkPhone: "",
            pCMExt: "",
            pCMCellPhone: "",
            pCMFax: ""
        });
    }
    clearFacility() {
        this.setState({
            DrAddress: "",
            DrEmailAddress: "",
            DoctorCity: "",
            DrFax: "",
            DoctorFirstName: "",
            DoctorLastName: "",
            Phone: "",
            DrState: "",
            DrStateId: "Select",
            DrZipCode: "",
            FacilityName: "",
            FacilityId: "",
            isNew: true,
            StartDate: "",
            EndDate: "",
            dFacNameRO: false,
            dFNRO: false,
            dLNRO: false,
            dEmailRO: false,
            dAddrRO: false,
            dCityRO: false,
            dStateRO: false,
            dZipRO: false,
            dPhnRO: false,
            dFaxRO: false,
            pDrAddress: false,
            pDrEmailAddress: false,
            pDoctorCity: false,
            pDrStateId: false,
            pDrZipCode: false,
            pPhone: false,
            pDrFax: false,
            pFacilityName: false,
            pDoctorFirstName: false,
            pDoctorLastName: false,
            SpecialInstruction: "",
            RushReq: false,
            choseRadioxtext: false,
            Files: [],
            SpecialType: "",
            DatesOfService: "",
            CustomStartDate: "",
            CustomEndDate: "",
            csd: false,
            ced: false,
            MedicalRequestType: "",
            BillingRequestType: "",
            FilmsRequestType: "",
            OtherRequestType: "",
            MedicalRushRequest: false,
            MedicalCertifiedRequest: false,
            MedicalAffidavitRequest: false,
            BillingRushRequest: false,
            BillingCertifiedRequest: false,
            BillingAffidavitRequest: false,
            FilmsRushRequest: false,
            FilmsCertifiedRequest: false,
            FilmsAffidavitRequest: false,
            OtherRushRequest: false,
            OtherCertifiedRequest: false,
            OtherAffidavitRequest: false,
            MedicalSplInstruct: "",
            BillingSplInstruct: "",
            FilmsSplInstruct: "",
            OtherSplInstruct: "",
            MedicalCustodianInstruction: "",
            BillingCustodianInstruction: "",
            FilmsCustodianInstruction: "",
            OtherCustodianInstruction: "",
            RequestTypes: []
        });
        document.getElementById("sdate").value = "";
        document.getElementById("edate").value = "";
        if (this.state.isOrderAdditional) {
            this.setState({ Files: [...this.state.ExistingFiles] });
        }
    }
    addDoctor(e) {
        let currentComponent = this;
        let CompanyType = localStorage.getItem("CompanyType");
        currentComponent.setState({ addvalid: true });
        currentComponent.state.addvalid = true;
        if (currentComponent.state.Phone.length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.Phone)) {
                currentComponent.state.addvalid = false;
                currentComponent.state.pPhone = true;
                currentComponent.setState({ pPhone: true, addvalid: false });
            }
        } else {
            currentComponent.state.addvalid = false;
            currentComponent.state.pPhone = true;
            currentComponent.setState({ pPhone: true, addvalid: false });
        }
        if (currentComponent.state.DrEmailAddress != null) {
            if (currentComponent.state.DrEmailAddress.trim().length != 0) {
                if (currentComponent.state.pDrEmailAddress) {
                    currentComponent.setState({ pDrEmailAddress: true });
                    currentComponent.state.addvalid = false;
                }
            }
        }
        if (this.state.isMandatoryDrAddress) {
            if (currentComponent.state.DrAddress.trim().length == 0) {
                currentComponent.setState({ pDrAddress: true });
                currentComponent.state.addvalid = false;
            }
        }
        if (currentComponent.state.DrFax.length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.DrFax)) {
                currentComponent.state.addvalid = false;
                currentComponent.state.pDrFax = true;
                currentComponent.setState({ pDrFax: true, addvalid: false });
            }
        }
        
        if (currentComponent.state.SpecialType == "CustomDate") {
            if (
                currentComponent.state.StartDate == "" ||
                currentComponent.state.StartDate == "Invalid date"
            ) {
                currentComponent.setState({ dosd: true, addvalid: false });
                currentComponent.state.addvalid = false;
                currentComponent.state.dosd = false;
            }
            if (
                currentComponent.state.EndDate == "" ||
                currentComponent.state.EndDate == "Invalid date"
            ) {
                currentComponent.setState({ doed: true, addvalid: false });
                currentComponent.state.addvalid = false;
                currentComponent.state.doed = false;
            }
            if (
                currentComponent.state.StartDate instanceof Date &&
                currentComponent.state.EndDate instanceof Date &&
                new Date(currentComponent.state.StartDate) <=
                new Date(currentComponent.state.EndDate)
            ) {
            } else {
                currentComponent.setState({ dosd: true, addvalid: false });
                currentComponent.state.addvalid = false;
                currentComponent.state.dosd = false;
            }

            if (new Date(currentComponent.state.StartDate) instanceof Date) {
                if (currentComponent.state.DOB.length != 0 && currentComponent.state.DOB instanceof Date) {
                    if (new Date(currentComponent.state.StartDate) < currentComponent.state.DOB) {
                        currentComponent.state.addvalid = false;
                        currentComponent.state.dosd = true;
                    }
                }
            }
        }
        if (currentComponent.state.SpecialType != "") {
            if (currentComponent.state.SpecialInstruction == "") {
                currentComponent.setState({ choseRadioxtext: true, addvalid: false });
                currentComponent.state.addvalid = false;
                currentComponent.state.choseRadioxtext = false;
            }
        }

        if (currentComponent.state.DoctorCity.length == 0) {
            currentComponent.setState({ pDoctorCity: true });
            currentComponent.setState({ addvalid: false });
            currentComponent.state.addvalid = false;
            currentComponent.state.pDoctorCity = false;
        }

        if (
            currentComponent.state.DrZipCode.length == 0 ||
            currentComponent.state.DrZipCode.length < 5
        ) {
            currentComponent.setState({ pDrZipCode: true });
            currentComponent.setState({ addvalid: false });
            currentComponent.state.addvalid = false;
            currentComponent.state.pDrZipCode = false;
        } else if (currentComponent.state.DrZipCode.length > 5) {
            if (currentComponent.state.DrZipCode.length != 10) {
                currentComponent.setState({ pDrZipCode: true });
                currentComponent.setState({ addvalid: false });
                currentComponent.state.addvalid = false;
                currentComponent.state.pDrZipCode = false;
            }
        }
        if (
            currentComponent.state.DrState.length == 0 ||
            currentComponent.state.DrState == "Select" ||
            currentComponent.state.DrState == "0"
        ) {
            currentComponent.setState({ pDrStateId: true });
            currentComponent.setState({ addvalid: false });
            currentComponent.state.addvalid = false;
        }
        if (
            currentComponent.state.FacilityName.length == 0 &&
            currentComponent.state.DoctorFirstName.length == 0 &&
            currentComponent.state.DoctorLastName.length == 0
        ) {
            currentComponent.setState({ pDoctorFirstName: true });
            currentComponent.setState({ pFacilityName: true });
            currentComponent.setState({ pDoctorLastName: true });
            currentComponent.setState({ addvalid: false });
            currentComponent.state.addvalid = false;
            currentComponent.state.pDoctorLastName = false;
            currentComponent.state.pDoctorFirstName = false;
            currentComponent.state.pFacilityName = false;
        }
        if (
            currentComponent.state.DoctorFirstName.length != 0 &&
            currentComponent.state.DoctorLastName.length == 0
        ) {
            currentComponent.setState({ pDoctorLastName: true });
            currentComponent.setState({ addvalid: false });
            currentComponent.state.addvalid = false;
            currentComponent.state.pDoctorLastName = false;
        }
        if (
            currentComponent.state.DoctorFirstName.length == 0 &&
            currentComponent.state.DoctorLastName.length != 0
        ) {
            currentComponent.setState({ pDoctorFirstName: true });
            currentComponent.setState({ addvalid: false });
            currentComponent.state.addvalid = false;
            currentComponent.state.pDoctorFirstName = false;
        }
        if (currentComponent.state.Files.length == 0) {
            currentComponent.setState({ Files: "" });
        }
        if (CompanyType == "LEGAL") {
            if (currentComponent.state.DatesOfService == "" || currentComponent.state.DatesOfService.length == 0) {
                currentComponent.state.addvalid = false;
                this.toasterredfun2("Dates of service is required");
            } else if (currentComponent.state.DatesOfService == "Custom") {
                if (
                    currentComponent.state.CustomStartDate == "" ||
                    currentComponent.state.CustomStartDate == "Invalid date"
                ) {
                    currentComponent.setState({ csd: true, addvalid: false });
                    currentComponent.state.addvalid = false;
                    currentComponent.state.csd = false;
                }
                if (
                    currentComponent.state.CustomEndDate == "" ||
                    currentComponent.state.CustomEndDate == "Invalid date"
                ) {
                    currentComponent.setState({ ced: true, addvalid: false });
                    currentComponent.state.addvalid = false;
                    currentComponent.state.ced = false;
                }
                if (
                    currentComponent.state.CustomStartDate instanceof Date &&
                    currentComponent.state.CustomEndDate instanceof Date &&
                    new Date(currentComponent.state.CustomStartDate) <=
                    new Date(currentComponent.state.CustomEndDate)
                ) {
                } else {
                    currentComponent.setState({ csd: true, addvalid: false });
                    currentComponent.state.addvalid = false;
                    currentComponent.state.csd = false;
                }
                if (new Date(currentComponent.state.CustomStartDate) instanceof Date) {
                    if (currentComponent.state.DOB.length != 0 && currentComponent.state.DOB instanceof Date) {
                        if (new Date(currentComponent.state.CustomStartDate) < currentComponent.state.DOB) {
                            currentComponent.state.addvalid = false;
                            currentComponent.state.csd = true;
                        }
                    }
                }
            }
            if (currentComponent.state.addvalid && currentComponent.state.RequestTypes.length == 0) {
                currentComponent.state.addvalid = false;
                this.toasterredfun2("Atleast one request type is required");
            }
        }
        if (currentComponent.state.addvalid) {
            //var ispr = false;
            //var matchedRequestType = "";
            //currentComponent.state.DocList.map((doctor, index) => {
            //    if (CompanyType == "LEGAL") {
            //        if (
            //            doctor.DrFacilityName == currentComponent.state.FacilityName &&
            //            doctor.DrFirstName == currentComponent.state.DoctorFirstName &&
            //            doctor.DrLastName == currentComponent.state.DoctorLastName &&
            //            doctor.DrCity == currentComponent.state.DoctorCity &&
            //            doctor.DrState == currentComponent.state.DrState &&
            //            doctor.DrAddress == currentComponent.state.DrAddress &&
            //            doctor.DrZipCode == currentComponent.state.DrZipCode &&
            //            doctor.DrPhone == currentComponent.state.Phone &&
            //            (doctor.FacilityRequests[0].RequestType == currentComponent.state.MedicalRequestType ||
            //                doctor.FacilityRequests[0].RequestType == currentComponent.state.BillingRequestType ||
            //                doctor.FacilityRequests[0].RequestType == currentComponent.state.FilmsRequestType ||
            //                doctor.FacilityRequests[0].RequestType == currentComponent.state.OtherRequestType)
            //        ) {
            //            matchedRequestType = doctor.FacilityRequests[0].RequestType;
            //            ispr = true;
            //            //switch (matchedRequestType) {
            //            //    case "Medical":
            //            //        this.cancelOrRemoveMedicalRequest();
            //            //        break;
            //            //    case "Billing":
            //            //        this.cancelOrRemoveBillingRequest();
            //            //        break;
            //            //    case "Films":
            //            //        this.cancelOrRemoveFilmsRequest();
            //            //        break;
            //            //    case "Other":
            //            //        this.cancelOrRemoveOtherRequest();
            //            //        break;
            //            //    default:
            //            //        break;
            //            //}
            //        }
            //    } else {
            //        if (
            //            doctor.DrFacilityName == currentComponent.state.FacilityName &&
            //            doctor.DrFirstName == currentComponent.state.DoctorFirstName &&
            //            doctor.DrLastName == currentComponent.state.DoctorLastName &&
            //            doctor.DrCity == currentComponent.state.DoctorCity &&
            //            doctor.DrState == currentComponent.state.DrState &&
            //            doctor.DrAddress == currentComponent.state.DrAddress &&
            //            doctor.DrZipCode == currentComponent.state.DrZipCode &&
            //            doctor.DrPhone == currentComponent.state.Phone
            //        ) {
            //            ispr = true;
            //        }
            //    }
            //});
            //if (ispr) {
            //    if (CompanyType == "LEGAL") {
            //        currentComponent.toasterredfun2(
            //            "Doctor/Facility already added with Request Type (" + matchedRequestType + "), please select another Doctor/Facility"
            //        );                                                                     
            //    } else {                                                                   
            //        currentComponent.toasterredfun2(
            //            "Doctor/Facility already added, please select another Doctor/Facility"
            //        );
            //    }                                     
            //    currentComponent.clearFacility(); 
            //} else {

            var _startDate = "";
            var _EndDate = "";

            if (CompanyType == "LEGAL") {
                _startDate = currentComponent.formatDateToString(currentComponent.state.CustomStartDate);
                _EndDate = currentComponent.formatDateToString(currentComponent.state.CustomEndDate);
            }
            else {
                _startDate = currentComponent.formatDateToString(currentComponent.state.StartDate);
                _EndDate = currentComponent.formatDateToString(currentComponent.state.EndDate);
            }

            if (CompanyType == "LEGAL") {
                var docList = currentComponent.state.DocList;
                var customerProvidedFacility = "";
                if (currentComponent.state.DoctorFirstName) {
                    customerProvidedFacility += "Dr " + currentComponent.state.DoctorFirstName;
                }

                if (currentComponent.state.DoctorLastName) {
                    customerProvidedFacility += currentComponent.state.DoctorFirstName ? " " + currentComponent.state.DoctorLastName : "Dr " + currentComponent.state.DoctorLastName;
                }
                customerProvidedFacility += ", " + currentComponent.state.FacilityName + ", " + currentComponent.state.DrAddress + ", " + currentComponent.state.DoctorCity + ", " + currentComponent.state.DrState + ", " + currentComponent.state.DrZipCode + ", " + (currentComponent.state.Phone ? "Ph: " + currentComponent.state.Phone : "");

                currentComponent.state.CustomerProvidedFacility = customerProvidedFacility;
                //debugger;
                currentComponent.state.RequestTypes.map((requestType) => {
                    docList.push({
                        DrFacilityName: currentComponent.state.FacilityName,
                        DrFirstName: currentComponent.state.DoctorFirstName,
                        DrLastName: currentComponent.state.DoctorLastName,
                        DrEmailAddress: currentComponent.state.DrEmailAddress,
                        DrAddress: currentComponent.state.DrAddress,
                        DrCity: currentComponent.state.DoctorCity,
                        DrStateId: parseInt(currentComponent.state.DrStateId),
                        Id: parseInt(
                            currentComponent.state.FacilityId
                                ? currentComponent.state.FacilityId
                                : 0
                        ),
                        isNew: currentComponent.state.isNew,
                        // StateId: currentComponent.state.DrStateId,
                        DrState: currentComponent.state.DrState,
                        DrZipCode: currentComponent.state.DrZipCode,
                        DrPhone: currentComponent.state.Phone,
                        DrFax: currentComponent.state.DrFax,
                        RushRequest: currentComponent.state.RushReq,
                        AuthDocs: currentComponent.state.Files,
                        SpecialInstruction: currentComponent.state.SpecialInstruction,
                        SpecialType: currentComponent.state.SpecialType,
                        DocEdit: true,
                        DocDelete: true,
                        StartDate: _startDate,
                        EndDate: _EndDate,
                        DateOfServiceType: currentComponent.state.DatesOfService,
                        FacilityRequests: [requestType],
                        CustomerProvidedFacility: currentComponent.state.CustomerProvidedFacility
                    });
                });

                currentComponent.setState({
                    DocList: docList
                });
            } else {
                var customerProvidedFacility = "";
                if (currentComponent.state.DoctorFirstName) {
                    customerProvidedFacility += "Dr " + currentComponent.state.DoctorFirstName;
                }

                if (currentComponent.state.DoctorLastName) {
                    customerProvidedFacility += currentComponent.state.DoctorFirstName ? " " + currentComponent.state.DoctorLastName : "Dr " + currentComponent.state.DoctorLastName;
                }
                customerProvidedFacility += ", " + currentComponent.state.FacilityName + ", " + currentComponent.state.DrAddress + ", " + currentComponent.state.DoctorCity + ", " + currentComponent.state.DrState + ", " + currentComponent.state.DrZipCode + ", " + (currentComponent.state.Phone ? "Ph: " + currentComponent.state.Phone : "");
                currentComponent.state.CustomerProvidedFacility = customerProvidedFacility;
                //debugger;
                currentComponent.setState({
                    DocList: [
                        ...currentComponent.state.DocList,
                        {
                            DrFacilityName: currentComponent.state.FacilityName,
                            DrFirstName: currentComponent.state.DoctorFirstName,
                            DrLastName: currentComponent.state.DoctorLastName,
                            DrEmailAddress: currentComponent.state.DrEmailAddress,
                            DrAddress: currentComponent.state.DrAddress,
                            DrCity: currentComponent.state.DoctorCity,
                            DrStateId: parseInt(currentComponent.state.DrStateId),
                            // StateId: currentComponent.state.DrStateId,
                            DrState: currentComponent.state.DrState,
                            DrZipCode: currentComponent.state.DrZipCode,
                            DrPhone: currentComponent.state.Phone,
                            DrFax: currentComponent.state.DrFax,
                            RushRequest: currentComponent.state.RushReq,
                            Id: currentComponent.state.FacilityId
                                ? currentComponent.state.FacilityId
                                : 0,
                            isNew: currentComponent.state.isNew,
                            AuthDocs: currentComponent.state.Files,
                            SpecialInstruction: currentComponent.state.SpecialInstruction,
                            SpecialType: currentComponent.state.SpecialType,
                            StartDate: currentComponent.formatDateToString(currentComponent.state.StartDate),
                            EndDate: currentComponent.formatDateToString(currentComponent.state.EndDate),
                            DocEdit: true,
                            DocDelete: true,
                            CustomerProvidedFacility: currentComponent.state.CustomerProvidedFacility,
                        },
                    ],
                });
            }
            currentComponent.clearFacility();
        }
    }
    formatDateToString(dt) {        
        var date = new Date(dt);
        var dd = (date.getDate() < 10 ? "0" : "") + date.getDate();
        var MM = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
        var yyyy = date.getFullYear();
        var dateString = MM + "/" + dd + "/" + yyyy;
        if (dateString == "NaN/NaN/NaN") {
            dateString = "";
        }
        return dateString;
    }
    handleSubmitOrder(e) {
        this.setState({ isHold: false, addvalid: true });
        this.state.isHold = false;
        this.state.addvalid = true;
        this.state.willUnmount = false;
        this.submitOrder();
    }
    handleSubmitOrderWithOrderId(e) {
        this.state.isHold = false;
        this.state.addvalid = true;
        this.state.willUnmount = false;
        var windowlocation = window.location.href;
        var ordersplit = windowlocation.split("orderid=");
        var getorderid = ordersplit[1];
        this.submitOrderWithOrderId(getorderid);
    }
    handleSubmitOrderReopen(e) {
        this.state.isHold = false;
        this.state.addvalid = true;
        this.state.isReopen = false;
        var windowlocation = window.location.href;
        var ordersplit = windowlocation.split("orderreopenid=");
        var getorderid = ordersplit[1];
        this.submitOrderReopen(getorderid);
    }
    handleHoldOrder(e) {
        debugger;
        this.state.isHold = true;
        this.state.addvalid = true;
        this.state.willUnmount = false;
        this.HoldOrder();
    }

    HoldOrder() {
        debugger;
        var isFromInboundOrder = window.location.href.indexOf("isFromInboundOrder") != -1 ? true : false;
        let currentComponent = this,
            valid = true,
            facility = this.state.DocList;
        var CompanyType = currentComponent.state.CompanyType;
        var dob = currentComponent.state.DOB.toString();
        var doI = currentComponent.state.DateOfIncident.toString();
        if (dob.toLowerCase() == "invalid date") {
            currentComponent.state.DOB = "";
        }
        if (doI.toLowerCase() == "invalid date") {
            currentComponent.state.DateOfIncident = "";
        }
        if (currentComponent.state.StateId == "0") {
            currentComponent.state.StateId = "Select";
        }
        if (currentComponent.state.Phone1Type == "") {
            currentComponent.state.Phone1Type = "Select";
        }
        if (currentComponent.state.Phone2Type == "") {
            currentComponent.state.Phone2Type = "Select";
        }
        if (currentComponent.state.Phone3Type == "") {
            currentComponent.state.Phone3Type = "Select";
        }
        if (currentComponent.state.DrStateId == 0 || currentComponent.state.DrStateId == '0') {
            currentComponent.state.DrStateId = "Select";
        }
        if (isNullOrUndefined(currentComponent.state.DatesOfService)) {
            currentComponent.state.DatesOfService = "";
        }
        var _startDate = "";
        var _EndDate = "";

        if (CompanyType == "LEGAL") {
            _startDate = currentComponent.formatDateToString(currentComponent.state.CustomStartDate);
            _EndDate = currentComponent.formatDateToString(currentComponent.state.CustomEndDate);
        }
        else {
            _startDate = currentComponent.formatDateToString(currentComponent.state.StartDate);
            _EndDate = currentComponent.formatDateToString(currentComponent.state.EndDate);
        }

        if (currentComponent.state.SSN.trim().length != 0) {
            if (!currentComponent.testSSN(currentComponent.state.SSN)) {
                valid = false;
                currentComponent.setState({ pSSN: true });
            }
            if (!currentComponent.state.ssnChecked) {
                valid = false;
                currentComponent.setState({ pSSN: true });
            }
        }

        var dOb = currentComponent.state.DOB.toString();
        if (

            dOb.toLowerCase() == "invalid date"
        ) {
            currentComponent.setState({ pDOB: true });
            valid = false;
        }
        if (currentComponent.state.FirstName.length != 0) {
            if (!currentComponent.testHyperLinks(currentComponent.state.FirstName)) {
                valid = false;
            }
        }
        if (currentComponent.state.LastName.length != 0) {
            if (!currentComponent.testHyperLinks(currentComponent.state.LastName)) {
                valid = false;
            }
        }
        if (currentComponent.state.PolicyNum.length != 0) {
            if (!currentComponent.testHyperLinks(currentComponent.state.PolicyNum)) {
                valid = false;
            }
        }
        if (currentComponent.state.FacilityName.length != 0) {
            if (!currentComponent.testHyperLinks(currentComponent.state.FacilityName)) {
                valid = false;
            }
        }
        if (currentComponent.state.DoctorFirstName.length != 0) {
            if (!currentComponent.testHyperLinks(currentComponent.state.DoctorFirstName)) {
                valid = false;
            }
        }
        if (currentComponent.state.DoctorLastName.length != 0) {
            if (!currentComponent.testHyperLinks(currentComponent.state.DoctorLastName)) {
                valid = false;
            }
        }
        if (currentComponent.state.PCZipCode.length != 0) {
            if (currentComponent.state.PCZipCode.length < 5) {
                currentComponent.setState({ pPCZipCode: true });
                valid = false;
            } else if (currentComponent.state.PCZipCode.length == 5) {
                if (!currentComponent.testZip(currentComponent.state.PCZipCode)) {
                    currentComponent.setState({ pPCZipCode: true });
                    valid = false;
                }
            } else if (currentComponent.state.PCZipCode.length > 5) {
                if (currentComponent.state.PCZipCode.length != 10) {
                    currentComponent.setState({ pPCZipCode: true });
                    valid = false;
                } else if (!currentComponent.testZip5(currentComponent.state.PCZipCode)) {
                    currentComponent.setState({ pPCZipCode: true });
                    valid = false;
                }
            }
        }
        if (currentComponent.state.Email.trim().length != 0 && currentComponent.state.Email != null) {
            if (currentComponent.state.pEmail) {
                currentComponent.setState({ pEmail: true });
                valid = false;
            }
        }
        if (currentComponent.state.DrEmailAddress != null) {
            if (currentComponent.state.DrEmailAddress.trim().length != 0) {
                if (currentComponent.state.pDrEmailAddress) {
                    currentComponent.setState({ pDrEmailAddress: true });
                    valid = false;
                }
            }
        }


        if (currentComponent.state.Phone1.length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.Phone1)) {
                currentComponent.setState({ pPhone1: true });
                valid = false;
            }
        }


        if (currentComponent.state.Phone2.length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.Phone2)) {
                currentComponent.setState({ pPhone2: true });
                valid = false;
            }
        }

        if (currentComponent.state.Fax.length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.Fax)) {
                currentComponent.setState({ pFax: true });
                valid = false;
            }
        }

        if (currentComponent.state.AgentEmail.trim().length != 0 && currentComponent.state.AgentEmail != null) {
            if (currentComponent.state.pAgentEmail) {
                currentComponent.setState({ pAgentEmail: true });
                valid = false;
            }
        }
        if (currentComponent.state.CMEmailAddress.trim().length != 0) {
            if (currentComponent.state.pCMEmailAddress) {
                currentComponent.setState({ pCMEmailAddress: true });
                valid = false;
            }
        }

        if (currentComponent.state.CMWorkPhone && currentComponent.state.CMWorkPhone.trim().length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.CMWorkPhone)) {
                currentComponent.setState({ pCMWorkPhone: true });
                valid = false;
            }
        }
        if (currentComponent.state.CMCellPhone && currentComponent.state.CMCellPhone.trim().length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.CMCellPhone)) {
                currentComponent.setState({ pCMCellPhone: true });
                valid = false;
            }
        }
        if (currentComponent.state.CMFax && currentComponent.state.CMFax.trim().length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.CMFax)) {
                currentComponent.setState({ pCMFax: true });
                valid = false;
            }
        }

        if (currentComponent.state.AgentPhone && currentComponent.state.AgentPhone.trim().length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.AgentPhone)) {
                currentComponent.setState({ pAgentPhone: true });
                valid = false;
            }
        }
        if (currentComponent.state.AgentFax && currentComponent.state.AgentFax.length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.AgentFax)) {
                currentComponent.setState({ pAgentFax: true });
                valid = false;
            }
        }
        if (currentComponent.state.AgentMobile && currentComponent.state.AgentMobile.length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.AgentMobile)) {
                currentComponent.setState({ pAgentMobile: true });
                valid = false;
            }
        }


        if (currentComponent.state.AdjusterEmail != null && currentComponent.state.AdjusterEmail.trim().length != 0) {
            if (currentComponent.state.pAdjusterEmail) {
                currentComponent.setState({ pAdjusterEmail: true });
                valid = false;
            }
        }

        if (currentComponent.state.AdjusterPhone && currentComponent.state.AdjusterPhone.trim().length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.AdjusterPhone)) {
                currentComponent.setState({ pAdjusterPhone: true });
                valid = false;
            }
        }
        if (currentComponent.state.AdjusterFax && currentComponent.state.AdjusterFax.length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.AdjusterFax)) {
                currentComponent.setState({ pAdjusterFax: true });
                valid = false;
            }
        }
        if (currentComponent.state.AdjusterMobile && currentComponent.state.AdjusterMobile.length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.AdjusterMobile)) {
                currentComponent.setState({ pAdjusterMobile: true });
                valid = false;
            }
        }

        if (this.state.DateOfIncident.length != 0 && this.state.DOB != 0) {
            if (new Date(this.state.DateOfIncident) < this.state.DOB) {
                currentComponent.setState({ pDateOfIncident: true });
                valid = false;
            }
        }

        if (
            currentComponent.state.DrFax != null &&
            currentComponent.state.DrFax.length != 0
        ) {
            if (!currentComponent.testPhone(currentComponent.state.DrFax)) {
                currentComponent.setState({ pDrFax: true });
                valid = false;
            }
        }
        if (currentComponent.state.Phone.length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.Phone)) {
                currentComponent.setState({ pPhone: true });
                valid = false;
            }
        }
        if (currentComponent.state.DrZipCode.length != 0) {
            if(currentComponent.state.DrZipCode.length < 5) {
                currentComponent.setState({ pDrZipCode: true });
                valid = false;
            } else if (currentComponent.state.DrZipCode.length > 5) {
                if (!currentComponent.testZip5(currentComponent.state.DrZipCode)) {
                    currentComponent.setState({ pDrZipCode: true });
                    valid = false;
                }
                if (currentComponent.state.DrZipCode.length != 10) {
                    currentComponent.setState({ pDrZipCode: true });
                    valid = false;
                }
            }
        }

        if (currentComponent.state.Title != "" || currentComponent.state.FirstName != "" || currentComponent.state.LastName != "" ||
            currentComponent.state.DOB != "" || currentComponent.state.SSN != "" || currentComponent.state.Gender != "Select" || currentComponent.state.OtherNames != "" ||
            currentComponent.state.PolicyNum != "" || currentComponent.state.PolicyAmount != "" || currentComponent.state.OtherField1 != "" || currentComponent.state.OtherField2 != "" ||
            currentComponent.state.OtherField3 != "" || currentComponent.state.OtherField4 != "" || currentComponent.state.DateOfIncident != "" || currentComponent.state.Email != "" ||
            currentComponent.state.PCAddress != "" || currentComponent.state.PCCity != "" || currentComponent.state.StateId != "Select" || currentComponent.state.PCZipCode != "" ||
            currentComponent.state.Phone1 != "" || currentComponent.state.Ext1 != "" || currentComponent.state.Phone1Type != "Select" || currentComponent.state.Phone2 != "" ||
            currentComponent.state.Ext2 != "" || currentComponent.state.Phone2Type != "Select" || currentComponent.state.Phone3 != "" || currentComponent.state.Ext3 != "" ||
            currentComponent.state.Phone3Type != "Select" || currentComponent.state.Fax != "" || currentComponent.state.PreferredContact != "" || currentComponent.state.AgentFN != "" ||
            currentComponent.state.AgentLN != "" || currentComponent.state.AgentEmail != "" || currentComponent.state.AgentPhone != "" || currentComponent.state.AgentExt != "" ||
            currentComponent.state.AgentMobile != "" || currentComponent.state.AgentFax != "" || currentComponent.state.AgentId != "" || currentComponent.state.AdjusterFN != "" ||
            currentComponent.state.AdjusterLN != "" || currentComponent.state.AdjusterEmail != "" || currentComponent.state.AdjusterPhone != "" || currentComponent.state.AdjusterExt != "" ||
            currentComponent.state.AdjusterMobile != "" || currentComponent.state.AdjusterFax != "" || currentComponent.state.AdjusterId != "" || currentComponent.state.FacilityName != "" ||
            currentComponent.state.DoctorFirstName != "" || currentComponent.state.DoctorLastName != "" || currentComponent.state.DrEmailAddress != "" || currentComponent.state.DrAddress != "" ||
            currentComponent.state.DoctorCity != "" || currentComponent.state.DrStateId != "Select" || currentComponent.state.DrZipCode != "" || currentComponent.state.Phone != "" ||
            currentComponent.state.DrFax != "" || currentComponent.state.SpecialType != "" || currentComponent.state.StartDate != "" || /* currentComponent.state.CustomerProvidedFacility != "" ||*/
            currentComponent.state.EndDate != "" || currentComponent.state.SpecialInstruction != "" || currentComponent.state.CustomStartDate != "" ||
            currentComponent.state.CustomEndDate != "" || currentComponent.state.MedicalRequestType != "" || currentComponent.state.BillingRequestType != "" || currentComponent.state.FilmsRequestType != "" ||
            currentComponent.state.OtherRequestType != "" || currentComponent.state.CMLN != "" || currentComponent.state.CMEmailAddress != "" || currentComponent.state.CMWorkPhone != "" ||
            currentComponent.state.CMExt != "" || currentComponent.state.CMCellPhone != "" || currentComponent.state.CMFax != "" || currentComponent.state.Files.length > 0) {
            if (!isNullOrUndefined(CompanyType)) {

                facility.map((docDate, index) => {
                    docDate.StartDate = currentComponent.formatDateToString(docDate.StartDate);
                    docDate.EndDate = currentComponent.formatDateToString(docDate.EndDate);
                });
                var customerProvidedFacility = "";
                if (currentComponent.state.DoctorFirstName) {
                    customerProvidedFacility += "Dr " + currentComponent.state.DoctorFirstName;
                }

                if (currentComponent.state.DoctorLastName) {
                    customerProvidedFacility += currentComponent.state.DoctorFirstName ? " " + currentComponent.state.DoctorLastName : "Dr " + currentComponent.state.DoctorLastName;
                }
                customerProvidedFacility += ", " + currentComponent.state.FacilityName + ", " + currentComponent.state.DrAddress + ", " + currentComponent.state.DoctorCity + ", " + currentComponent.state.DrState + ", " + currentComponent.state.DrZipCode + ", " + (currentComponent.state.Phone ? "Ph: " + currentComponent.state.Phone : "");
                currentComponent.state.CustomerProvidedFacility = customerProvidedFacility;
                //debugger
                if (CompanyType == "LEGAL") {
                    var docList = currentComponent.state.DocList;
                    //debugger
                    if (currentComponent.state.RequestTypes.length > 0) {
                        currentComponent.state.RequestTypes.map((requestType) => {
                            if (currentComponent.state.DoctorFirstName != "" || currentComponent.state.DoctorLastName != "" || currentComponent.state.DrEmailAddress != "" || currentComponent.state.DrAddress != "" ||
                                currentComponent.state.DoctorCity != "" || currentComponent.state.DrStateId != "Select" || currentComponent.state.DrZipCode != "" || currentComponent.state.Phone != "" ||
                                currentComponent.state.DrFax != "" || currentComponent.state.SpecialType != "" || currentComponent.state.StartDate != "" ||
                                currentComponent.state.EndDate != "" || currentComponent.state.SpecialInstruction != "" || currentComponent.state.DatesOfService != "" || currentComponent.state.CustomStartDate != "" ||
                                currentComponent.state.CustomEndDate != "" || currentComponent.state.MedicalRequestType != "" || currentComponent.state.BillingRequestType != "" || currentComponent.state.FilmsRequestType != "" ||
                                currentComponent.state.OtherRequestType != "" || currentComponent.state.FacilityName != "" || currentComponent.state.Files != []) {
                                facility = [
                                    ...facility,
                                    {
                                        DrFacilityName: currentComponent.state.FacilityName,
                                        DrFirstName: currentComponent.state.DoctorFirstName,
                                        DrLastName: currentComponent.state.DoctorLastName,
                                        DrEmailAddress: currentComponent.state.DrEmailAddress,
                                        DrAddress: currentComponent.state.DrAddress,
                                        DrCity: currentComponent.state.DoctorCity,
                                        DrStateId: currentComponent.state.DrStateId != "Select" ? parseInt(currentComponent.state.DrStateId) : 0,
                                        Id: parseInt(
                                            currentComponent.state.FacilityId
                                                ? currentComponent.state.FacilityId
                                                : 0
                                        ),
                                        isNew: currentComponent.state.isNew,
                                        DrState: currentComponent.state.DrState,
                                        DrZipCode: currentComponent.state.DrZipCode,
                                        DrPhone: currentComponent.state.Phone,
                                        DrFax: currentComponent.state.DrFax,
                                        RushRequest: currentComponent.state.RushReq,
                                        AuthDocs: currentComponent.state.Files,
                                        SpecialInstruction: currentComponent.state.SpecialInstruction,
                                        SpecialType: currentComponent.state.SpecialType,
                                        DocEdit: true,
                                        DocDelete: true,
                                        StartDate: _startDate,
                                        EndDate: _EndDate,
                                        DateOfServiceType: currentComponent.state.DatesOfService,
                                        FacilityRequests: [requestType],
                                        IsMissingFacilityDetails: currentComponent.state.IsMissingFacilityDetails,
                                        CustomerProvidedFacility: currentComponent.state.CustomerProvidedFacility
                                    },];
                            }
                        });
                    }
                    else {
                        //debugger
                        if (currentComponent.state.DoctorFirstName != "" || currentComponent.state.DoctorLastName != "" || currentComponent.state.DrEmailAddress != "" || currentComponent.state.DrAddress != "" ||
                            currentComponent.state.DoctorCity != "" || currentComponent.state.DrStateId != "Select" || currentComponent.state.DrZipCode != "" || currentComponent.state.Phone != "" ||
                            currentComponent.state.DrFax != "" || currentComponent.state.SpecialType != "" || currentComponent.state.StartDate != "" ||
                            currentComponent.state.EndDate != "" || currentComponent.state.SpecialInstruction != "" || currentComponent.state.DatesOfService != "" || currentComponent.state.CustomStartDate != "" ||
                            currentComponent.state.CustomEndDate != "" || currentComponent.state.MedicalRequestType != "" || currentComponent.state.BillingRequestType != "" || currentComponent.state.FilmsRequestType != "" ||
                            currentComponent.state.OtherRequestType != "" || currentComponent.state.FacilityName != "" || currentComponent.state.Files.length > 0) {

                            facility = [
                                ...facility,
                                {
                                    DrFacilityName: currentComponent.state.FacilityName,
                                    DrFirstName: currentComponent.state.DoctorFirstName,
                                    DrLastName: currentComponent.state.DoctorLastName,
                                    DrEmailAddress: currentComponent.state.DrEmailAddress,
                                    DrAddress: currentComponent.state.DrAddress,
                                    DrCity: currentComponent.state.DoctorCity,
                                    DrStateId: currentComponent.state.DrStateId != "Select" ? parseInt(currentComponent.state.DrStateId) : 0,
                                    Id: parseInt(
                                        currentComponent.state.FacilityId
                                            ? currentComponent.state.FacilityId
                                            : 0
                                    ),
                                    isNew: currentComponent.state.isNew,
                                    DrState: currentComponent.state.DrState,
                                    DrZipCode: currentComponent.state.DrZipCode,
                                    DrPhone: currentComponent.state.Phone,
                                    DrFax: currentComponent.state.DrFax,
                                    RushRequest: currentComponent.state.RushReq,
                                    AuthDocs: currentComponent.state.Files,
                                    SpecialInstruction: currentComponent.state.SpecialInstruction,
                                    SpecialType: currentComponent.state.SpecialType,
                                    DocEdit: true,
                                    DocDelete: true,
                                    StartDate: _startDate,
                                    EndDate: _EndDate,
                                    DateOfServiceType: currentComponent.state.DatesOfService,
                                    IsMissingFacilityDetails: currentComponent.state.IsMissingFacilityDetails,
                                    CustomerProvidedFacility: currentComponent.state.CustomerProvidedFacility
                                },];
                        }
                    }
                } else {
                    if (currentComponent.state.DoctorFirstName != "" || currentComponent.state.DoctorLastName != "" || currentComponent.state.DrEmailAddress != "" || currentComponent.state.DrAddress != "" ||
                        currentComponent.state.DoctorCity != "" || currentComponent.state.DrStateId != "Select" || currentComponent.state.DrZipCode != "" || currentComponent.state.Phone != "" ||
                        currentComponent.state.DrFax != "" || currentComponent.state.SpecialType != "" || currentComponent.state.StartDate != "" ||
                        currentComponent.state.EndDate != "" || currentComponent.state.SpecialInstruction != "" || currentComponent.state.DatesOfService != "" || currentComponent.state.CustomStartDate != "" ||
                        currentComponent.state.CustomEndDate != "" || currentComponent.state.MedicalRequestType != "" || currentComponent.state.BillingRequestType != "" || currentComponent.state.FilmsRequestType != "" ||
                        currentComponent.state.OtherRequestType != "" || currentComponent.state.FacilityName != "" || currentComponent.state.Files.length > 0) {
                        facility = [
                            ...facility,
                            {
                                DrFacilityName: currentComponent.state.FacilityName,
                                DrFirstName: currentComponent.state.DoctorFirstName,
                                DrLastName: currentComponent.state.DoctorLastName,
                                DrEmailAddress: currentComponent.state.DrEmailAddress,
                                DrAddress: currentComponent.state.DrAddress,
                                DrCity: currentComponent.state.DoctorCity,
                                DrStateId: currentComponent.state.DrStateId != "Select" ? parseInt(currentComponent.state.DrStateId) : 0,
                                DrState: currentComponent.state.DrState,
                                DrZipCode: currentComponent.state.DrZipCode,
                                DrPhone: currentComponent.state.Phone,
                                DrFax: currentComponent.state.DrFax,
                                RushRequest: currentComponent.state.RushReq,
                                Id: currentComponent.state.FacilityId
                                    ? currentComponent.state.FacilityId
                                    : 0,
                                isNew: currentComponent.state.isNew,
                                AuthDocs: currentComponent.state.Files,
                                SpecialInstruction: currentComponent.state.SpecialInstruction,
                                SpecialType: currentComponent.state.SpecialType,
                                StartDate: _startDate,
                                EndDate: _EndDate,
                                IsMissingFacilityDetails: currentComponent.state.IsMissingFacilityDetails,
                                CustomerProvidedFacility: currentComponent.state.CustomerProvidedFacility
                            },
                        ];
                    }
                }
                if (!valid) {
                    currentComponent.toasterredfun2("Your order was not submitted. It is missing required data. Please correct the data, and click HoldOrder again.");
                }
                if (valid) {
                    currentComponent.setState({ Loading: true });
                    var orderid = 0;
                    if (isFromInboundOrder) {
                        var urlParams = new URLSearchParams(window.location.search);
                        orderid = urlParams.get('id');
                    }

                    let url = "api/placeorder/HoldOrder";
                    var data = {
                        OrderingOfficeId: this.state.OrderingOfficeId,
                        HoldOrderId: this.state.HoldOrderId == "0" ? "" : this.state.HoldOrderId,
                        IsDefaultOrderingOffice: this.state.IsDefaultOrderingOffice,
                        AgId: this.state.AgId == "" ? 0 : this.state.AgId,
                        AdId: this.state.AdId == "" ? 0 : this.state.AdId,
                        PatientFN: Api.encryptData(this.state.FirstName),
                        PatientLN: Api.encryptData(this.state.LastName),
                        Title: Api.encryptData(this.state.Title),
                        SSN: Api.encryptData(this.state.SSN),
                        Address: Api.encryptData(this.state.PCAddress),
                        AgentFN: this.state.AgentFN,
                        AgentLN: this.state.AgentLN,
                        AgentId: this.state.AgentId,
                        AgentPhone: this.state.AgentPhone,
                        AgentExt: this.state.AgentExt,
                        AgentCell: this.state.AgentMobile,
                        AgentFax: this.state.AgentFax,
                        AgentEmail: this.state.AgentEmail,
                        IsAgentId: this.state.IsAgentId,
                        AdjusterFN: this.state.AdjusterFN,
                        AdjusterLN: this.state.AdjusterLN,
                        AdjusterId: this.state.AdjusterId,
                        AdjusterPhone: this.state.AdjusterPhone,
                        AdjusterExt: this.state.AdjusterExt,
                        AdjusterCell: this.state.AdjusterMobile,
                        AdjusterFax: this.state.AdjusterFax,
                        AdjusterEmail: this.state.AdjusterEmail,
                        IsAdjusterId: this.state.IsAdjusterId,
                        City: Api.encryptData(this.state.PCCity),
                        StateId: this.state.StateId != "Select" ? parseInt(this.state.StateId) : 0,
                        Zip: Api.encryptData(this.state.PCZipCode),
                        DOB: this.state.DOB != "" ? Api.encryptData(this.formatDateToString(this.state.DOB)) : "",
                        Email: Api.encryptData(this.state.Email),
                        Gender: this.state.Gender != "Select" ? this.state.Gender : "",
                        Policy: Api.encryptData(this.state.PolicyNum),
                        Phone1: Api.encryptData(this.state.Phone1),
                        Phone2: Api.encryptData(this.state.Phone2),
                        Phone3: Api.encryptData(this.state.Phone3),
                        PreferredContact: Api.encryptData(this.state.PreferredContact),
                        OtherName: Api.encryptData(this.state.OtherNames),
                        Phone1Type: this.state.Phone1Type != "Select" ? this.state.Phone1Type : "",
                        Phone2Type: this.state.Phone2Type != "Select" ? this.state.Phone2Type : "",
                        Phone3Type: this.state.Phone3Type != "Select" ? this.state.Phone3Type : "",
                        Fax: Api.encryptData(this.state.Fax),
                        Ext1: Api.encryptData(this.state.Ext1),
                        Ext2: Api.encryptData(this.state.Ext2),
                        Ext3: Api.encryptData(this.state.Ext3),
                        IsHold: this.state.isHold,
                        UserId: this.state.UserId,
                        Facility: facility,
                        PolicyAmount: Api.encryptData((this.state.PolicyAmount ? this.state.PolicyAmount : "0")),
                        OtherField1: Api.encryptData(this.state.OtherField1),
                        OtherField2: Api.encryptData(this.state.OtherField2),
                        OtherField3: Api.encryptData(this.state.OtherField3),
                        OtherField4: Api.encryptData(this.state.OtherField4),
                        DateOfIncident: Api.encryptData(this.state.DateOfIncident
                            ? this.formatDateToString(this.state.DateOfIncident)
                            : ""),
                        CompanyType: CompanyType,
                        IsFromInboundOrder: isFromInboundOrder,
                        InboundOrderId: orderid,
                        CMId: this.state.CMId,
                        CMFN: this.state.CMFN,
                        CMLN: this.state.CMLN,
                        CMEmailAddress: this.state.CMEmailAddress,
                        CMWorkPhone: this.state.CMWorkPhone,
                        CMExt: this.state.CMExt,
                        CMCellPhone: this.state.CMCellPhone,
                        CMFax: this.state.CMFax,
                    };
                    debugger;
                    Api.Post(url, data, currentComponent)
                        .then(function (resul) {
                            debugger;
                            if (resul.statusMessage == "Success") {
                               
                                currentComponent.setState({ willUnmount: false, Loading: false });
                                if (currentComponent.state.isHold) {
                                    currentComponent.toasterfun2(resul.statusMessage);
                                    currentComponent.props.history.push("/unsubmitted-order");
                                } else {
                                    localStorage.setItem("OrderId", resul.orderNo);

                                    if (isFromInboundOrder) {
                                        currentComponent.props.history.push("/Inbound-Orders");
                                    }
                                    else {
                                        currentComponent.props.history.push("/confirmation");
                                    }
                                }
                            }
                            else if (resul.statusCode == 403) {
                                //debugger;
                                currentComponent.setState({ Loading: false });
                                localStorage.removeItem('ShowAdminMenu');
                                localStorage.removeItem('UserType');
                                localStorage.removeItem('PlaceOrder');
                                localStorage.removeItem('ViewResults');
                                localStorage.removeItem('ViewAuth');
                                localStorage.removeItem('ExportSearchResults');
                                localStorage.removeItem('PerformanceMetrics');
                                localStorage.setItem('PlaceOrder', resul.allowPlaceOrder);
                                localStorage.setItem('ShowAdminMenu', resul.isAdmin);
                                localStorage.setItem('UserType', resul.userType);
                                localStorage.setItem('ViewResults', resul.downloadImages);
                                localStorage.setItem('ViewAuth', resul.viewAuth);
                                localStorage.setItem('ExportSearchResults', resul.exportSearchResults);
                                localStorage.setItem('PerformanceMetrics', resul.performanceMetrics);
                                currentComponent.setState({ adminAlert: true });

                            } else {
                                currentComponent.setState({ Loading: false });
                                currentComponent.toasterredfun2(resul.statusMessage);
                            }
                        })
                        .catch(function (error) {
                            currentComponent.setState({ Loading: false });
                            if (error == "TypeError: Failed to fetch") {
                                currentComponent.toasterredfun2("Check your internet connection.");
                            } else {
                                currentComponent.toasterredfun2(error);
                            }
                        });
                }
            }
            else {
                currentComponent.setState({ Loading: false });
                currentComponent.toasterredfun2("Company type not available. Please try again.");
            }
        }
        else {
            currentComponent.toasterredfun2("Please provide any of the PHI Information or any other fields to place the order on hold");
        }
    }

    HoldOrderWRD() {        
        if (this.state.HideHoldButton == false) {
            var isFromInboundOrder = window.location.href.indexOf("isFromInboundOrder") != -1 ? true : false;
            let currentComponent = this,
                facility = this.state.DocList;
            var CompanyType = currentComponent.state.CompanyType;
            var dob = currentComponent.state.DOB.toString();
            var doI = currentComponent.state.DateOfIncident.toString();
            if (dob.toLowerCase() == "invalid date") {
                currentComponent.state.DOB = "";
            }
            if (doI.toLowerCase() == "invalid date") {
                currentComponent.state.DateOfIncident = "";
            }
            if (currentComponent.state.StateId == "0") {
                currentComponent.state.StateId = "Select";
            }
            if (currentComponent.state.Phone1Type == "") {
                currentComponent.state.Phone1Type = "Select";
            }
            if (currentComponent.state.Phone2Type == "") {
                currentComponent.state.Phone2Type = "Select";
            }
            if (currentComponent.state.Phone3Type == "") {
                currentComponent.state.Phone3Type = "Select";
            }
            if (currentComponent.state.DrStateId == 0 || currentComponent.state.DrStateId == '0') {
                currentComponent.state.DrStateId = "Select";
            }
            if (isNullOrUndefined(currentComponent.state.DatesOfService)) {
                currentComponent.state.DatesOfService = "";
            }
            var _startDate = "";
            var _EndDate = "";

            if (CompanyType == "LEGAL") {
                _startDate = currentComponent.formatDateToString(currentComponent.state.CustomStartDate);
                _EndDate = currentComponent.formatDateToString(currentComponent.state.CustomEndDate);
            }
            else {
                _startDate = currentComponent.formatDateToString(currentComponent.state.StartDate);
                _EndDate = currentComponent.formatDateToString(currentComponent.state.EndDate);
            }
            if (currentComponent.state.Title != "" || currentComponent.state.FirstName != "" || currentComponent.state.LastName != "" ||
                currentComponent.state.DOB != "" || currentComponent.state.SSN != "" || currentComponent.state.Gender != "Select" || currentComponent.state.OtherNames != "" ||
                currentComponent.state.PolicyNum != "" || currentComponent.state.PolicyAmount != "" || currentComponent.state.OtherField1 != "" || currentComponent.state.OtherField2 != "" ||
                currentComponent.state.OtherField3 != "" || currentComponent.state.OtherField4 != "" || currentComponent.state.DateOfIncident != "" || currentComponent.state.Email != "" ||
                currentComponent.state.PCAddress != "" || currentComponent.state.PCCity != "" || currentComponent.state.StateId != "Select" || currentComponent.state.PCZipCode != "" ||
                currentComponent.state.Phone1 != "" || currentComponent.state.Ext1 != "" || currentComponent.state.Phone1Type != "Select" || currentComponent.state.Phone2 != "" ||
                currentComponent.state.Ext2 != "" || currentComponent.state.Phone2Type != "Select" || currentComponent.state.Phone3 != "" || currentComponent.state.Ext3 != "" ||
                currentComponent.state.Phone3Type != "Select" || currentComponent.state.Fax != "" || currentComponent.state.PreferredContact != "" || currentComponent.state.AgentFN != "" ||
                currentComponent.state.AgentLN != "" || currentComponent.state.AgentEmail != "" || currentComponent.state.AgentPhone != "" || currentComponent.state.AgentExt != "" ||
                currentComponent.state.AgentMobile != "" || currentComponent.state.AgentFax != "" || currentComponent.state.AgentId != "" || currentComponent.state.AdjusterFN != "" ||
                currentComponent.state.AdjusterLN != "" || currentComponent.state.AdjusterEmail != "" || currentComponent.state.AdjusterPhone != "" || currentComponent.state.AdjusterExt != "" ||
                currentComponent.state.AdjusterMobile != "" || currentComponent.state.AdjusterFax != "" || currentComponent.state.AdjusterId != "" || currentComponent.state.FacilityName != "" ||
                currentComponent.state.DoctorFirstName != "" || currentComponent.state.DoctorLastName != "" || currentComponent.state.DrEmailAddress != "" || currentComponent.state.DrAddress != "" ||
                currentComponent.state.DoctorCity != "" || currentComponent.state.DrStateId != "Select" || currentComponent.state.DrZipCode != "" || currentComponent.state.Phone != "" ||
                currentComponent.state.DrFax != "" || currentComponent.state.SpecialType != "" || currentComponent.state.StartDate != "" || /* currentComponent.state.CustomerProvidedFacility != "" ||*/
                currentComponent.state.EndDate != "" || currentComponent.state.SpecialInstruction != "" || currentComponent.state.CustomStartDate != "" ||
                currentComponent.state.CustomEndDate != "" || currentComponent.state.MedicalRequestType != "" || currentComponent.state.BillingRequestType != "" || currentComponent.state.FilmsRequestType != "" ||
                currentComponent.state.OtherRequestType != "" || currentComponent.state.CMFN != ""|| currentComponent.state.CMLN != "" || currentComponent.state.CMEmailAddress != "" || currentComponent.state.CMWorkPhone != "" ||
                currentComponent.state.CMExt != "" || currentComponent.state.CMCellPhone != "" || currentComponent.state.CMFax != "" || currentComponent.state.Files.length > 0) {
                if (!isNullOrUndefined(CompanyType)) {
                    facility.map((docDate, index) => {
                        docDate.StartDate = currentComponent.formatDateToString(docDate.StartDate);
                        docDate.EndDate = currentComponent.formatDateToString(docDate.EndDate);
                    });
                    var customerProvidedFacility = "";
                    if (currentComponent.state.DoctorFirstName) {
                        customerProvidedFacility += "Dr " + currentComponent.state.DoctorFirstName;
                    }

                    if (currentComponent.state.DoctorLastName) {
                        customerProvidedFacility += currentComponent.state.DoctorFirstName ? " " + currentComponent.state.DoctorLastName : "Dr " + currentComponent.state.DoctorLastName;
                    }
                    customerProvidedFacility += ", " + currentComponent.state.FacilityName + ", " + currentComponent.state.DrAddress + ", " + currentComponent.state.DoctorCity + ", " + currentComponent.state.DrState + ", " + currentComponent.state.DrZipCode + ", " + (currentComponent.state.Phone ? "Ph: " + currentComponent.state.Phone : "");
                    currentComponent.state.CustomerProvidedFacility = customerProvidedFacility;
                    //debugger
                    if (CompanyType == "LEGAL") {
                        var docList = currentComponent.state.DocList;
                        //debugger
                        if (currentComponent.state.RequestTypes.length > 0) {
                            currentComponent.state.RequestTypes.map((requestType) => {
                                if (currentComponent.state.DoctorFirstName != "" || currentComponent.state.DoctorLastName != "" || currentComponent.state.DrEmailAddress != "" || currentComponent.state.DrAddress != "" ||
                                    currentComponent.state.DoctorCity != "" || currentComponent.state.DrStateId != "Select" || currentComponent.state.DrZipCode != "" || currentComponent.state.Phone != "" ||
                                    currentComponent.state.DrFax != "" || currentComponent.state.SpecialType != "" || currentComponent.state.StartDate != "" ||
                                    currentComponent.state.EndDate != "" || currentComponent.state.SpecialInstruction != "" || currentComponent.state.DatesOfService != "" || currentComponent.state.CustomStartDate != "" ||
                                    currentComponent.state.CustomEndDate != "" || currentComponent.state.MedicalRequestType != "" || currentComponent.state.BillingRequestType != "" || currentComponent.state.FilmsRequestType != "" ||
                                    currentComponent.state.OtherRequestType != "" || currentComponent.state.FacilityName != "" || currentComponent.state.Files != []) {
                                    facility = [
                                        ...facility,
                                        {
                                            DrFacilityName: currentComponent.state.FacilityName,
                                            DrFirstName: currentComponent.state.DoctorFirstName,
                                            DrLastName: currentComponent.state.DoctorLastName,
                                            DrEmailAddress: currentComponent.state.DrEmailAddress,
                                            DrAddress: currentComponent.state.DrAddress,
                                            DrCity: currentComponent.state.DoctorCity,
                                            DrStateId: currentComponent.state.DrStateId != "Select" ? parseInt(currentComponent.state.DrStateId) : 0,
                                            Id: parseInt(
                                                currentComponent.state.FacilityId
                                                    ? currentComponent.state.FacilityId
                                                    : 0
                                            ),
                                            isNew: currentComponent.state.isNew,
                                            DrState: currentComponent.state.DrState,
                                            DrZipCode: currentComponent.state.DrZipCode,
                                            DrPhone: currentComponent.state.Phone,
                                            DrFax: currentComponent.state.DrFax,
                                            RushRequest: currentComponent.state.RushReq,
                                            AuthDocs: currentComponent.state.Files,
                                            SpecialInstruction: currentComponent.state.SpecialInstruction,
                                            SpecialType: currentComponent.state.SpecialType,
                                            DocEdit: true,
                                            DocDelete: true,
                                            StartDate: _startDate,
                                            EndDate: _EndDate,
                                            DateOfServiceType: currentComponent.state.DatesOfService,
                                            FacilityRequests: [requestType],
                                            IsMissingFacilityDetails: currentComponent.state.IsMissingFacilityDetails,
                                            CustomerProvidedFacility: currentComponent.state.CustomerProvidedFacility
                                        },];
                                }
                            });
                        }
                        else {
                            //debugger
                            if (currentComponent.state.DoctorFirstName != "" || currentComponent.state.DoctorLastName != "" || currentComponent.state.DrEmailAddress != "" || currentComponent.state.DrAddress != "" ||
                                currentComponent.state.DoctorCity != "" || currentComponent.state.DrStateId != "Select" || currentComponent.state.DrZipCode != "" || currentComponent.state.Phone != "" ||
                                currentComponent.state.DrFax != "" || currentComponent.state.SpecialType != "" || currentComponent.state.StartDate != "" ||
                                currentComponent.state.EndDate != "" || currentComponent.state.SpecialInstruction != "" || currentComponent.state.DatesOfService != "" || currentComponent.state.CustomStartDate != "" ||
                                currentComponent.state.CustomEndDate != "" || currentComponent.state.MedicalRequestType != "" || currentComponent.state.BillingRequestType != "" || currentComponent.state.FilmsRequestType != "" ||
                                currentComponent.state.OtherRequestType != "" || currentComponent.state.FacilityName != "" || currentComponent.state.Files.length > 0) {

                                facility = [
                                    ...facility,
                                    {
                                        DrFacilityName: currentComponent.state.FacilityName,
                                        DrFirstName: currentComponent.state.DoctorFirstName,
                                        DrLastName: currentComponent.state.DoctorLastName,
                                        DrEmailAddress: currentComponent.state.DrEmailAddress,
                                        DrAddress: currentComponent.state.DrAddress,
                                        DrCity: currentComponent.state.DoctorCity,
                                        DrStateId: currentComponent.state.DrStateId != "Select" ? parseInt(currentComponent.state.DrStateId) : 0,
                                        Id: parseInt(
                                            currentComponent.state.FacilityId
                                                ? currentComponent.state.FacilityId
                                                : 0
                                        ),
                                        isNew: currentComponent.state.isNew,
                                        DrState: currentComponent.state.DrState,
                                        DrZipCode: currentComponent.state.DrZipCode,
                                        DrPhone: currentComponent.state.Phone,
                                        DrFax: currentComponent.state.DrFax,
                                        RushRequest: currentComponent.state.RushReq,
                                        AuthDocs: currentComponent.state.Files,
                                        SpecialInstruction: currentComponent.state.SpecialInstruction,
                                        SpecialType: currentComponent.state.SpecialType,
                                        DocEdit: true,
                                        DocDelete: true,
                                        StartDate: _startDate,
                                        EndDate: _EndDate,
                                        DateOfServiceType: currentComponent.state.DatesOfService,
                                        IsMissingFacilityDetails: currentComponent.state.IsMissingFacilityDetails,
                                        CustomerProvidedFacility: currentComponent.state.CustomerProvidedFacility
                                    },];
                            }
                        }
                    } else {
                        if (currentComponent.state.DoctorFirstName != "" || currentComponent.state.DoctorLastName != "" || currentComponent.state.DrEmailAddress != "" || currentComponent.state.DrAddress != "" ||
                            currentComponent.state.DoctorCity != "" || currentComponent.state.DrStateId != "Select" || currentComponent.state.DrZipCode != "" || currentComponent.state.Phone != "" ||
                            currentComponent.state.DrFax != "" || currentComponent.state.SpecialType != "" || currentComponent.state.StartDate != "" ||
                            currentComponent.state.EndDate != "" || currentComponent.state.SpecialInstruction != "" || currentComponent.state.DatesOfService != "" || currentComponent.state.CustomStartDate != "" ||
                            currentComponent.state.CustomEndDate != "" || currentComponent.state.MedicalRequestType != "" || currentComponent.state.BillingRequestType != "" || currentComponent.state.FilmsRequestType != "" ||
                            currentComponent.state.OtherRequestType != "" || currentComponent.state.FacilityName != "" || currentComponent.state.Files.length > 0) {
                            facility = [
                                ...facility,
                                {
                                    DrFacilityName: currentComponent.state.FacilityName,
                                    DrFirstName: currentComponent.state.DoctorFirstName,
                                    DrLastName: currentComponent.state.DoctorLastName,
                                    DrEmailAddress: currentComponent.state.DrEmailAddress,
                                    DrAddress: currentComponent.state.DrAddress,
                                    DrCity: currentComponent.state.DoctorCity,
                                    DrStateId: currentComponent.state.DrStateId != "Select" ? parseInt(currentComponent.state.DrStateId) : 0,
                                    DrState: currentComponent.state.DrState,
                                    DrZipCode: currentComponent.state.DrZipCode,
                                    DrPhone: currentComponent.state.Phone,
                                    DrFax: currentComponent.state.DrFax,
                                    RushRequest: currentComponent.state.RushReq,
                                    Id: currentComponent.state.FacilityId
                                        ? currentComponent.state.FacilityId
                                        : 0,
                                    isNew: currentComponent.state.isNew,
                                    AuthDocs: currentComponent.state.Files,
                                    SpecialInstruction: currentComponent.state.SpecialInstruction,
                                    SpecialType: currentComponent.state.SpecialType,
                                    StartDate: _startDate,
                                    EndDate: _EndDate,
                                    IsMissingFacilityDetails: currentComponent.state.IsMissingFacilityDetails,
                                    CustomerProvidedFacility: currentComponent.state.CustomerProvidedFacility
                                },
                            ];
                        }
                    }
                    currentComponent.setState({ Loading: true });
                    var orderid = 0;
                    if (isFromInboundOrder) {
                        var urlParams = new URLSearchParams(window.location.search);
                        orderid = urlParams.get('id');
                    }
                    let isTimeOutFromPlaceorder = localStorage.getItem("IsTimeOutFromPlaceOrder");
                    let url = "api/placeorder/HoldOrder";
                    var data = {
                        OrderingOfficeId: this.state.OrderingOfficeId,
                        HoldOrderId: this.state.HoldOrderId == "0" ? "" : this.state.HoldOrderId,
                        IsDefaultOrderingOffice: this.state.IsDefaultOrderingOffice,
                        AgId: this.state.AgId == "" ? 0 : this.state.AgId,
                        AdId: this.state.AdId == "" ? 0 : this.state.AdId,
                        PatientFN: Api.encryptData(this.state.FirstName),
                        PatientLN: Api.encryptData(this.state.LastName),
                        Title: Api.encryptData(this.state.Title),
                        SSN: Api.encryptData(this.state.SSN),
                        Address: Api.encryptData(this.state.PCAddress),
                        AgentFN: this.state.AgentFN,
                        AgentLN: this.state.AgentLN,
                        AgentId: this.state.AgentId,
                        AgentPhone: this.state.AgentPhone,
                        AgentExt: this.state.AgentExt,
                        AgentCell: this.state.AgentMobile,
                        AgentFax: this.state.AgentFax,
                        AgentEmail: this.state.AgentEmail,
                        IsAgentId: this.state.IsAgentId,
                        AdjusterFN: this.state.AdjusterFN,
                        AdjusterLN: this.state.AdjusterLN,
                        AdjusterId: this.state.AdjusterId,
                        AdjusterPhone: this.state.AdjusterPhone,
                        AdjusterExt: this.state.AdjusterExt,
                        AdjusterCell: this.state.AdjusterMobile,
                        AdjusterFax: this.state.AdjusterFax,
                        AdjusterEmail: this.state.AdjusterEmail,
                        IsAdjusterId: this.state.IsAdjusterId,
                        City: Api.encryptData(this.state.PCCity),
                        StateId: this.state.StateId != "Select" ? parseInt(this.state.StateId) : 0,
                        Zip: Api.encryptData(this.state.PCZipCode),
                        DOB: this.state.DOB != "" ? Api.encryptData(this.formatDateToString(this.state.DOB)) : "",
                        Email: Api.encryptData(this.state.Email),
                        Gender: this.state.Gender != "Select" ? this.state.Gender : "",
                        Policy: Api.encryptData(this.state.PolicyNum),
                        Phone1: Api.encryptData(this.state.Phone1),
                        Phone2: Api.encryptData(this.state.Phone2),
                        Phone3: Api.encryptData(this.state.Phone3),
                        PreferredContact: Api.encryptData(this.state.PreferredContact),
                        OtherName: Api.encryptData(this.state.OtherNames),
                        Phone1Type: this.state.Phone1Type != "Select" ? this.state.Phone1Type : "",
                        Phone2Type: this.state.Phone2Type != "Select" ? this.state.Phone2Type : "",
                        Phone3Type: this.state.Phone3Type != "Select" ? this.state.Phone3Type : "",
                        Fax: Api.encryptData(this.state.Fax),
                        Ext1: Api.encryptData(this.state.Ext1),
                        Ext2: Api.encryptData(this.state.Ext2),
                        Ext3: Api.encryptData(this.state.Ext3),
                        IsHold: this.state.isHold,
                        UserId: this.state.UserId,
                        Facility: facility,
                        PolicyAmount: Api.encryptData((this.state.PolicyAmount ? this.state.PolicyAmount : "0")),
                        OtherField1: Api.encryptData(this.state.OtherField1),
                        OtherField2: Api.encryptData(this.state.OtherField2),
                        OtherField3: Api.encryptData(this.state.OtherField3),
                        OtherField4: Api.encryptData(this.state.OtherField4),
                        DateOfIncident: Api.encryptData(this.state.DateOfIncident
                            ? this.formatDateToString(this.state.DateOfIncident)
                            : ""),
                        CompanyType: CompanyType,
                        IsFromInboundOrder: isFromInboundOrder,
                        InboundOrderId: orderid,
                        CMId: this.state.CMId,
                        CMFN: this.state.CMFN,
                        CMLN: this.state.CMLN,
                        CMEmailAddress: this.state.CMEmailAddress,
                        CMWorkPhone: this.state.CMWorkPhone,
                        CMExt: this.state.CMExt,
                        CMCellPhone: this.state.CMCellPhone,
                        CMFax: this.state.CMFax,
                        IsTimeOutfromPlaceOrder: isTimeOutFromPlaceorder==="true"? true:false,
                    };

                    Api.Post(url, data, currentComponent)
                        .then(function (resul) {
                            if (resul.statusMessage == "Success") {
                                currentComponent.state.HoldOrderId = resul.holdOrderId;
                                localStorage.setItem("HoldOrderId", resul.holdOrderId);
                                currentComponent.setState({ Loading: false });
                            } else {
                                currentComponent.setState({ Loading: false });
                                currentComponent.toasterredfun2(resul.statusMessage);
                            }
                        })
                        .catch(function (error) {
                            currentComponent.setState({ Loading: false });
                            if (error == "TypeError: Failed to fetch") {
                                currentComponent.toasterredfun2("Check your internet connection.");
                            } else {
                                currentComponent.toasterredfun2(error);
                            }
                        });
                }
                else {
                    currentComponent.setState({ Loading: false });
                    currentComponent.toasterredfun2("Company type not available. Please try again.");
                }
            }
        }
    }

    submitOrder() {        
        var CompanyType = localStorage.getItem("CompanyType");
        let isDateofServiceandRequestSelected = true;
        var isFromInboundOrder = window.location.href.indexOf("isFromInboundOrder") != -1 ? true : false;
        let currentComponent = this,
            valid = true,
            dob = "",
            facility = this.state.DocList;
        var CompanyType = currentComponent.state.CompanyType;

        //if (currentComponent.state.FirstName.trim().length == 0) {
        //    currentComponent.setState({ pFirstName: true });
        //    valid = false;
        //}
        //if (currentComponent.state.LastName.trim().length == 0) {
        //    currentComponent.setState({ pLastName: true });
        //    valid = false;
        //}
        if (currentComponent.state.SSN.trim().length != 0) {
            if (!currentComponent.testSSN(currentComponent.state.SSN)) {
                valid = false;
                currentComponent.setState({ pSSN: true });
            }
            if (!currentComponent.state.ssnChecked) {
                valid = false;
                currentComponent.setState({ pSSN: true });
            }
        }
        var dOb = currentComponent.state.DOB.toString();
        if (
            dOb.toLowerCase() == "invalid date"
        ) {
            currentComponent.setState({ pDOB: true });
            valid = false;
        }
        //if (currentComponent.state.PCCity.trim().length == 0) {
        //    currentComponent.setState({ pPCCity: true });
        //    valid = false;
        //}
        if (
            currentComponent.state.StateId.trim().length == 0 ||
            currentComponent.state.StateId == "Select" ||
            currentComponent.state.StateId == "0"
        ) {
            currentComponent.setState({ pStateId: true });
            valid = false;
        }
        if (currentComponent.state.PCZipCode.length != 0) {
            if (currentComponent.state.PCZipCode.length < 5) {
                currentComponent.setState({ pPCZipCode: true });
                valid = false;
            } else if (currentComponent.state.PCZipCode.length == 5) {
                if (!currentComponent.testZip(currentComponent.state.PCZipCode)) {
                    currentComponent.setState({ pPCZipCode: true });
                    valid = false;
                }
            } else if (currentComponent.state.PCZipCode.length > 5) {
                if (currentComponent.state.PCZipCode.length != 10) {
                    currentComponent.setState({ pPCZipCode: true });
                    valid = false;
                } else if (!currentComponent.testZip5(currentComponent.state.PCZipCode)) {
                    currentComponent.setState({ pPCZipCode: true });
                    valid = false;
                }
            }
        }
        //if (
        //    currentComponent.state.Gender.trim().length == 0 ||
        //    currentComponent.state.Gender.trim() == "Select"
        //) {
        //    currentComponent.setState({ pGender: true });
        //    valid = false;
        //}
        if (currentComponent.state.Email.trim().length != 0 && currentComponent.state.Email != null) {
            if (currentComponent.state.pEmail) {
                currentComponent.setState({ pEmail: true });
                valid = false;
            }
        }
        if (currentComponent.state.DrEmailAddress != null) {
            if (currentComponent.state.DrEmailAddress.trim().length != 0) {
                if (currentComponent.state.pDrEmailAddress) {
                    currentComponent.setState({ pDrEmailAddress: true });
                    valid = false;
                }
            }
        }      
        //if (currentComponent.state.PCAddress.trim().length == 0) {
        //    currentComponent.setState({ pPCAddress: true });
        //    valid = false;
        //}
        //if (currentComponent.state.Phone1.trim().length == 0) {
        //    currentComponent.setState({ pPhone1: true });
        //    valid = false;
        //}
        if (currentComponent.state.Phone1.length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.Phone1)) {
                currentComponent.setState({ pPhone1: true });
                valid = false;
            }
        }
        //if (
        //    currentComponent.state.Phone1Type == "" ||
        //    currentComponent.state.Phone1Type == "Select"
        //) {
        //    currentComponent.setState({ pPhone1Type: true });
        //    valid = false;
        //}
        if (currentComponent.state.Phone2.length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.Phone2)) {
                currentComponent.setState({ pPhone2: true });
                valid = false;
            }
            if (
                currentComponent.state.Phone2Type == "" ||
                currentComponent.state.Phone2Type == "Select"
            ) {
                currentComponent.setState({ pPhone2Type: true });
                valid = false;
            }
        }
        if (
            currentComponent.state.Ext2 != null &&
            currentComponent.state.Ext2.length != 0
        ) {
            //if (currentComponent.state.Phone2.length == 0)
            //{
            //    currentComponent.setState({ pPhone2: true });
            //    valid = false;
            //}
            if (!currentComponent.testPhone(currentComponent.state.Phone2)) {
                currentComponent.setState({ pPhone2: true });
                valid = false;
            }
            if (
                currentComponent.state.Phone2Type == null ||
                currentComponent.state.Phone2Type == "Select"
            ) {
                currentComponent.setState({ pPhone2Type: true });
                valid = false;
            }
        }
        if (
            currentComponent.state.Phone2Type != null &&
            currentComponent.state.Phone2Type != "Select" &&
            currentComponent.state.Phone2Type.length != 0
        ) {
            //if (currentComponent.state.Phone2.length == 0) {
            //    currentComponent.setState({ pPhone2: true });
            //    valid = false;
            //}
            if (!currentComponent.testPhone(currentComponent.state.Phone2)) {
                currentComponent.setState({ pPhone2: true });
                valid = false;
            }
        }
        if (currentComponent.state.Fax.length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.Fax)) {
                currentComponent.setState({ pFax: true });
                valid = false;
            }
        }



        //// Email validation for Agent 
        //if ((currentComponent.state.AgentFN.trim().length != 0 || currentComponent.state.AgentLN.trim().length != 0 || currentComponent.state.AgentPhone.trim().length != 0
        //    || currentComponent.state.AgentExt.trim().length != 0 || currentComponent.state.AgentFax.trim().length != 0 || currentComponent.state.AgentMobile.trim().length != 0) &&
        //    (currentComponent.state.AgentEmail.trim().length == 0 && currentComponent.state.AgentId.trim().length == 0)) {
        //    currentComponent.setState({ pAgentEmail: true });
        //    currentComponent.setState({ pAgentId: true });
        //    valid = false;
        //} else {
        //    currentComponent.setState({ pAgentEmail: false });
        //    currentComponent.setState({ pAgentId: false });
        //}

        if (currentComponent.state.AgentEmail.trim().length != 0 && currentComponent.state.AgentEmail != null) {
            if (currentComponent.state.pAgentEmail) {
                currentComponent.setState({ pAgentEmail: true });
                valid = false;
            }
        }


        // Email validation for Client Manager
        if ((currentComponent.state.CMFN.trim().length != 0 || currentComponent.state.CMLN.trim().length != 0 || currentComponent.state.CMWorkPhone.trim().length != 0 ||
            currentComponent.state.CMExt.trim().length != 0 || currentComponent.state.CMCellPhone.trim().length != 0 || currentComponent.state.CMFax.trim().length != 0) &&
            currentComponent.state.CMEmailAddress.trim().length == 0) {
            if (((currentComponent.state.isMandatoryCMFN || currentComponent.state.CMFNHide) || (currentComponent.state.isMandatoryCMLN || currentComponent.state.CMLNHide)) && (currentComponent.state.isMandatoryCMEmailAddress || currentComponent.state.CMEmailAddressHide)) {
                currentComponent.setState({ pCMEmailAddress: true });
                valid = false;
            } else {
                currentComponent.setState({ pCMEmailAddress: false });
            }
        }

        if (currentComponent.state.CMEmailAddress.trim().length != 0) {
            if (currentComponent.state.pCMEmailAddress) {
                currentComponent.setState({ pCMEmailAddress: true });
                valid = false;
            }
        }

        if (currentComponent.state.CMWorkPhone && currentComponent.state.CMWorkPhone.trim().length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.CMWorkPhone)) {
                currentComponent.setState({ pCMWorkPhone: true });
                valid = false;
            }
        }
        if (currentComponent.state.CMCellPhone && currentComponent.state.CMCellPhone.trim().length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.CMCellPhone)) {
                currentComponent.setState({ pCMCellPhone: true });
                valid = false;
            }
        }
        if (currentComponent.state.CMFax && currentComponent.state.CMFax.trim().length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.CMFax)) {
                currentComponent.setState({ pCMFax: true });
                valid = false;
            }
        }


        //// Email validation AdjusterAgent 
        //if ((currentComponent.state.AdjusterFN.trim().length != 0 || currentComponent.state.AdjusterLN.trim().length != 0 || currentComponent.state.AdjusterPhone.trim().length != 0 ||
        //    currentComponent.state.AdjusterExt.trim().length != 0 || currentComponent.state.AdjusterFax.trim().length != 0 || currentComponent.state.AdjusterMobile.trim().length != 0) &&
        //    (currentComponent.state.AdjusterEmail.trim().length == 0 && currentComponent.state.AdjusterId.trim().length == 0)) {
        //    currentComponent.setState({ pAdjusterEmail: true });
        //    currentComponent.setState({ pAdjusterId: true });
        //    valid = false;
        //} else {
        //    currentComponent.setState({ pAdjusterEmail: false });
        //    currentComponent.setState({ pAdjusterId: false });
        //}

        //if (currentComponent.state.AdjusterEmail.trim().length != 0) {
        //    if (
        //        !currentComponent.testEmail(currentComponent.state.AdjusterEmail.trim())
        //    ) {
        //        currentComponent.setState({ pAdjusterEmail: true });
        //        valid = false;
        //    }
        //}
        // if (currentComponent.state.AgentLN.trim().length == 0) {
        //     currentComponent.setState({ pAgentLN: true });
        // }
        // else if (currentComponent.state.AgentEmail.trim().length != 0) {
        //     if (
        //         !currentComponent.testEmail(currentComponent.state.AgentEmail.trim())
        //     ) {
        //         currentComponent.setState({ pAgentEmail: true });
        //         valid = false;
        //     }
        // }

        // if (currentComponent.state.AgentEmail.trim().length == 0) {
        //     currentComponent.setState({ pAgentEmail: true });
        // }
        //else if (currentComponent.state.AgentEmail.trim().length != 0) {
        //     if (
        //         !currentComponent.testEmail(currentComponent.state.AgentEmail.trim())
        //     ) {
        //         currentComponent.setState({ pAgentEmail: true });
        //         valid = false;
        //     }
        // }
        if (currentComponent.state.AgentPhone && currentComponent.state.AgentPhone.trim().length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.AgentPhone)) {
                currentComponent.setState({ pAgentPhone: true });
                valid = false;
            }
        }
        if (currentComponent.state.AgentFax && currentComponent.state.AgentFax.length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.AgentFax)) {
                currentComponent.setState({ pAgentFax: true });
                valid = false;
            }
        }
        if (currentComponent.state.AgentMobile && currentComponent.state.AgentMobile.length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.AgentMobile)) {
                currentComponent.setState({ pAgentMobile: true });
                valid = false;
            }
        }

        if (currentComponent.state.AdjusterEmail != null && currentComponent.state.AdjusterEmail.trim().length != 0) {
            if (currentComponent.state.pAdjusterEmail) {
                currentComponent.setState({ pAdjusterEmail: true });
                valid = false;
            }
        }

        if (currentComponent.state.AdjusterPhone && currentComponent.state.AdjusterPhone.trim().length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.AdjusterPhone)) {
                currentComponent.setState({ pAdjusterPhone: true });
                valid = false;
            }
        }
        if (currentComponent.state.AdjusterFax && currentComponent.state.AdjusterFax.length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.AdjusterFax)) {
                currentComponent.setState({ pAdjusterFax: true });
                valid = false;
            }
        }
        if (currentComponent.state.AdjusterMobile && currentComponent.state.AdjusterMobile.length != 0) {
            if (!currentComponent.testPhone(currentComponent.state.AdjusterMobile)) {
                currentComponent.setState({ pAdjusterMobile: true });
                valid = false;
            }
        }
        if (this.state.DateOfIncident.length != 0 && this.state.DOB instanceof Date) {
            if (new Date(this.state.DateOfIncident) < this.state.DOB) {
                currentComponent.setState({ pDateOfIncident: true });
                valid = false;
            }
        }
        if (this.state.OrderingOfficeList && this.state.OrderingOfficeList.length > 0) {
            if (!this.state.OrderingOfficeId) {
                currentComponent.setState({ orderingofficereq: true });
                valid = false;
            }
        } else {
            currentComponent.toasterredfun2(
                "Ordering office is not mapped/active, contact Administrator"
            );
            valid = false;
        }

        if (currentComponent.state.RequiredFieldsList.length > 0 && currentComponent.state.RequiredFieldsList != null) {
            var checkifEmpty = "";
            var checkLength = 0;
            for (var i = 0; i < currentComponent.state.RequiredFieldsList.length; i++) {
                var setStateVarName = [currentComponent.state.RequiredFieldsList[i].className];
                checkifEmpty = currentComponent.state[setStateVarName] ? currentComponent.state[setStateVarName].toString().trim() : "";
                checkLength = currentComponent.state[setStateVarName] ? currentComponent.state[setStateVarName].toString().length : "";

                var setStateIsMandatoryVarName = currentComponent.state.RequiredFieldsList[i].isRequired;
                // var psetStateVarName = currentComponent.useState({ ['p' + currentComponent.state.RequiredFieldsList[i].className] });
                currentComponent.setState({ ['isMandatory' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].isRequired });
                if ((checkifEmpty == "" || checkLength > 80 || currentComponent.state[setStateVarName] == "Select") && setStateIsMandatoryVarName && currentComponent.state.RequiredFieldsList[i].sectionId != 5) {
                    currentComponent.setState({ ['p' + currentComponent.state.RequiredFieldsList[i].className]: true });
                    valid = false;
                }
                //else {
                //    currentComponent.setState({ ['p' + currentComponent.state.RequiredFieldsList[i].className]: false });
                //}
                //else if (currentComponent.state.RequiredFieldsList[i].sectionId != 5) {
                //    debugger;
                //    if (currentComponent.state[psetStateVarName].check === true) {
                //        currentComponent.setState({ ['p' + currentComponent.state.RequiredFieldsList[i].className]: true });
                //    }
                //    else {
                //        currentComponent.setState({ ['p' + currentComponent.state.RequiredFieldsList[i].className]: false });
                //    }
                //}
            }
        }

        if (
            currentComponent.state.FacilityName.length != 0 ||
            currentComponent.state.DoctorFirstName.length != 0 ||
            currentComponent.state.DoctorLastName.length != 0 ||
            currentComponent.state.DoctorCity.length != 0 ||
            currentComponent.state.DrZipCode.length != 0 ||
            currentComponent.state.DrFax.length != 0 ||
            currentComponent.state.Phone.length != 0 ||
            currentComponent.state.SpecialType != "" ||
            currentComponent.state.SpecialInstruction != "" ||
            currentComponent.state.DocList.length == 0 ||
            currentComponent.state.Files.length != 0 ||
            currentComponent.state.RushReq == true ||
            currentComponent.state.DrAddress.length != 0 ||
            currentComponent.state.DrEmailAddress.length != 0 ||
            currentComponent.state.DrState.length != 0
        ) {
            if (currentComponent.state.FacilityName.trim().length == 0) {
                if (currentComponent.state.DoctorFirstName.trim().length == 0) {
                    currentComponent.setState({ pDoctorFirstName: true });
                    currentComponent.state.addvalid = false;
                    valid = false;
                }
                if (currentComponent.state.DoctorLastName.trim().length == 0) {
                    currentComponent.setState({ pDoctorLastName: true });
                    currentComponent.state.addvalid = false;
                    valid = false;
                }
            }
            if (currentComponent.state.Phone.length == 0) {
                currentComponent.setState({ pPhone: true });
                currentComponent.setState({ addvalid: false });
                currentComponent.state.addvalid = false;
                currentComponent.state.pPhone = true;
            }
            if (currentComponent.state.DoctorCity.trim().length == 0) {
                currentComponent.setState({ pDoctorCity: true });
                currentComponent.state.addvalid = false;
                valid = false;
            }
            if (currentComponent.state.DrEmailAddress != null) {
                if (currentComponent.state.DrEmailAddress.trim().length != 0) {
                    if (currentComponent.state.pDrEmailAddress) {
                        currentComponent.setState({ pDrEmailAddress: true });
                        currentComponent.state.addvalid = false;
                        valid = false;
                    }
                }
            }
            if (this.state.isMandatoryDrAddress) {
                if (currentComponent.state.DrAddress.trim().length == 0) {
                    currentComponent.setState({ pDrAddress: true, addvalid: false});
                    currentComponent.state.addvalid = false;
                    currentComponent.state.pDrAddress = true;
                    valid = false;
                }
            }
            //if (currentComponent.state.DrFax.trim().length == 0) {
            //    currentComponent.setState({ pDrFax: true });
            //    currentComponent.state.addvalid = false;
            //    valid = false;
            //}
            if (
                currentComponent.state.DrFax != null &&
                currentComponent.state.DrFax.length != 0
            ) {
                if (!currentComponent.testPhone(currentComponent.state.DrFax)) {
                    currentComponent.setState({ pDrFax: true, addvalid: false });
                    currentComponent.state.addvalid = false;
                    currentComponent.state.pDrFax = true;
                }
            }
            if (currentComponent.state.Phone.length != 0) {
                if (!currentComponent.testPhone(currentComponent.state.Phone)) {
                    currentComponent.setState({ pPhone: true, addvalid: false });
                    currentComponent.state.addvalid = false;
                    currentComponent.state.pPhone = true;
                }
            }
            if (currentComponent.state.SpecialType == "CustomDate") {
                if (
                    currentComponent.state.EndDate == "" ||
                    currentComponent.state.EndDate == "Invalid date"
                ) {
                    currentComponent.setState({ doed: true });
                    currentComponent.setState({ addvalid: false });
                    currentComponent.state.addvalid = false;
                    currentComponent.state.doed = true;
                }
                if (
                    currentComponent.state.StartDate == "" ||
                    currentComponent.state.StartDate == "Invalid date"
                ) {
                    currentComponent.setState({ dosd: true });
                    currentComponent.state.addvalid = false;
                    currentComponent.state.dosd = true;
                }

                if (
                    currentComponent.state.StartDate instanceof Date &&
                    currentComponent.state.EndDate instanceof Date &&
                    new Date(currentComponent.state.StartDate) <=
                    new Date(currentComponent.state.EndDate)
                ) {
                } else {
                    currentComponent.setState({ dosd: true });
                    currentComponent.setState({ addvalid: false });
                    currentComponent.state.addvalid = false;
                    currentComponent.state.dosd = true;
                }

                if (new Date(currentComponent.state.StartDate) instanceof Date) {
                    if (currentComponent.state.DOB.length != 0 && currentComponent.state.DOB instanceof Date) {
                        if (new Date(currentComponent.state.StartDate) < currentComponent.state.DOB) {
                            currentComponent.state.addvalid = false;
                            currentComponent.state.dosd = true;
                        }
                    }
                }
            }
            if (currentComponent.state.SpecialType != "") {
                if (currentComponent.state.SpecialInstruction == "") {
                    currentComponent.setState({ choseRadioxtext: true });
                    currentComponent.setState({ addvalid: false });
                    currentComponent.state.addvalid = false;
                    currentComponent.state.choseRadioxtext = true;
                }
            }
            //if (currentComponent.state.DoctorCity.length == 0) {
            //    currentComponent.setState({ pDoctorCity: true });
            //    currentComponent.setState({ addvalid: false });
            //    currentComponent.state.addvalid = false;
            //    currentComponent.state.pDoctorCity = true;
            //}
            if (
                currentComponent.state.DrZipCode.length == 0 ||
                currentComponent.state.DrZipCode.length < 5
            ) {
                currentComponent.setState({ pDrZipCode: true });
                currentComponent.setState({ addvalid: false });
                currentComponent.state.addvalid = false;
                currentComponent.state.pDrZipCode = true;
            } else if (currentComponent.state.DrZipCode.length > 5) {
                if (!currentComponent.testZip5(currentComponent.state.DrZipCode)) {
                    currentComponent.setState({ pDrZipCode: true });
                    currentComponent.setState({ addvalid: false });
                    currentComponent.state.addvalid = false;

                }
                if (currentComponent.state.DrZipCode.length != 10) {
                    currentComponent.setState({ pDrZipCode: true });
                    currentComponent.setState({ addvalid: false });
                    currentComponent.state.addvalid = false;
                    currentComponent.state.pDrZipCode = true;
                }
            }
            if (
                (currentComponent.state.FacilityName == null) && (currentComponent.state.DoctorFirstName == null) && (currentComponent.state.DoctorLastName == null)) {
                currentComponent.setState({ pDoctorLastName: true });
                currentComponent.setState({ pDoctorFirstName: true });
                currentComponent.setState({ pFacilityName: true });
                currentComponent.setState({ addvalid: false });
                currentComponent.state.addvalid = false;
                currentComponent.state.pDoctorLastName = true;
                currentComponent.state.pDoctorFirstName = true;
                currentComponent.state.pFacilityName = true;
            }
            if (
                currentComponent.state.DoctorFirstName.length != 0 &&
                (currentComponent.state.DoctorLastName == null ||
                    currentComponent.state.DoctorLastName.length == 0)
            ) {
                currentComponent.setState({ pDoctorLastName: true });
                currentComponent.setState({ addvalid: false });
                currentComponent.state.addvalid = false;
                currentComponent.state.pDoctorLastName = true;
            }
            if (
                currentComponent.state.DrState.length == 0 ||
                currentComponent.state.DrState == "Select" ||
                currentComponent.state.DrState == "0"
            ) {
                currentComponent.setState({ pDrStateId: true });
                currentComponent.state.addvalid = false;
            }
            if (
                (currentComponent.state.DoctorFirstName == null ||
                    currentComponent.state.DoctorFirstName.length == 0) &&
                currentComponent.state.DoctorLastName.length != 0
            ) {
                currentComponent.setState({ pDoctorFirstName: true });
                currentComponent.setState({ addvalid: false });
                currentComponent.state.addvalid = false;
                currentComponent.state.pDoctorFirstName = true;
            }
            if (currentComponent.state.OrderingOfficeList && currentComponent.state.OrderingOfficeList.length > 0) {
                if (!currentComponent.state.OrderingOfficeId) {
                    currentComponent.setState({ orderingofficereq: true });
                    valid = false;
                }
                //else {
                //    valid = currentComponent.handleOrderingOfficeChangeInSubmit(currentComponent.state.OrderingOfficeId);
                //}
            } else {
                currentComponent.toasterredfun2(
                    "Ordering office is not mapped/active, contact Administrator"
                );
                currentComponent.setState({ orderingofficereq: true });
                valid = false;
            }
            if (
                ((currentComponent.state.FacilityName != null &&
                    currentComponent.state.FacilityName.length != 0) ||
                    (currentComponent.state.DoctorFirstName != null &&
                        currentComponent.state.DoctorFirstName.length != 0 &&
                        currentComponent.state.DoctorLastName != null &&
                        currentComponent.state.DoctorLastName.length != 0)) &&
                currentComponent.state.DoctorCity.length != 0 &&
                currentComponent.state.DrStateId != "Select" &&
                (currentComponent.state.DrZipCode.length == 10 ||
                    currentComponent.state.DrZipCode.length == 5)
            ) {
                if (currentComponent.state.Files.length == 0) {
                    currentComponent.setState({ Files: "" });
                }
                if (CompanyType == "LEGAL") {
                    if (currentComponent.state.DatesOfService == "" || currentComponent.state.DatesOfService.length == 0) {
                        currentComponent.state.addvalid = false;
                        isDateofServiceandRequestSelected = false;
                        this.toasterredfun2("Dates of service is required");
                    } else if (currentComponent.state.DatesOfService == "Custom") {
                        if (
                            currentComponent.state.CustomStartDate == "" ||
                            currentComponent.state.CustomStartDate == "Invalid date"
                        ) {
                            currentComponent.setState({ csd: true, addvalid: false });
                            currentComponent.state.addvalid = false;
                            currentComponent.state.csd = false;
                        }
                        if (
                            currentComponent.state.CustomEndDate == "" ||
                            currentComponent.state.CustomEndDate == "Invalid date"
                        ) {
                            currentComponent.setState({ ced: true, addvalid: false });
                            currentComponent.state.addvalid = false;
                            currentComponent.state.ced = false;
                        }
                        if (
                            new Date(currentComponent.state.CustomStartDate) instanceof Date &&
                            new Date(currentComponent.state.CustomEndDate) instanceof Date &&
                            new Date(currentComponent.state.CustomStartDate) <=
                            new Date(currentComponent.state.CustomEndDate)
                        ) {
                        } else {
                            currentComponent.setState({ csd: true, addvalid: false });
                            currentComponent.state.addvalid = false;
                            currentComponent.state.csd = false;
                        }
                        if (new Date(currentComponent.state.CustomStartDate) instanceof Date) {
                            if (currentComponent.state.DOB.length != 0 && currentComponent.state.DOB instanceof Date) {
                                if (new Date(currentComponent.state.CustomStartDate) < currentComponent.state.DOB) {
                                    currentComponent.state.addvalid = false;
                                    currentComponent.state.csd = true;
                                }
                            }
                        }
                    }
                    if (currentComponent.state.addvalid && currentComponent.state.RequestTypes.length == 0) {
                        currentComponent.state.addvalid = false;
                        isDateofServiceandRequestSelected = false;
                        this.toasterredfun2("Atleast one request type is required");
                    }
                }
            }
            if (currentComponent.state.addvalid && valid) {
                //var ispresent = false;
                //var matchedRequestType = "";

                for (var i = 0; i < facility.length; i++) {
                    var isValid = true;
                    if ((isNullOrUndefined(facility[i].DrFacilityName) || facility[i].DrFacilityName.length == 0) &&
                        (isNullOrUndefined(facility[i].DrFirstName) || facility[i].DrFirstName.length == 0) &&
                        (isNullOrUndefined(facility[i].DrLastName) || facility[i].DrLastName.length == 0)) {
                        currentComponent.setState({ pDoctorLastName: true });
                        currentComponent.setState({ pDoctorFirstName: true });
                        currentComponent.setState({ pFacilityName: true });
                        currentComponent.setState({ addvalid: false });
                        currentComponent.state.addvalid = false;
                        currentComponent.state.pDoctorLastName = true;
                        currentComponent.state.pDoctorFirstName = true;
                        currentComponent.state.pFacilityName = true;
                        isValid = false;
                    }
                    if (facility[i].DrState.length == 0 || facility[i].DrState == "Select" ||
                        facility[i].DrState == "0") {
                        currentComponent.setState({ pDrStateId: true });
                        currentComponent.state.addvalid = false;
                        isValid = false;
                    }

                    if (facility[i].DrCity.trim().length == 0) {
                        currentComponent.setState({ pDoctorCity: true });
                        currentComponent.state.addvalid = false;
                        isValid = false;
                    }

                    if (facility[i].DrZipCode.length == 0 || facility[i].DrZipCode.length < 5) {
                        currentComponent.setState({ pDrZipCode: true });
                        currentComponent.setState({ addvalid: false });
                        currentComponent.state.addvalid = false;
                        currentComponent.state.pDrZipCode = true;
                        isValid = false;
                    }
                    else if (facility[i].DrZipCode.length > 5) {
                        if (!currentComponent.testZip5(facility[i].DrZipCode)) {
                            currentComponent.setState({ pDrZipCode: true });
                            currentComponent.setState({ addvalid: false });
                            currentComponent.state.addvalid = false;
                            isValid = false;
                        }
                        if (facility[i].DrZipCode.length != 10) {
                            currentComponent.setState({ pDrZipCode: true });
                            currentComponent.setState({ addvalid: false });
                            currentComponent.state.addvalid = false;
                            currentComponent.state.pDrZipCode = true;
                            isValid = false;
                        }
                    }
                    if (facility[i].DrFax != null && facility[i].DrFax.length != 0) {
                        if (!currentComponent.testPhone(facility[i].DrFax)) {
                            currentComponent.setState({ pDrFax: true, addvalid: false });
                            currentComponent.state.addvalid = false;
                            currentComponent.state.pDrFax = true;
                            isValid = false;
                        }
                    }
                    if (facility[i].DrPhone.length == 0) {
                        currentComponent.setState({ pPhone: true });
                        currentComponent.setState({ addvalid: false });
                        currentComponent.state.addvalid = false;
                        currentComponent.state.pPhone = true;
                    }
                    if (CompanyType == "LEGAL") {

                        if (facility[i].DateOfServiceType == "" || facility[i].DateOfServiceType.length == 0) {
                            currentComponent.state.addvalid = false;
                            isDateofServiceandRequestSelected = false;
                            this.toasterredfun2("Dates of service is required");
                            isValid = false;
                        } else if (facility[i].DateOfServiceType == "Custom") {
                            if (facility[i].StartDate == "" || facility[i].StartDate == "Invalid date") {
                                currentComponent.setState({ csd: true, addvalid: false });
                                currentComponent.state.addvalid = false;
                                currentComponent.state.csd = false;
                                isValid = false;
                            }
                            if (facility[i].EndDate == "" || facility[i].EndDate == "Invalid date") {
                                currentComponent.setState({ ced: true, addvalid: false });
                                currentComponent.state.addvalid = false;
                                currentComponent.state.ced = false;
                                isValid = false;
                            }
                            //debugger
                            if (new Date(facility[i].StartDate) instanceof Date &&
                                new Date(facility[i].EndDate) instanceof Date &&
                                new Date(facility[i].StartDate) <= new Date(facility[i].EndDate)) {
                            } else {
                                currentComponent.setState({ csd: true, addvalid: false });
                                currentComponent.state.addvalid = false;
                                currentComponent.state.csd = false;
                                isValid = false;
                            }
                            if (new Date(facility[i].StartDate) instanceof Date) {
                                if (!isNullOrUndefined(facility[i].DOB) && facility[i].DOB.length != 0 && facility[i].DOB instanceof Date) {
                                    if (new Date(facility[i].StartDate) < facility[i].DOB) {
                                        currentComponent.state.addvalid = false;
                                        currentComponent.state.csd = true;
                                        isValid = false;
                                    }
                                }
                            }
                        }
                        if (isValid == true) {
                            if (facility[i].FacilityRequests.length > 0) {
                                if (currentComponent.state.addvalid && facility[i].FacilityRequests[0].RequestType.length == 0) {
                                    currentComponent.state.addvalid = false;
                                    isDateofServiceandRequestSelected = false;
                                    this.toasterredfun2("Atleast one request type is required");
                                    isValid = false;
                                }
                            }
                            else {
                                currentComponent.state.addvalid = false;
                                isDateofServiceandRequestSelected = false;
                                this.toasterredfun2("Atleast one request type is required");
                                isValid = false;
                            }
                        }
                    }
                    //debugger
                    if (!isNullOrUndefined(facility[i].CustomerProvidedFacility)) {
                        if (facility[i].CustomerProvidedFacility.toString().replace(/\s/g, '') == ",,,,,,") {
                            var customerProvidedFacility = "";
                            if (!isNullOrUndefined(facility[i].DrFirstName) || facility[i].DrFirstName.length != 0) {
                                customerProvidedFacility += "Dr " + facility[i].DrFirstName;
                            }
                            if (!isNullOrUndefined(facility[i].DrLastName) || facility[i].DrLastName.length != 0) {
                                customerProvidedFacility += facility[i].DrFirstName ? " " + facility[i].DrLastName : "Dr " + facility[i].DrLastName;
                            }
                            customerProvidedFacility += ", " + facility[i].DrFacilityName + ", " + facility[i].DrAddress + ", " + facility[i].DrCity + ", " + facility[i].DrState + ", " + facility[i].DrZipCode + ", " + (facility[i].DrPhone ? "Ph: " + facility[i].DrPhone : "");
                            facility[i].CustomerProvidedFacility = customerProvidedFacility;
                        }
                    }
                    else {
                        var customerProvidedFacility = "";
                        if (!isNullOrUndefined(facility[i].DrFirstName) || facility[i].DrFirstName.length != 0) {
                            customerProvidedFacility += "Dr " + facility[i].DrFirstName;
                        }
                        if (!isNullOrUndefined(facility[i].DrLastName) || facility[i].DrLastName.length != 0) {
                            customerProvidedFacility += facility[i].DrFirstName ? " " + facility[i].DrLastName : "Dr " + facility[i].DrLastName;
                        }
                        customerProvidedFacility += ", " + facility[i].DrFacilityName + ", " + facility[i].DrAddress + ", " + facility[i].DrCity + ", " + facility[i].DrState + ", " + facility[i].DrZipCode + ", " + (facility[i].DrPhone ? "Ph: " + facility[i].DrPhone : "");
                        facility[i].CustomerProvidedFacility = customerProvidedFacility;
                    }

                    if (isValid == false) {
                        valid = false;
                        this.editDoctor(i);
                        break;
                    }
                }

                if (currentComponent.state.addvalid && valid) {
                    if (CompanyType == "LEGAL") {
                        var docList = currentComponent.state.DocList;
                        currentComponent.state.RequestTypes.map((requestType) => {
                            var customerProvidedFacility = "";
                            if (currentComponent.state.DoctorFirstName) {
                                customerProvidedFacility += "Dr " + currentComponent.state.DoctorFirstName;
                            }

                            if (currentComponent.state.DoctorLastName) {
                                customerProvidedFacility += currentComponent.state.DoctorFirstName ? " " + currentComponent.state.DoctorLastName : "Dr " + currentComponent.state.DoctorLastName;
                            }
                            customerProvidedFacility += ", " + currentComponent.state.FacilityName + ", " + currentComponent.state.DrAddress + ", " + currentComponent.state.DoctorCity + ", " + currentComponent.state.DrState + ", " + currentComponent.state.DrZipCode + ", " + (currentComponent.state.Phone ? "Ph: " + currentComponent.state.Phone : "");

                            //if (/\s{2,}/g.test(e)) {

                            //}

                            currentComponent.state.CustomerProvidedFacility = customerProvidedFacility;
                                                  
                            docList.push({
                                DrFacilityName: currentComponent.state.FacilityName,
                                DrFirstName: currentComponent.state.DoctorFirstName,
                                DrLastName: currentComponent.state.DoctorLastName,
                                DrEmailAddress: currentComponent.state.DrEmailAddress,
                                DrAddress: currentComponent.state.DrAddress,
                                DrCity: currentComponent.state.DoctorCity,
                                DrStateId: parseInt(currentComponent.state.DrStateId),
                                Id: parseInt(
                                    currentComponent.state.FacilityId
                                        ? currentComponent.state.FacilityId
                                        : 0
                                ),
                                isNew: currentComponent.state.isNew,
                                // StateId: currentComponent.state.DrStateId,
                                DrState: currentComponent.state.DrState,
                                DrZipCode: currentComponent.state.DrZipCode,
                                DrPhone: currentComponent.state.Phone,
                                DrFax: currentComponent.state.DrFax,
                                RushRequest: currentComponent.state.RushReq,
                                AuthDocs: currentComponent.state.Files,
                                SpecialInstruction: currentComponent.state.SpecialInstruction,
                                SpecialType: currentComponent.state.SpecialType,
                                DocEdit: true,
                                DocDelete: true,
                                StartDate: CompanyType == "LEGAL" ? currentComponent.formatDateToString(currentComponent.state.CustomStartDate) : currentComponent.formatDateToString(currentComponent.state.StartDate),
                                EndDate: CompanyType == "LEGAL" ? currentComponent.formatDateToString(currentComponent.state.CustomEndDate) : currentComponent.formatDateToString(currentComponent.state.EndDate),
                                DateOfServiceType: currentComponent.state.DatesOfService,
                                FacilityRequests: [requestType],
                                IsMissingFacilityDetails: currentComponent.state.IsMissingFacilityDetails,
                                CustomerProvidedFacility: currentComponent.state.CustomerProvidedFacility
                            });
                        });

                        currentComponent.setState({
                            DocList: docList
                        });

                        facility = currentComponent.state.DocList;
                    } else {
                        var customerProvidedFacility = "";
                        if (currentComponent.state.DoctorFirstName) {
                            customerProvidedFacility += "Dr " + currentComponent.state.DoctorFirstName;
                        }

                        if (currentComponent.state.DoctorLastName) {
                            customerProvidedFacility += currentComponent.state.DoctorFirstName ? " " + currentComponent.state.DoctorLastName : "Dr " + currentComponent.state.DoctorLastName;
                        }
                        customerProvidedFacility += ", " + currentComponent.state.FacilityName + ", " + currentComponent.state.DrAddress + ", " + currentComponent.state.DoctorCity + ", " + currentComponent.state.DrState + ", " + currentComponent.state.DrZipCode + ", " + (currentComponent.state.Phone ? "Ph: " + currentComponent.state.Phone : "");

                        //if (/\s{2,}/g.test(e)) {

                        //}

                        currentComponent.state.CustomerProvidedFacility = customerProvidedFacility;
                                           
                        currentComponent.setState({
                            DocList: [
                                ...currentComponent.state.DocList,
                                {
                                    DrFacilityName: currentComponent.state.FacilityName,
                                    DrFirstName: currentComponent.state.DoctorFirstName,
                                    DrLastName: currentComponent.state.DoctorLastName,
                                    DrAddress: currentComponent.state.DrAddress,
                                    DrEmailAddress: currentComponent.state.DrEmailAddress,
                                    DrCity: currentComponent.state.DoctorCity,
                                    DrStateId: parseInt(currentComponent.state.DrStateId),
                                    // StateId: currentComponent.state.DrStateId,
                                    DrState: currentComponent.state.DrState,
                                    DrZipCode: currentComponent.state.DrZipCode,
                                    DrPhone: currentComponent.state.Phone,
                                    DrFax: currentComponent.state.DrFax,
                                    RushRequest: currentComponent.state.RushReq,
                                    Id: currentComponent.state.FacilityId
                                        ? currentComponent.state.FacilityId
                                        : 0,
                                    isNew: currentComponent.state.isNew,
                                    AuthDocs: currentComponent.state.Files,
                                    SpecialInstruction: currentComponent.state.SpecialInstruction,
                                    SpecialType: currentComponent.state.SpecialType,
                                    StartDate: currentComponent.formatDateToString(currentComponent.state.StartDate),
                                    EndDate: currentComponent.formatDateToString(currentComponent.state.EndDate),
                                    DocEdit: true,
                                    DocDelete: true,
                                    IsMissingFacilityDetails: currentComponent.state.IsMissingFacilityDetails,
                                    CustomerProvidedFacility: currentComponent.state.CustomerProvidedFacility
                                },
                            ],
                        });

                        facility = [
                            ...facility,
                            {
                                DrFacilityName: currentComponent.state.FacilityName,
                                DrFirstName: currentComponent.state.DoctorFirstName,
                                DrLastName: currentComponent.state.DoctorLastName,
                                DrEmailAddress: currentComponent.state.DrEmailAddress,
                                DrAddress: currentComponent.state.DrAddress,
                                DrCity: currentComponent.state.DoctorCity,
                                DrStateId: parseInt(currentComponent.state.DrStateId),
                                // StateId: currentComponent.state.DrStateId,
                                DrState: currentComponent.state.DrState,
                                DrZipCode: currentComponent.state.DrZipCode,
                                DrPhone: currentComponent.state.Phone,
                                DrFax: currentComponent.state.DrFax,
                                RushRequest: currentComponent.state.RushReq,
                                Id: currentComponent.state.FacilityId
                                    ? currentComponent.state.FacilityId
                                    : 0,
                                isNew: currentComponent.state.isNew,
                                AuthDocs: currentComponent.state.Files,
                                SpecialInstruction: currentComponent.state.SpecialInstruction,
                                SpecialType: currentComponent.state.SpecialType,
                                StartDate: CompanyType == "LEGAL" ? currentComponent.formatDateToString(currentComponent.state.CustomStartDate) : currentComponent.formatDateToString(currentComponent.state.StartDate),
                                EndDate: CompanyType == "LEGAL" ? currentComponent.formatDateToString(currentComponent.state.CustomEndDate) : currentComponent.formatDateToString(currentComponent.state.EndDate),
                                IsMissingFacilityDetails: currentComponent.state.IsMissingFacilityDetails,
                                CustomerProvidedFacility: currentComponent.state.CustomerProvidedFacility
                            },
                        ];
                    }
                    currentComponent.clearFacility();
                }
            }
        }
        else {            

            for (var i = 0; i < facility.length; i++) {
                var isValid = true;
                if ((isNullOrUndefined(facility[i].DrFacilityName) || facility[i].DrFacilityName.length == 0) &&
                    (isNullOrUndefined(facility[i].DrFirstName) || facility[i].DrFirstName.length == 0) &&
                    (isNullOrUndefined(facility[i].DrLastName) || facility[i].DrLastName.length == 0)) {
                    currentComponent.setState({ pDoctorLastName: true });
                    currentComponent.setState({ pDoctorFirstName: true });
                    currentComponent.setState({ pFacilityName: true });
                    currentComponent.setState({ addvalid: false });
                    currentComponent.state.addvalid = false;
                    currentComponent.state.pDoctorLastName = true;
                    currentComponent.state.pDoctorFirstName = true;
                    currentComponent.state.pFacilityName = true;
                    isValid = false;
                }
                if (facility[i].DrState.length == 0 || facility[i].DrState == "Select" ||
                    facility[i].DrState == "0") {
                    currentComponent.setState({ pDrStateId: true });
                    currentComponent.state.addvalid = false;
                    isValid = false;
                }

                if (facility[i].DrCity.trim().length == 0) {
                    currentComponent.setState({ pDoctorCity: true });
                    currentComponent.state.addvalid = false;
                    isValid = false;
                }

                if (facility[i].DrZipCode.length == 0 || facility[i].DrZipCode.length < 5) {
                    currentComponent.setState({ pDrZipCode: true });
                    currentComponent.setState({ addvalid: false });
                    currentComponent.state.addvalid = false;
                    currentComponent.state.pDrZipCode = true;
                    isValid = false;
                }
                else if (facility[i].DrZipCode.length > 5) {
                    if (!currentComponent.testZip5(facility[i].DrZipCode)) {
                        currentComponent.setState({ pDrZipCode: true });
                        currentComponent.setState({ addvalid: false });
                        currentComponent.state.addvalid = false;
                        isValid = false;
                    }
                    if (facility[i].DrZipCode.length != 10) {
                        currentComponent.setState({ pDrZipCode: true });
                        currentComponent.setState({ addvalid: false });
                        currentComponent.state.addvalid = false;
                        currentComponent.state.pDrZipCode = true;
                        isValid = false;
                    }
                }
                if (facility[i].DrFax != null && facility[i].DrFax.length != 0) {
                    if (!currentComponent.testPhone(facility[i].DrFax)) {
                        currentComponent.setState({ pDrFax: true, addvalid: false });
                        currentComponent.state.addvalid = false;
                        currentComponent.state.pDrFax = true;
                        isValid = false;
                    }
                }
                if (facility[i].DrPhone.length == 0) {
                    currentComponent.setState({ pPhone: true });
                    currentComponent.setState({ addvalid: false });
                    currentComponent.state.addvalid = false;
                    currentComponent.state.pPhone = true;
                }
                if (CompanyType == "LEGAL") {

                    if (facility[i].DateOfServiceType == "" || facility[i].DateOfServiceType.length == 0) {
                        currentComponent.state.addvalid = false;
                        isDateofServiceandRequestSelected = false;
                        this.toasterredfun2("Dates of service is required");
                        isValid = false;
                    } else if (facility[i].DateOfServiceType == "Custom") {
                        if (facility[i].StartDate == "" || facility[i].StartDate == "Invalid date") {
                            currentComponent.setState({ csd: true, addvalid: false });
                            currentComponent.state.addvalid = false;
                            currentComponent.state.csd = false;
                            isValid = false;
                        }
                        if (facility[i].EndDate == "" || facility[i].EndDate == "Invalid date") {
                            currentComponent.setState({ ced: true, addvalid: false });
                            currentComponent.state.addvalid = false;
                            currentComponent.state.ced = false;
                            isValid = false;
                        }
                        //debugger
                        if (new Date(facility[i].StartDate) instanceof Date &&
                            new Date(facility[i].EndDate) instanceof Date &&
                            new Date(facility[i].StartDate) <= new Date(facility[i].EndDate)) {
                        } else {
                            currentComponent.setState({ csd: true, addvalid: false });
                            currentComponent.state.addvalid = false;
                            currentComponent.state.csd = false;
                            isValid = false;
                        }
                        if (new Date(facility[i].StartDate) instanceof Date) {
                            if (!isNullOrUndefined(facility[i].DOB) && facility[i].DOB.length != 0 && facility[i].DOB instanceof Date) {
                                if (new Date(facility[i].StartDate) < facility[i].DOB) {
                                    currentComponent.state.addvalid = false;
                                    currentComponent.state.csd = true;
                                    isValid = false;
                                }
                            }
                        }
                    }
                    if (isValid == true) {
                        if (facility[i].FacilityRequests.length > 0) {
                            if (currentComponent.state.addvalid && facility[i].FacilityRequests[0].RequestType.length == 0) {
                                currentComponent.state.addvalid = false;
                                isDateofServiceandRequestSelected = false;
                                this.toasterredfun2("Atleast one request type is required");
                                isValid = false;
                            }
                        }
                        else {
                            currentComponent.state.addvalid = false;
                            isDateofServiceandRequestSelected = false;
                            this.toasterredfun2("Atleast one request type is required");
                            isValid = false;
                        }
                    }
                }
                //debugger
                if (!isNullOrUndefined(facility[i].CustomerProvidedFacility)) {
                    if (facility[i].CustomerProvidedFacility.toString().replace(/\s/g, '') == ",,,,,,") {
                        var customerProvidedFacility = "";
                        if (!isNullOrUndefined(facility[i].DrFirstName) || facility[i].DrFirstName.length != 0) {
                            customerProvidedFacility += "Dr " + facility[i].DrFirstName;
                        }
                        if (!isNullOrUndefined(facility[i].DrLastName) || facility[i].DrLastName.length != 0) {
                            customerProvidedFacility += facility[i].DrFirstName ? " " + facility[i].DrLastName : "Dr " + facility[i].DrLastName;
                        }
                        customerProvidedFacility += ", " + facility[i].DrFacilityName + ", " + facility[i].DrAddress + ", " + facility[i].DrCity + ", " + facility[i].DrState + ", " + facility[i].DrZipCode + ", " + (facility[i].DrPhone ? "Ph: " + facility[i].DrPhone : "");
                        facility[i].CustomerProvidedFacility = customerProvidedFacility;
                    }
                }
                else {
                    var customerProvidedFacility = "";
                    if (!isNullOrUndefined(facility[i].DrFirstName) || facility[i].DrFirstName.length != 0) {
                        customerProvidedFacility += "Dr " + facility[i].DrFirstName;
                    }
                    if (!isNullOrUndefined(facility[i].DrLastName) || facility[i].DrLastName.length != 0) {
                        customerProvidedFacility += facility[i].DrFirstName ? " " + facility[i].DrLastName : "Dr " + facility[i].DrLastName;
                    }
                    customerProvidedFacility += ", " + facility[i].DrFacilityName + ", " + facility[i].DrAddress + ", " + facility[i].DrCity + ", " + facility[i].DrState + ", " + facility[i].DrZipCode + ", " + (facility[i].DrPhone ? "Ph: " + facility[i].DrPhone : "");
                    facility[i].CustomerProvidedFacility = customerProvidedFacility;
                }

                if (isValid == false) {
                    valid = false;
                    this.editDoctor(i);
                    break;
                }
            }

            let isPresent = false;
            currentComponent.state.DocList.map((item, i) => {
                if (item.DocEdit) {
                    isPresent = true;
                }
            }, this);            
            if (!isPresent) {
                currentComponent.state.addvalid = false;
                currentComponent.setState({ pDoctorCity: true, pDrStateId: true, pDrZipCode: true, pPhone: true, pFacilityName: true, pDoctorFirstName: true, pDoctorLastName: true, pDrEmailAddress: true });
                $("html, body").animate({ scrollTop: 0 }, "slow");
            }            
            facility = currentComponent.state.DocList;   
        }

        if (!currentComponent.state.addvalid) {
            valid = false;
        }

        if (valid) {
            if (facility.length == 0) {
                //currentComponent.setState({pDoctorCity: true, pDrStateId: true, pDrZipCode: true, pPhone: true, pFacilityName: true, pDoctorFirstName: true, pDoctorLastName: true });
                valid = false;
            }
            //if (this.state.IsInvalidAuth) {
            //    valid = false;
            //}
        }

        if (!valid && isDateofServiceandRequestSelected) {
            currentComponent.toasterredfun2("Your order was not submitted. It is missing required data. Please correct the data, and click Submit Order again.");
        }        
        if (valid && currentComponent.state.IsOrderSent) {            
            currentComponent.setState({ Loading: true, IsOrderSent:false});
            
            var orderid = 0;
            if (isFromInboundOrder) {
                var urlParams = new URLSearchParams(window.location.search);
                orderid = urlParams.get('id');
            }
            let url = "api/placeorder/AddOrder";         
            facility = facility.map(fac => ({
                ...fac,
                StartDate: currentComponent.formatDateToString(fac.StartDate),
                EndDate: currentComponent.formatDateToString(fac.EndDate),
            }));
            var data = {
                OrderingOfficeId: this.state.OrderingOfficeId,
                HoldOrderId: this.state.HoldOrderId == "0" ? "" : this.state.HoldOrderId,
                IsDefaultOrderingOffice: this.state.IsDefaultOrderingOffice,
                AgId: this.state.AgId == "" ? 0 : this.state.AgId,
                AdId: this.state.AdId == "" ? 0 : this.state.AdId,
                PatientFN: Api.encryptData(this.state.FirstName),
                PatientLN: Api.encryptData(this.state.LastName),
                Title: Api.encryptData(this.state.Title),
                SSN: Api.encryptData(this.state.SSN),
                Address: Api.encryptData(this.state.PCAddress),
                AgentFN: this.state.AgentFN,
                AgentLN: this.state.AgentLN,
                AgentId: this.state.AgentId,
                AgentPhone: this.state.AgentPhone,
                AgentExt: this.state.AgentExt,
                AgentCell: this.state.AgentMobile,
                AgentFax: this.state.AgentFax,
                AgentEmail: this.state.AgentEmail,
                IsAgentId: this.state.IsAgentId,
                AdjusterFN: this.state.AdjusterFN,
                AdjusterLN: this.state.AdjusterLN,
                AdjusterId: this.state.AdjusterId,
                AdjusterPhone: this.state.AdjusterPhone,
                AdjusterExt: this.state.AdjusterExt,
                AdjusterCell: this.state.AdjusterMobile,
                AdjusterFax: this.state.AdjusterFax,
                AdjusterEmail: this.state.AdjusterEmail,
                IsAdjusterId: this.state.IsAdjusterId,
                City: Api.encryptData(this.state.PCCity),
                StateId: parseInt(this.state.StateId),
                Zip: Api.encryptData(this.state.PCZipCode),
                DOB: Api.encryptData(this.formatDateToString(this.state.DOB)),
                Email: Api.encryptData(this.state.Email),
                Gender: this.state.Gender,
                Policy: Api.encryptData(this.state.PolicyNum),
                Phone1: Api.encryptData(this.state.Phone1),
                Phone2: Api.encryptData(this.state.Phone2),
                Phone3: Api.encryptData(this.state.Phone3),
                PreferredContact: Api.encryptData(this.state.PreferredContact),
                OtherName: Api.encryptData(this.state.OtherNames),
                Phone1Type: this.state.Phone1Type,
                Phone2Type: this.state.Phone2Type,
                Phone3Type: this.state.Phone3Type,
                Fax: Api.encryptData(this.state.Fax),
                Ext1: Api.encryptData(this.state.Ext1),
                Ext2: Api.encryptData(this.state.Ext2),
                Ext3: Api.encryptData(this.state.Ext3),
                IsHold: this.state.isHold,
                UserId: this.state.UserId,
                Facility: facility,
                PolicyAmount: Api.encryptData((this.state.PolicyAmount ? this.state.PolicyAmount : "0")),
                OtherField1: Api.encryptData(this.state.OtherField1),
                OtherField2: Api.encryptData(this.state.OtherField2),
                OtherField3: Api.encryptData(this.state.OtherField3),
                OtherField4: Api.encryptData(this.state.OtherField4),
                DateOfIncident: Api.encryptData(this.state.DateOfIncident ? this.formatDateToString(this.state.DateOfIncident) : ""),
                CompanyType: CompanyType,
                IsFromInboundOrder: isFromInboundOrder,
                OfficeAccountNumber: this.state.OfficeAccountNumber,
                InboundOrderId: orderid,
                CMId: this.state.CMId,
                CMFN: this.state.CMFN,
                CMLN: this.state.CMLN,
                CMEmailAddress: this.state.CMEmailAddress,
                CMWorkPhone: this.state.CMWorkPhone,
                CMExt: this.state.CMExt,
                CMCellPhone: this.state.CMCellPhone,
                CMFax: this.state.CMFax,
                OrderGuid: this.state.OrderGuid,
                ProductCode: this.state.ProductCode,
                APSReason: this.state.APSReason
            };
            if (this.state.isUnsubmitted) {
                data = {
                    OrderingOfficeId: this.state.OrderingOfficeId,
                    HoldOrderId: this.state.HoldOrderId == "0" ? "" : this.state.HoldOrderId,
                    IsDefaultOrderingOffice: this.state.IsDefaultOrderingOffice,
                    PatientId: this.state.PatientId,
                    AgId: this.state.AgId == "" ? 0 : this.state.AgId,
                    AdId: this.state.AdId == "" ? 0 : this.state.AdId,
                    PatientFN: Api.encryptData(this.state.FirstName),
                    PatientLN: Api.encryptData(this.state.LastName),
                    Title: Api.encryptData(this.state.Title),
                    SSN: Api.encryptData(this.state.SSN),
                    Address: Api.encryptData(this.state.PCAddress),
                    City: Api.encryptData(this.state.PCCity),
                    StateId: parseInt(this.state.StateId),
                    Zip: Api.encryptData(this.state.PCZipCode),
                    DOB: Api.encryptData(this.formatDateToString(this.state.DOB)),
                    PreferredContact: Api.encryptData(this.state.PreferredContact),
                    OtherName: Api.encryptData(this.state.OtherNames),
                    Email: Api.encryptData(this.state.Email),
                    Gender: this.state.Gender,
                    Policy: Api.encryptData(this.state.PolicyNum),
                    Phone1: Api.encryptData(this.state.Phone1),
                    Phone2: Api.encryptData(this.state.Phone2),
                    Phone3: Api.encryptData(this.state.Phone3),
                    Ext1: Api.encryptData(this.state.Ext1),
                    Ext2: Api.encryptData(this.state.Ext2),
                    Ext3: Api.encryptData(this.state.Ext3),
                    Phone1Type: this.state.Phone1Type,
                    Phone2Type: this.state.Phone2Type,
                    Phone3Type: this.state.Phone3Type,
                    Fax: Api.encryptData(this.state.Fax),
                    AgentFN: this.state.AgentFN,
                    AgentLN: this.state.AgentLN,
                    AgentId: this.state.AgentId,
                    AgentPhone: this.state.AgentPhone,
                    AgentExt: this.state.AgentExt,
                    AgentCell: this.state.AgentMobile,
                    AgentFax: this.state.AgentFax,
                    AgentEmail: this.state.AgentEmail,
                    AdjusterFN: this.state.AdjusterFN,
                    AdjusterLN: this.state.AdjusterLN,
                    AdjusterId: this.state.AdjusterId,
                    AdjusterPhone: this.state.AdjusterPhone,
                    AdjusterExt: this.state.AdjusterExt,
                    AdjusterCell: this.state.AdjusterMobile,
                    AdjusterFax: this.state.AdjusterFax,
                    AdjusterEmail: this.state.AdjusterEmail,
                    IsAdjusterId: this.state.IsAdjusterId,
                    AdId: this.state.AdId == "" ? 0 : this.state.AdId,
                    IsHold: this.state.isHold,
                    UserId: this.state.UserId,
                    IsAgentId: this.state.IsAgentId,
                    Facility: facility,
                    PolicyAmount: Api.encryptData((this.state.PolicyAmount ? this.state.PolicyAmount : "0")),
                    OtherField1: Api.encryptData(this.state.OtherField1),
                    OtherField2: Api.encryptData(this.state.OtherField2),
                    OtherField3: Api.encryptData(this.state.OtherField3),
                    OtherField4: Api.encryptData(this.state.OtherField4),
                    DateOfIncident: Api.encryptData(this.state.DateOfIncident ? this.formatDateToString(this.state.DateOfIncident) : ""),
                    CompanyType: CompanyType,

                    CMId: this.state.CMId,
                    CMFN: this.state.CMFN,
                    CMLN: this.state.CMLN,
                    CMEmailAddress: this.state.CMEmailAddress,
                    CMWorkPhone: this.state.CMWorkPhone,
                    CMExt: this.state.CMExt,
                    CMCellPhone: this.state.CMCellPhone,
                    CMFax: this.state.CMFax,
                    OrderGuid: this.state.OrderGuid


                };
                url = "api/placeorder/AddUnsubmittedOrder";
            }

            Api.Post(url, data, currentComponent)
                .then(function (resul) {
                    currentComponent.setState({ IsOrderSent: true });
                    console.log("Order creation completed. " + new Date());
                    if (resul.statusMessage == "Success") {
                        console.log("Order creation success. " + new Date());
                        currentComponent.setState({ willUnmount: false, Loading: false });
                        if (currentComponent.state.isHold) {
                            currentComponent.toasterfun2(resul.statusMessage);
                            currentComponent.props.history.push("/unsubmitted-order");
                        } else {                            
                            localStorage.setItem("OrderId", resul.orderNo);

                            if (isFromInboundOrder) {
                                currentComponent.props.history.push("/Inbound-Orders");
                            }
                            else {
                                currentComponent.props.history.push("/confirmation");
                                console.log("Order creation successful. Redirected to confirmation page. " + new Date());
                            }

                        }
                    } else if (resul.statusCode == 102) {
                        currentComponent.setState({ Loading: false });
                        currentComponent.toasterfun2(resul.statusMessage);
                    }
                    else if (resul.statusCode == 403) {
                        console.log("Order creation failure. Received 403 response. " + new Date());
                        currentComponent.setState({ Loading: false });
                        localStorage.removeItem('ShowAdminMenu');
                        localStorage.removeItem('UserType');
                        localStorage.removeItem('PlaceOrder');
                        localStorage.removeItem('ViewResults');
                        localStorage.removeItem('ViewAuth');
                        localStorage.removeItem('ExportSearchResults');
                        localStorage.removeItem('PerformanceMetrics');
                        localStorage.setItem('PlaceOrder', resul.allowPlaceOrder);
                        localStorage.setItem('ShowAdminMenu', resul.isAdmin);
                        localStorage.setItem('UserType', resul.userType);
                        localStorage.setItem('ViewResults', resul.downloadImages);
                        localStorage.setItem('ViewAuth', resul.viewAuth);
                        localStorage.setItem('ExportSearchResults', resul.exportSearchResults);
                        localStorage.setItem('PerformanceMetrics', resul.performanceMetrics);
                        currentComponent.setState({ adminAlert: true });


                    } else {
                        console.log("Order creation failure. " + new Date());
                        currentComponent.setState({ Loading: false });
                        currentComponent.toasterredfun2(resul.statusMessage);
                    }
                })
                .catch(function (error) {
                    console.log("Order creation failure. catch function. " + new Date());
                    currentComponent.setState({ Loading: false });
                    if (error == "TypeError: Failed to fetch") {
                        console.log("Order creation failure. Check your internet connection. " + new Date());
                        currentComponent.toasterredfun2("Check your internet connection.");
                    } else {
                        console.log("Order creation failure. " + error + " " + new Date());
                        currentComponent.toasterredfun2(error);
                    }
                });
        }
        else {
            this.state.willUnmount = true;
        }
    }
    submitOrderWithOrderId(getorderid) {
        var CompanyType = localStorage.getItem("CompanyType");
        let isDateofServiceandRequestSelected = true;
        let currentComponent = this,
            isvalid = true,
            dob = "";
        let facility = currentComponent.state.DocList;
        if (
            currentComponent.state.FacilityName.length != 0 ||
            currentComponent.state.DoctorFirstName.length != 0 ||
            currentComponent.state.DoctorLastName.length != 0 ||
            currentComponent.state.DoctorCity.length != 0 ||
            currentComponent.state.DrZipCode.length != 0 ||
            currentComponent.state.DrFax.length != 0 ||
            currentComponent.state.Phone.length != 0 ||
            currentComponent.state.SpecialType != "" ||
            currentComponent.state.SpecialInstruction != "" ||
            currentComponent.state.DocList.length == 0 ||
            currentComponent.state.Files.length != 0 ||
            currentComponent.state.RushReq == true
        ) {
            //if (currentComponent.state.FacilityName.trim().length == 0) {
            //    if (currentComponent.state.DoctorFirstName.trim().length == 0) {
            //        currentComponent.setState({ pDoctorFirstName: true });
            //        currentComponent.state.addvalid = false;
            //        isvalid = false;
            //    }
            //    if (currentComponent.state.DoctorLastName.trim().length == 0) {
            //        currentComponent.setState({ pDoctorLastName: true });
            //        currentComponent.state.addvalid = false;
            //        isvalid = false;
            //    }
            //}

            //if (currentComponent.state.DoctorCity.trim().length == 0) {
            //    currentComponent.setState({ pDoctorCity: true });
            //    currentComponent.state.addvalid = false;
            //    isvalid = false;
            //}

            //if (currentComponent.state.Email.trim().length != 0) {
            //    if (!currentComponent.testEmailNew(currentComponent.state.Email)) {
            //        currentComponent.setState({ pEmail: true, addvalid: false });
            //        currentComponent.state.addvalid = false;
            //    }
            //}
            //if (currentComponent.state.DrEmailAddress.trim().length != 0) {
            //    if (!currentComponent.testEmailNew(currentComponent.state.DrEmailAddress)) {
            //        currentComponent.setState({ pDrEmailAddress: true, addvalid: false });
            //        currentComponent.state.addvalid = false;
            //    }
            //}

            if (currentComponent.state.Phone.length != 0) {
                if (!currentComponent.testPhone(currentComponent.state.Phone)) {
                    currentComponent.setState({ pPhone: true, addvalid: false });
                    currentComponent.state.addvalid = false;
                    currentComponent.state.pPhone = true;
                }
            }
            if (
                currentComponent.state.DrFax != null &&
                currentComponent.state.DrFax.length != 0
            ) {
                if (!currentComponent.testPhone(currentComponent.state.DrFax)) {
                    currentComponent.setState({ pDrFax: true, addvalid: false });
                    currentComponent.state.addvalid = false;
                    currentComponent.state.pDrFax = true;
                }
            }

            if (currentComponent.state.SpecialType == "CustomDate") {
                if (
                    currentComponent.state.StartDate == "" ||
                    currentComponent.state.StartDate == "Invalid date"
                ) {
                    currentComponent.setState({ dosd: true });
                    currentComponent.state.addvalid = false;
                    currentComponent.state.dosd = true;
                }
                if (
                    currentComponent.state.EndDate == "" ||
                    currentComponent.state.EndDate == "Invalid date"
                ) {
                    currentComponent.setState({ doed: true, addvalid: false });
                    currentComponent.state.addvalid = false;
                    currentComponent.state.doed = true;
                }
                if (
                    currentComponent.state.StartDate instanceof Date &&
                    currentComponent.state.EndDate instanceof Date &&
                    new Date(currentComponent.state.StartDate) <=
                    new Date(currentComponent.state.EndDate)
                ) {
                } else {
                    currentComponent.setState({ dosd: true, addvalid: false });
                    currentComponent.state.addvalid = false;
                    currentComponent.state.dosd = true;
                }
                if (new Date(currentComponent.state.StartDate) instanceof Date) {
                    if (currentComponent.state.DOB.length != 0 && currentComponent.state.DOB instanceof Date) {
                        if (new Date(currentComponent.state.StartDate) < currentComponent.state.DOB) {
                            currentComponent.state.addvalid = false;
                            currentComponent.state.dosd = true;
                        }
                    }
                }
                if (new Date(currentComponent.state.CustomStartDate) instanceof Date) {
                    if (currentComponent.state.DOB.length != 0 && currentComponent.state.DOB instanceof Date) {
                        if (new Date(currentComponent.state.CustomStartDate) < currentComponent.state.DOB) {
                            currentComponent.state.addvalid = false;
                            currentComponent.state.csd = true;
                        }
                    }
                }
            }
            if (currentComponent.state.SpecialType != "") {
                if (currentComponent.state.SpecialInstruction == "") {
                    currentComponent.setState({ choseRadioxtext: true, addvalid: false });
                    currentComponent.state.addvalid = false;
                    currentComponent.state.choseRadioxtext = true;
                }
            }
            if (
                currentComponent.state.DrZipCode.length == 0 ||
                currentComponent.state.DrZipCode.length < 5
            ) {
                currentComponent.setState({ pDrZipCode: true });
                currentComponent.setState({ addvalid: false });
                currentComponent.state.addvalid = false;
                currentComponent.state.pDrZipCode = true;
            }
            if (currentComponent.state.DoctorCity.length == 0) {
                currentComponent.setState({ pDoctorCity: true });
                currentComponent.setState({ addvalid: false });
                currentComponent.state.addvalid = false;
                currentComponent.state.pDoctorCity = true;
            }
            if (
                currentComponent.state.FacilityName.length == 0 &&
                currentComponent.state.DoctorFirstName.length == 0 &&
                currentComponent.state.DoctorLastName.length == 0
            ) {
                currentComponent.setState({ pDoctorLastName: true });
                currentComponent.setState({ pDoctorFirstName: true });
                currentComponent.setState({ pFacilityName: true });
                currentComponent.setState({ addvalid: false });
                currentComponent.state.addvalid = false;
                currentComponent.state.pDoctorLastName = true;
                currentComponent.state.pDoctorFirstName = true;
                currentComponent.state.pFacilityName = true;
            }
            if (
                currentComponent.state.DrZipCode.length > 5 &&
                currentComponent.state.DrZipCode < 10
            ) {
                currentComponent.setState({ pDrZipCode: true });
                currentComponent.setState({ addvalid: false });
                currentComponent.state.addvalid = false;
                currentComponent.state.pDrZipCode = true;
            }

            //if (
            //    currentComponent.state.DrStateId.length == 0 ||
            //    currentComponent.state.DrStateId == "Select"
            //) {
            //    currentComponent.setState({ pDrStateId: true });
            //    currentComponent.setState({ addvalid: false });
            //    currentComponent.state.addvalid = false;
            //    currentComponent.state.pDrStateId = true;
            //}
            if (
                currentComponent.state.DrState.length == 0 ||
                currentComponent.state.DrState == "Select" ||
                currentComponent.state.DrState == "0"
            ) {
                currentComponent.setState({ pDrStateId: true });
                currentComponent.state.addvalid = false;
            }
            if (
                currentComponent.state.DoctorFirstName.length != 0 &&
                currentComponent.state.DoctorLastName.length == 0
            ) {
                currentComponent.setState({ pDoctorLastName: true });
                currentComponent.setState({ addvalid: false });
                currentComponent.state.addvalid = false;
                currentComponent.state.pDoctorLastName = true;
            }
            if (
                currentComponent.state.DoctorFirstName.length == 0 &&
                currentComponent.state.DoctorLastName.length != 0
            ) {
                currentComponent.setState({ pDoctorFirstName: true });
                currentComponent.setState({ addvalid: false });
                currentComponent.state.addvalid = false;
                currentComponent.state.pDoctorFirstName = true;
            }
            if (currentComponent.state.Phone.length == 0) {
                currentComponent.setState({ pPhone: true });
                currentComponent.setState({ addvalid: false });
                currentComponent.state.addvalid = false;
                currentComponent.state.pPhone = true;
            }
            if (
                ((currentComponent.state.FacilityName != null &&
                    currentComponent.state.FacilityName.length != 0) ||
                    (currentComponent.state.DoctorFirstName != null &&
                        currentComponent.state.DoctorFirstName.length != 0 &&
                        currentComponent.state.DoctorLastName != null &&
                        currentComponent.state.DoctorLastName.length != 0)) &&
                currentComponent.state.DoctorCity.length != 0 &&
                currentComponent.state.DrStateId != "Select" &&
                (currentComponent.state.DrZipCode.length == 10 ||
                    currentComponent.state.DrZipCode.length == 5)
            ) {
                if (currentComponent.state.Files.length == 0) {
                    currentComponent.setState({ Files: "" });
                }

                if (CompanyType == "LEGAL") {
                    if (currentComponent.state.DatesOfService == "" || currentComponent.state.DatesOfService.length == 0) {
                        currentComponent.state.addvalid = false;
                        isDateofServiceandRequestSelected = false;
                        this.toasterredfun2("Dates of service is required");
                    } else if (currentComponent.state.DatesOfService == "Custom") {
                        if (
                            currentComponent.state.CustomStartDate == "" ||
                            currentComponent.state.CustomStartDate == "Invalid date"
                        ) {
                            currentComponent.setState({ csd: true, addvalid: false });
                            currentComponent.state.addvalid = false;
                            currentComponent.state.csd = false;
                        }
                        if (
                            currentComponent.state.CustomEndDate == "" ||
                            currentComponent.state.CustomEndDate == "Invalid date"
                        ) {
                            currentComponent.setState({ ced: true, addvalid: false });
                            currentComponent.state.addvalid = false;
                            currentComponent.state.ced = false;
                        }
                        if (
                            new Date(currentComponent.state.CustomStartDate) instanceof Date &&
                            new Date(currentComponent.state.CustomEndDate) instanceof Date &&
                            new Date(currentComponent.state.CustomStartDate) <=
                            new Date(currentComponent.state.CustomEndDate)
                        ) {
                        } else {
                            currentComponent.setState({ csd: true, addvalid: false });
                            currentComponent.state.addvalid = false;
                            currentComponent.state.csd = false;
                        }
                        if (new Date(currentComponent.state.CustomStartDate) instanceof Date) {
                            if (currentComponent.state.DOB.length != 0 && currentComponent.state.DOB instanceof Date) {
                                if (new Date(currentComponent.state.CustomStartDate) < currentComponent.state.DOB) {
                                    currentComponent.state.addvalid = false;
                                    currentComponent.state.csd = true;
                                }
                            }
                        }
                    }
                    if (currentComponent.state.addvalid && currentComponent.state.RequestTypes.length == 0) {
                        currentComponent.state.addvalid = false;
                        isDateofServiceandRequestSelected = false;
                        this.toasterredfun2("Atleast one request type is required");
                    }
                }
            }
            if (
                (currentComponent.state.FacilityName.length != 0 ||
                    (currentComponent.state.DoctorFirstName.length != 0 &&
                        currentComponent.state.DoctorLastName.length != 0)) &&
                currentComponent.state.DoctorCity.length != 0 &&
                currentComponent.state.DrStateId != "Select" &&
                (currentComponent.state.DrZipCode.length == 5 ||
                    currentComponent.state.DrZipCode.length == 10)
            ) {
                if (currentComponent.state.Files.length == 0) {
                    currentComponent.setState({ Files: "" });
                }

                // Email validation for Client Manager
                if ((currentComponent.state.CMFN.trim().length != 0 || currentComponent.state.CMLN.trim().length != 0 || currentComponent.state.CMWorkPhone.trim().length != 0 ||
                    currentComponent.state.CMExt.trim().length != 0 || currentComponent.state.CMCellPhone.trim().length != 0 || currentComponent.state.CMFax.trim().length != 0) &&
                    currentComponent.state.CMEmailAddress.trim().length == 0) {
                    if (((currentComponent.state.isMandatoryCMFN || currentComponent.state.CMFNHide) || (currentComponent.state.isMandatoryCMLN || currentComponent.state.CMLNHide)) && (currentComponent.state.isMandatoryCMEmailAddress || currentComponent.state.CMEmailAddressHide)) {
                        currentComponent.setState({ pCMEmailAddress: true });
                        currentComponent.state.addvalid = false;
                    } else {
                        currentComponent.setState({ pCMEmailAddress: false });
                    }
                }

                if (currentComponent.state.CMEmailAddress.trim().length != 0) {
                    if (currentComponent.state.pCMEmailAddress) {
                        currentComponent.setState({ pCMEmailAddress: true });
                        currentComponent.state.addvalid = false;
                    }
                }

                if (this.state.OrderingOfficeList && this.state.OrderingOfficeList.length > 0) {
                    if (this.state.OrderingOfficeId == "0") {
                        currentComponent.setState({ orderingofficereq: true });
                        currentComponent.state.addvalid = false;
                    }
                } else {
                    currentComponent.toasterredfun2(
                        "Ordering office is not mapped/active, contact Administrator"
                    );
                    currentComponent.state.addvalid = false;
                }

                //PE-462 - Check Validation for other Requied fields -Start 
                if (currentComponent.state.RequiredFieldsList.length > 0 && currentComponent.state.RequiredFieldsList != null) {
                    var checkifEmpty = "";
                    var checkLength = 0;
                    for (var i = 0; i < currentComponent.state.RequiredFieldsList.length; i++) {
                        var setStateVarName = [currentComponent.state.RequiredFieldsList[i].className];
                        checkifEmpty = currentComponent.state[setStateVarName] ? currentComponent.state[setStateVarName].toString().trim() : "";
                        checkLength = currentComponent.state[setStateVarName] ? currentComponent.state[setStateVarName].toString().length : "";

                        var setStateIsMandatoryVarName = currentComponent.state.RequiredFieldsList[i].isRequired;
                        currentComponent.setState({ ['isMandatory' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].isRequired });
                        if ((checkifEmpty == "" || checkLength > 80 || currentComponent.state[setStateVarName] == "Select") && setStateIsMandatoryVarName && currentComponent.state.RequiredFieldsList[i].sectionId != 5) {
                            currentComponent.setState({ ['p' + currentComponent.state.RequiredFieldsList[i].className]: true });
                            isvalid = false;
                            currentComponent.state.addvalid = false;
                        }
                    }
                }
                //PE-462 - Check Validation for other Requied fields -end

                if (currentComponent.state.addvalid) {
                    if (CompanyType == "LEGAL") {
                        var docList = currentComponent.state.DocList;
                        currentComponent.state.RequestTypes.map((requestType) => {

                            var customerProvidedFacility = "";
                            if (currentComponent.state.DoctorFirstName) {
                                customerProvidedFacility += "Dr " + currentComponent.state.DoctorFirstName;
                            }

                            if (currentComponent.state.DoctorLastName) {
                                customerProvidedFacility += currentComponent.state.DoctorFirstName ? " " + currentComponent.state.DoctorLastName : "Dr " + currentComponent.state.DoctorLastName;
                            }
                            customerProvidedFacility += ", " + currentComponent.state.FacilityName + ", " + currentComponent.state.DrAddress + ", " + currentComponent.state.DoctorCity + ", " + currentComponent.state.DrState + ", " + currentComponent.state.DrZipCode + ", " + (currentComponent.state.Phone ? "Ph: " + currentComponent.state.Phone : "");

                            //if (/\s{2,}/g.test(e)) {

                            //}

                            currentComponent.state.CustomerProvidedFacility = customerProvidedFacility;
                            
                            docList.push({
                                DrFacilityName: currentComponent.state.FacilityName,
                                DrFirstName: currentComponent.state.DoctorFirstName,
                                DrLastName: currentComponent.state.DoctorLastName,
                                DrEmailAddress: currentComponent.state.DrEmailAddress,
                                DrAddress: currentComponent.state.DrAddress,
                                DrCity: currentComponent.state.DoctorCity,
                                DrStateId: parseInt(currentComponent.state.DrStateId),
                                Id: parseInt(
                                    currentComponent.state.FacilityId
                                        ? currentComponent.state.FacilityId
                                        : 0
                                ),
                                isNew: currentComponent.state.isNew,
                                //    StateId: currentComponent.state.DrStateId,
                                DrState: currentComponent.state.DrState,
                                DrZipCode: currentComponent.state.DrZipCode,
                                DrPhone: currentComponent.state.Phone,
                                DrFax: currentComponent.state.DrFax,
                                RushRequest: currentComponent.state.RushReq,
                                AuthDocs: currentComponent.state.Files,
                                SpecialInstruction: currentComponent.state.SpecialInstruction,
                                SpecialType: currentComponent.state.SpecialType,
                                DocEdit: true,
                                DocDelete: true,
                                StartDate: CompanyType == "LEGAL" ? currentComponent.formatDateToString(currentComponent.state.CustomStartDate) : currentComponent.formatDateToString(currentComponent.state.StartDate),
                                EndDate: CompanyType == "LEGAL" ? currentComponent.formatDateToString(currentComponent.state.CustomEndDate) : currentComponent.formatDateToString(currentComponent.state.EndDate),
                                DateOfServiceType: currentComponent.state.DatesOfService,
                                FacilityRequests: [requestType],
                                IsMissingFacilityDetails: currentComponent.state.IsMissingFacilityDetails,
                                CustomerProvidedFacility: currentComponent.state.CustomerProvidedFacility
                            });
                        });

                        currentComponent.setState({
                            DocList: docList
                        });

                        facility = currentComponent.state.DocList;
                    } else {
                        var customerProvidedFacility = "";
                        if (currentComponent.state.DoctorFirstName) {
                            customerProvidedFacility += "Dr " + currentComponent.state.DoctorFirstName;
                        }

                        if (currentComponent.state.DoctorLastName) {
                            customerProvidedFacility += currentComponent.state.DoctorFirstName ? " " + currentComponent.state.DoctorLastName : "Dr " + currentComponent.state.DoctorLastName;
                        }
                        customerProvidedFacility += ", " + currentComponent.state.FacilityName + ", " + currentComponent.state.DrAddress + ", " + currentComponent.state.DoctorCity + ", " + currentComponent.state.DrState + ", " + currentComponent.state.DrZipCode + ", " + (currentComponent.state.Phone ? "Ph: " + currentComponent.state.Phone : "");

                        //if (/\s{2,}/g.test(e)) {

                        //}

                        currentComponent.state.CustomerProvidedFacility = customerProvidedFacility;

                        currentComponent.setState({
                            DocList: [
                                ...currentComponent.state.DocList,
                                {
                                    DrFacilityName: currentComponent.state.FacilityName,
                                    DrFirstName: currentComponent.state.DoctorFirstName,
                                    DrLastName: currentComponent.state.DoctorLastName,
                                    DrEmailAddress: currentComponent.state.DrEmailAddress,
                                    DrAddress: currentComponent.state.DrAddress,
                                    DrCity: currentComponent.state.DoctorCity,
                                    DrStateId: parseInt(currentComponent.state.DrStateId),
                                    // StateId: currentComponent.state.DrStateId,
                                    DrState: currentComponent.state.DrState,
                                    DrZipCode: currentComponent.state.DrZipCode,
                                    DrPhone: currentComponent.state.Phone,
                                    DrFax: currentComponent.state.DrFax,
                                    RushRequest: currentComponent.state.RushReq,
                                    Id: currentComponent.state.FacilityId
                                        ? currentComponent.state.FacilityId
                                        : 0,
                                    isNew: currentComponent.state.isNew,
                                    AuthDocs: currentComponent.state.Files,
                                    SpecialInstruction: currentComponent.state.SpecialInstruction,
                                    SpecialType: currentComponent.state.SpecialType,
                                    StartDate: currentComponent.formatDateToString(currentComponent.state.StartDate),
                                    EndDate: currentComponent.formatDateToString(currentComponent.state.EndDate),
                                    DocEdit: true,
                                    DocDelete: true,
                                    IsMissingFacilityDetails: currentComponent.state.IsMissingFacilityDetails,
                                    CustomerProvidedFacility: currentComponent.state.CustomerProvidedFacility
                                },
                            ],
                        });

                        facility = [
                            ...facility,
                            {
                                DrFacilityName: currentComponent.state.FacilityName,
                                DrFirstName: currentComponent.state.DoctorFirstName,
                                DrLastName: currentComponent.state.DoctorLastName,
                                DrEmailAddress: currentComponent.state.DrEmailAddress,
                                DrAddress: currentComponent.state.DrAddress,
                                DrCity: currentComponent.state.DoctorCity,
                                DrStateId: parseInt(currentComponent.state.DrStateId),
                                // StateId: currentComponent.state.DrStateId,
                                DrState: currentComponent.state.DrState,
                                DrZipCode: currentComponent.state.DrZipCode,
                                DrPhone: currentComponent.state.Phone,
                                DrFax: currentComponent.state.DrFax,
                                RushRequest: currentComponent.state.RushReq,
                                Id: currentComponent.state.FacilityId
                                    ? currentComponent.state.FacilityId
                                    : 0,
                                isNew: currentComponent.state.isNew,
                                AuthDocs: currentComponent.state.Files,
                                SpecialInstruction: currentComponent.state.SpecialInstruction,
                                SpecialType: currentComponent.state.SpecialType,
                                StartDate: CompanyType == "LEGAL" ? currentComponent.formatDateToString(currentComponent.state.CustomStartDate) : currentComponent.formatDateToString(currentComponent.state.StartDate),
                                EndDate: CompanyType == "LEGAL" ? currentComponent.formatDateToString(currentComponent.state.CustomEndDate) : currentComponent.formatDateToString(currentComponent.state.EndDate),
                                DocEdit: true,
                                DocDelete: true,
                                IsMissingFacilityDetails: currentComponent.state.IsMissingFacilityDetails,
                                CustomerProvidedFacility: currentComponent.state.CustomerProvidedFacility
                            },
                        ];
                    }
                    currentComponent.clearFacility();
                    //}
                }
            }
        } else {
            let isPresent = false;
            currentComponent.state.DocList.map((item, i) => {
                if (item.DocEdit) {
                    isPresent = true;
                }
            }, this);
            if (!isPresent) {
                currentComponent.state.addvalid = false;
                currentComponent.setState({ pDoctorCity: true, pDrStateId: true, pDrZipCode: true, pPhone: true, pFacilityName: true, pDoctorFirstName: true, pDoctorLastName: true, pDrEmailAddress: true });
                $("html, body").animate({ scrollTop: 0 }, "slow");
            }
            facility = currentComponent.state.DocList;
        }
        if (this.state.OrderingOfficeList && this.state.OrderingOfficeList.length > 0) {
            if (!this.state.OrderingOfficeId) {
                currentComponent.setState({ orderingofficereq: true });
                currentComponent.state.addvalid = false;
            }
        } else {
            currentComponent.toasterredfun2(
                "Ordering office is not mapped/active, contact Administrator"
            );
            currentComponent.setState({ orderingofficereq: true });
            currentComponent.state.addvalid = false;
        }
        if (!currentComponent.state.addvalid) {
            isvalid = false;
        }
        if (isvalid) {
            if (facility.length == 0) {
                currentComponent.setState({
                    pDrAddress: true,
                    pDrEmailAddress: true,
                    pDoctorCity: true,
                    pDrStateId: true,
                    pDrZipCode: true,
                    pPhone: true,
                    pDrFax: true,
                    pFacilityName: true,
                    pDoctorFirstName: true,
                    pDoctorLastName: true,
                });
                isvalid = false;
            }
        }
        if (!isvalid && isDateofServiceandRequestSelected) {
            currentComponent.toasterredfun2("Your order was not submitted. It is missing required data. Please correct the data, and click Submit Order again.");
        }
        if (isvalid && currentComponent.state.IsOrderSent) {
            currentComponent.setState({ Loading: true,IsOrderSent:false });
            let url = "api/placeorder/UpdateOrderAdditional";
            var data = {
                SSN: Api.encryptData(this.state.SSN),
                Address: Api.encryptData(this.state.PCAddress),
                IsAgentId: this.state.IsAgentId,
                AgId: this.state.AgId == "" ? 0 : this.state.AgId,
                AgentFN: this.state.AgentFN,
                AgentLN: this.state.AgentLN,
                AgentId: this.state.AgentId,
                AgentPhone: this.state.AgentPhone,
                PatientFN: Api.encryptData(this.state.FirstName),
                PatientLN: Api.encryptData(this.state.LastName),
                Title: Api.encryptData(this.state.Title),
                City: Api.encryptData(this.state.PCCity),
                StateId: parseInt(this.state.StateId),
                AgentExt: this.state.AgentExt,
                AgentCell: this.state.AgentMobile,
                AgentFax: this.state.AgentFax,
                AgentEmail: this.state.AgentEmail,
                Phone1: Api.encryptData(this.state.Phone1),
                Phone2: Api.encryptData(this.state.Phone2),
                Phone3: Api.encryptData(this.state.Phone3),
                Zip: Api.encryptData(this.state.PCZipCode),
                DOB: Api.encryptData(this.formatDateToString(this.state.DOB)),
                Email: Api.encryptData(this.state.Email),
                Gender: this.state.Gender,
                Policy: Api.encryptData(this.state.PolicyNum),
                Ext1: Api.encryptData(this.state.Ext1),
                Ext2: Api.encryptData(this.state.Ext2),
                Ext3: Api.encryptData(this.state.Ext3),
                IsHold: this.state.isHold,
                UserId: this.state.UserId,
                Facility: facility,
                OrderFacilityId: getorderid,
                PreferredContact: Api.encryptData(this.state.PreferredContact),
                OtherName: Api.encryptData(this.state.OtherNames),
                Phone1Type: this.state.Phone1Type,
                Phone2Type: this.state.Phone2Type,
                Phone3Type: this.state.Phone3Type,
                Fax: Api.encryptData(this.state.Fax),
                PolicyAmount: Api.encryptData(this.state.PolicyAmount),
                OtherField1: Api.encryptData(this.state.OtherField1),
                OtherField2: Api.encryptData(this.state.OtherField2),
                OtherField3: Api.encryptData(this.state.OtherField3),
                OtherField4: Api.encryptData(this.state.OtherField4),
                DateOfIncident: Api.encryptData(this.state.DateOfIncident
                    ? this.state.DateOfIncident
                    : ""),
                CompanyType: CompanyType,
                CMId: this.state.CMId,
                CMFN: this.state.CMFN,
                CMLN: this.state.CMLN,
                CMEmailAddress: this.state.CMEmailAddress,
                CMWorkPhone: this.state.CMWorkPhone,
                CMExt: this.state.CMExt,
                CMCellPhone: this.state.CMCellPhone,
                CMFax: this.state.CMFax,
                AdjusterFN: this.state.AdjusterFN,
                AdjusterLN: this.state.AdjusterLN,
                AdjusterId: this.state.AdjusterId,
                AdjusterPhone: this.state.AdjusterPhone,
                AdjusterExt: this.state.AdjusterExt,
                AdjusterCell: this.state.AdjusterMobile,
                AdjusterFax: this.state.AdjusterFax,
                AdjusterEmail: this.state.AdjusterEmail,
                IsAdjusterId: this.state.IsAdjusterId,
                OrderGuid:this.state.OrderGuid

            };
            if (this.state.isUnsubmitted) {
                data = {
                    OrderingOfficeId: this.state.OrderingOfficeId,
                    ExistingOrderingOfficeId: this.state.ExistingOrderingOfficeId,
                    IsDefaultOrderingOffice: this.state.IsDefaultOrderingOffice,
                    PatientFN: Api.encryptData(this.state.FirstName),
                    PatientLN: Api.encryptData(this.state.LastName),
                    Title: Api.encryptData(this.state.Title),
                    PatientId: this.state.PatientId,
                    IsAgentId: this.state.IsAgentId,
                    AgId: this.state.AgId == "" ? 0 : this.state.AgId,
                    SSN: Api.encryptData(this.state.SSN),
                    Address: Api.encryptData(this.state.PCAddress),
                    Zip: Api.encryptData(this.state.PCZipCode),
                    DOB: Api.encryptData(this.formatDateToString(this.state.DOB)),
                    City: Api.encryptData(this.state.PCCity),
                    StateId: parseInt(this.state.StateId),
                    Email: Api.encryptData(this.state.Email),
                    Gender: this.state.Gender,
                    Policy: Api.encryptData(this.state.PolicyNum),
                    PreferredContact: Api.encryptData(this.state.PreferredContact),
                    OtherName: Api.encryptData(this.state.OtherNames),
                    Phone1Type: this.state.Phone1Type,
                    Phone2Type: this.state.Phone2Type,
                    Phone3Type: this.state.Phone3Type,
                    Fax: Api.encryptData(this.state.Fax),
                    AgentFN: this.state.AgentFN,
                    AgentLN: this.state.AgentLN,
                    AgentId: this.state.AgentId,
                    AgentPhone: this.state.AgentPhone,
                    Phone1: Api.encryptData(this.state.Phone1),
                    Phone2: Api.encryptData(this.state.Phone2),
                    Phone3: Api.encryptData(this.state.Phone3),
                    Ext1: Api.encryptData(this.state.Ext1),
                    Ext2: Api.encryptData(this.state.Ext2),
                    Ext3: Api.encryptData(this.state.Ext3),
                    IsHold: this.state.isHold,
                    UserId: this.state.UserId,
                    Facility: facility,
                    OrderFacilityId: getorderid,
                    AgentExt: this.state.AgentExt,
                    AgentCell: this.state.AgentMobile,
                    AgentFax: this.state.AgentFax,
                    AgentEmail: this.state.AgentEmail,
                    PolicyAmount: Api.encryptData(this.state.PolicyAmount ? this.state.PolicyAmount : 0),
                    OtherField1: Api.encryptData(this.state.OtherField1),
                    OtherField2: Api.encryptData(this.state.OtherField2),
                    OtherField3: Api.encryptData(this.state.OtherField3),
                    OtherField4: Api.encryptData(this.state.OtherField4),
                    DateOfIncident: Api.encryptData(this.state.DateOfIncident
                        ? this.state.DateOfIncident
                        : ""),
                    CompanyType: CompanyType,
                    //PE-511
                    CMId: this.state.CMId,
                    CMFN: this.state.CMFN,
                    CMLN: this.state.CMLN,
                    CMEmailAddress: this.state.CMEmailAddress,
                    CMWorkPhone: this.state.CMWorkPhone,
                    CMExt: this.state.CMExt,
                    CMCellPhone: this.state.CMCellPhone,
                    CMFax: this.state.CMFax,
                    AdjusterFN: this.state.AdjusterFN,
                    AdjusterLN: this.state.AdjusterLN,
                    AdjusterId: this.state.AdjusterId,
                    AdjusterPhone: this.state.AdjusterPhone,
                    AdjusterExt: this.state.AdjusterExt,
                    AdjusterCell: this.state.AdjusterMobile,
                    AdjusterFax: this.state.AdjusterFax,
                    AdjusterEmail: this.state.AdjusterEmail,
                    IsAdjusterId: this.state.IsAdjusterId,
                    OrderGuid: this.state.OrderGuid
                };
                url = "api/placeorder/UpdateOrderAdditional";
            }


            Api.Post(url, data, currentComponent)
                .then(function (resul) {
                    currentComponent.setState({IsOrderSent:true})
                    if (resul.statusMessage == "Success") {
                        currentComponent.setState({ willUnmount: false, Loading: false });
                        if (currentComponent.state.isHold) {
                            currentComponent.toasterfun2(resul.statusMessage);
                            currentComponent.props.history.push("/unsubmitted-order");
                        } else {
                            localStorage.setItem("OrderId", resul.orderNo);
                            currentComponent.props.history.push("/confirmation");
                        }
                    }
                    else if (resul.statusCode == 102) {
                        currentComponent.setState({ Loading: false });
                        currentComponent.toasterfun2(resul.statusMessage);
                    }
                    else if (resul.statusCode == 403) {
                        currentComponent.setState({ Loading: false });
                        localStorage.removeItem('ShowAdminMenu');
                        localStorage.removeItem('UserType');
                        localStorage.removeItem('PlaceOrder');
                        localStorage.removeItem('ViewResults');
                        localStorage.removeItem('ViewAuth');
                        localStorage.removeItem('ExportSearchResults');
                        localStorage.removeItem('PerformanceMetrics');
                        localStorage.setItem('PlaceOrder', resul.allowPlaceOrder);
                        localStorage.setItem('ShowAdminMenu', resul.isAdmin);
                        localStorage.setItem('UserType', resul.userType);
                        localStorage.setItem('ViewResults', resul.downloadImages);
                        localStorage.setItem('ViewAuth', resul.viewAuth);
                        localStorage.setItem('ExportSearchResults', resul.exportSearchResults);
                        localStorage.setItem('PerformanceMetrics', resul.performanceMetrics);
                        currentComponent.setState({ adminAlert: true });

                    } else {
                        currentComponent.setState({ Loading: false });
                        currentComponent.toasterredfun2(resul.statusMessage);
                    }
                })
                .catch(function (error) {
                    currentComponent.setState({ Loading: false });
                    if (error == "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun2("Check your internet connection.");
                    } else {
                        currentComponent.toasterredfun2(error);
                    }
                });
        }
        else {
            this.state.willUnmount = true;
        }
    }

    submitOrderReopen(getorderid) {
        var CompanyType = localStorage.getItem("CompanyType");
        let currentComponent = this, isvalid = true
        var isSpecialInsExist = true;

        let facility = currentComponent.state.DocList;
        if (isNullOrUndefined(this.state.SpecialInstruction)) {
            isSpecialInsExist = false;
        }
        else if (this.state.SpecialInstruction.trim() == '') {
            isSpecialInsExist = false;
        }
        else {
            this.state.SpecialInstruction = this.state.SpecialInstruction.trim();
        }
        if (!isSpecialInsExist) {
            this.state.choseRadioxtext = true;
            isvalid = false;
        }
        if (this.state.OrderingOfficeList && this.state.OrderingOfficeList.length > 0) {
            if (!this.state.OrderingOfficeId) {
                currentComponent.setState({ orderingofficereq: true });
                isvalid = false;
            }
            else {
                var isOrdOfficeAvail = this.state.OrderingOfficeList.find(a => a.officeStatus == "Active" && a.id == this.state.OrderingOfficeId);
                if (!isOrdOfficeAvail) {
                    currentComponent.setState({ orderingofficereq: true });
                    isvalid = false;
                    currentComponent.toasterredfun2("Ordering office is not mapped/active, contact Administrator");
                }
            }
        } else {
            currentComponent.toasterredfun2(
                "Ordering office is not mapped/active, contact Administrator"
            );
            isvalid = false;
        }
        if (isvalid) {
            if (CompanyType == "LEGAL") {
                var docList = currentComponent.state.DocList;
                currentComponent.state.RequestTypes.map((requestType) => {
                    docList.push({
                        DrFacilityName: currentComponent.state.FacilityName,
                        DrFirstName: currentComponent.state.DoctorFirstName,
                        DrLastName: currentComponent.state.DoctorLastName,
                        DrEmailAddress: currentComponent.state.DrEmailAddress,
                        DrAddress: currentComponent.state.DrAddress,
                        DrCity: currentComponent.state.DoctorCity,
                        DrStateId: parseInt(currentComponent.state.DrStateId),
                        Id: parseInt(currentComponent.state.FacilityId ? currentComponent.state.FacilityId : 0),
                        isNew: currentComponent.state.isNew,
                        DrState: currentComponent.state.DrState,
                        DrZipCode: currentComponent.state.DrZipCode,
                        DrPhone: currentComponent.state.Phone,
                        DrFax: currentComponent.state.DrFax,
                        RushRequest: currentComponent.state.RushReq,
                        AuthDocs: currentComponent.state.Files,
                        SpecialInstruction: currentComponent.state.SpecialInstruction,
                        SpecialType: currentComponent.state.SpecialType,
                        DocEdit: true,
                        DocDelete: true,
                        StartDate: CompanyType == "LEGAL" ? currentComponent.formatDateToString(currentComponent.state.CustomStartDate) : currentComponent.formatDateToString(currentComponent.state.StartDate),
                        EndDate: CompanyType == "LEGAL" ? currentComponent.formatDateToString(currentComponent.state.CustomEndDate) : currentComponent.formatDateToString(currentComponent.state.EndDate),
                        DateOfServiceType: currentComponent.state.DatesOfService,
                        FacilityRequests: [requestType],
                        IsMissingFacilityDetails: currentComponent.state.IsMissingFacilityDetails,
                        CustomerProvidedFacility: currentComponent.state.CustomerProvidedFacility,
                        OrderGuid: this.state.OrderGuid
                    });
                });
                facility = docList;
            } else {
                facility = [
                    ...facility,
                    {
                        DrFacilityName: currentComponent.state.FacilityName,
                        DrFirstName: currentComponent.state.DoctorFirstName,
                        DrLastName: currentComponent.state.DoctorLastName,
                        DrEmailAddress: currentComponent.state.DrEmailAddress,
                        DrAddress: currentComponent.state.DrAddress,
                        DrCity: currentComponent.state.DoctorCity,
                        DrStateId: parseInt(currentComponent.state.DrStateId),
                        DrState: currentComponent.state.DrState,
                        DrZipCode: currentComponent.state.DrZipCode,
                        DrPhone: currentComponent.state.Phone,
                        DrFax: currentComponent.state.DrFax,
                        RushRequest: currentComponent.state.RushReq,
                        Id: currentComponent.state.FacilityId ? currentComponent.state.FacilityId : 0,
                        isNew: currentComponent.state.isNew,
                        AuthDocs: currentComponent.state.Files,
                        SpecialInstruction: currentComponent.state.SpecialInstruction,
                        SpecialType: currentComponent.state.SpecialType,
                        StartDate: CompanyType == "LEGAL" ? currentComponent.formatDateToString(currentComponent.state.CustomStartDate) : currentComponent.formatDateToString(currentComponent.state.StartDate),
                        EndDate: CompanyType == "LEGAL" ? currentComponent.formatDateToString(currentComponent.state.CustomEndDate) : currentComponent.formatDateToString(currentComponent.state.EndDate),
                        DocEdit: true,
                        DocDelete: true,
                        IsMissingFacilityDetails: currentComponent.state.IsMissingFacilityDetails,
                        CustomerProvidedFacility: currentComponent.state.CustomerProvidedFacility,
                        OrderGuid: this.state.OrderGuid
                    },
                ];
            }
        }
        if (!isvalid) {
            var isOrdOfficeAvail = this.state.OrderingOfficeList.find(a => a.officeStatus == "Active" && a.id == this.state.OrderingOfficeId);
            if (!isOrdOfficeAvail) {
                currentComponent.setState({ orderingofficereq: true });
                isvalid = false;
                currentComponent.toasterredfun2("Ordering office is not mapped/active, contact Administrator");
            }
            else {
                currentComponent.toasterredfun2("Your order was not submitted. It is missing required data. Please correct the data, and click Submit Order again.");
            }
        }
        //debugger
        if (isvalid) {
            currentComponent.setState({ Loading: true });
            let url = "api/placeorder/OrderReopen";
            var data = {
                UserId: this.state.UserId,
                Facility: facility,
                OrderFacilityId: getorderid,
            };
            //debugger
            Api.Post(url, data, currentComponent)
                .then(function (resul) {
                    if (resul.statusMessage == "Success") {
                        currentComponent.setState({ Loading: false });
                        localStorage.setItem("OrderId", resul.orderNo);
                        currentComponent.props.history.push("/confirmation");
                        localStorage.setItem("ReopenComments", "");
                    }
                    else if (resul.statusCode == 102) {
                        currentComponent.setState({ Loading: false });
                        currentComponent.toasterfun2(resul.statusMessage);
                    }
                    else if (resul.statusCode == 403) {
                        currentComponent.setState({ Loading: false });
                        localStorage.removeItem('ShowAdminMenu');
                        localStorage.removeItem('UserType');
                        localStorage.removeItem('PlaceOrder');
                        localStorage.removeItem('ViewResults');
                        localStorage.removeItem('ViewAuth');
                        localStorage.removeItem('ExportSearchResults');
                        localStorage.removeItem('PerformanceMetrics');
                        localStorage.setItem('PlaceOrder', resul.allowPlaceOrder);
                        localStorage.setItem('ShowAdminMenu', resul.isAdmin);
                        localStorage.setItem('UserType', resul.userType);
                        localStorage.setItem('ViewResults', resul.downloadImages);
                        localStorage.setItem('ViewAuth', resul.viewAuth);
                        localStorage.setItem('ExportSearchResults', resul.exportSearchResults);
                        localStorage.setItem('PerformanceMetrics', resul.performanceMetrics);
                        currentComponent.setState({ adminAlert: true });

                    } else {
                        currentComponent.setState({ Loading: false });
                        currentComponent.toasterredfun2(resul.statusMessage);
                    }
                })
                .catch(function (error) {
                    currentComponent.setState({ Loading: false });
                    if (error == "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun2("Check your internet connection.");
                    } else {
                        currentComponent.toasterredfun2(error);
                    }
                });
        }
    }

    handleClear(e) {
        this.setState({ removeAlertx: false });
        this.setState({ Loading: true });
        if (!this.state.ReOpenButtonHide) {
            this.setState({
                SpecialInstruction: "",
                Files: [],
                Loading: false
            });
        }
        else {
            this.clearFacility();
            document.getElementById("dobDate").value = null;
            //$('#dobDate').val('');
            document.getElementById("sdate").value = "";
            document.getElementById("edate").value = "";
            document.getElementById("doiDate").value = "";
            if (this.state.isUnsubmitted == false || this.state.IsOrderAdditional == false) {

                var orderOfficeInformation = this.state.OrderingOfficeList.find(a => a.isDefault == true);
                this.setState({ OrderingOfficeId: orderOfficeInformation ? orderOfficeInformation.id : 0 });
                if (this.state.OrderingOfficeList.length == 1) {
                    this.setState({ OrderingOfficeId: this.state.OrderingOfficeList[0].id });
                }

                this.setState({
                    Title: "",
                    FirstName: "",
                    LastName: "",
                    SSN: "",
                    Gender: "Select",
                    PreferredContact: "",
                    OtherNames: "",
                    PolicyNum: "",
                    PolicyAmount: "",
                    OtherField1: "",
                    OtherField2: "",
                    OtherField3: "",
                    OtherField4: "",
                    DateOfIncident: "",
                });
                this.setState({
                    pLastName: false,
                    pGender: false,
                    pPolicyNum: false,
                    pEmail: false,
                    pPCAddress: false,
                    pPCCity: false,
                    pPCZipCode: false,
                    pPhone1: false,
                    pPhone1Type: false,
                    pAgentFN: false,
                    pAgentLN: false,
                    pAgentEmail: false,
                    pCMEmailAddress: false,
                    pAgentPhone: false,
                    pFacilityName: false,
                    pDoctorFirstName: false,
                    pDoctorLastName: false,
                    pDoctorCity: false,
                    pDrZipCode: false,
                    pSSN: false,
                    pOtherNames: false,
                    pPhone2: false,
                    pPhone3: false,
                    pFax: false,
                    pPhone2Type: false,
                    pPhone3Type: false,
                    pPreferredContact: false,
                    pTitle: false,
                    pStateId: false,
                    pExt1: false,
                    pExt2: false,
                    pExt3: false,
                    pAgentExt: false,
                    pAgentMobile: false,
                    pAgentFax: false,
                    pAgentId: false,
                    pDrAddress: false,
                    pDrEmailAddress: false,
                    pDrStateId: false,
                    pPhone: false,
                    pDrFax: false,
                    DrStateId: "",
                    choseRadiox: false,
                    choseRadioxtext: false,
                    dosd: false,
                    doed: false,
                    pFirstName: false,
                    pDOB: false,
                    pPolicyAmount: false,
                    pOtherField1: false,
                    pOtherField2: false,
                    pOtherField3: false,
                    pOtherField4: false,
                    pDateOfIncident: false,
                    orderingofficereq: false,
                });
                this.setState({
                    Email: "",
                    PCAddress: "",
                    PCCity: "",
                    State: "Select",
                    PCZipCode: "",
                    Phone1: "",
                    Phone3: "",
                    Phone1Type: "",
                    Phone2: "",
                    Phone2Type: "",
                    Phone3Type: "",
                    Ext1: "",
                    Ext2: "",
                    Ext3: "",
                    AgentLN: "",
                    DOB: "",
                    Fax: "",
                    AgentFN: "",
                    AgentEmail: "",
                    AgentMobile: "",
                    AgentExt: "",
                    AgentFax: "",
                    AgentId: "",
                    AgentMobile: "",
                    AgentPhone: "",
                    DocList: [],
                    PolicyAmount: "",
                });
                this.setState({
                    CMId: "",
                    CMFN: "",
                    CMLN: "",
                    CMEmailAddress: "",
                    CMWorkPhone: "",
                    CMExt: "",
                    CMCellPhone: "",
                    CMFax: ""
                });
                this.setState({
                    pCMFN: "",
                    pCMLN: "",
                    pCMEmailAddress: "",
                    pCMWorkPhone: "",
                    pCMExt: "",
                    pCMCellPhone: "",
                    pCMFax: ""
                });
            }
            this.setState({ Loading: false });

            setTimeout(
                function () {
                    document.getElementById("dobDate").value = "";
                    $("#dobDate").removeAttr("input");
                    $(".clear-button").trigger("click");
                }.bind(this),
                100
            );
        }
    }
    handleMedicalRequest = () => {
        if (this.state.DatesOfService == "AllRecords" || (this.state.CustomStartDate instanceof Date && this.state.CustomEndDate instanceof Date)) {
            this.setState({
                isMedicalModalopen: true
            });
        }
        else {
            this.toasterredfun2("Dates of service is required");
        }
    };
    handleBillingRequest = () => {
        if (this.state.DatesOfService == "AllRecords" || (this.state.CustomStartDate instanceof Date && this.state.CustomEndDate instanceof Date)) {
            this.setState({
                isBillingModalopen: true
            });
        }
        else {
            this.toasterredfun2("Dates of service is required");
        }
    };
    handleFilmsRequest = () => {
        if (this.state.DatesOfService == "AllRecords" || (this.state.CustomStartDate instanceof Date && this.state.CustomEndDate instanceof Date)) {
            this.setState({
                isFilmsModalopen: true
            });
        }
        else {
            this.toasterredfun2("Dates of service is required");
        }
    };
    handleOtherRequest = () => {
        if (this.state.DatesOfService == "AllRecords" || (this.state.CustomStartDate instanceof Date && this.state.CustomEndDate instanceof Date)) {
            this.setState({
                isOtherModalopen: true
            });
        }
        else {
            this.toasterredfun2("Dates of service is required");
        }
    };
    addMedicalRequest = () => {
        if ((this.state.MedicalSplInstruct == "" && this.state.MedicalSplInstruct.length == 0) || (this.state.MedicalCustodianInstruction == "" && this.state.MedicalCustodianInstruction.length == 0)) {
            this.toasterredfun2("Please provide instructions");
        } else {
            let medicalRequestType = this.state.RequestTypes.find(rt => rt.RequestType == "Medical");
            if (medicalRequestType) {
                medicalRequestType.RushRequest = this.state.MedicalRushRequest;
                medicalRequestType.CertifiedRequest = this.state.MedicalCertifiedRequest;
                medicalRequestType.AffidavitRequest = this.state.MedicalAffidavitRequest;
                medicalRequestType.SpecialInstructionType = this.state.MedicalSplInstruct;
                medicalRequestType.SpecialInstruction = this.state.MedicalCustodianInstruction;
            } else {
                let newMedicalRequestType = {
                    RequestType: "Medical",
                    RushRequest: this.state.MedicalRushRequest,
                    CertifiedRequest: this.state.MedicalCertifiedRequest,
                    AffidavitRequest: this.state.MedicalAffidavitRequest,
                    SpecialInstructionType: this.state.MedicalSplInstruct,
                    SpecialInstruction: this.state.MedicalCustodianInstruction
                };
                let requestTypes = [
                    ...this.state.RequestTypes,
                    newMedicalRequestType
                ];
                this.setState({ RequestTypes: requestTypes });
            }
            this.setState({
                MedicalRequestType: "Medical",
                isMedicalModalopen: false
            });
        }
    }
    addBillingRequest = () => {
        if ((this.state.BillingSplInstruct == "" && this.state.BillingSplInstruct.length == 0) || (this.state.BillingCustodianInstruction == "" && this.state.BillingCustodianInstruction.length == 0)) {
            this.toasterredfun2("Please provide instructions");
        } else {
            let billingRequestType = this.state.RequestTypes.find(rt => rt.RequestType == "Billing");
            if (billingRequestType) {
                billingRequestType.RushRequest = this.state.BillingRushRequest;
                billingRequestType.CertifiedRequest = this.state.BillingCertifiedRequest;
                billingRequestType.AffidavitRequest = this.state.BillingAffidavitRequest;
                billingRequestType.SpecialInstructionType = this.state.BillingSplInstruct;
                billingRequestType.SpecialInstruction = this.state.BillingCustodianInstruction;
            } else {
                let newBillingRequestType = {
                    RequestType: "Billing",
                    RushRequest: this.state.BillingRushRequest,
                    CertifiedRequest: this.state.BillingCertifiedRequest,
                    AffidavitRequest: this.state.BillingAffidavitRequest,
                    SpecialInstructionType: this.state.BillingSplInstruct,
                    SpecialInstruction: this.state.BillingCustodianInstruction
                };
                let requestTypes = [
                    ...this.state.RequestTypes,
                    newBillingRequestType
                ];
                this.setState({ RequestTypes: requestTypes });
            }
            this.setState({
                BillingRequestType: "Billing",
                isBillingModalopen: false
            });
        }
    }
    addFilmsRequest = () => {
        if ((this.state.FilmsSplInstruct == "" && this.state.FilmsSplInstruct.length == 0) || (this.state.FilmsCustodianInstruction == "" && this.state.FilmsCustodianInstruction.length == 0)) {
            this.toasterredfun2("Please provide instructions");
        } else {
            let filmsRequestType = this.state.RequestTypes.find(rt => rt.RequestType == "Films");
            if (filmsRequestType) {
                filmsRequestType.RushRequest = this.state.FilmsRushRequest;
                filmsRequestType.CertifiedRequest = this.state.FilmsCertifiedRequest;
                filmsRequestType.AffidavitRequest = this.state.FilmsAffidavitRequest;
                filmsRequestType.SpecialInstructionType = this.state.FilmsSplInstruct;
                filmsRequestType.SpecialInstruction = this.state.FilmsCustodianInstruction;
            } else {
                let newFilmsRequestType = {
                    RequestType: "Films",
                    RushRequest: this.state.FilmsRushRequest,
                    CertifiedRequest: this.state.FilmsCertifiedRequest,
                    AffidavitRequest: this.state.FilmsAffidavitRequest,
                    SpecialInstructionType: this.state.FilmsSplInstruct,
                    SpecialInstruction: this.state.FilmsCustodianInstruction
                };
                let requestTypes = [
                    ...this.state.RequestTypes,
                    newFilmsRequestType
                ];
                this.setState({ RequestTypes: requestTypes });
            }
            this.setState({
                FilmsRequestType: "Films",
                isFilmsModalopen: false
            });
        }
    }
    addOtherRequest = () => {
        if ((this.state.OtherSplInstruct == "" && this.state.OtherSplInstruct.length == 0) || (this.state.OtherCustodianInstruction == "" && this.state.OtherCustodianInstruction.length == 0)) {
            this.toasterredfun2("Please provide instructions");
        } else {
            let otherRequestType = this.state.RequestTypes.find(rt => rt.RequestType == "Other");
            if (otherRequestType) {
                otherRequestType.RushRequest = this.state.OtherRushRequest;
                otherRequestType.CertifiedRequest = this.state.OtherCertifiedRequest;
                otherRequestType.AffidavitRequest = this.state.OtherAffidavitRequest;
                otherRequestType.SpecialInstructionType = this.state.OtherSplInstruct;
                otherRequestType.SpecialInstruction = this.state.OtherCustodianInstruction;
            } else {
                let newOtherRequestType = {
                    RequestType: "Other",
                    RushRequest: this.state.OtherRushRequest,
                    CertifiedRequest: this.state.OtherCertifiedRequest,
                    AffidavitRequest: this.state.OtherAffidavitRequest,
                    SpecialInstructionType: this.state.OtherSplInstruct,
                    SpecialInstruction: this.state.OtherCustodianInstruction
                };
                let requestTypes = [
                    ...this.state.RequestTypes,
                    newOtherRequestType
                ];
                this.setState({ RequestTypes: requestTypes });
            }
            this.setState({
                OtherRequestType: "Other",
                isOtherModalopen: false
            });
        }
    }
    closeMedicalRequest = () => {
        this.setState({
            isMedicalModalopen: false,
        });
    }
    closeBillingRequest = () => {
        this.setState({
            isBillingModalopen: false
        });
    }
    closeFilmsRequest = () => {
        this.setState({
            isFilmsModalopen: false
        });
    }
    closeOtherRequest = () => {
        this.setState({
            isOtherModalopen: false
        });
    }
    cancelOrRemoveMedicalRequest = () => {
        let requestTypes = this.state.RequestTypes;

        let matchedIndex = requestTypes.findIndex(r => r.RequestType == "Medical");
        if (matchedIndex != -1) {
            requestTypes.splice(matchedIndex, 1);
        }

        this.setState({
            MedicalRequestType: "",
            MedicalRushRequest: false,
            MedicalCertifiedRequest: false,
            MedicalAffidavitRequest: false,
            MedicalSplInstruct: "",
            MedicalCustodianInstruction: "",
            isMedicalModalopen: false,
            RequestTypes: requestTypes
        });
    }
    cancelOrRemoveBillingRequest = () => {
        let requestTypes = this.state.RequestTypes;

        let matchedIndex = requestTypes.findIndex(r => r.RequestType == "Billing");
        if (matchedIndex != -1) {
            requestTypes.splice(matchedIndex, 1);
        }

        this.setState({
            BillingRequestType: "",
            BillingRushRequest: false,
            BillingCertifiedRequest: false,
            BillingAffidavitRequest: false,
            BillingSplInstruct: "",
            BillingCustodianInstruction: "",
            isBillingModalopen: false,
            RequestTypes: requestTypes
        });
    }
    cancelOrRemoveFilmsRequest = () => {
        let requestTypes = this.state.RequestTypes;

        let matchedIndex = requestTypes.findIndex(r => r.RequestType == "Films");
        if (matchedIndex != -1) {
            requestTypes.splice(matchedIndex, 1);
        }

        this.setState({
            FilmsRequestType: "",
            FilmsRushRequest: false,
            FilmsCertifiedRequest: false,
            FilmsAffidavitRequest: false,
            FilmsSplInstruct: "",
            FilmsCustodianInstruction: "",
            isFilmsModalopen: false,
            RequestTypes: requestTypes
        });
    }
    cancelOrRemoveOtherRequest = () => {
        let requestTypes = this.state.RequestTypes;

        let matchedIndex = requestTypes.findIndex(r => r.RequestType == "Other");
        if (matchedIndex != -1) {
            requestTypes.splice(matchedIndex, 1);
        }

        this.setState({
            OtherRequestType: "",
            OtherRushRequest: false,
            OtherCertifiedRequest: false,
            OtherAffidavitRequest: false,
            OtherSplInstruct: "",
            OtherCustodianInstruction: "",
            isOtherModalopen: false,
            RequestTypes: requestTypes
        });
    }
    handleMedicalRushRequestSwitch(elem, state) {
        this.setState({
            MedicalRushRequest: state
        });
    }
    handleMedicalCertifiedRequestSwitch(elem, state) {
        this.setState({
            MedicalCertifiedRequest: state,
            MedicalAffidavitRequest: false
        });
    }
    handleMedicalAffidavitRequestSwitch(elem, state) {
        this.setState({
            MedicalAffidavitRequest: state,
            MedicalCertifiedRequest: false
        });
    }
    handleBillingRushRequestSwitch(elem, state) {
        this.setState({
            BillingRushRequest: state
        });
    }
    handleBillingCertifiedRequestSwitch(elem, state) {
        this.setState({
            BillingCertifiedRequest: state,
            BillingAffidavitRequest: false
        });
    }
    handleBillingAffidavitRequestSwitch(elem, state) {
        this.setState({
            BillingAffidavitRequest: state,
            BillingCertifiedRequest: false
        });
    }
    handleFilmsRushRequestSwitch(elem, state) {
        this.setState({
            FilmsRushRequest: state
        });
    }
    handleFilmsCertifiedRequestSwitch(elem, state) {
        this.setState({
            FilmsCertifiedRequest: state,
            FilmsAffidavitRequest: false
        });
    }
    handleFilmsAffidavitRequestSwitch(elem, state) {
        this.setState({
            FilmsAffidavitRequest: state,
            FilmsCertifiedRequest: false
        });
    }
    handleOtherRushRequestSwitch(elem, state) {
        this.setState({
            OtherRushRequest: state
        });
    }
    handleOtherCertifiedRequestSwitch(elem, state) {
        this.setState({
            OtherCertifiedRequest: state,
            OtherAffidavitRequest: false
        });
    }
    handleOtherAffidavitRequestSwitch(elem, state) {
        this.setState({
            OtherAffidavitRequest: state,
            OtherCertifiedRequest: false
        });
    }
    handleMedicalSplInstructStandard = (e) => {
        this.setState({
            MedicalSplInstruct: e.target.checked ? e.target.value : ""
        });
        if (e.target.checked) {
            if (this.state.DatesOfService == "AllRecords") {
                this.setState({
                    MedicalCustodianInstruction: "In electronic format, please provide any and all Medical records."
                });
            }
            else {
                this.setState({
                    MedicalCustodianInstruction: "In electronic format, please provide any and all Medical records from " + this.formatDateToString(this.state.CustomStartDate) +
                        " to " +
                        this.formatDateToString(this.state.CustomEndDate)
                });
            }
        }
        else {
            this.setState({
                MedicalCustodianInstruction: ""
            });
        }
    }
    handleMedicalSplInstructCustom = (e) => {
        this.setState({
            MedicalSplInstruct: e.target.checked ? e.target.value : ""
        });
        if (!e.target.checked) {
            this.setState({
                MedicalCustodianInstruction: ""
            });
        }
    }
    handleBillingSplInstructStandard = (e) => {
        this.setState({
            BillingSplInstruct: e.target.checked ? e.target.value : ""
        });
        if (e.target.checked) {
            if (this.state.DatesOfService == "AllRecords") {
                this.setState({
                    BillingCustodianInstruction: "In electronic format, please provide any and all Billing records."
                });
            }
            else {
                this.setState({
                    BillingCustodianInstruction: "In electronic format, please provide any and all Billing records from " + this.formatDateToString(this.state.CustomStartDate) +
                        " to " +
                        this.formatDateToString(this.state.CustomEndDate)
                });
            }
        }
        else {
            this.setState({
                BillingCustodianInstruction: ""
            });
        }
    }
    handleBillingSplInstructCustom = (e) => {
        this.setState({
            BillingSplInstruct: e.target.checked ? e.target.value : ""
        });
        if (!e.target.checked) {
            this.setState({
                BillingCustodianInstruction: ""
            });
        }
    }
    handleFilmsSplInstructStandard = (e) => {
        this.setState({
            FilmsSplInstruct: e.target.checked ? e.target.value : ""
        });
        if (e.target.checked) {
            if (this.state.DatesOfService == "AllRecords") {
                this.setState({
                    FilmsCustodianInstruction: "In electronic format, please provide any and all Film records."
                });
            }
            else {
                this.setState({
                    FilmsCustodianInstruction: "In electronic format, please provide any and all Film records from " + this.formatDateToString(this.state.CustomStartDate) +
                        " to " +
                        this.formatDateToString(this.state.CustomEndDate)
                });
            }
        }
        else {
            this.setState({
                FilmsCustodianInstruction: ""
            });
        }
    }
    handleFilmsSplInstructCustom = (e) => {
        this.setState({
            FilmsSplInstruct: e.target.checked ? e.target.value : ""
        });
        if (!e.target.checked) {
            this.setState({
                FilmsCustodianInstruction: ""
            });
        }
    }
    handleOtherSplInstructStandard = (e) => {
        this.setState({
            OtherSplInstruct: e.target.checked ? e.target.value : ""
        });
        if (e.target.checked) {
            if (this.state.DatesOfService == "AllRecords") {
                this.setState({
                    OtherCustodianInstruction: "In electronic format, please provide any and all Other records."
                });
            }
            else {
                this.setState({
                    OtherCustodianInstruction: "In electronic format, please provide any and all Other records from " + this.formatDateToString(this.state.CustomStartDate) +
                        " to " +
                        this.formatDateToString(this.state.CustomEndDate)
                });
            }
        }
        else {
            this.setState({
                OtherCustodianInstruction: ""
            });
        }
    }
    handleOtherSplInstructCustom = (e) => {
        this.setState({
            OtherSplInstruct: e.target.checked ? e.target.value : ""
        });
        if (!e.target.checked) {
            this.setState({
                OtherCustodianInstruction: ""
            });
        }
    }
    handleMedicalCustodianInstructChange = (e) => {
        this.setState({
            MedicalCustodianInstruction: e.target.value, MedicalSplInstruct: "Custom", choseRadioxtext: false
        });
    }
    handleBillingCustodianInstructChange = (e) => {
        this.setState({
            BillingCustodianInstruction: e.target.value, BillingSplInstruct: "Custom", choseRadioxtext: false
        });
    }
    handleFilmsCustodianInstructChange = (e) => {
        this.setState({
            FilmsCustodianInstruction: e.target.value, FilmsSplInstruct: "Custom", choseRadioxtext: false
        });
    }
    handleOtherCustodianInstructChange = (e) => {
        this.setState({
            OtherCustodianInstruction: e.target.value, OtherSplInstruct: "Custom", choseRadioxtext: false
        });
    }

    Onclickisadminpopup() {
        let currentComponent = this;
        currentComponent.props.history.push("/dashboard");
        currentComponent.setState({ adminAlert: false, loading: true });
    }

    async CheckForPlaceOrder() {
  /*      var user = jwt_decode(localStorage.getItem("UserId"));
        var userId = user.UserId;*/
        let currentComponent = this;
        let responce = false;        
        currentComponent.setState({ Loading: true });
        await Api.Get('api/placeorder/AllowExportValues', currentComponent).then(function (result) {
            if (result.statusCode == "100") {
                if (!isNullOrUndefined(result)) {
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.allowExport);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    if (!result.placeOrder) {

                        currentComponent.setState({ Loading: false });
                        currentComponent.setState({ adminAlert: true });
                        responce = false;

                    }
                    else {
                        currentComponent.setState({ Loading: false });
                        responce = true;
                    }

                }
                else {
                    currentComponent.setState({ Loading: false });
                }

            } else {
                currentComponent.setState({ Loading: false });
                currentComponent.toasterredfun(result.statusMessage);
                responce = false;
            }
        });

        return responce;
    }
    
    render() {
        var isFromInboundOrder = window.location.href.indexOf("isFromInboundOrder") != -1 ? true : false;
        var landing = "img/ems-logo.gif";
        return (
            <Layout>
                <TimeoutCounter willunMount={this.HoldOrderWRD} />
                <div
                    className={
                        "removeAlert-div " + (this.state.removeAlert ? "show" : "hide")
                    }
                >
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x">
                        {" "}
                        <i className="fa fa-bell" aria-hidden="true"></i> Are you sure you
                        provider clear?
                        <div className="text-center-div">
                            <div className="bttn popupButton" onClick={this.ClearProvider}>
                                Yes
                            </div>
                            <div
                                className="bttn popupButton"
                                onClick={(e) => {
                                    this.handleremoveCancelbttn();
                                }}
                            >
                                No
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={
                        "removeAlert-div " + (this.state.removeAgentAlert ? "show" : "hide")
                    }
                >
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x">
                        {" "}
                        <i className="fa fa-bell" aria-hidden="true"></i> Are you sure want to clear Agent?
                        <div className="text-center-div">
                            <div
                                className="bttn popupButton"
                                onClick={this.ClearAgentProvider}
                            >
                                Yes
                            </div>
                            <div
                                className="bttn popupButton"
                                onClick={(e) => {
                                    this.handleremoveCancelbttn();
                                }}
                            >
                                No
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={
                        "removeAlert-div " + (this.state.removeAdjusterAlert ? "show" : "hide")
                    }
                >
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x">
                        {" "}
                        <i className="fa fa-bell" aria-hidden="true"></i> Are you sure want to clear Adjuster?
                        <div className="text-center-div">
                            <div
                                className="bttn popupButton"
                                onClick={this.ClearAdjusterProvider}
                            >
                                Yes
                            </div>
                            <div
                                className="bttn popupButton"
                                onClick={(e) => {
                                    this.handleremoveCancelbttn();
                                }}
                            >
                                No
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={
                        "removeAlert-div " + (this.state.removeAlertx ? "show" : "hide")
                    }
                >
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x">
                        {" "}
                        <i className="fa fa-bell" aria-hidden="true"></i> Are you sure,
                        Do you want to clear the data you entered?
                        <div className="text-center-div">
                            <div className="bttn popupButton" onClick={this.handleClear}>
                                Yes
                            </div>
                            <div
                                className="bttn popupButton"
                                onClick={(e) => {
                                    this.handleremoveCancelbttnx();
                                }}
                            >
                                No
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={
                        "removeAlert-div " + (this.state.removeCMAlert ? "show" : "hide")
                    }
                >
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x">
                        {" "}
                        <i className="fa fa-bell" aria-hidden="true"></i> Are you sure want to clear Client Manager?
                        <div className="text-center-div">
                            <div
                                className="bttn popupButton"
                                onClick={this.ClearCarrierManager}
                            >
                                Yes
                            </div>
                            <div
                                className="bttn popupButton"
                                onClick={(e) => {
                                    this.handleremoveCarrierManagerCancelbttn();
                                }}
                            >
                                No
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={
                        "removeAlert-div " + (this.state.deleteAlert ? "show" : "hide")
                    }
                >
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x">
                        {" "}
                        <i className="fa fa-bell" aria-hidden="true"></i> Are you sure you
                        wish to delete this item?
                        <div className="text-center-div">
                            <div
                                className="bttn popupButton"
                                onClick={(e) => {
                                    this.Onclicktabledelpopup(
                                        this.state.removehidden,
                                        this.state.typehidden
                                    );
                                }}
                            >
                                Yes
                                <input
                                    type="hidden"
                                    value={this.state.removehidden}
                                    readOnly="readonly"
                                />
                                <input
                                    type="hidden"
                                    value={this.state.typehidden}
                                    readOnly="readonly"
                                />
                            </div>
                            <div
                                className="bttn popupButton"
                                onClick={(e) => {
                                    this.handledeletebttn();
                                }}
                            >
                                No
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"removeAlert-divbrowser " + (this.state.adminAlert ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x text-center">
                        <img src="img/error.png" alt="Error" />
                        <p className="my-3"> Your permissions has been modified/revoked to perform this action. Please contact your administrator.</p>
                        <div className="text-center-div m-0">
                            <div className="bttn popupButton m-0" onClick={this.Onclickisadminpopup}>
                                Ok
                            </div>
                        </div>
                    </div>
                </div>

                <span id="load" className={"loading " + (this.state.Loading ? "show" : "hide")}>
                    <span className="loading-div-d"></span>{" "}
                    <img src={process.env.PUBLIC_URL + landing} />{" "}
                </span>
                <div
                    className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}
                >
                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                    {this.state.toaster}{" "}
                </div>
                <div
                    className={
                        "toasterred-div " + (this.state.toasterredCla ? "show" : "hide")
                    }
                >
                    <i className="fa fa-exclamation-circle" aria-hidden="true"></i>{" "}
                    {this.state.toaster}{" "}
                </div>
                <div className="hgtDiv pt-2">
                    <div className="bg-gray nopadding">
                        <div className="row no-gutters">
                            <div className="col-xl-12 px-1">
                                <div className="bgwhite">
                                    <div className="card mb-1 placeholder-div">
                                        <div className="form-row">
                                            <div className="pull-left nopadding">
                                                <label
                                                    className="small mdb-1"
                                                    htmlFor="inputSelectOrderingOffice"
                                                >
                                                    SELECT ORDERING OFFICE:
                                                </label>
                                                <span className="red">*</span>
                                            </div>
                                            <div className="col-md-2 pull-left nopadding">
                                                <div className="form-group">
                                                    <select
                                                        className={
                                                            "form-control " +
                                                            (this.state.orderingofficereq
                                                                ? "redborder"
                                                                : "blackborder")
                                                        }
                                                        value={this.state.OrderingOfficeId}
                                                        onChange={this.handleOrderingOfficeChange}
                                                        //disabled={
                                                        //    this.state.orderingofficeRO
                                                        //}
                                                        disabled={this.state.DisOrderingOffice}
                                                    >
                                                        {this.state.OrderingOfficeList.length == 1 && this.state.IsAdditionalOrder &&
                                                            this.state.OrderingOfficeList.map((item, i) => {
                                                                if (i == 0) {
                                                                    return (
                                                                        <option key="0" value="Select">
                                                                            Select
                                                                        </option>
                                                                    );
                                                                }
                                                            }, this)}

                                                        {this.state.OrderingOfficeList.length == 1 &&
                                                            this.state.OrderingOfficeList.map((item, i) => {
                                                                return (
                                                                    <option
                                                                        key={item.officeAccountNumber}
                                                                        value={item.id}
                                                                    >
                                                                        {item.officeName}
                                                                    </option>
                                                                );
                                                            }, this)}
                                                        {this.state.OrderingOfficeList.length > 1 &&
                                                            this.state.OrderingOfficeList.map((item, i) => {
                                                                if (i == 0) {
                                                                    return (
                                                                        <option key="0" value="Select">
                                                                            Select
                                                                        </option>
                                                                    );
                                                                }
                                                            }, this)}

                                                        {this.state.OrderingOfficeList.length > 1 &&
                                                            this.state.OrderingOfficeList.map((item, i) => {
                                                                return (
                                                                    <option
                                                                        key={`${item.officeAccountNumber}-${item.id}`}
                                                                        value={item.id}
                                                                    >
                                                                        {item.officeName}
                                                                    </option>
                                                                );
                                                            }, this)}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className={"col-md-4 pull-left nopadding align-self-end " + (this.state.AllowDefaultOfficeCA ? "show" : "hide")}>
                                                <div className="form-group">
                                                    <input
                                                        type="checkbox"
                                                        id="medical"
                                                        className="pull-left"
                                                        name="isDefaultOrderingOffice"
                                                        value="IsDefaultOrderingOffice"
                                                        onChange={this.handleDefaultOrderingOffice}
                                                        checked={this.state.IsDefaultOrderingOffice}
                                                        disabled={this.state.isdefaultorderingofficeRO}
                                                    />
                                                    <label className="small leftside" htmlFor="medical">
                                                        Save As Default Ordering Office
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 px-1">
                                <div className="bgwhite">
                                    <div className="card mb-1 placeholder-div">
                                        <div className="card-header posrel">
                                            {this.state.individualHeaderLabel}:
                                            <div className="icons-div-mx">
                                                <img src="img/user-icon.png" />{" "}
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className={this.state.HideLifeDiv ? "show" : "hide"}>
                                                <div className="form-row">
                                                    <div className="col-md-7 nopadding">
                                                        <div className={(this.state.TitleHide ? "show" : "hide")}>
                                                            <div className="col-md-2 pull-left nopadding">
                                                                <label
                                                                    className="small mdb-1"
                                                                    htmlFor="inputTitle"
                                                                ><span style={{ float: "left" }}
                                                                    className={
                                                                        "red " +
                                                                        (this.state.isMandatoryTitle ? "show" : "hide")
                                                                    }
                                                                >
                                                                        *
                                                                    </span>{" "} {this.state.lblTitle}:{" "}
                                                                </label>
                                                            </div>
                                                            <div className="col-md-2 pull-left nopadding">
                                                                <input
                                                                    className={
                                                                        "form-control " +
                                                                        (this.state.pTitle
                                                                            ? "redborder"
                                                                            : "blackborder")
                                                                    }
                                                                    id="inputTitle"
                                                                    type="text"
                                                                    autoComplete="off"
                                                                    value={this.state.Title}
                                                                    onChange={this.handleTitleChange}
                                                                    readOnly={this.state.titleRO ? "readOnly" : ""}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className={"col-md-3 pull-left nopadding " + (this.state.FirstNameHide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="inputFirstName"
                                                            ><span style={{ float: "left" }}
                                                                className={
                                                                    "red " +
                                                                    (this.state.isMandatoryFirstName ? "show" : "hide")
                                                                }
                                                            >
                                                                    *
                                                                </span>{" "}
                                                                {this.state.lblFirstName}:{" "}
                                                            </label>

                                                        </div>
                                                        <div className={"col-md-5 pull-left nopadding " + (this.state.FirstNameHide ? "show" : "hide")}>
                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pFirstName
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputFirstName"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.FirstName}
                                                                onChange={this.handleFNChange}
                                                                maxLength={50}
                                                                readOnly={
                                                                    this.state.firstNameRO ? "readOnly" : ""
                                                                }
                                                                onBlur={this.handleFNBlur}
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="col-md-5 nopadding">
                                                        <div className={"col-md-4 pull-left nopadding " + (this.state.LastNameHide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="inputLastName"
                                                                style={{ position: "relative" }}
                                                            ><span style={{ float: "left" }}
                                                                className={
                                                                    "red " +
                                                                    (this.state.isMandatoryLastName ? "show" : "hide")
                                                                }
                                                            >
                                                                    *
                                                                </span>{" "}
                                                                {this.state.lblLastName}:{" "}

                                                            </label>
                                                        </div>
                                                        <div className={"col-md-8 pull-left nopadding " + (this.state.LastNameHide ? "show" : "hide")}>

                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pLastName
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputLastName"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.LastName}
                                                                onChange={this.handleLNChange}
                                                                maxLength={50}
                                                                readOnly={this.state.lastNameRO ? "readOnly" : ""}
                                                                onBlur={this.handleFNBlur}
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="col-md-7 nopadding">

                                                        <div className={"col-md-2 pull-left nopadding " + (this.state.DOBHide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="dobDate"
                                                            ><span style={{ float: "left" }}
                                                                className={
                                                                    "red " +
                                                                    (this.state.isMandatoryDOB ? "show" : "hide")
                                                                }
                                                            >
                                                                    *
                                                                </span>{" "}
                                                                {this.state.lblDOB}:{" "}
                                                            </label>
                                                        </div>
                                                        <div className={"col-md-4 pull-left nopadding dop-d " + (this.state.DOBHide ? "show" : "hide")} >
                                                            {(this.state.DOB.toString().length > 0 && this.state.MaskDOB && this.state.DOB.toString().toLowerCase() != "invalid date") ?
                                                                (
                                                                    <span>
                                                                        <input className={
                                                                            "form-control " +
                                                                            (this.state.pDOB ? "redborder" : "blackborder")
                                                                        } type='text' value="**********" onClick={this.handleDOBfocusIn} disabled={this.state.dobRO} onFocus={this.handleDOBfocusIn} />

                                                                    </span>
                                                                ) : (
                                                                    <span tabIndex="-1" onBlur={this.handleDOBfocusOut}>

                                                                        <DatePickerInput
                                                                            type="text"                                                                            
                                                                            value={this.state.DOB}
                                                                            selected={this.state.DOB}
                                                                            onClear={this.handleDOBclear}
                                                                            id="dobDate"
                                                                            onChange={this.handleDOBChange}
                                                                            maxLength="10"
                                                                            onKeyPress={this.onKeyPressValue.bind(this)}
                                                                            className={
                                                                                "docs-date form-control " +
                                                                                (this.state.pDOB ? "redborder" : "blackborder")
                                                                            }
                                                                            maxDate={this.state.maxDate}
                                                                            minDate={this.state.minDate}
                                                                            disabled={this.state.dobRO}
                                                                            onClick={this.handleDOBfocusIn}
                                                                            autoComplete="off"
                                                                            onFocus={this.handleDOBfocusIn}
                                                                            
                                                                        />
                                                                    </span>
                                                                )}

                                                            <div className={"view-dob " + (this.state.DOBHide ? "show " : "hide ")}>
                                                                <button className={"eye-for-dob " + (this.state.DOBEyeHide ? "hide" : "show")} onMouseLeave={this.handleDOBMouseLeave} onMouseDown={this.handleDOBEvent} onMouseUp={this.handleDOBEvent} ><i id="eye-dob" className="fa fa-eye-slash" onClick={this.disabled = true}></i></button>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className={"col-md-2 pull-left nopadding " + (this.state.SSNHide ? "show" : "hide")} >

                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="inputSSN"
                                                            ><span style={{ float: "left" }}
                                                                className={
                                                                    "red " +
                                                                    (this.state.isMandatorySSN ? "show" : "hide")
                                                                }
                                                            >
                                                                    *
                                                                </span>{" "}
                                                                {this.state.lblSSN}:{" "}
                                                            </label>
                                                        </div>

                                                        <div className={"col-md-4 pull-left nopadding " + (this.state.SSNHide ? "show" : "hide")} >
                                                            {!this.state.isFromCaseDetails ?
                                                                (
                                                                    <p className="ssnfield">


                                                                        <input

                                                                            className={"form-control " + (this.state.pSSN ? "redborder" : "blackborder")}
                                                                            ref="ssn"
                                                                            id="inputSSN"
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            value={this.state.ShowSSNValue ? this.state.ShowSSNBFocus ? this.state.SSN : this.state.MaskValue : this.state.SSN.length > 0 ? 'xxx-xxx-xxx' : ""}
                                                                            maxLength={11}
                                                                            onChange={this.handleSSNChange}
                                                                            readOnly={this.state.ssnRO ? "readOnly" : ""}
                                                                            onBlur={this.handleSSNfocusOut}
                                                                            onClick={this.handleSSNfocusIn}
                                                                            onFocus={this.handleSSNfocusIn}





                                                                        />

                                                                    </p>
                                                                ) : (
                                                                    <p className="ssnfield">

                                                                        <input

                                                                            className="form-control"
                                                                            ref="ssn"
                                                                            id="inputSSN"
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            value={this.state.ssnRO && this.state.SSN.length > 0 ? this.state.ShowSSNValueFromCaseDetails ?  'xxx-xxx-' + this.state.SSN.substr(this.state.SSN.length - 4) : 'xxx-xxx-xxx': ""}
                                                                            maxLength={11}
                                                                            onChange={this.handleSSNChange}
                                                                            readOnly={this.state.ssnRO ? "readOnly" : ""}
                                                                            onBlur={this.handleFNBlur}



                                                                        />

                                                                    </p>


                                                                )}

                                                        </div>
                                                        {!this.state.isFromCaseDetails ?(
                                                            <div className={"view-ssn " + (this.state.SSNHide ? "show " : "hide ")}>
                                                                <button className={"eye-for-ssn " + (this.state.SSNEyeHide ? "hide" : "show")} onMouseLeave={this.handleSSNMouseLeave} onMouseDown={this.handleEvent} onMouseUp={this.handleEvent} ><i id="eye-ssn" className="fa fa-eye-slash"></i></button>
                                                            </div>
                                                        ):
                                                            (
                                                                <div className={"view-ssn " + (this.state.SSNHide ? "show " : "hide ")}>
                                                                    <button className={"eye-for-ssn"} onMouseDown={this.handleEventFromCaseDetails} onMouseLeave={this.handleSSNMouseLeave} onMouseUp={this.handleEventFromCaseDetails} ><i id="eye-ssn" className="fa fa-eye-slash"></i></button>
                                                                </div>

                                                            )}
                                                    </div>
                                                    <div className="col-md-5 nopadding">

                                                        <div className={"col-md-4 pull-left nopadding " + (this.state.GenderHide ? "show" : "hide")} >

                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="inputGender"
                                                            ><span style={{ float: "left" }}
                                                                className={
                                                                    "red " +
                                                                    (this.state.isMandatoryGender ? "show" : "hide")
                                                                }
                                                            >
                                                                    *
                                                                </span>{" "}
                                                                {this.state.lblGender}:{" "}
                                                            </label>

                                                        </div>

                                                        <div className={"col-md-8 pull-left nopadding " + (this.state.GenderHide ? "show" : "hide")} >
                                                            <div className="form-group">
                                                                <select
                                                                    className={
                                                                        "form-control " +
                                                                        (this.state.pGender
                                                                            ? "redborder"
                                                                            : "blackborder")
                                                                    }
                                                                    value={this.state.Gender}
                                                                    onChange={this.handleGenderChange}
                                                                    disabled={this.state.genderRO ? "disabled" : ""}
                                                                >
                                                                    <option key="0" value="Select">
                                                                        Select
                                                                    </option>
                                                                    {this.state.GenderList.length > 0 &&
                                                                        this.state.GenderList.map((item, i) => {
                                                                            return (
                                                                                <option key={i + 1} value={item.id}>
                                                                                    {item.desc}
                                                                                </option>
                                                                            );
                                                                        }, this)}
                                                                </select>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-7 nopadding">

                                                        <div className={"col-md-4 pull-left nopadding " + (this.state.OtherNamesHide ? "show" : "hide")} >
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="inputOtherName"
                                                            ><span style={{ float: "left" }}
                                                                className={
                                                                    "red " +
                                                                    (this.state.isMandatoryOtherNames ? "show" : "hide")
                                                                }
                                                            >
                                                                    *
                                                                </span>{" "}
                                                                {this.state.lblOtherNames}:{" "}
                                                            </label>
                                                        </div>
                                                        <div className={"col-md-7 pull-left nopadding " + (this.state.OtherNamesHide ? "show" : "hide")} >
                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pOtherNames
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputOtherName"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.OtherNames}
                                                                maxLength={50}
                                                                onChange={this.handleONChange}
                                                                readOnly={
                                                                    this.state.otherNamesRO ? "readOnly" : ""
                                                                }
                                                            />
                                                        </div>


                                                    </div>
                                                    <div className="col-md-5 nopadding">

                                                        <div className={"col-md-4 pull-left nopadding " + (this.state.PolicyNumHide ? "show" : "hide")} >
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="inputPolicyNum"
                                                            ><span style={{ float: "left" }}
                                                                className={
                                                                    "red " +
                                                                    (this.state.isMandatoryPolicyNum ? "show" : "hide")
                                                                }
                                                            >
                                                                    *
                                                                </span>{" "}
                                                                {this.state.lblPolicyNum}
                                                            </label>
                                                        </div>

                                                        <div className={"col-md-8 pull-left nopadding " + (this.state.PolicyNumHide ? "show" : "hide")} >
                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pPolicyNum
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputPolicyNum"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.PolicyNum}
                                                                maxLength={15}
                                                                onChange={this.handlePNChange}
                                                                readOnly={this.state.policyRO ? "readOnly" : ""}
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="col-md-5 nopadding">
                                                        <div className="clearboth"></div>
                                                        <div className={"col-md-5 pull-left nopadding  " + (this.state.PolicyAmountHide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="inputPolicyAmount"
                                                            >
                                                                <span style={{ float: "left" }}
                                                                    className={
                                                                        "red " +
                                                                        (this.state.isMandatoryPolicyAmount ? "show" : "hide")
                                                                    }
                                                                >
                                                                    *
                                                                </span>{" "}{this.state.lblPolicyAmount}:{" "}
                                                            </label>
                                                        </div>

                                                        <div className={"col-md-5 pull-left nopadding  " + (this.state.PolicyAmountHide ? "show" : "hide")}>
                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pPolicyAmount
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputPolicyAmount"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.PolicyAmount}
                                                                maxLength={7}
                                                                onChange={this.handlePAChange}
                                                                readOnly={
                                                                    this.state.policyAmountRO ? "readOnly" : ""
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 nopadding">
                                                        <div className="clearboth"></div>
                                                        <div className={"col-md-4 pull-left nopadding  " + (this.state.OtherField1Hide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="inputOtherField1"
                                                            >
                                                                <span style={{ float: "left" }}
                                                                    className={
                                                                        "red " +
                                                                        (this.state.isMandatoryOtherField1 ? "show" : "hide")
                                                                    }
                                                                >
                                                                    *
                                                                </span>{" "}{this.state.lblOtherField1}:{" "}
                                                            </label>
                                                        </div>

                                                        <div className={"col-md-8 pull-left nopadding  " + (this.state.OtherField1Hide ? "show" : "hide")}>
                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pOtherField1
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputOtherField1"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.OtherField1}
                                                                maxLength={50}
                                                                onChange={this.handleOtherField1}
                                                                readOnly={
                                                                    this.state.pOtherField1RO ? "readOnly" : ""
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 nopadding">
                                                        <div className="clearboth"></div>
                                                        <div className={"col-md-4 pull-left nopadding  " + (this.state.OtherField3Hide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="inputOtherField3"
                                                            >
                                                                <span style={{ float: "left" }}
                                                                    className={
                                                                        "red " +
                                                                        (this.state.isMandatoryOtherField3 ? "show" : "hide")
                                                                    }
                                                                >
                                                                    *
                                                                </span>{" "}{this.state.lblOtherField3}:{" "}
                                                            </label>
                                                        </div>

                                                        <div className={"col-md-8 pull-left nopadding  " + (this.state.OtherField3Hide ? "show" : "hide")}>
                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pOtherField3
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputOtherField3"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.OtherField3}
                                                                maxLength={20}
                                                                onChange={this.handleOtherField3}
                                                                readOnly={
                                                                    this.state.pOtherField3RO ? "readOnly" : ""
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 nopadding">
                                                        <div className="clearboth"></div>

                                                        <div className={"col-md-4 pull-left nopadding  " + (this.state.OtherField2Hide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="inputOtherField2"
                                                            >
                                                                <span style={{ float: "left" }}
                                                                    className={
                                                                        "red " +
                                                                        (this.state.isMandatoryOtherField2 ? "show" : "hide")
                                                                    }
                                                                >
                                                                    *
                                                                </span>{" "}{this.state.lblOtherField2}:{" "}
                                                            </label>
                                                        </div>
                                                        <div className={"col-md-8 pull-left nopadding " + (this.state.OtherField2Hide ? "show" : "hide")}>
                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pOtherField2
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputOtherField2"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.OtherField2}
                                                                maxLength={50}
                                                                onChange={this.handleOtherField2}
                                                                readOnly={
                                                                    this.state.pOtherField2RO ? "readOnly" : ""
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 nopadding">
                                                        <div className="clearboth"></div>
                                                        <div className={"col-md-4 pull-left nopadding  " + (this.state.OtherField4Hide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="inputOtherField4"
                                                            >
                                                                <span style={{ float: "left" }}
                                                                    className={
                                                                        "red " +
                                                                        (this.state.isMandatoryOtherField4 ? "show" : "hide")
                                                                    }
                                                                >
                                                                    *
                                                                </span>{" "}{this.state.lblOtherField4}:{" "}
                                                            </label>
                                                        </div>

                                                        <div className={"col-md-8 pull-left nopadding  " + (this.state.OtherField4Hide ? "show" : "hide")}>
                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pOtherField4
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputOtherField4"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.OtherField4}
                                                                maxLength={50}
                                                                onChange={this.handleOtherField4}
                                                                readOnly={
                                                                    this.state.pOtherField4RO ? "readOnly" : ""
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.HideLegalDiv ? "show" : "hide"}>
                                                <div className="form-row">
                                                    <div className="col-md-6 nopadding">

                                                        <div className={"col-md-4 pull-left nopadding " + (this.state.FirstNameHide ? "show" : "hide")}>

                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="inputpfirstName"
                                                            >    <span style={{ float: "left" }}
                                                                className={
                                                                    "red " +
                                                                    (this.state.isMandatoryFirstName ? "show" : "hide")
                                                                }
                                                            >
                                                                    *
                                                                </span>{" "}
                                                                {this.state.lblFirstName}:{" "}
                                                            </label>

                                                        </div>
                                                        <div className={"col-md-8 pull-left nopadding " + (this.state.FirstNameHide ? "show" : "hide")}>
                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pFirstName
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputpfirstName"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.FirstName}
                                                                onChange={this.handleFNChange}
                                                                maxLength={50}
                                                                readOnly={
                                                                    this.state.firstNameRO ? "readOnly" : ""
                                                                }
                                                                onBlur={this.handleFNBlur}
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="col-md-6 nopadding">

                                                        <div className={"col-md-4 pull-left nopadding " + (this.state.LastNameHide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="inputplastname"
                                                                style={{ position: "relative" }}
                                                            ><span style={{ float: "left" }}
                                                                className={
                                                                    "red " +
                                                                    (this.state.isMandatoryLastName ? "show" : "hide")
                                                                }
                                                            >
                                                                    *
                                                                </span>{" "} {this.state.lblLastName}:{" "}
                                                            </label>
                                                        </div>
                                                        <div className={"col-md-8 pull-left nopadding " + (this.state.LastNameHide ? "show" : "hide")}>
                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pLastName
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputplastname"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.LastName}
                                                                onChange={this.handleLNChange}
                                                                maxLength={50}
                                                                readOnly={this.state.lastNameRO ? "readOnly" : ""}
                                                                onBlur={this.handleFNBlur}
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="col-md-6 nopadding">
                                                        <div className={"col-md-4 pull-left nopadding " + (this.state.DOBHide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="dobDate"
                                                            ><span style={{ float: "left" }}
                                                                className={
                                                                    "red " +
                                                                    (this.state.isMandatoryDOB ? "show" : "hide")
                                                                }
                                                            >
                                                                    *
                                                                </span>{" "}
                                                                {this.state.lblDOB}:{" "}
                                                            </label>

                                                        </div>

                                                        <div className={"col-md-8 pull-left nopadding dop-d " + (this.state.DOBHide ? "show" : "hide")}>
                                                            {(this.state.DOB.toString().length > 0 && this.state.MaskDOB && this.state.DOB.toString().toLowerCase() != "invalid date") ?
                                                                (
                                                                    <span>
                                                                        <input className={
                                                                            "form-control " +
                                                                            (this.state.pDOB ? "redborder" : "blackborder")
                                                                        } type='text' value="**********" onClick={this.handleDOBfocusIn} disabled={this.state.dobRO} onFocus={this.handleDOBfocusIn} />

                                                                    </span>
                                                                ) : (
                                                                    <span tabIndex="-1" onBlur={this.handleDOBfocusOut}>
                                                                        <DatePickerInput
                                                                            type={this.state.MaskDOB ? "" : ""}                                                                            
                                                                            value={this.state.DOB}
                                                                            selected={this.state.DOB}
                                                                            onClear={this.handleDOBclear}
                                                                            id="dobDate"
                                                                            onChange={this.handleDOBChange}
                                                                            maxLength="10"
                                                                            onKeyPress={this.onKeyPressValue.bind(this)}
                                                                            autoComplete="off"
                                                                            className={
                                                                                "docs-date form-control " +
                                                                                (this.state.pDOB ? "redborder" : "blackborder")
                                                                            }
                                                                            maxDate={this.state.maxDate}
                                                                            minDate={this.state.minDate}
                                                                            disabled={this.state.dobRO}                                                                            
                                                                            onClick={this.handleDOBfocusIn}
                                                                            onFocus={this.handleDOBfocusIn}                                                                           
                                                                        />

                                                                    </span>
                                                                )}
                                                            <div className={"view-dob " + (this.state.DOBHide ? "show " : "hide ") }>
                                                                <button className={"eye-for-dob " + (this.state.DOBEyeHide ? "hide" : "show")} onMouseLeave={this.handleDOBMouseLeaveLegal} onMouseDown={this.handleDOBEventLegal} onMouseUp={this.handleDOBEventLegal}><i id="eye-dob-legal" className="fa fa-eye-slash" ></i></button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 nopadding">
                                                        <div className={"col-md-4 pull-left nopadding " + (this.state.GenderHide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="inputpgender"
                                                            ><span style={{ float: "left" }}
                                                                className={
                                                                    "red " +
                                                                    (this.state.isMandatoryGender ? "show" : "hide")
                                                                }
                                                            >
                                                                    *
                                                                </span>{" "}

                                                                {this.state.lblGender}:{" "}
                                                            </label>

                                                        </div>
                                                        <div className={"col-md-8 pull-left nopadding " + (this.state.GenderHide ? "show" : "hide")}>
                                                            <div className="form-group">
                                                                <select
                                                                    className={
                                                                        "form-control " +
                                                                        (this.state.pGender
                                                                            ? "redborder"
                                                                            : "blackborder")
                                                                    }
                                                                    value={this.state.Gender}
                                                                    onChange={this.handleGenderChange}
                                                                    disabled={this.state.genderRO ? "disabled" : ""}
                                                                >
                                                                    <option key="-1" value="Select">
                                                                        Select
                                                                    </option>
                                                                    {this.state.GenderList.length > 0 &&
                                                                        this.state.GenderList.map((item, i) => {
                                                                            return (
                                                                                <option key={i + 1} value={item.id}>
                                                                                    {item.desc}
                                                                                </option>
                                                                            );
                                                                        }, this)}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 nopadding">
                                                        <div className={"col-md-4 pull-left nopadding " + (this.state.SSNHide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="inputpSSN"
                                                            ><span style={{ float: "left" }}
                                                                className={
                                                                    "red " +
                                                                    (this.state.isMandatorySSN ? "show" : "hide")
                                                                }
                                                            >
                                                                    *
                                                                </span>{" "}
                                                                {this.state.lblSSN}:{" "}
                                                            </label>
                                                        </div>
                                                        <div className={"col-md-8 pull-left nopadding " + (this.state.SSNHide ? "show" : "hide")}>
                                                            {!this.state.isFromCaseDetails ?
                                                                (
                                                                    <p className="ssnfield">

                                                                        <input

                                                                            className={"form-control " + (this.state.pSSN ? "redborder" : "blackborder")}
                                                                            ref="ssn"
                                                                            id="inputSSN"
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            value={this.state.ShowSSNValue ? this.state.ShowSSNBFocus ? this.state.SSN : this.state.MaskValue : this.state.SSN.length > 0 ? 'xxx-xxx-xxx' : ""}
                                                                            maxLength={11}
                                                                            onChange={this.handleSSNChange}
                                                                            readOnly={this.state.ssnRO ? "readOnly" : ""}
                                                                            onBlur={this.handleSSNfocusOut}
                                                                            onClick={this.handleSSNfocusIn}
                                                                            onFocus={this.handleSSNfocusIn}


                                                                        />

                                                                    </p>

                                                                )
                                                                :
                                                                (
                                                                    <p className="ssnfield">

                                                                        <input

                                                                            className="form-control"
                                                                            ref="ssn"
                                                                            id="inputSSN"
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            value={this.state.ssnRO && this.state.SSN.length > 0 ? this.state.ShowSSNValueFromCaseDetails ? 'xxx-xxx-' + this.state.SSN.substr(this.state.SSN.length - 4) : 'xxx-xxx-xxx' : ""}
                                                                            maxLength={11}
                                                                            onChange={this.handleSSNChange}
                                                                            readOnly={this.state.ssnRO ? "readOnly" : ""}
                                                                            onBlur={this.handleFNBlur}


                                                                        />

                                                                    </p>
                                                                )
                                                            }
                                                        </div>
                                                        {!this.state.isFromCaseDetails ? (
                                                            <div className={"view-ssn " + (this.state.SSNHide ? "show " : "hide ")}>
                                                                <button className={"eye-for-ssn " + (this.state.SSNEyeHide ? "hide" : "show")} onMouseLeave={this.handleSSNMouseLeaveLegal} onMouseDown={this.handleEventLegal} onMouseUp={this.handleEventLegal}><i id="eye-ssn-legal" className="fa fa-eye-slash"></i></button>
                                                            </div>
                                                        ) :
                                                            (
                                                                <div className={"view-ssn " + (this.state.SSNHide ? "show " : "hide ")}>
                                                                    <button className={"eye-for-ssn"} onMouseDown={this.handleEventLegalFromCaseDetails} onMouseLeave={this.handleSSNMouseLeaveLegal} onMouseUp={this.handleEventLegalFromCaseDetails}><i id="eye-ssn-legal" className="fa fa-eye-slash"></i></button>
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div className="col-md-6 nopadding">
                                                        <div className={"col-md-4 pull-left nopadding " + (this.state.PolicyNumHide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="inputPolicyNum"
                                                            ><span style={{ float: "left" }}
                                                                className={
                                                                    "red " +
                                                                    (this.state.isMandatoryPolicyNum ? "show" : "hide")
                                                                }
                                                            >
                                                                    *
                                                                </span>{" "}
                                                                {this.state.lblPolicyNum}:
                                                            </label>
                                                        </div>
                                                        <div className={"col-md-8 pull-left nopadding " + (this.state.PolicyNumHide ? "show" : "hide")}>
                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pPolicyNum
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputPolicyNum"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.PolicyNum}
                                                                maxLength={15}
                                                                onChange={this.handlePNChange}
                                                                readOnly={this.state.policyRO ? "readOnly" : ""}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 nopadding">
                                                        <div className={"col-md-4 pull-left nopadding " + (this.state.OtherNamesHide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="inputpOtherNames"
                                                            ><span style={{ float: "left" }}
                                                                className={
                                                                    "red " +
                                                                    (this.state.isMandatoryOtherNames ? "show" : "hide")
                                                                }
                                                            >
                                                                    *
                                                                </span>{" "}
                                                                {this.state.lblOtherNames}:{" "}
                                                            </label>
                                                        </div>
                                                        <div className={"col-md-8 pull-left nopadding " + (this.state.OtherNamesHide ? "show" : "hide")}>
                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pOtherNames
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputpOtherNames"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.OtherNames}
                                                                maxLength={50}
                                                                onChange={this.handleONChange}
                                                                readOnly={
                                                                    this.state.otherNamesRO ? "readOnly" : ""
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 nopadding">
                                                        <div className={"col-md-3 pull-left nopadding " + (this.state.DateOfIncidentHide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-2"
                                                                htmlFor="doiDate"
                                                            > <span style={{ float: "left" }}
                                                                className={
                                                                    "red " +
                                                                    (this.state.isMandatoryDateOfIncident ? "show" : "hide")
                                                                }
                                                            >
                                                                    *
                                                                </span>{" "}
                                                                {this.state.lblDateOfIncident}:{" "}
                                                            </label>
                                                        </div>
                                                        <div className={"col-md-8 pull-left nopadding dop-d " + (this.state.DateOfIncidentHide ? "show" : "hide")}>
                                                            <DatePickerInput
                                                                pattern="[0-9\/\]*"
                                                                value={this.state.DateOfIncident}
                                                                selected={this.state.DateOfIncident}
                                                                onClear={this.handleDOIclear}
                                                                id="doiDate"
                                                                onChange={this.handleDOIChange}
                                                                maxLength="10"
                                                                onKeyPress={this.onKeyPressValueDateOfIncident.bind(this)}
                                                                autoComplete="off"
                                                                className={
                                                                    "docs-date form-control " +
                                                                    (this.state.pDateOfIncident
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                maxDate={this.state.maxDate}
                                                                minDate={this.state.minDate}
                                                                disabled={this.state.pDateOfIncidentRO}
                                                                onBlur={this.handleFNBlur}
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="clearboth"></div>
                                                    <div className="col-md-12 nopadding">
                                                        <div className={"col-md-2 pull-left nopadding " + (this.state.OtherField1Hide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1 bggraym"
                                                                htmlFor="inputOtherField1"
                                                            > <span style={{ float: "left" }}
                                                                className={
                                                                    "red " +
                                                                    (this.state.isMandatoryOtherField1 ? "show" : "hide")
                                                                }
                                                            >
                                                                    *
                                                                </span>{" "}
                                                                {this.state.lblOtherField1}{" "}
                                                            </label>
                                                        </div>
                                                        <div className={"col-md-4 pull-left nopadding " + (this.state.OtherField1Hide ? "show" : "hide")}>
                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pOtherField1
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputOtherField1"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.OtherField1}
                                                                maxLength={50}
                                                                onChange={this.handleOtherField1}
                                                                readOnly={
                                                                    this.state.pOtherField1RO ? "readOnly" : ""
                                                                }
                                                            />
                                                        </div>
                                                        <div className={"col-md-2 pull-left nopadding " + (this.state.OtherField3Hide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1 bggraym"
                                                                htmlFor="inputOtherField3"
                                                            > <span style={{ float: "left" }}
                                                                className={
                                                                    "red " +
                                                                    (this.state.isMandatoryOtherField3 ? "show" : "hide")
                                                                }
                                                            >
                                                                    *
                                                                </span>{" "}
                                                                {this.state.lblOtherField3}
                                                            </label>
                                                        </div>
                                                        <div className={"col-md-4 pull-left nopadding " + (this.state.OtherField3Hide ? "show" : "hide")}>
                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pOtherField3
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputOtherField3"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.OtherField3}
                                                                maxLength={50}
                                                                onChange={this.handleOtherField3}
                                                                readOnly={
                                                                    this.state.pOtherField3RO ? "readOnly" : ""
                                                                }
                                                            />
                                                        </div>
                                                        <div className={"col-md-2 pull-left nopadding " + (this.state.OtherField2Hide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1 bggraym"
                                                                htmlFor="inputOtherField2"
                                                            > <span style={{ float: "left" }}
                                                                className={
                                                                    "red " +
                                                                    (this.state.isMandatoryOtherField2 ? "show" : "hide")
                                                                }
                                                            >
                                                                    *
                                                                </span>{" "}
                                                                {this.state.lblOtherField2}
                                                            </label>
                                                        </div>
                                                        <div className={"col-md-4 pull-left nopadding " + (this.state.OtherField2Hide ? "show" : "hide")}>
                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pOtherField2
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputOtherField2"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.OtherField2}
                                                                maxLength={50}
                                                                onChange={this.handleOtherField2}
                                                                readOnly={
                                                                    this.state.pOtherField2RO ? "readOnly" : ""
                                                                }
                                                            />
                                                        </div>
                                                        <div className={"col-md-2 pull-left nopadding " + (this.state.OtherField4Hide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1 bggraym"
                                                                htmlFor="inputOtherField4"
                                                            > <span style={{ float: "left" }}
                                                                className={
                                                                    "red " +
                                                                    (this.state.isMandatoryOtherField4 ? "show" : "hide")
                                                                }
                                                            >
                                                                    *
                                                                </span>{" "}
                                                                {this.state.lblOtherField4}
                                                            </label>
                                                        </div>
                                                        <div className={"col-md-4 pull-left nopadding " + (this.state.OtherField4Hide ? "show" : "hide")}>
                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pOtherField4
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputOtherField4"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.OtherField4}
                                                                maxLength={50}
                                                                onChange={this.handleOtherField4}
                                                                readOnly={
                                                                    this.state.pOtherField4RO ? "readOnly" : ""
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card mb-2 placeholder-div">
                                        <div className="card-header posrel">
                                            {this.state.individualContactInfoHeaderLabel}:
                                            <div className="icons-div-mx">
                                                <img src="img/tele-icon.png" />{" "}
                                            </div>
                                        </div>
                                        <div className="card-body mt-2">
                                            <div className="form-row">

                                                <div className="col-md-12 nopadding">

                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.EmailHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mb-1"
                                                            htmlFor="inputpEmail"
                                                        ><span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryEmail ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblEmail}:{" "}
                                                        </label>
                                                    </div>

                                                    <div className={"col-md-7 pull-left nopadding " + (this.state.EmailHide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pEmail
                                                                    ? "redborder"
                                                                    : "blackborder")
                                                            }
                                                            id="inputpEmail"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={this.state.Email}
                                                            maxLength={250}
                                                            onChange={this.handleEmailChange}
                                                            onBlur={this.handleblurEmailChange}
                                                            readOnly={this.state.emailRO ? "readOnly" : ""}
                                                        />
                                                    </div>

                                                </div>


                                                <div className="col-md-12 nopadding mt-1">

                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.PCAddressHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mb-1"
                                                            htmlFor="inputHomeAddress"
                                                            style={{ position: "relative" }}
                                                        ><span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryPCAddress ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblPCAddress}:{" "}

                                                        </label>
                                                    </div>

                                                    <div className={"col-md-10 pull-left nopadding " + (this.state.PCAddressHide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pPCAddress
                                                                    ? "redborder"
                                                                    : "blackborder")
                                                            }
                                                            id="inputHomeAddress"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={this.state.PCAddress}
                                                            maxLength={250}
                                                            onChange={this.handleAddrChange}
                                                            readOnly={this.state.addrRO ? "readOnly" : ""}
                                                        />
                                                    </div>

                                                </div>

                                                <div className="col-md-12 nopadding">

                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.PCCityHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="inputPatientCity"
                                                        ><span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryPCCity ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblPCCity}:
                                                        </label>

                                                    </div>
                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.PCCityHide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pPCCity
                                                                    ? "redborder"
                                                                    : "blackborder")
                                                            }
                                                            id="inputPatientCity"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={this.state.PCCity}
                                                            maxLength={100}
                                                            onChange={this.handleCityChange}
                                                            readOnly={this.state.cityRO ? "readOnly" : ""}
                                                        />
                                                    </div>


                                                    <div className={"col-md-1 pull-left nopadding " + (this.state.StateIdHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="patientSt"
                                                        ><span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryStateId ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblStateId}:{" "}
                                                        </label>

                                                    </div>
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.StateIdHide ? "show" : "hide")}>
                                                        <select
                                                            className={
                                                                "form-control " +
                                                                (this.state.pStateId
                                                                    ? "redborder"
                                                                    : "blackborder")
                                                            }
                                                            id="patientSt"
                                                            onChange={this.handleStChange}
                                                            value={this.state.StateId}
                                                            disabled={this.state.stateRO ? "disabled" : ""}
                                                        >
                                                            <option value="Select">Select</option>
                                                            {this.state.States.length > 0 &&
                                                                this.state.States.map((item, i) => {
                                                                    return (
                                                                        <option key={i + 1} value={item.id}>
                                                                            {item.stateName}
                                                                        </option>
                                                                    );
                                                                }, this)}
                                                        </select>
                                                    </div>



                                                    <div className={"col-md-1 pull-left nopadding " + (this.state.PCZipCodeHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="inputPatientZip"
                                                        ><span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryPCZipCode ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblPCZipCode}:
                                                        </label>

                                                    </div>
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.PCZipCodeHide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pPCZipCode ? "redborder" : "blackborder")
                                                            }
                                                            id="inputPatientZip"
                                                            ref="zipc"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={this.state.PCZipCode}
                                                            maxLength={10}
                                                            onChange={this.handleZipChange}
                                                            readOnly={this.state.zipRO ? "readOnly" : ""}
                                                        />
                                                    </div>

                                                </div>
                                                <div className="col-md-12 nopadding">

                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.Phone1Hide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="inputPatientPhone1"
                                                        ><span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryPhone1 ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblPhone1}:
                                                        </label>

                                                    </div>
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.Phone1Hide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pPhone1
                                                                    ? "redborder"
                                                                    : "blackborder")
                                                            }
                                                            ref="input"
                                                            id="inputPatientPhone1"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={this.state.Phone1}
                                                            maxLength={14}
                                                            onChange={this.handlePhn1Change}
                                                            readOnly={this.state.phn1RO ? "readOnly" : ""}
                                                        />
                                                    </div>


                                                    <div className={"col-md-1 pull-left nopadding " + (this.state.Ext1Hide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="inputPatientEXT"
                                                        ><span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryExt1 ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblExt1}:{" "}
                                                        </label>
                                                    </div>
                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.Ext1Hide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pExt1 ? "redborder" : "blackborder")
                                                            }
                                                            id="inputPatientEXT"
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder="ext"
                                                            value={this.state.Ext1}
                                                            maxLength={5}
                                                            onChange={this.handleExt1Change}
                                                            readOnly={this.state.ext1RO ? "readOnly" : ""}
                                                        />
                                                    </div>



                                                    <div className={"col-md-1 pull-left nopadding " + (this.state.Phone1TypeHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="inputPatientType"
                                                        ><span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryPhone1Type ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblPhone1Type}:
                                                        </label>

                                                    </div>
                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.Phone1TypeHide ? "show" : "hide")}>
                                                        <div className="form-group">
                                                            <select
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pPhone1Type
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                value={this.state.Phone1Type}
                                                                onChange={this.handlePhn1TypeChange}
                                                                disabled={
                                                                    this.state.phn1typeRO ? "disabled" : ""
                                                                }
                                                            >
                                                                <option key="0" value="Select">
                                                                    Select
                                                                </option>
                                                                {this.state.PhoneTypes.length > 0 &&
                                                                    this.state.PhoneTypes.map((item, i) => {
                                                                        return (
                                                                            <option
                                                                                key={item.value}
                                                                                value={item.name}
                                                                            >
                                                                                {item.desc}
                                                                            </option>
                                                                        );
                                                                    }, this)}
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-12 nopadding">

                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.Phone2Hide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="inputPatientPhone2"
                                                        ><span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryPhone2 ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblPhone2}:
                                                        </label>
                                                    </div>
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.Phone2Hide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pPhone2
                                                                    ? "redborder"
                                                                    : "blackborder")
                                                            }
                                                            id="inputPatientPhone2"
                                                            ref="phone2"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={this.state.Phone2}
                                                            maxLength={14}
                                                            onChange={this.handlePhn2Change}
                                                            readOnly={this.state.phn2RO ? "readOnly" : ""}
                                                        />
                                                    </div>


                                                    <div className={"col-md-1 pull-left nopadding " + (this.state.Ext2Hide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="inputPatientEXT1"
                                                        ><span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryExt2 ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblExt2}:{" "}
                                                        </label>
                                                    </div>
                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.Ext2Hide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pExt2
                                                                    ? "redborder"
                                                                    : "blackborder")
                                                            }
                                                            id="inputPatientEXT1"
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder="ext"
                                                            value={this.state.Ext2}
                                                            maxLength={5}
                                                            onChange={this.handleExt2Change}
                                                            readOnly={this.state.ext2RO ? "readOnly" : ""}
                                                        />
                                                    </div>


                                                    <div className={"col-md-1 pull-left nopadding " + (this.state.Phone2TypeHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="inputPatientType1"
                                                        ><span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryPhone2Type ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblPhone2Type}:
                                                        </label>
                                                    </div>
                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.Phone2TypeHide ? "show" : "hide")}>
                                                        <div className="form-group">
                                                            <select
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pPhone2Type
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                value={this.state.Phone2Type}
                                                                onChange={this.handlePhn2TypeChange}
                                                                disabled={
                                                                    this.state.phn2typeRO ? "disabled" : ""
                                                                }
                                                            >
                                                                <option key="0" value="Select">
                                                                    Select
                                                                </option>
                                                                {this.state.PhoneTypes.length > 0 &&
                                                                    this.state.PhoneTypes.map((item, i) => {
                                                                        return (
                                                                            <option
                                                                                key={item.value}
                                                                                value={item.name}
                                                                            >
                                                                                {item.desc}
                                                                            </option>
                                                                        );
                                                                    }, this)}
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-12 nopadding">

                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.Phone3Hide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="inputPatientPhone3"
                                                        ><span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryPhone3 ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblPhone3}:
                                                        </label>
                                                    </div>
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.Phone3Hide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pPhone3
                                                                    ? "redborder"
                                                                    : "blackborder")
                                                            }
                                                            id="inputPatientPhone3"
                                                            ref="phone3"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={this.state.Phone3}
                                                            maxLength={14}
                                                            onChange={this.handlePhn3Change}
                                                            readOnly={this.state.phn3RO ? "readOnly" : ""}
                                                        />
                                                    </div>


                                                    <div className={"col-md-1 pull-left nopadding " + (this.state.Ext3Hide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="inputPatientEXT2"
                                                        ><span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryExt3 ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblExt3}:{" "}
                                                        </label>
                                                    </div>
                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.Ext3Hide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pExt3
                                                                    ? "redborder"
                                                                    : "blackborder")
                                                            }
                                                            id="inputPatientEXT2"
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder="ext"
                                                            value={this.state.Ext3}
                                                            maxLength={5}
                                                            onChange={this.handleExt3Change}
                                                            readOnly={this.state.ext3RO ? "readOnly" : ""}
                                                        />
                                                    </div>


                                                    <div className={"col-md-1 pull-left nopadding " + (this.state.Phone3TypeHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="inputPatientType2"
                                                        ><span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryPhone3Type ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblPhone3Type}:
                                                        </label>
                                                    </div>
                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.Phone3TypeHide ? "show" : "hide")}>
                                                        <div className="form-group">
                                                            <select
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pPhone3Type
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                value={this.state.Phone3Type}
                                                                onChange={this.handlePhn3TypeChange}
                                                                disabled={
                                                                    this.state.phn3typeRO ? "disabled" : ""
                                                                }
                                                            >
                                                                <option key="0" value="Select">
                                                                    Select
                                                                </option>
                                                                {this.state.PhoneTypes.length > 0 &&
                                                                    this.state.PhoneTypes.map((item, i) => {
                                                                        return (
                                                                            <option
                                                                                key={item.value}
                                                                                value={item.name}
                                                                            >
                                                                                {item.desc}
                                                                            </option>
                                                                        );
                                                                    }, this)}
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-12 nopadding">

                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.FaxHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="inputPatientFax"
                                                        ><span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryFax ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblFax}:
                                                        </label>
                                                    </div>
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.FaxHide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pFax ? "redborder" : "blackborder")
                                                            }
                                                            ref="Fax"
                                                            id="inputPatientFax"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={this.state.Fax}
                                                            maxLength={14}
                                                            onChange={this.handleFaxChange}
                                                            readOnly={this.state.faxRO ? "readOnly" : ""}
                                                        />
                                                    </div>


                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.PreferredContactHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-3"
                                                            htmlFor="inputPatientPreferredContact"
                                                        ><span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryPreferredContact ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblPreferredContact}:{" "}
                                                        </label>
                                                    </div>
                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.PreferredContactHide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pPreferredContact
                                                                    ? "redborder"
                                                                    : "blackborder")
                                                            }
                                                            id="inputPatientPreferredContact"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={this.state.PreferredContact}
                                                            maxLength={50}
                                                            onChange={this.handlePCChange}
                                                            readOnly={this.state.pcRO ? "readOnly" : ""}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={
                                        "custom-tabs flex-row " +
                                        (this.state.HideLegalDiv ? "d-flex" : "d-none")
                                    }
                                >
                                    <div
                                        onClick={this.ShowAttorneyInfo}
                                        className={
                                            "tab-item " +
                                            (this.state.ShowAttorneyInfo ? "active" : "")
                                        }
                                    >
                                        Attorney Info
                                    </div>
                                    <div
                                        onClick={this.ShowAdjusterInfo}
                                        className={
                                            "tab-item " +
                                            (this.state.ShowAdjusterInfo ? "active" : "")
                                        }
                                    >
                                        Adjuster Info
                                    </div>
                                </div>
                                <div className="bgwhite">
                                    <div
                                        className={
                                            "card mb-2 placeholder-div " +
                                            (this.state.ShowAttorneyInfo ? "d-flex" : "d-none")
                                        }
                                    >
                                        <div className="card-header posrel">
                                            {this.state.agentInfoHeaderLabel}:
                                            <div className="icons-div-mx">
                                                <img src="img/id-icon.png" />{" "}
                                            </div>
                                        </div>
                                        <div className={"card-body"}>
                                            <div className="col-md-12 nopadding">


                                                <div className={"col-md-2 pull-left nopadding " + (this.state.AgentFNHide ? "show" : "hide")}>
                                                    <label
                                                        className="small mdb-1"
                                                        htmlFor="inputAgentFN"
                                                    ><span style={{ float: "left" }}
                                                        className={
                                                            "red " +
                                                            (this.state.isMandatoryAgentFN ? "show" : "hide")
                                                        }
                                                    >
                                                            *
                                                        </span>{" "}
                                                        {this.state.lblAgentFN}:
                                                    </label>
                                                </div>
                                                <div className={"col-md-4 pull-left nopadding " + (this.state.AgentFNHide ? "show" : "hide")}>
                                                    <input
                                                        className={
                                                            "form-control " +
                                                            (this.state.pAgentFN
                                                                ? "redborder"
                                                                : "blackborder")
                                                        }
                                                        id="inputAgentFN"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={this.state.AgentFN}
                                                        maxLength={50}
                                                        onChange={this.handleAFNChange}
                                                        readOnly={this.state.afnRO ? "readOnly" : ""}
                                                        data-toggle="dropdown"
                                                    />
                                                    {this.renderAgentList()}
                                                </div>



                                                <div className={"col-md-2 pull-left nopadding " + (this.state.AgentLNHide ? "show" : "hide")}>
                                                    <label
                                                        className="small mdb-1"
                                                        htmlFor="inputAgentLN"
                                                    ><span style={{ float: "left" }}
                                                        className={
                                                            "red " +
                                                            (this.state.isMandatoryAgentLN ? "show" : "hide")
                                                        }
                                                    >
                                                            *
                                                        </span>{" "}
                                                        {this.state.lblAgentLN}:
                                                    </label>
                                                </div>
                                                <div className={"col-md-4 pull-left nopadding " + (this.state.AgentLNHide ? "show" : "hide")}>
                                                    <input
                                                        className={
                                                            "form-control " +
                                                            (this.state.pAgentLN
                                                                ? "redborder"
                                                                : "blackborder")
                                                        }
                                                        id="inputAgentLN"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={this.state.AgentLN}
                                                        maxLength={50}
                                                        onChange={this.handleALNChange}
                                                        readOnly={this.state.alnRO ? "readOnly" : ""}
                                                        data-toggle="dropdown"
                                                    />
                                                    {this.renderAgentList()}
                                                </div>

                                            </div>
                                            <div className="col-md-12 nopadding">

                                                <div className={"col-md-2 pull-left nopadding mt-2 " + (this.state.AgentEmailHide ? "show" : "hide")}>
                                                    <label
                                                        className="small mb-1"
                                                        htmlFor="inputpAgentEmail"
                                                    ><span style={{ float: "left" }}
                                                        className={
                                                            "red " +
                                                            (this.state.isMandatoryAgentEmail ? "show" : "hide")
                                                        }
                                                    >
                                                            *
                                                        </span>{" "}
                                                        {this.state.lblAgentEmail}:
                                                    </label>
                                                </div>
                                                <div className={"col-md-7 pull-left nopadding " + (this.state.AgentEmailHide ? "show" : "hide")}>
                                                    <input
                                                        className={
                                                            "form-control " +
                                                            (this.state.pAgentEmail
                                                                ? "redborder"
                                                                : "blackborder")
                                                        }
                                                        id="inputpAgentEmail"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={this.state.AgentEmail}
                                                        maxLength={250}
                                                        onChange={this.handleAEmailChange}
                                                        onBlur={this.handleblurAEmailChange}
                                                        readOnly={this.state.aemailRO ? "readOnly" : ""}
                                                    />
                                                </div>

                                                <div className="clear"> </div>
                                            </div>

                                            <div className="col-md-12 nopadding">

                                                <div className={"col-md-2 pull-left nopadding " + (this.state.AgentPhoneHide ? "show" : "hide")}>

                                                    <label
                                                        className="small mdb-1"
                                                        htmlFor="inputAgentPhone"
                                                    ><span style={{ float: "left" }}
                                                        className={
                                                            "red " +
                                                            (this.state.isMandatoryAgentPhone ? "show" : "hide")
                                                        }
                                                    >
                                                            *
                                                        </span>{" "}
                                                        {this.state.lblAgentPhone}:
                                                    </label>
                                                </div>
                                                <div className={"col-md-5 pull-left nopadding " + (this.state.AgentPhoneHide ? "show" : "hide")}>
                                                    <input
                                                        className={
                                                            "form-control " +
                                                            (this.state.pAgentPhone ? "redborder" : "blackborder")
                                                        }
                                                        ref="APhn"
                                                        id="inputAgentPhone"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={this.state.AgentPhone}
                                                        maxLength={14}
                                                        onChange={this.handleAPhnChange}
                                                        readOnly={this.state.aphnRO ? "readOnly" : ""}
                                                    />
                                                </div>


                                                <div className={"col-md-1 pull-left nopadding " + (this.state.AgentExtHide ? "show" : "hide")}>
                                                    <label
                                                        className="small mdb-1"
                                                        htmlFor="inputAgentExt"
                                                    ><span style={{ float: "left" }}
                                                        className={
                                                            "red " +
                                                            (this.state.isMandatoryAgentExt ? "show" : "hide")
                                                        }
                                                    >
                                                            *
                                                        </span>{" "}
                                                        {this.state.lblAgentExt}:{" "}
                                                    </label>
                                                </div>
                                                <div className={"col-md-4 pull-left nopadding " + (this.state.AgentExtHide ? "show" : "hide")}>
                                                    <input
                                                        className={
                                                            "form-control " +
                                                            (this.state.pAgentExt ? "redborder" : "blackborder")
                                                        }
                                                        id="inputAgentExt"
                                                        placeholder="ext"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={this.state.AgentExt}
                                                        maxLength={5}
                                                        onChange={this.handleAExtChange}
                                                        readOnly={this.state.aextRO ? "readOnly" : ""}
                                                    />
                                                </div>

                                            </div>
                                            <div className="col-md-12 nopadding">

                                                <div className={"col-md-2 pull-left nopadding " + (this.state.AgentMobileHide ? "show" : "hide")}>
                                                    <label
                                                        className="small mdb-1"
                                                        htmlFor="inputpAgentMobile"
                                                    ><span style={{ float: "left" }}
                                                        className={
                                                            "red " +
                                                            (this.state.isMandatoryAgentMobile ? "show" : "hide")
                                                        }
                                                    >
                                                            *
                                                        </span>{" "}
                                                        {this.state.lblAgentMobile}:
                                                    </label>
                                                </div>
                                                <div className={"col-md-4 pull-left nopadding " + (this.state.AgentMobileHide ? "show" : "hide")}>
                                                    <input
                                                        className={
                                                            "form-control " +
                                                            (this.state.pAgentMobile
                                                                ? "redborder"
                                                                : "blackborder")
                                                        }
                                                        ref="Amob"
                                                        id="inputpAgentMobile"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={this.state.AgentMobile}
                                                        maxLength={14}
                                                        onChange={this.handleAMobileChange}
                                                        readOnly={this.state.amobileRO ? "readOnly" : ""}
                                                    />
                                                </div>


                                                <div className={"col-md-2 pull-left nopadding " + (this.state.AgentFaxHide ? "show" : "hide")}>
                                                    <label
                                                        className="small mdb-1"
                                                        htmlFor="inputAgentFax"
                                                    ><span style={{ float: "left" }}
                                                        className={
                                                            "red " +
                                                            (this.state.isMandatoryAgentFax ? "show" : "hide")
                                                        }
                                                    >
                                                            *
                                                        </span>{" "}
                                                        {this.state.lblAgentFax}:{" "}
                                                    </label>
                                                </div>
                                                <div className={"col-md-4 pull-left nopadding " + (this.state.AgentFaxHide ? "show" : "hide")}>
                                                    <input
                                                        className={
                                                            "form-control " +
                                                            (this.state.pAgentFax ? "redborder" : "blackborder")
                                                        }
                                                        ref="Afax"
                                                        id="inputAgentFax"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={this.state.AgentFax}
                                                        maxLength={14}
                                                        onChange={this.handleAFaxChange}
                                                        readOnly={this.state.afaxRO ? "readOnly" : ""}
                                                    />
                                                </div>

                                            </div>
                                            <div>

                                                <div className="col-md-12 nopadding">

                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.AgentIdHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="inputpAgentId"
                                                        ><span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryAgentId ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblAgentId}:
                                                        </label>
                                                    </div>
                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.AgentIdHide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pAgentId
                                                                    ? "redborder"
                                                                    : "blackborder")
                                                            }
                                                            id="inputpAgentId"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={this.state.AgentId}
                                                            maxLength={50}
                                                            onChange={this.handleAIdChange}
                                                            readOnly={this.state.aIdRO ? "readOnly" : ""}
                                                            data-toggle="dropdown"
                                                        />
                                                        {this.renderAgentList()}
                                                    </div>

                                                </div>
                                                <div className="clear"> </div>
                                                <div className={"col-md-12 pull-right " + (this.state.DisAgentSearch ? "hide" : "show")}>
                                                    <ul id="attorneyInfo" className="btn-alignment-x">
                                                        <li>
                                                            <button
                                                                className="btn btn-blue-btnn btn-type"
                                                                onClick={this.onOpenclearAgentpopup}
                                                            >
                                                                {this.state.agentInfoClearButtonLabel}
                                                            </button>{" "}
                                                        </li>
                                                        <li>
                                                            <button
                                                                className="btn btn-blue-btnn btn-type btn-auto-width"
                                                                onClick={this.onClickopenAgentPopup}
                                                            >
                                                                {this.state.agentInfoSearchButtonLabel}
                                                            </button>{" "}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            "card mb-2 placeholder-div " +
                                            (this.state.ShowAdjusterInfo ? "d-flex" : "d-none")
                                        }
                                    >
                                        <div className="card-header posrel">
                                            Adjuster Info
                                            <div className="icons-div-mx">
                                                <img src="img/id-icon.png" />{" "}
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="col-md-12 nopadding">

                                                <div className={"col-md-2 pull-left nopadding " + (this.state.AdjusterFNHide ? "show" : "hide")}>
                                                    <label
                                                        className="small mdb-1"
                                                        htmlFor="adjusterInputFirstName"
                                                    > <span style={{ float: "left" }}
                                                        className={
                                                            "red " +
                                                            (this.state.isMandatoryAdjusterFN ? "show" : "hide")
                                                        }
                                                    >
                                                            *
                                                        </span>{" "}
                                                        {this.state.lblAdjusterFN}:
                                                    </label>
                                                </div>
                                                <div className={"col-md-4 pull-left nopadding " + (this.state.AdjusterFNHide ? "show" : "hide")}>
                                                    <input
                                                        className={
                                                            "form-control " +
                                                            (this.state.pAdjusterFN
                                                                ? "redborder"
                                                                : "blackborder")
                                                        }
                                                        id="adjusterInputFirstName"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={this.state.AdjusterFN}
                                                        maxLength={50}
                                                        onChange={this.handleAdFNChange}
                                                        readOnly={this.state.adfnRO ? "readOnly" : ""}
                                                        data-toggle="dropdown"
                                                    />
                                                    {this.renderAdjusterList()}
                                                </div>
                                                <div className={"col-md-2 pull-left nopadding " + (this.state.AdjusterLNHide ? "show" : "hide")}>
                                                    <label
                                                        className="small mdb-1"
                                                        htmlFor="adjusterInputLastName"
                                                    > <span style={{ float: "left" }}
                                                        className={
                                                            "red " +
                                                            (this.state.isMandatoryAdjusterLN ? "show" : "hide")
                                                        }
                                                    >
                                                            *
                                                        </span>{" "}
                                                        {this.state.lblAdjusterLN}:
                                                    </label>
                                                </div>
                                                <div className={"col-md-4 pull-left nopadding " + (this.state.AdjusterLNHide ? "show" : "hide")}>
                                                    <input
                                                        className={
                                                            "form-control " +
                                                            (this.state.pAdjusterLN
                                                                ? "redborder"
                                                                : "blackborder")
                                                        }
                                                        id="adjusterInputLastName"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={this.state.AdjusterLN}
                                                        maxLength={50}
                                                        onChange={this.handleAdLNChange}
                                                        readOnly={this.state.adlnRO ? "readOnly" : ""}
                                                        data-toggle="dropdown"
                                                    />
                                                    {this.renderAdjusterList()}
                                                </div>
                                            </div>
                                            <div className="col-md-12 nopadding">
                                                <div className={"col-md-2 pull-left nopadding " + (this.state.AdjusterEmailHide ? "show" : "hide")}>
                                                    <label
                                                        className="small mb-1"
                                                        htmlFor="adjusterInputEmailAddress"
                                                    > <span style={{ float: "left" }}
                                                        className={
                                                            "red " +
                                                            (this.state.isMandatoryAdjusterEmail ? "show" : "hide")
                                                        }
                                                    >
                                                            *
                                                        </span>{" "}
                                                        {this.state.lblAdjusterEmail}:
                                                    </label>
                                                </div>
                                                <div className={"col-md-7 pull-left nopadding " + (this.state.AdjusterEmailHide ? "show" : "hide")}>
                                                    <input
                                                        className={
                                                            "form-control " +
                                                            (this.state.pAdjusterEmail
                                                                ? "redborder"
                                                                : "blackborder")
                                                        }
                                                        id="adjusterInputEmailAddress"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={this.state.AdjusterEmail}
                                                        maxLength={250}
                                                        onChange={this.handleAdEmailChange}
                                                        onBlur={this.handleblurAdEmailChange}
                                                        readOnly={this.state.ademailRO ? "readOnly" : ""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="clear"> </div>
                                            <div className="col-md-12 nopadding">
                                                <div className={"col-md-2 pull-left nopadding " + (this.state.AdjusterPhoneHide ? "show" : "hide")}>
                                                    <label
                                                        className="small mdb-1"
                                                        htmlFor="adjusterInputWorkPhone"
                                                    > <span style={{ float: "left" }}
                                                        className={
                                                            "red " +
                                                            (this.state.isMandatoryAdjusterPhone ? "show" : "hide")
                                                        }
                                                    >
                                                            *
                                                        </span>{" "}
                                                        Work Phone:
                                                    </label>
                                                </div>
                                                <div className={"col-md-5 pull-left nopadding " + (this.state.AdjusterPhoneHide ? "show" : "hide")}>
                                                    <input
                                                        className={
                                                            "form-control " +
                                                            (this.state.pAdjusterPhone ? "redborder" : "blackborder")
                                                        }
                                                        ref="AdPhn"
                                                        id="adjusterInputWorkPhone"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={this.state.AdjusterPhone}
                                                        maxLength={14}
                                                        onChange={this.handleAdPhnChange}
                                                        readOnly={this.state.adphnRO ? "readOnly" : ""}
                                                    />
                                                </div>
                                                <div className={"col-md-1 pull-left nopadding " + (this.state.AdjusterExtHide ? "show" : "hide")}>
                                                    <label
                                                        className="small mdb-1"
                                                        htmlFor="adjusterInputExt"
                                                    > <span style={{ float: "left" }}
                                                        className={
                                                            "red " +
                                                            (this.state.isMandatoryAdjusterExt ? "show" : "hide")
                                                        }
                                                    >
                                                            *
                                                        </span>{" "}
                                                        {this.state.lblAdjusterExt}:{" "}
                                                    </label>
                                                </div>
                                                <div className={"col-md-4 pull-left nopadding " + (this.state.AdjusterExtHide ? "show" : "hide")}>
                                                    <input
                                                        className={
                                                            "form-control " +
                                                            (this.state.pAdjusterExt ? "redborder" : "blackborder")
                                                        }
                                                        id="adjusterInputExt"
                                                        placeholder="ext"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={this.state.AdjusterExt}
                                                        maxLength={5}
                                                        onChange={this.handleAdExtChange}
                                                        readOnly={this.state.adextRO ? "readOnly" : ""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12 nopadding">
                                                <div className={"col-md-2 pull-left nopadding " + (this.state.AdjusterMobileHide ? "show" : "hide")}>
                                                    <label
                                                        className="small mdb-1"
                                                        htmlFor="adjusterInputCellPhone"
                                                    > <span style={{ float: "left" }}
                                                        className={
                                                            "red " +
                                                            (this.state.isMandatoryAdjusterMobile ? "show" : "hide")
                                                        }
                                                    >
                                                            *
                                                        </span>{" "}
                                                        {this.state.lblAdjusterMobile}:
                                                    </label>
                                                </div>
                                                <div className={"col-md-4 pull-left nopadding " + (this.state.AdjusterMobileHide ? "show" : "hide")}>
                                                    <input
                                                        className={
                                                            "form-control " +
                                                            (this.state.pAdjusterMobile
                                                                ? "redborder"
                                                                : "blackborder")
                                                        }
                                                        ref="Admob"
                                                        id="adjusterInputCellPhone"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={this.state.AdjusterMobile}
                                                        maxLength={14}
                                                        onChange={this.handleAdMobileChange}
                                                        readOnly={this.state.admobileRO ? "readOnly" : ""}
                                                    />
                                                </div>
                                                <div className={"col-md-2 pull-left nopadding " + (this.state.AdjusterFaxHide ? "show" : "hide")}>
                                                    <label
                                                        className="small mdb-1"
                                                        htmlFor="adjusterInputFax"
                                                    > <span style={{ float: "left" }}
                                                        className={
                                                            "red " +
                                                            (this.state.isMandatoryAdjusterFax ? "show" : "hide")
                                                        }
                                                    >
                                                            *
                                                        </span>{" "}
                                                        {this.state.lblAdjusterFax}:{" "}
                                                    </label>
                                                </div>
                                                <div className={"col-md-4 pull-left nopadding " + (this.state.AdjusterFaxHide ? "show" : "hide")}>
                                                    <input
                                                        className={
                                                            "form-control " +
                                                            (this.state.pAdjusterFax ? "redborder" : "blackborder")
                                                        }
                                                        ref="Adfax"
                                                        id="adjusterInputFax"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={this.state.AdjusterFax}
                                                        maxLength={14}
                                                        onChange={this.handleAdFaxChange}
                                                        readOnly={this.state.adfaxRO ? "readOnly" : ""}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="col-md-12 nopadding">
                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.AdjusterIdHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="inputpAdjusterId"
                                                        > <span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryAdjusterId ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblAdjusterId}:
                                                        </label>
                                                    </div>
                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.AdjusterIdHide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pAdjusterId
                                                                    ? "redborder"
                                                                    : "blackborder")
                                                            }
                                                            id="inputpAdjusterId"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={this.state.AdjusterId}
                                                            maxLength={50}
                                                            onChange={this.handleAdIdChange}
                                                            readOnly={this.state.adIdRO ? "readOnly" : ""}
                                                            data-toggle="dropdown"
                                                        />
                                                        {this.renderAdjusterList()}
                                                    </div>
                                                </div>
                                                <div className="clear"> </div>
                                                <div className={"col-md-6 pull-right " + (this.state.DisAgentSearch ? "hide" : "show")}>
                                                    <ul className="btn-alignment-x">
                                                        <li>
                                                            <button
                                                                className="btn btn-blue-btnn btn-type"
                                                                onClick={this.onOpenclearAdjusterpopup}
                                                            >
                                                                Clear
                                                            </button>{" "}
                                                        </li>
                                                        <li>
                                                            <button
                                                                className="btn btn-blue-btnn btn-type btn-auto-width"
                                                                onClick={this.onClickopenAdjusterPopup}
                                                            >
                                                                Search
                                                            </button>{" "}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-xl-6 px-1">
                                <div className="bgwhite">
                                    <div className="card mb-4 placeholder-div">
                                        <div className="card-header posrel">
                                            {this.state.facilityHeaderLabel}:
                                            <div className="icons-div-mx">
                                                <img src="img/doc-icon.png" />{" "}
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <p className="alert alert-info small p-1 mt-2">
                                                Facility Name or {this.state.facilityAlertLabel} Name is
                                                mandatory to create an order{" "}
                                            </p>
                                            <div className="form-row-xm">
                                                <div className="form-group">

                                                    <div className="row no-gutters">
                                                        <div className={"col-md-3 " + (this.state.FacilityNameHide ? "show" : "hide")}>
                                                            <label
                                                                className="small mb-1"
                                                                htmlFor="inputFacilityName"
                                                                style={{ position: "relative" }}
                                                            >

                                                                <span style={{
                                                                    float: "left",
                                                                    position: "absolute",
                                                                    top: "-4px",
                                                                    left: "-8px",
                                                                }}
                                                                    className={
                                                                        "red " +
                                                                        (this.state.isMandatoryFacilityName ? "show" : "hide")
                                                                    }
                                                                >
                                                                    *
                                                                </span>{" "}

                                                                {this.state.lblFacilityName}:

                                                            </label>
                                                        </div>
                                                        <div className={"col-md-9 facilityName " + (this.state.FacilityNameHide ? "show" : "hide")}>
                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pFacilityName && isFromInboundOrder
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputFacilityName"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.FacilityName}
                                                                maxLength={100}
                                                                onChange={this.handleDFacilityChange}
                                                                readOnly={this.state.dFacNameRO ? "readOnly" : ""}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row no-gutters">
                                                        <div className={"col-md-3 " + (this.state.DoctorFirstNameHide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="inputDoctorFirstName"
                                                                style={{ position: "relative" }}
                                                            >
                                                                <span className={"red " + (this.state.isMandatoryDoctorFirstName ? "show" : "hide")}
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "-4px",
                                                                        left: "-8px",
                                                                    }}
                                                                >
                                                                    *
                                                                </span> {" "}   {this.state.lblDoctorFirstName}:
                                                            </label>
                                                        </div>
                                                        <div className={"col-md-3 " + (this.state.DoctorFirstNameHide ? "show" : "hide")}>
                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pDoctorFirstName
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputDoctorFirstName"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.DoctorFirstName}
                                                                maxLength={50}
                                                                onChange={this.handleDFNChange}
                                                                readOnly={this.state.dFNRO ? "readOnly" : ""}
                                                            />
                                                        </div>
                                                        <div className={"col-md-3 " + (this.state.DoctorLastNameHide ? "show" : "hide")}>
                                                            <label
                                                                className="small mdb-1"
                                                                htmlFor="inputDrLN"
                                                            > <span className={"red " + (this.state.isMandatoryDoctorLastName ? "show" : "hide")}
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "-4px",
                                                                    left: "-8px",
                                                                }}
                                                            >
                                                                    *
                                                                </span> {" "}
                                                                {this.state.lblDoctorLastName}:
                                                            </label>

                                                        </div>
                                                        <div className={"col-md-3 " + (this.state.DoctorLastNameHide ? "show" : "hide")}>
                                                            <input
                                                                className={
                                                                    "form-control " +
                                                                    (this.state.pDoctorLastName
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                id="inputDrLN"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={this.state.DoctorLastName}
                                                                maxLength={50}
                                                                onChange={this.handleDLNChange}
                                                                readOnly={this.state.dLNRO ? "readOnly" : ""}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="form-row-xm mt-2">
                                                <div className="form-group">
                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.DrEmailAddressHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mb-1"
                                                            htmlFor="inputdoaddress"
                                                        >  <span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryDrEmailAddress ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}    {this.state.lblDrEmailAddress}:
                                                        </label>
                                                    </div>
                                                    <div className={"col-md-10 pull-left nopadding " + (this.state.DrEmailAddressHide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pDrEmailAddress
                                                                    ? "redborder"
                                                                    : "blackborder")
                                                            }
                                                            id="inputdoaddress"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={this.state.DrEmailAddress}
                                                            maxLength={250}
                                                            onChange={this.handleDEmailAddressChange}
                                                            onBlur={this.handleblurDEmailAddressChange}
                                                            readOnly={this.state.dEmailRO ? "readOnly" : ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.DrAddressHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mb-1"
                                                            htmlFor="inputdoaddress"
                                                        >  <span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryDrAddress ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}    {this.state.lblDrAddress}:
                                                        </label>
                                                    </div>
                                                    <div className={"col-md-10 pull-left nopadding " + (this.state.DrAddressHide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pDrAddress
                                                                    ? "redborder"
                                                                    : "blackborder")
                                                            }
                                                            id="inputdoaddress"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={this.state.DrAddress}
                                                            maxLength={250}
                                                            onChange={this.handleDAddressChange}
                                                            readOnly={this.state.dAddrRO ? "readOnly" : ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 nopadding">
                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.DoctorCityHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="inputDoctorCity"
                                                        >  <span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryDoctorCity ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblDoctorCity}:
                                                        </label>

                                                    </div>
                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.DoctorCityHide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pDoctorCity
                                                                    ? "redborder"
                                                                    : "blackborder")
                                                            }
                                                            id="inputDoctorCity"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={this.state.DoctorCity}
                                                            maxLength={100}
                                                            onChange={this.handleDCityChange}
                                                            readOnly={this.state.dCityRO ? "readOnly" : ""}
                                                        />
                                                    </div>



                                                    <div className={"col-md-1 pull-left nopadding " + (this.state.DrStateIdHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="doctorSt"
                                                        >  <span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryDrStateId ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblDrStateId}:
                                                        </label>

                                                    </div>
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.DrStateIdHide ? " show" : "hide")}>
                                                        <select
                                                            className={
                                                                "form-control " +
                                                                (this.state.pDrStateId
                                                                    ? "redborder"
                                                                    : "blackborder")
                                                            }
                                                            onChange={this.handleDStateChange}
                                                            id="doctorSt"
                                                            value={this.state.DrStateId}
                                                            disabled={this.state.dStateRO ? "disabled" : ""}
                                                        >
                                                            <option key="0" value="Select">
                                                                Select
                                                            </option>
                                                            {this.state.States.length > 0 &&
                                                                this.state.States.map((item, i) => {
                                                                    return (
                                                                        <option key={i} value={item.id}>
                                                                            {item.stateName}
                                                                        </option>
                                                                    );
                                                                }, this)}
                                                        </select>
                                                    </div>


                                                    <div className={"col-md-1 pull-left nopadding " + (this.state.DrZipCodeHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="inputdozip"
                                                        >  <span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryDrZipCode ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblDrZipCode}:
                                                        </label>

                                                    </div>
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.DrZipCodeHide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pDrZipCode ? "redborder" : "blackborder")
                                                            }
                                                            id="inputdozip"
                                                            type="text"
                                                            autoComplete="off"
                                                            ref="zipd"
                                                            value={this.state.DrZipCode}
                                                            maxLength={10}
                                                            onChange={(e) => this.handleDZipChange(e.target.value, e.target.selectionStart)}
                                                            readOnly={this.state.dZipRO ? "readOnly" : ""}
                                                        />
                                                    </div>

                                                </div>
                                                <div className="col-md-12 nopadding">
                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.PhoneHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="inputdophone"
                                                        >  <span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryPhone ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblPhone}:
                                                        </label>

                                                    </div>
                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.PhoneHide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pPhone
                                                                    ? "redborder"
                                                                    : "blackborder")
                                                            }
                                                            ref="Dphn"
                                                            id="inputdophone"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={this.state.Phone}
                                                            maxLength={14}
                                                            onChange={this.handleDPhnChange}
                                                            readOnly={this.state.dPhnRO ? "readOnly" : ""}
                                                        />
                                                    </div>


                                                    <div className={"col-md-2 pull-left nopadding " + (this.state.DrFaxHide ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="inputDrFax"
                                                        >  <span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryDrFax ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            {this.state.lblDrFax}:
                                                        </label>
                                                    </div>

                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.DrFaxHide ? "show" : "hide")}>
                                                        <input
                                                            className={
                                                                "form-control " +
                                                                (this.state.pDrFax ? "redborder" : "blackborder")
                                                            }
                                                            ref="Dfax"
                                                            id="inputDrFax"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={this.state.DrFax}
                                                            maxLength={14}
                                                            onChange={(e) => this.handleDFaxChange(e.target.value, e.target.selectionStart)}
                                                            readOnly={this.state.dFaxRO ? "readOnly" : ""}
                                                        />
                                                    </div>

                                                </div>
                                                <div className="col-md-12 nopadding">
                                                    <div className={"col-md-4 pull-left nopadding " + (isFromInboundOrder ? "show" : "hide")}>
                                                        <label
                                                            className="small mdb-1"
                                                            htmlFor="inputcpf"
                                                        >
                                                            Customer Provided Facility:
                                                        </label>
                                                    </div>
                                                    <div className={"col-md-8 pull-left nopadding " + (isFromInboundOrder ? "show" : "hide")}>
                                                        <textarea
                                                            className="form-control textareax"
                                                            placeholder="Customer Provided Facility"
                                                            value={this.state.CustomerProvidedFacility}
                                                            readOnly={true}
                                                        >
                                                            {" "}
                                                        </textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-md-12 nopadding " + (this.state.DisFacilitySearch ? "hide" : "show")}>
                                            <ul id="custodianInfo" className="btn-alignment-x">
                                                <li>
                                                    <button
                                                        className="btn btn-blue-btnn btn-type"
                                                        onClick={this.onOpenclearpopup}
                                                    >
                                                        {this.state.facilityClearButtonLabel}{" "}
                                                    </button>{" "}
                                                </li>
                                                <li>
                                                    <button
                                                        className="btn btn-blue-btnn btn-type btn-auto-width"
                                                        onClick={this.onClickopenPopup}
                                                    >
                                                        {this.state.facilitySearchButtonLabel}{" "}
                                                    </button>{" "}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className={"bgwhite " + (this.state.HideLifeDiv ? "show" : "hide")}>
                                    <div className={"card placeholder-div " + (this.state.LifeRushRequestHide ? "show" : "hide")}>
                                        <div className="card-body mtbight">
                                            <div className="form-row">
                                                <div className="col-md-5">
                                                    <h5 className="wedDiv-x">Rush Request:</h5>
                                                    <label className="checkbox-inline borderone">
                                                        <BootstrapSwitchButton
                                                            checked={this.state.RushReq}
                                                            onlabel="On"
                                                            offlabel="Off"
                                                            value={this.state.RushReq}
                                                            onChange={this.handleRushChange}
                                                            disabled={this.state.DisRushReq}
                                                        />
                                                    </label>
                                                </div>

                                                <div className="col-md-12">
                                                    <p className="special-div-x">
                                                        Selecting to Rush a case will incur additional charges on this order due to increased case handling processes.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bgwhite">
                                    <div className="bgwhite">
                                        <div className={"card placeholder-div " + (this.state.HideLifeDiv ? "show" : "hide")}>
                                            <div className="card-header posrel">
                                                Special Instructions
                                                <div className="icons-div-mx">
                                                    <img src="img/spec-icon.png" />{" "}
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <p className="special-div-x">
                                                    Additional special instructions for request
                                                </p>
                                                <div className="form-row width100">
                                                    <div className="col-md-12 nopadding radiomm">
                                                        <div className={"form-group " + (this.state.SI5yearHide ? "show" : "hide")}>
                                                            <input
                                                                type="radio"
                                                                id="last5years"
                                                                className="pull-left"
                                                                name="customedate"
                                                                value="Years_5"
                                                                onChange={this.handleSPTypeChange}
                                                                checked={this.state.SpecialType == "Years_5"}
                                                                disabled={this.state.DisY5SpecialType}
                                                            />
                                                            <label
                                                                className="small leftside"
                                                                htmlFor="last5years"
                                                            >{" "}
                                                                Last 5 Years
                                                            </label>
                                                        </div>
                                                        <div className={"form-group " + (this.state.SI3yearHide ? "show" : "hide")}>
                                                            <input
                                                                type="radio"
                                                                name="customedate"
                                                                className="pull-left"
                                                                id="last3years"
                                                                value="Years_3"
                                                                onChange={this.handleSPTypeChange}
                                                                checked={this.state.SpecialType == "Years_3"}
                                                                disabled={this.state.DisY3SpecialType}
                                                            />
                                                            <label
                                                                className="small leftside"
                                                                htmlFor="last3years"
                                                            >{" "}
                                                                Last 3 Years{" "}
                                                            </label>
                                                        </div>
                                                        <div className={"form-group " + (this.state.SICustomDateRangeHide ? "show" : "hide")}>
                                                            <input
                                                                type="radio"
                                                                name="customedate"
                                                                className="pull-left"
                                                                id="customDate"
                                                                value="CustomDate"
                                                                onChange={this.handleSPTypeChange}
                                                                checked={this.state.SpecialType == "CustomDate"}
                                                                disabled={this.state.DisCustomSpecialType}
                                                            />
                                                            <label
                                                                className="small leftside"
                                                                htmlFor="customDate"
                                                            >{" "}
                                                                Custom Date Range{" "}
                                                            </label>
                                                            <div className="datewidth">
                                                                <div
                                                                    className="input-group "
                                                                    placeholder="mm/dd/yyyy"
                                                                    id="datetimepicker"
                                                                >
                                                                    <label className="small mr-1" htmlFor="sdate">
                                                                        Start Date
                                                                    </label>
                                                                    <DatePickerInput
                                                                        selected={this.state.StartDate}
                                                                        id="sdate"
                                                                        value={this.state.StartDate}
                                                                        maxLength="10"
                                                                        onKeyPress={this.onKeyPressValueStartDate.bind(this)}
                                                                        autoComplete="off"
                                                                        onChange={this.handleSDChange}
                                                                        dateformat="MM/dd/yyyy"
                                                                        className={
                                                                            "form-control " +
                                                                            (this.state.dosd
                                                                                ? "redborder"
                                                                                : "blackborder")
                                                                        }
                                                                        maxDate={this.state.maxDate}
                                                                        minDate={this.state.minDate}
                                                                        onBlurRaw={this.handleSDBlur}
                                                                        disabled={this.state.DisStartDate}
                                                                        
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="datewidth">
                                                                <div
                                                                    className="input-group "
                                                                    placeholder="mm/dd/yyyy"
                                                                    id="datetimepicker"
                                                                >
                                                                    <label className="small mr-1" htmlFor="edate">
                                                                        End Date
                                                                    </label>
                                                                    <DatePickerInput
                                                                        selected={this.state.EndDate}
                                                                        id="edate"
                                                                        onChange={this.handleEDChange}
                                                                        maxLength="10"
                                                                        onKeyPress={this.onKeyPressValueEndDate.bind(this)}
                                                                        autoComplete="off"
                                                                        value={this.state.EndDate}
                                                                        dateformat="MM/dd/yyyy"
                                                                        className={
                                                                            "form-control " +
                                                                            (this.state.doed
                                                                                ? "redborder"
                                                                                : "blackborder")
                                                                        }
                                                                        maxDate={this.state.maxDate}
                                                                        minDate={this.state.minDate}
                                                                        onBlurRaw={this.handleEDBlur}
                                                                        disabled={this.state.DisEndDate}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={"form-group " + (this.state.SICustomRequirementsHide ? "show" : "hide")}>
                                                            <input
                                                                type="radio"
                                                                name="customedate"
                                                                className="pull-left"
                                                                id="customReqiurements"
                                                                value="CustomRequest"
                                                                onChange={this.handleSPTypeChange}
                                                                checked={
                                                                    this.state.SpecialType == "CustomRequest"
                                                                }
                                                                disabled={this.state.DisCustomRequest}
                                                            />
                                                            <label
                                                                className="small leftside"
                                                                htmlFor="customReqiurements"
                                                            > {" "}
                                                                Custom Requirements{" "}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row width100">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <textarea
                                                                className={
                                                                    "form-control textareax " +
                                                                    (this.state.choseRadioxtext
                                                                        ? "redborder"
                                                                        : "blackborder")
                                                                }
                                                                placeholder="Instructions"
                                                                value={this.state.SpecialInstruction}
                                                                maxLength={1000}
                                                                onChange={this.handleSpChange}
                                                            >
                                                                {" "}
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"card placeholder-div " + (this.state.HideLegalDiv ? "show" : "hide")}>
                                        <div className={"card-header posrel " + (this.state.HideLegalDiv ? "show" : "hide")}>
                                            <div className="d-flex justify-content-start align-items-start">
                                                <div style={{ paddingRight: "15px" }}>
                                                    Dates Of Service
                                                    <p className="special-div-x text-capitalize d-none">Dates for requested records</p>
                                                </div>
                                                <div className="radiomm d-flex">

                                                    <div className={"form-group " + (this.state.LegalPDOSALLRECORDSHide ? "show" : "hide")}>
                                                        <input
                                                            type="radio"
                                                            id="allRecords"
                                                            className="pull-left"
                                                            name="datesofservice"
                                                            value="AllRecords"
                                                            onChange={this.handleDatesOfServiceChange}
                                                            checked={this.state.DatesOfService == "AllRecords"}
                                                            disabled={this.state.DisDOSAllRec}
                                                        />
                                                        <label
                                                            className="small leftside"
                                                            htmlFor="allRecords"
                                                        > <span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryLegalPDOSALLRECORDS ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            All records
                                                        </label>
                                                    </div>
                                                    <div className={"form-group " + (this.state.LegalPDOSCustomHide ? "show" : "hide")}>
                                                        <input
                                                            type="radio"
                                                            name="datesofservice"
                                                            className="pull-left"
                                                            id="customdatesofservice"
                                                            value="Custom"
                                                            onChange={this.handleDatesOfServiceChange}
                                                            checked={
                                                                this.state.DatesOfService == "Custom"
                                                            }
                                                            disabled={this.state.DisDOSCus}
                                                        />
                                                        <label
                                                            className="small leftside"
                                                            htmlFor="customdatesofservice"
                                                        > <span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryLegalPDOSCustom ? "show" : "hide")
                                                            }
                                                        >
                                                                *
                                                            </span>{" "}
                                                            Custom:{" "}
                                                        </label>
                                                        <div className="datewidth">
                                                            <div
                                                                className="input-group "
                                                                placeholder="mm/dd/yyyy"
                                                                id="datetimepicker"
                                                            >
                                                                <label className="small mr-1" htmlFor="fdate">
                                                                    From
                                                                </label>
                                                                <DatePickerInput
                                                                    selected={this.state.CustomStartDate}
                                                                    id="fdate"
                                                                    value={this.state.CustomStartDate}
                                                                    maxLength="10"
                                                                    onKeyPress={this.onKeyPressValueStartDate.bind(this)}
                                                                    autoComplete="off"
                                                                    onChange={this.handleCSDChange}
                                                                    dateformat="MM/dd/yyyy"
                                                                    className={
                                                                        "form-control " +
                                                                        (this.state.csd
                                                                            ? "redborder"
                                                                            : "blackborder")
                                                                    }
                                                                    maxDate={this.state.maxDate}
                                                                    minDate={this.state.minDate}
                                                                    onBlurRaw={this.handleCSDBlur}
                                                                    disabled={this.state.DisStartDate}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="datewidth">
                                                            <div
                                                                className="input-group "
                                                                placeholder="mm/dd/yyyy"
                                                                id="datetimepicker"
                                                            >
                                                                <label className="small mr-1" htmlFor="tdate">
                                                                    To
                                                                </label>
                                                                <DatePickerInput
                                                                    selected={this.state.CustomEndDate}
                                                                    id="tdate"
                                                                    onChange={this.handleCEDChange}
                                                                    maxLength="10"
                                                                    onKeyPress={this.onKeyPressValueEndDate.bind(this)}
                                                                    autoComplete="off"
                                                                    value={this.state.CustomEndDate}
                                                                    dateformat="MM/dd/yyyy"
                                                                    className={
                                                                        "form-control " +
                                                                        (this.state.ced
                                                                            ? "redborder"
                                                                            : "blackborder")
                                                                    }
                                                                    maxDate={this.state.maxDate}
                                                                    minDate={this.state.minDate}
                                                                    onBlurRaw={this.handleCEDBlur}
                                                                    disabled={this.state.DisEndDate}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"card placeholder-div " + (this.state.HideLegalDiv ? "show" : "hide")}>
                                        <div className={"card-header posrel " + (this.state.RequestTypeHide ? "show" : "hide")}>
                                            <div className="d-flex justify-content-start align-items-center">
                                                <div style={{ paddingRight: "15px" }}>
                                                    Request Type
                                                    <p className="special-div-x text-capitalize d-none">Multiple option can be selected</p>
                                                </div>
                                                <div className="radiomm d-flex">
                                                    <div className={"form-group " + (this.state.LegalRTMedicalHide ? "show" : "hide")}>
                                                        <input
                                                            type="checkbox"
                                                            id="medicalRequestType"
                                                            className="pull-left"
                                                            name="medical"
                                                            value="Medical"
                                                            onChange={this.handleMedicalRequest}
                                                            checked={this.state.MedicalRequestType == "Medical"}
                                                            disabled={this.state.DisRqTypeMedical}
                                                        />
                                                        <label className="small leftside" htmlFor="medicalRequestType"> <span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryLegalRTMedical ? "show" : "hide")
                                                            }
                                                        >
                                                            *
                                                        </span>{" "}
                                                            Medical
                                                        </label>
                                                    </div>
                                                    <div className={"form-group " + (this.state.LegalRTBillingHide ? "show" : "hide")}>
                                                        <input
                                                            type="checkbox"
                                                            id="billing"
                                                            className="pull-left"
                                                            name="billing"
                                                            value="Billing"
                                                            onChange={this.handleBillingRequest}
                                                            checked={this.state.BillingRequestType == "Billing"}
                                                            disabled={this.state.DisRqTypeBilling}
                                                        />
                                                        <label className="small leftside" htmlFor="billing"> <span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryLegalRTBilling ? "show" : "hide")
                                                            }
                                                        >
                                                            *
                                                        </span>{" "}
                                                            Billing
                                                        </label>
                                                    </div>
                                                    <div className={"form-group " + (this.state.LegalRTFilmsHide ? "show" : "hide")}>
                                                        <input
                                                            type="checkbox"
                                                            id="films"
                                                            className="pull-left"
                                                            name="films"
                                                            value="Films"
                                                            onChange={this.handleFilmsRequest}
                                                            checked={this.state.FilmsRequestType == "Films"}
                                                            disabled={this.state.DisRqTypeFlims}
                                                        />
                                                        <label className="small leftside" htmlFor="films"> <span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryLegalRTFilims ? "show" : "hide")
                                                            }
                                                        >
                                                            *
                                                        </span>{" "}
                                                            Films
                                                        </label>
                                                    </div>
                                                    <div className={"form-group " + (this.state.LegalRTOtherHide ? "show" : "hide")}>
                                                        <input
                                                            type="checkbox"
                                                            id="other"
                                                            className="pull-left"
                                                            name="other"
                                                            value="Other"
                                                            onChange={this.handleOtherRequest}
                                                            checked={this.state.OtherRequestType == "Other"}
                                                            disabled={this.state.DisRqTypeOther}
                                                        />
                                                        <label className="small leftside" htmlFor="other"> <span style={{ float: "left" }}
                                                            className={
                                                                "red " +
                                                                (this.state.isMandatoryLegalRTOther ? "show" : "hide")
                                                            }
                                                        >
                                                            *
                                                        </span>{" "}
                                                            Other
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">

                                            <div className="form-row width100">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bgwhite">
                                    <div className={"card placeholder" + (this.state.UploadAuthorizationHide ? "show" : "hide")}>
                                        <div className="card-header posrel">
                                            Upload Authorization
                                            <div className="icons-div-mx">
                                                <img src="img/upload-icon.png" />{" "}
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        {this.renderDocTable()}

                                                    </div>
                                                </div>
                                                <div className="col-md-7">
                                                    <p className="special-div-x" >
                                                        Orders submitted without an authorization will be delayed until the authorization is received.
                                                    </p>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="file btn btn-lg btn-blue-btnn lineheight custom-file">
                                                        Upload
                                                        <input
                                                            type="file"
                                                            name="file"
                                                            id="customFile1"
                                                            accept=".pdf,.jpg,.jpeg,.tif,.tiff"
                                                            title="Please choose a file"
                                                            onChange={(event) => {
                                                                this.DocChangeHandler(event);
                                                            }}
                                                            onClick={(event) => {
                                                                event.target.value = null;
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="bgwhite">
                                    <div className={"card mb-2 placeholder-div d-flex " + (this.state.CarrierManagerHide ? "show" : "hide")} >
                                        <div className="card-header posrel">
                                            Client Manager:
                                            <div className="icons-div-mx">
                                                <img src="img/id-icon.png" />{" "}
                                            </div>
                                        </div>
                                        <div className="card-body">

                                            <div className={"col-md-2 pull-left nopadding " + (this.state.CMFNHide ? "show" : "hide")}>
                                                <label
                                                    className="small mdb-1"
                                                    htmlFor="CarrierManagerFirstName"

                                                ><span style={{ float: "left" }}
                                                    className={
                                                        "red " +
                                                        (this.state.isMandatoryCMFN ? "show" : "hide")
                                                    }
                                                >
                                                        *
                                                    </span>{" "}
                                                    {this.state.lblCMFN}:

                                                </label>
                                            </div>
                                            <div className={"col-md-4 pull-left nopadding " + (this.state.CMFNHide ? "show" : "hide")}>
                                                <input
                                                    className={
                                                        "form-control " +
                                                        (this.state.pCMFN
                                                            ? "redborder"
                                                            : "blackborder")
                                                    }
                                                    id="CarrierManagerFirstName"
                                                    type="text"
                                                    autoComplete="off"
                                                    maxLength={50}
                                                    value={this.state.CMFN}
                                                    maxLength={50}
                                                    readOnly={this.state.CMFNRo ? "readOnly" : ""}
                                                    onChange={this.handleCMFNChange}
                                                />
                                            </div>

                                            <div className={"col-md-2 pull-left nopadding " + (this.state.CMLNHide ? "show" : "hide")}>
                                                <label
                                                    className="small mdb-1"
                                                    htmlFor="CarrierMangerLastName"
                                                ><span style={{ float: "left" }}
                                                    className={
                                                        "red " +
                                                        (this.state.isMandatoryCMLN ? "show" : "hide")
                                                    }
                                                >
                                                        *
                                                    </span>{" "}
                                                    {this.state.lblCMLN}:
                                                </label>
                                            </div>
                                            <div className={"col-md-4 pull-left nopadding " + (this.state.CMLNHide ? "show" : "hide")}>
                                                <input
                                                    className={
                                                        "form-control " +
                                                        (this.state.pCMLN
                                                            ? "redborder"
                                                            : "blackborder")
                                                    }
                                                    id="CarrierMangerLastName"
                                                    type="text"
                                                    autoComplete="off"
                                                    maxLength={50}
                                                    value={this.state.CMLN}
                                                    maxLength={50}
                                                    readOnly={this.state.CMLNRo ? "readOnly" : ""}
                                                    onChange={this.handleCMLNChange}
                                                />
                                            </div>


                                            <div className="col-md-12 nopadding">
                                                <div className={"col-md-2 pull-left nopadding mt-2 " + (this.state.CMEmailAddressHide ? "show" : "hide")}>
                                                    <label
                                                        className="small mb-1"
                                                        htmlFor="CarrierManagerEmailAddress"
                                                    ><span style={{ float: "left" }}
                                                        className={
                                                            "red " +
                                                            (this.state.isMandatoryCMEmailAddress ? "show" : "hide")
                                                        }
                                                    >
                                                            *
                                                        </span>{" "}
                                                        {this.state.lblCMEmailAddress}:
                                                    </label>
                                                </div>
                                                <div className={"col-md-7 pull-left nopadding " + (this.state.CMEmailAddressHide ? "show" : "hide")}>
                                                    <input
                                                        className={
                                                            "form-control " +
                                                            (this.state.pCMEmailAddress
                                                                ? "redborder"
                                                                : "blackborder")
                                                        }
                                                        id="CarrierManagerEmailAddress"
                                                        type="text"
                                                        autoComplete="off"
                                                        maxLength={250}
                                                        value={this.state.CMEmailAddress}
                                                        maxLength={250}
                                                        readOnly={this.state.CMEmailAddressRo ? "readOnly" : ""}
                                                        onChange={this.handleCMEmailAddressChange}
                                                        onBlur={this.handleblurCMEmailAddressChange}
                                                    />
                                                </div>
                                                <div className="clear"> </div>
                                            </div>


                                            <div className="col-md-12 nopadding">
                                                <div className={"col-md-2 pull-left nopadding " + (this.state.CMWorkPhoneHide ? "show" : "hide")}>
                                                    <label
                                                        className="small mdb-1"
                                                        htmlFor="CarrierManagerWorkPhone"
                                                    ><span style={{ float: "left" }}
                                                        className={
                                                            "red " +
                                                            (this.state.isMandatoryCMWorkPhone ? "show" : "hide")
                                                        }
                                                    >
                                                            *
                                                        </span>{" "}
                                                        {this.state.lblCMWorkPhone}:
                                                    </label>

                                                </div>
                                                <div className={"col-md-4 pull-left nopadding " + (this.state.CMWorkPhoneHide ? "show" : "hide")}>
                                                    <input
                                                        className={
                                                            "form-control " +
                                                            (this.state.pCMWorkPhone
                                                                ? "redborder"
                                                                : "blackborder")
                                                        }
                                                        ref="APhn2"
                                                        id="CarrierManagerWorkPhone"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={this.state.CMWorkPhone}
                                                        maxLength={14}
                                                        readOnly={this.state.CMWorkPhoneRo ? "readOnly" : ""}
                                                        onChange={this.handleCMWorkPhoneChange}
                                                    />
                                                </div>
                                                <div className={"col-md-2 pull-left nopadding " + (this.state.CMExtHide ? "show" : "hide")}>
                                                    <label
                                                        className="small mdb-1"
                                                        htmlFor="CarrierManagerExt"
                                                    ><span style={{ float: "left" }}
                                                        className={
                                                            "red " +
                                                            (this.state.isMandatoryCMExt ? "show" : "hide")
                                                        }
                                                    >
                                                            *
                                                        </span>{" "}
                                                        {this.state.lblCMExt}:
                                                    </label>

                                                </div>
                                                <div className={"col-md-4 pull-left nopadding " + (this.state.CMExtHide ? "show" : "hide")}>
                                                    <input
                                                        className={
                                                            "form-control " +
                                                            (this.state.pCMExt
                                                                ? "redborder"
                                                                : "blackborder")
                                                        }
                                                        ref="CMExt"
                                                        id="CarrierManagerExt"
                                                        type="text"
                                                        placeholder="ext"
                                                        autoComplete="off"
                                                        value={this.state.CMExt}
                                                        readOnly={this.state.CMExtRo ? "readOnly" : ""}
                                                        maxLength={5}
                                                        onChange={this.handleCMExtChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-12 nopadding">
                                                <div className={"col-md-2 pull-left nopadding " + (this.state.CMCellPhoneHide ? "show" : "hide")}>
                                                    <label
                                                        className="small mdb-1"
                                                        htmlFor="CarrierManagerCellPhone"
                                                    ><span style={{ float: "left" }}
                                                        className={
                                                            "red " +
                                                            (this.state.isMandatoryCMCellPhone ? "show" : "hide")
                                                        }
                                                    >
                                                            *
                                                        </span>{" "}
                                                        {this.state.lblCMCellPhone}:
                                                    </label>

                                                </div>
                                                <div className={"col-md-4 pull-left nopadding " + (this.state.CMCellPhoneHide ? "show" : "hide")}>
                                                    <input
                                                        className={
                                                            "form-control " +
                                                            (this.state.pCMCellPhone
                                                                ? "redborder"
                                                                : "blackborder")
                                                        }
                                                        ref="Cmob"
                                                        id="CarrierManagerCellPhone"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={this.state.CMCellPhone}
                                                        maxLength={14}
                                                        readOnly={this.state.CMCellPhoneRo ? "readOnly" : ""}
                                                        onChange={this.handleCMCellPhoneChange}
                                                    />
                                                </div>
                                                <div className={"col-md-2 pull-left nopadding " + (this.state.CMFaxHide ? "show" : "hide")}>
                                                    <label
                                                        className="small mdb-1"
                                                        htmlFor="CarrierManagerFax"
                                                    ><span style={{ float: "left" }}
                                                        className={
                                                            "red " +
                                                            (this.state.isMandatoryCMFax ? "show" : "hide")
                                                        }
                                                    >
                                                            *
                                                        </span>{" "}
                                                        {this.state.lblCMFax}:
                                                    </label>

                                                </div>
                                                <div className={"col-md-4 pull-left nopadding " + (this.state.CMFaxHide ? "show" : "hide")}>
                                                    <input
                                                        className={
                                                            "form-control " +
                                                            (this.state.pCMFax
                                                                ? "redborder"
                                                                : "blackborder")
                                                        }
                                                        ref="CMfax"
                                                        id="CarrierManagerFax"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={this.state.CMFax}
                                                        maxLength={14}
                                                        readOnly={this.state.CMFaxRo ? "readOnly" : ""}
                                                        onChange={this.handleCMFaxChange}
                                                    />
                                                </div>
                                            </div>



                                        </div>
                                        <div className="form-row-xm mt-2">

                                            <div className={"col-md-12 pull-right " + (this.state.DisCarrierSearch ? "hide" : "show")}>
                                                <ul id="attorneyInfo" className="btn-alignment-x">
                                                    <li>
                                                        <button className="btn btn-blue-btnn btn-type btn-auto-width" onClick={this.onOpenclearCMpopup}>
                                                            Clear
                                                        </button>{" "}
                                                    </li>
                                                    <li>
                                                        <button
                                                            className="btn btn-blue-btnn btn-type btn-auto-width" onClick={this.onClickopenCMPopup}>
                                                            Search
                                                        </button>{" "}
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div
                                className={
                                    "col-md-12 border-x-btn  " + (this.state.ShowAddProvider ? "show" : "hide")}>
                                <span className={"addition-div-x " + (this.state.DisAddAnotherFac ? "hide" : "show")}>
                                    {this.state.additionalHeaderLabel}:
                                </span>
                                <button
                                    id="addAnotherCustodian"
                                    className={"btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 " + (this.state.DisAddAnotherFac ? "hide" : "show")}
                                    onClick={this.addDoctor}
                                >
                                    {this.state.additionalButtonLabal}
                                </button>
                            </div>
                            {this.renderDoctorTable()}
                            <div className="col-md-12 border-x-btn mt-3 mb-4">
                                {!this.state.SubmitButtonChange ? (
                                    <div>
                                        <button
                                            className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 btn-size-3"
                                            onClick={this.handleSubmitOrder}
                                        >
                                            Submit Order{" "}
                                        </button>
                                    </div>
                                ) : (
                                    <div>
                                        {this.state.ReOpenButtonHide ? (
                                            <button
                                                className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 btn-size-3"
                                                onClick={this.handleSubmitOrderWithOrderId}
                                            >
                                                Submit Order{" "}
                                            </button>
                                        ) : (
                                            <button
                                                className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 btn-size-3"
                                                onClick={this.handleSubmitOrderReopen}
                                            >
                                                Submit Order{" "}
                                            </button>
                                        )}

                                    </div>
                                )}
                                {!this.state.HideHoldButton ? (
                                    <button id="holdOrder"
                                        className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1 btn-size-3"
                                        onClick={this.handleHoldOrder}
                                    >
                                        Hold Order{" "}
                                    </button>
                                ) : (
                                    ""
                                )}
                                <button
                                    className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1 btn-size-3"
                                    onClick={this.onClickpopupclorder}
                                >
                                    Cancel Order{" "}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="myModal1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">
                                    Are you sure you want to cancel?
                                </h4>
                                <button type="button" className="close" data-dismiss="modal">
                                    &times;
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="col-md-12 mt-2 mb-2">
                                    <div className="col-md-12 pull-left">
                                        <span className="title-dix-pop"> Provider/Facility: </span>{" "}
                                        <span className="subtitle-dix-pop">Kaiser Northern</span>
                                    </div>
                                    <div className="col-md-1 pull-left mt-2 mb-2">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="last5years"
                                                name="customedate"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-11 pull-left mt-2 mb-2">
                                        <div className="form-row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Instructions"
                                                        defaultValue="Please provide the last 5 years of medical records to include, but not limited"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 mt-2 mb-2">
                                    <div className="col-md-12 pull-left">
                                        <span className="title-dix-pop"> Provider/Facility: </span>{" "}
                                        <span className="subtitle-dix-pop"> Dr Smith </span>
                                    </div>
                                    <div className="col-md-1 pull-left mt-2 mb-2">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="last5years"
                                                name="customedate"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-11 pull-left mt-2 mb-2">
                                        <div className="form-row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Instructions"
                                                        defaultValue="Please provide the last 5 years of medical records to include, but not limited"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 ">
                                    Cancel all Orders{" "}
                                </button>
                                <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1">
                                    Cancel Selected Orders{" "}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id="myModal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">
                                    Please verify Provider/Facility Info -
                                </h4>
                                <button type="button" className="close" data-dismiss="modal">
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="col-md-12 mt-2 mb-2">
                                    <span className="title-dix-pop"> Provider/Facility: </span>{" "}
                                    <span className="subtitle-dix-pop"> Dr Smith </span>
                                    <div className="form-row">
                                        <div className="col-md-9">
                                            <div className="form-group">
                                                <textarea
                                                    className="form-control"
                                                    placeholder="Instructions"
                                                    defaultValue="Special Instructions : Please provide the last 5 years
                          of medical records to include, but not limited to,
                          office notes, test results, labs, ekg's,
                          prescriptions, etc."
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <button className="btn btn-blue-btnnm btn-type mt-1 mb-1 ">
                                                Edit{" "}
                                            </button>
                                            <button className="btn btn-blue-btnnm btn-type mt-1 mb-1 mr-1">
                                                Delete{" "}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-2 mb-2">
                                    <span className="title-dix-pop"> Provider/Facility: </span>{" "}
                                    <span className="subtitle-dix-pop">Kaiser Northern</span>
                                    <div className="form-row">
                                        <div className="col-md-9">
                                            <div className="form-group">
                                                <textarea
                                                    className="form-control"
                                                    placeholder="Instructions"
                                                    defaultValue="Special Instructions : Please provide the last 5 years
                          of medical records to include, but not limited to,
                          office notes, test results, labs, ekg's,
                          prescriptions, etc."
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <button className="btn btn-blue-btnnm btn-type mt-1 mb-1 ">
                                                Edit{" "}
                                            </button>
                                            <button className="btn btn-blue-btnnm btn-type mt-1 mb-1 mr-1">
                                                Delete{" "}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 onconfirm">
                                    {" "}
                                    Confirm{" "}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={"modal " + (this.state.isModalopen ? "show" : "hide")}
                    id="myModal3"
                >
                    <span className="alert-bg-div-x"></span>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">
                                    {this.state.facilitySearchModalHeader}
                                </h4>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={this.onClickclosePopup}
                                    data-dismiss="modal"
                                >
                                    &times;
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="col-md-12 mt-2 mb-2">
                                    <div className="col-md-12 pull-left mb-2">
                                        <div className="form-group posrelative-xx fadocsel">
                                            {this.renderSearch()}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button
                                    className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 "
                                    onClick={this.handleFaclitityAdd}
                                >
                                    Add{" "}
                                </button>
                                <button
                                    className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1"
                                    onClick={this.onClickclosePopup}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={"modal " + (this.state.isModalAgentopen ? "show" : "hide")}
                    id="myModal3"
                >
                    <span className="alert-bg-div-x"></span>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Agent Search</h4>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={this.onClickclosePopup}
                                    data-dismiss="modal"
                                >
                                    &times;
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="col-md-12 mt-2 mb-2">
                                    <div className="col-md-12 pull-left mb-2">
                                        <div className="form-group posrelative-xx fadocsel">
                                            <Select
                                                className={
                                                    "hide form-control popAgent " +
                                                    (this.state.dopopAgentname
                                                        ? "redborder"
                                                        : "blackborder")
                                                }
                                                value={this.state.PopAgentId}
                                                options={this.state.AgentOptions}
                                                onChange={this.handlePopAgentNameChange}
                                                placeholder=""
                                                Clearable
                                                Loading
                                            />

                                            {this.renderAgentSearch()}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button
                                    className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 "
                                    onClick={this.handleAgentAdd}
                                >
                                    Add{" "}
                                </button>
                                <button
                                    className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1"
                                    onClick={this.onClickclosePopup}
                                >
                                    Cancel
                                </button>
                            </div>



                        </div>
                    </div>
                </div>

                <div
                    className={"modal " + (this.state.isModalCMtopen ? "show" : "hide")}
                    id="myModal3"
                >
                    <span className="alert-bg-div-x"></span>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Client Manager Search</h4>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={this.onClickclosePopup}
                                    data-dismiss="modal"
                                >
                                    &times;
                                </button>
                            </div>


                            <div className="modal-body">
                                <div className="col-md-12 mt-2 mb-2">
                                    <div className="col-md-12 pull-left mb-2">
                                        <div className="form-group posrelative-xx fadocsel">
                                            <Select
                                                className={
                                                    "hide form-control popCarrierManager " +
                                                    (this.state.dopopCarrierManagername
                                                        ? "redborder"
                                                        : "blackborder")
                                                }
                                                value={this.state.PopCarrierManagerId}
                                                options={this.state.CarrierManagerOptions}
                                                onChange={this.handlePopCarrierManagerNameChange}
                                                placeholder=""
                                                Clearable
                                                Loading
                                            />

                                            {this.renderCarrierManagerSearch()}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button
                                    className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 "
                                    onClick={this.handleCarrierManagerAdd}
                                >
                                    Add{" "}
                                </button>
                                <button
                                    className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1"
                                    onClick={this.onClickclosePopup}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div
                    className={"modal " + (this.state.isModalAdjusteropen ? "show" : "hide")}
                    id="myModal4"
                >
                    <span className="alert-bg-div-x"></span>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Adjuster Search</h4>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={this.onClickclosePopup}
                                    data-dismiss="modal"
                                >
                                    &times;
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="col-md-12 mt-2 mb-2">
                                    <div className="col-md-12 pull-left mb-2">
                                        <div className="form-group posrelative-xx fadocsel">
                                            <Select
                                                className={
                                                    "hide form-control popAdjuster " +
                                                    (this.state.dopopAdjustername
                                                        ? "redborder"
                                                        : "blackborder")
                                                }
                                                value={this.state.PopAdjusterId}
                                                options={this.state.AdjusterOptions}
                                                onChange={this.handlePopAdjusterNameChange}
                                                placeholder=""
                                                Clearable
                                                Loading
                                            />

                                            {this.renderAdjusterSearch()}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button
                                    className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 "
                                    onClick={this.handleAdjusterAdd}
                                >
                                    Add{" "}
                                </button>
                                <button
                                    className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1"
                                    onClick={this.onClickclosePopup}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={"modal " + (this.state.isEditModalopen ? "show" : "hide")}
                    id="editDoc"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content h-96">
                            <div className="modal-header">
                                <h4 className="modal-title">{this.state.DocFileName}</h4>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={this.onClickclosePopup}
                                    data-dismiss="modal"
                                >
                                    &times;
                                </button>
                            </div>

                            <div className="modal-body document-modal">
                                <div className="holds-the-iframe">
                                    <iframe src={this.state.url} scrolling="yes" title={this.state.pdftitle} className="iframewidth">
                                        <p>Your browser does not support iframes.</p>
                                    </iframe>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={
                        "modal " + (this.state.isMedicalModalopen ? "show" : "hide")
                    }
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Medical Request Details</h4>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={this.closeMedicalRequest}
                                    data-dismiss="modal"
                                >
                                    &times;
                                </button>
                            </div>

                            <div className="modal-body p-4">
                                <div className="form-group">
                                    <div className="row align-items-center">
                                        <div className="col-sm-3 offset-sm-0">
                                            <h6 className="font-weight-bold">Rush Request:</h6>
                                        </div>
                                        <div className="col-sm-2">
                                            <Switch
                                                defaultValue={false}
                                                bsSize="mini"
                                                onText="Yes"
                                                offText="No"
                                                value={this.state.MedicalRushRequest}
                                                onChange={(el, state) => this.handleMedicalRushRequestSwitch(el, state)}
                                                name="rushRequest"
                                                disabled={this.state.DisO_RushReq}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="row align-items-center">
                                                <p className="special-div-x">
                                                    Selecting to Rush a case will incur additional charges on this order due to increased case handling processes.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-sm-3 offset-sm-0">
                                            <h6 className="font-weight-bold">Certified Request:</h6>
                                        </div>
                                        <div className="col-sm-4">
                                            <Switch
                                                defaultValue={false}
                                                bsSize="mini"
                                                onText="Yes"
                                                offText="No"
                                                value={this.state.MedicalCertifiedRequest}
                                                onChange={(el, state) => this.handleMedicalCertifiedRequestSwitch(el, state)}
                                                name="certifiedRequest"
                                                disabled={this.state.DisO_CertifiedReq}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-sm-3 offset-sm-0">
                                            <h6 className="font-weight-bold">
                                                Affidavit Request (Notarized):
                                            </h6>
                                        </div>
                                        <div className="col-sm-4">
                                            <Switch
                                                defaultValue={false}
                                                bsSize="mini"
                                                onText="Yes"
                                                offText="No"
                                                value={this.state.MedicalAffidavitRequest}
                                                onChange={(el, state) => this.handleMedicalAffidavitRequestSwitch(el, state)}
                                                name="affidavitRequest"
                                                disabled={this.state.DisO_AffidavitReq}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row  mt-3">
                                    <div className="col-md-4">
                                        <h6 className="font-weight-bold">Special Instructions:</h6>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="form-row width100 placeholder-div">
                                            <div className="col-md-12 nopadding radiomm">
                                                <div className="form-group">
                                                    <input
                                                        type="checkbox"
                                                        id="standard"
                                                        className="pull-left"
                                                        name="standard"
                                                        value="Standard"
                                                        onChange={this.handleMedicalSplInstructStandard}
                                                        checked={this.state.MedicalSplInstruct == "Standard"}
                                                        disabled={this.state.DisO_Standard}
                                                    />
                                                    <label className="small leftside" htmlFor="standard">
                                                        Standard
                                                    </label>
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="checkbox"
                                                        id="custom"
                                                        className="pull-left ml-5"
                                                        name="custom"
                                                        value="Custom"
                                                        onChange={this.handleMedicalSplInstructCustom}
                                                        checked={this.state.MedicalSplInstruct == "Custom"}
                                                        disabled={this.state.DisO_Custom}
                                                    />
                                                    <label className="small leftside" htmlFor="custom">
                                                        Custom
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <label>Instruction for Custodian of Records</label>
                                <textarea
                                    className={
                                        "form-control textareax " +
                                        (this.state.choseRadioxtext ? "redborder" : "blackborder")
                                    }
                                    maxLength={1000}
                                    value={this.state.MedicalCustodianInstruction}
                                    onChange={this.handleMedicalCustodianInstructChange}
                                />
                                <div className="mt-3">
                                    <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1 btn-size-3" onClick={this.addMedicalRequest}>
                                        Add to Orders
                                    </button>
                                    <button className={"btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1 btn-size-3 " + (this.state.DisO_Cancel ? "hide" : "show")} onClick={this.cancelOrRemoveMedicalRequest}>
                                        Cancel/Remove
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={
                        "modal " + (this.state.isBillingModalopen ? "show" : "hide")
                    }
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Billing Request Details</h4>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={this.closeBillingRequest}
                                    data-dismiss="modal"
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body p-4">
                                <div className="form-group">
                                    <div className="row align-items-center">
                                        <div className="col-sm-3 offset-sm-0">
                                            <h6 className="font-weight-bold">Rush Request:</h6>
                                        </div>
                                        <div className="col-sm-2">
                                            <Switch
                                                defaultValue={false}
                                                bsSize="mini"
                                                onText="Yes"
                                                offText="No"
                                                value={this.state.BillingRushRequest}
                                                onChange={(el, state) => this.handleBillingRushRequestSwitch(el, state)}
                                                name="rushRequest"
                                                disabled={this.state.DisO_RushReq}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="row align-items-center">
                                                <p className="special-div-x">
                                                    Selecting to Rush a case will incur additional charges on this order due to increased case handling processes.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-sm-3 offset-sm-0">
                                            <h6 className="font-weight-bold">Certified Request:</h6>
                                        </div>
                                        <div className="col-sm-4">
                                            <Switch
                                                defaultValue={false}
                                                bsSize="mini"
                                                onText="Yes"
                                                offText="No"
                                                value={this.state.BillingCertifiedRequest}
                                                onChange={(el, state) => this.handleBillingCertifiedRequestSwitch(el, state)}
                                                name="certifiedRequest"
                                                disabled={this.state.DisO_CertifiedReq}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-sm-3 offset-sm-0">
                                            <h6 className="font-weight-bold">
                                                Affidavit Request (Notarized):
                                            </h6>
                                        </div>
                                        <div className="col-sm-4">
                                            <Switch
                                                defaultValue={false}
                                                bsSize="mini"
                                                onText="Yes"
                                                offText="No"
                                                value={this.state.BillingAffidavitRequest}
                                                onChange={(el, state) => this.handleBillingAffidavitRequestSwitch(el, state)}
                                                name="affidavitRequest"
                                                disabled={this.state.DisO_AffidavitReq}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row  mt-3">
                                    <div className="col-md-4">
                                        <h6 className="font-weight-bold">Special Instructions:</h6>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="form-row width100 placeholder-div">
                                            <div className="col-md-12 nopadding radiomm">
                                                <div className="form-group">
                                                    <input
                                                        type="checkbox"
                                                        id="standard"
                                                        className="pull-left"
                                                        name="standard"
                                                        value="Standard"
                                                        onChange={this.handleBillingSplInstructStandard}
                                                        checked={this.state.BillingSplInstruct == "Standard"}
                                                        disabled={this.state.DisO_Standard}
                                                    />
                                                    <label className="small leftside" htmlFor="standard">
                                                        Standard
                                                    </label>
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="checkbox"
                                                        id="custom"
                                                        className="pull-left ml-5"
                                                        name="custom"
                                                        value="Custom"
                                                        onChange={this.handleBillingSplInstructCustom}
                                                        checked={this.state.BillingSplInstruct == "Custom"}
                                                        disabled={this.state.DisO_Custom}
                                                    />
                                                    <label className="small leftside" htmlFor="custom">
                                                        Custom
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <label>Instruction for Custodian of Records</label>
                                <textarea
                                    className={
                                        "form-control textareax " +
                                        (this.state.choseRadioxtext ? "redborder" : "blackborder")
                                    }
                                    maxLength={1000}
                                    value={this.state.BillingCustodianInstruction}
                                    onChange={this.handleBillingCustodianInstructChange}
                                />
                                <div className="mt-3">
                                    <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1 btn-size-3" onClick={this.addBillingRequest}>
                                        Add to Orders
                                    </button>
                                    <button className={"btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1 btn-size-3 " + (this.state.DisO_Cancel ? "hide" : "show")} onClick={this.cancelOrRemoveBillingRequest}>
                                        Cancel/Remove
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={"modal " + (this.state.isFilmsModalopen ? "show" : "hide")}
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Films Request Details</h4>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={this.closeFilmsRequest}
                                    data-dismiss="modal"
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body p-4">
                                <div className="form-group">
                                    <div className="row align-items-center">
                                        <div className="col-sm-3 offset-sm-0">
                                            <h6 className="font-weight-bold">Rush Request:</h6>
                                        </div>
                                        <div className="col-sm-2">
                                            <Switch
                                                defaultValue={false}
                                                bsSize="mini"
                                                onText="Yes"
                                                offText="No"
                                                value={this.state.FilmsRushRequest}
                                                onChange={(el, state) => this.handleFilmsRushRequestSwitch(el, state)}
                                                name="rushRequest"
                                                disabled={this.state.DisO_RushReq}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="row align-items-center">
                                                <p className="special-div-x">
                                                    Selecting to Rush a case will incur additional charges on this order due to increased case handling processes.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-sm-3 offset-sm-0">
                                            <h6 className="font-weight-bold">Certified Request:</h6>
                                        </div>
                                        <div className="col-sm-4">
                                            <Switch
                                                defaultValue={false}
                                                bsSize="mini"
                                                onText="Yes"
                                                offText="No"
                                                value={this.state.FilmsCertifiedRequest}
                                                onChange={(el, state) => this.handleFilmsCertifiedRequestSwitch(el, state)}
                                                name="certifiedRequest"
                                                disabled={this.state.DisO_CertifiedReq}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-sm-3 offset-sm-0">
                                            <h6 className="font-weight-bold">
                                                Affidavit Request (Notarized):
                                            </h6>
                                        </div>
                                        <div className="col-sm-4">
                                            <Switch
                                                defaultValue={false}
                                                bsSize="mini"
                                                onText="Yes"
                                                offText="No"
                                                value={this.state.FilmsAffidavitRequest}
                                                onChange={(el, state) => this.handleFilmsAffidavitRequestSwitch(el, state)}
                                                name="affidavitRequest"
                                                disabled={this.state.DisO_AffidavitReq}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row  mt-3">
                                    <div className="col-md-4">
                                        <h6 className="font-weight-bold">Special Instructions:</h6>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="form-row width100 placeholder-div">
                                            <div className="col-md-12 nopadding radiomm">
                                                <div className="form-group">
                                                    <input
                                                        type="checkbox"
                                                        id="standard"
                                                        className="pull-left"
                                                        name="standard"
                                                        value="Standard"
                                                        onChange={this.handleFilmsSplInstructStandard}
                                                        checked={this.state.FilmsSplInstruct == "Standard"}
                                                        disabled={this.state.DisO_Standard}
                                                    />
                                                    <label className="small leftside" htmlFor="standard">
                                                        Standard
                                                    </label>
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="checkbox"
                                                        id="custom"
                                                        className="pull-left ml-5"
                                                        name="custom"
                                                        value="Custom"
                                                        onChange={this.handleFilmsSplInstructCustom}
                                                        checked={this.state.FilmsSplInstruct == "Custom"}
                                                        disabled={this.state.DisO_Custom}
                                                    />
                                                    <label className="small leftside" htmlFor="custom">
                                                        Custom
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <label>Instruction for Custodian of Records</label>
                                <textarea
                                    className={
                                        "form-control textareax " +
                                        (this.state.choseRadioxtext ? "redborder" : "blackborder")
                                    }
                                    maxLength={1000}
                                    value={this.state.FilmsCustodianInstruction}
                                    onChange={this.handleFilmsCustodianInstructChange}
                                />
                                <div className="mt-3">
                                    <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1 btn-size-3" onClick={this.addFilmsRequest}>
                                        Add to Orders
                                    </button>
                                    <button className={"btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1 btn-size-3 " + (this.state.DisO_Cancel ? "hide" : "show")} onClick={this.cancelOrRemoveFilmsRequest}>
                                        Cancel/Remove
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={"modal " + (this.state.isOtherModalopen ? "show" : "hide")}
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Other Request Details</h4>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={this.closeOtherRequest}
                                    data-dismiss="modal"
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body p-4">
                                <div className="form-group">
                                    <div className="row align-items-center">
                                        <div className="col-sm-3 offset-sm-0">
                                            <h6 className="font-weight-bold">Rush Request:</h6>
                                        </div>
                                        <div className="col-sm-2">
                                            <Switch
                                                defaultValue={false}
                                                bsSize="mini"
                                                onText="Yes"
                                                offText="No"
                                                value={this.state.OtherRushRequest}
                                                onChange={(el, state) => this.handleOtherRushRequestSwitch(el, state)}
                                                name="rushRequest"
                                                disabled={this.state.DisO_RushReq}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="row align-items-center">
                                                <p className="special-div-x">
                                                    Selecting to Rush a case will incur additional charges on this order due to increased case handling processes.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-sm-3 offset-sm-0">
                                            <h6 className="font-weight-bold">Certified Request:</h6>
                                        </div>
                                        <div className="col-sm-4">
                                            <Switch
                                                defaultValue={false}
                                                bsSize="mini"
                                                onText="Yes"
                                                offText="No"
                                                value={this.state.OtherCertifiedRequest}
                                                onChange={(el, state) => this.handleOtherCertifiedRequestSwitch(el, state)}
                                                name="certifiedRequest"
                                                disabled={this.state.DisO_CertifiedReq}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-sm-3 offset-sm-0">
                                            <h6 className="font-weight-bold">
                                                Affidavit Request (Notarized):
                                            </h6>
                                        </div>
                                        <div className="col-sm-4">
                                            <Switch
                                                defaultValue={false}
                                                bsSize="mini"
                                                onText="Yes"
                                                offText="No"
                                                value={this.state.OtherAffidavitRequest}
                                                onChange={(el, state) => this.handleOtherAffidavitRequestSwitch(el, state)}
                                                name="affidavitRequest"
                                                disabled={this.state.DisO_AffidavitReq}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row  mt-3">
                                    <div className="col-md-4">
                                        <h6 className="font-weight-bold">Special Instructions:</h6>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="form-row width100 placeholder-div">
                                            <div className="col-md-12 nopadding radiomm">
                                                <div className="form-group">
                                                    <input
                                                        type="checkbox"
                                                        id="standard"
                                                        className="pull-left"
                                                        name="standard"
                                                        value="Standard"
                                                        onChange={this.handleOtherSplInstructStandard}
                                                        checked={this.state.OtherSplInstruct == "Standard"}
                                                        disabled={this.state.DisO_Standard}
                                                    />
                                                    <label className="small leftside" htmlFor="standard">
                                                        Standard
                                                    </label>
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="checkbox"
                                                        id="custom"
                                                        className="pull-left ml-5"
                                                        name="custom"
                                                        value="Custom"
                                                        onChange={this.handleOtherSplInstructCustom}
                                                        checked={this.state.OtherSplInstruct == "Custom"}
                                                        disabled={this.state.DisO_Custom}
                                                    />
                                                    <label className="small leftside" htmlFor="custom">
                                                        Custom
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <label>Instruction for Custodian of Records</label>
                                <textarea
                                    className={
                                        "form-control textareax " +
                                        (this.state.choseRadioxtext ? "redborder" : "blackborder")
                                    }
                                    maxLength={1000}
                                    value={this.state.OtherCustodianInstruction}
                                    onChange={this.handleOtherCustodianInstructChange}
                                />
                                <div className="mt-3">
                                    <button className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1 btn-size-3" onClick={this.addOtherRequest}>
                                        Add to Orders
                                    </button>
                                    <button className={"btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1 btn-size-3 " + (this.state.DisO_Cancel ? "hide" : "show")} onClick={this.cancelOrRemoveOtherRequest}>
                                        Cancel/Remove
                                    </button>
                                </div>

                                <div className={"col-md-4 pull-left nopadding dop-d hide"} >
                                    <input
                                        type="text"                                        
                                        value={this.state.DOB}                                       
                                        id="dobDate"         
                                        disabled={true}
                                        hidden
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
