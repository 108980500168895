import React, { Component } from 'react';
import { Layout } from './Layout';
import jQuery from 'jquery';
import $ from 'jquery';
import MaterialTable from "material-table";
import { NavMenu } from './NavMenu';
import { Link, NavLink } from 'react-router-dom';
import { DatePicker, DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import Api from '../serviceCall';
import axios from 'axios';
import { isNull, isNullOrUndefined } from 'util';
import { TimeoutCounter } from './TimeoutCounter';
import Moment from 'moment';
import ReactTooltip from 'react-tooltip';
export class CaseDetailsSSO extends Component {
    static displayName = CaseDetailsSSO.name;
    constructor(props) {
        super(props);
        this.state = {
            DownloadImages: "",
            loading: true, PFN: "", PLN: "", PEA: "", PON: "", PDOB: "", PGender: "", PSSN: "", PPCM: "", PPhn1: "", PExt1: "", PPhn2: "", PExt2: "", PPhn3: "", PExt3: "", PFax: "", PAddr: "", PCity: "", PState: "", PZip: "",
            AFN: "", ALN: "", AEA: "", AWP: "", AExt: "", ACP: "", AFax: "", AAI: "", AdFN: "", AdLN: "", AdEA: "", AdWP: "", AdExt: "", AdCP: "", AdFax: "", AdAI: "", PolicyNum: "", AccName: "", Orginator: "", CarrierName: "",
            DocList: [], OrderId: "", contactName: "", contactEmail: "", PrevPage: '', BackText: "", UploadDocId: "", OrderFacilityId: "", OrderDiscussionFacilityId: "",
            contactPhone: "", isUploadModalOpen: false, FileName: '', FileType: '', FileString: '', Files: [], Base64: '', isViewModalopen: false, DocFileName: "", PolicyAmount: "",
            POtherField1: "", POtherField2: "", POtherField3: "", POtherField4: "", PDateOfIncident: "", HideLifeDiv: false, HideLegalDiv: false, isDiscussionModalOpen: false,
            OrderDiscussionTopic: "", OrderDiscussionType: "", OrderDiscussionStatus: "", OrderDiscussionComment: "", DateAuthor: "", Selected: "", isFirst: true,
            //added code
            CMId: "",
            CMFN: "",
            CMLN: "",
            CMEmailAddress: "",
            CMWorkPhone: "",
            CMExt: "",
            CMCellPhone: "",
            CMFax: "",

            lblOtherField1: "",
            lblOtherField2: "",
            lblOtherField3: "",
            lblOtherField4: "",
            lblPolicyAmount: "",
            lblPolicyNum: "",
            OtherField1Hide: false,
            OtherField2Hide: false,
            OtherField3Hide: false,
            OtherField4Hide: false,
            PolicyAmountHide: false,
            PolicyNumHide: false,

            lblAgentFN: "",
            lblAgentLN: "",
            lblAgentEmail: "",
            lblAgentPhone: "",
            lblAgentExt: "",
            lblAgentMobile: "",
            lblAgentFax: "",
            lblAgentId: "",
            AgentFNHide: false,
            AgentLNHide: false,
            AgentEmailHide: false,
            AgentPhoneHide: false,
            AgentExtHide: false,
            AgentMobileHide: false,
            AgentFaxHide: false,
            AgentIdHide: false,

            lblAdjusterFN: "",
            lblAdjusterLN: "",
            lblAdjusterEmail: "",
            lblAdjusterPhone: "",
            lblAdjusterMobile: "",
            lblAdjusterExt: "",
            lblAdjusterFax: "",
            lblAdjusterId: "",
            AdjusterFNHide: false,
            AdjusterLNHide: false,
            AdjusterEmailHide: false,
            AdjusterPhoneHide: false,
            AdjusterMobileHide: false,
            AdjusterExtHide: false,
            AdjusterFaxHide: false,
            AdjusterIdHide: false,

            lblCMFN: "",
            lblCMLN: "",
            lblCMEmailAddress: "",
            lblCMWorkPhone: "",
            lblCMCellPhone: "",
            lblCMExt: "",
            lblCMFax: "",
            CMFNHide: false,
            CMLNHide: false,
            CMEmailAddressHide: false,
            CMWorkPhoneHide: false,
            CMCellPhoneHide: false,
            CMExtHide: false,
            CMFaxHide: false,

            lblOtherNames: "",
            lblSSN: "",
            lblFirstName: "",
            lblLastName: "",
            lblEmail: "",
            lblPCAddress: "",
            lblPCCity: "",
            lblPCZipCode: "",
            lblPhone1: "",
            lblExt1: "",
            lblPhone2: "",
            lblExt2: "",
            lblPhone3: "",
            lblExt3: "",
            lblFax: "",
            lblPreferredContact: "",
            lblDOB: "",
            lblGender: "",
            lblStateId: "",
            lblDateOfIncident: "",

            OtherNamesHide: false,
            SSNHide: false,
            FirstNameHide: false,
            LastNameHide: false,
            EmailHide: false,
            PCAddressHide: false,
            PCCityHide: false,
            PCZipCodeHide: false,
            Phone1Hide: false,
            Ext1Hide: false,
            Phone2Hide: false,
            Ext2Hide: false,
            Phone3Hide: false,
            Ext3Hide: false,
            FaxHide: false,
            PreferredContactHide: false,
            DOBHide: false,
            GenderHide: false,
            StateIdHide: false,
            DateOfIncidentHide: false,

            lblDrAddress: "",
            lblDrEmailAddress: "",
            lblDoctorCity: "",
            lblDrStateId: "",
            lblDrZipCode: "",
            lblPhone: "",
            lblFacilityName: "",
            lblDrFax: "",
            DrAddressHide: false,
            DrEmailAddressHide: false,
            DoctorCityHide: false,
            DrStateIdHide: false,
            DrZipCodeHide: false,
            PhoneHide: false,
            FacilityNameHide: false,
            DrFaxHide: false,
            isDownload: false,
            isViewNote: false,
            isViewAuth: false,

            RequiredFieldsList: [],
            Description: [],

            repopupShow: false,
            ReopenHide: false,
            ReopenComents: "",
            IsReopenText: false,
            ReopenOrderId: "",
            AuthDocIndex: 0,
            IsRedacted: false,
            ProductType: "",
            ProductTypeShow: true,
            APSReasonShow: true
        };
        this.handleGoBack = this.handleGoBack.bind(this);
        this.GetRequiredFields_CaseDetails = this.GetRequiredFields_CaseDetails.bind(this);
        this.renderFac = this.renderFac.bind(this);
        this.handleexpandAll = this.handleexpandAll.bind(this);
        this.handlecollapseAll = this.handlecollapseAll.bind(this);
        this.toasterredfun = this.toasterredfun.bind(this);
        this.toasterredfun2 = this.toasterredfun2.bind(this);
        this.getBase64 = this.getBase64.bind(this);
        this.onClickclosePopup = this.onClickclosePopup.bind(this);
        this.handleOrderDiscussionTopicChange = this.handleOrderDiscussionTopicChange.bind(this);
        this.handleOrderDiscussionCommentChange = this.handleOrderDiscussionCommentChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleOrderDiscussionTypeChange = this.handleOrderDiscussionTypeChange.bind(this);
        this.handleOrderDiscussionStatusChange = this.handleOrderDiscussionStatusChange.bind(this);

    }
    onClickclosePopup = (e) => {
        /*this.setState({ isFirst: false });*/
        this.setState({
            isViewModalopen: false, url: "",
            isDiscussionModalOpen: false
        });
    }
    handlecollapseAll() {
        this.setState({ isFirst: false });
        $('.panel-collapse').removeClass('in').removeClass('show');
    }
    handleexpandAll() {
        this.setState({ isFirst: false });
        $('.panel-collapse').addClass('in').addClass('show');
    }
    handleDateChange(e) {
        this.setState({ isFirst: false });
        this.setState({ pdob: false });
        if (e instanceof Date) {
            this.setState({ dateAuthor: e });
        }
        else {
            this.setState({ dateAuthor: e });
        }
    }
    handleOrderDiscussionTopicChange(e) {
        this.setState({ isFirst: false });
        this.setState({ OrderDiscussionTopic: e.target.value });
    }
    handleOrderDiscussionCommentChange(e) {
        this.setState({ isFirst: false });
        this.setState({ OrderDiscussionComment: e.target.value });
    }
    handleOrderDiscussionTypeChange(e) {
        this.setState({ isFirst: false });
        this.setState({ OrderDiscussionType: e.target.value });
    }
    handleOrderDiscussionStatusChange(e) {
        this.setState({ isFirst: false });
        this.setState({ OrderDiscussionStatus: e.target.value });
    }
    handleDOBChange(e) {
        this.setState({ isFirst: false });
        this.setState({ pdob: false });
        if (e instanceof Date) {
            var tdate = e;
            var date = new Date(tdate);
            var minDateYear = this.state.minDate.getFullYear();
            var maxDateYear = this.state.maxDate.getFullYear();
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
            today = mm + '/' + dd + '/' + yyyy;
            var Selecteddd = String(date.getDate()).padStart(2, '0');
            var Selectedmm = String(date.getMonth() + 1).padStart(2, '0');
            var Selectedyyyy = date.getFullYear();
            var SelectedDate = Selectedmm + '/' + Selecteddd + '/' + Selectedyyyy
            if (tdate >= this.state.maxDate && tdate <= this.state.minDate) {
                this.setState({ pdob: true });
                document.getElementById("dobDate").value = "";
                this.setState({ DOB: "" });
            }
            else {
                this.setState({ DOB: e });
            }

        }
        else {
            this.setState({ pdob: true });
            this.setState({ DOB: e });
        }
    }
    handleDOBBlur(e) {
        this.setState({ isFirst: false });
        var dob = document.getElementById("dobDate").value;
        if (this.state.DOB == "Invalid date") {
            this.setState({ pdob: true });
            document.getElementById("dobDate").value = "";
            this.setState({ DOB: "" });
        }
    }
    onKeyPressValue(event) {
        this.setState({ isFirst: false });
        const DOB = (event.target.validity.valid) ? event.target.value : this.setState({ DOB: "" });
        var length = event.target.value.length;
        event.target.value = event.target.value.replace(/^(\d\d)(\d)$/g, '$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2').replace(/[^\d\/]/g, '');
        if (length > 11) {
            event.stopPropagation();
        }
    }
    toasterredfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
                //this.props.history.push('/search');
            }.bind(this), 5000);
    }
    toasterredfun(e) {
        jQuery("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }

    GetRequiredFields_CaseDetails() {
        let currentComponent = this;
        var CompanyId = 1;     
        Api.Post('api/placeorder/GetRequiredFields_CaseDetails', { "CompanyId": CompanyId }, currentComponent).then(function (result) {
            currentComponent.setState({ RequiredFieldsList: result.requiredFields });

            if (currentComponent.state.RequiredFieldsList.length > 0) {
                for (var i = 0; i < currentComponent.state.RequiredFieldsList.length; i++) {
                    currentComponent.setState({
                        [currentComponent.state.RequiredFieldsList[i].className + 'Hide']: currentComponent.state.RequiredFieldsList[i].isActive,
                        // ['isMandatory' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].isRequired,
                    });
                    if (currentComponent.state.RequiredFieldsList[i].customFieldName == "") {
                        currentComponent.setState({
                            ['lbl' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].originalFieldName,
                        });
                    }
                    else {
                        currentComponent.setState({
                            ['lbl' + currentComponent.state.RequiredFieldsList[i].className]: currentComponent.state.RequiredFieldsList[i].customFieldName,
                        });
                    }
                }
            }
        })
    }
    async componentDidMount() {
        //debugger;
        var encryptedRequest = window.location.search.slice(1);
        var urlParams = Api.splitURLParams(encryptedRequest);
        if (Object.keys(urlParams).length < 2) {
            window.location.href = "/LogoutSSO";
        }
        var userId = urlParams['uid'];
        var orderid = urlParams['id'];

        let companyType = localStorage.getItem("CompanyType");
        let companyCode = localStorage.getItem("Companycode");
        let isFromSearchPage = localStorage.getItem("PrevPage");

        let IsFromSearchPage = false;
        if (isFromSearchPage == "validatessouser") {
            IsFromSearchPage = true;
        }
        if (companyType == "LEGAL") {
            this.setState({ HideLegalDiv: true })
        }
        else {
            this.setState({ HideLifeDiv: true })
        }
        if (isNullOrUndefined(companyType) || isNullOrUndefined(companyCode)) {
            window.location.href = "/LogoutSSO";
        }
        let currentComponent = this;
        if (!isNullOrUndefined(orderid) && orderid!="" ) {
            fetch('labelConfigs/CaseDetails_Labels.json').then(function (response) {
                return response.json();
            }).then(function (response) {
                if (!isNullOrUndefined(companyType) && companyType != "") {
                    currentComponent.setState({
                        clientDetailsPolicyNoLabel: response[companyType].Client_Details.Policy_No,
                        agentInfoHeaderLabel: response[companyType].Agent_Info.Header,
                        agentInfoAgentIdLabel: response[companyType].Agent_Info.Agent_Id,
                        individualInfoHeaderLabel: response[companyType].Individual_Info.Header,
                        locationDetailsFacilityNameLabel: response[companyType].Location_Details.Facility_Name,
                    });
                }
            });
            
            await Api.Post('api/placeorder/GetOrderDetails', { "Id": orderid, "IsFromSearchPage": IsFromSearchPage }, currentComponent).then(function (result) {
                
                if (result.statusCode === 107 || result.statusCode === 300) {
                    currentComponent.setState({ loading: false });
                    currentComponent.toasterredfun2("Order not found.");
                }
                else if (result.statusCode === 400)
                {
                    currentComponent.setState({ loading: false });
                    currentComponent.toasterredfun2("You don't have permission to access this operation, please try again later.");
                }
                else if (result.statusCode === 100) {
                    currentComponent.GetRequiredFields_CaseDetails();
                    currentComponent.GetConfigData();
                    currentComponent.setState({
                        PFN: Api.decryptData(result.patient.patientFN), PLN: Api.decryptData(result.patient.patientLN), PEA: Api.decryptData(result.patient.email), PON: Api.decryptData(result.patient.otherName), PDOB: Api.decryptData(result.patient.dob).replace("-", "/").replace("-", "/"),
                        PGender: Api.decryptData(result.patient.genderStr), PSSN: Api.decryptData(result.patient.ssn), PPCM: Api.decryptData(result.patient.preferredContact), PPhn1: Api.decryptData(result.patient.phone1), PExt1: Api.decryptData(result.patient.ext1),
                        PPhn3: Api.decryptData(result.patient.phone3), PExt3: Api.decryptData(result.patient.ext3), PPhn2: Api.decryptData(result.patient.phone2), PExt2: Api.decryptData(result.patient.ext2), PFax: Api.decryptData(result.patient.fax),
                        PAddr: Api.decryptData(result.patient.address), PCity: Api.decryptData(result.patient.city), PState: Api.decryptData(result.patient.state), PZip: Api.decryptData(result.patient.zip), PolicyNum: Api.decryptData(result.patient.policy),
                        PolicyAmount: Api.decryptData(result.patient.policyAmount), POtherField1: Api.decryptData(result.patient.otherField1), POtherField2: Api.decryptData(result.patient.otherField2), POtherField3: Api.decryptData(result.patient.otherField3),
                        POtherField4: Api.decryptData(result.patient.otherField4), PDateOfIncident: Api.decryptData(result.patient.dateOfIncident).replace("-", "/").replace("-", "/"), Orginator: result.createdBy, CarrierName: result.carrierName, AccName: result.accountName,
                        DocList: result.facility, loading: false, Selected: result.selected, isDownload: result.isDownload, isViewNote: result.isViewNote, isViewAuth: result.isViewAuth, ProductType: result.productType ?? ""
                    });

                    if (isNullOrUndefined(result.productType) || result.productType == '') {
                        currentComponent.setState({ ProductTypeShow: false });
                    }
                    if (result.facility) {
                        var redactedOrder = result.facility.find((a) => { return a.doctor.isRedacted == true });
                        if (redactedOrder && redactedOrder.doctor) {
                            currentComponent.setState({ IsRedacted: redactedOrder.doctor.isRedacted });
                        }
                    }

                    if (result.carrierManager) {
                        currentComponent.setState({
                            CMId: result.carrierManager.id,
                            CMFN: result.carrierManager.firstName,
                            CMLN: result.carrierManager.lastName,
                            CMEmailAddress: result.carrierManager.emailAddress,
                            CMWorkPhone: result.carrierManager.workPhone,
                            CMExt: result.carrierManager.ext,
                            CMCellPhone: result.carrierManager.cellPhone,
                            CMFax: result.carrierManager.fax,
                        });
                    }
                } else {
                    currentComponent.setState({ loading: false });
                    currentComponent.toasterredfun2("You don't have permission to access this operation, please try again later.");
                }
            });
        }
        else
        {
            currentComponent.setState({ loading: false });
            currentComponent.toasterredfun2("order is not found");
        }

    }
    GetConfigData()
    {
        let currentComponent = this;
         Api.Get('api/placeorder/GetConfigData', currentComponent).then(function (result) {
            if (result.statusMessage == "Success") {
                currentComponent.setState({ contactName: result.contactName, contactEmail: result.contactEmail, contactPhone: result.contactPhone });
            }
        });
    }
    handleGoBack(e) {
        this.setState({ isFirst: false });
        if (this.state.PrevPage == "search") {
            this.props.history.push('/search');
        }
    }
    getBase64(file, cb) {
        let currentComp = this;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            currentComp.setState({ Base64: reader.result });
            cb(reader.result)
        };
        reader.onerror = function (error) {
        };
    }
    renderFac() {
    }

    render() {
        var landing = 'img/ems-logo.gif';
        var companyType = localStorage.getItem("CompanyType");
        return (
            <Layout>
                <TimeoutCounter />
                <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                <span className={"loading " + (this.state.loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} /> </span>
                <div className='hgtDiv'>
                    <div className="bg-gray">
                        <div className="">
                            <h1 className="mb-1">SSO Case Details</h1>
                            <h3 className="suborder-cla-x mb-0"> Order Details </h3>
                            <div className="bgwhite mt-0">

                                <div className="row no-gutters">
                                    <div className={" " + (companyType == "LEGAL" ? "col-md-3" : "col-md-3")}>
                                        <div className="card mb-1 placeholder-div max-div-xs ">
                                            <div className="card-header mt-0">
                                                Client Details
                                        </div>
                                            <div className="card-body">
                                                <div className="form-row">
                                                    <div className="col-md-12">
                                                        <div className="form-row-xm">
                                                            <div className="form-group">
                                                                <div className="col-md-4 pull-left nopadding">
                                                                    <label className="small mb-1" htmlFor="inputOfficeName">Office Name:</label>
                                                                </div>
                                                                <div className="col-md-8 pull-left nopadding">
                                                                    <input className="form-control" id="inputOfficeName" type="text" autoComplete="off" value={this.state.AccName} onChange={() => { }}/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-row-xm">
                                                            <div className="form-group">
                                                                <div className="col-md-4 pull-left nopadding">
                                                                    <label className="small mb-1" htmlFor="inputOriginator">Originator:</label>
                                                                </div>
                                                                <div className="col-md-8 pull-left nopadding">
                                                                    <input className="form-control" id="inputOriginator" type="text" autoComplete="off" value={this.state.Orginator} onChange={() => { }}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-row-xm">
                                                            <div className="form-group">
                                                                <div className={"col-md-4 pull-left nopadding " + (this.state.PolicyNumHide ? "show" : "hide")}>
                                                                    <label className="small mb-1" htmlFor="inputPolicyNum">{this.state.lblPolicyNum}:</label>
                                                                </div>
                                                                <div className={"col-md-8 pull-left nopadding " + (this.state.PolicyNumHide ? "show" : "hide")}>
                                                                    <input className="form-control" id="inputPolicyNum" type="text" autoComplete="off" value={this.state.PolicyNum} onChange={() => { }}/>
                                                                </div>
                                                                <div className={this.state.HideLifeDiv ? "show" : "hide"}>
                                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.PolicyAmountHide ? "show" : "hide")}>
                                                                        <label className="small mb-1" htmlFor="inputPolicyAmount">{this.state.lblPolicyAmount}:</label>
                                                                    </div>
                                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.PolicyAmountHide ? "show" : "hide")}>
                                                                        <input className="form-control" id="inputPolicyAmount" type="text" autoComplete="off" value={this.state.PolicyAmount} onChange={() => { }}/>
                                                                    </div>
                                                                </div>
                                                                <div className={"col-md-4 pull-left nopadding " + (this.state.ProductTypeShow ? "show" : "hide")}>
                                                                    <label className="small mb-1" htmlFor="inputProductType">Product Type:</label>
                                                                </div>
                                                                <div className={"col-md-8 pull-left nopadding " + (this.state.ProductTypeShow ? "show" : "hide")}>
                                                                    <input className="form-control" id="inputProductType" type="text" autoComplete="off" value={this.state.ProductType} onChange={() => { }} />
                                                                </div>                                                                
                                                                <div className={this.state.HideLegalDiv ? "show" : "hide"}>
                                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.DateOfIncidentHide ? "show" : "hide")}>
                                                                        <label className="small mb-1" htmlFor="inputDOI">{this.state.lblDateOfIncident}</label>
                                                                    </div>
                                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.DateOfIncidentHide ? "show" : "hide")}>
                                                                        <input className="form-control" id="inputDOI" type="text" autoComplete="off" value={this.state.PDateOfIncident} onChange={() => { }}/>
                                                                    </div>
                                                                    <div className="clearboth"></div>
                                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.OtherField1Hide ? "show" : "hide")}>
                                                                        <label className="small mb-1" htmlFor="inputPOtherField1">{this.state.lblOtherField1}:</label>
                                                                    </div>
                                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.OtherField1Hide ? "show" : "hide")}>
                                                                        <input className="form-control" id="inputPOtherField1" type="text" autoComplete="off" value={this.state.POtherField1} onChange={() => { }}/>
                                                                    </div>
                                                                    <div className="clearboth"></div>
                                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.OtherField2Hide ? "show" : "hide")}>
                                                                        <label className="small mb-1" htmlFor="inputPOtherField2">{this.state.lblOtherField2}:</label>
                                                                    </div>
                                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.OtherField2Hide ? "show" : "hide")}>
                                                                        <input className="form-control" id="inputPOtherField2" type="text" autoComplete="off" value={this.state.POtherField2} onChange={() => { }}/>
                                                                    </div>
                                                                    <div className="clearboth"></div>
                                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.OtherField3Hide ? "show" : "hide")}>
                                                                        <label className="small mb-1" htmlFor="inputPOtherField3">{this.state.lblOtherField3}:</label>
                                                                    </div>
                                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.OtherField3Hide ? "show" : "hide")}>
                                                                        <input className="form-control" id="inputPOtherField3" type="text" autoComplete="off" value={this.state.POtherField3} onChange={() => { }}/>
                                                                    </div>
                                                                    <div className="clearboth"></div>
                                                                    <div className={"col-md-4 pull-left nopadding " + (this.state.OtherField4Hide ? "show" : "hide")}>
                                                                        <label className="small mb-1" htmlFor="inputPOtherField4">{this.state.lblOtherField4}:</label>
                                                                    </div>
                                                                    <div className={"col-md-8 pull-left nopadding " + (this.state.OtherField4Hide ? "show" : "hide")}>
                                                                        <input className="form-control" id="inputPOtherField4" type="text" autoComplete="off" value={this.state.POtherField4} onChange={() => { }}/>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={companyType == "LEGAL" ? "col-md-3" : "col-md-3"}>
                                        <div className="card placeholder-div mb-1 max-div-xs">
                                            <div className="card mb-1 max-div-xs placeholder-div">
                                                <div className="card-header mt-0">
                                                    {this.state.individualInfoHeaderLabel}
                                                </div>
                                                <div className="card-body">

                                                    <div className="form-row-xm">
                                                        <div className="form-group">
                                                            <div className={"col-md-4 pull-left nopadding " + (this.state.FirstNameHide ? "show" : "hide")}>
                                                                <label className="small mb-1" htmlFor="inputIndFirstName">{this.state.lblFirstName}: </label>
                                                            </div>
                                                            <div className={"col-md-8 pull-left nopadding " + (this.state.FirstNameHide ? "show" : "hide")}>
                                                                <input className="form-control" id="inputIndFirstName" type="text" autoComplete="off" value={this.state.PFN} onChange={() => { }}/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-row-xm">
                                                        <div className="form-group">
                                                            <div className={"col-md-4 pull-left nopadding " + (this.state.LastNameHide ? "show" : "hide")}>
                                                                <label className="small mb-1" htmlFor="inputIndLastName">{this.state.lblLastName}: </label>
                                                            </div>
                                                            <div className={"col-md-8 pull-left nopadding " + (this.state.LastNameHide ? "show" : "hide")}>
                                                                <input className="form-control" id="inputIndLastName" type="text" autoComplete="off" value={this.state.PLN} onChange={() => { }}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 nopadding">
                                                        <div className={"col-md-4 pull-left nopadding " + (this.state.DOBHide ? "show" : "hide")}>
                                                            <label className="small mdb-1" htmlFor="inputIndDOB">{this.state.lblDOB}:</label>

                                                        </div>
                                                        <div className={"col-md-3 pull-left nopadding " + (this.state.DOBHide ? "show" : "hide")}>
                                                            <input className="form-control" id="inputIndDOB" type="text" autoComplete="off" value={'xx-xx-xxxx'} onChange={() => { }}/>
                                                        </div>

                                                        <div className={"col-md-2 pull-left nopadding " + (this.state.GenderHide ? "show" : "hide")}>
                                                            <label className="small mdb-1" htmlFor="inputIndGender">{this.state.lblGender}: </label>

                                                        </div>
                                                        <div className={"col-md-3 pull-left nopadding " + (this.state.GenderHide ? "show" : "hide")}>
                                                            <input className="form-control" id="inputIndGender" type="text" autoComplete="off" value={this.state.PGender} onChange={() => { }}/>
                                                        </div>


                                                    </div>


                                                    <div className="col-md-12 nopadding">
                                                        <div className={"col-md-4 pull-left nopadding mt-2 " + (this.state.SSNHide ? "show" : "hide")}>
                                                            <label className="small mb-1" htmlFor="inputIndSSN">{this.state.lblSSN}:</label>
                                                        </div>
                                                        <div className={"col-md-8 pull-left nopadding " + (this.state.SSNHide ? "show" : "hide")}>
                                                            <input className="form-control" id="inputIndSSN" type="text" autoComplete="off" value={'xxx-xxx-xxxx'} onChange={() => { }}/>
                                                        </div>

                                                    </div>
                                               </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className={"col-md-3 " + (companyType == "LEGAL" ? "show" : "hide")}>
                                        <div className="card placeholder-div mb-1 max-div-xs">
                                            <div className="card-header mt-0">
                                                Adjuster Info
                                            </div>
                                            <div className="card-body">
                                                <div className="form-row">
                                                    <div className="form-row-xm">
                                                        <div className="form-group">
                                                            <div className={"col-md-3 pull-left nopadding " + (this.state.AdjusterFNHide ? "show" : "hide")}>
                                                                <label className="small mb-1" htmlFor="inputAdjFirstName">{this.state.lblAdjusterFN}:</label>
                                                            </div>
                                                            <div className={"col-md-9 pull-left nopadding " + (this.state.AdjusterFNHide ? "show" : "hide")}>
                                                                <input className="form-control" id="inputAdjFirstName" type="text" autoComplete="off" value={this.state.AdFN} onChange={() => { }}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-row-xm">
                                                        <div className="form-group">
                                                            <div className={"col-md-3 pull-left nopadding " + (this.state.AdjusterLNHide ? "show" : "hide")}>
                                                                <label className="small mb-1" htmlFor="inputAdjLastName">{this.state.lblAdjusterLN}:</label>
                                                            </div>
                                                            <div className={"col-md-9 pull-left nopadding " + (this.state.AdjusterLNHide ? "show" : "hide")}>
                                                                <input className="form-control" id="inputAdjLastName" type="text" autoComplete="off" value={this.state.AdLN} onChange={() => { }}/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-row-xm">
                                                        <div className="form-group">
                                                            <div className={"col-md-3 pull-left nopadding " + (this.state.AdjusterEmailHide ? "show" : "hide")}>
                                                                <label className="small mb-1" htmlFor="inputAdjEmail">{this.state.lblAdjusterEmail}:</label>
                                                            </div>
                                                            <div className={"col-md-9 pull-left nopadding " + (this.state.AdjusterEmailHide ? "show" : "hide")}>
                                                                <input className="form-control" id="inputAdjEmail" type="text" autoComplete="off" value={this.state.AdEA} onChange={() => { }}/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-row-xm">
                                                        <div className="form-group">
                                                            <div className={"col-md-3 pull-left nopadding " + (this.state.AdjusterPhoneHide ? "show" : "hide")}>
                                                                <label className="small mdb-1" htmlFor="inputAdjPhone">{this.state.lblAdjusterPhone}:</label>

                                                            </div>
                                                            <div className={"col-md-6 pull-left nopadding " + (this.state.AdjusterPhoneHide ? "show" : "hide")}>
                                                                <input className="form-control" id="inputAdjPhone" type="text" autoComplete="off" value={this.state.AdWP} onChange={() => { }}/>
                                                            </div>
                                                            <div className={"col-md-1 pull-left nopadding " + (this.state.AdjusterExtHide ? "show" : "hide")}>
                                                                <label className="small mdb-1" htmlFor="inputAdjExt">{this.state.lblAdjusterExt}: </label>

                                                            </div>
                                                            <div className={"col-md-2 pull-left nopadding " + (this.state.AdjusterExtHide ? "show" : "hide")}>
                                                                <input className="form-control" id="inputAdjExt" type="text" autoComplete="off" value={this.state.AdExt} onChange={() => { }}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 nopadding">
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.AdjusterMobileHide ? "show" : "hide")}>
                                                        <label className="small mdb-1" htmlFor="inputAdjMobile">{this.state.lblAdjusterMobile}:</label>

                                                    </div>
                                                    <div className={"col-md-9 pull-left nopadding " + (this.state.AdjusterMobileHide ? "show" : "hide")}>
                                                        <input className="form-control" id="inputAdjMobile" type="text" autoComplete="off" value={this.state.AdCP} onChange={() => { }}/>
                                                    </div>

                                                </div>

                                                <div className="col-md-12 nopadding">
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.AdjusterFaxHide ? "show" : "hide")}>
                                                        <label className="small mdb-1" htmlFor="inputAdjFax">{this.state.lblAdjusterFax}:</label>

                                                    </div>
                                                    <div className={"col-md-9 pull-left nopadding " + (this.state.AdjusterFaxHide ? "show" : "hide")}>
                                                        <input className="form-control" id="inputAdjFax" type="text" autoComplete="off" value={this.state.AdFax} onChange={() => { }}/>
                                                    </div>

                                                </div>

                                                <div className="col-md-12 nopadding">
                                                    <div className={"col-md-3 pull-left nopadding " + (this.state.AdjusterIdHide ? "show" : "hide")}>
                                                        <label className="small mdb-1" htmlFor="inputAdjId">{this.state.lblAdjusterId}:</label>

                                                    </div>
                                                    <div className={"col-md-9 pull-left nopadding " + (this.state.AdjusterIdHide ? "show" : "hide")}>
                                                        <input className="form-control" id="inputAdjId" type="text" autoComplete="off" value={this.state.AdAI} onChange={() => { }}/>
                                                    </div>

                                                </div>




                                            </div>
                                        </div>

                                    </div>
                                    <div className={companyType == "LEGAL" ? "col-md-3" : "col-md-3"}>
                                        <div className="card mb-1 placeholder-div max-div-xs">
                                            <div className="card-header mt-0">

                                                Customer Service Contact

                                        </div>
                                            <div className="card-body">

                                                <div className="form-row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="small mdb-1" htmlFor="inputContactName">Name:</label>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <input className="form-control" type="text" id="inputContactName" autoComplete="off" value={this.state.contactName} onChange={() => { }}/>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="small mdb-1" htmlFor="inputContactEmail">Email Address:</label>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <input className="form-control" type="text" id="inputContactEmail" autoComplete="off" value={this.state.contactEmail} onChange={() => { }}/>
                                                    </div>

                                                </div>

                                                <div className="form-row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="small mdb-1" htmlFor="inputWorkPhone">Work Phone:</label>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <input className="form-control" type="text" id="inputWorkPhone" autoComplete="off" value={this.state.contactPhone} onChange={() => { }}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h3 className="suborder-cla-x"> Custodian/Facility Details </h3>
                            <div className="row">
                                <div className="col-md-12 mt-1">

                                    <button className="btn btn-blue-btnnm btn-type pull-left mt-1 expandAll mr-4" onClick={this.handleexpandAll}>Expand All  </button>
                                    <button className="btn btn-blue-btnnm btn-type pull-left mt-1 collAll mb-1 mr-1" onClick={this.handlecollapseAll}>Collapse All</button>
                                    <ReactTooltip id='tooltipOrder' />
                                </div>
                            </div>
                            {this.state.DocList.map((doc, index) => {
                                var name = "", facilityName = "", facname = "", docname = "", isfac = false, isdoc = false, expand = false, isCompleted = false;
                                if (doc.doctor.drFacilityName != null && doc.doctor.drFacilityName.length != 0) {
                                    facname = doc.doctor.drFacilityName;
                                    isfac = true;
                                }
                                if (doc.doctor.drFirstName != null && doc.doctor.drFirstName.length != 0) {
                                    docname = "Dr " + doc.doctor.drFirstName + " " + doc.doctor.drLastName;
                                    isdoc = true;
                                }
                                if (isfac && isdoc) {
                                    name = facname + " - " + docname;
                                }
                                else if (!isfac && isdoc) {
                                    name = docname;
                                }
                                else if (isfac && !isdoc) {
                                    name = facname;
                                }
                                if (this.state.isFirst && doc.doctor.mapId == this.state.Selected) {
                                    expand = true;
                                }
                                if (doc.doctor.status == "Completed" || doc.doctor.status == "Cancelled" || doc.doctor.status == "Cancel") {
                                    isCompleted = true;
                                }
                                if (companyType == "LEGAL" && doc.doctor.facilityRequests && doc.doctor.facilityRequests.length > 0 && doc.doctor.facilityRequests[0].requestType) {
                                    facilityName = name + " - " + doc.doctor.facilityRequests[0].requestType.toUpperCase();
                                } else {
                                    facilityName = name;
                                }
                                var SPIn = "";
                                if (companyType == "LEGAL") {
                                    SPIn = doc.doctor.facilityRequests ? doc.doctor.facilityRequests[0].specialInstruction : "";
                                } else {
                                    SPIn = doc.doctor.specialInstruction;
                                }
                                var _lastReopenDateTime = "";
                                if (!isNullOrUndefined(doc.lastReopenDateTime)) {
                                    _lastReopenDateTime = doc.lastReopenDateTime.replace("-", "/").replace("-", "/");
                                }

                                return (
                                    <div className="row">
                                        <div className="col-md-12 accordLevel">
                                            <div className="panel-group" id="accordion">
                                                <div className="panel panel-default">
                                                    <div className="panel-heading d-flex justify-content-between">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#accordion" href={"#collapse" + index}>{facilityName}</a>
                                                        </h4>
                                                    </div>
                                                    <div id={"collapse" + index} className={"panel-collapse collapse in " + (expand ? "show" : "")}>
                                                        <div className="panel-body d-block">
                                                            <div className="row">
                                                                <div className="col-md-7">
                                                                    <div className="bgwhite">
                                                                        <div className="card placeholder-div max-div-xs mb-1">
                                                                            <div className="card-header">
                                                                                Location Details
                                        </div>
                                                                            <div className="card-body">
                                                                                <div className="form-row-xm">
                                                                                    <div className="form-group">
                                                                                        {/*<div className="col-md-3">*/}
                                                                                        {/*<div className="form-group">*/}
                                                                                        <div className="col-md-3 pull-left nopadding">
                                                                                            <label className="small mdb-1" htmlFor="inputReceivedDTTime">Received Date/Time :</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-9">
                                                                                        <div className="form-group mat-div-xd">
                                                                                            <span> {doc.doctor.orderDate.replace("-", "/").replace("-", "/")}  </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={"form-row-xm " + (!isNullOrUndefined(doc.lastReopenDateTime) ? "show" : "hide")}>
                                                                                    <div className="form-group">
                                                                                        <div className="col-md-3 pull-left nopadding">
                                                                                            <label className="small mdb-1" htmlFor="inputReceivedDTTime">LastReopenDate/Time :</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-9">
                                                                                        <div className="form-group mat-div-xd">
                                                                                            <span> {_lastReopenDateTime} </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-row-xm">
                                                                                    <div className="form-group">
                                                                                        <div className="col-md-3 pull-left nopadding">
                                                                                            <label className="small mb-1" htmlFor="inputeNoahOrderId">eNoah Order #</label>
                                                                                        </div>
                                                                                        <div className="col-md-9 pull-left nopadding">
                                                                                            <input className="form-control" id="inputeNoahOrderId" type="text" autoComplete="off" value={doc.doctor.eNoahOrderId} onChange={() => { }}/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={"form-row-xm " + (this.state.HideLegalDiv ? "show" : "hide")}>
                                                                                    <div className="form-group">
                                                                                        <div className="col-md-3 pull-left nopadding">
                                                                                            <label className="small mb-1" htmlFor="inputReqType">Request Type: </label>
                                                                                        </div>
                                                                                        <div className="col-md-9 pull-left nopadding">
                                                                                            <input className="form-control" id="inputReqType" type="text" autoComplete="off" value={doc.doctor.facilityRequests && doc.doctor.facilityRequests.length > 0 ? doc.doctor.facilityRequests[0].requestType : ""} onChange={() => { }}/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={"form-row-xm " + (this.state.HideLegalDiv ? "show" : "hide")}>
                                                                                    <div className="form-group">
                                                                                        <div className="col-md-3 pull-left nopadding">
                                                                                            <label className="small mb-1" htmlFor="inputOrderType">Order Type: </label>
                                                                                        </div>
                                                                                        <div className="col-md-9 pull-left nopadding">
                                                                                            <input className="form-control" id="inputOrderType" type="text" autoComplete="off" value={doc.doctor.facilityRequests && doc.doctor.facilityRequests.length > 0 ? (doc.doctor.facilityRequests[0].rushRequest ? "Rush" : "Standard") : ""} onChange={() => { }}/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={"form-row-xm " + (this.state.HideLegalDiv ? "show" : "hide")}>
                                                                                    <div className="form-group">
                                                                                        <div className="col-md-3 pull-left nopadding">
                                                                                            <label className="small mb-1" htmlFor="inputCR">Certified Request: </label>
                                                                                        </div>
                                                                                        <div className="col-md-9 pull-left nopadding">
                                                                                            <input className="form-control" id="inputCR" type="text" autoComplete="off" value={doc.doctor.facilityRequests && doc.doctor.facilityRequests.length > 0 ? (doc.doctor.facilityRequests[0].certifiedRequest ? "Yes" : "No") : ""} onChange={() => { }}/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={"form-row-xm " + (this.state.HideLegalDiv ? "show" : "hide")}>
                                                                                    <div className="form-group">
                                                                                        <div className="col-md-3 pull-left nopadding">
                                                                                            <label className="small mb-1" htmlFor="inputAR">Affidavit Request: </label>
                                                                                        </div>
                                                                                        <div className="col-md-9 pull-left nopadding">
                                                                                            <input className="form-control" id="inputAR" type="text" autoComplete="off" value={doc.doctor.facilityRequests && doc.doctor.facilityRequests.length > 0 ? (doc.doctor.facilityRequests[0].affidavitRequest ? "Yes" : "No") : ""} onChange={() => { }}/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-row-xm">
                                                                                    <div className="form-group">
                                                                                        <div className={"col-md-3 pull-left nopadding " + (this.state.FacilityNameHide ? "show" : "hide")}>
                                                                                            <label className="small mb-1" htmlFor="inputFacName">{this.state.lblFacilityName}: </label>
                                                                                        </div>
                                                                                        <div className={"col-md-9 pull-left nopadding " + (this.state.FacilityNameHide ? "show" : "hide")}>
                                                                                            <input className="form-control" id="inputFacName" type="text" autoComplete="off" value={name} onChange={() => { }}/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                        <div className="form-row-xm mt-20">
                                                                                    <div className="form-group">
                                                                                        <div className={"col-md-3 pull-left nopadding"}>
                                                                                            <label className="small mb-1" htmlFor="inputCPF">Customer Provided Facility :</label>
                                                                                        </div>
                                                                                        <div className={"col-md-8 pull-left nopadding"}>
                                                                                            <textarea id="inputCPF" className="form-control textareax" placeholder="Customer Provided Facility" value={!isNullOrUndefined(doc.doctor.customerProvidedFacility) ? doc.doctor.customerProvidedFacility.replace(/[\r\n]+/gm, "") : ""} onChange={() => { }}></textarea>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={"bgwhite " + (this.state.HideLegalDiv ? "show" : "hide")}>
                                                                        <div className="card placeholder-div mb-1">
                                                                            <div className="card-header">
                                                                                <i className="fa fa-file" aria-hidden="true"></i>
                                                                        Special Instructions
                                                                        </div>
                                                                            <div className="card-body">
                                                                                <div className="form-row mb-3">
                                                                                    <div className="col-md-12">
                                                                                        <div className="form-group">
                                                                                            <textarea className="form-control textareax" placeholder="Instructions" value={SPIn} onChange={() => { }}>  </textarea>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>                                                                   
                                                                </div>

                                                                <div className="col-md-7">
                                                                    <div className={"bgwhite " + (this.state.HideLifeDiv ? "show" : "hide")}>
                                                                        <div className="card placeholder-div mb-1">
                                                                            <div className="card-header">
                                                                                <i className="fa fa-file" aria-hidden="true"></i>
                                                                                Special Instructions
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="form-row mb-3">
                                                                                    <div className="col-md-12">
                                                                                        <div className="form-group">
                                                                                            <textarea className="form-control textareax" placeholder="Instructions" value={doc.doctor.specialInstruction} onChange={() => { }}>  </textarea>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>                                                               

                                                                <div className="col-md-5">
                                                                    <div className={"bgwhite " + ((doc.apsReason.length > 0 && doc.apsReason.map(item => item.apsReason) != null && doc.apsReason.map(item => item.apsReason) != '' &&  this.state.HideLifeDiv) ? "show" : "hide")}>
                                                                        <div className="card placeholder-div mb-1">
                                                                            <div className="card-header">
                                                                                <i className="fa fa-file" aria-hidden="true"></i>
                                                                                APS Reason
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="form-row mb-3">
                                                                                    <div className="col-md-12">
                                                                                        <div className="form-group">
                                                                                            <textarea className="form-control textareax" placeholder="APS Reason" value={doc.apsReason.map(item => item.apsReason).join('\n')} maxLength={1000}
                                                                                                onChange={() => { }}>  </textarea>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 mt-2 clearboth">
                                                                    <div className="card max-div-xs mb-1">
                                                                        <div className="card-header">
                                                                            Status History
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="table-responsive dataTable unsubmitted posrel postion-right">
                                                                                <div className="left-0">
                                                                                    <label className="small mb-1">Number of entries: </label>
                                                                                    <span><label className="small mb-1"> {" " + doc.statuses.length} </label></span>
                                                                                </div>
                                                                                <MaterialTable
                                                                                    title=""
                                                                                    className="dataTable"
                                                                                    class="mat-paginator-sticky"
                                                                                    columns={[
                                                                                        {
                                                                                            title: 'Date/Time', field: 'dateTime', type: 'datetime', cellStyle: { width: '10%', }
                                                                                        },
                                                                                        { title: 'Status', field: 'status', cellStyle: { width: '10%', } },
                                                                                        { title: 'Status Event', field: 'eventDisplayName', cellStyle: { width: '10%', } },
                                                                                        { title: 'Details', field: 'details', cellStyle: { width: '60%', }, hidden: !this.state.isViewNote },
                                                                                    ]}
                                                                                    data={doc.statuses}
                                                                                    options={{
                                                                                        paginationType: "stepped",
                                                                                        search: true,
                                                                                        labelRowsPerPage: true,
                                                                                        sorting: true
                                                                                    }}

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
