import React, { Component } from 'react';
import { Layout } from './Layout';
import { Container } from 'reactstrap';
import { Link, NavLink, Redirect } from 'react-router-dom';
import $ from 'jquery';
import jwt_decode from 'jwt-decode';
import Api from '../serviceCall';
import { isNull, isNullOrUndefined } from 'util';
import { browserName, browserVersion, deviceDetect } from "react-device-detect";
var CryptoJS = require('crypto-js');

export class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserName: '', OPassword: '', NPassword: '', CPassword: '', isReset: false, Id: "", loading: true, toasterredCla: false, toasterCla: false, toaster: "",
            puserName: false, ppassword: false, pcPassword: false, poPassword: false, Version: "", Env: "", Date: "", CopyRightYear: new Date().getFullYear(), popupShow: false, passwordCount: "", PrivacyPolicyURL: ""
        };
        this.handleUNChange = this.handleUNChange.bind(this);
        this.handlePSChange = this.handlePSChange.bind(this);
        this.handleNPSChange = this.handleNPSChange.bind(this);
        this.handleCPSChange = this.handleCPSChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkUN = this.checkUN.bind(this);
        this.checkPass = this.checkPass.bind(this);
        this.checkPassChars = this.checkPassChars.bind(this);
        this.checkRepeat = this.checkRepeat.bind(this);
        this.toasterredfun2 = this.toasterredfun2.bind(this);
        this.toasterfun2 = this.toasterfun2.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.UserLogoutTime = this.UserLogoutTime.bind(this);

    }
    componentWillMount() {
        this.setState({ loading: true });
        let currentComponent = this;
        fetch('services/api/GetVersionConfiguration', {
            method: 'Get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',

            },

        }).then(function (response) {
            return response.json();

        }).then(function (result) {

            if (result.statusCode === 100) {
                currentComponent.setState({ Version: result.version, Env: result.environment, Date: result.buildDate });
            }
            else {
                currentComponent.setState({ Version: "", Env: "N/A", Date: "N/A" });
            }
        });

        Api.Get('api/login/GetPrivacyPolicy', currentComponent).then(function (response) {
            if (response.statusCode === 100) {
                currentComponent.setState({ PrivacyPolicyURL: response.privacyPolicyURL });
            }
        });

        var localpasswordCount = localStorage.getItem("PasswordCount");
        currentComponent.setState({ passwordCount: localpasswordCount, loading: true });
        var userId = Api.decryptData(localStorage.getItem("Euid"));
        if (userId.length == 0 || userId == null) {
            currentComponent.props.history.push('/');
        } else {
            Api.Get('api/placeorder/CheckUserDetails', currentComponent).then(function (result) {
                if (!result.isConfirmed) {
                    localStorage.setItem("Message", "User didn't confirmed account yet. Check your email to confirm account. If you have any queries contact administrator.");
                    localStorage.setItem("Title", "Confirmation Status");
                    currentComponent.props.history.push('/error');
                }
                else {
                    currentComponent.setState({ isReset: true, UserName: result.userName });
                }
                currentComponent.setState({ loading: false });
            }).catch(function (error) {
                currentComponent.setState({ Loading: false });
                if (error == "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }

                currentComponent.setState({ loading: false });
            });        

        //fetch('api/placeorder/CheckUserDetails', {
        //    method: 'post',
        //    headers: {
        //        Accept: 'application/json',
        //        'Content-Type': 'application/json',
        //        "Authorization": "Bearer " + token,
        //    },
        //    body: JSON.stringify({ "UserId": userId })
        //}).then(function (response) {
        //    if (response.status == 401) {
        //        currentComponent.props.history.push('/');
        //    }
        //    else { return response.json(); }
        //}).then(function (result) {
        //    if (!result.isConfirmed) {
        //        localStorage.setItem("Message", "User didn't confirmed account yet. Check your email to confirm account. If you have any queries contact administrator.");
        //        localStorage.setItem("Title", "Confirmation Status");
        //        currentComponent.props.history.push('/error');
        //    }
        //    else {
        //        currentComponent.setState({ isReset: true, UserName: result.userName });
        //    }
        //    currentComponent.setState({ loading: false });
        //}).catch(function (error) {
        //    currentComponent.setState({ Loading: false });
        //    if (error == "TypeError: Failed to fetch") {
        //        currentComponent.toasterredfun2("Check your internet connection.");
        //    }
        //    else {
        //        currentComponent.toasterredfun2(error);
        //    }

        //    currentComponent.setState({ loading: false });
        //});    
    }
}

componentDidMount() {
    this.setState({ loading: false });
}
handleUNChange(e) {
    if (e.target.value.length != 0) {
        if (this.checkUN(e.target.value.trim())) {
            this.setState({ UserName: e.target.value, puserName: false });
        }
    }
    else {
        this.setState({ UserName: e.target.value, puserName: false });
    }
}
handlePSChange(e) {
    if (e.target.value.length != 0) {
        if (e.target.value.length <= 15) {
            if (this.checkPassChars(e.target.value.trim())) {
                this.setState({ OPassword: e.target.value.trim(), poPassword: false, ppassword: false, });
            }
        }
        else {
            this.toasterredfun2("Password should not be more than 15 characters");
            this.setState({ OPassword: "" });
        }
    }
    else {
        this.setState({ OPassword: e.target.value, poPassword: false, ppassword: false, });
    }
}
handleNPSChange(e) {
    if (e.target.value.length != 0) {
        if (e.target.value.length <= 15) {
            if (this.checkPassChars(e.target.value.trim())) {
                this.setState({ NPassword: e.target.value.trim(), ppassword: false, poPassword: false });
            }
        }
        else {
            this.toasterredfun2("Password should not be more than 15 characters");
            this.setState({ NPassword: "" });
        }
    }
    else {
        this.setState({ NPassword: e.target.value.trim(), ppassword: false, poPassword: false });
    }
}
handleCPSChange(e) {
    if (e.target.value.length != 0) {
        if (e.target.value.length <= 15) {

            if (this.checkPassChars(e.target.value.trim())) {
                this.setState({ CPassword: e.target.value.trim(), pcPassword: false });
            }
        }
        else {
            this.toasterredfun2("Password should not be more than 15 characters");
            this.setState({ CPassword: "" });
        }
    }
    else {
        this.setState({ CPassword: e.target.value.trim(), pcPassword: false });
    }
}
toasterredfun2(e) {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    this.setState({ toasterredCla: true });
    this.setState({ toaster: e.toString() });
    setTimeout(
        function () {
            this.setState({ toasterredCla: false });
        }.bind(this), 5000);
}
 handleSubmit(e) {
    e.preventDefault();
    if (this.state.popupShow) {
        this.setState({ popupShow: false });
    }
    else {
        let currentComponent = this;
        var isValid = true;
        if (currentComponent.state.UserName.length == 0 && currentComponent.state.UserName.length < 8) {
            currentComponent.setState({ puserName: true });
            isValid = false;
        }
        if (currentComponent.state.OPassword.length == 0 && currentComponent.state.OPassword.length < 8) {
            currentComponent.setState({ poPassword: true });
            isValid = false;
        }
        else if (!currentComponent.checkPass(currentComponent.state.OPassword)) {
            currentComponent.toasterredfun2("Password does not meet the required criteria");
            currentComponent.setState({ poPassword: true });
            isValid = false;
        }
        if (currentComponent.state.NPassword.length == 0 && currentComponent.state.NPassword.length < 8) {
            currentComponent.setState({ ppassword: true });
            isValid = false;
        }
        else if (!currentComponent.checkPass(currentComponent.state.NPassword)) {
            currentComponent.toasterredfun2("Password does not meet the required criteria");
            currentComponent.setState({ ppassword: true });
            isValid = false;
        }
        if (currentComponent.state.CPassword.length == 0 && currentComponent.state.CPassword.length < 8) {
            currentComponent.setState({ pcPassword: true });
            isValid = false;
        }
        else if (currentComponent.state.NPassword != currentComponent.state.CPassword) {
            currentComponent.setState({ pcPassword: true });
            currentComponent.toasterredfun2("New Password and Confirm Password not matches.");
            isValid = false;
        }
        else {
            if (currentComponent.state.OPassword == currentComponent.state.NPassword) {
                currentComponent.setState({ poPassword: true, ppassword: true });
                currentComponent.toasterredfun2("Current Password and New Password are same. Try with another password.");
                isValid = false;
            }
        }
        if (isValid) {
            currentComponent.setState({ loading: true });
            var key = CryptoJS.enc.Utf8.parse('6728623734782342');
            var iv = CryptoJS.enc.Utf8.parse('8080808080808080');
            var encryptedlogin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(currentComponent.state.UserName), key,
                {
                    keySize: 128 / 8,
                    iv: iv,
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7
                });
            var encryptedpassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(currentComponent.state.OPassword), key,
                {
                    keySize: 128 / 8,
                    iv: iv,
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7
                });
            var encryptednpassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(currentComponent.state.NPassword), key,
                {
                    keySize: 128 / 8,
                    iv: iv,
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7
                });
            
            var userId = Api.decryptData(localStorage.getItem("Euid"));
            if (userId.length == 0 || userId == null) {
                currentComponent.props.history.push('/');
            }
            else {

                Api.Post('api/placeorder/ResetUserDetails', {
                    "UserName": Api.encryptData(currentComponent.state.UserName), "Password": Api.encryptData(currentComponent.state.OPassword), "NewPassword": Api.encryptData(currentComponent.state.NPassword), "BrowserType": deviceDetect().browserName,
                    "BrowserVersion": deviceDetect().browserFullVersion,"isFromResetPassword":true}, currentComponent).then(function (result) {
                    if (result.statusMessage == "Success") {
                        currentComponent.toasterfun2("Password has been changed successfully.");                       
                    }
                    else if (result.statusCode == 122) {
                        currentComponent.setState({ loading: false });
                        currentComponent.toasterredfun2(result.statusMessage);
                        setTimeout(function () {
                            localStorage.clear();
                            currentComponent.props.history.push("/");
                        }, 5000);
                    }
                    else if (result.statusCode == 409) {

                        currentComponent.setState({ loading: false });
                        currentComponent.toasterredfun2(result.statusMessage);
                    }
                    else {
                        currentComponent.setState({ loading: false });
                        currentComponent.toasterredfun2(result.statusMessage);
                    }
                }).catch(function (error) {
                    currentComponent.setState({ Loading: false });
                    if (error == "TypeError: Failed to fetch") {
                        currentComponent.toasterredfun2("Check your internet connection.");
                    }
                    else {
                        currentComponent.toasterredfun2(error);
                    }
                    currentComponent.setState({ loading: false });
                });

                //fetch('api/placeorder/ResetUserDetails', {
                //    method: 'post',
                //    headers: {
                //        Accept: 'application/json',
                //        'Content-Type': 'application/json',
                //        "Authorization": "Bearer " + token,
                //    },
                //    body: JSON.stringify({ "Id": userId, "UserName": encryptedlogin.toString(), "Password": encryptedpassword.toString(), "NewPassword": encryptednpassword.toString() })
                //}).then(function (response) {
                //    if (response.status == 401) {
                //        currentComponent.props.history.push('/');
                //    }
                //    else { return response.json(); }
                //}).then(function (result) {
                //    if (result.statusMessage == "Success") {
                //        currentComponent.toasterfun2("Password has been changed successfully.");
                //        setTimeout(
                //            function () {
                //                localStorage.clear();
                //                currentComponent.props.history.push('/');
                //            }.bind(this), 5000);
                //    }
                //    else {
                //        currentComponent.setState({ loading: false });
                //        currentComponent.toasterredfun2(result.statusMessage);
                //    }
                //}).catch(function (error) {
                //    currentComponent.setState({ Loading: false });
                //    if (error == "TypeError: Failed to fetch") {
                //        currentComponent.toasterredfun2("Check your internet connection.");
                //    }
                //    else {
                //        currentComponent.toasterredfun2(error);
                //    }
                //    currentComponent.setState({ loading: false });
                //});
            }
        }

    }
}

checkUN(e) {
    if (e.length > 1) {
        let split = e.split('.');
        if (split.length > 2) {
            return false;
        }
    }
    if (/^[a-zA-Z0-9@.]*$/g.test(e)) {
        return true;
    }
    else {
        return false;
    }
    return true;
}
checkPass(e) {
    //if (/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g.test(e)) {
    var re = /^(?!.*[\s])(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&])[0-9a-zA-Z@$!%*#?&]{8,15}$/;
    if (re.test(e)) {
        return true;
    }
    else {
        return false;
    }
}
checkPassChars(e) {
    let currentComponent = this;
    if (/^[A-Za-z0-9\d@$!%*#?&]*$/g.test(e)) {
        return true;
    }
    else {
        currentComponent.setState({ popupShow: true });
        currentComponent.setState({ NPassword: "" });
        currentComponent.setState({ CPassword: "" });
        currentComponent.setState({ OPassword: "" });
        return false;
    }
}
    async UserLogoutTime() {     
    let currentComponent = this;
    var CompanyCode = localStorage.getItem("Companycode");
    let euid = localStorage.getItem("Euid");
    this.setState({ loading: true });
    await fetch('api/login/UserLogout', {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "Euid": euid,
            "CompanyCode": CompanyCode,
        },
        body: JSON.stringify({
            "CompanyCode": CompanyCode,
            "BrowserType": deviceDetect().browserName,
            "BrowserVersion": deviceDetect().browserFullVersion            
        })
    }).then(function (response) {
        localStorage.clear();
        currentComponent.props.history.push("/");
    })

}
toasterfun2(e) {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    this.setState({ toasterCla: true });
    this.setState({ toaster: e });
    setTimeout(
        function () {
            this.setState({ toasterCla: false });
            this.props.history.push('/');
        }.bind(this), 5000);
}
checkRepeat(e) {
    if (e.length > 2) {
        if (/(.)\1{2}$/.test(e)) {
            return false;
        }
    }
    return true;
}

closePopup(e) {
    let currentComponent = this;
    currentComponent.setState({ popupShow: false });
}
render() {
    var landing = '/img/ems-logo.gif';
    const { Username, Password, isChecked} = this.state
    return (
        <Container fluid>

            <span className={"loading " + (this.state.loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} /> </span>
            <div className={"toaster-div " + (this.state.toasterCla ? "show" : "hide")}><i className="fa fa-check-circle" aria-hidden="true"></i>
                {this.state.toaster} </div>
            <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster} </div>
            <div className="container-div">
                <div className="con-div-pso">
                    <div className="con-div-xd lefeside-panel">
                    </div>
                </div> H
                <div className="con-div-xm">
                    <div className="con-div-x">
                        <div className="login-page">
                            <div className="logo">
                                <img src="img/eSubmission-logo.png" />
                                <h2>eNoah Online Portal </h2>
                            </div>
                            <div className="form">
                                <form className="login-form">
                                    <h5>Reset Password</h5>
                                    <label>User Name</label>
                                    <input type="text" className={"form-control " + (this.state.puserName ? "redborder" : "blackborder")} placeholder="Username" nofill="true" readOnly={(this.state.isReset ? "readOnly" : "")} id="un" autoComplete="false" maxLength={15} onChange={this.handleUNChange} value={this.state.UserName} onFocus={(event) => {
                                        event.target.setAttribute('autocomplete', 'off');
                                    }} />
                                    <label>Current Password</label>
                                    <input type="password" className={"form-control " + (this.state.poPassword ? "redborder" : "blackborder")} autoComplete="new-password" nofill="true" placeholder="Current Password" maxLength={16} onChange={this.handlePSChange} id="ps" value={this.state.OPassword} onFocus={(event) => {
                                        event.target.setAttribute('autocomplete', 'off');
                                    }} />
                                    <label>New Password</label>
                                    <input type="password" className={"form-control " + (this.state.ppassword ? "redborder" : "blackborder")} autoComplete="new-password" nofill="true" placeholder="Password" onChange={this.handleNPSChange} id="ps" value={this.state.NPassword} onFocus={(event) => {
                                        event.target.setAttribute('autocomplete', 'off');
                                    }} />
                                    <small style={{ lineHeight: 1.2 }} className="form-text text-muted text-left">
                                        <ul className="password-bullets">
                                            <li>
                                                Be between 8-15 characters long
                                            </li>
                                            <li>Contain at least one upper case character</li>
                                            <li>Contain at least one lower case character</li>
                                            <li>Contain at least one number</li>
                                            <li>Contain atleast one special character-    !@#$%?&*</li>
                                            <li>Password cannot be same as your last {this.state.passwordCount} {" "}  passwords</li>

                                        </ul>
                                    </small>
                                    <label>Confirm Password</label>
                                    <input type="password" className={"form-control " + (this.state.pcPassword ? "redborder" : "blackborder")} placeholder="Confirm Password" nofill="true" autoComplete="new-password" maxLength={16} onChange={this.handleCPSChange} id="cps" value={this.state.CPassword} onFocus={(event) => {
                                        event.target.setAttribute('autocomplete', 'off');
                                    }} />

                                    <button className="onLogin" onClick={this.handleSubmit}>Submit</button>
                                    <span className="logoutlink-div-x"><NavLink tag={Link} to="/" onClick={() => this.UserLogoutTime()}>Logout</NavLink> </span>
                                </form>
                            </div>
                        </div>
                        <div className="footer-text text-center mb-2">Build: <span className="text-dark mr-2">{this.state.Date + ": v" + this.state.Version}</span> Environment: <span className="text-dark">{this.state.Env}</span></div>
                        <div className="footer-text text-center mb-2">Copyright @ eNoah iSolutions Inc. {this.state.CopyRightYear}</div>
                        <div className="footer-text text-center">
                            <a href={this.state.PrivacyPolicyURL} target="_blank"> Privacy Policy</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"removeAlert-divbrowser " + (this.state.popupShow ? "show" : "hide")}>
                <span className="alert-bg-div"></span>
                <div className="alert-pos-x text-center">
                    <img src="img/error.png" alt="Error" />
                    <p className="my-3">
                        The following special characters are allowed !@#?$%&*
                    </p>
                    <div className="text-center-div m-0">
                        <div className="bttn popupButton m-0" onClick={this.closePopup}>
                            Ok
                        </div>
                    </div>
                </div>
            </div>

        </Container>

    );
}
}
