import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { browserName, browserVersion, deviceDetect } from "react-device-detect";
import { isNull, isNullOrUndefined } from 'util';
import Api from '../serviceCall';
import $ from 'jquery';
import { Link, NavLink, Redirect } from 'react-router-dom';

export class Layout extends Component {
    static displayName = Layout.name;
    constructor(props) {
        super(props);
        this.state = { Version: "", Env: "", Date: "", CopyRightYear: new Date().getFullYear(), UserGuidePath: "", ReleaseNotesPath: "", PrivacyPolicyURL: "", toasterredCla: false, toaster: "" , UserId:""}

        this.FetchFooters = this.FetchFooters.bind(this);
        this.toasterredfun2 = this.toasterredfun2.bind(this);
    }
    componentDidMount() {
        let currentComponent = this;
        localStorage.removeItem('PressedKey');
        localStorage.removeItem('PressedKeyCode');
        localStorage.removeItem('IsShiftPressed');
        localStorage.removeItem('IsCtrlPressed');

        document.addEventListener('keydown', function (event) {            
            localStorage.setItem('PressedKey', event.key);
            localStorage.setItem('PressedKeyCode', event.keyCode);
            localStorage.setItem('IsCtrlPressed', event.ctrlKey);
            localStorage.setItem('IsShiftPressed', event.shiftKey);
        });
        window.addEventListener('beforeunload', function (e) {
            var IsSSOUser = localStorage.getItem("IsSSOUser");
            var SyncClicked = localStorage.getItem("SyncClicked");
            var IsTimeOut = localStorage.getItem("IsTimeOut");
            var IsTimeOutFromPlaceOrder = localStorage.getItem("IsTimeOutFromPlaceOrder");
            if (IsSSOUser == 'true' && SyncClicked == 'false' && IsTimeOut == 'false') {
                e.preventDefault();
                e.returnValue = '';
            }
            else if (SyncClicked == 'false' && IsTimeOut == 'false' && IsTimeOutFromPlaceOrder == 'false') {
                e.preventDefault();
                e.returnValue = '';
            }
        });
        window.addEventListener('unload', function (event) {
            //console.log(event);
            var IsSSOUser = localStorage.getItem("IsSSOUser");
            var SyncClicked = localStorage.getItem("SyncClicked");
            var IsTimeOut = localStorage.getItem("IsTimeOut");
            var IsTimeOutFromPlaceOrder = localStorage.getItem("IsTimeOutFromPlaceOrder");
            var Euid = localStorage.getItem("Euid");
            var PressedKey = localStorage.getItem("PressedKey");
            var PressedKeyCode = localStorage.getItem("PressedKeyCode");
            var IsShiftPressed = localStorage.getItem("IsShiftPressed");
            var IsCtrlPressed = localStorage.getItem("IsCtrlPressed");
            if (IsSSOUser == 'true' && SyncClicked == 'false' && IsTimeOut == 'false') {
                if ((IsShiftPressed && IsCtrlPressed && (PressedKey == 'r' || PressedKey == 'R')) || PressedKey == 'F5' || (IsCtrlPressed && (PressedKey == 'r' || PressedKey == 'R'))) {
                    //Normal refresh will happen without Logging out.
                } else {
                    currentComponent.UserLogoutTime();
                }
            }
            else if ((!isNullOrUndefined(Euid)) && IsTimeOutFromPlaceOrder == 'false' && IsTimeOut == 'false' && SyncClicked == 'false') {
                if ((IsShiftPressed && IsCtrlPressed && (PressedKey == 'r' || PressedKey == 'R')) || PressedKey == 'F5' || (IsCtrlPressed && (PressedKey == 'r' || PressedKey == 'R'))) {
                    //Normal refresh will happen without Logging out.
                } else {
                    currentComponent.UserLogoutTime();
                }
            }
            setTimeout(() => {
                console.log('------');
            }, 10000);
        });
        

        //BRD Addition.
        Api.Get('api/login/GetPrivacyPolicy', currentComponent).then(function (response) {
            if (response.statusCode === 100) {
                currentComponent.setState({ PrivacyPolicyURL: response.privacyPolicyURL });
            }

        });
    }
    componentWillMount() {
        let currentComponent = this;
        var IsSSOUser = localStorage.getItem("IsSSOUser");
        if (IsSSOUser == 'true') {
            window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
            window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
        }
        fetch('services/api/GetVersionConfiguration', {
            method: 'Get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',

            },

        }).then(function (response) {
            return response.json();

        }).then(function (result) {            
            if (result.statusCode === 100) {
                currentComponent.setState({ Version: result.version, Env: result.environment, Date: result.buildDate });
            }
            else {
                currentComponent.setState({ Version: "", Env: "N/A", Date: "N/A" });
            }
        });
    }
    toasterredfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }

    FetchFooters = value => () => {

        var param = value;
        let currentComponent = this;
        Api.Get('api/login/GetFooterNotes', currentComponent).then(function (response) {
            if (response.statusCode === 100) {
                if (param === "userGuide") {
                    if (response.userGuidePath === "FNF") {
                        currentComponent.toasterredfun2("User guide not found!");
                    }
                    else {
                        window.open(response.userGuidePath, "_blank");
                    }
                }
                else if (param === "releaseNotes") {
                    if (response.releaseNotesPath === "FNF") {
                        currentComponent.toasterredfun2("Release notes not found!")
                    }
                    else {
                        window.open(response.releaseNotesPath, "_blank");
                    }
                }
                else if (param === "eopDemoVideo") {
                    if (response.demoVideoPath === "FNF") {
                        currentComponent.toasterredfun2("EOP Demo Video not found!")
                    }
                    else {
                        window.open(response.demoVideoPath, "_blank");
                    }
                }
            }
            else {
                if (param === "userGuide") {

                    currentComponent.toasterredfun2("User guide not found!");

                }
                else if (param === "releaseNotes") {

                    currentComponent.toasterredfun2("Release notes not found!")

                }
                else if (param === "eopDemoVideo") {
                    currentComponent.toasterredfun2("EOP Demo Video not found!")
                }

            }
        });
    }
    async UserLogoutTime() {      
        let currentComponet = this;
        var CompanyCode = localStorage.getItem("Companycode");
        let euid = localStorage.getItem("Euid");       
        const headers = {
            'Content-Type': 'application/json',
            'Euid': euid,
            'CompanyCode': CompanyCode,
            'BrowserType': deviceDetect().browserName,
            'BrowserVersion': deviceDetect().browserFullVersion
        };
        const formData = new FormData();
        Object.keys(headers).forEach((key) => {
            formData.append(key, headers[key]);
        });
        const result = navigator.sendBeacon('services/api/UserLogOutEntry', formData);
        localStorage.clear();
        if (result) {

            console.log('Beacon sent successfully');
        } else {
            console.log('Beacon failed to send');
        }
        setTimeout(
            function () {
                console.log("-------");
            }, 9000);
    }
    render() {
        if (window.location.pathname == '/result') {
            // Remove div elements with id's which starts with rbd-announcement/rbd-hidden
            const elementsToRemove = document.querySelectorAll('div[id^="rbd-hidden"], div[id^="rbd-announcement"]');
            // Remove each selected div element
            elementsToRemove.forEach(element => {
                element.remove();
            });
        }
        if (window.location.pathname == '/performance')
        {
            // Remove <div hidden> element
            const hiddendivelement = document.querySelector('div[hidden]');
            if (hiddendivelement) {
                hiddendivelement.remove();
            }
            const divElements = document.querySelectorAll('div');
            divElements.forEach((divElement) => {
                const computedStyle = window.getComputedStyle(divElement);
                if (computedStyle.visibility === 'hidden') {
                    divElement.remove();
                }
            });
        }
        if (window.location.pathname == '/account') {
            // Remove <div hidden> element
            const hiddendivelement = document.querySelector('div[hidden]');
            if (hiddendivelement) {
                hiddendivelement.remove();
            }
            const divElements = document.querySelectorAll('div');
            divElements.forEach((divElement) => {
                const computedStyle = window.getComputedStyle(divElement);
                if (computedStyle.visibility === 'hidden') {
                    divElement.remove();
                }
            });
       
        }
        return (
            <div>                
                <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                <NavMenu />
                <Container fluid>
                    {this.props.children}
                </Container>
                <Container fluid>
                    <row>
                        <column-12>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="footer-text">Copyright @ eNoah iSolutions Inc. {this.state.CopyRightYear}</div>
                        <div className="footer-text text-center mb-2">
                            <div className="footer-text text-center mb-2">
                                        <a href={this.state.PrivacyPolicyURL} target="_blank"> Privacy Policy</a> | <NavLink className="footer-text" onClick={this.FetchFooters('userGuide')} to={window.location} >User Guide</NavLink> | <NavLink className="footer-text" onClick={this.FetchFooters('releaseNotes')} to={window.location} >Release Notes </NavLink> | <NavLink className="footer-text" onClick={this.FetchFooters('eopDemoVideo')} to={window.location} >EOP Demo Video </NavLink>
                            </div>
                        </div>
                        <div className="footer-text">Build: <span className="text-dark mr-2">{this.state.Date + ": v" + this.state.Version}</span> Environment: <span className="text-dark">{this.state.Env}</span></div>
                            </div>
                        </column-12>
                    </row>
                </Container>
            </div>
        );
    }
}
